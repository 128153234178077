import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { TraveInsuranceService } from '../../../_service/traveInsurance.service';
import { GlobalService } from "../../../_service/global.service";
import { HomequoteService } from '../../../_service/homequote.service';
import Swal from 'sweetalert2';
import { MotorquoteService } from 'app/_service/motorquote.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  public policyNum:any; paymentLoader:boolean = false; quoteNum:any; public partnerId :any;  localStorDta:any;  orderNumber:any; orderToken:any; payPolicyNum:any =''; 
  public payment_transaction_ID_status:any; policy_Wording_Url:any; payment_Transaction_ID:any; token:any; response_msg:any =''; quoteID:any; debit_Note_Url:any =''; credit_Note_Url:any=''; payWebPolicyNumber:any;
  EmailAddress: any;
  hideshowemail: boolean=false;
  cust: boolean =false;
  policyno: boolean =false;
  PageLoader: boolean =false;
  policyScedLoder: boolean;
  insuranceCertificate: boolean;
  debitnoteloader: boolean;
  cerditnoteLoader: boolean;
  policyuid: any;
  CorePolicyNumber:any;
  SchemeType:any;
  access_type: boolean;
  username:any; lob:any;
  policyDetail:any = [];
  PartnerId: any;
  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public traveInsuranceService:TraveInsuranceService, public globalService:GlobalService, public homeQuoteService:HomequoteService,public motorQuoteService:MotorquoteService) { }

  ngOnInit() {
    // alert(1)
    this.PageLoader = this.globalService.spinnerShow();
    this.insuranceCertificate = this.globalService.spinnerHide();
    // let quote = "'QTR0130050000129640'"
    // console.log(quote.replace(/^'(.*)'$/, '$1'))
    // console.log(parseInt('QTR0130050000129640'))
    this.paymentLoader = this.globalService.spinnerHide();
    this.debitnoteloader = this.globalService.spinnerHide();
    this.cerditnoteLoader = this.globalService.spinnerHide();
    this.policyScedLoder = this.globalService.spinnerHide();
    this.policyno = this.globalService.spinnerShow();
    this.localStorDta =  this.globalService.getLocalStorageData();
    // this.PartnerId = this.localStorDta.Partner_ID;
    this.orderNumber =  localStorage.getItem("Payment_Order_ID");
    this.orderToken  =  localStorage.getItem("Payment_Order_Tokan");
    this.quoteNum = localStorage.getItem('Payment_Quotation_Number');
    this.quoteID =  localStorage.getItem('Payment_Quotation_ID');
    this.policyuid =  localStorage.getItem('travel_policy_uid');
    this.debit_Note_Url =  localStorage.getItem('travel_debit_note');
    this.credit_Note_Url =  localStorage.getItem('travel_credit_note');
    this.payPolicyNum =  localStorage.getItem('CorePolicyNumber');
    this.payWebPolicyNumber =  localStorage.getItem('policyNumber');
    // this.SchemeType=localStorage.getItem('scheme');
    // console.log( this.localStorDta)
    // console.log( this.SchemeType)
    // this.partnerId = this.localStorDta.Partner_ID;
    
    // this.user_id =this.localStorDta.User_ID;
    // console.log( this.partnerId,this.username)
    if(this.localStorDta != null){
      this.partnerId = this.localStorDta.Partner_ID;
      this.username = this.localStorDta.EmailAddress;
      // this.cedant_id = this.localStorDta.Cedant_ID;
    }
    else{
      this.cust=true;
    }
    // this.partnerId = this.localStorDta.Partner_ID;
  
    const routeParam = this._activatedroute.snapshot.params;
      if(routeParam){

          this.policyNum = routeParam.policyNumber;
          if(this.payWebPolicyNumber == null || this.payWebPolicyNumber == "")
          {
            this.payWebPolicyNumber = this.policyNum;
          }
          this.quoteNum = routeParam.quoteNum;
          this.token = routeParam.Token;
        }
        this.getCustomerPolicyDetail();
           
        if(this.orderToken!=null){
            this.verifyQuatationPayment();
        }
        else{
          this.PageLoader = this.globalService.spinnerHide();
          this.policyno = this.globalService.spinnerHide();
        }
        console.log( this.debit_Note_Url)     

        this.policy_Wording_Url = 'https://b2bmedstaging.fidelityunited.ae/pdfdocuments/TR/TravelInsurancePolicyWording.pdf';

            
  }

  //--------Vinith for scheme type
  getCustomerPolicyDetail(){
    //  this.partnerId = 1;
    //  this.username = 'broker@united.com';
     this.lob = 'TR';
    // console.log(this.partnerId,this.username,this.policyNum,this.lob);
this.homeQuoteService.getCustomerPolicyDetails(this.partnerId,this.username,this.payWebPolicyNumber,this.lob).subscribe(res =>{
              //console.log(res);
              this.policyDetail = res.data[0];

              if(res.res_code == 1){
                // this.quoteNum = this.policyDetail.QuotationNumber;
                // this.POBoxNum = this.policyDetail.POBOx;
                // this.insuredMobile = this.policyDetail.MobileNumber;
                // this.insuredEmail = this.policyDetail.EmailAddress;
                // this.paymentType = this.policyDetail.PaymentType;
                this.SchemeType=this.policyDetail.SchemeType;
                // this.CRSPolUID=this.policyDetail.CRSPolUID;
                 console.log(this.SchemeType);
              }
             
     });

     
}
//------------------------------------------------------------

  gotoGetQuote(){
      localStorage.removeItem("Payment_Order_ID");
      localStorage.removeItem("Payment_Order_Tokan");
      localStorage.removeItem('Payment_Quotation_Number');
      localStorage.removeItem('Payment_Quotation_ID');
      localStorage.removeItem('travel_debit_note');
      localStorage.removeItem('travel_credit_note');
      this._route.navigateByUrl('/dashboard');
  }

  goToHome(){
      localStorage.removeItem("Payment_Order_ID");
      localStorage.removeItem("Payment_Order_Tokan");
      localStorage.removeItem('Payment_Quotation_Number');
      localStorage.removeItem('Payment_Quotation_ID');
      localStorage.removeItem('travel_debit_note');
      localStorage.removeItem('travel_credit_note');
      this._route.navigateByUrl('/dashboard');
  }

  viewPolicy(){

  }
// -------------------------------------------------Old Payement Gateway------------------------------------------------------------
  // saveQuotationPayment(){
  //   this.traveInsuranceService.saveQuotationPayment(this.orderToken,this.payment_transaction_ID_status,this.quoteNum,this.response_msg,this.payment_Transaction_ID,this.quoteID,this.policyNum,'TR').subscribe(res =>{
      
  //         if(res.res_code == 1){
  //           this.payPolicyNum = res.CorePolicyNumber;
  //           this.debit_Note_Url = res.debit_note;
  //           this.credit_Note_Url = res.credit_note;
  //           this.payWebPolicyNumber = res.web_policy_number;
  //           this.policyno = this.globalService.spinnerHide();
  //           localStorage.removeItem("Payment_Order_ID");
  //           localStorage.removeItem("Payment_Order_Tokan");
  //           localStorage.removeItem('Payment_Quotation_Number');
  //           localStorage.removeItem('Payment_Quotation_ID');
  //           localStorage.removeItem('travel_debit_note');
  //           localStorage.removeItem('travel_credit_note');
  
  
  //         }
  //   });
  
  // }
//  ---------------------------------------------------New Payement Gateway-----------------------------------------------
  saveQuotationPayment(){
    // alert(1)
    this.homeQuoteService.saveQuotationPayment( this.quoteID,this.orderToken,this.payment_transaction_ID_status,this.quoteNum,this.response_msg,this.payment_Transaction_ID,'TR','').subscribe(res =>{
       console.log(res);
          if(res.res_code == 1){
            this.payPolicyNum = res.CorePolicyNumber;
            this.policyuid =res.PolicyUid;
            this.debit_Note_Url = res.debit_note;
            this.credit_Note_Url = res.credit_note;
            this.payWebPolicyNumber = res.web_policy_number;
            this.policyno = this.globalService.spinnerHide();
            this.getCustomerPolicyDetail();
            // localStorage.removeItem("Payment_Order_ID");
            // localStorage.removeItem("Payment_Order_Tokan");
            // localStorage.removeItem('Payment_Quotation_Number');
            // localStorage.removeItem('Payment_Quotation_ID');
            // localStorage.removeItem('travel_debit_note');
            // localStorage.removeItem('travel_credit_note');


          }
    });

}
// -------------------------------------------------------------New Payemnt Gateway---------------------------------------------
verifyQuatationPayment(){
  //Modified by Anju for new Payment gateway integration --   
  this.traveInsuranceService.verifyPayment(this.orderNumber).subscribe(res =>{
    console.log(res);
    let response = res;
    this.response_msg = response.result.payment_result.response_code;
    this.payment_Transaction_ID = response.result.tran_ref;
    // this.quoteNum = response.result.cart_id.replace(/^'(.*)'$/, '$1');
           
             if(res.res_code == 2){
               // if(res.result.details == "This invoice is either cancelled by the customer or expired"){	
              this.payment_transaction_ID_status = "CANCELLED";	
              localStorage.setItem('Payment_Quotation_Number',res.result.cart_id);
               //Modified by Anju for retry payment scenario
              this._route.navigateByUrl("/travel/cancelpayment/" + this.response_msg+ "/" +this.quoteNum+ "/" +"TR");	
              this.PageLoader = this.globalService.spinnerHide();	
                }	
            else{	
                  this.payment_transaction_ID_status = "ISSUED";	
                  this.saveQuotationPayment();	
                  this.PageLoader = this.globalService.spinnerHide();	
              }

              
          //     if((res.result.payment_result.response_status == "A") || (res.result.response_code == '100')){
          //       this.payment_transaction_ID_status = "ISSUED";
          //      alert("Issued");
          //      this.saveQuotationPayment();
          //       this.PageLoader = this.globalService.spinnerHide();
          //   }
          //   else{
          //     this.payment_transaction_ID_status = "CANCELLED";
          //     console.log(1)
          //     this._route.navigateByUrl('travel/cancelpayment/' + this.response_msg);
          //     this.PageLoader = this.globalService.spinnerHide();
          // }

      });

}

// getCheckCN(){
//   this.motorQuoteService.getCheckCN('HC').subscribe(res =>{

//         this.access_type = res.access_type;
//   });
// }

// ---------------------------------------Old Payement Gateway------------------------------------------------------
// verifyQuatationPayment(){
//   this.traveInsuranceService.verifyQuatationPayment(this.orderNumber).subscribe(res =>{
//     let response = res;
//     this.response_msg = response.result.result;
//     this.payment_Transaction_ID = response.result.statement_reference;
           
//              if(res.res_code == 1){
  
//                 if(res.result.transaction_id == null){
//                     this.payment_transaction_ID_status = "CANCELLED";
//                     this.PageLoader = this.globalService.spinnerHide();
//                 }
//                 if(res.result.transaction_id != null){
//                   this.payment_transaction_ID_status = "ISSUED";
//                   this.PageLoader = this.globalService.spinnerHide();
//               }
  
  
//                this.saveQuotationPayment();
//              }
//       });
  
//   }

  generatePDF(type){
          if(type == 1){
            this.policyScedLoder = this.globalService.spinnerShow();
            this.policyNum = this.orderNumber == null ? this.policyNum: this.payWebPolicyNumber;
            this.traveInsuranceService.getPolicySchedulePDF(this.policyNum,'B2B',this.quoteNum).subscribe(res =>{                    if(res.response_code == 1){
                      window.open(res.pdfPath, "_blank");
                      this.policyScedLoder = this.globalService.spinnerHide();
                    }
              });
          }

          if(type == 2){
            this.debitnoteloader = this.globalService.spinnerShow();
              window.open( this.debit_Note_Url, "_blank");
              this.debitnoteloader = this.globalService.spinnerHide();
          }
        
          if(type == 3){
            this.cerditnoteLoader = this.globalService.spinnerShow();
              window.open( this.credit_Note_Url, "_blank");
              this.cerditnoteLoader = this.globalService.spinnerHide();
          }
              
  }
  generateCertificate(){
    this.insuranceCertificate = this.globalService.spinnerShow();
    this.policyNum = this.orderNumber == null ? this.policyNum: this.payWebPolicyNumber;
    this.traveInsuranceService.getTravelCertificate(this.policyNum,'B2B',this.quoteNum).subscribe(res =>{
      if(res.response_code == 1){
        window.open(res.pdfPath, "_blank");
        this.insuranceCertificate = this.globalService.spinnerHide();
      }

    })

  }
  sendPolicyMail(){
    this.policyNum = this.orderNumber == null ? this.policyNum: this.payWebPolicyNumber;
    this.traveInsuranceService.sendPolicyScheduleMail( this.policyNum,this.EmailAddress,this.quoteNum).subscribe(result=>{
      if(result.response_code == 1){
        this.hideshowemail=true;
      }

    })
  }

  goToURL(){

          this.motorQuoteService.webSiteLink("B2BMotor").subscribe(res =>{
                  location.href = res.fedility_website ;
          });
        }     
          ngOnDestroy(){
            localStorage.removeItem("CorePolicyNumber");
            localStorage.removeItem("Payment_Order_ID");
            localStorage.removeItem("policyNumber");
            localStorage.removeItem("Payment_Order_Tokan");
            localStorage.removeItem('Payment_Quotation_Number');
            localStorage.removeItem('Payment_Quotation_ID');
            localStorage.removeItem('travel_debit_note');
            localStorage.removeItem('travel_credit_note');
            localStorage.removeItem('travel_policy_uid');
           
}
getCheckCN(){
  this.motorQuoteService.getCheckCN('HC').subscribe(res =>{
        this.access_type = res.access_type;
  });
}
}
