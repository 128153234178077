import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutoAddSlash]'
})
export class AutoAddSlashDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const input = this.el.nativeElement as HTMLInputElement;
    const inputValue = input.value;
    const cleanedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (cleanedValue.length <= 2) {
      // Automatically add a "/" after the second character
      this.renderer.setProperty(input, 'value', cleanedValue);
    } else if (cleanedValue.length <= 4) {
      // Automatically add a "/" after the second and fifth characters
      this.renderer.setProperty(input, 'value', cleanedValue.substring(0, 2) + '/' + cleanedValue.substring(2));
    } else if (cleanedValue.length <= 8) {
      // Automatically add a "/" after the second, fifth, and eighth characters
      this.renderer.setProperty(
        input,
        'value',
        cleanedValue.substring(0, 2) + '/' + cleanedValue.substring(2, 4) + '/' + cleanedValue.substring(4)
      );
    } else {
      // Trim to "dd mm yyyy" format and set the value
      this.renderer.setProperty(
        input,
        'value',
        cleanedValue.substring(0, 2) + '/' + cleanedValue.substring(2, 4) + '/' + cleanedValue.substring(4, 8)
      );
    }
  }
}
