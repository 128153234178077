import { Component, OnInit, ViewChild, ɵConsole } from "@angular/core";
import { MotorquoteService } from "../../_service/motorquote.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { GlobalService } from "../../_service/global.service";
import Swal from "sweetalert2";
import { AppDateAdapter, APP_DATE_FORMATS } from "../motorquote/date.adapter";
import { Router, ActivatedRoute } from "@angular/router";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Observable, Subscription, fromEvent } from "rxjs";
import { MatSelect } from "@angular/material";
import { IssuepolicyService } from "../../_service/issuepolicy.service";
import { max } from "rxjs-compat/operator/max";
import { config } from "../../config";
import { TraveInsuranceService } from '../../_service/traveInsurance.service';

interface BenefitReplacement {
  value: string;
  viewValue: string;
}
interface Code {
  value: string;
  viewValue: string;
}

interface DrivExp {
  value: string;
  viewValue: string;
}

interface VehiTrim {
  value: string;
  viewValue: string;
}
interface VehiModelYr {
  value: string;
  label: string;
}
interface BrandNew {
  value: string;
  viewValue: string;
}
interface VehiSeatCap {
  value: string;
  viewValue: string;
}
interface VehiCylinder {
  value: string;
  viewValue: string;
  code: string;
}
interface InsType {
  value: string;
  viewValue: string;
  WebProdCode: string;
  CoreProdCode: string;
}
interface YesNo {
  value: string;
  viewValue: string;
}
interface RepairType {
  value: string;
  viewValue: string;
}
interface MotBankNam {
  value: string;
  viewValue: string;
}
interface Gender {
  value: string;
  viewValue: string;
}
interface ExpiryMonth {
  value: string;
  viewValue: string;
}

interface Country {
  id: number;
  CountryName: string;
  Active: boolean;
  CountryCode: string;
  CurrencyCode: string;
  CRS_NATION_CODE: number;
  CRS_Country_Map_Code: number;
}

interface Make {
  VehicleMakeId: number;
  VehicleMakeName: string;
  Active: boolean;
  IsReferral: number;
  CRS_VEH_CODE: number;
}

interface Model {
  VehicleModelId: number;
  VehicleModelName: string;
  Active: boolean;
  SeatingCapacityExDriver: number;
  BodyTypeId: number;
  CRS_MODEL_CODE: number;
  EDATA_MODEL_CODE: string;
}

interface PlateCategory {
  label: string;
  value: string;
}

interface VichleColor {
  ColorId: number;
  ColorName: string;
  CRS_COLOR_CODE: string;
}

interface MortageBank {
  Id: number;
  InstituteName: string;
  InstituteCode: string;
  CRS_Bank_Map_Code: string;
}

interface Partners {
  PartnerName: string;
  PartnerId: number;
  BusinessSource: string;
}


export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-agentmotorquote",
  templateUrl: "./agentmotorquote.component.html",
  styleUrls: ["./agentmotorquote.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class AgentmotorquoteComponent implements OnInit {
  private digi_status = new Subject<string>();
  digi_status$ = this.digi_status.asObservable();
  digiSwalInstance:any;
  IsTPL:any  = false  //condn added to hide vehicle value field if it is TPL -ruhina
  /****************** selection search country ***********/

  /** control for the MatSelect filter keyword  */
  public country1FilterCtrl: FormControl = new FormControl();
  public nationalFilterCtrl: FormControl = new FormControl();
  public makeFilterCtrl: FormControl = new FormControl();
  public modelFilterCtrl: FormControl = new FormControl();

  public yearFilterCtrl: FormControl = new FormControl();
  public plateFilterCtrl: FormControl = new FormControl();
  public vchColorFilterCtrl: FormControl = new FormControl();
  public mortageBankFilterCtrl: FormControl = new FormControl();
  public codeFilterCtrl: FormControl = new FormControl();
  public partnerFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<
    Country[]
  >(1);
  public filteredNationCountries: ReplaySubject<Country[]> = new ReplaySubject<
    Country[]
  >(1);
  public filteredMakes: ReplaySubject<Make[]> = new ReplaySubject<Make[]>(1);
  public filteredModels: ReplaySubject<Model[]> = new ReplaySubject<Model[]>(1);
  public filteredYears: ReplaySubject<VehiModelYr[]> = new ReplaySubject<
    VehiModelYr[]
  >(1);
  public filteredPlateCat: ReplaySubject<PlateCategory[]> = new ReplaySubject<
    PlateCategory[]
  >(1);
  public filteredVchColor: ReplaySubject<VichleColor[]> = new ReplaySubject<
    VichleColor[]
  >(1);
  public filteredBank: ReplaySubject<MortageBank[]> = new ReplaySubject<
    MortageBank[]
  >(1);
  public filteredCode: ReplaySubject<Code[]> = new ReplaySubject<Code[]>(1);

  public filteredPartners: ReplaySubject<Partners[]> = new ReplaySubject<
  Partners[]
>(1);

  @ViewChild("singleSelect", { static: false }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  vehicleValueLimit = {
    isSet: false,
    startLimit: 0,
    mediumLimit: 0,
    endLimit: 0, 
  };

  insvehibys: any = [
    { value: "Individual", viewValue: "Individual", code: "100" },
    { value: "Corporate", viewValue: "Corporate", code: "200" },
  ];
  codes: Code[] = [
    { value: "050", viewValue: "050" },
    { value: "052", viewValue: "052" },
    { value: "054", viewValue: "054" },
    { value: "055", viewValue: "055" },
    { value: "056", viewValue: "056" },
    { value: "057", viewValue: "057" },
    { value: "058", viewValue: "058" },
  ];

  drivexps: DrivExp[] = [
    { value: "Yes", viewValue: "Yes" },
    { value: "No", viewValue: "No" },
  ];

  vehimodelyrs: VehiModelYr[] = [
    { value: "2025", label: "2025" },//added new model year - Anju
    { value: "2024", label: "2024" },//added new model year - Anju
    { value: "2023", label: "2023" }, //added new model year - ruhina
    { value: "2022", label: "2022" }, //added new Model year - ruhina
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
    { value: "2017", label: "2017" },
    { value: "2016", label: "2016" },
    { value: "2015", label: "2015" },
    { value: "2014", label: "2014" },
    { value: "2013", label: "2013" },
    { value: "2012", label: "2012" },
    { value: "2011", label: "2011" },
    { value: "2010", label: "2010" },
    { value: "2009", label: "2009" },
    { value: "2008", label: "2008" },
    { value: "2007", label: "2007" },
    { value: "2006", label: "2006" },
    { value: "2005", label: "2005" },
    { value: "2004", label: "2004" },
    { value: "2003", label: "2003" },
    { value: "2002", label: "2002" },
    { value: "2001", label: "2001" },
    { value: "2000", label: "2000" },
    { value: "1999", label: "1999" },
    { value: "1998", label: "1998" },
    { value: "1997", label: "1997" },
    { value: "1996", label: "1996" },
    { value: "1995", label: "1995" },
    { value: "1994", label: "1994" },
    { value: "1993", label: "1993" },
    { value: "1992", label: "1992" },
    { value: "1991", label: "1991" },
    { value: "1990", label: "1990" },
  ];
  brandnews: BrandNew[] = [
    { value: "Yes", viewValue: "Yes" },
    { value: "No", viewValue: "No" },
  ];
  vehiseatcaps: any = [
    { value: "0", viewValue: "0" },
    { value: "1", viewValue: "1" },
    { value: "3", viewValue: "3" },
    { value: "4", viewValue: "4" },
    { value: "5", viewValue: "5" },
    { value: "6", viewValue: "6" },
    { value: "7", viewValue: "7" },
    { value: "8", viewValue: "8" },
  ];
  // vehicylinders: VehiCylinder[] = [
  //   { value: '1', viewValue: '1 Cylinder', code: '1001' },
  //   { value: '2', viewValue: '2 Cylinder', code: '1002' },
  //   { value: '3', viewValue: '3 Cylinder', code: '1003' },
  //   { value: '4', viewValue: '4 Cylinder', code: '1004' },
  //   { value: '6', viewValue: '6 Cylinder', code: '1006' },
  //   { value: '8', viewValue: '8 Cylinder', code: '1008' },
  //   { value: '10', viewValue: '10 Cylinder', code: '1010' },
  //   { value: '12', viewValue: '12 Cylinder', code: '1012' },
  // ];
  // instypes: InsType[] = [
  //   { value: '100', viewValue: 'Comprehensive', WebProdCode: 'MT00061', CoreProdCode: '4001_1' },
  //   { value: '200', viewValue: 'Third Party Liability', WebProdCode: 'MTTPL0065', CoreProdCode: '4021' },
  // ];

  genders: Gender[] = [
    { value: "M", viewValue: "Male" },
    { value: "F", viewValue: "Female" },
  ];
  expmonths: ExpiryMonth[] = [
    { value: "EXPMON", viewValue: "January" },
    { value: "EXPMON", viewValue: "February" },
    { value: "EXPMON", viewValue: "March" },
    { value: "EXPMON", viewValue: "April" },
    { value: "EXPMON", viewValue: "May" },
    { value: "EXPMON", viewValue: "June" },
    { value: "EXPMON", viewValue: "July" },
    { value: "EXPMON", viewValue: "August" },
    { value: "EXPMON", viewValue: "September" },
    { value: "EXPMON", viewValue: "October" },
    { value: "EXPMON", viewValue: "November" },
    { value: "EXPMON", viewValue: "December" },
  ];

  NCDArr = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5+", label: "5+" },
  ];

  activePolicyTypeArr = [
    { id: 1,value: "Yes", label: "No  Insurance" },
    { id: 2,value: "No", label: "Comprehensive" },
    { id: 3,value: "Yes", label: "Third Party Liability" },
  ];

  // plateCatArray: PlateCategory[] = [
  //   { label: "Private", value: "Private" },
  //   { label: "Public (Driving Learning)", value: "Public (Driving Learning)" },
  //   { label: "Protocol", value: "Protocol" },
  //   { label: "Public (Transport/Taxi)", value: "Public (Transport/Taxi)" },
  //   { label: "Trade Plate", value: "Trade Plate" },
  //   { label: "Export", value: "Export" },
  //   { label: "Diplomatic Association", value: "Diplomatic Association" },
  //   { label: "International Organization", value: "International Organization" },
  //   { label: "Delegate", value: "Delegate" },
  //   { label: "Classical", value: "Classical" },
  //   { label: "Others", value: "Others" },
  // ];

  calculationby = [
    { label: "By Amount", value: "0" },
    { label: "By Percent", value: "1" },
  ];

  transactionTypeArray = [
    { value: "0", label: "New Vehicle" },
    { value: "1", label: "Renewal" },
    { value: "2", label: "Change in Ownership" },
  ];

  public policyStatus: any;
  formDataRes: any;
  partnersArr: any = [];
  partnerVal: any;
  partnerName: any;
  branchVal: any;
  partnerID: any;
  localStorDta: any;
  branchId: any;
  repairtypes = [];
  repairs: any[];
  repairtypes_: { value: string; viewValue: string }[];
  vehicylinders: any;
  expdateboolean: boolean = false;
  instypes: any;
  mobilevalue: any;
//added referral condition check start
  age: number;
  sumvalue: any;
  gccspec: any;
  vehiclemodified: any;
  drivingLicAge: any;
  prevNoIns: boolean;
  docProperty: string; // ruhina - added new docProperty for OCR
  discountType: any;
  showDiscount: boolean = true;
  selectedPartnerId: any;//added by Anju for Partner Credit Limit check
  selectedBranchId: any;//added by Anju for Partner Credit Limit check
  loggedInUserRole: string;
  executed: boolean;
  timer: boolean = true;
  showTimer: boolean = true;
  rSASelected: boolean;
  LDAmountOld: any;
  isLoadingDisabled:boolean = false;
  enableSurveyReport: boolean= false;
  enableAdditional: boolean= false;
//Added by Anju for User branch display
  userID: any;
  userBranchArr: any[];
  disabledbr: boolean = false;
  defaultBranchId: string;
  accept_disclaimer: boolean = false;
  policy_Wording_Url: string;
  isDiscountLoadingEnabled: boolean=false;
  changeVehicleValue: boolean=false;;
  isDeductibleEnabled: boolean=false;;

//added referral condition check end
  termsAndConditions(frame, type) {
 //DIGI Status check starts Feb2024
 this.digi_status$.subscribe(trackText => {
  this.digiSwalInstance= Swal.fire({
    title: '',
    html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,      
    showConfirmButton: false
  });
    });
   // this.digi_status.next("KYC verification is in progress.");
 var digiRequest={
  "QuotationNumber":this.webQuoteNumber,
  "FirstName": this.insuredDetailForm.value.e_name,
  "MiddleName": "",
  "LastName":'',
  "MatchCategory": "I",
  "Nationality":this.insuredDetailForm.value.e_nationality.CountryName,
  "DOB": "",
  "CustomerType": "I",
  "UserEmail": this.localStorDta.EmailAddress
};
this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
 // this.digi_status.next("Almost there.");
console.log(JSON.stringify(res));
if(res==null ||res.data==null ){
  Swal.fire("", "Failed to process the request. Please try again", 'error'); 
return;
}
else if(res.data.digiStatus=='Approved'){
  if (this.digiSwalInstance) {
    this.digiSwalInstance.close();
   }
if(this.totalFixPremium != 0){
    frame.show();
    this.policyStatus = type;
    this.getTermsConditions();
  }
  else{
Swal.fire("Total Premium cannot be Zero","Please create a new quotation and send for referral","error");
  }
  return;
}

else {
 
  if(config.enableDIGIApproval=="N"){
    this.digi_status.next("Almost there...");
    setTimeout(() => {
      this.digi_status.next("Please do not close the browser or refresh...");
  }, 2000);
    setTimeout(() => {
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
       if(this.totalFixPremium != 0){
        frame.show();
        this.policyStatus = type;
        this.getTermsConditions();
      }
      else{
    Swal.fire("Total Premium cannot be Zero","Please create a new quotation and send for referral","error");
      }
      return;
  }, 60000); 
  }
  else{  
     if (this.digiSwalInstance) {
    this.digiSwalInstance.close();
   }
    Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
    return;  
  }
}
// else{
//   if(res.data.recordExists==true){
//     Swal.fire("DIGI Approval Requested already", "You will be receiving a confirmation mail from compliance", 'error').then(result => {
//       this._route.navigateByUrl('home/retreivequote');
//     });
//   }
//    else{
//     Swal.fire("DIGI Approval Requested", "You will be receiving a confirmation mail from compliance", 'error').then(result => {
//       this._route.navigateByUrl('home/retreivequote');
//     });
//    }

// }
});
//DIGI Status check End Feb2024  
  }

  //DECLARE FORMGROUPS
  insuredDetailForm: FormGroup;
  quotationForm: FormGroup;

  //DECLARE ARRAYS
  vhclMakeArr: Make[];
  countryArr: Country[];
  vhclColorArr: VichleColor[];
  cityArr: any = [];
  vhclModelArr: Model[];
  vhclBodyTypeArr: any = [];
  vehitrims: any = [];
  tempRepairTypeArr: any = [];
  multilpleFile: any = [];
  quotationHistoryArr: any = [];
  coverData: any = [];
  NCDData: any = [];
  benfPremIdArray: any = [];
  savePlanDetailArr: any = [];
  quoteDetailArr: any = [];
  bankDetail: MortageBank[];
  quoteDetailDataForCUst: any = [];
  plateCodeArray: any = [];
  tempAdditionalDoc: any = [];
  document_data: any = [];
  vehicleData: any = [];
  tempDocArray: any = [];
  public termsAndCondition: any = [];
  retrieveData: any = [];
  tempBenefitData: any = [];
  tempMultipleDocData: any = [];
  additionalDocFile: any = [];
  multilpleFile1:any = [];
  plateCatArray: any = [];
  industryTypeArr: any = [];
  tempTransactionTypeArr: any = [];
  partnerBranchArr: any = [];

  //DECLARE VARIABLES
  public accept_terms: boolean = false;
  language_code = "ENG";
  country = "United Arab Emirates";
  docNameType: any;
  chassis_No_Details: any;
  invalidEID: boolean = false;
  value: any = 0;
  showAllDocImg: any = 0;
  showMultiQuotationImg: any = 0;
  webQuoteNumber: any = "";
  quoteNumber: any;
  public policyFee: number;
  VATAMT: any;
  Total_Primium: any;
  tempTotalFixPremium: number;
  totalFixPremium: number = 0;
  totalVariablePremium: number = 0;
  referalDescription: any = "";
  showAdditionalDocImg: any = 0;
  plan_Name: any;
  plan_Id: any;
  mulOption: any;
  optionalBenefit: any;
  totalPreimum: number = 0;
  localStorageData: any;
  validateMsg: boolean = false;
  nofDoors: any;
  getVechileDetailData: any;
  ImagedocumentType: any;
  plate_code: any;
  vhcleMake: any;
  onModelChange: boolean = false;
  validtnMsg: boolean = false;
  emailId: any;
  retrieveQuoteNumber: any = "";
  public showTerms: boolean = false;
  public editQuoteDocs: any;
  fileType: any;
  quoteDetail: any;
  loading_rate: any;
  public vatAMt: any;
  total_premium: any;
  policyNumber: any;
  vhcleValueFlag: boolean = false;
  public existVehiclevalue: any;
  loadingBy: any;
  loadingValue: any;
  checkStepper: boolean = false;
  accessGroup: any;
  quoteStatus: any;
  loadByAmount: any = 0;
  loadByPercent: any = 0;
  businessSource: any;
  partnerId: any;
  checkChassisValidation: boolean = false;
  SchemeCode: any;
  PaymentMode: any;
  tempTotalLoadFixPrem = 0;
  multipleImg: any = "";
  referalStatus: boolean = false;
  public userRole: any;
  userType: any;
  calculBy: any = "0";
  calculationAmt: any;
  public uploadMultipleDocs: boolean = false;
  uploadSingleDocs: boolean = false;
  referral_reason: any;
  refAdditionalCondition: any;
  refDescription: any = "";
  refer_condtion_type: any;
  refer_type: any;
  referalModel: boolean = false;
  fileUploadFlag: boolean = false;
  vehicle_age: any;
  isRenewal: any;
  initial_benefit_data: any;
  discount: any;
  GCC_Specification: any = "";
  public disValidate: boolean = false;
  public repairLoadingAmt: number;
  public LDAmount: any = "";
  LDType: any = "";
  LDRate: any = "";
  retrieve_repair_type = "";
  planBenefitIDforBronze: any = config.planBenefitID;
  issuePolicyBtnAccess: boolean = false;
  onlinePayBtnAccess: boolean = false;
  //Date
  todayDate = new Date(Date.now());
  policyMinDate = new Date(Date.now());
  policyMaxDate = new Date(new Date().setDate(new Date().getDate() +13)); //made 5 days instead of 13 -ruhina  //made 60 instead of 5
  toDay = new Date(Date.now());
  minyear = new Date().getFullYear() - 1;
  maxYear = new Date().getFullYear() + 1;
  regMinDate = new Date(2000, 0, 1);
  regMaxDate = new Date(2021, 12, 31); //max registration date changed to Dec 31,2021 by ruhina
  year = new Date().getFullYear();
  checkregExpDate = new Date(new Date().setDate(new Date().getDate() - 60));
  nextDate = new Date(Date.now() + 1);
  PrevDate = new Date(Date.now() - 2);
  eidMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
  eidMinDate = new Date(Date.now());
  // reg_exp_min_date = new Date(new Date().setDate(new Date().getDate() - 365));
// Earlier min date set to one year now made it to 3 years for testing by ruhina
  reg_exp_min_date = new Date(new Date().setDate(new Date().getDate() - 1000));
  reg_exp_max_date = new Date(new Date().setDate(new Date().getDate() + 90));
  // reg_exp_max_date = new Date(new Date().setDate(new Date().getDate() + 30));
  minDOB = new Date(new Date().setFullYear(new Date().getFullYear() - 150));
  //-ruhina - DOB set to 25 years
  maxDOB = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

 // maxDOB = new Date(Date.now());
  //prevInsMaxDate = new Date(new Date().setMonth(new Date().getMonth() + 2));
  // prevInsMaxDate = new Date(Date.now());
  prevInsMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 5)); //-ruhina - prev Insurate date must be always after  date
  previnsstartdate = this.prevInsMaxDate; //ruhina
  previnsmaxdate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)); //ruhina
  licExpMinDate = new Date(Date.now());
  licIssueMaxDate = new Date(Date.now());
  EIDExpiryminDate = new Date(Date.now());
  firstRegDate: any = new Date(Date.now() - 40);
  currentdate: any = new Date(Date.now());

  public hideImages = {
    reg_card_front: false,
    reg_card_back: false,
    emirates_Id_front: false,
    emirates_Id_back: false,
    driving_Lic_front: false,
    driving_Lic_back: false,
    supporting_Document: false,
    multipleDoc: false,
    additionalDoc: false,
    trade_Licence: false,
    TRN_Certificate: false,
  };

  public showLoader = {
    load_data : false, // added loader for initial page loading - ruhina
    reg_card_front: false,
    reg_card_back: false,
    emirates_Id_front: false,
    emirates_Id_back: false,
    driving_Lic_front: false,
    driving_Lic_back: false,
    Quotation: false,
    VehicleValue: false,
    vhclMake: false,
    vhclModel: false,
    vhclTrim: false,
    vhclBodyType: false,
    retrieveQuote: false,
    multipleDoc: false,
    supporting_Document: false,
    chasisNoButton: false,
    additionalDoc: false,
    referal: false,
    trade_Licence: false,
    TRN_Certificate: false,
    surveryReport:false
  };
  public chnageData = {
    rg_vhcl_make: "",
  };

  formatLabel(value: number) {
    if (value >= 5000) {
      return Math.round(value / 1000) + "k";
    }

    return value;
  }

  constructor(
    public _route: Router,
    public _activatedroute: ActivatedRoute,
    public issuepolicyService: IssuepolicyService,
    public motorQuoteService: MotorquoteService,
    public formBuilder: FormBuilder,
    public globalService: GlobalService,
    public issuePolicyService: IssuepolicyService,
    public travelInsuranceService:TraveInsuranceService
  ) {}

  ngOnInit() {
    this.showLoader.load_data = true // added loader for initial page loading - ruhina
    this.partnerName = this.globalService.getLocalStorageData();
    this.localStorDta = this.globalService.getLocalStorageData();
    const routeParams = this._activatedroute.snapshot.params;
    this.partnerID = this.localStorDta.Partner_ID;
    if (routeParams.quoteNum) {
      this.retrieveQuoteNumber = routeParams.quoteNum;
    }
    this.policy_Wording_Url = config.motor_terms_and_condition_pdf;
    this.checkPremiumEditPermission();
    this.getCylinder();
    this.localStorageData = this.globalService.getLocalStorageData();
    this.businessSource = this.localStorageData.BusinessSource;
    this.partnerId = this.localStorageData.Partner_ID;
    this.userID = this.localStorDta.User_ID;
    this.accessGroup = this.localStorageData.AccessGroup;
    this.userRole = this.localStorageData.UserRole;
    this.userType = this.localStorageData.UserType;
    this.SchemeCode = localStorage.getItem("SchemeCode");
    if (this.SchemeCode == "") {
      // this._route.navigateByUrl('agentMotor/motorquote');
    } else {
      this.getinsuredType();
    }
    this.tempTransactionTypeArr = this.transactionTypeArray;

    localStorage.removeItem("schedulelink");
    localStorage.removeItem("creditNote");
    localStorage.removeItem("debitNote");
    localStorage.removeItem("hirePurchase");
    localStorage.removeItem("Payment_Quotation_Number");
    localStorage.removeItem("CRS_Quotation_Number");
    localStorage.removeItem("Policy_Number");
    localStorage.removeItem("pageStatus");

    if (
      this.SchemeCode == null ||
      this.SchemeCode == "" ||
      this.SchemeCode == undefined
    ) {
      this._route.navigate(["agentMotor/motorquote"]);

    }
    if (
      this.businessSource == "DIRECT" &&
      this.partnerId == 1 &&
      this.userType == "POS" &&
      (this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') &&
      this.quoteStatus == "REFERRED"
    ) {
      this.vehimodelyrs.push(
        { value: "1989", label: "1989" },
        { value: "1988", label: "1988" },
        { value: "1987", label: "1987" },
        { value: "1986", label: "1986" },
        { value: "1985", label: "1985" },
        { value: "1984", label: "1984" },
        { value: "1983", label: "1983" },
        { value: "1982", label: "1982" },
        { value: "1981", label: "1981" },
        { value: "1980", label: "1980" }
      );
    }
    this.showLoader.reg_card_front = false;
    this.showLoader.reg_card_back = false;
    this.showLoader.emirates_Id_front = false;
    this.showLoader.emirates_Id_back = false;
    this.showLoader.driving_Lic_front = false;
    this.showLoader.driving_Lic_back = false;
    this.showLoader.retrieveQuote = false;
    this.showLoader.supporting_Document = false;
    this.showLoader.trade_Licence = false;
    this.showLoader.TRN_Certificate = false;

    this.quotationForm = this.formBuilder.group({
      issuePolicyStepper: ["", [Validators.required]],
    });

    this.insuredDetailForm = this.formBuilder.group({
      docRegCardFront: [""],
      reg_Card_Front: [""],
      reg_Card_FrontFilePath: "",
      reg_Card_FrontFileType: "",

      docSupporting: [""],
      supporting_Document: [""],
      supporting_DocumentFilePath: "",
      supporting_DocumentFileType: "",

      docRegCardBack: [""],
      reg_Card_Back: [""],
      reg_Card_BackFilePath: "",
      reg_Card_BackFileType: "",

      docEmiratedIdFront: [""],
      emirated_ID_Front: [""],
      emirated_ID_FrontFilePath: "",
      emirated_ID_FrontFileType: "",

      docEmiratedIdBack: [""],
      emirated_ID_Back: [""],
      emirated_ID_BackFilePath: "",
      emirated_ID_BackFileType: "",

      docDrivingLicFront: [""],
      driving_Lic_Front: [""],
      driving_Lic_FrontFilePath: "",
      driving_Lic_FrontFileType: "",

      docDrivingLicBack: [""],
      driving_Lic_Back: [""],
      driving_Lic_BackFilePath: "",
      driving_Lic_BackFileType: "",

      docTradeLicence: [""],
      trade_Licence: [""],
      trade_LicenceFilePath: "",
      trade_LicenceFileType: "",

      docTRNCertificate: [""],
      TRN_Certificate: [""],
      TRN_CertificateFilePath: "",
      TRN_CertificateFileType: "",

      MulkhiyaStatus: "Yes",
      oCRNeeded:"No", //Added by Anju to make OCR upload optional

      // reg card front form fields
      rg_model_year: ["", [Validators.required]],
      rg_num_passenger: ["", [Validators.required]],
      rg_origin: [""],
      rg_vhcl_make: ["", [Validators.required]],
      rg_vhcl_model: ["", [Validators.required]],
      rg_gvm: [""],
      rg_engin_num: ["", [Validators.required]],
      rg_chassis_num: [
        "",
        [
          Validators.required,
          Validators.maxLength(17),
          Validators.minLength(17),
        ],
      ],
      // reg card back form fields
      rg_traffic_plate_num: [""],
      rg_place_issue: ["", [Validators.required]],
      // Reg. Number added min and max validation by ruhina
      rg_TC_num: ["",  [Validators.required]],
      rg_type: ["", [Validators.required]],
      rg_plateCode: [""],
      rg_expiry_date: ["", [Validators.required]],
      rg_reg_date: ["", [Validators.required]],
      rg_ins_exp: ["", [Validators.required]],
      rg_policy_num: [""],
      rg_mortgage: [""],
      // eid front form fields
      e_eid: ["", [Validators.required]],
      e_name: ["", [Validators.required]],
      e_nationality: [""],
      e_cardNumber: [""],
      e_expiryDate: ["",[Validators.required]], //Added by Anju to make expiry date mandatory
      e_gender: [""],
      e_arabic_name: [""],
      // eid back form fields

      // driving lic front form fields
      d_driv_lic_num: [""],
      d_dob: ["",[
        Validators.required]],
      d_issue_date: [""],
      d_expiry_date: [""],
      d_place_issue: [""],
      d_TC_number: [""],
      d_lic_type: [""],

      // Additional detail fields /^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/   //'[050|052|054|055|056|057|058|059]{1}[0-9]{7}'
      adtnl_detail_email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+)[.]{1}[a-zA-Z]{2,}$"
          ),
        ]),
      ],
      // adtnl_detail_mbCode: ['', [Validators.required]],
      adtnl_detail_mobile: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(
            /^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/
          ),
        ],
      ],
      adtnl_detail_NCD: ["", [Validators.required]],
      adtnl_detail_repairTye: [""],
      adtnl_detail_vhclValue: [""],
      adtnl_detail_trim: ["", [Validators.required]],
      adtnl_detail_bodyType: ["", [Validators.required]],
      adtnl_detail_cylinder: ["", [Validators.required]],
      adtnl_detail_gccSpecified: ["", [Validators.required]],
      adtnl_detail_vhclModified: ["", [Validators.required]],
      adtnl_detail_brandNew: [""],
      adtnl_detail_address: [""],
      adtnl_detail_area: [""],
      adtnl_detail_poBoxNum: [""],
      adtnl_detail_insType: ["", [Validators.required]],
      isChassisValidate: [""],
      adtnl_detail_vhclOwnBy: ["", [Validators.required]],
      polStartDate: [new Date(Date.now()), Validators.required],
      vhclTPLCoverage: ["", [Validators.required]],
      promoCode: [""],
      vhclColor: [""],
      eIDCheck: ["", [Validators.required]],
      CC: [""],
      loading_capacity: [""],
      deductible: [""],

      //For Corporate : insured vehicle own by
      TRN_num: [""],
      trade_lic_num: [""],
      industry_type: [""],
      vat_register: ["No"],
     // transaction_type: ["", [Validators.required]], // Hide Transaction Type by Anju

      // Added Partner And Branch

      partner: ["", [Validators.required]],
      branch: ["", [Validators.required]],
    });

    // this.checkUserAccess();
    this.getAllFormData();
    // this.getVhclNCD();
    this.getIndustryTypes();

    this.insuredDetailForm.get("adtnl_detail_brandNew").setValue("0");

    //Removed transaction type validation by Anju
    // if (this.insuredDetailForm.value.adtnl_detail_brandNew == 1) {
    //   this.transactionTypeArray = [];
    //   this.transactionTypeArray.push(this.tempTransactionTypeArr[0]);
    //   this.insuredDetailForm
    //     .get("transaction_type")
    //     .setValue(this.transactionTypeArray[0]);
    // } else {
    //   this.transactionTypeArray = [];
    //   this.transactionTypeArray.push(this.tempTransactionTypeArr[1]);
    //   this.transactionTypeArray.push(this.tempTransactionTypeArr[2]);
    //   this.insuredDetailForm
    //     .get("transaction_type")
    //     .setValue(this.transactionTypeArray[0]);
    // }
    // this.onChangeTransactionType(
    //   this.insuredDetailForm.value.transaction_type.label
    // );

    if (this.SchemeCode == "106") {
      this.insuredDetailForm
        .get("adtnl_detail_insType")
        .setValue({
          CampaignCode: "CAMP1",
          CampaignId: 1,
          CampaignShortName: "Extended Insurance",
          CedantId: 3,
          CedantLogo: null,
          CedantName: "United Fidelity Insurance Company",
          CoreProdCode: "4021",
          ProductCode: "MTTPL0065",
          ProductDesc:
            "With innovative and all-round insurance plans and easy vehicle insurance renewals, you get covered with regards to all aspects that an ideal Car Insurance policy should have.",
          ProductId: 65,
          ProductImage: null,
          ProductName: "Motor TPL",
          ProductShortName: "THIRD PARTY LIABILITY",
          ProductType: "Individual",
          SchemeCode: "ALL",
          SchemeName: "Standard TPL",
          crsProdId: "200",
        });
    } else {
      this.insuredDetailForm
        .get("adtnl_detail_insType")
        .setValue({
          CampaignCode: "CAMP1",
          CampaignId: 1,
          CampaignShortName: "Extended Insurance",
          CedantId: 3,
          CedantName: "United Fidelity Insurance Company",
          CoreProdCode: "4001_1",
          ProductCode: "MT00061",
          ProductDesc:
            "With innovative and all-round insurance plans and easy vehicle insurance renewals, you get covered with regards to all aspects that an ideal Car Insurance policy should have.",
          ProductId: 61,
          ProductImage: null,
          ProductName: "Motor Comprehensive",
          ProductShortName: "COMPREHENSIVE",
          ProductType: "Individual",
          SchemeCode: "ALL",
          SchemeName: "Standard",
          crsProdId: "100",
        });
    }

    //-------------VALIDATION ON POLICY TYPE CHANGE --------------------------------//

    this.insuredDetailForm.controls[
      "adtnl_detail_insType"
    ].valueChanges.subscribe((obj) => {
      // if (obj.ProductShortName.toLowerCase() == 'comprehensive') {

      //   this.insuredDetailForm.controls['adtnl_detail_repairTye'].setValidators([Validators.required]);
      //    this.insuredDetailForm.controls['adtnl_detail_vhclValue'].setValidators([Validators.required, Validators.min(this.vehicleValueLimit.startLimit), Validators.max(this.vehicleValueLimit.endLimit)]);

      // }
      // else {

      //   this.insuredDetailForm.controls['adtnl_detail_repairTye'].setValidators(null);
      //   this.insuredDetailForm.controls['adtnl_detail_vhclValue'].setValidators(null);

      // }

      this.insuredDetailForm.controls[
        "adtnl_detail_repairTye"
      ].updateValueAndValidity();
      this.insuredDetailForm.controls[
        "adtnl_detail_vhclValue"
      ].updateValueAndValidity();
    });
       //Added By Anju for NCD validation
       this.insuredDetailForm.controls[
        "adtnl_detail_NCD"
      ].updateValueAndValidity();
  

    // this.insuredDetailForm.controls['rg_place_issue'].valueChanges.subscribe(obj => {
    //       if (obj.CityName == 'Dubai') {
    //             this.insuredDetailForm.controls['rg_TC_num'].setValidators([ Validators.minLength(8), Validators.maxLength(8),]);
    //             this.insuredDetailForm.controls['d_TC_number'].setValidators([ Validators.minLength(8), Validators.maxLength(8),]);
    //       }else{
    //           this.insuredDetailForm.controls['rg_TC_num'].setValidators([ Validators.minLength(10), Validators.maxLength(10),]);
    //           this.insuredDetailForm.controls['d_TC_number'].setValidators([ Validators.minLength(10), Validators.maxLength(10),]);
    //       }

    //       this.insuredDetailForm.controls['rg_TC_num'].updateValueAndValidity();
    //       this.insuredDetailForm.controls['d_TC_number'].updateValueAndValidity();
    // });

    this.insuredDetailForm.controls[
      "adtnl_detail_bodyType"
    ].valueChanges.subscribe((obj) => {
      // if bodytye is motorcycle 'CC' is mandatory --------------- 02 Nov 20
      if (obj.BodyTypeName == "MOTORCYCLE") {
        this.insuredDetailForm.controls["CC"].setValidators([
          Validators.required,
        ]);
        this.insuredDetailForm.controls["loading_capacity"].setValidators(null);
      }
      // if bodytye is Pickup 'loading_capacity' is mandatory ------------- 02 Nov 20
      else if (obj.BodyTypeName == "PICKUP") {
        this.insuredDetailForm.controls["CC"].setValidators(null);
        this.insuredDetailForm.controls["loading_capacity"].setValidators([
          Validators.required,
        ]);
      } else {
        this.insuredDetailForm.controls["CC"].setValidators(null);
        this.insuredDetailForm.controls["loading_capacity"].setValidators(null);
      }

      this.insuredDetailForm.controls["CC"].updateValueAndValidity();
      this.insuredDetailForm.controls[
        "loading_capacity"
      ].updateValueAndValidity();
    });
console.log("vehicle own by",this.insuredDetailForm.value.adtnl_detail_vhclOwnBy)
    this.insuredDetailForm.controls[
      "adtnl_detail_vhclOwnBy"
    ].valueChanges.subscribe((obj) => {
      if (obj.viewValue == "Corporate") {
        this.insuredDetailForm.controls["industry_type"].setValidators([
          Validators.required,
        ]);
        this.insuredDetailForm.controls["vat_register"].setValidators([
          Validators.required,
        ]);
        this.insuredDetailForm.controls["e_eid"].setValidators(null);
        this.insuredDetailForm.controls["d_dob"].setValidators(null);
        this.insuredDetailForm.controls["e_nationality"].setValidators(null);
        this.insuredDetailForm.controls["d_driv_lic_num"].setValidators(null);
        this.insuredDetailForm.controls["d_place_issue"].setValidators(null);
        this.insuredDetailForm.controls["d_issue_date"].setValidators(null);
        this.insuredDetailForm.controls["d_expiry_date"].setValidators(null);
       //this.insuredDetailForm.controls["d_TC_number"].setValidators(null);
        this.insuredDetailForm.controls["e_gender"].setValidators(null);
        this.insuredDetailForm.controls["e_expiryDate"].setValidators(null);
        this.insuredDetailForm.controls["trade_lic_num"].setValidators([
          Validators.required,
        ]);
        this.insuredDetailForm.controls["vat_register"].valueChanges.subscribe(
          (obj) => {
            if (obj == "Yes") {
              this.insuredDetailForm.controls["TRN_num"].setValidators([
                Validators.required,
              ]);
            } else {
              this.insuredDetailForm.controls["TRN_num"].setValidators(null);
            }
            this.insuredDetailForm.controls["TRN_num"].updateValueAndValidity();
          }
        );
      } else {
        this.insuredDetailForm.controls["industry_type"].setValidators(null);
        this.insuredDetailForm.controls["vat_register"].setValidators(null);
        this.insuredDetailForm.controls["d_dob"].setValidators([
          Validators.required,
        ]);
        this.insuredDetailForm.controls["e_nationality"].setValidators([
          Validators.required,
        ]);
        this.insuredDetailForm.controls["e_eid"].setValidators([
          Validators.required,
        ]);
        // this.insuredDetailForm.controls["d_driv_lic_num"].setValidators([
        //   Validators.required,
        // ]);
        // this.insuredDetailForm.controls["d_place_issue"].setValidators([
        //   Validators.required,
        // ]);
        this.insuredDetailForm.controls["d_issue_date"].setValidators([
          Validators.required,
         ]);
        // this.insuredDetailForm.controls["d_expiry_date"].setValidators([
        //   Validators.required,
        // ]);
        // this.insuredDetailForm.controls["d_TC_number"].setValidators([
        //   Validators.required,
        // ]);

        //Lic Expiry date Lic No ,Issue Place is non mandatory - ruhina - commenetd above code added null 
        this.insuredDetailForm.controls["d_place_issue"].setValidators(null);
       // this.insuredDetailForm.controls["d_issue_date"].setValidators(null);
        this.insuredDetailForm.controls["d_expiry_date"].setValidators(null);
        this.insuredDetailForm.controls["d_driv_lic_num"].setValidators(null);
        
        this.insuredDetailForm.controls["e_gender"].setValidators([
          Validators.required,
        ]);
        this.insuredDetailForm.controls["trade_lic_num"].setValidators(null);
      }

      this.insuredDetailForm.controls["industry_type"].updateValueAndValidity();
      this.insuredDetailForm.controls["vat_register"].updateValueAndValidity();
      this.insuredDetailForm.controls["d_dob"].updateValueAndValidity();
      this.insuredDetailForm.controls["e_nationality"].updateValueAndValidity();
      this.insuredDetailForm.controls["e_eid"].updateValueAndValidity();
      this.insuredDetailForm.controls[
        "d_driv_lic_num"
      ].updateValueAndValidity();
      this.insuredDetailForm.controls["d_place_issue"].updateValueAndValidity();
      this.insuredDetailForm.controls["d_issue_date"].updateValueAndValidity();
      this.insuredDetailForm.controls["d_expiry_date"].updateValueAndValidity();
      //this.insuredDetailForm.controls["d_TC_number"].updateValueAndValidity();
      this.insuredDetailForm.controls["e_gender"].updateValueAndValidity();
      this.insuredDetailForm.controls["trade_lic_num"].updateValueAndValidity();
      this.insuredDetailForm.controls["e_expiryDate"].updateValueAndValidity();
      
    });

    // listen for search field value changes
    this.country1FilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountry();
      });

    // listen for search field value changes
    this.nationalFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterNationalCountry();
      });

    this.makeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMake();
      });

    this.modelFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterModel();
      });

    this.filteredYears.next(this.vehimodelyrs.slice());

    this.yearFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterYear();
      });

    this.filteredPlateCat.next(this.plateCatArray.slice());

    this.plateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPlateCat();
      });

    this.vchColorFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterVechColor();
      });

    this.mortageBankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMortageBank();
      });

    this.filteredCode.next(this.codes.slice());

    this.codeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCode();
      });

      this.tempRepairTypeArr = this.repairtypes;    
              this.getQuotationFormData();

      //Added by Anju for Partner Filter

    this.partnerFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPartners();
      });


      if(this.partnerID == 1){
        this.disabledbr = false;
        }
        else{
          this.disabledbr = true;
        }    
  }

  ngOnDestroy() {
   
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get f() {
    return this.insuredDetailForm.controls;
  }
  //------------------------------------------ FOR DOCUMENTS UPLOAD (SINGLE)----------------------------------------------//
  onFileChange(event, docName, FiledName, files: FileList) {
    console.log(files);
    this.docNameType = FiledName;

    if (this.docNameType == "reg_Card_Front") {
      this.docProperty = 'Front'
      this.showLoader.reg_card_front = true;
    }

    if (this.docNameType == "reg_Card_Back") {
      this.docProperty = 'Back'
      this.showLoader.reg_card_back = true;
    }

    if (this.docNameType == "emirated_ID_Front") {
      this.docProperty = 'Front'
      this.showLoader.emirates_Id_front = true;
    }

    if (this.docNameType == "emirated_ID_Back") {
      this.docProperty = 'Back'
      this.showLoader.emirates_Id_back = true;
    }

    if (this.docNameType == "driving_Lic_Front") {
      this.docProperty = 'Front'
      this.showLoader.driving_Lic_front = true;
    }

    if (this.docNameType == "driving_Lic_Back") {
      this.docProperty = 'Back'
      this.showLoader.driving_Lic_back = true;
    }

    if (this.docNameType == "supporting_Document") {
      this.docProperty = 'Back'
      this.showLoader.supporting_Document = true;
    }

    if (this.docNameType == "trade_Licence") {
      this.docProperty = 'Back'
      this.showLoader.trade_Licence = true;
    }

    if (this.docNameType == "TRN_Certificate") {
      this.docProperty = 'Back'
      this.showLoader.TRN_Certificate = true;
    }

    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("docName", docName);
    formData.append("source", "B2B");
    formData.append("stage", "QUOTE");
    formData.append("schemeCode", this.SchemeCode);
    formData.append("docProperty",this.docProperty) // ruhina - added extra field for OCR
    // Array.from(files).forEach(f => formData.append('file', f))
   
    if( this.insuredDetailForm.value.oCRNeeded == "Yes") {
    this.motorQuoteService.uploadDocuments(formData).subscribe((res) => {
      let updateMemResponse = res;
      this.document_data = updateMemResponse.Document_data;
      this.document_data = this.document_data.Document_data //change in response format
      console.log("Data from Document ", this.document_data);

      if (updateMemResponse.res_code == 1) {
        this.showLoader.reg_card_front = false;
        this.showLoader.reg_card_back = false;
        this.showLoader.emirates_Id_front = false;
        this.showLoader.emirates_Id_back = false;
        this.showLoader.driving_Lic_front = false;
        this.showLoader.driving_Lic_back = false;
        this.showLoader.supporting_Document = false;
        this.showLoader.trade_Licence = false;
        this.showLoader.TRN_Certificate = false;

        let fileType = updateMemResponse.File.split(".");
        fileType = fileType[fileType.length - 1];
        fileType = fileType == "pdf" ? "PDF" : "IMG";

        let formArrayValue: any = this.insuredDetailForm.value;

        formArrayValue[FiledName] = updateMemResponse.File;
        formArrayValue[FiledName + "FilePath"] = updateMemResponse.FileDir;
        let tempDocumentArray = {
          file_name: FiledName,
          file_dir: updateMemResponse.FileDir,
          docName: updateMemResponse.File,
        };

        if (FiledName == "reg_Card_Front") {
          this.hideImages.reg_card_front = true;
          this.insuredDetailForm
            .get("reg_Card_FrontFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("reg_Card_Front")
            .setValue(updateMemResponse.File);
          this.insuredDetailForm
            .get("reg_Card_FrontFileType")
            .setValue(fileType);
          this.tempDocArray[1] = tempDocumentArray;
        }
        if (FiledName == "reg_Card_Back") {
          this.hideImages.reg_card_back = true;
          this.insuredDetailForm
            .get("reg_Card_BackFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("reg_Card_BackFileType")
            .setValue(fileType);
          this.tempDocArray[0] = tempDocumentArray;
        }
        if (FiledName == "emirated_ID_Front") {
          this.hideImages.emirates_Id_front = true;
          this.insuredDetailForm
            .get("emirated_ID_FrontFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("emirated_ID_FrontFileType")
            .setValue(fileType);
          this.tempDocArray[2] = tempDocumentArray;
        }
        if (FiledName == "emirated_ID_Back") {
          this.hideImages.emirates_Id_back = true;
          this.insuredDetailForm
            .get("emirated_ID_BackFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("emirated_ID_BackFileType")
            .setValue(fileType);
          this.tempDocArray[3] = tempDocumentArray;
        }
        if (FiledName == "driving_Lic_Front") {
          this.hideImages.driving_Lic_front = true;
          this.insuredDetailForm
            .get("driving_Lic_FrontFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("driving_Lic_FrontFileType")
            .setValue(fileType);
          this.tempDocArray[4] = tempDocumentArray;
        }

        if (FiledName == "driving_Lic_Back") {
          this.hideImages.driving_Lic_back = true;
          this.insuredDetailForm
            .get("driving_Lic_BackFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("driving_Lic_BackFileType")
            .setValue(fileType);
          this.tempDocArray[5] = tempDocumentArray;
        }

        if (FiledName == "supporting_Document") {
          this.hideImages.supporting_Document = true;
          this.insuredDetailForm
            .get("supporting_DocumentFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("supporting_DocumentFileType")
            .setValue(fileType);
          this.tempDocArray[6] = tempDocumentArray;
        }

        if (FiledName == "trade_Licence") {
          this.hideImages.trade_Licence = true;
          this.insuredDetailForm
            .get("trade_LicenceFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("trade_LicenceFileType")
            .setValue(fileType);
          this.tempDocArray[7] = tempDocumentArray;
        }

        if (FiledName == "TRN_Certificate") {
          this.hideImages.TRN_Certificate = true;
          this.insuredDetailForm
            .get("TRN_CertificateFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("TRN_CertificateFileType")
            .setValue(fileType);
          this.tempDocArray[8] = tempDocumentArray;
        }

        if (fileType == "pdf") {
          formArrayValue[FiledName + "FileType"] = "PDF";
        } else {
          formArrayValue[FiledName + "FileType"] = "IMG";
        }
        formArrayValue["docRegCardFront"] = "";
        formArrayValue["docRegCardBack"] = "";
        formArrayValue["docEmiratedIdFront"] = "";
        formArrayValue["docEmiratedIdBack"] = "";
        formArrayValue["docDrivingLicFront"] = "";
        formArrayValue["docDrivingLicBack"] = "";
        formArrayValue["docSupporting"] = "";
        formArrayValue["docTradeLicence"] = "";
        formArrayValue["docTRNCertificate"] = "";

        if (this.document_data.Errocode == 106) {
          if (FiledName == "reg_Card_Front") {
            this.hideImages.reg_card_front = false;
          }
          if (FiledName == "reg_Card_Back") {
            this.hideImages.reg_card_back = false;
          }
          if (FiledName == "emirated_ID_Front") {
            this.hideImages.emirates_Id_front = false;
          }
          if (FiledName == "emirated_ID_Back") {
            this.hideImages.emirates_Id_back = false;
          }
          if (FiledName == "driving_Lic_Front") {
            this.hideImages.driving_Lic_front = false;
          }
          if (FiledName == "driving_Lic_back") {
            this.hideImages.driving_Lic_back = false;
          }
          if (FiledName == "supporting_Document") {
            this.hideImages.supporting_Document = false;
          }
          if (FiledName == "trade_Licence") {
            this.hideImages.trade_Licence = false;
          }
          if (FiledName == "TRN_Certificate") {
            this.hideImages.TRN_Certificate = false;
          }

          //  Swal.fire("", this.document_data.ErrorDescription, "error");
          // Invalid document type selected or the document resolution is very low to read the data. Please upload another clean copy and try. Sorry for the inconvenience.
          Swal.fire(
            "Invalid document type selected or the document resolution is very low to read the data.",
            "Please upload another clean copy and try. Sorry for the inconvenience.",
            "error"
          );
          return false;
        }

        if (
          this.document_data.DocumentType == "Emirates-Id" &&
          this.document_data.DocumentSubType == "Front"
        ) {
          this.getEmiratesIdData(1, this.document_data);
        } else if (
          this.document_data.DocumentType == "Emirates-Id" &&
          this.document_data.DocumentSubType == "Back"
        ) {
          this.getEmiratesIdData(2, this.document_data);
        } else if (
          this.document_data.DocumentType == "Driving License" &&
          this.document_data.DocumentSubType == "Front"
        ) {
          this.getDrivingLicData(1, this.document_data);
        } else if (
          this.document_data.DocumentType == "Driving License" &&
          this.document_data.DocumentSubType == "Back"
        ) {
          this.getDrivingLicData(2, this.document_data);
        } else if (
          this.document_data.DocumentType == "Vehicle License" &&
          this.document_data.DocumentSubType == "Back"
        ) {
          this.insuredDetailForm.get("rg_vhcl_make").setValue("");
          this.insuredDetailForm.get("rg_vhcl_model").setValue("");
          this.insuredDetailForm.get("adtnl_detail_trim").setValue("");
          this.insuredDetailForm.get("adtnl_detail_bodyType").setValue("");

          this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
          console.log("vehicleData", this.vehicleData);
          this.getRegCardData(1, this.document_data);
        } else if (
          this.document_data.DocumentType == "Vehicle License" &&
          this.document_data.DocumentSubType == "Front"
        ) {
          this.getRegCardData(2, this.document_data);
        } else if (
          this.document_data.DocumentType == "Vehicle License" &&
          this.document_data.DocumentSubType == "Both"
        ) {
          this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
          console.log("Vehicle License", this.vehicleData);
          this.getRegCardData(1, this.document_data);
          this.getRegCardData(2, this.document_data);
        } else if (
          this.document_data.DocumentType == "Driving License" &&
          this.document_data.DocumentSubType == "Both"
        ) {
          this.getDrivingLicData(1, this.document_data);
          this.getDrivingLicData(2, this.document_data);
        } else if (
          this.document_data.DocumentType == "Emirates-Id" &&
          this.document_data.DocumentSubType == "Both"
        ) {
          this.getEmiratesIdData(1, this.document_data);
          this.getEmiratesIdData(2, this.document_data);
        } else if (this.document_data.DocumentType == "Other-Document") {
          this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
          this.getRegCardData(1, this.document_data);
        }
      }

      if (updateMemResponse.response_code == 5) {
        this.showLoader.reg_card_front = false;
        this.showLoader.reg_card_back = false;
        this.showLoader.emirates_Id_front = false;
        this.showLoader.emirates_Id_back = false;
        this.showLoader.driving_Lic_front = false;
        this.showLoader.driving_Lic_back = false;
        this.showLoader.supporting_Document = false;
        this.showLoader.trade_Licence = false;
        this.showLoader.TRN_Certificate = false;

        Swal.fire(
          "Please select valid file format.",
          "only .pdf, .jpg, .png and .jpeg file formats allowed.",
          "error"
        );
      }

      if (updateMemResponse.response_code == 6) {
        this.showLoader.reg_card_front = false;
        this.showLoader.reg_card_back = false;
        this.showLoader.emirates_Id_front = false;
        this.showLoader.emirates_Id_back = false;
        this.showLoader.driving_Lic_front = false;
        this.showLoader.driving_Lic_back = false;
        this.showLoader.supporting_Document = false;
        this.showLoader.trade_Licence = false;
        this.showLoader.TRN_Certificate = false;

        Swal.fire(updateMemResponse.response_status);
      }

      setTimeout(() => {
        this.onModelChange = true;
      }, 30000);
    });
  }
  else {
    this.motorQuoteService.uploadDocumentswithoutOCR(formData).subscribe((res) => {
      let updateMemResponse = res;
      
      if (updateMemResponse.response_code == 1) {
        this.showLoader.reg_card_front = false;
        this.showLoader.reg_card_back = false;
        this.showLoader.emirates_Id_front = false;
        this.showLoader.emirates_Id_back = false;
        this.showLoader.driving_Lic_front = false;
        this.showLoader.driving_Lic_back = false;
        this.showLoader.supporting_Document = false;
        this.showLoader.trade_Licence = false;
        this.showLoader.TRN_Certificate = false;

        let fileType = updateMemResponse.File.split(".");
        fileType = fileType[fileType.length - 1];
        fileType = fileType == "pdf" ? "PDF" : "IMG";

        let formArrayValue: any = this.insuredDetailForm.value;

        formArrayValue[FiledName] = updateMemResponse.File;
        formArrayValue[FiledName + "FilePath"] = updateMemResponse.FileDir;
        let tempDocumentArray = {
          file_name: FiledName,
          file_dir: updateMemResponse.FileDir,
          docName: updateMemResponse.File,
        };

        if (FiledName == "reg_Card_Front") {
          this.hideImages.reg_card_front = true;
          this.insuredDetailForm
            .get("reg_Card_FrontFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("reg_Card_Front")
            .setValue(updateMemResponse.File);
          this.insuredDetailForm
            .get("reg_Card_FrontFileType")
            .setValue(fileType);
          this.tempDocArray[1] = tempDocumentArray;
        }
        if (FiledName == "reg_Card_Back") {
          this.hideImages.reg_card_back = true;
          this.insuredDetailForm
            .get("reg_Card_BackFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("reg_Card_BackFileType")
            .setValue(fileType);
          this.tempDocArray[0] = tempDocumentArray;
        }
        if (FiledName == "emirated_ID_Front") {
          this.hideImages.emirates_Id_front = true;
          this.insuredDetailForm
            .get("emirated_ID_FrontFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("emirated_ID_FrontFileType")
            .setValue(fileType);
          this.tempDocArray[2] = tempDocumentArray;
        }
        if (FiledName == "emirated_ID_Back") {
          this.hideImages.emirates_Id_back = true;
          this.insuredDetailForm
            .get("emirated_ID_BackFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("emirated_ID_BackFileType")
            .setValue(fileType);
          this.tempDocArray[3] = tempDocumentArray;
        }
        if (FiledName == "driving_Lic_Front") {
          this.hideImages.driving_Lic_front = true;
          this.insuredDetailForm
            .get("driving_Lic_FrontFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("driving_Lic_FrontFileType")
            .setValue(fileType);
          this.tempDocArray[4] = tempDocumentArray;
        }

        if (FiledName == "driving_Lic_Back") {
          this.hideImages.driving_Lic_back = true;
          this.insuredDetailForm
            .get("driving_Lic_BackFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("driving_Lic_BackFileType")
            .setValue(fileType);
          this.tempDocArray[5] = tempDocumentArray;
        }

        if (FiledName == "supporting_Document") {
          this.hideImages.supporting_Document = true;
          this.insuredDetailForm
            .get("supporting_DocumentFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("supporting_DocumentFileType")
            .setValue(fileType);
          this.tempDocArray[6] = tempDocumentArray;
        }

        if (FiledName == "trade_Licence") {
          this.hideImages.trade_Licence = true;
          this.insuredDetailForm
            .get("trade_LicenceFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("trade_LicenceFileType")
            .setValue(fileType);
          this.tempDocArray[7] = tempDocumentArray;
        }

        if (FiledName == "TRN_Certificate") {
          this.hideImages.TRN_Certificate = true;
          this.insuredDetailForm
            .get("TRN_CertificateFilePath")
            .setValue(updateMemResponse.FileDir);
          this.insuredDetailForm
            .get("TRN_CertificateFileType")
            .setValue(fileType);
          this.tempDocArray[8] = tempDocumentArray;
        }

        if (fileType == "pdf") {
          formArrayValue[FiledName + "FileType"] = "PDF";
        } else {
          formArrayValue[FiledName + "FileType"] = "IMG";
        }
        formArrayValue["docRegCardFront"] = "";
        formArrayValue["docRegCardBack"] = "";
        formArrayValue["docEmiratedIdFront"] = "";
        formArrayValue["docEmiratedIdBack"] = "";
        formArrayValue["docDrivingLicFront"] = "";
        formArrayValue["docDrivingLicBack"] = "";
        formArrayValue["docSupporting"] = "";
        formArrayValue["docTradeLicence"] = "";
        formArrayValue["docTRNCertificate"] = "";

        if (this.document_data.Errocode == 106) {
          if (FiledName == "reg_Card_Front") {
            this.hideImages.reg_card_front = false;
          }
          if (FiledName == "reg_Card_Back") {
            this.hideImages.reg_card_back = false;
          }
          if (FiledName == "emirated_ID_Front") {
            this.hideImages.emirates_Id_front = false;
          }
          if (FiledName == "emirated_ID_Back") {
            this.hideImages.emirates_Id_back = false;
          }
          if (FiledName == "driving_Lic_Front") {
            this.hideImages.driving_Lic_front = false;
          }
          if (FiledName == "driving_Lic_back") {
            this.hideImages.driving_Lic_back = false;
          }
          if (FiledName == "supporting_Document") {
            this.hideImages.supporting_Document = false;
          }
          if (FiledName == "trade_Licence") {
            this.hideImages.trade_Licence = false;
          }
          if (FiledName == "TRN_Certificate") {
            this.hideImages.TRN_Certificate = false;
          }

          //  Swal.fire("", this.document_data.ErrorDescription, "error");
          // Invalid document type selected or the document resolution is very low to read the data. Please upload another clean copy and try. Sorry for the inconvenience.
          Swal.fire(
            "Invalid document type selected or the document resolution is very low to read the data.",
            "Please upload another clean copy and try. Sorry for the inconvenience.",
            "error"
          );
          return false;
        }

      if (updateMemResponse.response_code == 5) {
        this.showLoader.reg_card_front = false;
        this.showLoader.reg_card_back = false;
        this.showLoader.emirates_Id_front = false;
        this.showLoader.emirates_Id_back = false;
        this.showLoader.driving_Lic_front = false;
        this.showLoader.driving_Lic_back = false;
        this.showLoader.supporting_Document = false;
        this.showLoader.trade_Licence = false;
        this.showLoader.TRN_Certificate = false;

        Swal.fire(
          "Please select valid file format.",
          "only .pdf, .jpg, .png and .jpeg file formats allowed.",
          "error"
        );
      }

      if (updateMemResponse.response_code == 6) {
        this.showLoader.reg_card_front = false;
        this.showLoader.reg_card_back = false;
        this.showLoader.emirates_Id_front = false;
        this.showLoader.emirates_Id_back = false;
        this.showLoader.driving_Lic_front = false;
        this.showLoader.driving_Lic_back = false;
        this.showLoader.supporting_Document = false;
        this.showLoader.trade_Licence = false;
        this.showLoader.TRN_Certificate = false;

        Swal.fire(updateMemResponse.response_status);
      }
    }
    });
  

  }
  
  }
  //-------------------------------------------- MULTIPLE DOCUMENTS ---------------------------------------//
  uploadMultipleDocuments(event) {
    this.showLoader.multipleDoc = true;

    this.multilpleFile = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.showLoader.multipleDoc = true;
      const formData = new FormData();
      formData.append("file", event.target.files[i]);
      formData.append("stage", "QUOTE");
      formData.append("schemeCode", this.SchemeCode);

      this.motorQuoteService
        .uploadMultipleDocuments(formData)
        .subscribe((res) => {
          let updateMemResponse = res;
          this.document_data = updateMemResponse.Document_data;
          console.log("Document Type",updateMemResponse)
          console.log("Multiple document data ", this.document_data);

          this.hideImages.multipleDoc = true;

          if (updateMemResponse.res_code == 1) {
            let fileType = updateMemResponse.File.split(".");
            fileType = fileType[fileType.length - 1];
            fileType = fileType == "pdf" ? "PDF" : "IMG";

            this.multilpleFile.push({
              file: updateMemResponse.FileDir,
              fileType: fileType,
              file_name: this.document_data[0].DocumentType,
              // file_name: "Other",
              file_dir: updateMemResponse.FileDir,
              docName: updateMemResponse.File,
            });

            console.log("Multiple File",this.multilpleFile)
            this.tempMultipleDocData = this.multilpleFile;

            this.showLoader.multipleDoc = false;

            this.document_data.forEach((item, index) => {
              if (item.ErroCode == "Null") {
                if (
                  item.DocumentType == "Emirates-Id" &&
                  item.DocumentSubType == "Front"
                ) {
                  this.getEmiratesIdData(1, item);
                } else if (
                  item.DocumentType == "Emirates-Id" &&
                  item.DocumentSubType == "Back"
                ) {
                  this.getEmiratesIdData(2, item);
                } else if (
                  item.DocumentType == "Driving License" &&
                  item.DocumentSubType == "Front"
                ) {
                  this.getDrivingLicData(1, item);
                } else if (
                  item.DocumentType == "Driving License" &&
                  item.DocumentSubType == "Back"
                ) {
                  this.getDrivingLicData(2, item);
                } else if (
                  item.DocumentType == "Vehicle License" &&
                  item.DocumentSubType == "Back"
                ) {
                  this.vehicleData =
                    updateMemResponse.vechile_data.VehicleDetails;

                  // if(item["Chassis No"].length < 17 || item["Chassis No"] == '' || item["Chassis No"] == 'Not Found'){
                  //   //
                  console.log("Vehicle Data",this.vehicleData)

                  // }else{
                  this.getRegCardData(1, item);
                  //}
                } else if (
                  item.DocumentType == "Vehicle License" &&
                  item.DocumentSubType == "Front"
                ) {
                  this.getRegCardData(2, item);
                } else if (
                  item.DocumentType == "Vehicle License" &&
                  item.DocumentSubType == "Both"
                ) {
                  this.getRegCardData(2, item);
                  this.vehicleData =
                    updateMemResponse.vechile_data.VehicleDetails;
                  this.getRegCardData(1, item);
                } else if (
                  item.DocumentType == "Driving License" &&
                  item.DocumentSubType == "Both"
                ) {
                  this.getDrivingLicData(2, item);
                  this.getDrivingLicData(1, item);
                } else if (
                  item.DocumentType == "Emirates-Id" &&
                  item.DocumentSubType == "Both"
                ) {
                  this.getEmiratesIdData(2, item);
                  this.getEmiratesIdData(1, item);
                }
              }
            });
          }
          if (updateMemResponse.res_code == 2 )
          { 
            this.showLoader.multipleDoc = false;
            Swal.fire(
              "Document uploaded is not readable",
              "Please fill the data manually or upload each document seperately",
              "error"
            );
          }
          if (updateMemResponse.response_code == 5) {
            this.showLoader.multipleDoc = false;

            Swal.fire(
              "Please select valid file format.",
              "only .pdf, .jpg, .png and .jpeg file formats allowed.",
              "error"
            );
          }
          if (updateMemResponse.response_code == 3) {
            this.showLoader.multipleDoc = false;

            Swal.fire(
              "Please upload valid format.",
              "Data is not readable.Please try again or Fill form manually",
              "error"
            );
          }
          if (updateMemResponse.response_code == 6) {
            this.showLoader.multipleDoc = false;

            Swal.fire(updateMemResponse.response_status);
          }
        });
    }
  }

  //------------------------------------------- UPLOAD ADDITIONAL DOCUMENTS ---------------------------------//
  uploadAdditionalDoc(event) {
    this.showLoader.additionalDoc = true;

    this.additionalDocFile = [];
    for (var i = 0; i < event.target.files.length; i++) {
      const formData = new FormData();
      formData.append("file", event.target.files[i]);
      formData.append("stage", "QUOTE_ADDINFO");
      formData.append("quotation_number", this.webQuoteNumber);

      this.motorQuoteService
        .uploadMultipleDocuments(formData)
        .subscribe((res) => {
          let updateMemResponse = res;
          this.document_data = updateMemResponse.Document_data;

          this.hideImages.additionalDoc = true;

          if (updateMemResponse.res_code == 1 || updateMemResponse.res_code == 2 ) {
            let fileType = updateMemResponse.File.split(".");
            fileType = fileType[fileType.length - 1];
            fileType = fileType == "pdf" ? "PDF" : "IMG";

            this.additionalDocFile.push({
              file: updateMemResponse.FileDir,
              fileType: fileType,
              file_name: "Additional_Doc",
              file_dir: updateMemResponse.FileDir,
              docName: updateMemResponse.File,
            });

            this.tempAdditionalDoc = this.additionalDocFile;

            this.showLoader.additionalDoc = false;
          }
          if (updateMemResponse.response_code == 5) {
            this.showLoader.additionalDoc = false;
            Swal.fire(
              "Please select valid file format.",
              "only .pdf, .jpg, .png and .jpeg file formats allowed.",
              "error"
            );
          }

          if (updateMemResponse.response_code == 6) {
            this.showLoader.additionalDoc = false;
            Swal.fire(updateMemResponse.response_status);
          }
        });
    }
  }

  getCylinder() {
    this.motorQuoteService.getCylinderData().subscribe((res) => {
      this.vehicylinders = res.cylinder;
    });
  }
  getRegCardData(type, docData) {
    console.log("Vehicle General Data", this.vehicleData);
    console.log("Vehicle  License Back ", type, docData);
    if (type == 1) {
      this.document_data.Data =
        docData.Data != "" ? docData.Data : this.document_data.Data;

      if (
        this.document_data.Data["Chassis No."] == "Not Found" ||
        this.vehicleData.StatusCode == 422
      ) {
        this.insuredDetailForm.get("rg_chassis_num").setValue("");
        this.insuredDetailForm.get("isChassisValidate").setValue("");
      } else {
        this.checkChassisValidation = true;
        this.insuredDetailForm
          .get("rg_chassis_num")
          .setValue(this.document_data.Data["Chassis No."]);
        this.insuredDetailForm.get("isChassisValidate").setValue("1");
      }

      //GCC
      this.GCC_Specification = this.vehicleData.General.Region;
      if (
        this.vehicleData.General.Region != null &&
        this.vehicleData.General.Region != ""
      ) {
        let gcc_specification =
          this.vehicleData.General.Region == "GCC" ? "No" : "Yes";
        this.insuredDetailForm
          .get("adtnl_detail_gccSpecified")
          .setValue(gcc_specification);
      } else {
        this.insuredDetailForm.get("adtnl_detail_gccSpecified").setValue("");
      }

      if (this.document_data.Data["G. V. W."] == "Not Found") {
        this.insuredDetailForm.get("rg_gvm").setValue("");
      } else {
        this.insuredDetailForm
          .get("rg_gvm")
          .setValue(this.document_data.Data["G. V. W."]);
      }

      let enginNumber = this.document_data.Data["Eng. No."];
      this.insuredDetailForm.get("rg_engin_num").setValue(enginNumber.trim());

      this.nofDoors = this.vehicleData.General.NoOfDoors;
      //this.insuredDetailForm.get('rg_vhcl_model').setValue(this.document_data.Data['Expiry Date']);

      this.fileUploadFlag = true;
      //Model year
      //let modelYear = this.document_data.Data.Model;
      let modelYear = this.vehicleData.General.ModelYear;
      console.log("modelyear", modelYear);
      console.log("Vehimodelyrs", this.vehimodelyrs);
      var indexMYear = this.vehimodelyrs.findIndex(function (obj, k) {
        return obj.label === modelYear;
      });
      if(indexMYear == -1)
      {
        var yearVal = this.vehimodelyrs[0];
      }
      else{
        var yearVal = this.vehimodelyrs[indexMYear];
      } // added above logic to avoid data discrepancy - ruhina
      //let yearVal = this.vehimodelyrs[indexMYear];
      //Added Model Year value --Ruhina
      // let yearVal = docData.Data.Model
      console.log("YearVal", yearVal);
      this.insuredDetailForm.get("rg_model_year").setValue(yearVal);
      console.log(this.insuredDetailForm);

      // this.chnageData.rg_vhcl_make = this.document_data.Data["Veh. Type"];
      this.chnageData.rg_vhcl_make = this.document_data.Data["Veh. Type"];
      // this.vehicleData.General.Make

      // this.getVhclMakeData(this.document_data.Data["Veh. Type"], yearVal, 2);
      this.getVhclMakeData(this.vehicleData.General.Make, yearVal, 2);

      //NO OF PAASENGER
      let numOfPass = this.vehicleData.General.NoOfSeats;
      var iPassenger = this.vehiseatcaps.findIndex(function (obj, k) {
        return obj.viewValue == numOfPass - 1;
      });

      let passengerVal = this.vehiseatcaps[iPassenger];
      this.insuredDetailForm.get("rg_num_passenger").setValue(passengerVal);

      //ORIGIN
      let origin = this.document_data.Data["Origin"];
      var indexOrin = this.countryArr.findIndex(function (obj, k) {
        return obj.CountryName === origin;
      });
      let originVal = this.countryArr[indexOrin];
      this.insuredDetailForm.get("rg_origin").setValue(originVal);

      //cylinders

      let cylVal;
      let cylindervalue = this.vehicleData.Technical.EngineCylinders + ' Cylinders'
      this.vehicylinders.forEach((item, index) => {   //changed to cylindername -ruhina
        if (
          item.CylinderName ==
          cylindervalue
        )
      // this.vehicylinders.forEach((item, index) => {
      //   if (item.Id == this.vehicleData.Technical.EngineCylinders) 
        {
          cylVal = item;
        }
      });

      this.insuredDetailForm.get("adtnl_detail_cylinder").setValue(cylVal);
    }

    if (type == 2) {
      this.document_data.Data =
        docData.Data != "" ? docData.Data : this.document_data.Data;

      let dateExp: string = this.document_data.Data["Exp. Date"];
      dateExp = dateExp.replace(/-/gi, "/");
      let dateReg: string = this.document_data.Data["Reg. Date"];
      dateReg = dateReg.replace(/-/gi, "/");
      let dateInsp: string = this.document_data.Data["Ins. Exp."];
      dateInsp = dateInsp.replace(/-/gi, "/");
      let strTCNum: string = this.document_data.Data["T. C. No."];

      if (this.document_data.Data["Exp. Date"] != "Not Found")
        this.insuredDetailForm
          .get("rg_expiry_date")
          .setValue(this.dateConvert(dateExp));
      else {
        this.insuredDetailForm.get("rg_expiry_date").setValue("");
      }

      if (this.document_data.Data["Ins. Exp."] != "Not Found")
        this.insuredDetailForm
          .get("rg_ins_exp")
          .setValue(this.dateConvert(dateInsp));
      else {
        this.insuredDetailForm.get("rg_ins_exp").setValue("");
      }

      if (this.document_data.Data["Reg. Date"] != "Not Found")
        this.insuredDetailForm
          .get("rg_reg_date")
          .setValue(this.dateConvert(dateReg));
      else {
        this.insuredDetailForm.get("rg_reg_date").setValue("");
      }

      this.insuredDetailForm
        .get("rg_policy_num")
        .setValue(this.document_data.Data["Policy No."]);

      this.insuredDetailForm
        .get("rg_TC_num")
        .setValue(this.document_data.Data["T. C. No."]);
      this.insuredDetailForm
        .get("rg_traffic_plate_num")
        .setValue(this.document_data.Data["Traffic Plate No."]);

      // if (this.insuredDetailForm.value.d_TC_number == "") {
      //   this.insuredDetailForm
      //     .get("d_TC_number")
      //     .setValue(this.insuredDetailForm.value.rg_TC_num);
      // }

      let plate_code_array =
        this.document_data.Data["Traffic Plate No."].split("/");

      //Mortgage Bank
      if (this.document_data.Data["Mortgage By"] == "Not Found") {
        this.insuredDetailForm.get("rg_mortgage").setValue("");
      } else {
        let mBank = this.document_data.Data["Mortgage By"];
        var indexBank = this.bankDetail.findIndex(function (obj, k) {
          return obj.InstituteName.toLowerCase() === mBank.toLowerCase();
        });
        let bankVal = this.bankDetail[indexBank];
        this.insuredDetailForm.get("rg_mortgage").setValue(bankVal);
      }
      //REG PLACE
      let regPlace = this.document_data.Data["Place of Issue"];
      this.plate_code =
        this.document_data.Data["Plate_code"] != ""
          ? this.document_data.Data["Plate_code"]
          : plate_code_array[0];

      if (strTCNum.length == 8) {
        var indexRegPlace = this.cityArr.findIndex(function (obj, k) {
          return obj.CityName === "Dubai";
        });
        let placelVal = this.cityArr[indexRegPlace];
        this.insuredDetailForm.get("rg_place_issue").setValue(placelVal);
        this.getPlateCode(placelVal.CityName, 3);
      } else if (
        this.document_data.Data["Place of Issue"] != "Not Found" &&
        strTCNum.length != 8
      ) {
        console.log("City Array",this.cityArr)
        var indexRegPlace = this.cityArr.findIndex(function (obj, k) {
          return obj.CityName.toLowerCase() === regPlace.toLowerCase();
        });
        let placelVal = this.cityArr[indexRegPlace];
        this.insuredDetailForm.get("rg_place_issue").setValue(placelVal);
        this.getPlateCode(placelVal.CityName, 3);
      } else {
        this.insuredDetailForm.get("rg_place_issue").setValue("");
      }

      //REG TyPE == PLATE CATEGORY
      if (
        this.document_data.Data["Registration Type"] == "Not Found" ||
        this.document_data.Data["Registration Type"] == null ||
        this.document_data.Data["Registration Type"] == ""
      ) {
        this.insuredDetailForm.get("rg_type").setValue("");
      } else {
        let regType = this.document_data.Data["Registration Type"];
        var indexRegType = this.plateCatArray.findIndex(function (obj, k) {
          return obj.value.toLowerCase() === regType.toLowerCase();
        });
        let typelVal = this.plateCatArray[indexRegType];
        this.insuredDetailForm.get("rg_type").setValue(typelVal.value);
      }
    }
    // let yearVal = this.vehimodelyrs[indexMYear];
    // this.insuredDetailForm.get('rg_model_year').setValue(yearVal);

    // this.chnageData.rg_vhcl_make = this.vehicleData.General.Make;

    // this.getVhclMakeData(this.vehicleData.General.Make, yearVal, 2);
  }

  getDrivingLicData(type, docData) {
    if (type == 1) {
      this.document_data.Data =
        docData.Data != "" ? docData.Data : this.document_data.Data;

      let dateDob: string = this.document_data.Data["Date of Birth"];
      dateDob = dateDob.replace(/-/gi, "/");
      let dateIssue: string = this.document_data.Data["Issue Date"];
      dateIssue = dateIssue.replace(/-/gi, "/");
      let dateExp: string = this.document_data.Data["Expiry Date"];
      dateExp = dateExp.replace(/-/gi, "/");

      if (this.document_data.Data["License No."] != "Not Found") {
        this.insuredDetailForm
          .get("d_driv_lic_num")
          .setValue(this.document_data.Data["License No."]);
      } else {
        this.insuredDetailForm.get("d_driv_lic_num").setValue("");
      }

      // else {
      //     this.insuredDetailForm.get('d_dob').setValue('');
      // }

      if (this.document_data.Data["Issue Date"] != "Not Found")
        this.insuredDetailForm
          .get("d_issue_date")
          .setValue(this.dateConvert(dateIssue));
      else {
        this.insuredDetailForm.get("d_issue_date").setValue("");
      }

      if (this.document_data.Data["Expiry Date"] != "Not Found")
        this.insuredDetailForm
          .get("d_expiry_date")
          .setValue(this.dateConvert(dateExp));
      else {
        this.insuredDetailForm.get("d_expiry_date").setValue("");
      }

      // Driving Reg. Place
      let regPlace = this.document_data.Data["Place of Issue"];
      if (regPlace != "Not Found" && regPlace != "" && regPlace != null) {
        var indexRegPlace = this.cityArr.findIndex(function (obj, k) {
          return obj.CityName.toLowerCase() === regPlace.toLowerCase();
        });
        let placelVal = this.cityArr[indexRegPlace];
        this.insuredDetailForm.get("d_place_issue").setValue(placelVal);
        this.getIssuePlace(placelVal.CityName);
      }

      // 11 - 02 -2021 : If emirates id file not uploaded then name should be taken from driving lic file
      if (this.insuredDetailForm.value.e_name == "") {
        if (this.document_data.Data["Name"] == "Not Found") {
          this.insuredDetailForm.get("e_name").setValue("");
        } else {
          this.insuredDetailForm
            .get("e_name")
            .setValue(this.document_data.Data["Name"]);
        }
      }
      if (this.insuredDetailForm.value.e_nationality == "") {
        // Nationality
        let nationality = this.document_data.Data["Nationality"];
        var indexNationality = this.countryArr.findIndex(function (obj, k) {
          console.log("CountryName",obj.CountryName,nationality)
          console.log("Nationality",nationality)
          return obj.CountryName === nationality;
        });
        let nationalVal = this.countryArr[indexNationality];
        this.insuredDetailForm.get("e_nationality").setValue(nationalVal);
      }
      if (this.insuredDetailForm.value.d_dob == "") {
        if (
          this.document_data.Data["Date of Birth"] != "Not Found" &&
          this.insuredDetailForm.value.d_dob == ""
        )
          this.insuredDetailForm
            .get("d_dob")
            .setValue(this.dateConvert(dateDob));
      }
    }
    if (type == 2) {
      this.document_data.Data =
        docData.Data != "" ? docData.Data : this.document_data.Data;

      let licType = this.document_data.Data["Permitted Vehicles"][0].Type;

      this.insuredDetailForm.get("d_lic_type").setValue(licType);

      if (this.document_data.Data["Traffic Code No."] != "Not Found") {
        this.insuredDetailForm
          .get("d_TC_number")
          .setValue(this.document_data.Data["Traffic Code No."].trim());
      } else {
        //this.insuredDetailForm.get("d_TC_number").setValue("");
      }
    }
  }

  getEmiratesIdData(type, docData) {
    if (type == 1) {
      this.document_data.Data =
        docData.Data != "" ? docData.Data : this.document_data.Data;

      // if(this.document_data.Data['ID Number']=="Not Found" && this.document_data.Data['ID-NumberInArabic']!=''){ alert(1);
      //   this.motorQuoteService.translateInEnglish(this.document_data.Data['ID-NumberInArabic']).subscribe(res => {

      //   });
      // }

      this.insuredDetailForm
        .get("e_arabic_name")
        .setValue(this.document_data.Data["NameInArabic"]);

      if (this.document_data.Data["ID Number"] == "Not Found") {
        this.insuredDetailForm.get("e_eid").setValue("");
      } else {
        this.insuredDetailForm
          .get("e_eid")
          .setValue(this.document_data.Data["ID Number"]);
      }

      if (this.document_data.Data["Name"] == "Not Found") {
        this.insuredDetailForm.get("e_name").setValue("");
      } else {
        this.insuredDetailForm
          .get("e_name")
          .setValue(this.document_data.Data["Name"]);
      }

      // Nationality
      let nationality = this.document_data.Data["Nationality"];
      var indexNationality = this.countryArr.findIndex(function (obj, k) {
        console.log("CountryName",obj.CountryName,nationality)
        //if countryname is almost equal - ruhina
        // if(nationality.includes(obj.CountryName))
        // {
        //   return obj.CountryName
        // }
        return obj.CountryName === nationality;
      });
      let nationalVal = this.countryArr[indexNationality];
      this.insuredDetailForm.get("e_nationality").setValue(nationalVal);
    }

    if (type == 2) {
      this.document_data.Data =
        docData.Data != "" ? docData.Data : this.document_data.Data;

      let dateDob: string = this.document_data.Data["Date of Birth"];
      dateDob = dateDob.replace(/-/gi, "/");

      let dateExp: string = this.document_data.Data["Expiry Date"];
      dateExp = dateExp.replace(/-/gi, "/");

      this.insuredDetailForm
        .get("e_cardNumber")
        .setValue(this.document_data.Data["Card Number"]);
      this.insuredDetailForm
        .get("e_expiryDate")
        .setValue(this.dateConvert(dateExp));

      let gender = this.document_data.Data["Sex"];
      //Gender
      
      var indexGender = this.genders.findIndex(function (obj, k) {
        return obj.value === gender;
      });
      let gnderVal = this.genders[indexGender];
      console.log("gender",gnderVal)
      this.insuredDetailForm.get("e_gender").setValue(gnderVal);

      if (
        this.document_data.Data["Date of Birth"] != "Not Found" &&
        this.document_data.Data["Date of Birth"] != ""
      )
        this.insuredDetailForm.get("d_dob").setValue(this.dateConvert(dateDob));
    }
  }

  dateConvert(inputFormat) {
    if(inputFormat == null || inputFormat == '') //added date input null check to avoid date issues
    {
      return ''
    }
    else
    {
      let vDOEntryArray = inputFormat.split("/");
      let DOEntry = new Date();
      DOEntry.setDate(vDOEntryArray[0]);
      DOEntry.setMonth(vDOEntryArray[1] - 1);
      DOEntry.setFullYear(vDOEntryArray[2]);
  
      return DOEntry;
    }
    
  }

  checkTermsCond() {
    this.accept_terms = !this.accept_terms;
  }

  checkDisclaimer() {
    this.accept_disclaimer = !this.accept_disclaimer;
  }

  closeImg(type) {
    if (type == 1) {
      this.hideImages.reg_card_front = false;
      this.insuredDetailForm.get("reg_Card_Front").setValue("");
      this.insuredDetailForm.get("reg_Card_FrontFilePath").setValue("");
      this.insuredDetailForm.get("rg_model_year").setValue("");
      this.insuredDetailForm.get("rg_num_passenger").setValue("");
      this.insuredDetailForm.get("rg_origin").setValue("");
      this.insuredDetailForm.get("rg_vhcl_make").setValue("");
      this.insuredDetailForm.get("rg_vhcl_model").setValue("");
      this.insuredDetailForm.get("rg_gvm").setValue("");
      this.insuredDetailForm.get("rg_chassis_num").setValue("");
      this.insuredDetailForm.get("rg_engin_num").setValue("");
      this.insuredDetailForm.get("adtnl_detail_cylinder").setValue("");
    }
    if (type == 2) {
      this.hideImages.reg_card_back = false;

      this.insuredDetailForm.get("reg_Card_Back").setValue("");
      this.insuredDetailForm.get("reg_Card_BackFilePath").setValue("");
      this.insuredDetailForm.get("rg_traffic_plate_num").setValue("");
      this.insuredDetailForm.get("rg_place_issue").setValue("");
      this.insuredDetailForm.get("rg_TC_num").setValue("");
      this.insuredDetailForm.get("rg_type").setValue("");
      this.insuredDetailForm.get("rg_expiry_date").setValue("");
      this.insuredDetailForm.get("rg_reg_date").setValue("");
      this.insuredDetailForm.get("rg_ins_exp").setValue("");
      this.insuredDetailForm.get("rg_policy_num").setValue("");
      this.insuredDetailForm.get("rg_mortgage").setValue("");
    }
    if (type == 3) {
      this.hideImages.emirates_Id_front = false;
      this.insuredDetailForm.get("emirated_ID_Front").setValue("");
      this.insuredDetailForm.get("emirated_ID_FrontFilePath").setValue("");
      this.insuredDetailForm.get("e_eid").setValue("");
      this.insuredDetailForm.get("e_name").setValue("");
      this.insuredDetailForm.get("e_nationality").setValue("");
    }
    if (type == 4) {
      this.hideImages.emirates_Id_back = false;
      this.insuredDetailForm.get("emirated_ID_Back").setValue("");
      this.insuredDetailForm.get("emirated_ID_BackFilePath").setValue("");
      this.insuredDetailForm.get("e_cardNumber").setValue("");
      this.insuredDetailForm.get("e_expiryDate").setValue("");
      this.insuredDetailForm.get("e_gender").setValue("");
    }
    if (type == 5) {
      this.hideImages.driving_Lic_front = false;
      this.insuredDetailForm.get("driving_Lic_Front").setValue("");
      this.insuredDetailForm.get("driving_Lic_FrontFilePath").setValue("");
      this.insuredDetailForm.get("d_driv_lic_num").setValue("");
      this.insuredDetailForm.get("d_dob").setValue("");
      this.insuredDetailForm.get("d_issue_date").setValue("");
      this.insuredDetailForm.get("d_expiry_date").setValue("");
      this.insuredDetailForm.get("d_place_issue").setValue("");
    }

    if (type == 6) {
      this.hideImages.driving_Lic_back = false;
      this.insuredDetailForm.get("driving_Lic_Back").setValue("");
      this.insuredDetailForm.get("driving_Lic_BackFilePath").setValue("");
      this.insuredDetailForm.get("d_TC_number").setValue("");
      this.insuredDetailForm.get("d_lic_type").setValue("");
    }

    if (type == 7) {
      this.hideImages.multipleDoc = false;

      //added field reset for multiple file change
      //type 1
      this.insuredDetailForm.get("reg_Card_Front").setValue("");
      this.insuredDetailForm.get("reg_Card_FrontFilePath").setValue("");
      this.insuredDetailForm.get("rg_model_year").setValue("");
      this.insuredDetailForm.get("rg_num_passenger").setValue("");
      this.insuredDetailForm.get("rg_origin").setValue("");
      this.insuredDetailForm.get("rg_vhcl_make").setValue("");
      this.insuredDetailForm.get("rg_vhcl_model").setValue("");
      this.insuredDetailForm.get("rg_gvm").setValue("");
      this.insuredDetailForm.get("rg_chassis_num").setValue("");
      this.insuredDetailForm.get("rg_engin_num").setValue("");
      this.insuredDetailForm.get("adtnl_detail_cylinder").setValue("");
      //type2
      this.insuredDetailForm.get("reg_Card_Back").setValue("");
      this.insuredDetailForm.get("reg_Card_BackFilePath").setValue("");
      this.insuredDetailForm.get("rg_traffic_plate_num").setValue("");
      this.insuredDetailForm.get("rg_place_issue").setValue("");
      this.insuredDetailForm.get("rg_TC_num").setValue("");
      this.insuredDetailForm.get("rg_type").setValue("");
      this.insuredDetailForm.get("rg_expiry_date").setValue("");
      this.insuredDetailForm.get("rg_reg_date").setValue("");
      this.insuredDetailForm.get("rg_ins_exp").setValue("");
      this.insuredDetailForm.get("rg_policy_num").setValue("");
      this.insuredDetailForm.get("rg_mortgage").setValue("");
      //type 3
      this.hideImages.emirates_Id_front = false;
      this.insuredDetailForm.get("emirated_ID_Front").setValue("");
      this.insuredDetailForm.get("emirated_ID_FrontFilePath").setValue("");
      this.insuredDetailForm.get("e_eid").setValue("");
      this.insuredDetailForm.get("e_name").setValue("");
      this.insuredDetailForm.get("e_nationality").setValue("");
      //type 4
      this.hideImages.emirates_Id_back = false;
      this.insuredDetailForm.get("emirated_ID_Back").setValue("");
      this.insuredDetailForm.get("emirated_ID_BackFilePath").setValue("");
      this.insuredDetailForm.get("e_cardNumber").setValue("");
      this.insuredDetailForm.get("e_expiryDate").setValue("");
      this.insuredDetailForm.get("e_gender").setValue("");
      //type 5
      this.insuredDetailForm.get("driving_Lic_Front").setValue("");
      this.insuredDetailForm.get("driving_Lic_FrontFilePath").setValue("");
      this.insuredDetailForm.get("d_driv_lic_num").setValue("");
      this.insuredDetailForm.get("d_dob").setValue("");
      this.insuredDetailForm.get("d_issue_date").setValue("");
      this.insuredDetailForm.get("d_expiry_date").setValue("");
      this.insuredDetailForm.get("d_place_issue").setValue("");
      //type 6
      this.insuredDetailForm.get("driving_Lic_Back").setValue("");
      this.insuredDetailForm.get("driving_Lic_BackFilePath").setValue("");
      this.insuredDetailForm.get("d_TC_number").setValue("");
      this.insuredDetailForm.get("d_lic_type").setValue("");

      this.multilpleFile = [];
    }
    
    if (type == 8) {
      this.hideImages.trade_Licence = false;
      // this.insuredDetailForm.get("driving_Lic_Back").setValue("");
      // this.insuredDetailForm.get("driving_Lic_BackFilePath").setValue("");
      // this.insuredDetailForm.get("d_TC_number").setValue("");
      // this.insuredDetailForm.get("d_lic_type").setValue("");
    }
    if (type == 9) {
      this.hideImages.TRN_Certificate = false;
      // this.insuredDetailForm.get("driving_Lic_Back").setValue("");
      // this.insuredDetailForm.get("driving_Lic_BackFilePath").setValue("");
      // this.insuredDetailForm.get("d_TC_number").setValue("");
      // this.insuredDetailForm.get("d_lic_type").setValue("");
    }
  }
  //--------------------------------- GET ALL FORM DROPDOWN DATA -----------------------------
  getAllFormData() {
    let error = false;

    //  let promise = new Promise((resolve,reject)=> {
    //         setTimeout(() => {
    //           if (error){
    //               reject('error');
    //           }else {
    //             resolve('Done');
    this.motorQuoteService
      .getAllFormData(
        "COMPREHENSIVE",
        "INDIVIDUAL",
        "0",
        this.language_code,
        this.country,
        ""
      )
      .subscribe((res) => {
        // console.log("Async Work Complete");
        this.cityArr = res.cityData;

        // var indexRegPlace = this.cityArr.findIndex(function (obj, k) {
        //   return obj.CityName === 'Dubai';
        // });
        // let placelVal = this.cityArr[indexRegPlace];
        // this.insuredDetailForm.get('rg_place_issue').setValue(placelVal);

        this.vhclColorArr = res.vehicleColorsData;

        this.filteredVchColor.next(this.vhclColorArr.slice());
        this.countryArr = res.countryData;

        // this.insuredDetailForm.get("licIssueContry").setValue(this.countryArr[32]);
        this.filteredCountries.next(this.countryArr.slice());

        res.PlateCategory.forEach((item, index) => {
          this.plateCatArray.push(item);
        });
        this.filteredPlateCat.next(this.plateCatArray.slice());

        // this.insuredDetailForm.get("nationality").setValue(this.countryArr[32]);
        this.filteredNationCountries.next(this.countryArr.slice());
      });

    this.motorQuoteService.getBankDetail().subscribe((res) => {
      this.bankDetail = res.bankdetailsData;
      this.filteredBank.next(this.bankDetail.slice());
    });

    this.getVhclNCD();
    // }

    //         }, 1000);

    //         return promise;
    //  })

    // promise.then(
    //   () => this.getVhclNCD(),
    //   (err) => console.error(err)
    // );
  }

  //------------------------------ GET VEHICLE NCD ---------------------------------------------
  getVhclNCD() {
    this.motorQuoteService
      .getVehicleNcd(this.language_code, "MOTOR")
      .subscribe((res) => {
        this.NCDData = res.vechileNCDData;

        if (
          (this.retrieveQuoteNumber != "" ||
            this.retrieveQuoteNumber != undefined) &&
          res.response_code == 1
        ) {
          setTimeout(() => {
            this.getRetrieveQuoteData();
          },10000); // added timeout ruhina
        }
      });
  }

  //Added by Anju for Standard NCD Validation
  getVhclNCDStandard() {
    //console.log(this.insuredDetailForm.value.adtnl_detail_NCD);
    this.motorQuoteService
      .getVehicleNcdStandard(this.language_code, "MOTOR",this.insuredDetailForm.value.rg_reg_date,this.insuredDetailForm.value.adtnl_detail_repairTye,this.insuredDetailForm.value.rg_model_year,this.insuredDetailForm.value.d_issue_date)
      .subscribe((res) => {
        this.NCDData = res.vechileNCDData;
        if (this.retrieveQuoteNumber == "") {
        this.insuredDetailForm.get("adtnl_detail_NCD").setValue(null);
        }
      });

  }

  //---------------------------------- GET INDUSTRY TYPE --------------------------------------------------------//
  getIndustryTypes() {
    this.motorQuoteService.getIndustryTypes("MT").subscribe((res) => {
      this.industryTypeArr = res.industries;
    });
  }
  //------------------------------ GET VEHICLE MAKE DATA ---------------------------------//
  getVhclMakeData(vhcleMake, year, type) {
    this.regMinDate =  new Date(year, 0, 1);  //ruhina
    // alert(1)
    console.log(year);
    this.showLoader.vhclMake = true;
    console.log("Vehicle Make inside", vhcleMake);
    this.motorQuoteService
      .getVhclMake(
        this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
        this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue,
        "0",
        this.language_code,
        this.country,
        year,
        this.SchemeCode
      )
      .subscribe((res) => {
        this.vhclMakeArr = res.vechileMakeValuesData;
        console.log("Vehicle Make Array", this.vhclMakeArr);
        this.showLoader.vhclMake = false;
        console.log(this.vhclMakeArr);
        if (
          (type == 2 || type == 1) &&
          typeof this.vhclMakeArr != undefined &&
          typeof this.vhclMakeArr != "undefined"
        ) {
          //VEHICLE Make ----- filter
          let makeVal;
          //check undefined added - ruhina
          if(vhcleMake == "" || vhcleMake == undefined)
          {
            makeVal = this.vhclMakeArr[0]
          }
          else{
            this.vhclMakeArr.forEach((item, index) => {
            if (item.VehicleMakeName === vhcleMake) {
              makeVal = item;
              return
            }
          });
        } 
        //ruhina - if ocr data match with vehicel make array - then assign the first vehiclemake - if makeval is undefined
        if(makeVal == undefined || makeVal == null)
        {
          makeVal = this.vhclMakeArr[0]
        }
        // ruhina end   
          console.log(makeVal);
          this.insuredDetailForm.get("rg_vhcl_make").setValue(makeVal);
          // this.vehicleData.General.Make = makeVal.VehicleMakeId;

          if (type == 2) this.getVhclModel(makeVal.VehicleMakeId, 2, year);
          else this.getVhclModel(makeVal.VehicleMakeId, 1, year);
        }

        if (
          type == 3 &&
          typeof this.vhclMakeArr != undefined &&
          typeof this.vhclMakeArr != "undefined"
        ) {
          //VEHICLE Make ----- filter
          let makeVal;
          //check undefined added - ruhina
          if(vhcleMake == "" || vhcleMake == "undefined")
          {
            makeVal = this.vhclMakeArr[0].VehicleMakeName
          }
          else{
            this.vhclMakeArr.forEach((item, index) => {
              if (item.VehicleMakeName == vhcleMake) {
                makeVal = item;
              }
            });
          }        

          this.insuredDetailForm.get("rg_vhcl_make").setValue(makeVal);
          this.chassis_No_Details.General.Make = makeVal.VehicleMakeId;
          this.getVhclModel(makeVal.VehicleMakeId, 3, year);
        }

        if (res.response_code == 1) {
          // this.onVehiclAgeChange(year);
          this.filteredMakes.next(this.vhclMakeArr.slice());
        }
        this.showLoader.vhclMake = false;
        this.onVehiclAgeChange(this.insuredDetailForm.value.rg_model_year);
      });
  }

  //------------------------------ GET VEHICLE MODEL -----------------------------------------
  getVhclModel(vehclMkId, type, year) {
    // this.onVehiclAgeChange(year);
    console.log("GetModel", vehclMkId, year, type);
    this.showLoader.vhclModel = true;
console.log( this.insuredDetailForm.value.rg_vhcl_make);

    this.motorQuoteService
      .getVehicleModel(
        "P",
        this.insuredDetailForm.value.rg_vhcl_make,
        this.language_code,
        null,
        year.value
      )
      .subscribe((res) => {
        this.vhclModelArr = res.vechileModelData;
        console.log("Vehicle Model", this.vhclModelArr);
        if (res.response_code == 1) {
          this.filteredModels.next(this.vhclModelArr.slice()); // FOr ng select search
        }

        if (
          (type == 2 || type == 1) &&
          typeof this.vhclModelArr != undefined &&
          typeof this.vhclModelArr != "undefined"
        ) {
          let trim =
            type == 2
              ? this.vehicleData.General.Trim
              : this.quoteDetail.TrimCode;
          let model =
            type == 2
              ? this.vehicleData.General.Model
              : this.quoteDetail.VehicleModel;
          let ModalName = vehclMkId == 34 || vehclMkId == 7 ? trim : model;
          // let ModalName = 'Kia'
          console.log("trim",trim, model, ModalName);
          var indexModel = -1
          //added undefined constraint - ruhina
          if(ModalName == "" || ModalName == "undefined")
          {
            var indexModel = -1
          }
          else
          {
            var indexModel = this.vhclModelArr.findIndex(function (obj, k) {
              return obj.VehicleModelName === ModalName;
            });
          }
          let modelVal = this.vhclModelArr[indexModel];
          console.log("Model Name",modelVal);  
          this.insuredDetailForm.get("rg_vhcl_model").setValue(modelVal);
          console.log(this.insuredDetailForm.get("rg_vhcl_model"));
          if (type == 2)
            this.getMotorBodyType(
              modelVal.VehicleModelId,
              2,
              year,
              modelVal.VehicleModelName
            );
          else
            this.getMotorBodyType(
              modelVal.VehicleModelId,
              1,
              year,
              modelVal.VehicleModelName
            );
        }

        if (
          type == 3 &&
          typeof this.vhclModelArr != undefined &&
          typeof this.vhclModelArr != "undefined"
        ) {
          let ModalName =
            this.chassis_No_Details.General.Make == 34 ||
            this.chassis_No_Details.General.Make == 7
              ? this.chassis_No_Details.General.Trim
              : this.chassis_No_Details.General.Model;
//commented by ruhina till 2442 
          // if (ModalName != "" && ModalName != null) {
          //   var indexModel = this.vhclModelArr.findIndex(function (obj, k) {
          //     return obj.VehicleModelName === ModalName;
          //   });
            //added undefined constraint - ruhina
          var indexModel = -1
          if(ModalName == "" || ModalName == "undefined")
          {
            var indexModel = -1
          }
          else
          {
            var indexModel = this.vhclModelArr.findIndex(function (obj, k) {
              return obj.VehicleModelName === ModalName;
            });
          }
            let modelVal = this.vhclModelArr[indexModel];
            this.insuredDetailForm.get("rg_vhcl_model").setValue(modelVal);
            this.getMotorBodyType(
              modelVal.VehicleModelId,
              3,
              year,
              modelVal.VehicleModelName
            );
        //  }
        }
        this.showLoader.vhclModel = false;

        var d = new Date(this.insuredDetailForm.value.rg_reg_date);
        let firstRegYear: number = d.getFullYear();

        var p = new Date(this.insuredDetailForm.value.polStartDate);
        let policyStartDateYear: number = p.getFullYear();
        let vhclYear = this.insuredDetailForm.value.rg_model_year;

        let timeDiff = Math.abs(
          this.insuredDetailForm.value.rg_reg_date -
            this.insuredDetailForm.value.polStartDate
        );
        let days_difference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        let D1 = Math.ceil(1 + days_difference / 397); //this will give you years
        let D2 = Number(policyStartDateYear) - Number(vhclYear.value) + 1;
        let vehicle_age = Math.max(D2, D1);
        let D3 = Math.abs(D2 - D1);
        if (D3 == 1) {
          vehicle_age = D1;
        } else {
          vehicle_age = Math.max(D2, D1);
        }

        if (vehicle_age >= 5) {
          if (
            (this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName ==
              "BMW" ||
              this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName ==
                "MINI") &&
            this.SchemeCode == "104"
          ) {
          } else {
            this.insuredDetailForm
              .get("adtnl_detail_repairTye")
              .setValue(this.repairtypes[0]);
          }
        }
        this.getDeductibleValue();
      });
  }

  //------------------------------- GET MOTOR BODY TYPE AND VEHICLE SPECIFICATION------------------------------------------
  getMotorBodyType(vhclModelId, type, year, modelName) {
    if (typeof vhclModelId == "undefined" || typeof vhclModelId == undefined)
      return false;

    this.showLoader.vhclTrim = true;
    this.showLoader.vhclBodyType = true;

    this.motorQuoteService
      .getMotorBodyType(
        this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
        this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue,
        vhclModelId,
        this.language_code,
        year,
        modelName
      )
      .subscribe((res) => {
        this.vhclBodyTypeArr = res.vechileMotoBodyTypeData;
        this.vehitrims = res.vechilesSpecificationData;

        if (res.response_code == 1 || res.response_code == 2) {
          this.showLoader.vhclTrim = false;
          this.showLoader.vhclBodyType = false;
        }

        if (
          (type == 2 || type == 1) &&
          typeof this.vhclBodyTypeArr != undefined &&
          typeof this.vhclBodyTypeArr != "undefined"
        ) {
          //VEHICLE TRIM
          let trimName =
            type == 2
              ? this.vehicleData.General.Trim +
                " " +
                "-" +
                " " +
                this.vehicleData.Technical.EngineDisplacementNominal.Value.trim()
              : this.quoteDetail.TrimName;
          let bodyType =
            type == 2
              ? this.vehicleData.General.BodyType
              : this.quoteDetail.BodyType;
              //bodytype not loading fix by ruhina 
          // if (this.vehicleData.General.Trim == null) {
          // } else {
            var indexTrim = this.vehitrims.findIndex(function (obj, k) {
              return obj.VehicleSpecName === trimName;
            });

            let trimVal = this.vehitrims[indexTrim];
            this.insuredDetailForm.get("adtnl_detail_trim").setValue(trimVal);

            //BODY TYPE
            var indexbodyType = this.vhclBodyTypeArr.findIndex(function (
              obj,
              k
            ) {
              return obj.BodyTypeName === bodyType;
            });
            let bdTyVal = this.vhclBodyTypeArr[indexbodyType];
            this.insuredDetailForm
              .get("adtnl_detail_bodyType")
              .setValue(bdTyVal);
          // }
        }

        if (
          type == 3 &&
          typeof this.vhclBodyTypeArr != undefined &&
          typeof this.vhclBodyTypeArr != "undefined"
        ) {
          //VEHICLE TRIM ------- not given

          let trimName =
            this.chassis_No_Details.General.Trim +
            " " +
            "-" +
            " " +
            this.chassis_No_Details.Technical.EngineDisplacementNominal.Value.trim();
          let bodyType = this.chassis_No_Details.General.BodyType;

          var indexTrim = this.vehitrims.findIndex(function (obj, k) {
            return obj.VehicleSpecName === trimName;
          });

          let trimVal = this.vehitrims[indexTrim];
          this.insuredDetailForm.get("adtnl_detail_trim").setValue(trimVal);

          //BODY TYPE
          var indexbodyType = this.vhclBodyTypeArr.findIndex(function (obj, k) {
            return obj.BodyTypeName === bodyType;
          });
          let bdTyVal = this.vhclBodyTypeArr[indexbodyType];
          this.insuredDetailForm.get("adtnl_detail_bodyType").setValue(bdTyVal);
        }

        this.updateValuation();
        this.getTonnage();
      });
  }

  /************** Country Filter */

  private filterCountry() {
    if (!this.countryArr) {
      return;
    }
    // get the search keyword
    let search = this.country1FilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countryArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCountries.next(
      this.countryArr.filter(
        (bank) => bank.CountryName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterNationalCountry() {
    if (!this.countryArr) {
      return;
    }
    // get the search keyword
    let search = this.nationalFilterCtrl.value;
    if (!search) {
      this.filteredNationCountries.next(this.countryArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredNationCountries.next(
      this.countryArr.filter(
        (bank) => bank.CountryName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterMortageBank() {
    if (!this.bankDetail) {
      return;
    }

    // get the search keyword
    let search = this.mortageBankFilterCtrl.value;

    if (!search) {
      this.filteredBank.next(this.bankDetail.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.filteredBank.next(
      this.bankDetail.filter(
        (bank) => bank.InstituteName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterVechColor() {
    if (!this.vhclColorArr) {
      return;
    }

    // get the search keyword
    let search = this.vchColorFilterCtrl.value;
    if (!search) {
      this.filteredVchColor.next(this.vhclColorArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks 
    //Modified by Anju to search from start letter of color name
    this.filteredVchColor.next(
      this.vhclColorArr.filter(
       // (bank) => bank.ColorName.toLowerCase().indexOf(search) > -1
       (bank) => bank.ColorName.toLowerCase().startsWith(search) ==true
      )
    );
  }

  private filterPlateCat() {
    if (!this.plateCatArray) {
      return;
    }

    // get the search keyword
    let search = this.plateFilterCtrl.value;
    if (!search) {
      this.filteredPlateCat.next(this.plateCatArray.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.filteredPlateCat.next(
      this.plateCatArray.filter(
        (bank) => bank.label.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterCode() {
    if (!this.codes) {
      return;
    }

    // get the search keyword
    let search = this.codeFilterCtrl.value;
    if (!search) {
      this.filteredCode.next(this.codes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.filteredCode.next(
      this.codes.filter(
        (bank) => bank.viewValue.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterYear() {
    if (!this.vehimodelyrs) {
      return;
    }

    // get the search keyword
    let search = this.yearFilterCtrl.value;
    if (!search) {
      this.filteredYears.next(this.vehimodelyrs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.filteredYears.next(
      this.vehimodelyrs.filter(
        (bank) => bank.label.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterMake() {
    if (!this.vhclMakeArr) {
      return;
    }
    // get the search keyword
    let search = this.makeFilterCtrl.value;
    if (!search) {
      this.filteredMakes.next(this.vhclMakeArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredMakes.next(
      this.vhclMakeArr.filter(
        (bank) => bank.VehicleMakeName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterModel() {
    if (!this.vhclModelArr) {
      return;
    }
    // get the search keyword
    let search = this.modelFilterCtrl.value;
    if (!search) {
      this.filteredModels.next(this.vhclModelArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredModels.next(
      this.vhclModelArr.filter(
        (bank) => bank.VehicleModelName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private filterPartners() {
    if (!this.partnersArr) {
      return;
    }
    // get the search keyword
    let search = this.partnerFilterCtrl.value;
    if (!search) {
      this.filteredPartners.next(this.partnersArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredPartners.next(
      this.partnersArr.filter(
        (partner) => partner.PartnerName.toLowerCase().startsWith(search) ==true
      )
    );
  }

  //----------- CONVERT DATE INTO DD/MM/YYYY FORMAT ------------//
  convertDate(inputFormat) {
    console.log(inputFormat)
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    //date format when null - ruhina
    if(inputFormat == null || inputFormat == '')
    {
      return ''
    }
    else
    {
      var d = new Date(inputFormat);
      //console.log([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
    }
    
  }

  convertStartDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }

    // to avoid date issues . if null let it be blank -- ruhina
    if(inputFormat == null || inputFormat == '')
    {
      return ''
    }
    else
    {
      var d = new Date(inputFormat);
      //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
    }
   
  }

  //----------------------------------------VALIDATION FOR AGE ------------------------------------------------//
  calucateAge(inputData, type) {
    if (inputData != "") {
      let timeDiff = Math.abs(Date.now() - inputData);
      if (type == 1) {
        this.age =  Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      }
// refrral condition and validation for age added by ruhina 
      if (type == 2) {
        this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      }

      console.log(this.age)
      // if(this.age < 25 || this.age > 70)
      // {
        
      //   this.referalStatus = true;
      // }
      // else
      // {
      //   this.referalStatus = false;
      // }
      console.log(this.referalStatus)
      return this.age;
    } else {
      return "";
    }
  }
  //------------------------------------ GET QUOTATION ---------------------------------------------//

  getQuotation(stepper, type) {
    // if(this.insuredDetailForm.status == 'INVALID')
    // {
    //   alert("Please fill the mandatory fields properly")
    // }
    // if (this.insuredDetailForm.value.rg_model_year.value >= (this.minyear - 1) && this.insuredDetailForm.value.adtnl_detail_insType.value == 100 && this.insuredDetailForm.value.adtnl_detail_brandNew != 1) {
    //     this.insuredDetailForm.controls['rg_reg_date'].setValidators([Validators.required]);

    //     if (this.insuredDetailForm.value.adtnl_detail_brandNew != 1) {
    //       if (this.regMaxDate < this.insuredDetailForm.value.rg_reg_date || this.regMinDate > this.insuredDetailForm.value.rg_reg_date) {
    //           Swal.fire("Invalid 1st registration date","",'error');
    //           return false;
    //       }
    //     }
    // } else {
    //           this.insuredDetailForm.controls['rg_reg_date'].setValidators(null);
    //       }

    //     this.insuredDetailForm.controls['rg_reg_date'].updateValueAndValidity();

    // if (Number(this.insuredDetailForm.value.adtnl_detail_vhclValue) < Number(this.vehicleValueLimit.startLimit) || Number(this.insuredDetailForm.value.adtnl_detail_vhclValue) > Number(this.vehicleValueLimit.endLimit)) {
    //     return false;
    //   }

    // this.showLoader.Quotation = true;
console.log("in",stepper,type)
    let modifyValue =
      this.existVehiclevalue == 1 ? this.existVehiclevalue : null;
    // let dob = this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == '100' ? this.convertDate(this.insuredDetailForm.value.d_dob) : '';
    let repairType =
      this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100"
        ? this.insuredDetailForm.value.adtnl_detail_repairTye
        : { value: "Garage", viewValue: "Garage" };
    let vehicle_value =
      this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100"
        ? this.insuredDetailForm.value.adtnl_detail_vhclValue
        : 0;
    let brand_new =
      this.insuredDetailForm.value.rg_model_year.value != this.maxYear ||
      this.insuredDetailForm.value.rg_model_year.value != this.year ||
      this.insuredDetailForm.value.rg_model_year.value != this.minyear
        ? { code: "0", value: "N" }
        : { code: "1", value: "Y" };
    let vehicleType =
      this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == "100"
        ? { code: "1001", value: "Private" }
        : { code: "1001", value: "Private" };
    // let policy_expire_date = this.insuredDetailForm.value.vhclTPLCoverage == 'No' ? this.nextDate : this.PrevDate;
    let policy_expire_date = this.prevNoIns ? this.convertDate(Date.now())  :this.convertDate(
      this.insuredDetailForm.value.rg_ins_exp
    );
    let emiratesId = this.insuredDetailForm.value.e_eid.replace(/-/gi, "");
    // var sCode = "ALL";
    let policy_status = this.isRenewal == 1 ? "RENEWAL" : "NEW";

    // if (this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName == "BMW" || this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName == "MINI") {
    //   if (this.SchemeCode == "104")
    //     sCode = "104";
    // }
    // else if (this.SchemeCode == "104") {
    //   sCode = "ALL";
    // }

   
    let motorArrayQuote = {
      cedant_broker_id: this.insuredDetailForm.value.partner,
      broker_branch_id: this.insuredDetailForm.value.branch,
      insured_type: this.insuredDetailForm.value.adtnl_detail_vhclOwnBy,
      insured_name: this.insuredDetailForm.value.e_name,
      gender: this.insuredDetailForm.value.e_gender,
      dob: this.convertDate(this.insuredDetailForm.value.d_dob),
      prospect_age: this.calucateAge(this.insuredDetailForm.value.d_dob, 1),
      nationality: this.insuredDetailForm.value.e_nationality,
      email: this.insuredDetailForm.value.adtnl_detail_email,
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      UAE_lic_age: this.convertDate(this.insuredDetailForm.value.d_issue_date),
      UAELicAge: this.calucateAge(this.insuredDetailForm.value.d_issue_date, 2),
      license_country: this.insuredDetailForm.value.e_nationality,
      NCD: this.insuredDetailForm.value.adtnl_detail_NCD,
      model_year: this.insuredDetailForm.value.rg_model_year,
      make: this.insuredDetailForm.value.rg_vhcl_make,
      model: this.insuredDetailForm.value.rg_vhcl_model,
      trim: this.insuredDetailForm.value.adtnl_detail_trim,
      body_type: this.insuredDetailForm.value.adtnl_detail_bodyType,
      cylinders: this.insuredDetailForm.value.adtnl_detail_cylinder,
      passenger: this.insuredDetailForm.value.rg_num_passenger,
      registered_date: this.convertDate(
        this.insuredDetailForm.value.rg_reg_date
      ),
      GCCSpecified: this.insuredDetailForm.value.adtnl_detail_gccSpecified,
      vhclModified: this.insuredDetailForm.value.adtnl_detail_vhclModified,
      registered_place: this.insuredDetailForm.value.rg_place_issue,
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      repair_type: repairType,
      sum_insured: vehicle_value,
      emirates_id: emiratesId,
      //policy_type: this.insuredDetailForm.value.adtnl_detail_insType,
      policy_type: {
        value: this.insuredDetailForm.value.adtnl_detail_insType.crsProdId,
        viewValue:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
        WebProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductCode,
        CoreProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode,
      },
      ExistingCover: this.insuredDetailForm.value.vhclTPLCoverage,
      policy_expire_date: this.convertDate(policy_expire_date),
      brand_new: brand_new,
      customer_id: "0",
      lead_id: "0",
      NCD_docs: "No",
      registration_type: vehicleType,
      VEHENGINESIZE: "1",
      source: "B2B",
      document_data: this.tempDocArray,
      multiple_doc_data: this.tempMultipleDocData,
      GVM: this.insuredDetailForm.value.rg_gvm,
      origin: this.insuredDetailForm.value.rg_origin,
      policy_num: this.insuredDetailForm.value.rg_policy_num,
      eid_card_num: this.insuredDetailForm.value.e_cardNumber,
      reg_ins_exp_date:this.prevNoIns ? this.convertDate(Date.now())  :this.convertDate(
        this.insuredDetailForm.value.rg_ins_exp
      ),
      reg_exp_date: this.convertDate(
        this.insuredDetailForm.value.rg_expiry_date
      ),
      eid_exp_date: this.convertDate(this.insuredDetailForm.value.e_expiryDate),
      driv_lic_issue_date: this.convertDate(
        this.insuredDetailForm.value.d_issue_date
      ),
      driv_lic_exp_date: this.convertDate(
        this.insuredDetailForm.value.d_expiry_date
      ),
      driv_lic_issuePlace: this.insuredDetailForm.value.d_place_issue,
      name_in_arabic: this.insuredDetailForm.value.e_arabic_name,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      //license: this.insuredDetailForm.value.d_driv_lic_num, //Hide license by Anju
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      plate_number: this.insuredDetailForm.value.rg_traffic_plate_num,
      plate_code: this.insuredDetailForm.value.rg_plateCode,
      plate_category: this.insuredDetailForm.value.rg_type,
      //driv_lic_TC_number: this.insuredDetailForm.value.d_TC_number, //Hide License TC by Anju
      driv_lic_type: this.insuredDetailForm.value.d_lic_type,
      vehicleColor: this.insuredDetailForm.value.vhclColor,
      SchemeCode: this.SchemeCode,
      modifiedVhclValue: modifyValue,
      start_date: this.convertDate(
        this.insuredDetailForm.value.polStartDate
      ),
      policy_status: policy_status,
      deductible_value: this.insuredDetailForm.value.deductible,
      TRN_number: this.insuredDetailForm.value.TRN_num,
      trade_Lic_number: this.insuredDetailForm.value.trade_lic_num,
      industry_type: this.insuredDetailForm.value.industry_type,
      //transaction_type: this.insuredDetailForm.value.transaction_type.label, --Removed transaction type
    };
    console.log(motorArrayQuote);

    var gender = "";
    if (this.insuredDetailForm.value.e_gender) {
      gender = this.insuredDetailForm.value.e_gender.value;
    }

    let policyDetail = {
      quotation_number: this.webQuoteNumber,
      CRS_quotation_number: this.quoteNumber,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      //license: this.insuredDetailForm.value.d_driv_lic_num,
      emirates: this.insuredDetailForm.value.rg_place_issue,
      address: this.insuredDetailForm.value.adtnl_detail_address,
      PO_box: this.insuredDetailForm.value.adtnl_detail_poBoxNum,
      occupation: "",
      national_id: this.insuredDetailForm.value.e_eid,
      arabic_name: "",
      home_office_number: "",
      start_date: this.convertDate(this.insuredDetailForm.value.polStartDate),
      end_date: "22/12/2019",
      color: "",
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      engine: this.insuredDetailForm.value.rg_engin_num,
      plate: "54687",
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      payment_mode: "ONLINE",
      named_driver: this.insuredDetailForm.value.e_name,
      additional_driver_under_age: "0",
      IP_address: "",
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      driving_License_Front: this.insuredDetailForm.value.driving_Lic_Front,
      driving_license_Back: "",
      Reg_Card_Front: this.insuredDetailForm.value.reg_Card_Front,
      Reg_Card_Back: this.insuredDetailForm.value.reg_Card_Back,
      EID_Front: this.insuredDetailForm.value.emirated_ID_Front,
      EID_Back: this.insuredDetailForm.value.emirated_ID_Backk,
      dealer_quote: "",
      vhclPlateCategory: this.insuredDetailForm.value.rg_type,
      plateCode: this.insuredDetailForm.value.rg_plateCode,
      plateNumber: this.insuredDetailForm.value.rg_traffic_plate_num,
      area: this.insuredDetailForm.value.adtnl_detail_area,
      noOfDoors: this.nofDoors,
      gender: gender,
      nationality: this.insuredDetailForm.value.e_nationality,
      source: "B2B",
      promoCode: this.insuredDetailForm.value.promoCode,
    };
    this.loggedInUserRole = localStorage.getItem('currentUser')

    this.quoteDetailArr = motorArrayQuote;
    this.emailId = this.insuredDetailForm.value.adtnl_detail_email;
    this.motorQuoteService
      .getInsertQuote(motorArrayQuote, policyDetail)
      .subscribe((res) => {
        if (type != 0) {
          stepper.next();
        }
        // if(type == 0){
        //   this.sendRefferMailToAdmin(1,"");
        // }
        this.PlanDataJson.planBenefitData.benefit_data = [];

        if (res.response_code == '0') {
          this.showLoader.Quotation = false;
          this.showLoader.retrieveQuote = false;
          Swal.fire("Sorry! Plan not available.", " ", "error");

          stepper.previous();
        } else if (res.response_code == '4') {
          
          this.webQuoteNumber = res.web_quotation_number;
          this.showLoader.Quotation = false;
          this.showLoader.retrieveQuote = false;
          this.referalStatus = true;
          this.referral_reason = res.referral_reason;

          Swal.fire(this.referral_reason + "\n Click on Refer Quote to proceed further",
          "Thank you for your request, based on your application details, this case needs to be referred for further review.\n If you need further assistance call us at 800-842",
          "error"
          );
          stepper.previous();

       

          //   this.savePolicyDetail(1);

          
          //   "An email will be sent to you shortly. If you need further assistance call us at 800-842",,"error"); //-ruhina Show Referral_reason
          // Swal.fire(
          //   "Thank you for you request, based on your application details, the case needs to be referred for further review.",
          //   "An email will be sent to you shortly. If you need further assistance call us at 800-842",
          //   "error"
          // );
//Just commented to check the status
         // stepper.previous();
          
        } else if (res.response_code_ == '400' || res.response_code == '5') {
          this.showLoader.Quotation = false;
          this.showLoader.retrieveQuote = false;
          this.webQuoteNumber = res.web_quotation_number;
          this.referral_reason = res.referral_reason;
          this.referalStatus = true;
          //   this.savePolicyDetail(1);
          Swal.fire(
            this.referral_reason + "\n Click on Refer Quote to proceed further",
            "Thank you for your request, based on your application details, this case needs to be referred for further review.\n If you need further assistance call us at 800-842",
            "error"
           
          );
          // Swal.fire(
          //   "Thank you for you request, based on your application details, the case needs to be referred for further review.",
          //   "An email will be sent to you shortly. If you need further assistance call us at 800-842",
          //   "error"
          // );
          stepper.previous();
        } else if (res.response_code_r == '7') {
          this.webQuoteNumber = res.web_quotation_number_r;
          this.referral_reason = res.response_message_r;
          this.showLoader.Quotation = false;
          this.showLoader.retrieveQuote = false;
          this.referalStatus = true;

          // this.savePolicyDetail(1);
          Swal.fire(
            this.referral_reason + "\n Refer the Quote to proceed Further",
            "Thank you for you request, based on your application details, the case needs to be referred for further review.",
            "error"
          );
          // Swal.fire(
          //   "Thank you for you request, based on your application details, the case needs to be referred for further review.",
          //   "An email will be sent to you shortly. If you need further assistance call us at 800-842",
          //   "error"
          // );

          stepper.previous();
        } else {
          stepper.next();
          this.referalStatus = false;
          this.initial_benefit_data = res.planBenefitData.benefit_data;
          // this.policyFee = 1;
          this.policyFee = 0;
          this.totalVariablePremium = 0;
          this.PlanDataJson = res;
          this.totalFixPremium = 0;
          this.plan_Name = this.PlanDataJson.planBenefitData.data[0].PlanName;
          this.plan_Id = this.PlanDataJson.planBenefitData.data[0].PlanId;
          this.benfPremIdArray = this.PlanDataJson.planBenefitData.data;
          this.webQuoteNumber = this.PlanDataJson.webQuotationNumber;
          this.quoteNumber = this.PlanDataJson.quotationNumber;
          this.tempBenefitData = this.PlanDataJson.planBenefitData.benefit_data;

          this.showLoader.Quotation = false;
          this.showLoader.retrieveQuote = false;

          if (this.webQuoteNumber == "" || this.webQuoteNumber == null) {
            stepper.previous();
          }

          //Added by Anju to upload Additional documents 
          if((this.insuredDetailForm.value.vhclTPLCoverage.id=='1' || this.stripTime(new Date (this.insuredDetailForm.value.rg_ins_exp))   < this.stripTime(new Date()) ) && this.quoteStatus != 'REFERRED'  && this.insuredDetailForm.value.adtnl_detail_brandNew != 1){
                this.NCDFormSubmit.get('SurveyReport').setValidators(Validators.required);
                this.NCDFormSubmit.get('SurveyReport').updateValueAndValidity();
                this.enableSurveyReport=true;
                this.enableAdditional=true;
               }
               else
               {
                this.enableSurveyReport=false;
                  this.enableAdditional=false;
               }

          this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
            if (
              item.PlanBenefitType == "OPTIONAL" &&
              item.MultiOptionFlag == 1
            ) {
              console.log("COver Data",this.PlanDataJson.planBenefitData)
              this.mulOption = item.multipleOptionData[0];
              this.PlanDataJson.planBenefitData.data[
                index
              ].benefitSelectedData = item.multipleOptionData[0];
              this.PlanDataJson.planBenefitData.data[index].multiOptID =
                item.multipleOptionData[0].BenefitId;
              this.PlanDataJson.planBenefitData.data[index].Price =
                item.multipleOptionData[0].Price;
              this.PlanDataJson.planBenefitData.data[index].checked = false;
              this.PlanDataJson.planBenefitData.data[index].offerChecked =
                false;
            }

            if (
              item.PlanBenefitType == "OPTIONAL" &&
              item.MultiOptionFlag != 1
            ) {
              this.PlanDataJson.planBenefitData.data[index].checked = false;
              this.PlanDataJson.planBenefitData.data[index].offerChecked =
                false;
            }
          });

          // let discount = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdPerc/100;
          this.discount =
            this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt;
            this.discountType = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdYear
            if (this.discountType == "19")
              this.showDiscount = false
            else
              this.showDiscount = true
          this.PlanDataJson.planBenefitData.benefit_data.forEach(
            (item, index) => {
              this.totalFixPremium =
                Number(this.totalFixPremium) + Number(item.premium);
              // this.PlanDataJson.planBenefitData.benefit_data[index].premium = (item.premium - item.premium * discount);
            }
          );

          // if (
          //   this.insuredDetailForm.value.adtnl_detail_repairTye.viewValue ==
          //   "Premium Garage"
          // ) {
          //   this.applyLoadingOnChangeRepairType();
          // }

          this.tempTotalFixPremium = this.totalFixPremium;

          //  this.totalFixPremium = Math.round(this.tempTotalFixPremium - this.tempTotalFixPremium * discount);
          // this.totalFixPremium = Math.round(
          //   this.tempTotalFixPremium - this.discount
          // );

          // this.totalFixPremium = Math.round(
          //   this.totalFixPremium + this.policyFee
          // );
          this.totalFixPremium = Number((
            this.tempTotalFixPremium - this.discount
          ).toFixed(2)); //rounding off issue fixed

          this.totalFixPremium = Number((
            this.totalFixPremium + this.policyFee
          ).toFixed(2)); //rounding off issue fixed
          this.tempTotalLoadFixPrem = this.totalFixPremium;
          // this.VATAMT = Math.round(
          //   (this.totalFixPremium + this.totalVariablePremium) * 0.05
          // );
          // this.Total_Primium = Math.round(
          //   this.totalFixPremium + this.totalVariablePremium + this.VATAMT
          // );
          this.VATAMT = (
            (this.totalFixPremium + this.totalVariablePremium) * 0.05
          ).toFixed(2);
          this.Total_Primium = (
            this.totalFixPremium + this.totalVariablePremium + this.VATAMT
          ).toFixed(2);
          this.loadingBy = "1";
          //this.savePolicyDetail(1);
          this.checkAccessForPolicyIssueButtons();
        }
      });
  }

  PlanDataJson: any = {
    quotationNumber: "",
    productCode: "",
    planBenefitData: {},
    data: [],
  };
//old onMulOptionDataChange function commented by ruhina
  // onMulOptionDataChange(eve, i, mulOption) {
  //   this.PlanDataJson.planBenefitData.data[i].benefitSelectedData = eve;
  //   this.PlanDataJson.planBenefitData.data[i].multiOptID = eve.BenefitId;
  //   this.PlanDataJson.planBenefitData.data[i].Price = eve.Price;
  //   this.PlanDataJson.planBenefitData.data[i].checked = false;
  //   this.addOptionalBenigits(1, 2);
  // }

  //new Added by ruhina
  onMulOptionDataChange(eve, i, mulOption) {
    var index = this.PlanDataJson.planBenefitData.data[i].multipleOptionData.findIndex(obj => obj.BenefitId === eve.source.value);
    var selecteddata = this.PlanDataJson.planBenefitData.data[i].multipleOptionData[index]
    this.PlanDataJson.planBenefitData.data[i].benefitSelectedData = selecteddata;
    this.PlanDataJson.planBenefitData.data[i].multiOptID = selecteddata.BenefitId;
    this.PlanDataJson.planBenefitData.data[i].Price = selecteddata.Price;
    this.PlanDataJson.planBenefitData.data[i].checked = false;
    this.addOptionalBenigits(selecteddata.BenefitId, 2);
  }

  addOptionalBenigits(id, event) {
    this.totalVariablePremium = 0;
    this.optionalBenefit = ""; 
    // commented by ruhina - made bronze as OC cover again
    // //added ruhina - default bronze is selected 
    // if(!this.IsTPL)
    // {
    //   this.optionalBenefit = this.planBenefitIDforBronze;
    // }
    // else
    // {
    //   this.optionalBenefit = "";
    // }
    
    console.log("Additional benefit",this.PlanDataJson.planBenefitData.data)

    this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
      //admin this.accessGroup == 'ADMIN
      if (
        this.localStorageData.BusinessSource == "DIRECT" &&
        this.localStorageData.Partner_ID == 1 &&
        (this.localStorageData.UserRole == "TELEMARKETING" || this.localStorageData.UserRole == 'UW USER' || this.localStorageData.UserRole == 'SENIOR UW') &&
        this.localStorageData.UserType == "POS"
      ) {
        if (item.offerChecked == true) {
          //this.PlanDataJson.planBenefitData.data[index].checked = true;
          this.totalVariablePremium = Number(this.totalVariablePremium + 1);
        }
        if (item.checked == false) {
          // this.PlanDataJson.planBenefitData.data[index].offerChecked = false;
        }
        if (
          item.checked == true &&
          item.PlanBenefitType == "OPTIONAL" &&
          item.MultiOptionFlag != 1 &&
          item.offerChecked == false
        ) {
          // if (id == 321) {
          //   if (
          //     item.BenefitId == this.planBenefitIDforBronze &&
          //     item.checked == true
          //   ) {
          //     this.totalVariablePremium = Number(
          //       this.totalVariablePremium - item.Price
          //     );
          //     item.checked = false;
          //   }
          // }
          // // 477 for production & 509 for online test - Roadside Assistance - Bronze
          // if (id == this.planBenefitIDforBronze) {
          //   if (item.BenefitId == 321 && item.checked == true) {
          //     this.totalVariablePremium = Number(
          //       this.totalVariablePremium - item.Price
          //     );
          //     item.checked = false;
          //   }
          // }
          if(item.MultiOptionFlagName == 'RSA')
          {
           
          }
          else
          {
            this.optionalBenefit = this.optionalBenefit + "," + item.BenefitId;
            this.totalVariablePremium = Number(
              this.totalVariablePremium + item.Price
            );
          }
 
        }

        if (
          item.checked == true &&
          item.PlanBenefitType == "OPTIONAL" &&
          item.MultiOptionFlag == 1 &&
          item.offerChecked == false
        ) {
          
          this.optionalBenefit =
            this.optionalBenefit + "," + this.mulOption.BenefitId;
          this.totalVariablePremium = Number(
            this.totalVariablePremium + item.Price
          );

          // //commented below code to remove RSA bronze from default cover list start
          // //ruhina added condition for RSA covers in Comprehensive
          // if(!this.IsTPL)
          // {
          //   if(item.MultiOptionFlagName == 'RSA'  )
          //   {
                     
          //     if(this.optionalBenefit == this.planBenefitIDforBronze )
          //     {
          //       this.totalVariablePremium = Number(this.totalVariablePremium - 1); //Bronze premium 1AED to be deducted if other options are enabled //ruhina 
          //     }
          //     this.optionalBenefit = "";
          //     var index1 = this.PlanDataJson.planBenefitData.data.findIndex(obj => obj.BenefitId == this.planBenefitIDforBronze);
          //     if(index1 != -1)
          //     {
          //       this.PlanDataJson.planBenefitData.data[index1].checked = false;
          //       this.PlanDataJson.planBenefitData.data[index1].offerChecked =
          //         false;
          //     }
          //     this.optionalBenefit = this.optionalBenefit + "," + item.BenefitId;
          //   this.totalVariablePremium = Number(
          //     this.totalVariablePremium + item.Price
          //   );
          //   }
          //   else
          //   {
          //     var index1 = this.PlanDataJson.planBenefitData.data.findIndex(obj => obj.BenefitId == this.planBenefitIDforBronze);
          //     if(index1 != -1)
          //     {
          //       this.PlanDataJson.planBenefitData.data[index1].checked = true;
          //       this.PlanDataJson.planBenefitData.data[index1].offerChecked =
          //         false;
          //     }
          //     this.optionalBenefit =
          //     this.optionalBenefit + "," + item.BenefitId;
          //   this.totalVariablePremium = Number(
          //     this.totalVariablePremium + item.Price
          //   );
          //   }
          //   //ruhina end
          // }
          // else
          // {
          //       this.optionalBenefit = this.optionalBenefit + "," + item.BenefitId;
          //       this.totalVariablePremium = Number(
          //       this.totalVariablePremium + item.Price
          //       );
          // }

          // //ruhina comment end
          
        }
      } else {
        //USER

        if (item.offerChecked == true) {
          this.PlanDataJson.planBenefitData.data[index].checked = true;
          this.totalVariablePremium = Number(this.totalVariablePremium + 1);
        }
        if (item.checked == false) {
          this.PlanDataJson.planBenefitData.data[index].offerChecked = false;
        }

        if (
          item.checked == true &&
          item.PlanBenefitType == "OPTIONAL" &&
          item.MultiOptionFlag != 1 &&
          item.offerChecked == false
        ) {
          // if (id == 321) {
          //   if (
          //     item.BenefitId == this.planBenefitIDforBronze &&
          //     item.checked == true
          //   ) {
          //     this.totalVariablePremium = Number(
          //       this.totalVariablePremium - item.Price
          //     );
          //     item.checked = false;
          //   }
          // }
          // // 477 for production & 509 for online test
          // if (id == this.planBenefitIDforBronze) {
          //   if (item.BenefitId == 321 && item.checked == true) {
          //     this.totalVariablePremium = Number(
          //       this.totalVariablePremium - item.Price
          //     );
          //     item.checked = false;
          //   }
          // }
          this.optionalBenefit = this.optionalBenefit + "," + item.BenefitId;
          this.totalVariablePremium = Number(
            this.totalVariablePremium + item.Price
          );
        }

        if (
          item.checked == true &&
          item.PlanBenefitType == "OPTIONAL" &&
          item.MultiOptionFlag == 1 &&
          item.offerChecked == false
        ) {
          this.optionalBenefit =
            this.optionalBenefit + "," + this.mulOption.BenefitId;
          this.totalVariablePremium = Number(
            this.totalVariablePremium + item.Price
          );

          // ruhina commented below code start
          // if(!this.IsTPL)
          // {
          //   if(item.MultiOptionFlagName == 'RSA'  )
          //   {
                    
          //    if(this.optionalBenefit == this.planBenefitIDforBronze )
          //     {
          //       this.totalVariablePremium = Number(this.totalVariablePremium - 1); //Bronze premium 1AED to be deducted if other options are enabled //ruhina 
          //     }
          //     this.optionalBenefit = "";
          //     var index1 = this.PlanDataJson.planBenefitData.data.findIndex(obj => obj.BenefitId == this.planBenefitIDforBronze);
          //     if(index1 != -1)
          //     {
          //       this.PlanDataJson.planBenefitData.data[index1].checked = false;
          //       this.PlanDataJson.planBenefitData.data[index1].offerChecked =
          //         false;
          //     }
          //     this.optionalBenefit = this.optionalBenefit + "," + item.BenefitId;
          //   this.totalVariablePremium = Number(
          //     this.totalVariablePremium + item.Price
          //   );
          //   }
          //   else
          //   {
          //     var index1 = this.PlanDataJson.planBenefitData.data.findIndex(obj => obj.BenefitId == this.planBenefitIDforBronze);
          //     if(index1 != -1)
          //     {
          //       this.PlanDataJson.planBenefitData.data[index1].checked = true;
          //       this.PlanDataJson.planBenefitData.data[index1].offerChecked =
          //         false;
          //     }
          //     this.optionalBenefit =
          //     this.optionalBenefit + "," + item.BenefitId;
          //   this.totalVariablePremium = Number(
          //     this.totalVariablePremium + item.Price
          //   );
          //   }
          // }
          // else
          // {
          //       this.optionalBenefit = this.optionalBenefit + "," + item.BenefitId;
          //       this.totalVariablePremium = Number(
          //       this.totalVariablePremium + item.Price
          //       );
          // }
          // ruhina commented below end 
        }
      }
    });
  }

  checkOptionalBenefit(benefitId) {
    if (typeof benefitId != undefined && typeof benefitId != "undefined") {
      let benefitIdArray = benefitId.split(",");

      this.totalVariablePremium = 0;

      benefitIdArray.forEach((item1, index1) => {
        this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
          if (item.BenefitId == item1 && (item.PlanBenefitType != "OPTIONAL" && item.MultiOptionFlag != 1)) {
            this.PlanDataJson.planBenefitData.data[index].checked = true;
            this.PlanDataJson.planBenefitData.data[index].offerChecked = false;
            this.totalVariablePremium = Number(
              this.totalVariablePremium + item.Price
            );
          }
        });

        this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
          if (item.PlanBenefitType == "OPTIONAL" && item.MultiOptionFlag == 1) {
            item.multipleOptionData.forEach((multiItem, multiIndex) => {
              if (multiItem.BenefitId == item1) {
                this.mulOption = item.multipleOptionData[multiIndex]; 
                this.PlanDataJson.planBenefitData.data[index].benefitSelectedData = this.mulOption;          
                this.PlanDataJson.planBenefitData.data[index].checked = true;
                this.PlanDataJson.planBenefitData.data[index].offerChecked =
                  false;
                this.PlanDataJson.planBenefitData.data[index].multiOptID =
                  item.multipleOptionData[multiIndex].BenefitId;
                  
                this.PlanDataJson.planBenefitData.data[index].Price =
                  item.multipleOptionData[multiIndex].Price;
                  this.totalVariablePremium = Number(
                    this.totalVariablePremium + item.Price
                  );
              }
              console.log("Line no 2771");
            });
          }
          else if (item.BenefitId == item1) {
              this.PlanDataJson.planBenefitData.data[index].checked = true;
              this.PlanDataJson.planBenefitData.data[index].offerChecked = false;
              this.totalVariablePremium = Number(
                this.totalVariablePremium + item.Price
              );
            }
          
        });
      });
    }
  }

  //--------------------------------APPLY 20% LOADING ON PREMIUM GARAGE REPAIR TYPE ----------------------------//
  applyLoadingOnChangeRepairType() {
    // this.repairLoadingAmt = Math.round(
    //   (20 * Number(this.totalFixPremium)) / 100
    // ); //FOR BY PERCENT
    this.repairLoadingAmt =Number(((20 * Number(this.totalFixPremium)) / 100).toFixed(2)) //FOR BY PERCENT
    this.totalFixPremium += this.repairLoadingAmt;
  }

  //----------------------------------- VIEW QUOTE PDF -------------------------------------------
  viewQuotePDF() {
    this.showLoader.Quotation = true;
    let vatAMt = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
    let total_premium =
    Number(this.totalFixPremium) + Number(this.totalVariablePremium) + Number(vatAMt);
    // let loadingAmt =
    //   this.calculBy == 1 ? this.loadingByPercent :  Number(this.LDAmount);
    let loadingAmt = Number(this.LDAmount);
    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      // base_premium: Math.round(this.totalFixPremium),
      base_premium: Number(this.totalFixPremium).toFixed(2), // rounding off issue fixed
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      //  product_code: this.insuredDetailForm.value.adtnl_detail_insType,
      product_code: {
        value: this.insuredDetailForm.value.adtnl_detail_insType.crsProdId,
        viewValue:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
        WebProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductCode,
        CoreProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode,
      },
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,
      calculation_type: this.calculBy,
      loading_by: this.loadingBy,
      loading_amount: loadingAmt,
      loadis_rate: this.loading_rate,
      premiumGarageAmount: this.repairLoadingAmt,
    };

    this.motorQuoteService
      .insertPlanDataEmail(
        this.PlanDataJson.quotationNumber,
        this.PlanDataJson.PlanDataJson,
        // Math.round(this.totalFixPremium),
        this.totalFixPremium.toFixed(2),
        this.totalVariablePremium,
        this.policyFee,
        total_premium,
        this.PlanDataJson.planBenefitData.data,
        this.PlanDataJson.planBenefitData.benefit_data,
        this.insuredDetailForm.value.adtnl_detail_repairTy,
        this.savePlanDetailArr,
        "Individual",
        this.SchemeCode
      )
      .subscribe((res) => {
        this.motorQuoteService
          .getQuoteDetailPDF(this.webQuoteNumber)
          .subscribe((res) => {
            this.showLoader.Quotation = false;
            setTimeout(function () {}.bind(this), 600);
            let viewPDF = res;
            if (viewPDF.response_code == 1) {
              window.open(viewPDF.pdfPath, "_blank");
            }
          });
        //  this.editableStep.Quotation = false ;
      });
  }

  //------------------------------------ SAVE QUOTE PLAN ------------------------------------------
  getQuotePlan(frame) {
    if (frame != "") {
      if (this.accept_terms == false) {
        return;
      }
      if (this.accept_disclaimer == false) {
        return;
      }

      frame.hide();
    }

    this.showLoader.Quotation = true;
    this.showLoader.referal = false;
    // let vatAMt =
    //   Math.round(this.totalFixPremium + this.totalVariablePremium) * 0.05;
      let vatAMt =
      ((this.totalFixPremium + this.totalVariablePremium) * 0.05).toFixed(2); //rounding off issue fixed
    this.vatAMt = vatAMt;
    let total_premium =
      Number(this.totalFixPremium) + Number(this.totalVariablePremium) + Number(vatAMt);
    this.total_premium = total_premium;
    // let loadingAmt =
    //   this.calculBy == 1 ? this.loadingByPercent :  Number(this.LDAmount);
    let loadingAmt = Number(this.LDAmount);
    //   this.PlanDataJson.planBenefitData.benefit_data = this.tempBenefitData
    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      // base_premium: Math.round(this.totalFixPremium),
      base_premium: this.totalFixPremium.toFixed(2),
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      //product_code: this.insuredDetailForm.value.adtnl_detail_insType,
      product_code: {
        value: this.insuredDetailForm.value.adtnl_detail_insType.crsProdId,
        viewValue:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
        WebProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductCode,
        CoreProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode,
      },
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,
      calculation_type: this.calculBy,
      loading_by: this.loadingBy,
      loading_amount: loadingAmt,
      loadis_rate: this.loading_rate,
      premiumGarageAmount: this.repairLoadingAmt,
    };
//Added by Anju to get the selected partner & branch for credit limit check
this.selectedPartnerId=this.insuredDetailForm.value.partner;
this.selectedBranchId = this.insuredDetailForm.value.branch.Id;
    //RSA bronxze default to be selected if no other RSA covers or Optional Covers are selected
    console.log("Before",this.PlanDataJson.planBenefitData.data)
    // //ruhina commented remove below code - rsa bronze  
    // this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
    //   if(!this.IsTPL && item.MultiOptionFlagName == 'RSA' && item.BenefitId != this.planBenefitIDforBronze && item.checked==true)
    //   {
    //     this.rSASelected=true;
    //   }
    // })
 
    // if( !this.rSASelected)
    // {
    //     this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
    //       if(!this.IsTPL && item.MultiOptionFlag == 1 && item.MultiOptionFlagName == 'RSA' )
    //       {
    //         if(item.checked == false)
    //         {
    //           var index1 = this.PlanDataJson.planBenefitData.data.findIndex(obj => obj.BenefitId == this.planBenefitIDforBronze);
    //           this.PlanDataJson.planBenefitData.data[index1].checked = true;
    //         }
    //       }
    //     })
    // }
    // else
    // {
    //     var index1 = this.PlanDataJson.planBenefitData.data.findIndex(obj => obj.BenefitId == this.planBenefitIDforBronze);
    //     this.PlanDataJson.planBenefitData.data[index1].checked = false;
    // }

    // //ruhina commented 

    //Added Partner Credit Limit check by Anju
    this.motorQuoteService
    .checkPartnerCreditLimit("Motor",this.total_premium,this.selectedPartnerId,this.selectedBranchId)
    .subscribe((result) => {
      console.log(result);
      //Changed the cl_msg to cl_status to use status code
    if(result.cl_status!='200012' && ((result.cl_status == "1") && this.policyStatus == "ISSUEPOLICY") || this.policyStatus != "ISSUEPOLICY")
    {
    console.log("After",this.PlanDataJson.planBenefitData.benefit_data)
    this.motorQuoteService
      .insertPlanData(
        this.PlanDataJson.quotationNumber,
        this.PlanDataJson.PlanDataJson,
        // Math.round(this.totalFixPremium),
        (this.totalFixPremium).toFixed(2), //rounding off issue fixed
        this.totalVariablePremium,
        this.policyFee,
        total_premium,
        //this.PlanDataJson.thirdPartyAPIData.Covers, //send the cover response from IIRIS -ruhina
         this.PlanDataJson.planBenefitData.data, 
        this.PlanDataJson.planBenefitData.benefit_data,
        this.insuredDetailForm.value.adtnl_detail_repairTye,
        this.savePlanDetailArr,
        this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
        "B2B",
        this.SchemeCode,
        "",
        "",
        "",
        ""
      )
      .subscribe((res) => {
        if (this.policyStatus == "ISSUEPOLICY") {
          this.PaymentMode = "CRD";
          localStorage.setItem("pageStatus", "ISSUPOLICY");
          this.saveAdditionalInfo(3);
        }

        if (this.policyStatus == "PAYMENT") {
          this.PaymentMode = "ONL";
          localStorage.setItem("pageStatus", "PAYMENT");
          Swal.fire(
            "Your details will be saved and you will be re-directed to Payment gateway page",
            " Do not click back or click any button",
            "success"
          );
          this.saveAdditionalInfo(2);
        }

        if (this.policyStatus == "SENDPAYMENTLINK") {
          this.PaymentMode = "ONL";//Added by Anju for bypassing Credit Limit Check
          this.saveAdditionalInfo(4);
        }

        if (this.policyStatus == "REFERED") {
          this.showLoader.Quotation = false;
          Swal.fire(
            "Your quote has been referred to our expert team of UWs for review.",
            "You will be notified soon after the review. Your Quotation Reference# " +
              this.webQuoteNumber,
            "success"
          );
          this._route.navigate(["agentMotor/motorquote"]);
        }

        if (this.policyStatus == "REJECTED") {
          this.showLoader.Quotation = false;
          Swal.fire(
            "",
            "Quotation " +
              this.webQuoteNumber +
              " has been rejected and a notification is sent to the user.",
            "success"
          );
          this._route.navigate(["agentMotor/motorquote"]);
        }

        if (this.policyStatus == "APPROVED") {
          this.saveAdditionalInfo(1);
          this.showLoader.Quotation = false;
          Swal.fire(
            "",
            "Quotation " +
              this.webQuoteNumber +
              " has been approved and sent to user for policy issuance.",
            "success"
          );
          this._route.navigate(["agentMotor/motorquote"]);
        }

        if (this.policyStatus == "ADDITIONALINFO") {
          this.showLoader.Quotation = false;
          Swal.fire(
            "",
            "Quotation " +
              this.webQuoteNumber +
              " has been sent to user to provide additional information as requested.",
            "success"
          );
          this._route.navigate(["agentMotor/motorquote"]);
        }
      });
    }
  else{
    if(result.cl_status =='200012'){
    Swal.fire("Party not active.");
    }
    else
    {
      Swal.fire("Party credit limit exceeded.");
    }
    this.showLoader.Quotation = false;
  }
  
  });
  }
  //------------------------------ SEND EMAIL ---------------------------------------------//
  getQuotePlanEmail() {
    this.showLoader.Quotation = true;
    // this.policyFee = this.vehicleDetailForm.value.insType.value == 200 ? 50 : 0;
    // let vatAmt = (this.totalFixPremium + this.totalVariablePremium + this.policyFee) * 0.05;
    // let total_premium = this.totalFixPremium + this.totalVariablePremium + this.policyFee + vatAmt;

    let vatAMt = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
    let total_premium =
    Number(this.totalFixPremium) + Number(this.totalVariablePremium) + Number(vatAMt);
    // let loadingAmt =
    //   this.calculBy == 1 ? this.loadingByPercent :   Number(this.LDAmount);
    let loadingAmt = Number(this.LDAmount);
    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      // base_premium: Math.round(this.totalFixPremium),
      base_premium: (this.totalFixPremium).toFixed(2), //rounding off issue fixed
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      //product_code: this.insuredDetailForm.value.adtnl_detail_insType,
      product_code: {
        value: this.insuredDetailForm.value.adtnl_detail_insType.crsProdId,
        viewValue:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
        WebProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductCode,
        CoreProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode,
      },
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,
      calculation_type: this.calculBy,
      loading_by: this.loadingBy,
      loading_amount: loadingAmt,
      loadis_rate: this.loading_rate,
      premiumGarageAmount: this.repairLoadingAmt,
    };

    this.motorQuoteService
      .insertPlanDataEmail(
        this.PlanDataJson.quotationNumber,
        this.PlanDataJson.PlanDataJson,
        this.totalFixPremium,
        this.totalVariablePremium,
        this.policyFee,
        total_premium,
        this.PlanDataJson.planBenefitData.data,
        this.PlanDataJson.planBenefitData.benefit_data,
        this.insuredDetailForm.value.adtnl_detail_repairTye,
        this.savePlanDetailArr,
        "Individual",
        this.SchemeCode
      )
      .subscribe((res) => {
        this.sendMail();
        this.showLoader.Quotation = false;
        //  this.editableStep.Quotation = false ;

        Swal.fire(
          "",
          "Thank you for choosing Fidelity United for insuring your car. We have sent an email to your registered email with all the quotation details. Your Quotation Reference# " +
            this.webQuoteNumber,
          "success"
        );
      });
  }

  sendMail() {
    this.motorQuoteService
      .sendMail(this.webQuoteNumber, this.emailId)
      .subscribe((res) => {});
  }

  getQuotation1() {
    this.sendRefferMailToAdmin(1, "");
    //Upon submitting to referral after get quote - it creates new quote number  - commented below things by ruhina - directyly go for referral 
    //     // if (this.insuredDetailForm.value.rg_model_year.value >= (this.minyear - 1) && this.insuredDetailForm.value.adtnl_detail_insType.value == 100 && this.insuredDetailForm.value.adtnl_detail_brandNew != 1) {
    // //     this.insuredDetailForm.controls['rg_reg_date'].setValidators([Validators.required]);

    // //     if (this.insuredDetailForm.value.adtnl_detail_brandNew != 1) {
    // //       if (this.regMaxDate < this.insuredDetailForm.value.rg_reg_date || this.regMinDate > this.insuredDetailForm.value.rg_reg_date) {
    // //           Swal.fire("Invalid 1st registration date","",'error');
    // //           return false;
    // //       }
    // //     }
    // // } else {
    // //           this.insuredDetailForm.controls['rg_reg_date'].setValidators(null);
    // //       }

    // //     this.insuredDetailForm.controls['rg_reg_date'].updateValueAndValidity();

    // // if (Number(this.insuredDetailForm.value.adtnl_detail_vhclValue) < Number(this.vehicleValueLimit.startLimit) || Number(this.insuredDetailForm.value.adtnl_detail_vhclValue) > Number(this.vehicleValueLimit.endLimit)) {
    // //     return false;
    // //   }

    // // this.showLoader.Quotation = true;

    // let modifyValue =
    //   this.existVehiclevalue == 1 ? this.existVehiclevalue : null;
    // // let dob = this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == '100' ? this.convertDate(this.insuredDetailForm.value.d_dob) : '';
    // let repairType =
    //   this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100"
    //     ? this.insuredDetailForm.value.adtnl_detail_repairTye
    //     : { value: "Garage", viewValue: "Garage" };
    // let vehicle_value =
    //   this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100"
    //     ? this.insuredDetailForm.value.adtnl_detail_vhclValue
    //     : 0;
    // let brand_new =
    //   this.insuredDetailForm.value.rg_model_year.value != this.maxYear ||
    //   this.insuredDetailForm.value.rg_model_year.value != this.year ||
    //   this.insuredDetailForm.value.rg_model_year.value != this.minyear
    //     ? { code: "0", value: "N" }
    //     : { code: "1", value: "Y" };
    // let vehicleType =
    //   this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == "100"
    //     ? { code: "1001", value: "Private" }
    //     : { code: "1001", value: "Private" };
    // // let policy_expire_date = this.insuredDetailForm.value.vhclTPLCoverage == 'No' ? this.nextDate : this.PrevDate;
    // let policy_expire_date = this.convertDate(
    //   this.insuredDetailForm.value.rg_ins_exp
    // );
    // let emiratesId = this.insuredDetailForm.value.e_eid.replace(/-/gi, "");
    // // var sCode = "ALL";
    // let policy_status = this.isRenewal == 1 ? "RENEWAL" : "NEW";

    // // if (this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName == "BMW" || this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName == "MINI") {
    // //   if (this.SchemeCode == "104")
    // //     sCode = "104";
    // // }
    // // else if (this.SchemeCode == "104") {
    // //   sCode = "ALL";
    // // }

    // let motorArrayQuote = {
    //   cedant_broker_id: this.insuredDetailForm.value.partner,
    //   broker_branch_id: this.insuredDetailForm.value.branch,
    //   insured_type: this.insuredDetailForm.value.adtnl_detail_vhclOwnBy,
    //   insured_name: this.insuredDetailForm.value.e_name,
    //   gender: this.insuredDetailForm.value.e_gender,
    //   dob: this.convertDate(this.insuredDetailForm.value.d_dob),
    //   prospect_age: this.calucateAge(this.insuredDetailForm.value.d_dob, 1),
    //   nationality: this.insuredDetailForm.value.e_nationality,
    //   email: this.insuredDetailForm.value.adtnl_detail_email,
    //   mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
    //   code: this.insuredDetailForm.value.adtnl_detail_mbCode,
    //   UAE_lic_age: this.convertDate(this.insuredDetailForm.value.d_issue_date),
    //   UAELicAge: this.calucateAge(this.insuredDetailForm.value.d_issue_date, 2),
    //   license_country: this.insuredDetailForm.value.e_nationality,
    //   NCD: this.insuredDetailForm.value.adtnl_detail_NCD,
    //   model_year: this.insuredDetailForm.value.rg_model_year,
    //   make: this.insuredDetailForm.value.rg_vhcl_make,
    //   model: this.insuredDetailForm.value.rg_vhcl_model,
    //   trim: this.insuredDetailForm.value.adtnl_detail_trim,
    //   body_type: this.insuredDetailForm.value.adtnl_detail_bodyType,
    //   cylinders: this.insuredDetailForm.value.adtnl_detail_cylinder,
    //   passenger: this.insuredDetailForm.value.rg_num_passenger,
    //   registered_date: this.convertDate(
    //     this.insuredDetailForm.value.rg_reg_date
    //   ),
    //   GCCSpecified: this.insuredDetailForm.value.adtnl_detail_gccSpecified,
    //   vhclModified: this.insuredDetailForm.value.adtnl_detail_vhclModified,
    //   registered_place: this.insuredDetailForm.value.rg_place_issue,
    //   chassis_no: this.insuredDetailForm.value.rg_chassis_num,
    //   repair_type: repairType,
    //   sum_insured: vehicle_value,
    //   emirates_id: emiratesId,
    //   //policy_type: this.insuredDetailForm.value.adtnl_detail_insType,
    //   policy_type: {
    //     value: this.insuredDetailForm.value.adtnl_detail_insType.crsProdId,
    //     viewValue:
    //       this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
    //     WebProdCode:
    //       this.insuredDetailForm.value.adtnl_detail_insType.ProductCode,
    //     CoreProdCode:
    //       this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode,
    //   },
    //   ExistingCover: this.insuredDetailForm.value.vhclTPLCoverage,
    //   policy_expire_date: this.convertDate(policy_expire_date),
    //   brand_new: brand_new,
    //   customer_id: "0",
    //   lead_id: "0",
    //   NCD_docs: "No",
    //   registration_type: vehicleType,
    //   VEHENGINESIZE: "1",
    //   source: "B2B",
    //   document_data: this.tempDocArray,
    //   multiple_doc_data: this.tempMultipleDocData,
    //   GVM: this.insuredDetailForm.value.rg_gvm,
    //   origin: this.insuredDetailForm.value.rg_origin,
    //   policy_num: this.insuredDetailForm.value.rg_policy_num,
    //   eid_card_num: this.insuredDetailForm.value.e_cardNumber,
    //   reg_ins_exp_date: this.convertDate(
    //     this.insuredDetailForm.value.rg_ins_exp
    //   ),
    //   reg_exp_date: this.convertDate(
    //     this.insuredDetailForm.value.rg_expiry_date
    //   ),
    //   eid_exp_date: this.convertDate(this.insuredDetailForm.value.e_expiryDate),
    //   driv_lic_issue_date: this.convertDate(
    //     this.insuredDetailForm.value.d_issue_date
    //   ),
    //   driv_lic_exp_date: this.convertDate(
    //     this.insuredDetailForm.value.d_expiry_date
    //   ),
    //   driv_lic_issuePlace: this.insuredDetailForm.value.d_place_issue,
    //   name_in_arabic: this.insuredDetailForm.value.e_arabic_name,
    //   traffic_code: this.insuredDetailForm.value.rg_TC_num,
    //   license: this.insuredDetailForm.value.d_driv_lic_num,
    //   mortgage: this.insuredDetailForm.value.rg_mortgage,
    //   plate_number: this.insuredDetailForm.value.rg_traffic_plate_num,
    //   plate_code: this.insuredDetailForm.value.rg_plateCode,
    //   plate_category: this.insuredDetailForm.value.rg_type,
    //   driv_lic_TC_number: this.insuredDetailForm.value.d_TC_number,
    //   driv_lic_type: this.insuredDetailForm.value.d_lic_type,
    //   vehicleColor: this.insuredDetailForm.value.vhclColor,
    //   SchemeCode: this.SchemeCode,
    //   modifiedVhclValue: modifyValue,
    //   start_date: this.convertStartDate(
    //     this.insuredDetailForm.value.polStartDate
    //   ),
    //   policy_status: policy_status,
    //   deductible_value: this.insuredDetailForm.value.deductible,
    //   TRN_number: this.insuredDetailForm.value.TRN_num,
    //   trade_Lic_number: this.insuredDetailForm.value.trade_lic_num,
    //   industry_type: this.insuredDetailForm.value.industry_type,
    //   transaction_type: this.insuredDetailForm.value.transaction_type.label,
    // };
    // console.log(motorArrayQuote);

    // var gender = "";
    // if (this.insuredDetailForm.value.e_gender) {
    //   gender = this.insuredDetailForm.value.e_gender.value;
    // }

    // let policyDetail = {
    //   quotation_number: this.webQuoteNumber,
    //   CRS_quotation_number: this.quoteNumber,
    //   traffic_code: this.insuredDetailForm.value.rg_TC_num,
    //   license: this.insuredDetailForm.value.d_driv_lic_num,
    //   emirates: this.insuredDetailForm.value.rg_place_issue,
    //   address: this.insuredDetailForm.value.adtnl_detail_address,
    //   PO_box: this.insuredDetailForm.value.adtnl_detail_poBoxNum,
    //   occupation: "",
    //   national_id: this.insuredDetailForm.value.e_eid,
    //   arabic_name: "",
    //   home_office_number: "",
    //   start_date: this.convertDate(this.insuredDetailForm.value.polStartDate),
    //   end_date: "22/12/2019",
    //   color: "",
    //   chassis_no: this.insuredDetailForm.value.rg_chassis_num,
    //   engine: this.insuredDetailForm.value.rg_engin_num,
    //   plate: "54687",
    //   mortgage: this.insuredDetailForm.value.rg_mortgage,
    //   payment_mode: "ONLINE",
    //   named_driver: this.insuredDetailForm.value.e_name,
    //   additional_driver_under_age: "0",
    //   IP_address: "",
    //   mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
    //   code: this.insuredDetailForm.value.adtnl_detail_mbCode,
    //   driving_License_Front: this.insuredDetailForm.value.driving_Lic_Front,
    //   driving_license_Back: "",
    //   Reg_Card_Front: this.insuredDetailForm.value.reg_Card_Front,
    //   Reg_Card_Back: this.insuredDetailForm.value.reg_Card_Back,
    //   EID_Front: this.insuredDetailForm.value.emirated_ID_Front,
    //   EID_Back: this.insuredDetailForm.value.emirated_ID_Backk,
    //   dealer_quote: "",
    //   vhclPlateCategory: this.insuredDetailForm.value.rg_type,
    //   plateCode: this.insuredDetailForm.value.rg_plateCode,
    //   plateNumber: this.insuredDetailForm.value.rg_traffic_plate_num,
    //   area: this.insuredDetailForm.value.adtnl_detail_area,
    //   noOfDoors: this.nofDoors,
    //   gender: gender,
    //   nationality: this.insuredDetailForm.value.e_nationality,
    //   source: "B2B",
    //   promoCode: this.insuredDetailForm.value.promoCode,
    // };

    // this.quoteDetailArr = motorArrayQuote;
    // this.emailId = this.insuredDetailForm.value.adtnl_detail_email;
    // this.motorQuoteService
    //   .getInsertQuote(motorArrayQuote, policyDetail)
    //   .subscribe((res) => {
    //     this.webQuoteNumber = res.web_quotation_number;
    //     this.sendRefferMailToAdmin(1, "");
    //   });
  }
  //--------------------------------------- GET USER ACCESS PERMISSION -------------------------------------------//
  getUserAccessPermission(stepper, type, stepperType) {
    const controls = this.insuredDetailForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
          console.log("invalid form",controls[name])
      }
  }
    console.log("Form Data Status",this.insuredDetailForm)
    console.log("getUserAccessPermission",stepper,type,stepperType)
    this.checkEID(this.insuredDetailForm.value.e_eid);
    this.checkStepper = true;

    //this.insuredDetailForm.controls["e_expiryDate"].setValidators(null);
    this.insuredDetailForm.controls["e_expiryDate"].updateValueAndValidity
    if(this.insuredDetailForm.controls["e_expiryDate"].invalid)
    {
      this.insuredDetailForm.get('e_expiryDate').setValue("")
    }
    if (type == 1 || type == 2) {
      this.insuredDetailForm.markAllAsTouched();
      if (this.invalidEID == true) {
        this.validtnMsg = true;
        this.insuredDetailForm.get("eIDCheck").setValue("");
        return false;
      }

      if (
        this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100"
      ) {
        this.insuredDetailForm.controls["adtnl_detail_repairTye"].setValidators(
          [Validators.required]
        );
        this.insuredDetailForm.controls["adtnl_detail_vhclValue"].setValidators(
          [
            Validators.required,
            Validators.min(this.vehicleValueLimit.startLimit),
            Validators.max(this.vehicleValueLimit.endLimit),
          ]
        );
        this.IsTPL = false
      } else {
        // this.insuredDetailForm.controls['adtnl_detail_repairTye'].setValidators(null);
        this.insuredDetailForm.controls["adtnl_detail_vhclValue"].setValidators(
          null
        );
        this.insuredDetailForm.get('adtnl_detail_gccSpecified').setValue('No'); // hide vehicle non modified if tpl - ruhina
        this.insuredDetailForm.get('adtnl_detail_vhclModified').setValue('No'); // hide vehicle non modified if tpl - ruhina
        this.IsTPL = true // condn added to hide vehicle value field if it is TPL -ruhina
      }

      this.insuredDetailForm.controls[
        "adtnl_detail_repairTye"
      ].updateValueAndValidity();
      this.insuredDetailForm.controls[
        "adtnl_detail_vhclValue"
      ].updateValueAndValidity();
      this.insuredDetailForm.controls[
        "rg_expiry_date"
      ].updateValueAndValidity(); //ruhina
      if (this.insuredDetailForm.value.rg_place_issue.CityName == "Dubai") { //Added by Anju for TC validation for Ras Al Khaimah
        // if (this.insuredDetailForm.value.rg_place_issue.CityName == "Dubai") {
          
          this.insuredDetailForm.controls["rg_TC_num"].setValidators([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(8),
          ]);
          // this.insuredDetailForm.controls['d_TC_number'].setValidators([ Validators.minLength(8), Validators.maxLength(8),]);
        } else {
          this.insuredDetailForm.controls["rg_TC_num"].setValidators([
            Validators.required,
            Validators.minLength(10), // added minValidatorLength as 8 
            Validators.maxLength(10),
          ]);
          // this.insuredDetailForm.controls['d_TC_number'].setValidators([ Validators.minLength(10), Validators.maxLength(10),]);
        }

      this.insuredDetailForm.controls["rg_TC_num"].updateValueAndValidity();
      // this.insuredDetailForm.controls['d_TC_number'].updateValueAndValidity();

      if (this.insuredDetailForm.value.adtnl_detail_brandNew == 1) {
        this.insuredDetailForm.controls['rg_plateCode'].setValidators(null);// if brand new make plate code - non mandatory - ruhina
        this.insuredDetailForm.controls['rg_traffic_plate_num'].setValidators(null); // if brand new make plate code - non mandatory - ruhina
        this.insuredDetailForm.controls["rg_reg_date"].setValidators(null);


      } else {
        // this.insuredDetailForm.controls['rg_plateCode'].setValidators([Validators.required]);
        // this.insuredDetailForm.controls['rg_traffic_plate_num'].setValidators([Validators.required]);
        this.insuredDetailForm.controls["rg_reg_date"].setValidators([
          Validators.required,
        ]);
      }

       this.insuredDetailForm.controls['rg_plateCode'].updateValueAndValidity(); // if brand new make plate code - non mandatory - ruhina
       this.insuredDetailForm.controls['rg_traffic_plate_num'].updateValueAndValidity(); // if brand new make plate code - non mandatory - ruhina
      this.insuredDetailForm.controls["rg_reg_date"].updateValueAndValidity();

      if (
        this.insuredDetailForm.value.adtnl_detail_NCD == "null"
      ) {
        this.insuredDetailForm.controls["adtnl_detail_NCD"].setValidators(
          [Validators.required]
        );
      }

      

      if (this.insuredDetailForm.value.rg_place_issue.CityName == "Dubai") {
        this.insuredDetailForm.controls["rg_plateCode"].setValidators(
          [Validators.required]
        );
        if( this.insuredDetailForm.value.rg_plateCode.PlateCode != 'NEW NUMBER' ){
          this.insuredDetailForm.controls["rg_traffic_plate_num"].setValidators(
            [Validators.required]
          );
        }
        else
        {
          this.insuredDetailForm.controls['rg_traffic_plate_num'].setValidators(null); 
        }
        }
        else{
        if (
          this.insuredDetailForm.value.rg_plateCode != "null" && this.insuredDetailForm.value.rg_plateCode != "" && this.insuredDetailForm.value.rg_plateCode != undefined && this.insuredDetailForm.value.rg_plateCode.PlateCode != 'NEW NUMBER' 
        ) {
        this.insuredDetailForm.controls["rg_traffic_plate_num"].setValidators(
          [Validators.required]
        );}
        else
        {
          this.insuredDetailForm.controls['rg_traffic_plate_num'].setValidators(null); 
        }
      }
      this.insuredDetailForm.controls['rg_plateCode'].updateValueAndValidity(); 
        this.insuredDetailForm.controls['rg_traffic_plate_num'].updateValueAndValidity(); 

      if (
        this.tempMultipleDocData.length == 0 &&
        this.tempDocArray.length == 0
      ) {
        Swal.fire("", "Please upload mandatory documents", "error");
        return false;
      }

      if (
        this.tempMultipleDocData.length == 0 &&
        this.tempDocArray.length >= 0
      ) {
        if (this.insuredDetailForm.value.MulkhiyaStatus == "Yes") {
          if (this.insuredDetailForm.value.reg_Card_BackFilePath == "") {
            Swal.fire(
              "",
              "Please upload the vehicle registration back copy",
              "error"
            );
            return false;
          } else if (
            this.insuredDetailForm.value.reg_Card_FrontFilePath == ""
          ) {
            Swal.fire(
              "",
              "Please upload the vehicle registration back copy",
              "error"
            );
            return false;
          }
          else if (
            this.insuredDetailForm.value.driving_Lic_FrontFilePath == "" &&
            this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue !=
              "Corporate"
          ) {
            Swal.fire("", "Please upload driving license front copy", "error");
            return false;
          } else if (
            this.insuredDetailForm.value.driving_Lic_BackFilePath == "" &&
            this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue !=
              "Corporate"
          ) {
            Swal.fire("", "Please upload driving license back copy", "error");
            return false;
          }
        } else if (this.insuredDetailForm.value.MulkhiyaStatus == "No") {
          if (this.insuredDetailForm.value.supporting_DocumentFilePath == "") {
            Swal.fire(
              "",
              "Please upload mandatory supporting document copy",
              "error"
            );
            return false;
          }
        } else if (
          this.insuredDetailForm.value.driving_Lic_FrontFilePath == "" &&
          this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue !=
            "Corporate"
        ) {
          Swal.fire("", "Please upload driving license front copy", "error");
          return false;
        } else if (
          this.insuredDetailForm.value.driving_Lic_BackFilePath == "" &&
          this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue !=
            "Corporate"
        ) {
          Swal.fire("", "Please upload driving license back copy", "error");
          return false;
        }
      }
      console.log("Vehicle Own By",this.insuredDetailForm.value.adtnl_detail_vhclOwnBy)
      if (
        this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue ==
          "Corporate" &&
        this.insuredDetailForm.value.trade_LicenceFilePath == ""
      ) {
        Swal.fire("", "Please upload Trade License copy", "error");
        return false;
      }
      if (
        this.insuredDetailForm.value.vat_register == "Yes" &&
        this.insuredDetailForm.value.TRN_CertificateFilePath == ""
      ) {
        Swal.fire("", "Please upload TRN certificate copy", "error");
        return false;
      }

      if (
        this.stripTime( new Date( this.insuredDetailForm.value.polStartDate)) <     this.stripTime( new Date (this.policyMinDate))
      ) {
        Swal.fire("", "Policy start date cannot be less than today's date.", "error");
        return false;
      }

         //Added by Anju for GCC Validation 

         if (this.insuredDetailForm.value.adtnl_detail_gccSpecified=='Yes') {   // conditon added by shitala for validation for GCC 
          this.validtnMsg = true;
          Swal.fire("Vehicle Non GCC Specs","Please choose the NON GCC if your vehicle is NON GCC specifications.", "error");
          return false;
        }
  


        //Added by Anju for Vehicle Age Validation
   if(this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName.toLowerCase() =="comprehensive" && this.vehicle_age>13 && this.insuredDetailForm.value.adtnl_detail_brandNew != 1)
   {
    Swal.fire("", "Vehicle age is more than 13 years. Please issue as TPL", "error");
    return false;
   }
      // if(this.convertDate(this.insuredDetailForm.value.polStartDate) > this.convertDate(this.insuredDetailForm.value.rg_ins_exp)){
      //     Swal.fire('', 'Invalid previous policy insurance expiry date selected.', 'error');
      //     return false;
      // }

      // comment on 18 Nov
      // if(this.convertDate(this.insuredDetailForm.value.rg_ins_exp) >= this.convertDate(this.toDay) && this.convertDate(this.insuredDetailForm.value.polStartDate) >= this.convertDate(this.toDay)){
      //   let insExpDate =  new Date(new Date(this.insuredDetailForm.value.rg_ins_exp).setDate(new Date(this.insuredDetailForm.value.rg_ins_exp).getDate() + 1));

      //         if(this.convertDate(this.insuredDetailForm.value.polStartDate) > this.convertDate(insExpDate))
      //         {
      //           Swal.fire('', 'Policy start date cannot be later than previous insurance expiry date.', 'error');
      //           return false;

      //         }
      // }else{

      //         Swal.fire('', 'Invalid policy start date and/or previous insurance exp date.', 'error');
      //         return false;
      // }

      if (
        this.insuredDetailForm.value.rg_model_year.value >= this.minyear - 1 &&
        this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100" &&
        this.insuredDetailForm.value.adtnl_detail_brandNew != 1
      ) {
        this.insuredDetailForm.controls["rg_reg_date"].setValidators([
          Validators.required,
        ]);

        if (this.insuredDetailForm.value.adtnl_detail_brandNew != 1) {
          //   if (this.regMaxDate < this.insuredDetailForm.value.rg_reg_date || this.regMinDate > this.insuredDetailForm.value.rg_reg_date) {
          //     Swal.fire("Invalid 1st registration date", "", 'error');
          //     if (stepperType == 0) {
          //       stepper.previous();
          //     }
          //     return false;
          //   }
        }
      } else {
        // this.insuredDetailForm.controls['rg_reg_date'].setValidators(null);
      }

      this.insuredDetailForm.controls["rg_reg_date"].updateValueAndValidity();

      if (this.insuredDetailForm.invalid) {
        this.validtnMsg = true;
        Swal.fire("", "Please fill all the mandatory data", "error");
        return false;
      }
      // removed invalid NCD as we have covid discount - cant compare with Vehcile Age - ruhina
      // if (
      //   this.insuredDetailForm.value.adtnl_detail_NCD.NCDCode > this.vehicle_age
      // ) {
      //   Swal.fire("", "Invalid NCD value selected.", "error");
      //   return false;
      // }
      //ruhina end
      if (this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100")
        if (
          Number(this.insuredDetailForm.value.adtnl_detail_vhclValue) <
            Number(this.vehicleValueLimit.startLimit) ||
          Number(this.insuredDetailForm.value.adtnl_detail_vhclValue) >
            Number(this.vehicleValueLimit.endLimit)
        ) {
          if (stepperType == 0) {
            stepper.previous();
          }
          return false;
        }

      if (
        this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName.toLowerCase() ==
          "Comprehensive" &&
        (this.insuredDetailForm.value.deductible == "" ||
          this.insuredDetailForm.value.deductible == null)
      ) {
        this.referalStatus = true;
      }
    }


    //Reg Expiry Date must not be less than 2 months
    
    // if (this.insuredDetailForm.value.rg_expiry_date < this.checkregExpDate) {
    //   console.log("check expiry date")
    //   // alert(1)
    //   this.referalStatus = true;
    //   this.showLoader.Quotation = false;
    //   this.showLoader.retrieveQuote = false;
    //   this.referalStatus = true;
    //   this.expdateboolean = true;
    //   Swal.fire(
    //     "Registration Expiry is less than 2 months from current Date \n You need to do Refer a Quote to proceed further",
    //     "Thank you for your request, based on your application details, the case needs to be referred for further review. \n An email will be sent to you shortly. If you need further assistance call us at 800-842",
    //     "error"
    //   );

    //   // Swal.fire(
    //   //   "Thank you for you request, based on your application details, the case needs to be referred for further review.",
    //   //   "An email will be sent to you shortly. If you need further assistance call us at 800-842",
    //   //   "error"
    //   // );
    //   stepper.previous();
    //   return false;
    // } else {
    // }
    this.validtnMsg = false;
    this.showLoader.Quotation = true;
    this.showLoader.retrieveQuote = true;
    console.log("before checkUseraccess")
    this.motorQuoteService
      .checkUserAccess("MOTORQUOTE", this.localStorageData.EmailAddress, "MT")
      .subscribe((res) => {
        console.log("User Access res",res)
        let response = res;
        //  this.checkStepper = false;
        if (response.userAccessData == 0) {
          this.showLoader.Quotation = false;
          this.showLoader.retrieveQuote = false;
          Swal.fire("", "Permission Denied", "error");
          stepper.previous();
          return false;
        } else {
          if (type == 1) {
            this.getQuotation(stepper, stepperType);
          }

          if (type == 2) {
            this.executed = false;
            this.updateQuotation(stepper, stepperType);
          }
        }
      });
  }

  onPrevInsExpdateChange() {
    // if(this.convertDate(this.insuredDetailForm.value.polStartDate) > this.convertDate(this.insuredDetailForm.value.rg_ins_exp)){

    if (
      this.convertDate(this.insuredDetailForm.value.rg_ins_exp) >=
        this.convertDate(this.toDay) &&
      this.convertDate(this.insuredDetailForm.value.polStartDate) >=
        this.convertDate(this.toDay)
    ) {
      let insExpDate = new Date(
        new Date(this.insuredDetailForm.value.rg_ins_exp).setDate(
          new Date(this.insuredDetailForm.value.rg_ins_exp).getDate() + 1
        )
      );
      if (
        this.convertDate(insExpDate) >=
        this.convertDate(this.insuredDetailForm.value.polStartDate)
      ) {
        return true;
      }
      if (
        this.convertDate(this.insuredDetailForm.value.polStartDate) >
        this.convertDate(insExpDate)
      ) {
        Swal.fire(
          "",
          "Invalid start date and/or previous insurance expiry date.",
          "error"
        );
        return false;
      }
    } else {
      Swal.fire(
        "",
        "Iinvalid start date or previous insurance exp date.",
        "error"
      );
      return false;
    }

    // this.insuredDetailForm.get('polStartDate').setValue(insExpDate);
    // }
  }
  //------------------------------- VALIDATION FOR EMIRATES ID ----------------------------------------------//
  checkEID(value) {
    value = this.insuredDetailForm.value.e_eid;
    this.value = value;

    this.value = this.value.replace(/-/g, "");
    if (/[^0-9-]+/.test(this.value)) this.invalidEID = true;

    // if (this.value.length != 15) {

    //   this.invalidEID = true;
    //   this.insuredDetailForm.get("eIDCheck").setValue('');
    //   return this.invalidEID;
    // }
    if (
      this.value == "111111111111111" ||
      this.value == "000000000000000" ||
      this.value == "222222222222222" ||
      this.value == "333333333333333"
    ) {
      this.invalidEID = false;
      this.insuredDetailForm.get("eIDCheck").setValue("1");

      return this.invalidEID;
    } else {
      //The Luhn Algorithm.
      var nCheck = 0,
        nDigit = 0,
        bEven = false;
      this.value = this.value.replace(/\D/g, "");

      //784-1982-6961498-2

      for (let n = this.value.length - 1; n >= 0; n--) {
        var cDigit = this.value.charAt;
        nDigit = parseInt(this.value[n]);

        if (bEven) {
          if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
      }

      if (nCheck % 10 == 0) {
        //if valid, ok, check next
        this.invalidEID = false;
        this.insuredDetailForm.get("eIDCheck").setValue(1);

        return this.invalidEID;
      } else {
        this.invalidEID = true;
        this.insuredDetailForm.get("eIDCheck").setValue("");

        return this.invalidEID;
        //alert('Invalid Emirates ID. Please enter valid emirates Id including (-) dash/hypen.');
      }
    }
  }
  //---------------------- VALIDATION FOR NUMBERS & DECIMAL NUM ----------------------------------------//
  checkValidInputData(event: any, type) {
    this.globalService.checkValidInputData(event, type);
  }
  preventAllEvents(event: any) {
    this.globalService.preventAllEvents(event);
  }

  //------------------------------- UPDATE QUOTATION ----------------------------------------------//
  updateQuotation(stepper, type) {
    this.isLoadingDisabled = false;
    if(this.executed)
    {
      stepper.previous();
      return;
    }
    //   if(this.insuredDetailForm.value.rg_model_year.value >= (this.minyear - 1) && this.insuredDetailForm.value.adtnl_detail_insType.value == 100 && this.insuredDetailForm.value.adtnl_detail_brandNew != 1) {
    //          this.insuredDetailForm.controls['rg_reg_date'].setValidators([Validators.required]);

    //     if(this.insuredDetailForm.value.adtnl_detail_brandNew != 1) {
    //           if (this.regMaxDate < this.insuredDetailForm.value.rg_reg_date || this.regMinDate > this.insuredDetailForm.value.rg_reg_date) {
    //             return false;
    //           }
    //      }
    //  } else{
    //           this.insuredDetailForm.controls['rg_reg_date'].setValidators(null);
    //     }

    //     this.insuredDetailForm.controls['rg_reg_date'].updateValueAndValidity();

    //   if(Number(this.insuredDetailForm.value.adtnl_detail_vhclValue) < Number(this.vehicleValueLimit.startLimit) || Number(this.insuredDetailForm.value.adtnl_detail_vhclValue) > Number(this.vehicleValueLimit.endLimit)) {
    //          return false;
    //     }

    // this.showLoader.Quotation = true;
    // if (type != 0){
    //   stepper.next();
    // }
    this.loggedInUserRole = localStorage.getItem('currentUser')
    this.loggedInUserRole = JSON.parse(this.loggedInUserRole)
    let modifyValue =
      this.existVehiclevalue == 1 ? this.existVehiclevalue : null;
    // let dob = this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == '100' ? this.convertDate(this.insuredDetailForm.value.d_dob) : '';
    let repairType =
      this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100"
        ? this.insuredDetailForm.value.adtnl_detail_repairTye
        : { value: "Garage", viewValue: "Garage" };
    let vehicle_value =
      this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100"
        ? this.insuredDetailForm.value.adtnl_detail_vhclValue
        : 0;
    let brand_new =
      this.insuredDetailForm.value.rg_model_year.value != this.maxYear ||
      this.insuredDetailForm.value.rg_model_year.value != this.year ||
      this.insuredDetailForm.value.rg_model_year.value != this.minyear
        ? { code: "0", value: "N" }
        : { code: "1", value: "Y" };
    let vehicleType =
      this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == "100"
        ? { code: "1001", value: "Private" }
        : { code: "1001", value: "Private" };
    let policy_expire_date =
      this.insuredDetailForm.value.vhclTPLCoverage == "No"
        ? this.nextDate
        : this.PrevDate;
    let emiratesId = this.insuredDetailForm.value.e_eid.replace(/-/gi, "");
    let policy_status = this.isRenewal == 1 ? "RENEWAL" : "NEW";

    // var sCode = "ALL";

    // if (this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName == "BMW" || this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName == "MINI") {
    //   if (this.SchemeCode == "104")
    //     sCode = "104";
    // }
    // else if (this.SchemeCode == "104") {
    //   sCode = "ALL";
    // }

    let motorArrayQuote = {
      cedant_broker_id: this.insuredDetailForm.value.partner,
      broker_branch_id: this.insuredDetailForm.value.branch,
      quotation_number: this.quoteNumber,
      web_quote_number: this.webQuoteNumber,
      insured_type: this.insuredDetailForm.value.adtnl_detail_vhclOwnBy,
      insured_name: this.insuredDetailForm.value.e_name,
      gender: this.insuredDetailForm.value.e_gender,
      dob: this.convertDate(this.insuredDetailForm.value.d_dob),
      prospect_age: this.calucateAge(this.insuredDetailForm.value.d_dob, 1),
      nationality: this.insuredDetailForm.value.e_nationality,
      email: this.insuredDetailForm.value.adtnl_detail_email,
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      UAE_lic_age: this.convertDate(this.insuredDetailForm.value.d_issue_date),
      UAELicAge: this.calucateAge(this.insuredDetailForm.value.d_issue_date, 2),
      license_country: this.insuredDetailForm.value.e_nationality,
      NCD: this.insuredDetailForm.value.adtnl_detail_NCD,
      model_year: this.insuredDetailForm.value.rg_model_year,
      make: this.insuredDetailForm.value.rg_vhcl_make,
      model: this.insuredDetailForm.value.rg_vhcl_model,
      trim: this.insuredDetailForm.value.adtnl_detail_trim,
      body_type: this.insuredDetailForm.value.adtnl_detail_bodyType,
      cylinders: this.insuredDetailForm.value.adtnl_detail_cylinder,
      passenger: this.insuredDetailForm.value.rg_num_passenger,
      registered_date: this.convertDate(
        this.insuredDetailForm.value.rg_reg_date
      ),
      GCCSpecified: this.insuredDetailForm.value.adtnl_detail_gccSpecified,
      vhclModified: this.insuredDetailForm.value.adtnl_detail_vhclModified,
      registered_place: this.insuredDetailForm.value.rg_place_issue,
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      repair_type: repairType,
      sum_insured: vehicle_value,
      emirates_id: emiratesId,
      // policy_type: this.insuredDetailForm.value.adtnl_detail_insType,
      policy_type: {
        value: this.insuredDetailForm.value.adtnl_detail_insType.crsProdId,
        viewValue:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
        WebProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.ProductCode,
        CoreProdCode:
          this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode,
      },
      ExistingCover: this.insuredDetailForm.value.vhclTPLCoverage,
      policy_expire_date: this.convertDate(policy_expire_date),
      brand_new: brand_new,
      customer_id: "0",
      lead_id: "0",
      NCD_docs: "No",
      registration_type: vehicleType,
      VEHENGINESIZE: "1",
      source: "B2B",
      document_data: this.tempDocArray,
      multiple_doc_data: this.tempMultipleDocData,
      GVM: this.insuredDetailForm.value.rg_gvm,
      origin: this.insuredDetailForm.value.rg_origin,
      policy_num: this.insuredDetailForm.value.rg_policy_num,
      eid_card_num: this.insuredDetailForm.value.e_cardNumber,
      reg_ins_exp_date: this.prevNoIns ? this.convertDate(Date.now())  :this.convertDate(
        this.insuredDetailForm.value.rg_ins_exp
      ),
      reg_exp_date: this.convertDate(
        this.insuredDetailForm.value.rg_expiry_date
      ),
      eid_exp_date: this.convertDate(this.insuredDetailForm.value.e_expiryDate),
      driv_lic_issue_date: this.convertDate(
        this.insuredDetailForm.value.d_issue_date
      ),
      driv_lic_exp_date: this.convertDate(
        this.insuredDetailForm.value.d_expiry_date
      ),
      driv_lic_issuePlace: this.insuredDetailForm.value.d_place_issue,
      name_in_arabic: this.insuredDetailForm.value.e_arabic_name,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      license: this.insuredDetailForm.value.d_driv_lic_num,
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      plate_number: this.insuredDetailForm.value.rg_traffic_plate_num,
      plate_code: this.insuredDetailForm.value.rg_plateCode,
      plate_category: this.insuredDetailForm.value.rg_type,
     // driv_lic_TC_number: this.insuredDetailForm.value.d_TC_number,
      driv_lic_type: this.insuredDetailForm.value.d_lic_type,
      vehicleColor: this.insuredDetailForm.value.vhclColor,
      SchemeCode: this.SchemeCode,
      start_date: this.convertDate(
        this.insuredDetailForm.value.polStartDate
      ),
      modifiedVhclValue: modifyValue,
      policy_status: policy_status,
      deductible_value: this.insuredDetailForm.value.deductible,
      TRN_number: this.insuredDetailForm.value.TRN_num,
      trade_Lic_number: this.insuredDetailForm.value.trade_lic_num,
      industry_type: this.insuredDetailForm.value.industry_type,
     // transaction_type: this.insuredDetailForm.value.transaction_type.label, -- Removed transaction type
      loggedInUser :this.loggedInUserRole['UserRole']
    };

    this.quoteDetailArr = motorArrayQuote;
    this.emailId = this.insuredDetailForm.value.adtnl_detail_email;

    var gender = "";
    if (this.insuredDetailForm.value.e_gender) {
      gender = this.insuredDetailForm.value.e_gender.value;
    }

    let policyDetail = {
      quotation_number: this.webQuoteNumber,
      CRS_quotation_number: this.quoteNumber,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      license: this.insuredDetailForm.value.d_driv_lic_num,
      emirates: this.insuredDetailForm.value.rg_place_issue,
      address: this.insuredDetailForm.value.adtnl_detail_address,
      PO_box: this.insuredDetailForm.value.adtnl_detail_poBoxNum,
      occupation: "",
      national_id: this.insuredDetailForm.value.e_eid,
      arabic_name: "",
      home_office_number: "",
      start_date: this.convertDate(this.insuredDetailForm.value.polStartDate),
      end_date: "22/12/2019",
      color: "",
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      engine: this.insuredDetailForm.value.rg_engin_num,
      plate: "54687",
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      payment_mode: "ONLINE",
      named_driver: this.insuredDetailForm.value.e_name,
      additional_driver_under_age: "0",
      IP_address: "",
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      driving_License_Front: this.insuredDetailForm.value.driving_Lic_Front,
      driving_license_Back: "",
      Reg_Card_Front: this.insuredDetailForm.value.reg_Card_Front,
      Reg_Card_Back: this.insuredDetailForm.value.reg_Card_Back,
      EID_Front: this.insuredDetailForm.value.emirated_ID_Front,
      EID_Back: this.insuredDetailForm.value.emirated_ID_Backk,
      dealer_quote: "",
      vhclPlateCategory: this.insuredDetailForm.value.rg_type,
      plateCode: this.insuredDetailForm.value.rg_plateCode,
      plateNumber: this.insuredDetailForm.value.rg_traffic_plate_num,
      area: this.insuredDetailForm.value.adtnl_detail_area,
      noOfDoors: this.nofDoors,
      gender: gender,
      nationality: this.insuredDetailForm.value.e_nationality,
      source: "B2B",
    };

    this.motorQuoteService
      .updateQuotation(motorArrayQuote, policyDetail)
      .subscribe((res) => {
        if (type != 0) {
          stepper.next();
        }
        if(res.response_code_ == '400' && type == 3) // if quote is once triggered in referral scheme it should nto loop - ruhina 
        {
            Swal.fire("No tariff in IIRIS to approve this quote","Sorry, You cant proceed with the Quote Approval Flow", "error");
            this.referral_reason = res.referral_reason;
            this.referalStatus = true;
         //  this.showLoader.Quotation = false;
            this.showLoader.retrieveQuote = false;
            this.executed = true;
            stepper.previous();
            return;
        }
        else
        {

        this.PlanDataJson.planBenefitData.benefit_data = [];
        this.showLoader.retrieveQuote = false;
        console.log("response code",res.response_code)
      
            if (res.response_code == '0') {
              
          
              this.showLoader.Quotation = false;
              this.showLoader.retrieveQuote = false;
              Swal.fire("Sorry! Plan not available.", " ", "error");

              stepper.previous();
            } else if (res.response_code == '4') {
            
              this.showLoader.Quotation = false;
              this.showLoader.retrieveQuote = false;
             
              // this.webQuoteNumber = res.web_quotation_number;
              this.referalStatus = true;
              this.referral_reason = res.referral_reason;
              
                Swal.fire(res.referral_reason, res.response_message_, "error");
                stepper.previous();
              // }
              //    this.savePolicyDetail(1);
              
            }
            //commented below if else statemnet for referral to trigger by ruhina

            //  else if (res.response_code_ == 400) {
            //   this.showLoader.Quotation = false;
            //   this.showLoader.retrieveQuote = false;
            //   Swal.fire("", res.thirdPartyAPIData.Status.Description, "error");
            //   this.referalStatus = true;
            //   this.referral_reason = res.referral_reason;
            //   //    this.savePolicyDetail(1);
            //   stepper.previous();
            // } 
            else if ((res.response_code_ == '400' || res.response_code == '5') && type != 3) {
              
              console.log("response code is 400")
              this.showLoader.Quotation = false;
              this.showLoader.retrieveQuote = false;
              console.log(this.quoteStatus);


              //commeneted below code - ruhina 
              // if( res.thirdPartyAPIData != null)
              // {
              //   let thirdPartyAPIResponse =
              //   res.thirdPartyAPIData.Status.Description.split(
              //     "no tariff available".toLowerCase()
              //   );
              // console.log("ThirdPartyAPI length",thirdPartyAPIResponse.length);
              // }
              // else{
              //   Swal.fire("No tariff Data available", "Sorry, an error occurred while processing your request.<br /> Kindly try again or contact Fidelity United at 800 842.Your contact details and vehicle details are sent to our back-office team and one of our representative will contact you shortly.", "error");
              //   this.referral_reason = res.referral_reason;
              //   this.referalStatus = true;
              //   //console.log(stepper.previous())
              //   stepper.previous();
              // }     
              // && thirdPartyAPIResponse.length > 1
              if (
                this.quoteStatus == "REFERRED" &&
                this.businessSource == "DIRECT" &&
                this.partnerId == 1 &&
                this.userType == "POS" &&
                (this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW')
              ) {
                Swal.fire({
                  title: res.thirdPartyAPIData == null ? 'No Tariff Data Available' :res.thirdPartyAPIData.Status.Description,
                  text: "Do you want to trigger a referral scheme?",
                  showCancelButton: true,
                  confirmButtonColor: "#3085D6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                }).then((result) => {
                  console.log("result",result)
                  if (result.value) {
                    this.SchemeCode = "108";
                    this.referalStatus = false;
                    this.showLoader.Quotation = true;
                    this.showLoader.retrieveQuote = true;
                    this.updateQuotation(stepper, 3);
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    //Swal.fire("Cancelled", ":)", "error");
                    stepper.previous();
                    return;
                  }
                });
              } else {
                
                // alert(1)
                Swal.fire("Please Send for Referral", res.thirdPartyAPIData == null ? 'No Tariff Data Available' :res.thirdPartyAPIData.Status.Description, "error");
                // this.webQuoteNumber = res.web_quotation_number;
                this.referral_reason = res.referral_reason;
                this.referalStatus = true;
                //      this.savePolicyDetail(1);
                stepper.previous();
              }
            } else if (res.response_code_r == '7') {
            
              // this.webQuoteNumber = res.web_quotation_number_r;
              this.referral_reason = res.response_message_r;
              this.showLoader.Quotation = false;
              this.showLoader.retrieveQuote = false;
              this.referalStatus = true;
              //    this.savePolicyDetail(1);
              Swal.fire("Response code is 7", res.thirdPartyAPIData.Status.Description, "error");

              stepper.previous();
            } else {
              this.referalStatus = false;

              if((this.insuredDetailForm.value.vhclTPLCoverage.id=='1' ||this.stripTime(new Date (this.insuredDetailForm.value.rg_ins_exp))  < this.stripTime(new Date()) ) && this.quoteStatus != 'REFERRED'  && this.insuredDetailForm.value.adtnl_detail_brandNew != 1){
                this.NCDFormSubmit.get('SurveyReport').setValidators(Validators.required);
                this.NCDFormSubmit.get('SurveyReport').updateValueAndValidity();
                this.enableSurveyReport=true;
                this.enableAdditional=true;
            }
            else
            {
             this.enableSurveyReport=false;
               this.enableAdditional=false;
            }

              this.initial_benefit_data = res.planBenefitData.benefit_data;

              // this.policyFee = 1;
              this.policyFee = 0;
              this.totalVariablePremium = 0;
              this.PlanDataJson = res;
              this.totalFixPremium = 0;
              //this.totalFixPremium = this.LDAmount; //changed by ruhina as loading was not reflecting on retrive quote.
              this.plan_Name = this.PlanDataJson.planBenefitData.data[0].PlanName;
              this.plan_Id = this.PlanDataJson.planBenefitData.data[0].PlanId;
              this.benfPremIdArray = this.PlanDataJson.planBenefitData.data;
              this.webQuoteNumber = this.PlanDataJson.webQuotationNumber;
              this.quoteNumber = this.PlanDataJson.quotationNumber;
              this.showLoader.Quotation = false;
              this.showLoader.retrieveQuote = false;

              if (this.webQuoteNumber == "" || this.webQuoteNumber == null) {
                stepper.previous();
              }
              this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
                if (
                  item.PlanBenefitType == "OPTIONAL" &&
                  item.MultiOptionFlag == 1
                ) {
                  this.mulOption = item.multipleOptionData[0];
                  this.PlanDataJson.planBenefitData.data[
                    index
                  ].benefitSelectedData = item.multipleOptionData[0];
                  this.PlanDataJson.planBenefitData.data[index].multiOptID =
                    item.multipleOptionData[0].BenefitId;
                  this.PlanDataJson.planBenefitData.data[index].Price =
                    item.multipleOptionData[0].Price;
                  this.PlanDataJson.planBenefitData.data[index].checked = false;
                  this.PlanDataJson.planBenefitData.data[index].offerChecked =
                    false;
                }

                if (
                  item.PlanBenefitType == "OPTIONAL" &&
                  item.MultiOptionFlag != 1
                ) {
                  this.PlanDataJson.planBenefitData.data[index].checked = false;
                  this.PlanDataJson.planBenefitData.data[index].offerChecked =
                    false;
                }
              });

              this.checkOptionalBenefit(this.optionalBenefit);

              let discount =
                this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdPerc / 100;
              this.discount =
                this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt;
              this.discountType = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdYear
                if (this.discountType == "19")
                  this.showDiscount = false
                else
                  this.showDiscount = true
              this.tempBenefitData = this.PlanDataJson.planBenefitData.benefit_data;
              //add loading for refered quotes
             
            this.tempBenefitData.forEach((item, index) => {
              // FOR COMPRENSIVE
              if (
                this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100" &&
                item.Code == "40001"
              ) {
              
                item.premium = Number(item.premium) + Number(this.LDAmount)

              }
                // FOR TPL
                if (
                  this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "200" &&
                  item.Code == "40002"
                ) {
                
                  item.premium = Number(item.premium) + Number(this.LDAmount)
                }
              });
              this.PlanDataJson.planBenefitData.benefit_data = this.tempBenefitData
              // end 
              this.PlanDataJson.planBenefitData.benefit_data.forEach(
                (item, index) => {
                  this.totalFixPremium =
                    Number(this.totalFixPremium) + Number(item.premium);
                  // this.PlanDataJson.planBenefitData.benefit_data[index].premium = (item.premium - item.premium * discount);
                }
              );

              if (
                this.insuredDetailForm.value.adtnl_detail_repairTye.viewValue ==
                "Premium Garage"
              ) {
                this.applyLoadingOnChangeRepairType();
              }

              this.tempTotalFixPremium = this.totalFixPremium;

              //  this.totalFixPremium = Math.round(this.tempTotalFixPremium - this.tempTotalFixPremium * discount);
              // this.totalFixPremium = Math.round(
              //   this.tempTotalFixPremium - this.discount
              // );
              // this.totalFixPremium = Math.round(
              //   this.totalFixPremium + this.policyFee
              // );
              this.totalFixPremium =Number(Number(
                this.tempTotalFixPremium - this.discount
              ).toFixed(2));//rounding off issue fixed
              this.totalFixPremium = Number(Number(
                this.totalFixPremium + this.policyFee
              ).toFixed(2)); //rounding off issue fixed
              this.tempTotalLoadFixPrem = this.totalFixPremium;

              this.VATAMT =
                (this.totalFixPremium + this.totalVariablePremium) * 0.05;
              this.Total_Primium =
                this.totalFixPremium + this.totalVariablePremium + this.VATAMT;
              this.loadingBy = "1";

              if (this.tempTotalFixPremium <= 500) {
                stepper.previous();
              }
              // else
              // this.savePolicyDetail(1);
              //  this.checkAccessForPolicyIssueButtons();
            }
          }
      
      });
  }

  //------------------------------------- SAVE POLICY DETAIL (SAVE IN DATABASE)---------------------------------------------//
  savePolicyDetail(type) {
    //this._route.navigateByUrl('/motorquote/thankyou');
    var gender = "";
    if (this.insuredDetailForm.value.e_gender) {
      gender = this.insuredDetailForm.value.e_gender.value;
    }

    let policyDetail = {
      quotation_number: this.webQuoteNumber,
      CRS_quotation_number: this.quoteNumber,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      license: this.insuredDetailForm.value.d_driv_lic_num,
      emirates: this.insuredDetailForm.value.rg_place_issue,
      address: this.insuredDetailForm.value.adtnl_detail_address,
      PO_box: this.insuredDetailForm.value.adtnl_detail_poBoxNum,
      occupation: "",
      national_id: this.insuredDetailForm.value.e_eid,
      arabic_name: "",
      home_office_number: "",
      start_date: this.convertDate(this.insuredDetailForm.value.polStartDate),
      end_date: "22/12/2019",
      color: "",
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      engine: this.insuredDetailForm.value.rg_engin_num,
      plate: "54687",
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      payment_mode: "ONLINE",
      named_driver: this.insuredDetailForm.value.e_name,
      additional_driver_under_age: "0",
      IP_address: "",
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      driving_License_Front: this.insuredDetailForm.value.driving_Lic_Front,
      driving_license_Back: "",
      Reg_Card_Front: this.insuredDetailForm.value.reg_Card_Front,
      Reg_Card_Back: this.insuredDetailForm.value.reg_Card_Back,
      EID_Front: this.insuredDetailForm.value.emirated_ID_Front,
      EID_Back: this.insuredDetailForm.value.emirated_ID_Backk,
      dealer_quote: "",
      vhclPlateCategory: this.insuredDetailForm.value.rg_type,
      plateCode: this.insuredDetailForm.value.rg_plateCode,
      plateNumber: this.insuredDetailForm.value.rg_traffic_plate_num,
      area: this.insuredDetailForm.value.adtnl_detail_area,
      noOfDoors: this.nofDoors,
      gender: gender,
      nationality: this.insuredDetailForm.value.e_nationality,
      source: "B2B",
    };

    this.motorQuoteService
      .savePolicyDetail(policyDetail, this.quoteDetailArr)
      .subscribe((res) => {
        if (type == 2) {
        }
      });
  }
  //----------------------------------------- SAVE ADDITIONAL INFO (THIRD PARTY API)--------------------------------------------------------
  saveAdditionalInfo(type) {
    let name = this.insuredDetailForm.value.e_name;

    this.motorQuoteService
      .saveAdditionalInfo(
        this.quoteNumber,
        this.webQuoteNumber,
        this.insuredDetailForm.value.rg_mortgage,
        "N",
        name,
        this.insuredDetailForm.value.adtnl_detail_insType,
        this.insuredDetailForm.value.rg_engin_num,
        this.insuredDetailForm.value.e_gender == undefined ? "" : this.insuredDetailForm.value.e_gender.value,
        this.PaymentMode,
        "B2B",
        ''
      )
      .subscribe((res) => {
        if (res.response_code == 1) {
          if (type == 1) {
            //this.savePolicyDetail(1);
          }

          if (type == 2) {
            // FOR PAYMENT

            let return_url = "http://k2key.in/motor_ins//dashboard";
            let site_url = "http://k2key.in/motor_ins/" + this._route.url;

            //this.paymentLoader = this.globalService.spinnerShow();
            this.showLoader.Quotation = true;

            this.travelInsuranceService
              .paymentAuth(
                this.webQuoteNumber,
                site_url,
                return_url,
                "MT",
                "1",
                "",
                "",
                "B2B"
              )
              .subscribe((res) => {
                let payRes = res;

                if(payRes.status == 'Failed'){
                  console.log("Paytab rescode Failed")
                  Swal.fire(payRes.res_msg);
                  // this.paymentLoader = this.globalService.spinnerHide();
                  this.showLoader.Quotation = false;
                  return false;
                } else if (
                  payRes.status == 'Success'
                ) {
                  console.log("Paytab rescode Success")
                  console.log("Payment URL from API",payRes.data.redirect_url)
                  //this.paymentLoader = this.globalService.spinnerHide();
                  this.showLoader.Quotation = false;
                  localStorage.setItem("Payment_Order_ID",  payRes.data.tran_ref);
                  localStorage.setItem(
                    "Payment_Order_Tokan",
                    payRes.data.token
                  );
                  localStorage.setItem(
                    "Payment_Quotation_Number",
                    this.webQuoteNumber
                  );

                  window.location.href = payRes.data.redirect_url;
                }
              },
              error => {
                console.log(error);
                const errorMessage = error.error.res_status;
                const errorCode = error.error.res_code;
                Swal.fire(errorMessage);
              });
          }

          if (type == 3) {
            this.approvePolicyRequest(); // for issue policy
          }

          if (type == 4) {
            this.sendPaymentLink(); // for send payment link
          }
        }
      });
  }
  //------------------------------------ SEND PAYMENT LINK ------------------------------------------//
  sendPaymentLink() {
    this.motorQuoteService
      .sendPaymentLink(
        this.webQuoteNumber,
        "MT",
        this.emailId,
        "B2B",
        this.quoteNumber,
        ""
      )
      .subscribe((res) => {
        let payRes = res;

        if (payRes.res_code == 1) {
          this.motorQuoteService
            .sendReferralMailToAdmin(
              this.webQuoteNumber,
              "",
              "QUOTECONFIRMED",
              "",
              this.tempMultipleDocData,
              "",
              ""
            )
            .subscribe((res) => {});
          Swal.fire(
            "",
            "Payment link has been sent to customer for the reference no. " +
              this.webQuoteNumber,
            "success"
          );

          this._route.navigate(["agentMotor/motorquote"]);
        }
        this.showLoader.Quotation = false;
      });
  }
  //------------------------------------ VIEW POLICY SUMMARY ------------------------------------------//
  viewPolicySummary() {
    this.motorQuoteService
      .viewPolicySummary(this.quoteNumber, this.webQuoteNumber, "B2B")
      .subscribe((res) => {
        // this._route.navigateByUrl('agentMotor/MTquotepage/thankyou/' + this.policyNumber +"/"+ this.webQuoteNumber +"/"+" ");
        // res.policy_number
        if (res.response_code == 1) {
          this.showLoader.Quotation = false;
          localStorage.setItem("schedulelink", res.schedulelink);
          localStorage.setItem("creditNote", res.credit_note);
          localStorage.setItem("debitNote", res.debit_note);
          localStorage.setItem("hirePurchase", res.hirePurchaselink);
          localStorage.setItem("bankLetter", res.bankletter);
          localStorage.setItem("CRS_Quotation_Number", this.quoteNumber);
          localStorage.setItem("mortgage_bank", res.mortgageBank);
          localStorage.setItem("Policy_Number", res.policy_number);

          this._route.navigateByUrl("motor/thankyou");
        }
      });
  }
  //----------------------------------- APPROVE POLICY REQUEST -------------------------------------//
  approvePolicyRequest() {
    this.motorQuoteService
      .approvePolicyRequest(this.quoteNumber, this.webQuoteNumber, "B2B")
      .subscribe(
        (res) => {
          if (res.response_code == 1) {
            let polNum = res.approvePolicyRequest.PolNo;
            let policy_uid = res.approvePolicyRequest.PolicyUid;

            localStorage.setItem(
              "Payment_Quotation_Number",
              this.webQuoteNumber
            );
            localStorage.setItem("CRS_Quotation_Number", this.quoteNumber);

            if (polNum == "" || polNum == null || polNum == undefined) {
              localStorage.removeItem("Policy_Number");
              localStorage.removeItem("Policy_UID");
            } else {
              localStorage.setItem("Policy_Number", polNum);
              localStorage.setItem("Policy_UID", policy_uid);
            }

            this.viewPolicySummary();
          }
        },
        (error) => {
          localStorage.removeItem("Policy_Number");
          localStorage.removeItem("Policy_UID");
          localStorage.setItem("Payment_Quotation_Number", this.webQuoteNumber);
          localStorage.setItem("CRS_Quotation_Number", this.quoteNumber);
          this._route.navigateByUrl("motor/thankyou");
        }
      );
  }

  //---------------------------------------- GET PLATE CODE ARRAY -----------------------------//
  getPlateCode(regPlaceId, type) {
    let plateSource = regPlaceId;
    // if(regPlaceId == 'Dubai'){
    //       this.reg_place = 1;
    // }
    // else{
    //       this.reg_place = 0;
    // }

    if (typeof plateSource != undefined && typeof plateSource != "undefined") {
      this.motorQuoteService
        .getPlateCode(this.language_code, plateSource)
        .subscribe((res) => {
          this.plateCodeArray = res.plateCodeData;

          if (
            this.insuredDetailForm.value.adtnl_detail_brandNew == 1 &&
            typeof this.plateCodeArray != undefined &&
            typeof this.plateCodeArray != "undefined"
          ) {
            var indexPlCode = this.plateCodeArray.findIndex(function (obj, k) {
              return obj.PlateCode == "NEW NUMBER";
            });
            let plCodeVal = this.plateCodeArray[indexPlCode];
            this.insuredDetailForm.get("rg_plateCode").setValue(plCodeVal);
          }

          if (
            type == 2 &&
            typeof this.plateCodeArray != undefined &&
            typeof this.plateCodeArray != "undefined"
          ) {
            //Plate COde
            let plateCode = this.quoteDetail.plateCode;

            var indexPlCode = this.plateCodeArray.findIndex(function (obj, k) {
              return obj.PlateCode === plateCode;
            });
            let plCodeVal = this.plateCodeArray[indexPlCode];
            this.insuredDetailForm.get("rg_plateCode").setValue(plCodeVal);
          }

          if (
            type == 3 &&
            typeof this.plateCodeArray != undefined &&
            typeof this.plateCodeArray != "undefined"
          ) {
            //Plate COde
            let plateCode = this.plate_code;
            if (plateCode != undefined) {
              var indexPlCode = this.plateCodeArray.findIndex(function (
                obj,
                k
              ) {
                return obj.PlateCode.toLowerCase() === plateCode.toLowerCase();
              });
              let plCodeVal = this.plateCodeArray[indexPlCode];
              this.insuredDetailForm.get("rg_plateCode").setValue(plCodeVal);
            }
          }
        });
    }
  }

  //------------------------------------------ GET VEHICLE VALUE -------------------------------------------------//
  updateValuation() {
    // console.log(111111111111111111111111111111111111)
    if (
      this.vhcleValueFlag == false &&
      this.insuredDetailForm.value.adtnl_detail_insType.ProductId == "61"
    ) {
      if (
        this.insuredDetailForm.value.rg_vhcl_make != "" &&
        this.insuredDetailForm.value.rg_vhcl_model != "" &&
        this.insuredDetailForm.value.adtnl_detail_trim != "" &&
        this.insuredDetailForm.value.adtnl_detail_bodyType != ""
      ) {
        this.showLoader.VehicleValue = true;

        let vehicleDetailArray = {
          chassisNum: this.insuredDetailForm.value.rg_chassis_num,
          //  insType: this.insuredDetailForm.value.adtnl_detail_insType,
          insType: {
            value: this.insuredDetailForm.value.adtnl_detail_insType.crsProdId,
            viewValue:
              this.insuredDetailForm.value.adtnl_detail_insType
                .ProductShortName,
            WebProdCode:
              this.insuredDetailForm.value.adtnl_detail_insType.ProductCode,
            CoreProdCode:
              this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode,
          },
          repairType: this.insuredDetailForm.value.rg_vhcl_make,
          vhclBodyType: this.insuredDetailForm.value.adtnl_detail_bodyType,
          vhclCylinder: this.insuredDetailForm.value.adtnl_detail_cylinder,
          vhclGCCSpecifird:
            this.insuredDetailForm.value.adtnl_detail_gccSpecified,
          vhclMake: this.insuredDetailForm.value.rg_vhcl_make,
          vhclModel: this.insuredDetailForm.value.rg_vhcl_model,
          vhclModelYr: this.insuredDetailForm.value.rg_model_year,
          vhclSeatCapcity: this.insuredDetailForm.value.rg_num_passenger,
          vhclTrim: this.insuredDetailForm.value.adtnl_detail_trim,
        };

        this.motorQuoteService
          .getMotorValuation(vehicleDetailArray)
          .subscribe((res) => {
            if (res.StatusCode == 200) {
              this.existVehiclevalue = 0;
              this.referalStatus = false;
              this.vehicleValueLimit.isSet = true;

              // if(this.quoteStatus == 'REFERRED' &&  (this.businessSource == 'DIRECT' && this.partnerId == 1 && this.userType == 'POS' && this.userRole == 'TELEMARKETING')){
              //       let  mediumValue = res.Valuation.Medium;
              //       let updatedValue =  Math.round((mediumValue * (20/ 100)));
              //       this.vehicleValueLimit.startLimit = Number(mediumValue) - Number(updatedValue);
              //       this.vehicleValueLimit.endLimit =  Number(mediumValue) + Number(updatedValue);

              // }else{
              this.vehicleValueLimit.startLimit = res.Valuation.Low;
              this.vehicleValueLimit.endLimit = res.Valuation.High;
              // }

              this.insuredDetailForm.controls[
                "adtnl_detail_vhclValue"
              ].setValidators([
                Validators.required,
                Validators.min(res.Valuation.Low),
                Validators.max(res.Valuation.High),
              ]);
              this.insuredDetailForm.controls[
                "adtnl_detail_vhclValue"
              ].updateValueAndValidity();
              this.insuredDetailForm
                .get("adtnl_detail_vhclValue")
                .setValue(res.Valuation.Medium);
              this.getDeductibleValue();
              //   this.vehicleDetailForm.vhclValue
            } else {
              this.existVehiclevalue = 1;
              this.referalStatus = true;
              this.vehicleValueLimit.startLimit = 0;
              this.vehicleValueLimit.endLimit = 2000000; // Updated as per the business 
              this.insuredDetailForm.controls[
                "adtnl_detail_vhclValue"
              ].setValidators([Validators.required]);
              this.insuredDetailForm.controls[
                "adtnl_detail_vhclValue"
              ].updateValueAndValidity();
              this.insuredDetailForm.get("adtnl_detail_vhclValue").setValue("");
              this.vehicleValueLimit.isSet = false;
            }

            this.showLoader.VehicleValue = false;
          });
      }
    } else {
      this.vhcleValueFlag = false;
    }
  }

  changeChasisValidation(type) {
    // commented for timing
    if (this.checkChassisValidation == false) {
      this.insuredDetailForm.get("isChassisValidate").setValue("");
      if (type == 1) {
        this.insuredDetailForm.get("rg_model_year").setValue("");
        this.insuredDetailForm.get("rg_vhcl_make").setValue("");
        this.insuredDetailForm.get("rg_vhcl_model").setValue("");
        this.insuredDetailForm.get("adtnl_detail_trim").setValue("");
        this.insuredDetailForm.get("adtnl_detail_bodyType").setValue("");
        this.insuredDetailForm.get("adtnl_detail_cylinder").setValue("");
        this.insuredDetailForm.get("rg_num_passenger").setValue("");
        this.insuredDetailForm.get("adtnl_detail_brandNew").setValue("0");
        this.insuredDetailForm.get("rg_plateCode").setValue("");
      }
    } else {
      this.checkChassisValidation = false;
    }
  }

  //------------------------------------- VALIDATE CHASSIS NUMBER ------------------------------------------------------------//
  validateChassisNum(chassisNo, stepper, type) {
    // this.validChassisNum = true;
    console.log(chassisNo);
    if (chassisNo == "") {
      //this.blankChassis = true;
      return false;
    }

    // this.vehicleDetailForm.get('isChassisValidate').setValue('');
    // this.additionalDetailForm.get('isChassisValidate').setValue('');
    // let stepperD = stepper;

    // this.blankChassis = false;
    this.showLoader.chasisNoButton = true;
    this.motorQuoteService.validateChassisNumber(chassisNo).subscribe((res) => {
      let validateRes = res;
      console.log("server response", validateRes);
      if (validateRes.validateChassisNumber == "1") {
        Swal.fire(
          chassisNo +
            " " +
            "This Chassis number is already available. Please contact Fidelity United at 800 842",
          "error"
        );
        this.showLoader.chasisNoButton = false;

        this.insuredDetailForm.get("isChassisValidate").setValue("");
        this.insuredDetailForm.get("rg_chassis_num").setValue("");
        return false;
      } else {
        this.motorQuoteService.getDetailsByVIN(chassisNo).subscribe((res) => {
          this.showLoader.chasisNoButton = false;
          this.getVechileDetailData = res.vechileData;
          this.chassis_No_Details = this.getVechileDetailData.VehicleDetails;
          console.log("server response 2", res);

          if (
            res.vechileData.StatusCode == 400 ||
            res.vechileData.StatusCode == 422 ||
            res.vechileData.ExpiresIn == 1200
          ) {
            Swal.fire(
              " ",
              this.getVechileDetailData.Errors[0] + "\nInvalid Chasis No.",
              "error"
            );
            if (type == 1) {
              this.insuredDetailForm.get("isChassisValidate").setValue("");
              this.insuredDetailForm.get("rg_vhcl_make").setValue("");
              this.insuredDetailForm.get("rg_model_year").setValue("");
              this.insuredDetailForm.get("rg_num_passenger").setValue("");
              this.insuredDetailForm
                .get("adtnl_detail_gccSpecified")
                .setValue("");
              this.insuredDetailForm.get("rg_vhcl_model").setValue("");
              this.insuredDetailForm.get("adtnl_detail_trim").setValue("");
              this.insuredDetailForm.get("adtnl_detail_bodyType").setValue("");
              this.insuredDetailForm.get("adtnl_detail_cylinder").setValue("");
              this.insuredDetailForm.get("adtnl_detail_vhclValue").setValue("");
              this.insuredDetailForm.get("rg_chassis_num").setValue("");
            }
          } else {
            if (this.insuredDetailForm.value.rg_chassis_num != "") {
              this.insuredDetailForm.get("isChassisValidate").setValue("1");

              //VEHICLE YEAR
              let yearVal;
              this.vehimodelyrs.forEach((item, index) => {
                if (
                  item.label ==
                  this.getVechileDetailData.VehicleDetails.General.ModelYear
                ) {
                  yearVal = item;
                }
              });

              this.insuredDetailForm.get("rg_model_year").setValue(yearVal);
              this.getVhclMakeData(
                this.getVechileDetailData.VehicleDetails.General.Make,
                yearVal,
                3
              );

              // //VEHICLE Make ----- filter
              // let makeVal
              // this.vhclMakeArr.forEach((item, index) => {

              //   if (item.VehicleMakeName == this.getVechileDetailData.VehicleDetails.General.Make) {
              //     makeVal = item;
              //   }
              // });
              // this.insuredDetailForm.get('rg_vhcl_make').setValue(makeVal);

              //this.onVehiclAgeChange(yearVal);

              //Vehicle Cylinders
              let cylVal;
              let cylindervalue = this.getVechileDetailData.VehicleDetails.Technical
              .EngineCylinders + ' Cylinders'
              this.vehicylinders.forEach((item, index) => {   //changed to cylindername -ruhina
                if (
                  item.CylinderName ==
                  cylindervalue
                )
              // this.vehicylinders.forEach((item, index) => {
              //   if (
              //     item.Id ==
              //     this.getVechileDetailData.VehicleDetails.Technical
              //       .EngineCylinders )
                     {
                  cylVal = item;
                }
              });
              this.insuredDetailForm
                .get("adtnl_detail_cylinder")
                .setValue(cylVal);

              //Passenger Count
              let PasgerVal;

              this.vehiseatcaps.forEach((item, index) => {
                if (
                  item.value ==
                  this.getVechileDetailData.VehicleDetails.General.NoOfSeats - 1
                ) {
                  PasgerVal = item;
                }
              });
              this.insuredDetailForm
                .get("rg_num_passenger")
                .setValue(PasgerVal);

              this.GCC_Specification =
                this.getVechileDetailData.VehicleDetails.General.Region;
              if (
                this.getVechileDetailData.VehicleDetails.General.Region != "" &&
                this.getVechileDetailData.VehicleDetails.General.Region != null
              ) {
                let gcc_specification =
                  this.getVechileDetailData.VehicleDetails.General.Region ==
                  "GCC"
                    ? "No"
                    : "Yes";
                this.insuredDetailForm
                  .get("adtnl_detail_gccSpecified")
                  .setValue(gcc_specification);
              } else {
                this.insuredDetailForm
                  .get("adtnl_detail_gccSpecified")
                  .setValue("");
              }

              //Vehicle Color
              if (
                this.getVechileDetailData.VehicleDetails.General.ColourEN != ""
              ) {
                let colorVal;
                this.vhclColorArr.forEach((item, index) => {
                  if (
                    item.ColorName ==
                    this.getVechileDetailData.VehicleDetails.General.ColourEN
                  ) {
                    colorVal = item;
                  }
                });

                this.insuredDetailForm.get("vhclColor").setValue(colorVal);
              }

              let enginNo =
                this.getVechileDetailData.VehicleDetails.General.EngineNo;
              this.insuredDetailForm.get("rg_engin_num").setValue(enginNo);

              this.nofDoors =
                this.getVechileDetailData.VehicleDetails.General.NoOfDoors;
            }
          }
        });
      }
    });
  }

  nonEditableFields(GCC_Specification) {
    if (GCC_Specification != "" && GCC_Specification != null) {
      return true;
    }
  }

  checkBrandNew() {
    if (this.insuredDetailForm.value.adtnl_detail_brandNew == 1) {
      this.insuredDetailForm.controls["rg_reg_date"].setValidators(null);

      //Added validation for Brand New by Anju
      this.insuredDetailForm.controls["rg_expiry_date"].setValidators(null);
      this.insuredDetailForm.get("rg_expiry_date").setValue(new Date(Date.now())); //set Current Date 
      this.insuredDetailForm.get("rg_reg_date").setValue(new Date(Date.now()));//set Current Date 

      this.insuredDetailForm.controls["rg_ins_exp"].setValidators(null);
      this.insuredDetailForm.get("rg_ins_exp").setValue(new Date(Date.now()));

      this.insuredDetailForm.controls['rg_traffic_plate_num'].setValidators(null); // plate number to be non mandatory if new vehicle --ruhina
      this.insuredDetailForm.controls['rg_plateCode'].setValidators(null); // plate code to be non mandatory if new vehicle --ruhina

      //Removed transaction type by Anju
    //   this.transactionTypeArray = [];
    //   this.transactionTypeArray.push(this.tempTransactionTypeArr[0]);
    //   this.insuredDetailForm
    //     .get("transaction_type")
    //     .setValue(this.transactionTypeArray[0]);
    // } else {
    //   this.transactionTypeArray = [];
    //   this.transactionTypeArray.push(this.tempTransactionTypeArr[1]);
    //   this.transactionTypeArray.push(this.tempTransactionTypeArr[2]);
    //   this.insuredDetailForm
    //     .get("transaction_type")
    //     .setValue(this.transactionTypeArray[0]);
    }

    this.insuredDetailForm.controls["rg_reg_date"].updateValueAndValidity();
    this.insuredDetailForm.controls["rg_plateCode"].updateValueAndValidity();
    this.insuredDetailForm.controls["rg_traffic_plate_num"].updateValueAndValidity();

    // this.insuredDetailForm.get("rg_plateCode").setValue("");
  }

  checkMulkiyaCopy() {
    if (this.insuredDetailForm.value.MulkhiyaStatus == "No") {
      this.insuredDetailForm.get("adtnl_detail_gccSpecified").setValue("");
      this.insuredDetailForm.get("rg_model_year").setValue("");
      this.insuredDetailForm.get("rg_num_passenger").setValue("");
      this.insuredDetailForm.get("rg_origin").setValue("");
      this.insuredDetailForm.get("adtnl_detail_cylinder").setValue("");
      this.insuredDetailForm.get("rg_expiry_date").setValue("");
      this.insuredDetailForm.get("rg_ins_exp").setValue("");
      this.insuredDetailForm.get("rg_policy_num").setValue("");
      this.insuredDetailForm.get("rg_reg_date").setValue("");
      this.insuredDetailForm.get("rg_TC_num").setValue("");
      this.insuredDetailForm.get("rg_traffic_plate_num").setValue("");
      this.insuredDetailForm.get("rg_chassis_num").setValue("");
      this.insuredDetailForm.get("isChassisValidate").setValue("");
      this.insuredDetailForm.get("rg_engin_num").setValue("");
      this.insuredDetailForm.get("rg_vhcl_model").setValue("");
      this.insuredDetailForm.get("adtnl_detail_trim").setValue("");
      this.insuredDetailForm.get("adtnl_detail_bodyType").setValue("");
      this.insuredDetailForm.get("rg_vhcl_make").setValue("");
      this.insuredDetailForm.get("reg_Card_BackFilePath").setValue("");
      this.insuredDetailForm.get("reg_Card_FrontFilePath").setValue("");
    } else {
      this.insuredDetailForm.get("supporting_DocumentFilePath").setValue("");
    }
  }

  //--------------------------------- ON MODEL YEAR CHANGE -------------------------------------//
  onVehiclAgeChange(vhclYear) {
    console.log(vhclYear)
    var d = new Date(this.insuredDetailForm.value.rg_reg_date);
    let firstRegYear: number = d.getFullYear();

    var p = new Date(this.insuredDetailForm.value.polStartDate);
    let policyStartDateYear: number = p.getFullYear();

    let vhclNextYear = Number(vhclYear.value) + 1;
    let vhclPrevYear = Number(vhclYear.value) - 1;

    let schemeYear = Number(firstRegYear) - Number(vhclYear.value);

    this.regMinDate = new Date(vhclPrevYear, 4, 1);
    //this.regMaxDate = new Date(vhclNextYear, 1, 28);
    this.regMaxDate = new Date();

    let vhclAge = this.year - vhclYear.value;

    let timeDiff = Math.abs(
      this.insuredDetailForm.value.rg_reg_date -
        this.insuredDetailForm.value.polStartDate
    );
    let days_difference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    let D1 = Math.ceil(1 + days_difference / 397); //this will give you years
    let D2 = Number(policyStartDateYear) - Number(vhclYear.value) + 1;
    this.vehicle_age = Math.max(D2, D1);
    let D3 = Math.abs(D2 - D1);
    if (D3 == 1) {
      this.vehicle_age = D1;
    } else {
      this.vehicle_age = Math.max(D2, D1);
    }

    this.motorQuoteService
      .getMotorRequiredData(
        "AgencyLogic",
        this.vehicle_age,
        Number(vhclYear.value),
        Number(policyStartDateYear),
        this.insuredDetailForm.value.rg_vhcl_make,
        this.SchemeCode,
        this.quoteStatus,
        this.webQuoteNumber
      )
      .subscribe((res) => {
        let response = res;
        this.repairs = response.repair_type_data;
        this.repairtypes = this.repairs;

        this.insuredDetailForm
          .get("adtnl_detail_repairTye")
          .setValue(this.repairtypes[0]);

        if (this.retrieve_repair_type != "") {
          var indRepairType;
          this.repairtypes.forEach((item, index) => {
            if (
               item.value.toLowerCase() ==
              this.quoteDetail.RepairType.toLowerCase()
            ) {
              indRepairType = item;
            }
          });
          //Added by Anju to make default Agency for Brand New Vehicles
        }
        else if( Number(vhclYear.value)>=policyStartDateYear){
          this.repairtypes.forEach((item, index) => {
          if (
            item.value.toLowerCase() ==
           'agency'
         ) {
           indRepairType = item;
         }
        });
        }

        this.insuredDetailForm
            .get("adtnl_detail_repairTye")
            .setValue(indRepairType);
      });
  }

  getRetrieveQuoteData() {
    
    if (
      this.retrieveQuoteNumber == "" ||
      typeof this.retrieveQuoteNumber == "undefined"
    )
      return true;
    this.showLoader.retrieveQuote = true;
    this.motorQuoteService
      .getRetrieveQuote(this.retrieveQuoteNumber, "BTBPORTAL", "")
      .subscribe((res) => {
       if (this.timer == true && this.showTimer == false)
       {
        setTimeout(()=>{           
          this.showTimer = false                // <<<---using ()=> syntax
          this.getRetrieveQuoteData()
      }, 10000);
       }
        if (res.response_code == 1) {
       
          this.webQuoteNumber = this.retrieveQuoteNumber;
          this.retrieveData = res.quotationDetailsDataForCustomer;
          let resData = this.retrieveData[0];
          this.quoteDetail = this.retrieveData[0];
          this.SchemeCode = this.quoteDetail.SchemeCode;
          // this.getinsuredType();
          //added quotationvalididity check  from front end 
          if(this.quoteDetail.QuoteValidity > 14)
          {
            Swal.fire("Your Quotation is Expired.Please Create new Quote");
            this._route.navigateByUrl("/home/retreivequote")
            return false;
          }
          //quotation validity check end -ruhina
          this.quoteDetail.plateCode = resData.PlateCode;
          this.vhcleValueFlag = true;
          this.quoteStatus = resData.StatusDesc;
          this.optionalBenefit = this.quoteDetail.SelectedBenefits;
          this.isRenewal = this.quoteDetail.IsRenewal;
          this.LDAmount = this.quoteDetail.LDAmount;
          this.LDType = this.quoteDetail.LDType;
          this.LDRate = this.quoteDetail.LDRate;
          //IN CASE OF B2C - DOCUMENTS ARE NOT GETTING BECAUSE OF WE DON'T SAVE THEM BEFORE INSERT/UPDATE QUOTE
          this.getDataForEditQuoteDocs(this.retrieveQuoteNumber);
          this.getQuotationHistory(this.retrieveQuoteNumber);

          if (resData.Bsrc == "B2B") {
            this.insuredDetailForm.get("e_name").setValue(resData.InsuredName);
            this.insuredDetailForm
              .get("adtnl_detail_email")
              .setValue(resData.InsuredEmail);
            this.insuredDetailForm
              .get("adtnl_detail_mobile")
              .setValue(resData.InsuredMobile);
            this.insuredDetailForm
              .get("adtnl_detail_address")
              .setValue(resData.InsuredAddress);
            this.insuredDetailForm
              .get("adtnl_detail_area")
              .setValue(resData.Area);
            this.insuredDetailForm
              .get("rg_chassis_num")
              .setValue(resData.ChassisNumber);
            this.checkChassisValidation = true;
            this.insuredDetailForm.get("isChassisValidate").setValue("1");
            this.insuredDetailForm
              .get("adtnl_detail_gccSpecified")
              .setValue(resData.HasGCCSpecification);
            this.insuredDetailForm
              .get("adtnl_detail_vhclModified")
              .setValue(resData.IsModified);
            this.insuredDetailForm
              .get("e_eid")
              .setValue(resData.EmiratesIdNumber);
            this.insuredDetailForm
              .get("rg_engin_num")
              .setValue(resData.EngineNumber);
            this.insuredDetailForm
              .get("adtnl_detail_poBoxNum")
              .setValue(resData.POBox);
            this.insuredDetailForm
              .get("rg_TC_num")
              .setValue(resData.TrafficFileNo);
            this.insuredDetailForm
              .get("d_driv_lic_num")
              .setValue(resData.LicenceNumber);
            this.insuredDetailForm
              .get("d_dob")
              .setValue(this.dateConvert(resData.InsuredDOB));
            this.insuredDetailForm
              .get("adtnl_detail_vhclValue")
              .setValue(resData.SumInsured);
            this.insuredDetailForm
              .get("rg_reg_date")
              .setValue(this.dateConvert(resData.FirstRegistrationDate));
            this.insuredDetailForm
              .get("adtnl_detail_brandNew")
              .setValue(resData.IsBrandNew);
            this.insuredDetailForm.get("rg_gvm").setValue(resData.VehGVM);
            this.insuredDetailForm
              .get("rg_policy_num")
              .setValue(resData.PrvInsPolNo);
            this.insuredDetailForm
              .get("e_cardNumber")
              .setValue(resData.NationalIdCardNo);
            // this.insuredDetailForm.get('e_arabic_name').setValue(resData.ArabicName);    FOR NAME IN ARABIC
          //  this.insuredDetailForm.get("d_TC_number").setValue(resData.DLTCNo);
            this.insuredDetailForm
              .get("trade_lic_num")
              .setValue(resData.TradeLicNumber);
            this.insuredDetailForm
              .get("TRN_num")
              .setValue(resData.TaxRegNumber);

            if (
              resData.RegistrationNumber !== "" &&
              resData.RegistrationNumber != null
            ) {
              this.insuredDetailForm
                .get("rg_traffic_plate_num")
                .setValue(resData.RegistrationNumber);
            }

            // if(this.quoteStatus == 'REFERRED' &&  (this.businessSource == 'DIRECT' && this.partnerId == 1 && this.userType == 'POS' && this.userRole == 'TELEMARKETING')){
            //       let  mediumValue =  resData.ValuationMedium;
            //       let updatedValue =  Math.round((mediumValue * (20/ 100)));
            //       this.vehicleValueLimit.startLimit = Number(mediumValue) - Number(updatedValue);
            //       this.vehicleValueLimit.endLimit =  Number(mediumValue) + Number(updatedValue);

            // }else{
            //       this.vehicleValueLimit.startLimit = resData.ValuationLow;
            //       this.vehicleValueLimit.endLimit =  resData.ValuationHigh;
            //     }

            this.vehicleValueLimit.startLimit = resData.ValuationLow;
            this.vehicleValueLimit.endLimit = resData.ValuationHigh;
            //Added by Anju to extend the Vehicle Value range for specified users
            if (this.partnerID == 1){
              if( this.changeVehicleValue) {
              this.vehicleValueLimit.startLimit = resData.ValuationLow;
              this.vehicleValueLimit.endLimit = resData.ValuationHigh;
             }
             else{
              this.vehicleValueLimit.startLimit = resData.ValuationLowOrg;
              this.vehicleValueLimit.endLimit = resData.ValuationHighOrg;

             }
            }
            this.vehicleValueLimit.isSet = true;
            if (resData.CedantBrokerId == "") {
              this.insuredDetailForm
                .get("partner")
                .setValue(localStorage.getItem("Partner_ID"));
             // this.getPartnerBranchList();
             if(this.partnerID == 1){
              this.getPartnerBranchList();
            }
              else{
                this.getUserBranchList();
              }
            } else {
              this.insuredDetailForm
                .get("partner")
                .setValue(resData.CedantBrokerId);

                 //Added by Anju for default partner branch
                 if (resData.CedantBrokerBranchId != "" && resData.CedantBrokerBranchId != undefined) {
                  this.defaultBranchId = resData.CedantBrokerBranchId;
                
               }

              //this.getPartnerBranchList();
              if(this.partnerID == 1){
                this.getPartnerBranchList();
              }
                else{
                  this.getUserBranchList();
                }
            }

            if (
              resData.InsuranceExpiryDate == null ||
              resData.InsuranceExpiryDate == "1900-01-01 00:00:00.000",
              resData.InsuranceExpiryDate == '' //added if date is empty for no insurance /TPL
            ) {
              this.insuredDetailForm.get("rg_ins_exp").setValue(Date.now()); //set Current Date 
              
            } else {
              this.insuredDetailForm
                .get("rg_ins_exp")
                .setValue(new Date(resData.InsuranceExpiryDate)); 
            }

            if (
              resData.DrivingLicExpDate == null ||
              resData.DrivingLicExpDate == "1900-01-01 00:00:00.000"
            ) {
              this.insuredDetailForm.get("d_expiry_date").setValue(null);
            } else {
              this.insuredDetailForm
                .get("d_expiry_date")
                .setValue(new Date(resData.DrivingLicExpDate));
            }

            if (
              resData.DrivingLicIssueDate == null ||
              resData.DrivingLicIssueDate == "1900-01-01 00:00:00.000"
            ) {
              this.insuredDetailForm.get("d_issue_date").setValue(null);
            } else {
              this.insuredDetailForm
                .get("d_issue_date")
                .setValue(new Date(resData.DrivingLicIssueDate));
            }

            if (
              resData.NationalIdExpDate == null ||
              resData.NationalIdExpDate == "1900-01-01 00:00:00.000"
            ) {
              this.insuredDetailForm.get("e_expiryDate").setValue('');
            } else {
              this.insuredDetailForm
                .get("e_expiryDate")
                .setValue(new Date(resData.NationalIdExpDate));
            }

            if (
              resData.RegistrationExpiryDate == null ||
              resData.RegistrationExpiryDate == "1900-01-01 00:00:00.000"
            ) {
              this.insuredDetailForm.get("rg_expiry_date").setValue(null);
            } else {
              this.insuredDetailForm
                .get("rg_expiry_date")
                .setValue(new Date(resData.RegistrationExpiryDate));
            }
               //Policy Start Date 

          if (
            resData.CoverStartDate == null ||
            resData.CoverStartDate == "1900-01-01 00:00:00.000"
          ) {
            this.insuredDetailForm.get("polStartDate").setValue(new Date(Date.now()));
          } else {
            this.insuredDetailForm
              .get("polStartDate")
              .setValue(new Date(resData.CoverStartDate));
          }  // added cover start date from response
            if (resData.InsuredType == "Corporate") {
              this.insuredDetailForm
                .get("vat_register")
                .setValue(resData.IsTaxReg);
            }
            //Vehicle own by
            var indexOwnBy = this.insvehibys.findIndex(function (obj, k) {
              return obj.value === resData.InsuredType;
            });
            let ownByVal = this.insvehibys[indexOwnBy];
            this.insuredDetailForm
              .get("adtnl_detail_vhclOwnBy")
              .setValue(ownByVal);

            //Policy Type
            var indexInsType = this.instypes.findIndex(function (obj, k) {
              return (
                obj.ProductShortName.toLowerCase() ===
                resData.PolicyType.toLowerCase()
              );
            });
            let insVal = this.instypes[indexInsType];
            this.insuredDetailForm.get("adtnl_detail_insType").setValue(insVal);

            //Mobile Code
            var indexCode = this.codes.findIndex(function (obj, k) {
              return obj.viewValue === resData.MobileCode;
            });
            //   let codeVal = this.codes[indexCode];
            //   this.insuredDetailForm.get('adtnl_detail_mbCode').setValue(codeVal);

            //NCD
            var indexNCD = this.NCDData.findIndex(function (obj, k) {
              return obj.NCDDescription === resData.NCD;
            });
            let NCDVal = this.NCDData[indexNCD];
            this.insuredDetailForm.get("adtnl_detail_NCD").setValue(NCDVal);

            //VEHICLE YEAR
            let yearVal;
            this.vehimodelyrs.forEach((item, index) => {
              if (item.label == resData.VehicleModelYear) {
                yearVal = item;
              }
            });
            this.insuredDetailForm.get("rg_model_year").setValue(yearVal);

            //Business Type
            let businessTypeVal;
            if (
              resData.BusinessCategory != null &&
              resData.BusinessCategory != ""
            ) {
              this.industryTypeArr.forEach((item, index) => {
                if (
                  item.IndustryName.toLowerCase() ==
                  resData.BusinessCategory.toLowerCase()
                ) {
                  businessTypeVal = item;
                }
              });
              this.insuredDetailForm
                .get("industry_type")
                .setValue(businessTypeVal);
            }

            //Removed transaction type by Anju
            //Transaction Type
            // var transType;
            // this.transactionTypeArray.forEach((item, index) => {
            //     if (item.label.toLowerCase() == resData.TransType.toLowerCase()) {
            //           transType = item;
            //     }
            // });
            // this.insuredDetailForm.get('transaction_type').setValue(transType);
            // this.onChangeTransactionType(this.insuredDetailForm.value.transaction_type.label);

            this.quoteDetail.VehicleMake = resData.VehicleMake;
            this.quoteDetail.VehicleModel = resData.VehicleModel;
            this.quoteDetail.TrimCode = resData.TrimCode;
            this.quoteDetail.TrimName = resData.TrimName;
            this.quoteDetail.BodyType = resData.BodyType;
            this.retrieve_repair_type = resData.RepairType;
            this.quoteDetail.RepairType = resData.RepairType;

            this.getVhclMakeData(this.quoteDetail.VehicleMake, yearVal, 1);

            // Nationality
            var indexNationality;
            this.countryArr.forEach((item, index) => {
              if (item.CountryName == resData.InsuredNationality) {
                indexNationality = item;
              }
            });
            this.insuredDetailForm
              .get("e_nationality")
              .setValue(indexNationality);

            //Mortagage bank
            if (resData.Mortgage != "" || resData.Mortgage != null) {
              var indexBank = this.bankDetail.findIndex(function (obj, k) {
                return obj.InstituteName === resData.Mortgage;
              });
              let bankVal = this.bankDetail[indexBank];
              if (resData.Mortgage == "Not Mortgaged")
                this.insuredDetailForm
                  .get("rg_mortgage")
                  .setValue(this.bankDetail[0]);
              else this.insuredDetailForm.get("rg_mortgage").setValue(bankVal);
            } else {
              this.insuredDetailForm.get("rg_mortgage").setValue("");
            }

            //Gender
            var indexGender = this.genders.findIndex(function (obj, k) {
              return obj.value === resData.InsuredGender;
            });
            let gnderVal = this.genders[indexGender];
            this.insuredDetailForm.get("e_gender").setValue(gnderVal);

            //Vehicle Cylinders
            var indexCylinder = this.vehicylinders.findIndex(function (obj, k) {
              return obj.CylinderName == resData.VehicleCylinders;
            });
            console.log(resData.VehicleCylinders);
            console.log(indexCylinder);
            let cylVal = this.vehicylinders[indexCylinder];
            console.log(cylVal);
            this.insuredDetailForm
              .get("adtnl_detail_cylinder")
              .setValue(cylVal);

            //Passenger Count
            var indexPasger = this.vehiseatcaps.findIndex(function (obj, k) {
              return obj.value == resData.PassengersCount;
            });
            let PasgerVal = this.vehiseatcaps[indexPasger];
            this.insuredDetailForm.get("rg_num_passenger").setValue(PasgerVal);

            //REG PLACE
            if (
              resData.RegistrationPlace != "" &&
              resData.RegistrationPlace != null &&
              resData.RegistrationPlace != undefined
            ) {
              var indexPlace = this.cityArr.findIndex(function (obj, k) {
                return obj.CityName === resData.RegistrationPlace;
              });
              let placeal = this.cityArr[indexPlace];
              this.insuredDetailForm.get("rg_place_issue").setValue(placeal);
              this.quoteDetail.regplace = placeal.CityName;
              this.getPlateCode(this.quoteDetail.regplace, 2);
            }

            //Plate category
            if (resData.PlateCategory != null) {
              var indexPlCat = this.plateCatArray.findIndex(function (obj, k) {
                return (
                  obj.label.toLowerCase() ===
                  resData.PlateCategory.toLowerCase()
                );
              });
              let plCatVal = this.plateCatArray[indexPlCat];
              if (typeof plCatVal != "undefined")
                this.insuredDetailForm.get("rg_type").setValue(plCatVal.value);
            } else {
              this.insuredDetailForm.get("rg_type").setValue("");
            }

            //Vehicle Color
            if (resData.VehicleColor == null || resData.VehicleColor == "") {
              this.insuredDetailForm.get("vhclColor").setValue("");
            } else {
              var indexColor = this.vhclColorArr.findIndex(function (obj, k) {
                return obj.ColorName === resData.VehicleColor;
              });
              let clrVal = this.vhclColorArr[indexColor];
              this.insuredDetailForm.get("vhclColor").setValue(clrVal);
            }

            //Active policy type
            if (this.insuredDetailForm.value.adtnl_detail_brandNew == 1) {
              this.insuredDetailForm
                .get("vhclTPLCoverage")
                .setValue(this.activePolicyTypeArr[1]);
            } else {
              if (resData.CurrentInsuranceId == 1) {
                this.insuredDetailForm
                  .get("vhclTPLCoverage")
                  .setValue(this.activePolicyTypeArr[0]);
                    this.prevNoIns = true
                    this.insuredDetailForm.controls["rg_expiry_date"].setValidators(null)
                    this.insuredDetailForm.get("rg_ins_exp").setValue(new Date(Date.now()));  // removed convertdate ruhina
                    this.insuredDetailForm.controls['rg_ins_exp'].updateValueAndValidity()
              } 
              else if (resData.CurrentInsuranceId == 2){
                this.insuredDetailForm
                .get("vhclTPLCoverage")
                .setValue(this.activePolicyTypeArr[1]);
              }
              
              else {
                this.insuredDetailForm
                  .get("vhclTPLCoverage")
                  .setValue(this.activePolicyTypeArr[2]);
              }  //no insurance not working hence added ID 
            }

            //Origin
            var indexNationality;
            if (resData.Origin != "") {
              this.countryArr.forEach((item, index) => {
                if (item.CountryName == resData.Origin) {
                  indexNationality = item;
                }
              });
              this.insuredDetailForm
                .get("rg_origin")
                .setValue(indexNationality);
            } else {
              this.insuredDetailForm.get("rg_origin").setValue("");
            }

            //Driving Lic. issue place
            if (resData.DrivingLicIssuePlace != "") {
              var indexPlace = this.cityArr.findIndex(function (obj, k) {
                return obj.CityName === resData.IssuePlace;
              });
              let drivLicPlace = this.cityArr[indexPlace];

              if (
                typeof drivLicPlace != "undefined" &&
                drivLicPlace != "undefined"
              ) {
                this.insuredDetailForm
                  .get("d_place_issue")
                  .setValue(drivLicPlace);
                this.getIssuePlace(drivLicPlace.CityName);
              }
            } else {
              this.insuredDetailForm.get("d_place_issue").setValue("");
            }
          //NCD
          this.motorQuoteService
          .getVehicleNcdStandard(this.language_code, "MOTOR",this.insuredDetailForm.value.rg_reg_date,this.insuredDetailForm.value.adtnl_detail_repairTye,this.insuredDetailForm.value.rg_model_year,this.insuredDetailForm.value.d_issue_date)
          .subscribe((res) => {
          this.NCDData = res.vechileNCDData;
   
   
            var indexNCD = this.NCDData.findIndex(function (obj, k) {
              return obj.NCDDescription === resData.NCD;
            });
            let NCDVal = this.NCDData[indexNCD];
            this.insuredDetailForm.get("adtnl_detail_NCD").setValue(NCDVal);
          });

            this.showLoader.retrieveQuote = false;

            this.getDeductibleValue();
          }
          /// Code for get deductitable

          if (resData.Bsrc == "B2C" || resData.Bsrc == null) {
            this.getDataForB2CRetievQuote();
          }
        }

        if (res.response_code == 2) {
          this._route.navigate(["home/retreivequote"]);
          this.showLoader.retrieveQuote = false;
        }
      });
  }

  getDataForB2CRetievQuote() {
    // alert(1)
    console.log(111);
    let resData = this.quoteDetail;
    //Policy Type
    var indexInsType = this.instypes.findIndex(function (obj, k) {
      return (
        obj.ProductShortName.toLowerCase() === resData.PolicyType.toLowerCase()
      );
    });
    let insVal = this.instypes[indexInsType];
    this.insuredDetailForm.get("adtnl_detail_insType").setValue(insVal);
    // this.insuredDetailForm.get('partner').setValue(resData.CedantBrokerId);
    // this.insuredDetailForm.get('branch').setValue(resData.CedantBrokerBranchId);
    this.insuredDetailForm.get("e_name").setValue(resData.InsuredName);
    this.insuredDetailForm
      .get("adtnl_detail_email")
      .setValue(resData.InsuredEmail);
    this.insuredDetailForm
      .get("adtnl_detail_mobile")
      .setValue(resData.MobileCode + resData.InsuredMobile);
    //    this.insuredDetailForm.get('adtnl_detail_address').setValue(resData.InsuredAddress);
    //    this.insuredDetailForm.get('adtnl_detail_area').setValue(resData.Area);
    this.insuredDetailForm
      .get("rg_chassis_num")
      .setValue(resData.ChassisNumber);
    this.checkChassisValidation = true;
    this.insuredDetailForm.get("isChassisValidate").setValue("1");
    this.insuredDetailForm
      .get("adtnl_detail_gccSpecified")
      .setValue(resData.HasGCCSpecification);
    this.insuredDetailForm
      .get("adtnl_detail_vhclModified")
      .setValue(resData.IsModified);
    //    this.insuredDetailForm.get('e_eid').setValue(resData.EmiratesIdNumber);
    //    this.insuredDetailForm.get('rg_engin_num').setValue(resData.EngineNumber);
    //    this.insuredDetailForm.get('adtnl_detail_poBoxNum').setValue(resData.POBox);
    //    this.insuredDetailForm.get('rg_TC_num').setValue(resData.TrafficFileNo);
    //    this.insuredDetailForm.get('d_driv_lic_num').setValue(resData.LicenceNumber);
    this.insuredDetailForm
      .get("d_dob")
      .setValue(this.dateConvert(resData.InsuredDOB));
    this.insuredDetailForm
      .get("adtnl_detail_vhclValue")
      .setValue(resData.SumInsured);
    this.insuredDetailForm
      .get("rg_reg_date")
      .setValue(this.dateConvert(resData.FirstRegistrationDate));
    this.insuredDetailForm
      .get("rg_traffic_plate_num")
      .setValue(resData.RegistrationNumber);
    this.insuredDetailForm
      .get("adtnl_detail_brandNew")
      .setValue(resData.IsBrandNew);
    this.insuredDetailForm.get("rg_gvm").setValue(resData.VehGVM);

    this.insuredDetailForm.get("rg_policy_num").setValue(resData.PrvInsPolNo);
    //    this.insuredDetailForm.get('e_cardNumber').setValue(resData.NationalIdCardNo);

    // this.insuredDetailForm.get('e_arabic_name').setValue(resData.ArabicName);    FOR NAME IN ARABIC
   // this.insuredDetailForm.get("d_TC_number").setValue(resData.DLTCNo);
    this.insuredDetailForm
      .get("d_lic_type")
      .setValue(resData.DrivingLicenseType);

    this.vehicleValueLimit.startLimit = resData.ValuationLow;
    this.vehicleValueLimit.endLimit = resData.ValuationHigh;
    this.vehicleValueLimit.isSet = true;

    if (resData.CedantBrokerId == "") {
      this.insuredDetailForm
        .get("partner")
        .setValue(localStorage.getItem("Partner_ID"));
      //this.getPartnerBranchList();
      if(this.partnerID == 1){
        this.getPartnerBranchList();
      }
        else{
          this.getUserBranchList();
        }
    } else {
      this.insuredDetailForm.get("partner").setValue(resData.CedantBrokerId);
          //Added by Anju for default partner branch
          if (resData.CedantBrokerBranchId != "" && resData.CedantBrokerBranchId != undefined) {
            this.defaultBranchId = resData.CedantBrokerBranchId;
          
         }
      //this.getPartnerBranchList();
      if(this.partnerID == 1){
        this.getPartnerBranchList();
      }
        else{
          this.getUserBranchList();
        }
    }

    if (
      resData.InsuranceExpiryDate == null ||
      resData.InsuranceExpiryDate == "1900-01-01 00:00:00.000"
    ) {
      this.insuredDetailForm.get("rg_ins_exp").setValue(null);
    } else {
      this.insuredDetailForm
        .get("rg_ins_exp")
        .setValue(new Date(resData.InsuranceExpiryDate));
        this.insuredDetailForm.controls['rg_ins_exp'].updateValueAndValidity()
    }

    if (
      resData.DrivingLicExpDate == null ||
      resData.DrivingLicExpDate == "1900-01-01 00:00:00.000"
    ) {
      this.insuredDetailForm.get("d_expiry_date").setValue(null);
    } else {
      this.insuredDetailForm
        .get("d_expiry_date")
        .setValue(new Date(resData.DrivingLicExpDate));
    }

    if (
      resData.DrivingLicIssueDate == null ||
      resData.DrivingLicIssueDate == "1900-01-01 00:00:00.000"
    ) {
      this.insuredDetailForm.get("d_issue_date").setValue(null);
    } else {
      this.insuredDetailForm
        .get("d_issue_date")
        .setValue(new Date(resData.DrivingLicIssueDate));
    }

    if (
      resData.NationalIdExpDate == null ||
      resData.NationalIdExpDate == "1900-01-01 00:00:00.000"
    ) {
      this.insuredDetailForm.get("e_expiryDate").setValue('');
    } else {
      this.insuredDetailForm
        .get("e_expiryDate")
        .setValue(new Date(resData.NationalIdExpDate));
    }

    if (
      resData.RegistrationExpiryDate == null ||
      resData.RegistrationExpiryDate == "1900-01-01 00:00:00.000"
    ) {
      this.insuredDetailForm.get("rg_expiry_date").setValue(null);
    } else {
      this.insuredDetailForm
        .get("rg_expiry_date")
        .setValue(new Date(resData.RegistrationExpiryDate));
    }

    //Vehicle own by
    var indexOwnBy = this.insvehibys.findIndex(function (obj, k) {
      return obj.value === resData.InsuredType;
    });
    let ownByVal = this.insvehibys[indexOwnBy];
    this.insuredDetailForm.get("adtnl_detail_vhclOwnBy").setValue(ownByVal);

    //     //Mobile Code
    //     var indexCode = this.codes.findIndex(function (obj, k) {
    //       return obj.viewValue === resData.MobileCode;
    //     });
    // //   let codeVal = this.codes[indexCode];
    // //   this.insuredDetailForm.get('adtnl_detail_mbCode').setValue(codeVal);

    //NCD
    var indexNCD = this.NCDData.findIndex(function (obj, k) {
      return obj.NCDDescription === resData.NCD;
    });
    let NCDVal = this.NCDData[indexNCD];
    this.insuredDetailForm.get("adtnl_detail_NCD").setValue(NCDVal);

    //VEHICLE YEAR
    let yearVal;
    this.vehimodelyrs.forEach((item, index) => {
      if (item.label == resData.VehicleModelYear) {
        yearVal = item;
      }
    });
    this.insuredDetailForm.get("rg_model_year").setValue(yearVal);

    this.quoteDetail.VehicleMake = resData.VehicleMake;
    this.quoteDetail.VehicleModel = resData.VehicleModel;
    this.quoteDetail.TrimCode = resData.TrimCode;
    this.quoteDetail.TrimName = resData.TrimName;
    this.quoteDetail.BodyType = resData.BodyType;

    //  this.getVhclMakeData(this.quoteDetail.VehicleMake, yearVal, 1);

    // Nationality
    // var indexNationality;
    // this.countryArr.forEach((item, index) => {
    //   if (item.CountryName == resData.InsuredNationality) {
    //     indexNationality = item;
    //   }
    // });
    // this.insuredDetailForm.get('e_nationality').setValue(indexNationality);

    //Mortagage bank
    // if (resData.Mortgage != '' || resData.Mortgage != null) {
    //   var indexBank = this.bankDetail.findIndex(function (obj, k) {
    //     return obj.InstituteName === resData.Mortgage;
    //   });
    //   let bankVal = this.bankDetail[indexBank];
    //   if (resData.Mortgage == "Not Mortgaged")
    //     this.insuredDetailForm.get('rg_mortgage').setValue(this.bankDetail[0]);
    //   else
    //     this.insuredDetailForm.get('rg_mortgage').setValue(bankVal);
    // }
    // else {
    //   this.insuredDetailForm.get('rg_mortgage').setValue('');
    // }

    //Gender
    var indexGender = this.genders.findIndex(function (obj, k) {
      return obj.value === resData.InsuredGender;
    });
    let gnderVal = this.genders[indexGender];
    this.insuredDetailForm.get("e_gender").setValue(gnderVal);

    //Vehicle Cylinders
    var indexCylinder = this.vehicylinders.findIndex(function (obj, k) {
      return obj.CylinderName === resData.VehicleCylinders;
    });
    let cylVal = this.vehicylinders[indexCylinder];
    this.insuredDetailForm.get("adtnl_detail_cylinder").setValue(cylVal);

    //Passenger Count
    var indexPasger = this.vehiseatcaps.findIndex(function (obj, k) {
      return obj.value == resData.PassengersCount;
    });
    let PasgerVal = this.vehiseatcaps[indexPasger];
    this.insuredDetailForm.get("rg_num_passenger").setValue(PasgerVal);

    //REG PLACE
    var indexPlace = this.cityArr.findIndex(function (obj, k) {
      return obj.CityName === resData.RegistrationPlace;
    });
    let placeal = this.cityArr[indexPlace];
    this.insuredDetailForm.get("rg_place_issue").setValue(placeal);

    this.quoteDetail.regplace = placeal.CityName;
    //  this.getPlateCode(this.quoteDetail.regplace, 2);

    //Plate category
    // var indexPlCat = this.plateCatArray.findIndex(function (obj, k) {
    //   return obj.label === resData.PlateCategory;
    // });
    // let plCatVal = this.plateCatArray[indexPlCat];
    // this.insuredDetailForm.get('rg_type').setValue(plCatVal.value);

    //Vehicle Color
    // if (resData.VehicleColor == null || resData.VehicleColor == '') {
    //   this.insuredDetailForm.get('vhclColor').setValue('');
    // } else {
    //   var indexColor = this.vhclColorArr.findIndex(function (obj, k) {
    //     return obj.ColorName === resData.VehicleColor;
    //   });
    //   let clrVal = this.vhclColorArr[indexColor];
    //   this.insuredDetailForm.get('vhclColor').setValue(clrVal);

    //Active policy type
    if (this.insuredDetailForm.value.adtnl_detail_brandNew == 1) {
      this.insuredDetailForm
        .get("vhclTPLCoverage")
        .setValue(this.activePolicyTypeArr[1]);
    } else {
      if (resData.CurrentInsurance == "Yes") {
        this.insuredDetailForm
          .get("vhclTPLCoverage")
          .setValue(this.activePolicyTypeArr[2]);
      } else {
        this.insuredDetailForm
          .get("vhclTPLCoverage")
          .setValue(this.activePolicyTypeArr[1]);
      }
    }

    this.showLoader.retrieveQuote = false;
  }

  //---------------------------- GET DOCUMENTS DATA FOR RETRIEVE -----------------------------------------------------------//
  getDataForEditQuoteDocs(quoteNumber) {
    this.motorQuoteService
      .getDataForEditQuoteDoc(quoteNumber)
      .subscribe((res) => {
        this.editQuoteDocs = res.getDataForEditQuoteDoc;

        if (res.response_message != "Failed") {
          this.editQuoteDocs.forEach((item, index) => {
            var sDoc = false;
            this.fileType = item.DocFileName.split(".");
            this.fileType = this.fileType[this.fileType.length - 1];
            this.fileType = this.fileType == "pdf" ? "PDF" : "IMG";
            if (
              (item.DocumentType != "Other" ||
                item.DocumentType != "Additional_Doc") &&
              item.DocFilePath != ""
            ) {
              this.tempDocArray = this.editQuoteDocs;
            }

            if (
              item.DocumentType == "reg_Card_Front" &&
              item.DocFilePath != ""
            ) {
              sDoc = true;
              this.showLoader.reg_card_front = false;
              this.hideImages.reg_card_front = true;
              this.insuredDetailForm
                .get("reg_Card_FrontFilePath")
                .setValue(this.editQuoteDocs[index].DocFilePath);
              this.insuredDetailForm
                .get("reg_Card_FrontFileType")
                .setValue(this.fileType);
            }

            if (
              item.DocumentType == "reg_Card_Back" &&
              item.DocFilePath != ""
            ) {
              sDoc = true;

              this.showLoader.reg_card_back = false;
              this.hideImages.reg_card_back = true;
              this.insuredDetailForm
                .get("reg_Card_BackFilePath")
                .setValue(this.editQuoteDocs[index].DocFilePath);
              this.insuredDetailForm
                .get("reg_Card_BackFileType")
                .setValue(this.fileType);
            }

            if (
              item.DocumentType == "emirated_ID_Front" &&
              item.DocFilePath != ""
            ) {
              sDoc = true;
              this.showLoader.emirates_Id_front = false;
              this.hideImages.emirates_Id_front = true;
              this.insuredDetailForm
                .get("emirated_ID_FrontFilePath")
                .setValue(this.editQuoteDocs[index].DocFilePath);
              this.insuredDetailForm
                .get("emirated_ID_FrontFileType")
                .setValue(this.fileType);
            }

            if (
              item.DocumentType == "emirated_ID_Back" &&
              item.DocFilePath != ""
            ) {
              sDoc = true;
              this.showLoader.emirates_Id_back = false;
              this.hideImages.emirates_Id_back = true;
              this.insuredDetailForm
                .get("emirated_ID_BackFilePath")
                .setValue(this.editQuoteDocs[index].DocFilePath);
              this.insuredDetailForm
                .get("emirated_ID_BackFileType")
                .setValue(this.fileType);
            }

            if (
              item.DocumentType == "driving_Lic_Front" &&
              item.DocFilePath != ""
            ) {
              sDoc = true;
              this.showLoader.driving_Lic_front = false;
              this.hideImages.driving_Lic_front = true;
              this.insuredDetailForm
                .get("driving_Lic_FrontFilePath")
                .setValue(this.editQuoteDocs[index].DocFilePath);
              this.insuredDetailForm
                .get("driving_Lic_FrontFileType")
                .setValue(this.fileType);
            }

            if (
              item.DocumentType == "driving_Lic_Back" &&
              item.DocFilePath != ""
            ) {
              sDoc = true;
              this.showLoader.driving_Lic_back = false;
              this.hideImages.driving_Lic_back = true;
              this.insuredDetailForm
                .get("driving_Lic_BackFilePath")
                .setValue(this.editQuoteDocs[index].DocFilePath);
              this.insuredDetailForm
                .get("driving_Lic_BackFileType")
                .setValue(this.fileType);
            }

            if (
              item.DocumentType == "trade_Licence" &&
              item.DocFilePath != ""
            ) {
              sDoc = true;
              this.showLoader.trade_Licence = false;
              this.hideImages.trade_Licence = true;
              this.insuredDetailForm
                .get("trade_LicenceFilePath")
                .setValue(this.editQuoteDocs[index].DocFilePath);
              this.insuredDetailForm
                .get("trade_LicenceFileType")
                .setValue(this.fileType);
            }

            if (
              item.DocumentType == "TRN_Certificate" &&
              item.DocFilePath != ""
            ) {
              sDoc = true;
              this.showLoader.TRN_Certificate = false;
              this.hideImages.TRN_Certificate = true;
              this.insuredDetailForm
                .get("TRN_CertificateFilePath")
                .setValue(this.editQuoteDocs[index].DocFilePath);
              this.insuredDetailForm
                .get("TRN_CertificateFileType")
                .setValue(this.fileType);
            }

            if (
              (item.DocumentType == "Other" || sDoc == false) &&
              item.DocFilePath != ""
            ) {
              this.showLoader.multipleDoc = false;
              this.hideImages.multipleDoc = true;

              // this.multilpleFile = this.editQuoteDocs;
              this.multilpleFile.push(item);

              this.tempMultipleDocData = this.multilpleFile;

              this.multilpleFile.forEach((item1, index1) => {
                let fileType =
                  this.multilpleFile[index1].DocFileName.split(".");
                fileType = fileType[fileType.length - 1];
                fileType = fileType == "pdf" ? "PDF" : "IMG";
                this.multilpleFile[index1].file =
                  this.multilpleFile[index1].DocFilePath;
                this.multilpleFile[index1].fileType = fileType;
              });
            }

            if (
              (item.DocumentType == "Additional_Doc"|| item.DocumentType == "SurveyReport" ) &&
              item.DocFilePath != ""
            ) {
              //&& item.UploadedStage == "QUOTE_ADDINFO"
              this.showLoader.additionalDoc = false;
              this.hideImages.additionalDoc = true;

              // this.additionalDocFile = this.editQuoteDocs;
              this.additionalDocFile.push(item);

              this.additionalDocFile.forEach((item1, index1) => {
                let fileType =
                  this.additionalDocFile[index1].DocFileName.split(".");
                fileType = fileType[fileType.length - 1];
                fileType = fileType == "pdf" ? "PDF" : "IMG";
                this.additionalDocFile[index1].file =
                  this.additionalDocFile[index1].DocFilePath;
                this.additionalDocFile[index1].fileType = fileType;
              });
              console.log(this.additionalDocFile);
            }


            //Added by Anju to show Survey Report
            if (
              (item.DocumentType == "SurveyReport" ) &&
              item.DocFilePath != ""
            ) {
              this.NCDFormSubmit.get("SurveyReport").setValue("true");
              this.multilpleFile1.push(item);

              this.multilpleFile1.forEach((item1, index1) => {
                let fileType =
                  this.multilpleFile1[index1].DocFileName.split(".");
                fileType = fileType[fileType.length - 1];
                fileType = fileType == "pdf" ? "PDF" : "IMG";
                this.multilpleFile1[index1].file =
                  this.multilpleFile1[index1].DocFilePath;
                this.multilpleFile1[index1].fileType = fileType;
              });
              console.log(this.multilpleFile1);
            }
          });
        }
      });
  }
  // this.tempDocArray.length
  getTermsConditions() {
    this.showTerms = true;
    this.motorQuoteService.getTermsConditions("B2B").subscribe((res) => {
      if (res.response_code == 1) {
        this.showTerms = false;
        this.termsAndCondition = res.termsAndCondition;
      }
    });
  }

  getIssuePlace(place) {
    this.insuredDetailForm.get("adtnl_detail_address").setValue(place);
  }

  callChangeStepper(event: any, stepper) {
    if (event.selectedIndex == 0) {
      this.checkStepper = false;
    }

    if (event.selectedIndex == 1) {
      if (this.checkStepper == false) {
        let type = this.webQuoteNumber == "" ? 1 : 2;

        this.getUserAccessPermission(stepper, type, 0);
      } else {
        this.checkStepper = true;
      }
    }

    if (event.selectedIndex == 2) {
      return false;
    }
  }

  public additionalLoading: number;
  additionalDiscount: number;
  loadingByPercent: number;
  //----------------- APPLY DISCOUNT ON PREMIUM WITH VAT ----------------//
  calculateDiscount(loadingby, loadByAmount, calculationType) {
    this.isLoadingDisabled=false;
    // for  discount
    if (loadingby == 2) {
      if (calculationType == 1 && loadByAmount >= 45) {
        // for percent

        Swal.fire("", "Invalid percent selected for discount", "error");
        return false;
      }
      if (calculationType == 0 && loadByAmount >= 500) {
        // for amount

        Swal.fire("", "Invalid amount selected for discount", "error");
        return false;
      }
    }

    // for loading
    if (loadingby == 1) {
      if (
        this.businessSource == "DIRECT" &&
        this.partnerId == 1 &&
        this.userType == "POS" &&
        (this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW')
      ) {
        // for admin

        if (calculationType == 1 && loadByAmount >= 45) {
          // for percent

          Swal.fire("", "Invalid percent selected for loading", "error");
          return false;
        }
        if (calculationType == 0 && loadByAmount >= 2000) {
          // for amount

          Swal.fire("", "Invalid amount selected for loading", "error");
          return false;
        }
      } else {
        // for user

        if (calculationType == 1 && loadByAmount >= 20) {
          // for percent

          Swal.fire("", "Invalid percent selected for loading", "error");
          return false;
        }
        if (calculationType == 0 && loadByAmount >= 250) {
          // for amount

          Swal.fire("", "Invalid amount selected for loading", "error");
          return false;
        }
      }
    }
    const tempData=this.PlanDataJson.planBenefitData.benefit_data;
    this.tempBenefitData=tempData;
    console.log("tempBenefitdata",this.tempBenefitData)
    const copy = JSON.parse(JSON.stringify(this.tempBenefitData));
    let base_Primium = copy;
    
    console.log(copy)
    this.PlanDataJson.planBenefitData.benefit_data = this.tempBenefitData;

    let Multiple: number;

    if (loadingby == 1) {
      Multiple = 1;
    } else {
      Multiple = -1;
    }

   // this.totalFixPremium = this.tempTotalLoadFixPrem;

    let loadingAmt = 0;

    let discount: number = Number(
      this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt
    );

    let policyFee: number = 0;

    this.tempBenefitData.forEach((item, index) => {
      if (
        this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "200" &&
        item.Code == "40099"
      ) {
        //get policy fee
        policyFee = item.premium;
      }
    });

    this.tempBenefitData.forEach((item, index) => {
      // FOR COMPRENSIVE
      if (
        this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100" &&
        item.Code == "40001"
      ) {
        let newBasePremium = this.totalFixPremium;
        if (calculationType == 0) {
          console.log(loadByAmount)
          loadingAmt = Number(loadByAmount) * Multiple; //FOR BY AMOUNT
          this.loading_rate = 1;
          this.loadByAmount = Number(loadingAmt) ;
          
          this.LDAmountOld=this.LDAmount;//  Modified by Anju for #INC-10772 Motor loading amount can be re-edited by UW
          this.LDAmount = Number(this.loadByAmount) ;
          console.log(this.loadByAmount)
        } else {
          // loadingAmt =
          //   Math.round((loadByAmount * Number(newBasePremium)) / 100) *
          //   Multiple; //FOR BY PERCENT
          loadingAmt =
            Number(((loadByAmount * Number(newBasePremium)) / 100).toFixed(2)) *
            Multiple; //FOR BY PERCENT
          this.loading_rate = loadByAmount;
          this.loadByAmount = Number(loadingAmt) ;
          this.LDAmountOld=this.LDAmount;//  Modified by Anju for #INC-10772 Motor loading amount can be re-edited by UW
          this.LDAmount = Number(this.loadByAmount) ;
          // this.loadingByPercent = Number(loadingAmt);
        }
        //  Modified by Anju for #INC-10772 Motor loading amount can be re-edited by UW   
         item.premium = Number(item.premium)-  Number(this.LDAmountOld)+ Number(this.loadByAmount)
        
       //item.premium = Number(item.premium) + Number(this.LDAmount)

      }

    
        // let totalPrem: number = 0;
        // totalPrem += newBasePremium;
        // totalPrem += discount;
        // totalPrem += loadingAmt;

        // console.log("Total Premium",totalPrem)
        // if (Number(base_Primium[1].premium) > Number(base_Primium[0].premium)) {
        //   totalPrem -= parseInt(base_Primium[0].premium);
        // } else {
        //   totalPrem -= parseInt(base_Primium[1].premium);
        // }
        
        // if (loadingby == 2) {
        //   this.PlanDataJson.planBenefitData.benefit_data[index].premium =
        //     totalPrem-1;  // added -1 to avoid premium mismatch when discount is applied .
        // }
      // }

      // FOR TPL
      if (
        this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "200" &&
        item.Code == "40002"
      ) {
        let newBasePremium = this.totalFixPremium;

        if (calculationType == 0) {
          loadingAmt = Number(loadByAmount) * Multiple; //FOR BY AMOUNT
          this.loading_rate = 1;
          //Modified by Anju for B2B Discount Calculation Issue for TPL
          this.loadByAmount = Number(loadingAmt);
          this.LDAmountOld = this.LDAmount;//  Modified by Anju for #INC-10772 Motor loading amount can be re-edited by UW
          this.LDAmount = Number(this.loadByAmount);
        } else {
          // loadingAmt =
          //   Math.round((loadByAmount * Number(newBasePremium)) / 100) *
          //   Multiple; //FOR BY PERCENT
          loadingAmt =
          Number(((loadByAmount * Number(newBasePremium)) / 100).toFixed(2)) *
          Multiple; //FOR BY PERCENT
          this.loading_rate = loadByAmount;
          this.loadByAmount = Number(loadingAmt);
          this.LDAmountOld = this.LDAmount;//  Modified by Anju for #INC-10772 Motor loading amount can be re-edited by UW
          this.LDAmount = Number(this.loadByAmount) ;
          //this.loadingByPercent = Number(loadingAmt);
        }
        //  item.premium = Number(item.premium)  + Number(this.loadByAmount)
        item.premium = Number(item.premium) -Number(this.LDAmountOld) + Number(this.loadByAmount);//  Modified by Anju for #INC-10772 Motor loading amount can be re-edited by UW
        // if (loadingby == 2) {
        //   let totalPrem: number = 0;

        //   if (
        //     this.PlanDataJson.planBenefitData.benefit_data[index].Code == 40002
        //   ) {
        //     totalPrem = parseInt(base_Primium[index].premium) + loadingAmt;
        //   }

        //   this.PlanDataJson.planBenefitData.benefit_data[index].premium =
        //     totalPrem;
        // }
      }
    });
    console.log("after applying dicount/loading",this.tempBenefitData)
    this.PlanDataJson.planBenefitData.benefit_data = this.tempBenefitData
    this.totalFixPremium = 0 
    this.PlanDataJson.planBenefitData.benefit_data.forEach(
      (item, index) => {
        console.log("benefit",this.discount,item.premium)
        this.totalFixPremium =
          Number(this.totalFixPremium) + Number(item.premium) 
        // this.PlanDataJson.planBenefitData.benefit_data[index].premium = (item.premium - item.premium * discount);
      }
    );
    this.totalFixPremium = Number(this.totalFixPremium) - Number(this.discount);

    // if (loadingby == 1) {
    //   this.additionalLoading = loadingAmt;
    // } else {
    //   this.additionalDiscount = loadingAmt;
    // }
    // this.totalFixPremium = Math.round(
    //   Number(this.totalFixPremium) + Number(loadingAmt)
    // );

     this.VATAMT = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
     this.Total_Primium =
      Number(this.totalFixPremium) +
      Number(this.totalVariablePremium) +
      Number(this.VATAMT);
      console.log("Total Premium+++",this.totalFixPremium,this.totalVariablePremium)
    this.tempBenefitData = tempData;
    //this.isLoadingDisabled=true;
  }

  //sherinedke
  showMultipleDoc(fileName, documentType) {
    this.multipleImg = fileName;
    this.showAllDocImg = 1;
    this.ImagedocumentType = documentType;
  }
  modalboxclose() {
    this.showAllDocImg = 0;
  }
  quotationmultimg() {
    this.showMultiQuotationImg = 1;
  }
  closeQuotationImg() {
    this.showMultiQuotationImg = 0;
  }

  additionalDocImg() {
    this.showAdditionalDocImg = 1;
  }

  closeadditionalDocImg() {
    this.showAdditionalDocImg = 0;
  }

  sendRefferMailToAdmin(type, frame) {
    this.showLoader.referal = true; // added loader to avoid double click - ruhina
    console.log("type",type)
    if (type == 1 || type == 2) {
      if (this.referalDescription == "") {
        this.validtnMsg = true;
        this.showLoader.referal = false;
        return false;
      }
      this.validtnMsg = false;
      this.showLoader.referal = true;
    }

    if (type == 3 || type == 4 || type == 5) {
      if (this.referalDescription == "") {
        this.validtnMsg = true;
        this.showLoader.referal = false;
        return false;
      }
      this.validtnMsg = false;
      this.showLoader.referal = true;
    }

    let refer_type;
    let event_type;

    if (type == 1 || type == 2) {
      event_type = "REFERQUOTE"; // REFER A QUOTE
      refer_type = type == 1 ? "SYSTEM" : "MANUAL";
      this.policyStatus = "REFERED";
    }

    if (type == 3) {
      event_type = "REFERAPPROVED"; // APPROVED
      refer_type = "";
      this.policyStatus = "APPROVED";
    }

    if (type == 4) {
      event_type = "REFERREJECTED"; // REJECTED
      refer_type = "";
      this.policyStatus = "REJECTED";
    }

    if (type == 5) {
      event_type = "ADDITIONALINFO"; // ADDITIONAL INFO
      refer_type = "";
      this.policyStatus = "ADDITIONALINFO";
    }

    this.motorQuoteService
      .sendReferralMailToAdmin(
        this.webQuoteNumber,
        this.referalDescription,
        event_type,
        refer_type,
        this.referral_reason,
        this.tempMultipleDocData,
        this.refAdditionalCondition
      )
      .subscribe((res) => {
        if (res.response_code == 1) {
          if (type == 1) {
            this.showLoader.referal = false;
            Swal.fire(
              "Your quote has been referred to our expert team of UWs for review.",
              "You will be notified soon after the review. Your Quotation Reference# " +
                this.webQuoteNumber,
              "success"
            );
            this._route.navigate(["agentMotor/motorquote"]);
          }

          if (type == 2 || type == 3 || type == 4 || type == 5) {
            this.getQuotePlan("");
            this.referalModel = false;
          }
        }
      });
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  getReferalCondtion(ref_val) {
    this.referalModel = true;
    if (ref_val == "APPROVED") {
      console.log('Approved')
      this.refer_type = "Approve";
      this.refer_condtion_type = 3;
      // this.redirectOnQuoteStatus()
    }

    if (ref_val == "REJECTED") {
      this.refer_type = "Reject";
      this.refer_condtion_type = 4;
    }

    if (ref_val == "REFERRAL") {
      this.refer_type = "Refer";
      this.refer_condtion_type = 2;
    }

    if (ref_val == "ADDITIONALINFO") {
      this.refer_type = "Additional Info";
      this.refer_condtion_type = 5;
    }
  }

  redirectOnQuoteStatus() {
    this._route.navigate([
      "agentMotor/MTquotepage/approveQuote/" + this.webQuoteNumber,
    ]);
  }

  getTonnage() {
    this.motorQuoteService
      .getTonnage(
        this.insuredDetailForm.value.rg_vhcl_make,
        this.insuredDetailForm.value.rg_vhcl_model,
        this.insuredDetailForm.value.rg_model_year
      )
      .subscribe((res) => {
        if (res.response_code == 1) {
          this.insuredDetailForm
            .get("loading_capacity")
            .setValue(res.tonnageName);
        }
      });
  }

  getQuotationHistory(quoteNumber) {
    this.motorQuoteService
      .getQuotationHistory(quoteNumber, "MT")
      .subscribe((res) => {
        if (res.response_code == 1) {
          this.quotationHistoryArr = res.quotationHistoryList;
        }
      });
  }
  public deductCnt = 0;
  getDeductibleValue() {
    if (this.insuredDetailForm.value.adtnl_detail_insType.crsProdId == "100") {
      let deductibleArray = {
        lob_code: "MT",
        // policy_type : this.insuredDetailForm.value.adtnl_detail_insType,
        policy_type: {
          value: this.insuredDetailForm.value.adtnl_detail_insType.crsProdId,
          viewValue:
            this.insuredDetailForm.value.adtnl_detail_insType.ProductShortName,
          WebProdCode:
            this.insuredDetailForm.value.adtnl_detail_insType.ProductCode,
          CoreProdCode:
            this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode,
        },
        model_year: this.insuredDetailForm.value.rg_model_year,
        vechile_make: this.insuredDetailForm.value.rg_vhcl_make,
        vechile_model: this.insuredDetailForm.value.rg_vhcl_model,
        body_type: this.insuredDetailForm.value.adtnl_detail_bodyType,
        number_of_seat: this.insuredDetailForm.value.rg_num_passenger,
        sum_insured: this.insuredDetailForm.value.adtnl_detail_vhclValue,
        brand_new: this.insuredDetailForm.value.adtnl_detail_brandNew,
        scheme_code: this.SchemeCode,
      };

      this.motorQuoteService.getDeductible(deductibleArray).subscribe((res) => {
        if (res.getDeductibleData[0].ExcessAmt == null && this.deductCnt <= 4) {
          setTimeout(() => {
            //<<<---using ()=> syntax
            this.getDeductibleValue();
          }, 3000);
          this.deductCnt++;
        }

        // if(this.insuredDetailForm.value.adtnl_detail_insType != '' && this.insuredDetailForm.value.adtnl_detail_insType != null){
        //       if(res.getDeductibleData[0].ExcessAmt==null){
        //         this.referalStatus = true ;
        //       }
        // }
        this.insuredDetailForm
          .get("deductible")
          .setValue(res.getDeductibleData[0].ExcessAmt);
      });
      this.IsTPL = false  // condn added to hide vehicle value field if it is TPL -ruhina
    }
    else
    {
      this.IsTPL = true // condn added to hide vehicle value field if it is TPL -ruhina
    }
  }

  onChangeVATRegister() {}

  insuredVechOwnedBy() {
    if (
      this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue ==
      "Corporate"
    ) {
      this.insuredDetailForm.controls["industry_type"].setValidators([
        Validators.required,
      ]);
      this.insuredDetailForm.controls["vat_register"].setValidators([
        Validators.required,
      ]);
      this.insuredDetailForm.controls["e_eid"].setValidators(null);
      this.insuredDetailForm.controls["d_dob"].setValidators(null);
      this.insuredDetailForm.controls["e_nationality"].setValidators(null);
      this.insuredDetailForm.controls["d_driv_lic_num"].setValidators(null);
      this.insuredDetailForm.controls["d_place_issue"].setValidators(null);
      this.insuredDetailForm.controls["d_issue_date"].setValidators(null);
      this.insuredDetailForm.controls["d_expiry_date"].setValidators(null);
     // this.insuredDetailForm.controls["d_TC_number"].setValidators(null);
      this.insuredDetailForm.controls["e_gender"].setValidators(null);
      this.insuredDetailForm.controls["trade_lic_num"].setValidators([
        Validators.required,
      ]);
      this.insuredDetailForm.controls["vat_register"].valueChanges.subscribe(
        (obj) => {
          if (obj == "Yes") {
            this.insuredDetailForm.controls["TRN_num"].setValidators([
              Validators.required,
            ]);
          } else {
            this.insuredDetailForm.controls["TRN_num"].setValidators(null);
          }
          this.insuredDetailForm.controls["TRN_num"].updateValueAndValidity();
        }
      );
    } else {
      this.insuredDetailForm.controls["industry_type"].setValidators(null);
      this.insuredDetailForm.controls["vat_register"].setValidators(null);
      this.insuredDetailForm.controls["d_dob"].setValidators([
        Validators.required,
      ]);
      this.insuredDetailForm.controls["e_nationality"].setValidators([
        Validators.required,
      ]);
      this.insuredDetailForm.controls["e_eid"].setValidators([
        Validators.required,
      ]);
      // this.insuredDetailForm.controls["d_driv_lic_num"].setValidators([
      //   Validators.required,
      // ]);
      // this.insuredDetailForm.controls["d_place_issue"].setValidators([
      //   Validators.required,
      // ]);
      this.insuredDetailForm.controls["d_issue_date"].setValidators([
         Validators.required,
       ]);
      // this.insuredDetailForm.controls["d_expiry_date"].setValidators([
      //   Validators.required,
      // ]);
      // this.insuredDetailForm.controls["d_TC_number"].setValidators([
      //   Validators.required,
      // ]);

      //LicIssue date,Place is non mandatory - ruhina - commenetd above code added null 
      this.insuredDetailForm.controls["d_place_issue"].setValidators(null);
      //this.insuredDetailForm.controls["d_issue_date"].setValidators(null);
      this.insuredDetailForm.controls["d_expiry_date"].setValidators(null);
      this.insuredDetailForm.controls["d_driv_lic_num"].setValidators(null);
      this.insuredDetailForm.controls["e_gender"].setValidators([
        Validators.required,
      ]);
      this.insuredDetailForm.controls["trade_lic_num"].setValidators(null);
    }

    this.insuredDetailForm.controls["industry_type"].updateValueAndValidity();
    this.insuredDetailForm.controls["vat_register"].updateValueAndValidity();
    this.insuredDetailForm.controls["d_dob"].updateValueAndValidity();
    this.insuredDetailForm.controls["e_nationality"].updateValueAndValidity();
    this.insuredDetailForm.controls["e_eid"].updateValueAndValidity();
    this.insuredDetailForm.controls["d_driv_lic_num"].updateValueAndValidity();
    this.insuredDetailForm.controls["d_place_issue"].updateValueAndValidity();
    this.insuredDetailForm.controls["d_issue_date"].updateValueAndValidity();
    this.insuredDetailForm.controls["d_expiry_date"].updateValueAndValidity();
   // this.insuredDetailForm.controls["d_TC_number"].updateValueAndValidity();
    this.insuredDetailForm.controls["e_gender"].updateValueAndValidity();
    this.insuredDetailForm.controls["trade_lic_num"].updateValueAndValidity();
  }

  onPrevPolChange(prevPolDate) {

    //ruhina - add referal condition
    // if(prevPolDate < new Date(Date.now()))
    // {
    //   this.referalStatus = true;
    // }
    // else{
    //   this.referalStatus = false;
    // }
    if (
      this.toDay < prevPolDate &&
      prevPolDate < new Date(new Date().setDate(new Date().getDate() + 13))
    ) {
      this.policyMinDate = new Date(Date.now());
      this.policyMaxDate = new Date(
        new Date(prevPolDate).setDate(prevPolDate.getDate() + 1)
      );
      this.insuredDetailForm.get("polStartDate").setValue(this.policyMinDate);
    } else {
      this.policyMinDate = new Date(Date.now());
      this.policyMaxDate = new Date(
        new Date().setDate(new Date().getDate() + 13)
      );
      this.insuredDetailForm.get("polStartDate").setValue(this.toDay);
    }
  }

  changeToUpperCase(iputData) {
    return iputData.toUpperCase();
  }

  // Removed transaction type validation by Anju

  // onChangeTransactionType(transaction_type) {
  //   if (transaction_type == "Renewal") {
  //     this.insuredDetailForm.controls["rg_plateCode"].setValidators([
  //       Validators.required,
  //     ]);
  //     this.insuredDetailForm.controls["rg_traffic_plate_num"].setValidators([
  //       Validators.required,
  //     ]);
  //     this.insuredDetailForm.controls["vhclColor"].setValidators([
  //       Validators.required,
  //     ]);
  //   } else {
  //     this.insuredDetailForm.controls["rg_plateCode"].setValidators(null);
  //     this.insuredDetailForm.controls["rg_traffic_plate_num"].setValidators(
  //       null
  //     );
  //     this.insuredDetailForm.controls["vhclColor"].setValidators(null);
  //   }

  //   this.insuredDetailForm.controls["rg_plateCode"].updateValueAndValidity();
  //   this.insuredDetailForm.controls[
  //     "rg_traffic_plate_num"
  //   ].updateValueAndValidity();
  //   this.insuredDetailForm.controls["vhclColor"].updateValueAndValidity();
  // }

  // ---------------------------------------dropdown web service-------------------------------------------------
  getQuotationFormData() {
    this.issuepolicyService.getQuotationFormData().subscribe((response) => {
      this.formDataRes = response;

      this.partnersArr = this.formDataRes.Partners;
      this.filteredPartners.next(this.partnersArr.slice());       
      if (this.retrieveQuoteNumber == "") {
        this.partnersArr.forEach((item, index) => {
          if (item.PartnerId == this.partnerID) {
            this.partnerVal = item.PartnerId;
          }
        });

        this.insuredDetailForm.get("partner").setValue(this.partnerVal);     
        //this.getPartnerBranchList();
        if(this.partnerID == 1){
          this.getPartnerBranchList();
        }
          else{
            this.getUserBranchList();
          }
      }
    });
  }

  getPartnerBranchList() {
    console.log("----");
    this.partnerBranchArr = [];

    this.issuepolicyService
      .getpartnerBranch(this.insuredDetailForm.value.partner)
      .subscribe((res) => {
        let updateRes: any = res;

        this.partnerBranchArr = updateRes.branchList;
        //Remove online branch 
        this.partnerBranchArr.forEach( (item, index) => {
          if(item.BranchName === 'ONLINE') this.partnerBranchArr.splice(index,1);
        });
        this.branchId = updateRes.branchList[0];

        //  branch
        if (this.retrieveQuoteNumber == "") {
          this.partnerBranchArr.forEach((item, index) => {
            if (item.Id == this.branchId.Id) {
              this.branchVal = item;
            }
          });

          this.insuredDetailForm.get("branch").setValue(this.branchVal);
        } else {
          this.insuredDetailForm.get("branch").setValue(this.branchId);
        }
         //Added by Anju for default branch
         if(this.defaultBranchId!='' && this.defaultBranchId!=undefined){
          const defaultBranch = this.partnerBranchArr.find(item => item.Id === this.defaultBranchId);
          this.insuredDetailForm.get("branch").setValue(defaultBranch);
          }
      });
      this.showLoader.load_data = false; // added loader for initial page loading - ruhina
  }

  //Added by Anju for User branch display
getUserBranchList(){
  this.userBranchArr = [];
  // this.loader=this.globalService.spinnerShow();
  this.issuepolicyService.getuserBranch(this.userID).subscribe(res =>{
    let updateRes:any = res;
    // this.loader=this.globalService.spinnerHide();
    this.userBranchArr = updateRes.branchList ;
    this.branchId=updateRes.branchList[0];

      this.userBranchArr.forEach((item,index)=>{
        if(item.Id ==  this.branchId.Id){
          this.branchVal  = item;
          
        }
    });
     this.insuredDetailForm.get('branch').setValue(this.branchVal); 
     //Added by Anju for default branch
     if(this.defaultBranchId!=''  && this.defaultBranchId!=undefined){
      const defaultBranch = this.userBranchArr.find(item => item.Id === this.defaultBranchId);
      this.insuredDetailForm.get("branch").setValue(defaultBranch);
      }
  });
  this.showLoader.load_data = false;
}

  //----------------------------- CHECK  USER ACCESS FOR POLICY ISSUE BUTTONS ------------------------------//
  checkAccessForPolicyIssueButtons(){
    this.motorQuoteService.checkAccessToMotorPolicyButtons('MT',  this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode).subscribe(res =>{
          if(res.IsNonPayIssueAllowed == 0){
               this.issuePolicyBtnAccess = true;
          }

          if( res.IsOnlinePaymentAllowed == 0){
               this.onlinePayBtnAccess = true;
          }
    });
}

  //------------------------- CHECK USER ACCESS FOR PAGE ------------------------------------//
  // checkUserAccess(){
  //   this.motorQuoteService.checkUserAccess('MOTORQUOTE',this.localStorDta.EmailAddress, 'MT').subscribe(res => {
  //     let response = res;

  //         if (response.userAccessData == 0) {                 // userAccessData = 1 ------ give access
  //               Swal.fire('Sorry !','You can not authorised.','error');
  //               this._route.navigateByUrl('/agentMotor/motorquote');                                         // userAccessData = 0 ------- access denied
  //               this.showLoader.retrieveQuote = false;
  //               return false;
  //         }else{

  //             this.showLoader.retrieveQuote = false;
  //         }
  //     });
  // }
  async getinsuredType() {
    await this.motorQuoteService
      .getInsuredType(this.SchemeCode)
      .subscribe((result) => {
        this.instypes = result.insuredType;
        console.log(this.instypes);
      });
  }

  restrictnumber(ev) {
    console.log(ev.target.value);
    this.mobilevalue = ev.target.value;
    this.mobilevalue.replaceAll(/[^a-z]/g, "red");
    // ev.target.value.replace(/[^a-z]/, '')
  }

  sumvaluechange(ev)
  {
   this.sumvalue = ev
    console.log("sumvalue",ev)
    // if(this.sumvalue > 30000){
    //   console.log("greater than 30000")
    //   this.referalStatus = true;
    // }
    // else {
    //   this.referalStatus = false;
    // }
  }

  Gccvaluechange(ev)
  {
    console.log(this.insuredDetailForm.get("adtnl_detail_vhclModified").value);
    console.log(this.insuredDetailForm.get("adtnl_detail_gccSpecified").value);
    this.gccspec = this.insuredDetailForm.get("adtnl_detail_gccSpecified").value
    this.vehiclemodified = this.insuredDetailForm.get("adtnl_detail_vhclModified").value 
    //Driving License Age less than one year go for referral - ruhina
    let LicIssueDate= new Date(this.insuredDetailForm.get("d_issue_date").value)
    let today = new Date(Date.now());
    this.drivingLicAge = today.getFullYear() - LicIssueDate.getFullYear() 
    console.log("Driving License Age",this.drivingLicAge)
    // end
    // if( this.gccspec == "Yes" || this.vehiclemodified == "Yes" || this.drivingLicAge < 1){
    //   console.log("its a gcc spec")
    //   this.referalStatus = true;
    // }
    // else {
    //   this.referalStatus = false;
    // }
  }

  //make Prev.Ins Expiry Date non mandatory if no Insurance selected - added below code ruhina
  previousPolicy(ev)
  {
    console.log("in",ev)
    if(ev.id == 1)
    {
      this.prevNoIns = true
      this.insuredDetailForm.controls["rg_ins_exp"].setValidators(null);
      this.insuredDetailForm.get("rg_ins_exp").setValue(Date.now()); 
      this.insuredDetailForm.controls['rg_ins_exp'].updateValueAndValidity()
    }
    else
    {
      this.prevNoIns = false
    }
  } 
  //---------VALIDATION FOR NUMBERS & DECIMAL NUM------------//

  NCDFormSubmit=this.formBuilder.group({
    SurveyReport: ['']
  });;

   
    nextStep(stepper){
      const controls = this.NCDFormSubmit.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
            console.log("invalid form",controls[name])
            Swal.fire("", "Please upload " + name, "error");
            return;
        }
      }
      stepper.next();
    }

  


  fileUpload(e,docName) {
    //this.multilpleFile1 = [];
    this.showLoader.surveryReport = true;//Added loader by Anju
    for (var i = 0; i < e.target.files.length; i++) {
      // console.log( e.target.files[i])
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("docName", docName);
    formData.append("source", "B2B");
    formData.append("stage", "QUOTE");
    formData.append("schemeCode", this.SchemeCode);
    formData.append("QuotationNumber", this.webQuoteNumber);
      this.motorQuoteService.uploadDocuments(formData).subscribe((res) => {
          let updateMemResponse = res;
          let fileType= updateMemResponse.File.split(".");
          fileType = fileType[fileType.length - 1];
          fileType = fileType == "pdf" ? "PDF" : "IMG";
          this.multilpleFile1.push({
            file: updateMemResponse.FileDir            ,
            finalFileName: "Other",
            docName: updateMemResponse.File,
            fileType: fileType,
          });
          this.NCDFormSubmit.get("SurveyReport").setValue("true");
          this.showLoader.surveryReport = false;
         // this.NCDFormSubmit.setValue({ "required": true });
        });
    }
  }

  GetNCDStandard(): void {

    this.getVhclNCDStandard();
     
   }
 
     //------------------------- CHECK USER ACCESS FOR PREMIUM EDIT ------------------------------------//
     checkPremiumEditPermission(){
     this.motorQuoteService.checkPremiumEditPermission(this.localStorDta.EmailAddress, 'MT').subscribe(res => {
       let response = res;
 
           if (response.userAccessPremiumEdit ==  1 ) {                 // userAccessData = 1 ------ give access
                 this.isDiscountLoadingEnabled=true;
                 this.isDeductibleEnabled=true;
                 this.changeVehicleValue=true;
           }
       });
   }
 
   
    stripTime(date) {
     return new Date(date.getFullYear(), date.getMonth(), date.getDate());
 }

 
}
