import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError, observable } from 'rxjs';
import { GlobalService } from "../_service/global.service";
import { config } from './../config';

@Injectable({
  providedIn: 'root'
})
export class TraveInsuranceService {

  apiURL: string = config.apiUrl;
  paymentAPIURL = config.paymentGatewayUrl;
   
  constructor(private httpClient: HttpClient,public _globalService: GlobalService) {}
  

  public handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
      } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
  }

  public handlePaymentError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
}


//----------------------------- FOR GET PROPERTY TYTPE -----------------------------------------//
  public getPropertyType(partnerId,language):Observable<any> {

    return this.httpClient.post(`${this.apiURL}HomeInsuranceWebservice/getPropertyType`,{partner_id:partnerId,language:language},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);

          }));
  }

//------------------------------ FOR GET COVER TYPE ----------------------------------------------//
  public getCoverType(partnerId,lob,prodCode):Observable<any> {

    return this.httpClient.post(`${this.apiURL}HomeInsuranceWebservice/getCoverType`,{partner_id:partnerId,lob:lob,product_code:prodCode},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);

          }));
  }

//------------------------------ FOR GET OWNERSHIP STATUS ---------------------------------------//
  public getOwnershipStatus(partnerId,language):Observable<any> {

    return this.httpClient.post(`${this.apiURL}HomeInsuranceWebservice/getOwnershipStatus`,{partner_id:partnerId,language:language},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);

          }));
  }

//------------------------------ FOR INSERT QUOTATION ---------------------------------------//
  public forGetQuotation(partner_id,isInternalAgent,user_id,cedant_id,prodCode,quotationValuesArray,custPolicyFormTOtal,source,productType,cedant_brokerid,branch_id):Observable<any> {

    return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/insertQuotation`,
                                  {partner_id:partner_id,isInternalAgent:isInternalAgent,user_id:user_id,cedant_id:cedant_id,prodCode:prodCode,quotationValuesArray:quotationValuesArray,custPolicyFormTotal:custPolicyFormTOtal,source:source,productType:productType,cedant_brokerid:cedant_brokerid,branch_id:branch_id},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);

          }));
  }

//------------------------------ FOR CALCULATE CONTRIBUTION ---------------------------------------//
  public calculateContribution(quotationValuesArray):Observable<any> {

    return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/calculateContribution`,{quotationValuesArray:quotationValuesArray},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);

          }));
  }

//------------------------------ FOR GET TravelDestination ---------------------------------------//
public getTravelDesitination(partnerId,prodCode):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getTravelDestination`,{partner_id:partnerId,product_code:prodCode},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

//------------------------------ FOR GET TravelMastern ---------------------------------------//
public getTravelMasters(partner_id,product_code,lob,source):Observable<any> {
 
  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getMasterValues`,
                              {partner_id:partner_id,product_code:product_code,lob:lob,source:source},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//------------------------------ FOR GET TravelDuration ---------------------------------------//
public getTravelDuration(trip_type_id,SchemeType):Observable<any> {
 
  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getDurationByTripTypeId`,{trip_type_id:trip_type_id,SchemeType:SchemeType},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

//------------------------------ FOR SAVE QUOTATION PLAN DETAIL ---------------------------------------//
public saveQuotePlanDetail(quotationValuesArray):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/insertQuotationPlanDetails`,{quotationValuesArray:quotationValuesArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}


public saveQuotePlanSelectedDetails(quotationValuesArray):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/insertPlanDetails`,{planValuesArray:quotationValuesArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

public updateTravellerData(travelArr):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/updateTravellerData`,{travelArr:travelArr},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

public removeTravellerData(travel_id):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/deleteTravellerData`,{id:travel_id},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

public getQuotationDetail(view_type,reference_number,partner_id,source):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getQuotationDetails`,{view_type:view_type,quotation_number:reference_number,partner_id:partner_id,source:source},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

public viewQuotePDF(id,quoteNumber,source,plan_id):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getQuotationDetailsPDF`,{partner_id:id,reference_number:quoteNumber,source : source,plan_id:plan_id},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

public callTravelerDetails(reference_number,reference_type):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getTravellerDetails`,{reference_number:reference_number,reference_type:reference_type},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

public updateQuotation(QuotationId,quotation_number,partner_id,isInternalAgent,user_id,cedant_id,prodCode,quotationValuesArray,custPolicyFormTOtal,source,productType,cedant_brokerid,branch_id):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/updateQuotation`,{QuotationId:QuotationId,quotation_number:quotation_number,partner_id:partner_id,isInternalAgent:isInternalAgent,user_id:user_id,cedant_id:cedant_id,prodCode:prodCode,quotationValuesArray:quotationValuesArray,custPolicyFormTotal:custPolicyFormTOtal,source:source,productType:productType,cedant_brokerid:cedant_brokerid,branch_id:branch_id},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

public insertPolicy(policyValuesArray,QuotationId,quotation_number):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/insertPolicy`,{policyValuesArray:policyValuesArray,QuotationId:QuotationId,quotation_number:quotation_number},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
 //------------------------------------------- FOR GET PLAN BENEFIT DATA ------------------------------------------------------//
  public getPlanBenefitData(quoteNo,planID,parentBenifit,source):Observable<any> {

    return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getQuotationPlanBenefits`,{quotation_number : quoteNo,plan_id : planID,parent_benefit : parentBenifit,source : source},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }
  //-------------------------------------------- FOR GET PROMO CODE DISCOUNT RATE --------------------------------------------------//
  public getPromoCodeDiscount(source,lob,promo_code):Observable<any> {

    return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getPromoCodeDiscountValue`,{source : source,lob : lob,promo_code : promo_code},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }
 //-------------------------------------------- FOR SAVE POLICY DETAILS --------------------------------------------------//
 public savePolicyDetails(policyValuesArray):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/savePolicyDetails`,{policyValuesArray:policyValuesArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}
 //-------------------------------------------- FOR SEND QUOTATION EMAIL --------------------------------------------------//
 public sendQuotationEmail(quoteNumber,plan_id,benefits,source,email):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/sendEmail`,{quotation_number : quoteNumber,plan_id : plan_id,parent_benefit : benefits,source:source,email:email},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}
//------------------------------------------ FOR GET POLICY SCHEDULE PDF ---------------------------------------------------//
public getPolicySchedulePDF(polNum,source,refNum):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/getPolicyDetailsPDF`,{policy_number : polNum,source:source,reference_number:refNum},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);
console.log(res);
          }));
}
//-----------------SAVE QUOTATION PAYMENT -------------------//
  public saveQuotationPayment(token,eventType,quoteNumber,responseMsg,transactionID,quote_id,policy_number,project_Code):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PaymentWebservice/saveQuatationPayment`,{PaymentLogId:token,eventType:eventType,QuotationNumber:quoteNumber,ResponseMessage:responseMsg,TransactionID:transactionID,quote_id:quote_id,web_policy_number:policy_number,project_Code : project_Code},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }

//-------------------- NEW VERIFY QUOTATION PAYMENT ----------------//
  public verifyQuatationPayment(orderNumber):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PaymentWebservice/verifyQuatationPayment`,{orderNumber:orderNumber},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }
//--------------------OLD  VERIFY QUOTATION PAYMENT ----------------//
  // public verifyQuatationPayment(orderNumber):Observable<any> {

  //   return this.httpClient.post(`${this.apiURL}PaymentWebservice/verifyPayment`,{tran_ref:orderNumber},this._globalService.getHeader())
  //         .pipe(catchError(this.handleError), tap(res => {
  //           let  response=this._globalService.convertResponseInJson(res);
  
  //           }));
  // }

  //---------------------- REFERAL FUNCTIONALITY ----------------------------//
  public referalFunctionality():Observable<any> {

    return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/quotationReferralFunctionality`,{},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }
//--------------------- SEND PAYMENT LINK TO CUSTOMER -------------//
public sendPaymentLink(quotationNumber,project_type,emailId,source,coreQuotationNumber,partner_id):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/sendQuatationPaymentLink`,{QuotationNumber:quotationNumber,project_type:project_type,emailId:emailId,source:source,coreQuotationNumber:coreQuotationNumber,partner_id:partner_id},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  } 

   //-------------------------------------------- FOR insert Plan Details  --------------------------------------------------//
 public insertPlanDetails(policyValuesArray):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/insertPlanDetails`,{planValuesArray:policyValuesArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

   //-------------------------------------------- Upload Document Details --------------------------------------------------//
  //  public UploadDocumentDetails(formData):Observable<any> {

  //   return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/UploadDocumentDetails`,formData)
  //         .pipe(catchError(this.handleError), tap(res => {
  //           let  response=this._globalService.convertResponseInJson(res);
  
  //           }));
  // }
  public UploadDocumentDetails(formData):Observable<any> {

    return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/UploadDocumentDetails`,formData)
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));
  }

  // ---------------------------------------Check partner Credit------------------------------------------------------
  public checkPartnerCreditLimit(lob_code,cedant_brokerid,branch_id):Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/checkPartnerCreditLimit`,{lob_code:lob_code,cedant_brokerid:cedant_brokerid,branch_id:branch_id},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));
  }
  // ---------------------------------------Check User Pol  Acesss------------------------------------------------------
  public checkUserPolAccess(lob_code):Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/checkUserPolAccess`,{lob_code:lob_code},this._globalService.getHeader())
    .pipe(catchError(this.handleError), tap(res => {
      let  response=this._globalService.convertResponseInJson(res);

    }));
  }


  // ------------------------------------- get Ploicy by user------------------------------------------------------------
  public getPliciesByUserTravel(partnerId,userName,lob,fromDate,toDate,opText):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PolicyController/getPoliciesByUser`,{partnerid:partnerId,username:userName,lob:lob,from_date:fromDate,to_date:toDate,OptionalText:opText},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));  
    }
  // ------------------------------------- Bulk Payemnt ------------------------------------------------------------
  public bulkPayment(batchNumber,policy_number,total_amount,broker_name,broker_email):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PaymentWebservice/bulkPayment`,{policy_number:policy_number,batchNumber:batchNumber,total_amount:total_amount,broker_name:broker_name,broker_email:broker_email},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));  
    }
  // ------------------------------------- Generate Reports  ------------------------------------------------------------
  public travelReport(partner_id,from_date,to_date,reportsFormate,):Observable<any> {

    return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/travelReport`,{partner_id:partner_id,from_date:from_date,to_date:to_date,reportsFormate:reportsFormate},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));  
    }
     //-----------------SAVE QUOTATION PAYMENT -------------------//
  public getPolicyForBulkPayment(userId,partnerId,quote_id,token,eventType,batchNumber,responseMsg,transactionID,lob,quote_type):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PaymentWebservice/getPolicyForBulkPayment`,{userId:userId,partnerId:partnerId,PaymentLogId:token,eventType:eventType,batchNumber:batchNumber,ResponseMessage:responseMsg,TransactionID:transactionID,lob:lob,quote_type:quote_type,quote_id:quote_id},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }
     //-----------------Send  Policy Mail -------------------//
  public sendPolicyScheduleMail(policyNumber,insured_email,quotes_number):Observable<any> {

    return this.httpClient.post(`${this.apiURL}TravelInsuranceWebservice/sendPolicyScheduleMail`,{policyNumber:policyNumber,insured_email:insured_email,quotes_number:quotes_number},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }
   // ------------------------------------- get policy by user------------------------------------------------------------
   public getpolicyforpayement(partnerId,userName,lob,fromDate,toDate,opText):Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/getPolicyForPayment`,{partnerid:partnerId,username:userName,lob:lob,from_date:fromDate,to_date:toDate,OptionalText:opText},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));  
    }

    //------------------------------------------ FOR GET Travel Certificate ---------------------------------------------------//
public getTravelCertificate(polNum,source,refNum):Observable<any> {

  return this.httpClient.post(`${this.apiURL}TravelPDFWebservices/getPolicyCertificate`,{policy_number : polNum,source:source,reference_number:refNum},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

//------------------- FOR PAYMENT ------------------------//
//Added method by Anju for new Payment API Integration
public paymentAuth(
  QuotationNumber,
  site_url,
  return_url,
  project_type,
  partner_id,
  policy_number,
  quote_type,
  source
): Observable<any> {
  return this.httpClient
    .post(
      `${this.paymentAPIURL}pay-page`,
      {
        QuotationNumber: QuotationNumber,
        site_url: site_url,
        return_url: return_url,
        project_type: project_type,
        partner_id: partner_id,
        policy_number: policy_number,
        quote_type: quote_type,
        source: source,
      },
      this._globalService.getHeader()
    )
    .pipe(
      catchError(this.handlePaymentError),
      tap((res) => {
        let response = this._globalService.convertResponseInJson(res);
        console.log(response);
      })
    );
}


//Added method by Anju for new Payment API Integration
public verifyPayment(orderNumber): Observable<any> {
  return this.httpClient
    .get(this.paymentAPIURL + 'pay-status/' + orderNumber)
    .pipe(
      catchError(this.handleError),
      tap((res) => {
        let response = this._globalService.convertResponseInJson(res);
      })
    );
}


public bulkPaymentNew(batchNumber,policy_number,total_amount,broker_name,broker_email,userId):Observable<any> {

  return this.httpClient.post( `${this.paymentAPIURL}pay-page`,{policy_number:policy_number,batchNumber:batchNumber,total_amount:total_amount,broker_name:broker_name,broker_email:broker_email, userId:2, project_type:"TR"},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }

public sendMailDueToCancelPayment(respMessage, invoiceId, product):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PaymentWebservice/sendMailOnCancelPayment`,{respMessage:respMessage,invoiceId:invoiceId,product:product},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }


}
