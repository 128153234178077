import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { TraveInsuranceService } from '../_service/traveInsurance.service';
import { GlobalService } from "../_service/global.service";
import { HomequoteService } from '../_service/homequote.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class CommonThankYouComponent implements OnInit {

  public policyNum:any; paymentLoader:boolean = false; quoteNum:any; public partnerId :any;  localStorDta:any;  orderNumber:any; orderToken:any; payPolicyNum:any; 
  public payment_transaction_ID_status:any; payment_Transaction_ID:any; token:any; response_msg:any =''; quoteID:any; debit_Note_Url:any; credit_Note_Url:any; payWebPolicyNumber:any;
  batchNo: any;
  totalnoofrecords: any;
  totalAmounts: any;
  userId: any;
  EmailAddress: any;
  PageLoader: boolean =false;
  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public traveInsuranceService:TraveInsuranceService, public globalService:GlobalService, public homeQuoteService:HomequoteService) { }

  ngOnInit() {
    this.PageLoader = this.globalService.spinnerShow();
    this.paymentLoader = this.globalService.spinnerHide();
    this.localStorDta =  this.globalService.getLocalStorageData();
    localStorage.removeItem("paymentMode");
    this.orderNumber =  localStorage.getItem("Payment_Order_ID");
    this.orderToken  =  localStorage.getItem("Payment_Order_Tokan");
    this.quoteNum = localStorage.getItem('Payment_Quotation_Number');
    this.quoteID =  localStorage.getItem('Payment_Quotation_ID');
    this.debit_Note_Url =  localStorage.getItem('travel_debit_note');
    this.credit_Note_Url =  localStorage.getItem('travel_credit_note');
    this.totalnoofrecords =  localStorage.getItem('TotalNumberOfRecords');
    this.totalAmounts =  localStorage.getItem('TotalAmount');
    
    this.partnerId = this.localStorDta.Partner_ID;
    this.userId = this.localStorDta.User_ID;
  console.log(this.userId)
    const routeParam = this._activatedroute.snapshot.params;
      if(routeParam){

          this.batchNo = routeParam.batchNo;
          this.quoteNum = routeParam.quoteNum;
  
        }
           
        if(this.orderToken!=null){
            this.verifyQuatationPayment();
        }
            
  }

  gotoGetQuote(){
      localStorage.removeItem("Payment_Order_ID");
      localStorage.removeItem("Payment_Order_Tokan");
      localStorage.removeItem('Payment_Quotation_Number');
      localStorage.removeItem('Payment_Quotation_ID');
      localStorage.removeItem('travel_debit_note');
      localStorage.removeItem('travel_credit_note');
      this._route.navigateByUrl('/standardtravel/traveloption');
  }

  goToHome(){
      localStorage.removeItem("Payment_Order_ID");
      localStorage.removeItem("Payment_Order_Tokan");
      localStorage.removeItem('Payment_Quotation_Number');
      localStorage.removeItem('Payment_Quotation_ID');
      localStorage.removeItem('travel_debit_note');
      localStorage.removeItem('travel_credit_note');
      this._route.navigateByUrl('/dashboard');
  }

  viewPolicy(){

  }
 
  saveQuotationPayment(){
    // alert(1)
    this.traveInsuranceService.getPolicyForBulkPayment( this.userId,this.partnerId,'',this.orderToken,this.payment_transaction_ID_status,this.batchNo,this.response_msg,this.payment_Transaction_ID,'BP','').subscribe(res =>{
      
          if(res.response_code == 1){
            // this.payPolicyNum = res.CorePolicyNumber;
            // this.debit_Note_Url = res.debit_note;
            // this.credit_Note_Url = res.credit_note;
            // this.payWebPolicyNumber = res.web_policy_number;
            this.totalnoofrecords = res.response_batch;
           // this.totalAmounts = res.response_amt;
            localStorage.removeItem("Payment_Order_ID");
            localStorage.removeItem("Payment_Order_Tokan");
            localStorage.removeItem('Payment_Quotation_Number');
            localStorage.removeItem('Payment_Quotation_ID');
            localStorage.removeItem('travel_debit_note');
            localStorage.removeItem('travel_credit_note');
            localStorage.removeItem('TotalNumberOfRecords');
            localStorage.removeItem('TotalAmount');


          }
    });

}
//   saveQuotationPayment(){
//     // alert(1)
//     this.homeQuoteService.saveQuotationPayment( '',this.orderToken,this.payment_transaction_ID_status,this.batchNo,this.response_msg,this.payment_Transaction_ID,'TR','').subscribe(res =>{
      
//           if(res.res_code == 1){
//             this.payPolicyNum = res.CorePolicyNumber;
//             this.debit_Note_Url = res.debit_note;
//             this.credit_Note_Url = res.credit_note;
//             this.payWebPolicyNumber = res.web_policy_number;

//             localStorage.removeItem("Payment_Order_ID");
//             localStorage.removeItem("Payment_Order_Tokan");
//             localStorage.removeItem('Payment_Quotation_Number');
//             localStorage.removeItem('Payment_Quotation_ID');
//             localStorage.removeItem('travel_debit_note');
//             localStorage.removeItem('travel_credit_note');
//             localStorage.removeItem('TotalNumberOfRecords');
//             localStorage.removeItem('TotalAmount');


//           }
//     });

// }

verifyQuatationPayment(){
  console.log(1)
 
  console.log(12)
  //Modified by ANju for integrating new payment gateway
  this.traveInsuranceService.verifyPayment(this.orderNumber).subscribe(res =>{
    let response = res;
    this.response_msg = response.result.payment_result.response_code;
    this.payment_Transaction_ID = response.result.tran_ref;
    this.quoteNum = response.result.cart_id;
        this.totalAmounts =res.result.cart_amount;

             if(res.res_code == 2){

            //  if(res.result.details == "This invoice is either cancelled by the customer or expired"){	
                this.payment_transaction_ID_status = "CANCELLED";	
                 //Modified by Anju for retry payment scenario
                this._route.navigateByUrl("/travel/cancelpayment/" + this.response_msg + "/" +this.quoteNum + "/" +"TR");	
          this.PageLoader = this.globalService.spinnerHide();	
            }	
            else{	
              this.payment_transaction_ID_status = "ISSUED";	
              this.saveQuotationPayment();	
              this.PageLoader = this.globalService.spinnerHide();	
          }

              
          //     if(res.result.payment_result.response_status == "A"){
          //       this.payment_transaction_ID_status = "ISSUED";
          //       this.saveQuotationPayment();
          //       this.PageLoader = this.globalService.spinnerHide();
          //   }
          //   else{
          //     this.payment_transaction_ID_status = "CANCELLED";
          
          //     this._route.navigateByUrl('travel/cancelpayment/' + this.response_msg);
          //     this.PageLoader = this.globalService.spinnerHide();
          // }


              
             
      });

}

  generatePDF(type){
          if(type == 1){
             this.policyNum = this.orderNumber == null ? this.policyNum: this.payWebPolicyNumber;
              this.traveInsuranceService.getPolicySchedulePDF(this.policyNum,'B2B',this.quoteNum).subscribe(res =>{
                    if(res.response_code == 1){
                      window.open(res.pdfPath, "_blank");
                    }
              });
          }

          if(type == 2){
                
              window.open( this.debit_Note_Url, "_blank");
          }
        
          if(type == 3){
          
              window.open( this.credit_Note_Url, "_blank");
          }
              
  }

 
}
