import { Component, OnInit , ViewChild} from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { IssuepolicyService } from '../../_service/issuepolicy.service';
import { GlobalService } from "../../_service/global.service";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

import Swal from 'sweetalert2';
import { catchError, map } from 'rxjs/operators';

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-medical-maf-form',
  templateUrl: './medical-maf-form.component.html',
  styleUrls: ['./medical-maf-form.component.scss']
})
export class MedicalMafFormComponent implements OnInit {

  @ViewChild(SignaturePad, {static:false}) public signaturePad: SignaturePad;
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }
 
  drawComplete(id) {
    // will be notified of szimek/signature_pad's onEnd event
    this.medicalHistoryForm[id] = this.signaturePad.toDataURL();
    //console.log(this.medicalHistoryForm[id]);
  }
 
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    
  }

  bloodGroup = [
 
    {value: '0', label: 'A+'},
    {value: '1', label: 'A-'},
    {value: '2', label: 'B+'},
    {value: '3', label: 'B-'},
    {value: '4', label: 'O+'},
    {value: '5', label: 'O-'},
    {value: '6', label: 'AB+'},
    {value: '7', label: 'AB-'},
  ];

  uaeResidentArr=[
    {value: '0', label: 'Yes'},
    {value: '1', label: 'No'}
  ]

  //Declare Array
  public  questionnaireMaster:any=[]; sectionMaster:any=[]; medicalHistoryForm:any= []; ; regionArr = []; maritalstatusArr = [];   medicalHistory:any={ Conditions: []}; quotationDetail: any=[];
  genderArr = []; nationalityArr = []; mobileArr = []; salaryArr = []; RelationshipArr:any = []; allMedicalHistoryForm:any=[];public selectedMember:any=[];
  relatedQuestion: any=[];
  selectAllSectionMedicalYesNo:  boolean = false;
  IsMAFReferral: number;
  conditionsArray: any=[];
  selectedMedicationCount: number[] = []; 
  medicationFields: { value: string }[][] = []; 
  mainQuestion: any;
  isDependentAlone: boolean = false;
  isQuestionSelected: boolean;
  questionMaster:any=[];flag=0;
  conditionMapping:any=[];
  Conditions: any=[];

  public selectAllMedicalYesNo: any=""; genderVal:any; token:any; quoteNum:any; memberId:any; memberDetail:any = [];  maxDate = new Date(); relationVal:any; email:any;
  partnerId:any;
 
  constructor(public issuePolicyService :IssuepolicyService,public _activatedroute: ActivatedRoute,public globalService:GlobalService,public _route: Router) { }

  ngOnInit() {

    this.getQuestionnaireMaster();
   // this.getQuotationFormData();

    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){
      this.token = routeParam.token;
      this.quoteNum = routeParam.quoteNum;
    }
  
    this.conditionsArray =[];
    this.isQuestionSelected = false;

      this.getAccessToMAFForm();
  }

  getQuestionnaireMaster(){
    // alert(1)
        this.issuePolicyService.getQuestionnaire().subscribe(res =>{
          let responseData = res;
    
          this.questionnaireMaster=responseData.questionnaireMaster;
          this.sectionMaster=responseData.sectionMaster;
          this.questionMaster = this.sectionMaster.filter(item => item.Id >= 10 && item.Id <= 18);
          this.questionnaireMaster.RelatedItems = [];
          this.mainQuestion =  this.sectionMaster.filter(item => item.SectionName =='MainQuestion')[0];
          console.log(this.mainQuestion);
          this.conditionMapping =responseData.conditionMapping;
    
          Object.keys(this.questionnaireMaster).forEach(sectionKey => {
            const sectionQuestions = this.questionnaireMaster[sectionKey];
        
            sectionQuestions.forEach(item => {
                const relatedItems = sectionQuestions.filter(
                    relatedItem => relatedItem.RelatedQuestionId === item.Id
                );
        
                item.RelatedItems = relatedItems;
            });
        });
        
        function findSubRelatedItems(itemId, questions) {
            const subRelatedItems = questions.filter(
                relatedItem => relatedItem.RelatedQuestionId === itemId
            );
        
            // For each sub-related item, find their own sub-related items
            subRelatedItems.forEach(subItem => {
                subItem.SubRelatedItems = findSubRelatedItems(subItem.Id, questions);
            });
        
            return subRelatedItems;
        }
        
    
    console.log(this.questionnaireMaster);
          this.questionnaireMaster['section_1'].forEach((qustionObj,index) => {
    
            this.medicalHistoryForm[qustionObj.Id] = "no" ;
      
        });
           
        });
        console.log(this.medicalHistoryForm.section)
      }

  //----------- ALL DROPDOWN VALUES --------------//
  getQuotationFormData(){
    
    this.issuePolicyService.getQuotationFormData().subscribe(response =>{
       let formDataRes = response;
     
      this.regionArr = formDataRes.Region;
      this.maritalstatusArr = formDataRes.MaritalStatus;
      //this.coveragetypeArr = formDataRes.Coverage;
      this.nationalityArr = formDataRes.Nationality
      this.mobileArr  = formDataRes.MobileCode;
      this.RelationshipArr = formDataRes.Relationship;
      this.salaryArr = formDataRes.Salary;
      this.genderArr = formDataRes.Gender;
    });
    
  }
  // --------------Select all yes or no -------------------//

selectAllEvent(section){

  if(this.selectAllMedicalYesNo=='yes'){

          this.questionnaireMaster['section_1'].forEach((qustionObj,index) => {
        
            this.medicalHistoryForm[qustionObj.Id] = "yes" ;
          

        });

  }

  if(this.selectAllMedicalYesNo=='no'){

        this.questionnaireMaster['section_1'].forEach((qustionObj,index) => {

          this.medicalHistoryForm[qustionObj.Id] = "no" ;
        

      });

  }
  console.log(this.selectAllMedicalYesNo)
}

SelectAllSection(sectionId: string, selectedValue: string): void {

if(sectionId == 'section_19' && selectedValue === 'no'){

this.conditionsArray=[];
for (const sectionKey in this.questionnaireMaster) {
  if (this.questionnaireMaster.hasOwnProperty(sectionKey)) {
    const sectionQuestions = this.questionnaireMaster[sectionKey];
    this.medicalHistoryForm[sectionKey] ='no';
    sectionQuestions.forEach((questionItem: any) => {
      if (questionItem.FieldFlag === 'Radio') {
        this.medicalHistoryForm[questionItem.Id] = 'no';
      } else if (questionItem.FieldFlag === 'Text' || questionItem.FieldFlag === 'Year') {
        this.medicalHistoryForm[questionItem.Id] = 'NA';
      }

      if (questionItem.RelatedItems) {
        questionItem.RelatedItems.forEach((item: any) => {
          if (item.FieldFlag === 'CheckBox') {
            this.medicalHistoryForm[item.Id] = false;
          } else if (item.FieldFlag === 'Text' || item.FieldFlag === 'Year') {
            this.medicalHistoryForm[item.Id] = 'NA';
          } else if (item.FieldFlag === 'Radio') {
            this.medicalHistoryForm[item.Id] = 'no';
          }
        });
      }
    });
  }
}

}
else{
if (selectedValue === 'no') {
const sectionQuestions = this.questionnaireMaster[sectionId];
sectionQuestions.forEach((questionItem: any) => {
  if (questionItem.FieldFlag === 'Radio') {
    this.medicalHistoryForm[questionItem.Id] = 'no';
  }
  else if (questionItem.FieldFlag === 'Text') {
    this.medicalHistoryForm[questionItem.Id] = 'NA';
  }
  else if (questionItem.FieldFlag === 'Year') {
    this.medicalHistoryForm[questionItem.Id] = 'NA';
  }
  if (questionItem.RelatedItems) {
    questionItem.RelatedItems.forEach((item: any) => {
      if (item.FieldFlag === 'CheckBox') {
        this.medicalHistoryForm[item.Id] = false;
      }
      else if (item.FieldFlag === 'Text') {
        this.medicalHistoryForm[item.Id] = 'NA';
      }
      else if (item.FieldFlag === 'Radio') {
        this.medicalHistoryForm[item.Id] = 'no';
      }
      else if (item.FieldFlag === 'Year') {
        this.medicalHistoryForm[item.Id] = 'NA';
      }
    });
  }
});
}
}
}

callChange(id){

  var sectionId ='';
  this.flag = 0;

  if (String(id).includes('section_')) {
    sectionId = id;
  }
  else{
     sectionId = this.getSectionIdByQuestionId(id);
  }

  
  if (sectionId && this.isAnyQuestionInSectionYes(sectionId)) {
      this.medicalHistoryForm[sectionId] = 'yes';
      this.medicalHistoryForm['section_19'] = 'yes';

  } 
   if(this.medicalHistoryForm[sectionId] == 'yes' && this.medicalHistoryForm['section_19']=='No') {
    this.medicalHistoryForm['section_19'] = 'yes';
   }


  this.setMatchingQuestionsAsYes(id);
  
  if (String(id).includes('section_')) {
    const sectionId = this.medicalHistoryForm[id]; 
//     if (sectionId) {
//         this.sortmedicalform.push(sectionId);
// }
}

this.isQuestionSelected = false;
if (sectionId === 'section_18' &&  this.medicalHistoryForm['section_18'] =='yes') {
const filteredSubquestions = this.questionnaireMaster[sectionId].filter(item =>
    item.RelatedQuestionId != null && item.FieldFlag === 'CheckBox' && item.Id!=id
);

const trimesterQuestion = this.questionnaireMaster[sectionId].filter(item =>
  item.RelatedQuestionId != null && item.FieldFlag === 'CheckBox' && item.Id === id
);

filteredSubquestions.forEach(item => {
    const questionId = item.Id;
   if (this.medicalHistoryForm[questionId] === true && ! this.isQuestionSelected && trimesterQuestion!='' && trimesterQuestion!=null &&  trimesterQuestion.length > 0) {
        this.isQuestionSelected = true;
        if (this.isQuestionSelected) {
          this.globalService.openSnackBar("Please select only the relevant trimester",'',);
          this.medicalHistoryForm[id] = false;
        }
    }
});
}

if(!this.isQuestionSelected)
{
   
    if(this.flag == 1 || this.flag == 0){
              if((this.medicalHistoryForm[id] == 'yes' || this.medicalHistoryForm[id] == '1') && !this.isMAFCondition(sectionId,id)){
                this.flag = 3;
            
              }else if(this.medicalHistoryForm[id] == 'no'){
                this.flag = 1;
                
              }               
  }
  if(this.flag == 3 || this.flag == 2){
    this.selectAllMedicalYesNo = 'yes';
  }else if(this.flag == 1){
    this.selectAllMedicalYesNo = 'no';
  }

  if((this.medicalHistoryForm[id] == 'yes' || this.medicalHistoryForm[id] == '1') && this.isMAFCondition(sectionId,id)){
    const questionsInSection = this.questionnaireMaster[sectionId] || [];
    const question = questionsInSection.find(q => q.Id === id);
    if (question) {
      const condition = this.conditionMapping.find(cm => cm.QuestionIndex === question.DisplayIndex);
      const conditionExists = this.conditionsArray.some(c => c.ConditionName === condition.Conditions);
      if (!conditionExists) {
        this.conditionsArray.push({
          ConditionName: condition.Conditions, 
          DisplayIndex: condition.QuestionIndex, 
        });
      }      
    }
  }
  else{
    const questionsInSection = this.questionnaireMaster[sectionId] || [];
    const question = questionsInSection.find(q => q.Id === id);
    if (question) {
      const condition = this.conditionMapping.find(cm => cm.QuestionIndex === question.DisplayIndex);
      const conditionExists = this.conditionsArray.some(c => c.ConditionName === condition.Conditions);
      if (conditionExists) {
        this.conditionsArray = this.conditionsArray.filter(c => c.ConditionName !== condition.Conditions && c.DisplayIndex !== condition.QuestionIndex);
      }      
    }

  }
}
}

updateBloodGroup(){ 
    
 // this.formDetail.controls[this.activeIndex].get("bloodGroup").setValue(this.medicalHistory.bloodGroup);

}
//------------------------------------  MAF Page Validation -----------------------------------//
saveMedicalHistory(medicalHistory){

  let mafValidationFlag = false ;

    //Selection of all sections are mandatory
    if (!this.medicalHistoryForm['section_10'] || !this.medicalHistoryForm['section_11'] ||
      !this.medicalHistoryForm['section_12'] || !this.medicalHistoryForm['section_13']
      || !this.medicalHistoryForm['section_14']  //|| !this.medicalHistoryForm['section_15']
      || !this.medicalHistoryForm['section_16']  || !this.medicalHistoryForm['section_17']
      || !this.medicalHistoryForm['section_18']
    ) {
        mafValidationFlag = true;
        this.globalService.openSnackBar("Please select all sections ",'',);
        return false;
      
      }
     
      mafValidationFlag = this.validateSection(this.questionnaireMaster['section_10']);
      mafValidationFlag = this.validateSection(this.questionnaireMaster['section_11']);
      mafValidationFlag = this.validateSection(this.questionnaireMaster['section_12']);
      mafValidationFlag = this.validateSection(this.questionnaireMaster['section_13']);
      mafValidationFlag = this.validateSection(this.questionnaireMaster['section_14']);
      mafValidationFlag = this.validateSection(this.questionnaireMaster['section_16']);
      mafValidationFlag = this.validateSection(this.questionnaireMaster['section_17']);
      mafValidationFlag = this.validateSection(this.questionnaireMaster['section_18']);

      mafValidationFlag = this.validateConditions(this.conditionsArray);

    if(this.genderVal == 'Female'){
 
      let maternitySectStatus:boolean = true;
      let isPregnant:boolean = false;

    this.questionnaireMaster['section_18'].forEach((qustionObj,index) => {
      if(this.medicalHistoryForm['section_18'] == 'yes' && this.medicalHistory.age>=18 &&  this.medicalHistory.age <=50 && this.medicalHistory.maritalStatus =='M' &&  this.medicalHistory.gender.value  =='F' ){

          if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
               
            if(qustionObj.RelatedQuestionId == 0){
                    
                        maternitySectStatus = false;
                        mafValidationFlag = true;
                        this.globalService.openSnackBar("Please Select all relevant options for Maternity section",'',);
                     
              }
            if(isPregnant == true){
                        
                        maternitySectStatus = false;
                        mafValidationFlag = true;
                        this.globalService.openSnackBar("Please Select all relevant options for Maternity section",'',);
              } 
          }
            else if(qustionObj.QuestionText == 'Are you currently pregnant?'){
                 
                  if(this.medicalHistoryForm[qustionObj.Id] == 'yes'){
                      isPregnant = true;
                  }
        }
      }
      else if (this.medicalHistoryForm['section_18'] == 'no' && this.medicalHistory.age>=18 &&  this.medicalHistory.age <=50 && this.medicalHistory.maritalStatus =='M' &&  this.medicalHistory.gender.value  =='F'){
        if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
               
          if(qustionObj.RelatedQuestionId == 0){
                  
                      maternitySectStatus = false;
                      mafValidationFlag = true;
                      this.globalService.openSnackBar("Please Select all relevant options for Maternity section",'',);
                   
            }
          }

      }

      });
     
  }


  if(this.selectAllMedicalYesNo == 'yes' || this.selectAllMedicalYesNo == '' ){
      
        this.questionnaireMaster['section_9'].forEach((qustionObj) => {

          if((this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null ) && qustionObj.FieldFlag !='Date'){
                  mafValidationFlag = true;
                  this.globalService.openSnackBar("Please Select all Relevant options for Medical condition",'',);
                  return false;
                }
        });

        this.questionnaireMaster['section_4'].forEach((qustionObj) => {

          if((this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null) && qustionObj.FieldFlag !='Date'){
              mafValidationFlag = true;
              this.globalService.openSnackBar("Please Select all Relevant options for Diagnosis Status",'',);
              return false;
            }
        });
   
        this.questionnaireMaster['section_5'].forEach((qustionObj) => {

          if((this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null) && qustionObj.FieldFlag !='Date'){
            mafValidationFlag = true;
            this.globalService.openSnackBar("Please Select all Relevant options for Diagnosis Status",'',);
            return false;
          }
        });

        this.questionnaireMaster['section_6'].forEach((qustionObj) => {
            if((this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null)){
              mafValidationFlag = true;
              this.globalService.openSnackBar("Please fill all the mandatory details for How often do the symptoms occur ?",'',);
              return false;
            }
        });

        this.questionnaireMaster['section_8'].forEach((qustionObj) => {
          if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
            mafValidationFlag = true;
            this.globalService.openSnackBar("Please fill all the mandatory details for Systolic/ distolic readings",'',);
            return false;
          }
      });
  }

    if(this.medicalHistoryForm['27'] == '' || this.medicalHistoryForm['27'] == null){
          this.globalService.openSnackBar("Signature is required",'',);
          return false;
      }

 if(!mafValidationFlag){


   ////Nextcare API change - self fill MAF to be saved in order to encode 


   this.issuePolicyService.saveMemberMedicalHistory(this.selectedMember,this.medicalHistoryForm,this.quoteNum,this.medicalHistory.bloodGroup,this.email,this.partnerId,this.Conditions).subscribe( res => {
    let updateMemResponse = res;
   });

            Swal.fire("Your MAF has been submmited successfully","Your quotation under review & send you shortly",'success');

             setTimeout(() => {
             
                      this.redirectPage();

              },3000);  
          
    }
  
 }
   //----------- CONVERT DATE INTO DD/MM/YYYY FORMAT ------------//
  convertEIDExpDate(inputData){
    
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(inputData);
      //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
      return ([ pad(d.getDate()),pad(d.getMonth()+1),d.getFullYear()].join('/'));
  }

  getAccessToMAFForm(){
        this.issuePolicyService.validateMAFAccess(this.quoteNum,'0',this.token).subscribe(res =>{

          this.memberId = res.validateMAFAccess.MemberId;

          this.quotationDetail = res.QuotationDetail[0];
          this.memberDetail = res.Members;

          this.regionArr = res.Region;
          this.maritalstatusArr = res.MaritalStatus;
          //this.coveragetypeArr = formDataRes.Coverage;
          this.nationalityArr = res.Nationality
          this.mobileArr  = res.MobileCode;
          this.RelationshipArr = res.Relationship;
          this.salaryArr = res.Salary;
          this.genderArr = res.Gender;

          this.email = res.validateMAFAccess.CustEmail;
          this.partnerId = res.validateMAFAccess.PartnerId;
          this.selectedMember.MemberID = this.memberId;
                this.getMemberDetails();

        });
  }

   //--------------------FOR ADDITIONAL MEMBER DETAILS -----------------//
   getMemberDetails(){
    

      //  this.issuePolicyService.getMembersDetailByQuoteNo(this.quoteNum).subscribe(res =>{
              
                //   let memberlistRes = res; 
                // this.quotationDetail = memberlistRes.QuotationDetail[0]; 
                // this.memberDetail = memberlistRes.Members;

                this.memberDetail.forEach((item,i)=>{
                        if(item.MemberID == this.memberId){
                           this.medicalHistory.PlanName = this.quotationDetail.PlanName ;
                           this.medicalHistory.insuredName = this.quotationDetail.InsuredName;
                           this.medicalHistory.FirstName = item.MemberFirstName ;
                           
                           this.medicalHistory.LastName = item.MemberLastName ;
                           this.medicalHistory.DOB = this.convertEIDExpDate(item.MemberDOB);
                           this.medicalHistory.height = item.MemberHeight;
                           this.medicalHistory.weight = item.MemberWeight;
                           this.medicalHistory.signatureDate = this.convertEIDExpDate(this.maxDate);
                           this.medicalHistory.signatureDate1 = this.convertEIDExpDate(this.maxDate);
                           this.medicalHistory.inceptionDate = this.convertEIDExpDate(this.quotationDetail.QuotedDate);
                           this.medicalHistory.policyID = this.quotationDetail.QuotationNumber;
                           this.medicalHistory.bloodGroup =  item.BloodGroup;
                           this.medicalHistory.nationlity = item.Nationality;
                           this.medicalHistory.maritalStatus=item.MaritalStatus;
                           this.medicalHistory.age = item.MemberAge;
                           
                           // UAE residence
                           this.uaeResidentArr.forEach((obj, k) =>{
                             if(obj.value === '0'){
                               this.medicalHistory.uaeRes = obj;
                             }
                           });
                          //relationship
                             this.RelationshipArr.forEach((obj, k) =>{
                               if(obj.value === item.Relationship){
                                 this.medicalHistory.relation = obj;
                               }
                             });
                             this.relationVal = this.medicalHistory.relation.label;
                           //Gender
                           this.genderArr.forEach((obj, k)=>{
                             if(obj.value === item.Gender){
                               this.medicalHistory.gender = obj;
                             }
                           });
                           this.genderVal = this.medicalHistory.gender.label
                       
                        }

                        this.questionnaireMaster["section_9"].forEach((obj,index) => {
       
                          if(obj.QuestionText == 'Name of applicant')
                          {
                            this.medicalHistoryForm[obj.Id] = item.MemberFirstName;
                          }
                          if(obj.QuestionText == 'Age')
                          {
                          
                            this.medicalHistoryForm[obj.Id] = item.MemberAge;
                          }
                          if(obj.QuestionText == 'Date of application')
                          {
                           
                            this.medicalHistoryForm[obj.Id] = this.convertEIDExpDate(this.maxDate);
                          }
                          if(obj.QuestionText == 'Sex')
                          {
                            this.genderArr.forEach((p,k)=>{
                             
                                if(p.value == item.Gender){
                                  this.medicalHistoryForm[obj.Id] = p;
                                }
                            })
                          }
                        });
                });

                  //-------------- FOR MEDICAL CONDITION----------------------//

          this.questionnaireMaster["section_8"].forEach((item,index) => {
        
              if(item.QuestionText == 'Systolic:')
              {
                this.medicalHistoryForm[item.Id] = '0';
              }
        
              if(item.QuestionText == 'Diastolic:')
              {
                this.medicalHistoryForm[item.Id] = '0';
              }
          });

     




      //  });

   }   

   redirectPage(){

          this.issuePolicyService.redirectPages().subscribe(res =>{

                    let redirectLink = res.redirection_link;
      
                    window.open(redirectLink,"_self");
                              
          });
   }

   validateSection = (section) => {
    return section.some((questionObj) => {
      if (!this.medicalHistoryForm[questionObj.Id] && questionObj.FieldFlag !== 'Date' && questionObj.FieldFlag !== 'CheckBox') {
        this.globalService.openSnackBar("Please select all relevant options or mark it as NA", '');
        return true;
      }
      return false;
    });
  };
  
  isAnyQuestionInSectionYes(sectionId: string): boolean {
    const questionsInSection = this.questionnaireMaster[sectionId] || [];
    return questionsInSection.some(question => this.medicalHistoryForm[question.Id] === 'yes');
  }
  
  
  
  getSectionIdByQuestionId(questionId: string): string {
  for (let sectionKey in this.questionnaireMaster) {
      if (this.questionnaireMaster[sectionKey].some(question => question.Id === questionId)) {
          return sectionKey;
      }
  }
  return null;
  }
  
  setMatchingQuestionsAsYes(checkboxId: string): void {
  for (const sectionKey in this.questionnaireMaster) {
      const questionsInSection = this.questionnaireMaster[sectionKey] || [];
      questionsInSection.forEach(question => {
          const relatedCheckbox = question.RelatedItems.find(
              (relatedItem) => relatedItem.Id === checkboxId
          );
          if (relatedCheckbox && relatedCheckbox.RelatedQuestionId === question.Id) {
              this.medicalHistoryForm[question.Id] = 'yes';
              this.medicalHistoryForm[sectionKey] = 'yes';
              this.medicalHistoryForm['section_19'] = 'yes';
          }
      });
  }
  }
  
  onDateChange(questionId: string, selectedDate: Date): void {
  if (selectedDate) {
      const convertedDate = this.convertEIDExpDate(selectedDate);
      this.medicalHistoryForm[questionId] = convertedDate;
  }
  }
  
  isMAFCondition(sectionId: string,questionId: string): boolean {
  
  if(sectionId=="section_13" || sectionId=="section_14" || sectionId=="section_16" || sectionId=="section_17")
  {
    return false;
  }
  else if(questionId=="section_10" || questionId=="section_11" ||questionId=="section_12" ||questionId=="section_18")
  {
    return false;
  }
  const questionsInSection = this.questionnaireMaster[sectionId] || [];
  const question = questionsInSection.find(q => q.Id === questionId);
  return question ? question.isMaFCondition === true : false;
  }
  
  
  initializeMedicalHistory(): void {
  this.conditionsArray.forEach((condition, idx) => {
    if (!this.Conditions[idx]) {
      this.Conditions[idx] = {
        conditionName: condition.ConditionName,
        numberOfMedications: 0,
        medications: []
      };
    }
  });
  }
  
  updateMedicationFields(index: number): void {
  const count = this.selectedMedicationCount[index] || 0;
  this.medicationFields[index] = Array(count).fill(null).map(() => ({ value: '' }));
  
  if (!this.Conditions[index]) {
    this.Conditions[index] = {
      conditionName: this.conditionsArray[index].ConditionName,
      numberOfMedications: count,
      medications: []
    };
  }
  
  this.Conditions[index].numberOfMedications = count;
  this.Conditions[index].medications = this.medicationFields[index].map(field => field.value);
  }
  
  updateMedicalHistoryForm(conditionIndex: number, medicationIndex: number): void {
  const medicationValue = this.medicationFields[conditionIndex][medicationIndex].value;
  if (!this.Conditions[conditionIndex]) {
    this.Conditions[conditionIndex] = {
      conditionName: this.conditionsArray[conditionIndex].ConditionName,
      numberOfMedications: this.selectedMedicationCount[conditionIndex],
      medications: []
    };
  }
  
  // Ensure medications array is properly initialized
  if (!this.Conditions[conditionIndex].medications[medicationIndex]) {
    this.Conditions[conditionIndex].medications[medicationIndex] = '';
  }
  
  this.Conditions[conditionIndex].medications[medicationIndex] = medicationValue;
  }
  
  validateConditions(conditions: any[]): boolean {
  let allFieldsValid = true;
  
  this.conditionsArray.forEach((condition, idx) => {
    if (this.selectedMedicationCount[idx] <0) {
      this.globalService.openSnackBar(`Number of medications not selected for condition at index ${idx}`,'');
      allFieldsValid = false;
    }
  
  
    // Check if each medication field is filled
    if (this.selectedMedicationCount[idx] > 0 && this.medicationFields[idx])  {
      this.medicationFields[idx].forEach((field, i) => {
        if (!field.value || field.value.trim() === '') {
          this.globalService.openSnackBar(`Medication ${i + 1} for condition at index ${idx} is not filled`,'');;
          allFieldsValid = false;
        }
      });
    }
  });
  
  if(this.conditionsArray.length >0 && this.selectedMedicationCount.length<=0)
  {
    allFieldsValid = false;
  }
  
  if(!allFieldsValid)
  {
  this.globalService.openSnackBar("Please select all relevant options for medications section", '');
  return true;
  }
  
  return false;
  }

  
 convertObjectToArray(obj: any): { formData: any[] } {
    let formData = [];
    Object.keys(obj).forEach((key, index) => {
      formData.push({
        id: index, // Use index as the id
        data: obj[key],
      });
    });
    return { formData };
  }
  

}
