import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MotorquoteService } from '../../_service/motorquote.service';
import Swal from 'sweetalert2';
import { GlobalService } from '../../_service/global.service';
import { HomequoteService } from '../../_service/homequote.service';
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';

@Component({
  selector: 'app-smecancelpayment',
  templateUrl: './smecancelpayment.component.html',
  styleUrls: ['./smecancelpayment.component.scss']
})
export class SmecancelpaymentComponent implements OnInit {

  quotenumber:any; paymentLoader: boolean = false; payment_cancel_reason : any; res_code = 0; errorMsg:any; pageStatus:any;
  localStorDta:any; partnerId:any;
  policyList: any;
  totalAmount: any;
  Email: any;
  userName: any;
  paymode: string;
  businessSource: any;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public motorQuoteService: MotorquoteService,public globalService:GlobalService,public homeQuoteService:HomequoteService,public travelInsuranceService:TraveInsuranceService) { }

  ngOnInit() {
   
    this.localStorDta =  this.globalService.getLocalStorageData();
    this.partnerId = this.localStorDta.Partner_ID;
    this.totalAmount = localStorage.getItem('TotalAmount');
    this.policyList = localStorage.getItem('policyList');
    this.paymode = localStorage.getItem('paymentMode');
    this.Email=this.localStorDta.EmailAddress;
    this.userName=this.localStorDta.UserName;
    this.paymentLoader = this.globalService.spinnerHide();
    this.quotenumber = localStorage.getItem('Payment_Quotation_Number');
    this.pageStatus = localStorage.getItem('pageStatus');
    
    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){
     this.res_code = routeParam.Res_Code;
     this.res_code = routeParam.Res_Code;
     this.quotenumber = routeParam.quoteNum;
     this.businessSource=routeParam.businessSource;
     this.getPaymentErrorMsg(this.res_code);
    }

   
    console.log(this.totalAmount);

  }

  goBackto(){
    this._route.navigateByUrl('/dashboard');
      // window.open('https://fidelityunited.ae','_self');
  }

  retryPayment(){
    if(this.paymode != 'Bulk'){
    //   let return_url = "http://k2key.in/motor_ins//dashboard";
    //   let site_url = "http://k2key.in/motor_ins/" + this._route.url;
  
    //   this.paymentLoader = this.globalService.spinnerShow();
    //  // this.showHideLoader.additionalDetail = true;
  
    //     this.travelInsuranceService.paymentAuth(this.quotenumber, site_url, return_url, 'SM', this.partnerId, '', '', 'B2B').subscribe(res => {//Modified by Anju for SME Payment gateway revamp
    //     let payRes = res;
  
    //       if (payRes.status == 'Failed') {
    //       Swal.fire(payRes.res_msg);
    //        this.paymentLoader = this.globalService.spinnerHide();
         
    //       return false;
    //     }
  
    //       else if (payRes.status == 'Success') {
  
    //        this.paymentLoader = this.globalService.spinnerHide();
    //           localStorage.setItem('Payment_Order_ID', res.data.tran_ref);
    //           localStorage.setItem('Payment_Order_Tokan', res.data.token);
    //        localStorage.setItem('Payment_Quotation_Number',this.quotenumber);
    //       //  localStorage.setItem('Payment_Quotation_ID',this.QuotationId);
    //           window.location.href = res.data.redirect_url;
          
    //       // localStorage.setItem('Payment_Order_ID', payRes.result.p_id);
    //       // localStorage.setItem('Payment_Order_Tokan', payRes.result.token);
    //       // localStorage.setItem('Payment_Quotation_Number', this.quotenumber);
  
    //       // window.location.href = payRes.result.redirect_url;
  
  
    //     }
  
  
    //   });
    this._route.navigateByUrl('/dashboard');
    }
    else
    {
      const todayDate =new Date();

      var n = todayDate.getTime();
      this.travelInsuranceService.bulkPayment(n,this.policyList,this.totalAmount,this.userName,this.Email).subscribe(res=>{
        if(res.res_code == 1 ){
      
        localStorage.setItem('Payment_Order_ID',res.result.tran_ref);
        localStorage.setItem('Payment_Order_Tokan',res.result.token);
      
        localStorage.setItem('TotalAmount',this.totalAmount);
        localStorage.setItem('policyList',this.policyList);
        window.location.href = res.result.redirect_url;
    
             }

      });
    }
   
  }

  getPaymentErrorMsg(res_code){
      this.motorQuoteService.getPaymentErrorMsg('PAYMENT',res_code,'ENG').subscribe(res =>{
            this.errorMsg = res.errorMessage[0].ErrorMessage;
      });
  }

  retrieveQuote(){
    
      // if(this.pageStatus == 'PAYMENT'){
      //     this._route.navigateByUrl( "agentMotor/MTquotepage/update/" +  this.quotenumber);
      // }
        

  }
  public ngOnDestroy() {
    // localStorage.removeItem('TotalAmount');
      // localStorage.removeItem('policyList');
    }

}

