import { Component, OnInit,ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { GlobalService } from "../../_service/global.service";
import { config } from '../../config';
declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  navBarChange=0;
  subscriptions: Subscription[] = []; 
  token: string;
  data: any;
  userId: any;
  constructor(public _globalService: GlobalService) { }
  
  ngOnInit() {
    if(localStorage.getItem("Dotnet-token"))
    {
      this.token = localStorage.getItem("Dotnet-token");
      this.data=this._globalService.getLocalStorageData();
      this.userId = this.data.User_ID;
    }
   
    this._globalService.getAllMenu().subscribe(res =>{
      this.menuItems= [];
      let i:number= 0;
      res.allMenuData.forEach((item,index) => {
        // console.log(item.Id);

         if(item.ParentMenuId==0){
          this.menuItems[i]= item;
          this.menuItems[i].submenu=[];

          i++;
         }else{
          var indexG =  this.menuItems.findIndex(function(obj, k) {
            return obj.Id === item.ParentMenuId;
          });
           if(indexG!=-1){
            this.menuItems[indexG].submenu.push(item);
            let submenuLen = this.menuItems[indexG].submenu.length ;
            this.menuItems[indexG].submenu[submenuLen-1].submenu = [] ;
            res.allMenuData.forEach((item1,index1) => {
              if(this.menuItems[indexG].submenu[submenuLen-1].Id==item1.ParentMenuId){
                this.menuItems[indexG].submenu[submenuLen-1].submenu.push(item1);
              }
            });
           }else{
           }
         }  
      });
      // Login Revamp - AppURL update angular is 1, Dot net redirection 0 - ruhina 
      this.menuItems.forEach((item,index)=>{  
        // console.log(item);
        item.submenu.forEach((item1,index1)=>{
          item1.submenu.forEach((item2,index2)=>{
            if(item2.MenuName == 'Marine'){
              item2.AppURL = item2.AppURL.concat( `?Id=${this.userId}&Value=${this.token}`)
            }
          })

          if(item1.IsChange == 0 )
          {
          if(item1.AppURL)
              item1.AppURL = item1.AppURL.concat( `?Id=${this.userId}&Value=${this.token}`)
          }
        })

      })
      //Login Revamp End 
      console.log(this.menuItems);
  

});
   // this.menuItems = ROUTES.filter(menuItem => menuItem);

    // const statusModSub =  this._globalService.navChanged.subscribe(data => {
    //   this.navBarChange=data;
    //   console.log(this.navBarChange);
    // }) ;

    //  this.subscriptions.push(statusModSub);
    
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
