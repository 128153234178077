import { Component, OnInit, ElementRef, HostListener } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService } from "../../_service/global.service";
import { config } from "../../config";
import { UserauthService } from "app/_service/userauth.service";
import { MotorquoteService } from "app/_service/motorquote.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public isAdmin: string;
  localStorDta:any;
  userData: any = [];
  menuList: any;
  public firstParam: string = "";
  public userAccess:any;
  public internalUser=false;
  isSmallScreen = false;
  isCollapsed: boolean;
  constructor(
    private route: ActivatedRoute,
    public _globalService: GlobalService,
    public _route: Router,
    location: Location,
    public userAuth: UserauthService,
    private element: ElementRef,
    private router: Router, public motorQuoteService: MotorquoteService
  ) {
    this.location = location;
    this.sidebarVisible = false;
    this.checkScreenSize();
    
  }

  ngOnInit() {
    //this.checkUserAccess();
    //   this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName("close-layer")[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });

    
    //START:GET LOCALSTORAGE DATA
    this.userData = this._globalService.getLocalStorageData();
   this.checkIfInternalUser();
   console.log('internalUser',this.internalUser)
    this.userData.default_image_name = "assets/img/faces/marc.png";
    //ahmed added pet menu depend on parameter passed from menu from database
    console.log("userdata", this.userData.Partner_ID);
    this.firstParam = this.route.snapshot.queryParamMap.get("Product");
    if (this.firstParam === "pet") {
     // this.getPetMenuList();
     this.getMenuList();

    } else {
      this.getMenuList();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isSmallScreen = window.innerWidth < 991;
    if (!this.isSmallScreen) {
    this.isCollapsed = false; // Reset collapsed state for larger screens
  }
}
  
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);

    body.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    body.classList.remove("nav-open");
  }
  sidebarToggle() {
    this.isCollapsed = !this.isCollapsed;
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName("body")[0];

    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove("nav-open");
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function () {
        $toggle.classList.remove("toggled");
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function () {
        $toggle.classList.add("toggled");
      }, 430);

      var $layer = document.createElement("div");
      $layer.setAttribute("class", "close-layer");

      if (body.querySelectorAll(".main-panel")) {
        document.getElementsByClassName("main-panel")[0].appendChild($layer);
      } else if (body.classList.contains("off-canvas-sidebar")) {
        document
          .getElementsByClassName("wrapper-full-page")[0]
          .appendChild($layer);
      }

      setTimeout(function () {
        $layer.classList.add("visible");
      }, 100);

      $layer.onclick = function () {
        //asign a function
        body.classList.remove("nav-open");
        this.mobile_menu_visible = 0;
        $layer.classList.remove("visible");
        setTimeout(function () {
          $layer.remove();
          $toggle.classList.remove("toggled");
        }, 400);
      }.bind(this);

      body.classList.add("nav-open");
      this.mobile_menu_visible = 1;
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  logout() {
    localStorage.clear();

    window.location.href = config.logOutURL;

    //     this.router.navigate(["/login"]);
    //    // window.location.href = 'http://k2key.in/fidelityUnited/mainpage';
  }
  sidenavToggle() {
    let navChangedVal = 1;

    let navChangedDataVal = this._globalService.navChanged.subscribe((data) => {
      navChangedVal = data;
    });

    let navChangedNext = 0;
    if (navChangedVal == 0) {
      navChangedNext = 1;
    }

    this._globalService.navChanged.next(navChangedNext);
  }
 
  getMenuList() {
    if(this.isSmallScreen)
      {
        var menulistre = null;
        menulistre = [
          {
            menuName: "Retrieve Quote",
            menuLink: "/home/retreivequote",
          },
          {
            menuName: "Referrals",
            menuLink: "/home/referalquote",
          },
          {
            menuName: "Policies",
            menuLink: "/home/viewPolicy",
          },
        ];
    
        this.menuList = menulistre;
      }
      else
      {
    this.userAuth.getAllMenuListDynmic().subscribe((result) => {
      console.log(result);
      this.menuList = result.menuArray.filter((item)=>item.permission ==1);
      console.log("filterd list", this.menuList)
    });
  }
  }

  //ahmed added pet menu
  getPetMenuList() {
    var menulistre = null;
    menulistre = [
      {
        menuName: "Dashboard",
        menuLink: "/dashboard",
      },
      {
        menuName: "Retrieve Quote",
        menuLink: "/home/retreivequote",
      },
      {
        menuName: "Referrals",
        menuLink: "/home/referalquote",
      },
      {
        menuName: "Policies",
        menuLink: "/home/viewPolicy",
      },
      {
        menuName: "Report Extraction",
        menuLink: "/agentMotor/policyrenew",
      },
      // {
      //   menuName: "Policy Purchase",
      //   menuLink: "<a href='http://mybuddy.fidelityunited.ae?broker_id=2'></a>",
      // },
    ];

    this.menuList = menulistre;
  }
  navigateToPet() {
   
      this.checkUserAccess();
   
  }
  navigate(routerLink) {
    this._route.navigateByUrl(routerLink);
  }
  

  checkUserAccess(){
    this.localStorDta =  this._globalService.getLocalStorageData();

    this.motorQuoteService.checkUserAccess('Pet',this.localStorDta.EmailAddress, 'PT').subscribe(res => {
      let response = res;
    console.log("prm res:",response.userAccessData)
          if (response.userAccessData == 0) {                 // userAccessData = 1 ------ give access 
                Swal.fire('Sorry !','You are not authorised.','error');     
                //this._route.navigateByUrl('/agentMotor/motorquote');   // userAccessData = 0 ------- access denied
                 this.userAccess = false;
                return false;
          }else{
            var someUrl =
            "http://10.170.11.58/?broker_id=" + this.userData.User_ID;
            (window as any).open(someUrl, "_blank");
              this.userAccess = true;
          }
      });
  }
  navigateToReports(){
    var someUrl =
    "https://smestaging.fidelityunited.ae/reports/productionreconn.aspx";
    (window as any).open(someUrl, "_blank");
  }

  public checkIfInternalUser(){
    this.userData = this._globalService.getLocalStorageData();
    
if(this.userData.Partner_ID==1  || this.userData.Partner_ID==3){
  console.log('true',this.userData.Partner_ID);
  this.internalUser=  true;
}
else
this.internalUser= false;
}
}
