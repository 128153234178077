import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { IssuepolicyService } from '../../../_service/issuepolicy.service';
import { GlobalService } from "../../../_service/global.service";
import { MotorquoteService } from '../../../_service/motorquote.service';
import { TraveInsuranceService } from '../../../_service/traveInsurance.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {

  quoteNum = 0; loader:boolean = false; 
  PageLoader: boolean =false;
  token = 0; orderNumber:any; orderToken:any; quoteNumber:any; payment_transaction_ID_status:any; payment_Transaction_ID:any;
  totalPremium :any; quoteDetail:any = [];

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public issuePolicyService: IssuepolicyService,public globalService:GlobalService,public motorQuoteService:MotorquoteService, public travelInsuranceService:TraveInsuranceService) { }
 
  ngOnInit() {
    this.PageLoader = this.globalService.spinnerShow();
   this.orderNumber =  localStorage.getItem("Payment_Order_ID");
   this.orderToken  =  localStorage.getItem("Payment_Order_Tokan");
   this.quoteNumber = localStorage.getItem('Payment_Quotation_Number');



    const routeParam = this._activatedroute.snapshot.params;
      if(routeParam){

       this.token = routeParam.Token;
  
      }
       this.verifyQuatationPayment();
  
  }

 
  async saveQuotationPayment(){
      await this.issuePolicyService.saveQuotationPayment(this.orderToken,this.payment_transaction_ID_status,this.quoteNumber,this.response_msg,this.payment_Transaction_ID).then((res:any) =>{
        
            if(res.res_code == 1){
              localStorage.removeItem("Payment_Order_ID");
              localStorage.removeItem("Payment_Order_Tokan");
              localStorage.removeItem('Payment_Quotation_Number');
              //Modified by Anju as policy integration failing as policy number is passed as null
              //Nextcare API start
             this.issuePolicyService.callNextCareTPAIntegration(this.quoteNumber).subscribe(res =>
              {
                    console.log(res)
              })
              //Nextcare API end
            }
      });
  }
response_msg:any ='';
  verifyQuatationPayment(){
//Modified by Anju for Medical Payment gateway revamp
    this.travelInsuranceService.verifyPayment(this.orderNumber).subscribe(res =>{
      console.log(res);
    let response = res;
    this.response_msg = response.result.payment_result.response_code;
    this.payment_Transaction_ID = response.result.tran_ref;
      //this.payment_Transaction_ID = response.result.transaction_id;

             
               if(res.res_code == 2){

                  //if(res.result.details == "This invoice is either cancelled by the customer or expired"){
                      this.payment_transaction_ID_status = "CANCELLED";
                      this.travelInsuranceService.sendMailDueToCancelPayment(response.result.payment_result.response_message, this.payment_Transaction_ID,'MEDICAL').subscribe(res=>{
                      console.log(res);
                      });
                      this._route.navigateByUrl('medical/cancelpayment/' + this.response_msg);	
              this.PageLoader = this.globalService.spinnerHide();	
                  }
                  else{	
                    this.payment_transaction_ID_status = "ISSUED";	
                    this.saveQuotationPayment();	
                    this.PageLoader = this.globalService.spinnerHide();	
                }


                 //this.saveQuotationPayment();
              // }
        });

  }

  donePayment(){
    //changed ok button url path// ruhina
    //window.open('https://fidelityunited.ae/', "_self"); 

    localStorage.removeItem("Payment_Order_ID");
    localStorage.removeItem("Payment_Order_Tokan");
    localStorage.removeItem('Payment_Quotation_Number');
    this._route.navigateByUrl('/dashboard');
            
  }


 


}
