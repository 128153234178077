import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { googleMapService } from "app/_service/google-map-service";
import { BehaviorSubject } from "rxjs";
import { google } from 'google-maps';
@Component({
  selector: "modal",
  templateUrl: "./geo-location.component.html",
  styleUrls: ["./geo-location.component.scss"],
  // providers:[GoogleMapService]
})
export class GeoLocationComponent implements OnInit {
  locationCords: string = "";
  mapInitialized:boolean=false;
  public messageEmitter: EventEmitter<string> = new EventEmitter<string>();
  public cordsSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  constructor(private _googleMapService: googleMapService) {
    var mapSearchElement = document.getElementById("map-search");

    if (mapSearchElement) {
      mapSearchElement.style.display = "none";
    }
    this.messageEmitter = new EventEmitter<string>();
  }
  @Output() closeModalEvent = new EventEmitter<void>();
  @ViewChild("map", { static: true }) mapElement: ElementRef;

  map: any;
  ngOnInit() {
    var mapSearchElement = document.getElementById("map-search");

    if (mapSearchElement) {
      mapSearchElement.style.display = "none";
    }
    this.cordsSubject.next("hello");
    this.initMap();
    this.map.addListener("click", (event: any) => {
      const latitude = event.latLng.lat();
      const longitude = event.latLng.lng();
      this.locationCords = latitude + "," + longitude;
      const geocoder = new google.maps.Geocoder();
      let adressCord = new google.maps.LatLng(latitude, longitude);
      console.log(adressCord);
      geocoder.geocode({ 'location': adressCord }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const locationName = results[0].formatted_address;
            console.log('Location Name:', locationName);
          } else {
            console.error('No results found');
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });


    });

    let searchLocation = new google.maps.places.SearchBox(
      document.getElementById("map-search") as HTMLInputElement
    );
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      document.getElementById("map-search")
    );
    this.map.addListener("bounds_changed", () => {
      searchLocation.setBounds(
        this.map.getBounds() as google.maps.LatLngBounds
      );
    });

    let markers: google.maps.Marker[] = [];
    searchLocation.addListener("places_changed", () => {
      const places = searchLocation.getPlaces();

      if (places.length == 0) {
        return;
      }
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        const icon = {
          url: place.icon as string,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };
        let cords = JSON.stringify(place.geometry.location);
        this.locationCords =
          JSON.parse(cords).lat + "," + JSON.parse(cords).lng;
        markers.push(
          new google.maps.Marker({
            position: place.geometry.location,
            icon: icon,
            // this.map,
            //   icon,
            //   title: place.name,
            //   position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });
  }

  initMap() {
    const mapOptions: google.maps.MapOptions = {
      center: { lat: 25.2048, lng: 55.2708 }, // Dubai coordinates
      zoom: 12,
    };
    this.map = new google.maps.Map(document.getElementById("map"), mapOptions);
    this.mapInitialized=true;

    setTimeout(() => {
      const mapSearchElement = document.getElementById("map-search");    
      if (mapSearchElement) {
        mapSearchElement.style.display = "block";
      }
    }, 2000);


  }
  ConfirmLocation() {
    console.log(`Selected locationCords: ${this.locationCords}`)
    this._googleMapService.setLocation(this.locationCords);
    this.closeModalEvent.emit();
  }
  closeModal() {
    // const center = this.map.getCenter();
    // const zoom = this.map.getZoom();

    // console.log('Current Map Center:', center.lat(), center.lng());
    // console.log('Current Zoom Level:', zoom);
    // alert(center.lat()+','+center.lng())
    this.locationCords = "";
    this.closeModalEvent.emit();
  }
}
