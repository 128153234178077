import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { UserauthService } from "app/_service/userauth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { GlobalService } from 'app/_service/global.service';
import { config } from "app/config";
import Swal from 'sweetalert2';
import { ReCaptcha2Component } from 'ngx-captcha';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(public _userAuthService: UserauthService,private translate: TranslateService,
    private _router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public _globalService: GlobalService) { }

  ngOnInit() {

  }


  onChange(){
    // alert(1);
              this._router.navigate(["/login"]);  
          } 

}


