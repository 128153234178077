import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { catchError, tap, map } from "rxjs/operators";
import { of, throwError, observable } from "rxjs";
import { GlobalService } from "../_service/global.service";
import { config } from "./../config";
import { MatSnackBar } from "@angular/material/snack-bar";
import Swal from "sweetalert2";


@Injectable({
  providedIn: 'root'
})
export class SmeMedicalService {
  apiURL: string = config.apiUrl;
  url: string = config.SmeMedCoreURL
  url2 : string = config.SmeMedDashboardURL

  constructor( 
  private httpClient: HttpClient,
  public _globalService: GlobalService) { }

  public handleError(error) {
    console.log(error)
    let errorMessage: any = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(error.status);
    if (error.status == 0) {
      //window.alert("There is server error. Please try again later");
      Swal.fire("There is server error", "Please try again later", "error");
    }
    // if(error.status == 500){
    //         Swal.fire("Sorry, an error occured", "Please try again later", "error");
    // }

    return throwError(errorMessage);
  }

  public handleErrorCensus(error) {
    console.log(error)
    if(error.status == 500)
    {

      Swal.fire('','Invalid data detected for the uploaded census data. Please re-upload valid data.','error')
    }
    let errorMessage: any = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(error.status);
    if (error.status == 0) {
      //window.alert("There is server error. Please try again later");
      Swal.fire("There is server error", "Please try again later", "error");
    }
    //   if(error.status == 500){
    //         Swal.fire("Sorry, an error occured", "Please try again later", "error");
    //  }

    return throwError(errorMessage);
  }
  public handleErrorBlacklist(error) {
    console.log(error)
    if(error.status == 500)
    {

      Swal.fire('','Invalid format was detected in uploaded blacklist data, please correct it and try again.','error')
    }
    let errorMessage: any = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(error.status);
    if (error.status == 0) {
      //window.alert("There is server error. Please try again later");
      Swal.fire("There is server error", "Please try again later", "error");
    }
    //   if(error.status == 500){
    //         Swal.fire("Sorry, an error occured", "Please try again later", "error");
    //  }

    return throwError(errorMessage);
  }
  //General Form API integration 
  generalInfoAPI(request):Observable<any> {
    return this.httpClient
      .post(`${this.url}/lead`, request)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          console.log("response",response)
        })
      );
  }

   checkBlacklist()
  {
    return  this.httpClient
      .get(`${this.url}/blacklist`)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      ).toPromise();
  }

  uploadDocument(uploadData): Observable<any> {
    return this.httpClient
      .post(`${this.url}/files`, uploadData)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  validateCensus(formData) {
    return this.httpClient
      .post(`${this.url}/validate`, formData)
      .pipe(
        catchError(this.handleErrorCensus),
        tap((res) => {
          console.log(res)
          let response = this._globalService.convertResponseInJson(res);
        })
      ).toPromise();
  }
  
  getAllPlans(region_code,productname,partnerID)
  {
  return this.httpClient
  
      .get(`${this.url}/plans`,{ params: {
        emirate:region_code,
        product: productname,
        partner_id :partnerID
    
      }
    }, )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      ).toPromise();
  }


  getPlanInfo(plan_code,refid)
  {
  return this.httpClient
      .get(`${this.url}/plan-details/${plan_code}`,{params: {
        refid:refid
      }
    })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      ).toPromise();
  }

  savePlanSelected(selectedplanarray)
  {
  return this.httpClient
      .post(`${this.url}/save-plans`,selectedplanarray)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  getQuotationDetails(quoteNum,userRole)
  {
    return this.httpClient
        .get(`${this.url}/quote/${quoteNum}`,{params: {
          userRole:userRole
        }
      })
        .pipe(
          catchError(this.handleError),
          tap((res) => {
            let response = this._globalService.convertResponseInJson(res);
          })
        );
    }

    cloneQuotation(quoteNum)
    {
      return this.httpClient
          .get(`${this.url2}/clone/${quoteNum}`)
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          ).toPromise();
      }

      readFileObject(fileUrl)
      {
        return this.httpClient
          .get(`${fileUrl}`)
          .pipe(
            catchError(this.handleError),
            tap((res:any) => {
              console.log(res.blob())
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      updateQuoteStatus(quoteNum,status)
      {
        return this.httpClient
          .post(`${this.url2}/update_status`,{
            quote_number : quoteNum,
            new_status : status
          })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      getDashboardPremium(partnerid)
      {
        return this.httpClient
          .get(`${this.url2}/quotation-count`,{params: {
            partner_id:partnerid
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      getDailyDashboard(partner_id,duration)
      {
        return this.httpClient
          .get(`${this.url2}/premium-dashboard`,{params: {
            partner_id:partner_id,
            duration : duration
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }


      getWeeklyDashboard(partner_id,duration)
      {
        return this.httpClient
          .get(`${this.url2}/premium-dashboard`,{params: {
            partner_id:partner_id,
            duration : duration
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      getMonthlyDashboard(partner_id,duration)
      {
        return this.httpClient
          .get(`${this.url2}/premium-dashboard`,{params: {
            partner_id:partner_id,
            duration : duration
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      getStaticFiles()
      {
        return this.httpClient
        .get(`${this.url}/static-files`)
        .pipe(
          catchError(this.handleError),
          tap((res) => {
            let response = this._globalService.convertResponseInJson(res);
          })
        );
      }
      
      sendEmail(refid,type)
      {
        return this.httpClient
          .post(`${this.url}/send-email/${refid}`, {
            email_type : type
          })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      uploadBlacklist(uploadData): Observable<any> {
          return this.httpClient
            .post(`${this.url}/import-blacklist`, uploadData)
            .pipe(
              catchError(this.handleErrorBlacklist),
              tap((res) => {
                let response = this._globalService.convertResponseInJson(res);
              })
            );

      }

      //------------ List of dropdown data FOR ISSUPOLICY ---------//
  public getQuotationFormData() {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getQuotationFormData`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      ).toPromise();
  }

  public getpartnerBranch(partnerID) {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/partnerBranch`,
        { partnerID: partnerID },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      ).toPromise();
  }

  public getProductList(partner_id){
    return this.httpClient
    .get(`${this.url}/partner-product`,{params: {
      partner_id:partner_id,
    
    }
  })
    .pipe(
      catchError(this.handleError),
      tap((res) => {
        let response = this._globalService.convertResponseInJson(res);
      })
    ).toPromise();
  }

  downloadQuoteWord(refid,fileType,quotationNumber)
      {
        return this.httpClient
          .get(`${this.url}/download_quote`,{params: {
            refid:refid,
            fileType : fileType,
            quotationNumber : quotationNumber
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      downloadQuoteExcel(refid,fileType,quotationNumber)
      {
        return this.httpClient
          .get(`${this.url}/download_quote_excel`,{params: {
            refid:refid,
            fileType : fileType,
            quotationNumber : quotationNumber
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      downloadQuotePremium(refid,quotationNumber)
      {
        return this.httpClient
          .get(`${this.url}/download_premium`,{params: {
            refid:refid,
            quotationNumber : quotationNumber
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      downloadQuoteRates(quotationNumber)
      {
        return this.httpClient
          .get(`${this.url}/download_rates`,{params: {
            // refid:refid,
            quotationNumber : quotationNumber
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      downloadQuoteTOB(refid,quotationNumber)
      {
        return this.httpClient
          .get(`${this.url}/download_tob`,{params: {
            refid:refid,
            quotationNumber : quotationNumber
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      downloadQuoteTOBExcel(refid,quotationNumber)
      {
        return this.httpClient
          .get(`${this.url}/download_tob_excel`,{params: {
            refid:refid,
            quotationNumber : quotationNumber
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      sendQuoteEmail(refid,email)
      {
        return this.httpClient
          .post(`${this.url}/send-email-user/${refid}`, {
            refid:refid,
            email : email
          })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      mafPopulate(refid: any,userrole: any)
      {
        return this.httpClient
          .get(`${this.url}/maf_form_populate`,{params: {
            refid:refid,
            userrole: userrole
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      sendMafPopulate(refid: any, memberId: any)
      {
        return this.httpClient
          .get(`${this.url}/maf_form_populate_email`,{params: {
            refid:refid,
            memberId:memberId
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      mafRefPopulate(refid: any,userrole: any)
      {
        return this.httpClient
          .get(`${this.url}/maf_populate_referral`,{params: {
            refid:refid,
            userrole: userrole
          }
        })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      confirmCover(quoteid,userrole)
      {
        return this.httpClient
          .post(`${this.url}/confirm_cover`, {
            quoteid:quoteid,
            userrole: userrole
          })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      applyFactor(quoteid,factor)
      {
        return this.httpClient
          .post(`${this.url}/apply_factor`, {
            quoteid:quoteid,
            factor: factor
          })
          .pipe(
            catchError(this.handleError),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );
      }

      mafApprovalSubmit(apiData): Observable<any> {
        return this.httpClient
          .post(`${this.url}/maf_referral_submit`, apiData)
          .pipe(
            catchError(this.handleErrorBlacklist),
            tap((res) => {
              let response = this._globalService.convertResponseInJson(res);
            })
          );

    }

  
}
