import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomequoteComponent } from './homequote.component';
import {MaterialuiModule} from '../../materialui/materialui.module';
import { HomequoteRoutingModule } from './homequote-routing.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ThankYouComponenthome } from './thank-you/thank-you.component';
import { PaymentConfirmComponent } from './payment-confirm/payment-confirm.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [HomequoteComponent, ThankYouComponenthome, PaymentConfirmComponent],
  imports: [
    CommonModule,MaterialuiModule,HomequoteRoutingModule,NgxMatSelectSearchModule,
    MDBBootstrapModule.forRoot(), NgxMaskModule.forRoot(),
  ]
})
export class HomequoteModule { }
