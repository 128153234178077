import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "./_service/auth.guard";
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LandingAuthUserComponent } from './layouts/landing-auth-user/landing-auth-user.component';
import { LoginUserComponent } from './layouts/login-user/login-user.component';
import { MedicalMafFormComponent } from './medical/medical-maf-form/medical-maf-form.component';
import { ThankyouComponent } from './medical/memberlist/thankyou/thankyou.component';
import { PaymentinvoiceComponent } from './medical/memberlist/paymentinvoice/paymentinvoice.component';
import { MotorpaymentinvoiceComponent } from './agent_motor/agentmotorquote/motorpaymentinvoice/motorpaymentinvoice.component';
import { MedicalcancelpaymentComponent } from './medical/medicalcancelpayment/medicalcancelpayment.component';
import { MotorThankyouComponent } from './agent_motor/agentmotorquote/thankyou/thankyou.component';
import { MotorcancelpaymentComponent } from './agent_motor/agentmotorquote/motorcancelpayment/motorcancelpayment.component';
import { TravelcancelpaymentComponent } from './travel/travelquote/travelcancelpayment/travelcancelpayment.component';
import { ThankYouComponent } from './travel/travelquote/thank-you/thank-you.component';
import { TravelPaymentinvoiceComponent } from './travel/travelinvoicepayment/paymentinvoice.component';
import { CommonThankYouComponent } from './common-tank-you/thank-you.component';
import { ThankYouComponenthome } from './home/homequote/thank-you/thank-you.component';
import { HomequoteComponent } from './home/homequote/homequote.component';
import { HolidayquoteComponent } from './home/holidayquote/holidayquote.component';
import { SmepaymentinvoiceComponent } from './sme/smepaymentinvoice/smepaymentinvoice.component';
import { ThankyouSMEComponent } from './sme/thankyou/thankyou.component';
import { HomePaymentinvoiceComponent } from './home/flexiquote/flexiinvoicepayment/paymentinvoice.component';
import { SmecancelpaymentComponent } from './sme/smecancelpayment/smecancelpayment.component';
import { ThankyouSMERiskComponent } from './sme/sme-allrisk/thankyou/thankyou.component';
import { ThankyouSMEFixedComponent } from './sme/sme-fixed/thankyou/thankyou.component';
import { LoginComponent } from './login/login.component';
import { ResetpasswordComponent } from './login/resetpassword/resetpassword.component';
import { LogoutComponent } from './login/logout/logout.component';
import { AdminAccessibilityComponent } from './login/admin-proxy-access/admin-proxy.component';
import { MailMafQuoteToolComponent } from './medical/sme-medical-rating/mailmafquotetool/mailmafquotetool.component';
// import { MafAppFormComponent } from './medical/sme-medical-rating/mafappform/mafappform.component';
// import { TravelPaymentinvoiceComponent } from './travel/travelinvoicepayment/paymentinvoice.component';
const routes: Routes =[
  {
    path: '',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  // {
  //   path: '',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  // },
  {
    path: 'authlanding',
    component: LandingAuthUserComponent
  },
  {
    path: "login/:id/:product/:page",
    component: LoginUserComponent,
    
  },
  {
    path: "login/:id/:product",
    component: LoginUserComponent,
    
  },
  {
    path: 'MAF_Form/:token/:quoteNum',
    component: MedicalMafFormComponent
  },
  {
    path: 'MAFTOOL_Form/:token/:memberid/:quoteNum',
    component: MailMafQuoteToolComponent
  },
  // {
  //   path: 'smeMedicalRating/mafappform/smeMafApproval/:quoteNum',
  //   component: MafAppFormComponent,
  // },
  {
    path: 'medical/member/thankyou/:Token',
    component: ThankyouComponent,
  },
  {
    path: 'medical/member/pay/r/:QuotationNumber/quote/payment',
    component: PaymentinvoiceComponent,
  },
  {
    path: 'motor/pay/r/:QuotationNumber/quote/payment',
    component: MotorpaymentinvoiceComponent,
  },
  {
    path : 'medical/cancelpayment/:Res_Code',
    component : MedicalcancelpaymentComponent
  },
  {
    path : 'motor/thankyou',
    component : MotorThankyouComponent
  },
  {
    path : 'motor/cancelpayment/:Res_Code',
    component : MotorcancelpaymentComponent
  },
  {
    path : 'travel/cancelpayment/:Res_Code/:quoteNum/:businessSource',
    component : TravelcancelpaymentComponent
  },
  {
    path: "travel/travelquote/thankyou/:policyNumber/:quoteNum",
    component: ThankYouComponent,
    
  },
  {
    path: 'home/member/pay/r/:QuotationNumber/quote/payment',
    component: HomePaymentinvoiceComponent,
  },
  {
    path: "home/flexiquote/thankyou/:policyNumber/:quoteNum",
    component: ThankYouComponenthome,
    
  },
  {
    path: "home/homequote/thankyou/:policyNumber/:quoteNum",
    component: HomequoteComponent,
    
  },
  {
    path: "home/holidayquote/thankyou/:policyNumber/:quoteNum",
    component: HolidayquoteComponent,
    
  },
  {
    path: "standardtravel/member/pay/r/:QuotationNumber/quote/payment",
    component: TravelPaymentinvoiceComponent,
    
  },
  {
    path: "thankyou/:batchNo/:quoteNum",
    component: CommonThankYouComponent,
    
  },
  {
    path: "sme/member/pay/r/:QuotationNumber/quote/payment",
    component: SmepaymentinvoiceComponent,
    
  },
  {
    path: "sme/thankyou/:policyNumber/:quoteid",
    component: ThankyouSMEComponent,
    
  },
  {
    path: "sme/smefixed/thankyou/:policyNumber/:quoteid",
    component: ThankyouSMEFixedComponent,
    
  },
  {
    path: "sme/smeallrisk/thankyou/:policyNumber/:quoteid",
    component: ThankyouSMERiskComponent,
    
  },
  {
    path : 'sme/cancelpayment/:Res_Code/:quoteNum/:businessSource',
    component : SmecancelpaymentComponent
  },
 
  // {
  //   path: "login/:id/:product",
  //   component: LoginUserComponent,
    
  // },
  // {
  //   path: "login/:id",
  //   component: LoginUserComponent,
    
  // },
  {
    path: "login",
    component: LoginComponent,
    
  },

  {
    path: "logout",
    component: LogoutComponent,
    
  },

  {
    path: "reset",
    component: ResetpasswordComponent,
  },

  {
    path: "adminproxy",
    component: AdminAccessibilityComponent,
  },
 

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
