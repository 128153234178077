import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import { GlobalService } from 'app/_service/global.service';
import { IssuepolicyService } from 'app/_service/issuepolicy.service';
import { SmequoteService } from 'app/_service/smequote.service';
import Swal from 'sweetalert2';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../home/viewpolicy/date.adapter';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import {  DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MotorquoteService } from 'app/_service/motorquote.service';
import { HomequoteService } from 'app/_service/homequote.service';
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';
import { TreeRepository } from 'typeorm';
import { googleMapService } from 'app/_service/google-map-service';
import { GeoLocationComponent } from '../geo-location-modal/geo-location.component';
import { config } from 'app/config';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-sme-fixed',
  templateUrl: './sme-fixed.component.html',
  styleUrls: ['./sme-fixed.component.scss'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class SmeFixedComponent implements OnInit {
  characterToReplace = '&';
  replacementCharacter = '&#38;';
  optionyesNo=[{value:'Y',label:'Yes'},{value:'N',label:'No'},]
  smeQuoteDetails: FormGroup;
  localStorDta: any;
  partnerID: any;
  noofFloor: any;
  turnOver: any;
  bArea: any;
  claimHistory: any;
  emirates: any;
  emiratesArea: any;
  conclass: any;
  indSegment: any;
  busseg: any;
  emrmall: any;
  bussPremise: any;
  userId: any;
  validtnMsg: boolean;
  formDataRes: any;
  partnersArr: any;
  partnerId: any;
  partnerVal: any;
  partnerBranchArr: any[]; accept_terms :boolean = false;
  accept_disclaimer :boolean = false;
  branchId: any;
  branchVal: any;
  quoteNumber = '';
  benefitData: any;
  displayBenefitData: any;
  WorkmensName="Workmens Compensation including Employers Liability "
  Property: any;
  rowAmount=0;
  sectionpremium_PAR=0;sectionpremium_Money=0;  sectionpremium_PL=0;  sectionpremium_WN=0; sectionpremium_FG=0; sectionpremium_BI=0;
  sectionpremium_MB=0;
  sectionpremium_EE=0;
  minsectionpremium_PAR=0;
  minsectionpremium_Money=0;
  minsectionpremium_PL=0;
  minsectionpremium_WN=0;
  minsectionpremium_FG=0;
  minsectionpremium_BI=0;
  minsectionpremium_MB=0;
  minsectionpremium_EE=0;
  totalPremium: any = 0;
  policyFee = 50;
  basePremium: number;
  Vat_Amount: number;
  totalsumCovered=0;
  loadingby: any;
  loadingAmount=0;
  planId: any;
  serviceTax: any;
  additionalDetails: FormGroup;
  freezzone: any;
  ButtonName="Get Quote"
  policyType = "NEW";
  quoteId: any;
  businessSegmentLoader: boolean;
  emiratesmallLoader: boolean;
  quoteLoader: boolean;
  pageLoader: boolean;
  processType="'INSERT'"
  policyNumber: any;
  productCode: string;
  companydocument: any;
  documentCheckbox: boolean =false;
  docList: any;
  docUploadFlag: boolean ;  minDate = new Date(Date.now());
  maxDate = new Date(new Date().setDate(new Date().getDate() + 45));
  madFileds: string;
  quotationloader: boolean;
  issuepolicyLoader: boolean;
  worDoc: any;
  userAccess: boolean =true;
  referalModel: boolean =false;
  refer_type: string;
  refer_condtion_type: number;
  referalDescription: string ='';
  referal: boolean;
  policyStatus: string;
  validtnMsgfordescription: boolean;
  additionalDoc: boolean;
  additionalDocFile: any[];
  document_data: any;
  hideImages: boolean;
  tempAdditionalDoc: any[];
  refferalButton: boolean =false;
  businessSource: any;
  accessGroup: any;
  userRole: any;
  userType: any;
  checkId: any;
  quoteStatus: any;
  quotationHistoryArr: any;
  emailAdd: any ='';
  minLoadingRange: any;
  maxLoadingRange: any;
  creditLimit: any;
  PTY_CREDIT_LMT_DAYS: any;
  lmt_days: boolean =false;
  source='B2B';
  emiId: any;
  checked:any=[];
  policyHolderForm :FormGroup; planDetailForm:FormGroup; additionalFormDetail:FormGroup;
  partnerActive:boolean =false; //Added by Anju for partnercreditlimitcheck
  selectedPartnerId: any;//added by Anju for Partner Credit Limit check
  selectedBranchId: any;//added by Anju for Partner Credit Limit check

  colorPlan:any=[{color:"backgroundmaintheme-blue"},
                 {color:"backgroundmaintheme-lavender"},
                 {color:"backgroundmaintheme-lightblue"}]

  section={PAR_hideshow :false,M_hideshow:false,PL_hideshow:false,WM_hideshow:false,FG_hideshow:false,BI_hideshow:false,MB_hideshow:false,EE_hideshow:false}
  checkcreditLimitArray:any=[];
  chechfor: boolean =true;
  emailaddValidation: boolean =false;
  selectedYear: number;
  years: number[] = [];
  access: number;
  hideshow={par:true,m:true,pl:true,w:true,fg:true,bi:true,mb:true,ee:true};
  hideshowpar: boolean =true;  hideshowm: boolean =true;  hideshowpl: boolean =true;  hideshoww: boolean =true;hideshowfg: boolean =true; hideshowbi: boolean =true; hideshowmb: boolean =true; hideshowee: boolean =true;
  AdminFees: any;
  baseprice: any;
  planName: any;
  FixedContribution: any;
  planData: any;
  viewBenLoader: boolean=false;
  isPlus: boolean=false;
  isGold: boolean=false;
  isPlatinum: boolean=false;
  reapprove: boolean = false;
  expiryDate: any;
  dtminDate: Date;
  dtmaxDate: Date;
  planslct: any;
  Vat_Amount1: any;
  Vat_Amount2: any;
  Vat_Amount3: any;
  totalPremium1: any;
  totalPremium2: any;
  totalPremium3: any;
  totalsalary: any;
  quotation_branchId:string="";
  refReason: any;	
  referalRes: any;	
  isReferral: any;	
  file_name: any;	
  UploadedFileName: any;	
  response_code: any;	
  quoteDocuments: any;	
  showWC: boolean;	
  buttonds: boolean;
  ref_value: any;
  claimCount: any;
  claimAmount: any;
  claimRatio: number;
  expiringPremium: any;
  valueRatio: number;
  addressLocationCords:string="";
  geoLocationMandatory:boolean=true;
riskInMall:boolean=false;
InitiatedQuote:boolean=false;
initiatedPolDocs:any=[];
enableCoverStartDateModify:boolean=false;
private digi_status = new Subject<string>();
  digi_status$ = this.digi_status.asObservable();
  digiSwalInstance:any;
  @ViewChild('modalContainer', { static: true, read: ViewContainerRef }) modalContainer: ViewContainerRef;
  showFL: boolean=true;
  showIcon: boolean;
  
constructor(public _route: Router,public formBuilder: FormBuilder,public homeQuoteService:HomequoteService,public _activatedroute: ActivatedRoute,public smequoteService: SmequoteService, public issuepolicyService:IssuepolicyService,public motorQuoteService: MotorquoteService, public globalService: GlobalService,public travelInsuranceService:TraveInsuranceService,private resolver: ComponentFactoryResolver, private _googleMapService:googleMapService) { }

  ngOnInit() {
    const routParam = this._activatedroute.snapshot.params;
    if(routParam.quoteNumber &&routParam.ID=="3"){
      this.InitiatedQuote=true;
      this.localStorDta = this.globalService.getLocalStorageData();
      let loggedUser =Number(this.localStorDta.User_ID);
      let privilagedUsers: number[] = config.sme_EnableCoverDateEdit.split(',').map(Number);
      if (privilagedUsers.includes(loggedUser)){
        this.enableCoverStartDateModify=true;
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        this.minDate = thirtyDaysAgo;

      }
    }
    this._googleMapService.getLocation().subscribe(res=>{
      this.addressLocationCords=res;
      this.additionalDetails.get('companyAddressGeoLocation').setValue(res);
      })
    this.setupYears();
    this.localStorDta = this.globalService.getLocalStorageData();
    this.partnerID = this.localStorDta.Partner_ID;
   
    this.businessSource = this.localStorDta.BusinessSource;
    this.claimCount = localStorage.getItem('ClaimCount');
    this.claimAmount = localStorage.getItem('ClaimAmount');
    this.expiringPremium = localStorage.getItem('ExpiringPremium');
  
    this.accessGroup = this.localStorDta.AccessGroup;
    this.userRole = this.localStorDta.UserRole;
    this.userType = this.localStorDta.UserType;
    // this.productCode = localStorage.getItem('ProductCode');
    this.productCode = 'DGSME';
    console.log(this.productCode);
    this.businessSegmentLoader=this.globalService.spinnerHide();
    this.emiratesmallLoader=this.globalService.spinnerHide();
    this.issuepolicyLoader=this.globalService.spinnerHide();
    this.pageLoader=this.globalService.spinnerHide();
    this.quotationloader=this.globalService.spinnerHide();
    this.quoteLoader=this.globalService.spinnerHide();
    this.userId = this.localStorDta.User_ID;
    this.smeQuoteDetails = this.formBuilder.group({
      partner:['',Validators.required],
      branch:['',Validators.required],
      businessName:['',Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9\s!@#$%^&.,-/()*]+$/)])],
      businessArea:['',Validators.required],
      industryType : ['',Validators.required],
      businessSegment: ['',Validators.required],
      // Address: ['',Validators.required],
      // insuringProperty:  ['',Validators.required],
      businessYear: [''],
      construction: ['',Validators.required],
      businessPremises: ['',Validators.required],
      AnnualBusiness: ['',Validators.required],
      buildingCladding: ['',Validators.required],
      NoOfFloors: ['',Validators.required],
      claimHistory: ['',Validators.required],
      emirates: ['',Validators.required],
      emirateMall: [''],
      riskSelect:['',Validators.required],
    });

    this.planDetailForm = this.formBuilder.group({
      quote_plan_id: ['',Validators.required],
      loading_per:[0],
      discount_per:[0],
      promo_code : ''
    
    });
    
    this.additionalDetails = this.formBuilder.group({
      entityName:['',Validators.required],
      contactperson:['',Validators.required],
      mortgageecheck:['',Validators.required],
      vatRegcheck:['',Validators.required],
      mortgagee:['',Validators.required],
      tradeLicNumber:['',Validators.required],
      companyAddress : ['',Validators.required],
      // companyAddressGeoLocation : ['',Validators.compose([Validators.required,Validators.minLength(8)])],
      companyAddressGeoLocation : [''],
      poboxNo: ['',Validators.required],
      phoneNumber: ['',Validators.compose([Validators.required,Validators.minLength(9), Validators.maxLength(9), Validators.pattern(/^((02|03|04|06|07|08|09){1}([0-9]{7}))$/)])],
      startDate:  [this.minDate,Validators.required],
  
      email: ['',Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+)[.]{1}[a-zA-Z]{2,}$')])],
      trnNo: [''],
      // mobNo: ['',Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/)])],
      freezzone: ['',Validators.required],
    });
    // this.checkUserAccess();
    // this.getQuotationFormData();
    // this.getfloorCount();
    // this.getannualover();
    // this.getBusinessArea();
    // this.getcliamhistory();
    // this.getConstClass();
    // this.getIndSegment();
    // this.getZone();
    // this.getBusinessPremise();
    // this.discountRange();
    // this.getemirates();

    this.checkUserAccess();
    this.getQuotationFormData();
    if(!this.InitiatedQuote){
     
      this.getfloorCount();
      this.getannualover();
      this.getBusinessArea();
      this.getcliamhistory();
      this.getConstClass();
      this.getIndSegment();
      this.getBusinessPremise();
      this.discountRange();
      this.getemirates();
    }

    this.getZone();
    const routeParams = this._activatedroute.snapshot.params;
    console.log(routeParams.quoteNumber)
    if(routeParams.quoteNumber){
      // alert(1)
     this.pageLoader=this.globalService.spinnerShow();
     setTimeout(() => {
      // this.policyNumber = routeParams.policyNumber;
      this.checkId = routeParams.ID;
      // console.log(this.checkId);
      if(this.checkId != ''){
       this.quoteNumber = routeParams.quoteNumber;
       this.ButtonName ="Update Quote";
       this.policyType = "Update";
       this.getQuoteDetails();
      }
     }, 1000);
          
          
     }
     if(routeParams.policyNumber){
      this.pageLoader=this.globalService.spinnerShow();
      setTimeout(() => {
        this.policyNumber = routeParams.policyNumber;
      this.ButtonName ="Update Quote";
      this.policyType = "RENEWAL";
      this.getEditData();
    }, 1000);
     }
  
    // this.smeQuoteDetails.controls['businessYear'].setValidators([Validators.required]);
    if(this.InitiatedQuote){
      this.GetInitiatedPolicyDetails(routeParams.quoteNumber);
      }
  }
  get f() { return this.smeQuoteDetails.controls; }
  getfloorCount(){
    this.smequoteService.getfloorcount(this.partnerID).subscribe(res=>{

      this.noofFloor =res.Quotations;
      
    })
  }

  setupYears() {
    const currentYear = new Date().getFullYear();
    const startYear = 1951; 
    const endYear = currentYear;
    // const endYear = currentYear + 50; 
    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year);
    }
  }

  onYearSelected(year: number) {
    this.selectedYear = year;
    console.log('Selected Year:', this.selectedYear);
  }

  getannualover(){
    this.smequoteService.getannualturnover(this.partnerID).subscribe(res=>{

      this.turnOver =res.Quotations;
      
    })
  }
  getBusinessArea(){
    this.smequoteService.getbusinessarea(this.partnerID).subscribe(res=>{

      this.bArea =res.Quotations;
      
    })
  }
  getcliamhistory(){
    this.smequoteService.getClaimHistory(this.partnerID).subscribe(res=>{

      this.claimHistory =res.Quotations;
      
    })
  }
  getemirates(){
    this.smequoteService.getEmirates(this.partnerID).subscribe(res=>{

      this.emirates =res.Quotations;
      
    })
  }
  getConstClass(){
    this.smequoteService.getConstructionClass(this.partnerID).subscribe(res=>{

      this.conclass =res.Quotations;
      
    })
  }
  getIndSegment(){
    this.smequoteService.getIndustrySegment(this.partnerID, this.productCode).subscribe(res=>{

      this.indSegment =res.Quotations;
      
    })
  }
  getBusinessPremise(){
    this.smequoteService.getbusinesspremise(this.partnerID).subscribe(res=>{

      this.bussPremise =res.Quotations;
      
    })
  }
  getBusinessSegment(data){
    console.log(data)
    this.businessSegmentLoader=this.globalService.spinnerShow();
    this.smequoteService.getBusinesSegment(this.partnerID,this.smeQuoteDetails.value.industryType.IndustryId, this.productCode).subscribe(res=>{
      if(res.res_code == 1){
        this.busseg =res.Quotations;
        this.businessSegmentLoader=this.globalService.spinnerHide();
            if(data != ''){
              var businesssegIndex =  this.busseg.findIndex((obj,key)=>{
                return obj.BusinessName == data
              })
              console.log(businesssegIndex)
              this.smeQuoteDetails.get('businessSegment').setValue(this.busseg[businesssegIndex])
            }
      }
      
      console.log(this.busseg)
    })
   
    
  }

  getemiratesmall(data){
    // console.log(this.smeQuoteDetails.value.emirates.Id);
    // console.log(data);
    if(data == '0'){
       this.emiId = null;
    }else{
      this.emiId = this.smeQuoteDetails.value.emirates.Id;;
    }
    this.emiratesmallLoader=this.globalService.spinnerShow();
    this.smequoteService.getemiratemall(this.partnerID,this.emiId).subscribe(res=>{
      console.log(res);
      if(res.res_code == 1){
        this.emrmall =res.Quotations;
        this.emiratesmallLoader=this.globalService.spinnerHide();
            if(data != ''){
              var emiratesmallIndex =  this.emrmall.findIndex((obj,key)=>{
                return obj.Id == data
              })
              console.log(emiratesmallIndex)
              this.smeQuoteDetails.get('emirateMall').setValue(this.emrmall[emiratesmallIndex])
            }
      }
      
      console.log(this.emrmall)
    })
   
  }

  getQuotation(stepper){
    // console.log(this.smeQuoteDetails)
    // console.log(this.smeQuoteDetails.value.businessYear);
if(this.smeQuoteDetails.invalid){
  this.validtnMsg =true;
  this.smeQuoteDetails.markAllAsTouched();
  return false;
}
if(this.quoteStatus != 'REFERRED' && this.quoteStatus != 'REFAPPROVED' && this.quoteStatus != 'ADDITIONALINFO') {
  if(this.smeQuoteDetails.value.NoOfFloors.Id == '3'){
    this.quoteLoader=this.globalService.spinnerHide();
    Swal.fire("Check the coverage, This policy will not cover you if you are looking for a cover for the entire building which is having more than 10 floors with aluminium cladding/ any other combustible cladding.","However if you are looking for covering only a few units within such building, then you can change no.of floors and proceed issuing policy from this portal", "error");
    return false;
  }
  // if(this.smeQuoteDetails.value.businessYear <= '1950' && this.smeQuoteDetails.value.insuringProperty.label == 'Yes'){
  //   this.quoteLoader=this.globalService.spinnerHide();
  //   Swal.fire("Invalid Year Please enter valid establishment year", "error");
  //   return false;
  // }
  }
this.quoteLoader=this.globalService.spinnerShow();
console.log(this.quoteNumber)
   let processtype = (this.quoteNumber == '' ) ? 'Insert':'Update';
    let QuoteParamArray=[{
      EntityName:this.smeQuoteDetails.value.businessName.replace(this.characterToReplace, this.replacementCharacter),
      industry:this.smeQuoteDetails.value.industryType,
      business:this.smeQuoteDetails.value.businessSegment,
      emiratesid:this.smeQuoteDetails.value.emirates,
      emiratemall:this.smeQuoteDetails.value.emirateMall,
      ConstructionClass:this.smeQuoteDetails.value.construction,
      Premises:this.smeQuoteDetails.value.businessPremises,
      Turnover:this.smeQuoteDetails.value.AnnualBusiness,
      estYear:this.smeQuoteDetails.value.businessYear,
      claimHistory:this.smeQuoteDetails.value.claimHistory,
      cladding:this.smeQuoteDetails.value.buildingCladding,
      businessArea:this.smeQuoteDetails.value.businessArea,
      floors:this.smeQuoteDetails.value.NoOfFloors,
      // riskLocation:this.smeQuoteDetails.value.Address,
      partnerId:this.smeQuoteDetails.value.partner,
      userId:this.userId,
      quotationNumber:this.quoteNumber,
      quoteId:this.quoteId,
      brokerId:this.smeQuoteDetails.value.partner,
      branchId:this.smeQuoteDetails.value.branch.Id,
      productCode: this.productCode,
      lob_code:'SM',
      processType : processtype ,
      policyType:this.policyType,
      source : 'B2B' ,
      isRiskInMall:this.smeQuoteDetails.value.riskSelect
    }]
    console.log(QuoteParamArray)
    this.checked =[];
     this.planDetailForm.get('quote_plan_id').setValue(null);
        // QuoteParamArray = {
        //     // cover_type :this.policyHolderForm.value.coverType,
	      //     // sub_cover_type :null,
	      //     // product_code :this.prodCode,
	      //     partner_id :this.partnerId
        // }
      this.smequoteService.getQuote(QuoteParamArray).subscribe(res=>{
        this.ButtonName ='Update Quote';
        this.isReferral = res.response_code;	
        this.referalRes = res.referralReason;	
        if(res.response_code == 3 || res.response_code == 1 || this.quoteStatus == 'REFERRED' || this.quoteStatus == 'ADDITIONALINFO'){
          this.quoteLoader=this.globalService.spinnerHide();
          this.quoteNumber = res.quotation_number;
          this.benefitData =res.benefitData;
          this.planData =res.planData;
          console.log(this.benefitData);
          console.log(this.planData);
          // this.baseprice = this.benefitData.FixedContribution;
          // console.log(this.baseprice);
          this.AdminFees =res.quotedetails[0].AdminFees;
          this.serviceTax =res.quotedetails[0].ServiceTax;
          // this.planslct = res.quotedetails[0].SelectedPlanId;
          if(res.quotedetails[0].SelectedPlanId == 13){
            this.planslct = res.quotedetails[0].SelectedPlanId;
            }
            else if(res.quotedetails[0].SelectedPlanId == 14){
              this.planslct = res.quotedetails[0].SelectedPlanId;
              }
              else if(res.quotedetails[0].SelectedPlanId == 15){
                this.planslct = res.quotedetails[0].SelectedPlanId;
                }


          this.planData.forEach((item,index) => {
            console.log(this.planslct);
            console.log(index);
            this.hideshowpar = false;
            this.Vat_Amount = (item.FixedContribution + this.AdminFees)* 0.05;
            this.totalPremium = item.FixedContribution + this.AdminFees + this.Vat_Amount;
            this.planData[index].Vat_Amount = this.Vat_Amount;
            this.planData[index].totalPremium = this.totalPremium;
            this.planData[index].FixedContribution =  this.planData[index].FixedContribution;
            this.planData[index].checked = false;
            if(this.planslct == 13){
            if (index === 0) {
              console.log(this.planData[index]);
              this.planData[index].checked = true;
              this.planDetailForm.get('quote_plan_id').setValue(item.checked);
              this.Vat_Amount1 = this.planData[index].Vat_Amount;
              this.totalPremium1 = this.planData[index].totalPremium;
              this.getPlanId(0,
                this.planData[index].PlanId,
                this.planData[index].PlanName,
                this.planData[index].FixedContribution,
                this.planData[index].Vat_Amount,
                15,
                this.planData[index].totalPremium);
            }
            else {
              this.planData[index].checked = false;
            }
          }
          if(this.planslct == 14){
            if (index === 1) {
              this.planData[index].checked = true;
              this.planDetailForm.get('quote_plan_id').setValue(item.checked);
              this.Vat_Amount2 = this.planData[index].Vat_Amount;
              this.totalPremium2 = this.planData[index].totalPremium;
              this.getPlanId(1,
                this.planData[index].PlanId,
                this.planData[index].PlanName,
                this.planData[index].FixedContribution,
                this.planData[index].Vat_Amount,
                15,
                this.planData[index].totalPremium);
            }
            else {
              this.planData[index].checked = false;
            }
          }
          if(this.planslct == 15){
            if (index === 2) {
              this.planData[index].checked = true;
              this.planDetailForm.get('quote_plan_id').setValue(item.checked);
              this.Vat_Amount3 = this.planData[index].Vat_Amount;
              this.totalPremium3 = this.planData[index].totalPremium;
              this.getPlanId(2,
                this.planData[index].PlanId,
                this.planData[index].PlanName,
                this.planData[index].FixedContribution,
                this.planData[index].Vat_Amount,
                15,
                this.planData[index].totalPremium);
            }
            else {
              this.planData[index].checked = false;
            }
          }
            
          });
          // this.benefitData.forEach((element,index) => {
          //   this.benefitData[index]['SumCover']=element.DefaultSumCovered;       
          //   this.benefitData[index]['defaultCover']=element.DefaultSumCovered;       
          //   this.benefitData[index]['checked']=true;
          //   if( this.benefitData[index].ParentBenefitName == 'Property All Risks'){
          //     this.hideshowpar = false;

          //   }else if(this.benefitData[index].ParentBenefitName =='Money'){
          //     this.hideshowm = false;
          //   } else if(this.benefitData[index].ParentBenefitName == 'Public Liability'){
          //     this.hideshowpl = false;
          //   } else if(this.benefitData[index].ParentBenefitName == this.WorkmensName){
          //     this.hideshoww = false;
          //   }  else if(this.benefitData[index].ParentBenefitName == 'Fidelity Guarantee'){
          //     this.hideshowfg = false;
          //   }else if(this.benefitData[index].ParentBenefitName == 'Business Interruption'){
          //     this.hideshowbi = false;
          //   }else if(this.benefitData[index].ParentBenefitName == 'Machinery Breakdown'){
          //     this.hideshowmb = false;
          //   }else if(this.benefitData[index].ParentBenefitName == 'Electronic Equipment'){
          //     this.hideshowee = false;
          //   }
          // });
    
          stepper.next();
          console.log(this.hideshowpar + ','+this.hideshowm +','+this.hideshowpl+','+this.hideshoww +','+ this.hideshowfg+','+ this.hideshowbi+','+ this.hideshowmb+','+this.hideshowee)
          
          this.premiumCalucation();
          this.getWordDoc();

        }
        // else if(this.smeQuoteDetails.value.NoOfFloors.Id == '3'){
        //   this.quoteLoader=this.globalService.spinnerHide();
        //   this.quoteNumber = res.quotation_number;
        //   Swal.fire("Check the coverage, This policy will not cover you if you are looking for a cover for the entire building which is having more than 10 floors with aluminium cladding/ any other combustible cladding. However if you are looking for covering only a few units within such building, then you can proceed issuing policy from this portal", "error");
        // }
        else {
          this.quoteLoader=this.globalService.spinnerHide();
          this.quoteNumber = res.quotation_number;
          this.refferalButton =true;
          //   Refferal notificattion email to be sent
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
        }
   
       
      })

  
   
   
  }

  changeselect(checked,i) {
    this.planData.forEach((item,index) => {
      if(index == i){
      item.checked = true;
    }else{
      item.checked = false;
    }
       });
  }

  refQuotation(){
    if(this.smeQuoteDetails.invalid){
  this.validtnMsg =true;
  this.smeQuoteDetails.markAllAsTouched();
  return false;
}
    let QuoteParamArray=[{
      EntityName:this.smeQuoteDetails.value.businessName.replace(this.characterToReplace, this.replacementCharacter),
      industry:this.smeQuoteDetails.value.industryType,
      business:this.smeQuoteDetails.value.businessSegment,
      emiratesid:this.smeQuoteDetails.value.emirates,
      emiratemall:this.smeQuoteDetails.value.emirateMall,
      ConstructionClass:this.smeQuoteDetails.value.construction,
      Premises:this.smeQuoteDetails.value.businessPremises,
      Turnover:this.smeQuoteDetails.value.AnnualBusiness,
      estYear:this.smeQuoteDetails.value.businessYear,
      claimHistory:this.smeQuoteDetails.value.claimHistory,
      cladding:this.smeQuoteDetails.value.buildingCladding,
      businessArea:this.smeQuoteDetails.value.businessArea,
      floors:this.smeQuoteDetails.value.NoOfFloors,
      riskLocation:this.smeQuoteDetails.value.Address,
      partnerId:this.smeQuoteDetails.value.partner,
      userId:this.userId,
      quotationNumber:this.quoteNumber,
      quoteId:this.quoteId,
      brokerId:this.smeQuoteDetails.value.partner,
      branchId:this.smeQuoteDetails.value.branch.Id,
      productCode: this.productCode,
      lob_code:'SM',
      processType : 'Insert' ,
      policyType:this.policyType,
      source : 'B2B' 
    }]
      this.smequoteService.getQuote(QuoteParamArray).subscribe(res=>{
        // this.ButtonName ='Update Quote';
          // this.quoteLoader=this.globalService.spinnerHide();
          this.quoteNumber = res.quotation_number;             
      })
 
  }

  //---------VALIDATION FOR NUMBERS & DECIMAL NUM------------//
  // checkValidInputData(event: any,type)
  // {
  //     this.globalService.checkValidInputData(event,type);
  // }
  back(stepper){
    stepper.previous()
  }

  checkValidation(){
    if(this.additionalDetails.value.mortgageecheck.label == 'No'){
      this.madFileds = "";
      this.additionalDetails.controls['mortgagee'].setValidators(null);
      this.additionalDetails.controls['mortgagee'].updateValueAndValidity()
    }else{
      this.madFileds = "*";
      this.additionalDetails.controls['mortgagee'].setValidators([Validators.required]);
      this.additionalDetails.controls['mortgagee'].updateValueAndValidity()
    }
  }

  vatCheckValidation(){
    if(this.additionalDetails.value.vatRegcheck.label == 'No'){
      this.docList.forEach((element,index) => {
                if(element.DocumentName == 'VAT Certificate'){
                  element.MandatorySymbol = '';
                }
                if(element.DocumentName == 'No VAT Declaration Letter'){
                  element.MandatorySymbol = '*';
                }
      });
    }else{
      this.docList.forEach((element,index) => {
                if(element.DocumentName == 'VAT Certificate'){
                  element.MandatorySymbol = '*';
                }
                if(element.DocumentName == 'No VAT Declaration Letter'){
                  element.MandatorySymbol = '';
                }
      });
    }
  }

  GeoCheckValidation(){
    
    if(config.enableGeoLocValid == 'Y'){
      this.additionalDetails.controls['companyAddressGeoLocation'].setValidators([Validators.required]);
      this.additionalDetails.controls['companyAddressGeoLocation'].updateValueAndValidity()
    }else{
      this.additionalDetails.controls['companyAddressGeoLocation'].setValidators(null);
      this.additionalDetails.controls['companyAddressGeoLocation'].updateValueAndValidity()
    }

  if(this.showFL == true){
    this.showIcon = true;
  }else{
    this.showIcon = false;
  }
}

  // ---------------------------------------dropdown For Partner And Branch-------------------------------------------------
getQuotationFormData(){
  // this.quoteRes=this.globalService.spinnerShow();

  this.issuepolicyService.getQuotationFormData().subscribe(response =>{
    this.formDataRes = response;
    // this.quoteRes = this.globalService.spinnerHide();
    this.partnersArr = this.formDataRes.Partners;

    // if(this.quatationNo =='' ){
  //  console.log(this.partnerId)
      this.partnersArr.forEach((item,index)=>{
 
           if(item.PartnerId== this.partnerID){
             this.partnerVal  = item.PartnerId;
             
           }
          
      });
    //  console.log(this.partnerVal);
       this.smeQuoteDetails.get('partner').setValue(this.partnerVal);
       this.getPartnerBranchList();
  //  }
   

  });
  
}

// getPartnerBranchList(){
//   this.partnerBranchArr = [];
//   // this.loader=this.globalService.spinnerShow();
//   this.issuepolicyService.getpartnerBranch(this.smeQuoteDetails.value.partner).subscribe(res =>{
//     let updateRes:any = res;
//     // this.loader=this.globalService.spinnerHide();
//     this.partnerBranchArr = updateRes.branchList ;
//     this.branchId=updateRes.branchList[0];
//     //  branch
//     // if(this.quatationNo == ''){
//       this.partnerBranchArr.forEach((item,index)=>{
//         if(item.Id ==  this.branchId.Id){
//           this.branchVal  = item;       
//         }
//     });
//      this.smeQuoteDetails.get('branch').setValue(this.branchVal);
// // } 
//   });
// }
getPartnerBranchList(){
  this.partnerBranchArr = [];
  // this.loader=this.globalService.spinnerShow();
  this.issuepolicyService.getpartnerBranch(this.smeQuoteDetails.value.partner).subscribe(res =>{
    let updateRes:any = res;
    // this.loader=this.globalService.spinnerHide();
    updateRes.branchList=updateRes.branchList.filter(a=>a.BranchCode!='ONL')
    this.partnerBranchArr = updateRes.branchList;
    //UW changes -shuhaib
    if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
      const routeParams = this._activatedroute.snapshot.params;
      if(routeParams.quoteNumber){
        this.branchId=updateRes.branchList.filter(b=>b.Id==this.quotation_branchId)[0];
      }
      else{
        this.branchId=updateRes.branchList[0];
      }
     
      console.log('branches list',JSON.stringify(this.branchId));
      this.smeQuoteDetails.get('branch').setValue(this.branchId);
    }
    else{
      this.branchId=updateRes.branchList[0];
      this.partnerBranchArr.forEach((item,index)=>{
        if(item.Id ==  this.branchId.Id){
          this.branchVal  = item;          
        }
    });
    this.smeQuoteDetails.get('branch').setValue(this.branchVal);
    }


    //  branch
    // if(this.quatationNo == ''){

 
    
    

  

// }
    
 
    
  });
 // this.showLoader.load_data = false;
}

// getPartnerBranchList(){

//   this.partnerBranchArr = [];
//   // this.loader=this.globalService.spinnerShow();
//   this.issuepolicyService.getpartnerBranch(this.smeQuoteDetails.value.partner).subscribe(res =>{
//     let updateRes:any = res;
//     // this.loader=this.globalService.spinnerHide();
//     this.partnerBranchArr = updateRes.branchList ;
//     if (this.userRole == "TELEMARKETING" && this.businessSource == "DIRECT") {
//       this.branchId=updateRes.branchList.filter(b=>b.Id==this.quotation_branchId)[0];
//       console.log('branches list',JSON.stringify(this.branchId));
//       this.smeQuoteDetails.get('branch').setValue(this.branchId);
//     }
//     else{
//     this.branchId=updateRes.branchList[0];

//     //  branch
//     // if(this.quatationNo == ''){

//       this.partnerBranchArr.forEach((item,index)=>{
//         if(item.Id ==  this.branchId.Id){
//           this.branchVal  = item;
          
//         }
//     });
    
    

//      this.smeQuoteDetails.get('branch').setValue(this.branchVal);
//   }

// // }
    
 
    
//   });

// }
preventAllEvents(event: any)
{
    this.globalService.preventAllEvents(event);
}
getZone(){
  this.smequoteService.getfreezone(this.smeQuoteDetails.value.partner).subscribe(res=>{
    this.freezzone=res.freezones;
    this.additionalDetails.get('freezzone').setValue( this.freezzone[0])

  })
}
checksumCal(data,val,index){
  console.log(val)
  console.log(data.BenefitSI)
  this.benefitData[index].checked=true;
  val = val.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.benefitData[index].DefaultSumCovered = val;
    this.benefitData[index].defaultCover = val.replace(/,/gi, "");
   
    data.BenefitSI=data.BenefitSI.replace(/,/gi, "");val =val.replace(/,/gi, "");
    console.log( this.benefitData[index].DefaultSumCovered.replace(/,/gi, ""))
  if(parseInt(data.BenefitSI) < parseInt(val)){
    
    this.benefitData[index].DefaultSumCovered=data.SumCover.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    Swal.fire('Invalid Sum Insured',data.BenefitName+ ',' +'cannot be more than'+ ' ' +(data.BenefitSI).replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ","),'error');
    
  }
  this.premiumCalucation();
}
checkCheckboxSelection(value,index,item){
  console.log(value)
  console.log(this.benefitData)
  if(!value.checked){
    // alert(1)
    this.benefitData[index].checked=false;

    this.benefitData[index].DefaultSumCovered=0;
    this.premiumCalucation();

  }else{
    this.benefitData[index].DefaultSumCovered=item.SumCover.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.premiumCalucation();
  }
}

premiumCalucation(){
  this.sectionpremium_PAR=0;this.sectionpremium_Money=0;  this.sectionpremium_PL=0;  this.sectionpremium_WN=0; this.sectionpremium_FG=0; this.sectionpremium_BI=0;
  this.sectionpremium_MB=0;
  this.sectionpremium_EE=0;
  this.totalsumCovered=0;
  this.benefitData.forEach((element,index) => {
    if(  this.benefitData[index].DefaultSumCovered == 0){
      this.rowAmount = this.benefitData[index].DefaultSumCovered * this.benefitData[index].Rate /100;
      this.totalsumCovered += parseInt(element.DefaultSumCovered);
    }else{
      this.rowAmount = this.benefitData[index].DefaultSumCovered.replace(/,/gi, "") * this.benefitData[index].Rate /100;
      this.totalsumCovered += parseInt(element.DefaultSumCovered.replace(/,/gi, ""));
    }
// this.rowAmount = this.benefitData[index].DefaultSumCovered.replace(/,/gi, "") * this.benefitData[index].Rate /100;

// this.totalsumCovered += parseInt(element.DefaultSumCovered.replace(/,/gi, ""));
// console.log(this.rowAmount)
    if(this.benefitData[index].ParentBenefitName == 'Property All Risks'){
      
      this.sectionpremium_PAR += this.rowAmount;
      //console.log( this.sectionpremium_PAR)
      this.minsectionpremium_PAR = this.benefitData[index].MinimumContribution;
            if(this.benefitData[index].DefaultSumCovered == 0){
              this.benefitData[index].checked = false;
            }else{
              this.benefitData[index].DefaultSumCovered= this.benefitData[index].DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            
            }
         

    }else if(this.benefitData[index].ParentBenefitName == 'Money'){

      this.sectionpremium_Money += this.rowAmount;
      this.minsectionpremium_Money = this.benefitData[index].MinimumContribution;
              if(this.benefitData[index].DefaultSumCovered == 0){
                this.benefitData[index].checked = false;
              }else{
                this.benefitData[index].DefaultSumCovered=this.benefitData[index].DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
    }else if(this.benefitData[index].ParentBenefitName == 'Public Liability'){

      this.sectionpremium_PL += this.rowAmount;
      // console.log(this.sectionpremium_PL)
      this.minsectionpremium_PL = this.benefitData[index].MinimumContribution;
              if(this.benefitData[index].DefaultSumCovered == 0){
                this.benefitData[index].checked = false;
              }else{
                this.benefitData[index].DefaultSumCovered=this.benefitData[index].DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }

    }else if(this.benefitData[index].ParentBenefitName == this.WorkmensName){

      this.sectionpremium_WN += this.rowAmount;
      this.minsectionpremium_WN = this.benefitData[index].MinimumContribution;
                  if(this.benefitData[index].DefaultSumCovered == 0){
                    this.benefitData[index].checked = false;
                  }else{
                    this.benefitData[index].DefaultSumCovered=this.benefitData[index].DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }

    }else if(this.benefitData[index].ParentBenefitName == 'Fidelity Guarantee'){
      this.sectionpremium_FG += this.rowAmount;
      this.minsectionpremium_FG = this.benefitData[index].MinimumContribution;
              if(this.benefitData[index].DefaultSumCovered == 0){
                this.benefitData[index].checked = false;
              }else{
                this.benefitData[index].DefaultSumCovered= this.benefitData[index].DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }

    }else if(this.benefitData[index].ParentBenefitName == 'Business Interruption'){
      this.sectionpremium_BI += this.rowAmount;
      this.minsectionpremium_BI = this.benefitData[index].MinimumContribution;
              if(this.benefitData[index].DefaultSumCovered == 0){
                this.benefitData[index].checked = false;
              }else{
                this.benefitData[index].DefaultSumCovered=this.benefitData[index].DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
    }else if(this.benefitData[index].ParentBenefitName == 'Machinery Breakdown'){
      this.sectionpremium_MB += this.rowAmount;
      this.minsectionpremium_MB = this.benefitData[index].MinimumContribution;
            if(this.benefitData[index].DefaultSumCovered == 0){
              this.benefitData[index].checked = false;
            }else{
              this.benefitData[index].DefaultSumCovered=this.benefitData[index].DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
    }else if(this.benefitData[index].ParentBenefitName == 'Electronic Equipment'){
      this.sectionpremium_EE += this.rowAmount;
      this.minsectionpremium_EE = this.benefitData[index].MinimumContribution;
            if(this.benefitData[index].DefaultSumCovered == 0){
              this.benefitData[index].checked = false;
            }else{
              this.benefitData[index].DefaultSumCovered=this.benefitData[index].DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
    }
   
  });
console.log(this.benefitData)
// console.log(this.sectionpremium_PAR)
 
// console.log(1)
  if( this.sectionpremium_Money <= 0 && this.sectionpremium_PL > 0 && this.sectionpremium_PAR <= 0  && this.sectionpremium_WN <= 0 && this.sectionpremium_FG <= 0 && this.sectionpremium_BI <= 0 && this.sectionpremium_MB <= 0 && this.sectionpremium_EE <= 0){
    this.minsectionpremium_PL =400;
    // alert('PL selected')
  }
  else if( this.sectionpremium_Money <= 0 && this.sectionpremium_WN > 0 && this.sectionpremium_PAR <= 0 &&  this.sectionpremium_PL <= 0  && this.sectionpremium_FG <= 0 && this.sectionpremium_BI <= 0 && this.sectionpremium_MB <= 0 && this.sectionpremium_EE <= 0){
    this.minsectionpremium_WN =400;
    // alert('WM selected')
  }
  else if( this.sectionpremium_Money <= 0 && this.sectionpremium_PAR <= 0 &&  this.sectionpremium_PL > 0 && this.sectionpremium_WN > 0 && this.sectionpremium_FG <= 0 && this.sectionpremium_BI <= 0 && this.sectionpremium_MB <= 0 && this.sectionpremium_EE <= 0){
    this.minsectionpremium_PL =400;
    // alert('Both selected')
  }
console.log(this.sectionpremium_PAR)
console.log(this.sectionpremium_PL)
console.log(this.minsectionpremium_PL)
  if( this.sectionpremium_PAR > 0 &&  this.sectionpremium_PAR < this.minsectionpremium_PAR){
    //  alert(1)
      this.sectionpremium_PAR = this.minsectionpremium_PAR;
    }
     if(this.sectionpremium_Money > 0 &&  this.sectionpremium_Money < this.minsectionpremium_Money){
      this.sectionpremium_Money =this.minsectionpremium_Money;
  
    }
     if(this.sectionpremium_PL > 0 &&  this.sectionpremium_PL < this.minsectionpremium_PL){
      this.sectionpremium_PL =this.minsectionpremium_PL;
  
    }
     if(this.sectionpremium_WN > 0 &&  this.sectionpremium_WN < this.minsectionpremium_WN){
      this.sectionpremium_WN =this.minsectionpremium_WN;
  
    }
     if(this.sectionpremium_FG > 0 &&  this.sectionpremium_FG < this.minsectionpremium_FG){
      this.sectionpremium_FG =this.minsectionpremium_FG;
  
    }
     if(this.sectionpremium_BI > 0 &&  this.sectionpremium_BI < this.minsectionpremium_BI){
      this.sectionpremium_BI =this.minsectionpremium_BI;
    }
     if(this.sectionpremium_MB > 0 &&  this.sectionpremium_MB < this.minsectionpremium_MB){
      this.sectionpremium_MB =this.minsectionpremium_MB;
    }
     if(this.sectionpremium_EE > 0 &&  this.sectionpremium_EE < this.minsectionpremium_EE){
      this.sectionpremium_EE =this.minsectionpremium_EE;
    }

  // console.log( this.sectionpremium_PAR)
  console.log(this.sectionpremium_PAR, this.sectionpremium_Money , this.sectionpremium_PL,this.sectionpremium_WN,this.sectionpremium_FG,this.sectionpremium_BI,this.sectionpremium_MB,this.sectionpremium_EE)
  this.basePremium = this.sectionpremium_PAR + this.sectionpremium_Money + this.sectionpremium_PL + this.sectionpremium_WN + this.sectionpremium_FG +
                        this.sectionpremium_BI + this.sectionpremium_MB + this.sectionpremium_EE;
                        console.log( this.basePremium)
    this.claimRatio = (this.claimAmount/this.expiringPremium) * 100;
      console.log(this.claimRatio);
      if(this.claimRatio > 40 && this.claimRatio < 60){
        this.basePremium = this.basePremium + (this.basePremium * 0.1);
      }  
      
      if(this.claimRatio > 60 && this.claimRatio < 150){
        this.basePremium = this.basePremium + (this.basePremium * 0.5);
      }

                        this.totalPremiumCalculation();
}


totalPremiumCalculation(){
  this.Vat_Amount =0;
  // this.totalPremium =0;
  this.Vat_Amount = (this.baseprice + this.loadingAmount )* 0.05;

  // this.totalPremium = this.baseprice + this.Vat_Amount + this.policyFee +this.loadingAmount ;

}

getLoadingBy(){
  this.loadingAmount =0;
  console.log(this.loadingby)
  this.loadingAmount =  this.basePremium *(this.loadingby/100);
  this.totalPremiumCalculation();
}

getWordDoc(){
  this.smequoteService.getWordingDocuments('SM','B2B').subscribe(res=>{
    this.worDoc = res.wordingdocs;

  })
}
printDoc(value){
  window.open(value, "_blank");
}
getEditData(){
  this.smequoteService.getProcessSMERenewal('B2B', this.productCode,'eng',this.policyNumber,'RENQUOTE','RENEWAL',this.claimCount,this.claimAmount,this.expiringPremium).subscribe(res=>{

    let quotedetail = res.quotedetails[0];
    this.quoteId =quotedetail.QuotationId;
    this.quoteNumber =res.quotation_number;
    this.expiryDate = res.quotedetails[0].ExpiryDate;
    this.dtminDate = new Date(this.expiryDate);
    if(this.dtminDate > this.minDate){
      this.dtminDate.setDate(this.dtminDate.getDate() + 1);
      this.dtmaxDate = new Date(this.dtminDate);
      this.dtmaxDate.setDate(this.dtmaxDate.getDate() + 45);
          }
          else{
            this.dtminDate = new Date(this.minDate);
            this.dtmaxDate = new Date(this.dtminDate);
            this.dtmaxDate.setDate(this.dtmaxDate.getDate() + 45);
          }
    this.additionalDetails.get('startDate').setValue(this.dtminDate);
    // Partner Name
    
    this.smeQuoteDetails.get('businessArea').setValue(quotedetail.PartnerId);
   //underWriter changes for broker and branch- shuhaib
   console.log('this.partnerID',this.partnerID);
   if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
     this.smeQuoteDetails.get("partner").setValue(quotedetail.CedantBrokerId);
     this.quotation_branchId=quotedetail.BranchId;
   }

    // Branch 
    this.getPartnerBranchList()
    //underWriter changes for broker and branch- shuhaib
    console.log(this.userRole)
    if((this.userRole=='TELEMARKETING' || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource=='DIRECT'){     
      this.smeQuoteDetails.get('partner').setValue(quotedetail.CedantBrokerId);
      this.smeQuoteDetails.get('branch').setValue(quotedetail.BranchId);
    }
    this.smeQuoteDetails.get('businessName').setValue(quotedetail.BusinessEntityName);
    this.smeQuoteDetails.get('riskSelect').setValue(quotedetail.IsRiskInMall.trim());
    if(quotedetail.IsRiskInMall.trim()=='Y'){
      this.riskInMall=true;
      this.smeQuoteDetails.controls['emirateMall'].setValidators([Validators.required]);
      this.smeQuoteDetails.controls['emirateMall'].updateValueAndValidity();
    }
    else{
      this.riskInMall=false;
      this.smeQuoteDetails.controls['emirateMall'].setValidators(null);
      this.smeQuoteDetails.controls['emirateMall'].updateValueAndValidity();
    }
 // Business Area Name
 if(!this.InitiatedQuote){
    let businessArea =quotedetail.BusinessArea
    var businessIndex = this.bArea.findIndex((obj, k) =>{

      return obj.BusinessAreaName == businessArea;
    });
    this.smeQuoteDetails.get('businessArea').setValue(this.bArea[businessIndex]);
  }
      // Industry Name
      if(!this.InitiatedQuote){
    let industryName =quotedetail.IndustryName
    var industryIndex = this.indSegment.findIndex((obj, k) =>{

      return obj.IndustryName == industryName;
    });
    this.smeQuoteDetails.get('industryType').setValue(this.indSegment[industryIndex]);
  
    this.getBusinessSegment(quotedetail.BusinessName);
  }
    // emirate Name
    if(!this.InitiatedQuote){
    let regionName =quotedetail.EmiratesName
    var emirateIndex = this.emirates.findIndex((obj, k) =>{

      return obj.RegionName == regionName;
    });
    this.smeQuoteDetails.get('emirates').setValue(this.emirates[emirateIndex]);
  
    // this.getemiratesmall(quotedetail.MallId);
    if(regionName == null){
      this.getemiratesmall('0');
   }else{
    this.getemiratesmall(quotedetail.MallId);
   }
  }
    // this.smeQuoteDetails.get('Address').setValue(quotedetail.RiskLocationAddress);

    // Insuring Property
    // if(quotedetail.FloorsCount == '0'){
    //   this.smeQuoteDetails.get('insuringProperty').setValue(this.optionyesNo[1]);
    //   this.checkValidation();
    // }else{
    //   this.smeQuoteDetails.get('insuringProperty').setValue(this.optionyesNo[0]);
    //   this.checkValidation();
    if(!this.InitiatedQuote){
      this.smeQuoteDetails.get('businessYear').setValue(quotedetail.BusinessEstYear);
      let classconstruction =quotedetail.ConstructionClassName
      var constIndex = this.conclass.findIndex((obj, k) =>{
        return obj.ConstructionClassName == classconstruction;
      });
      this.smeQuoteDetails.get('construction').setValue(this.conclass[constIndex]);
      let bussinessPrem =quotedetail.BusinessPremise
      var premIndex = this.bussPremise.findIndex((obj, k) =>{
        return obj.BusinessPremise == bussinessPrem;
      });
      this.smeQuoteDetails.get('businessPremises').setValue(this.bussPremise[premIndex]);
      let annual =quotedetail.AnnualTurnover
      var annualIndex = this.turnOver.findIndex((obj, k) =>{
      return obj.AnnualTurnover == annual;
      });
      console.log(annualIndex)
      this.smeQuoteDetails.get('AnnualBusiness').setValue(this.turnOver[annualIndex]);
            if(quotedetail.PremisesWithCladding == 'N'){
              this.smeQuoteDetails.get('buildingCladding').setValue(this.optionyesNo[1]);          
          }else{
            this.smeQuoteDetails.get('buildingCladding').setValue(this.optionyesNo[0]);
          }
          let nooffloor =quotedetail.FloorsCount
          this.smeQuoteDetails.get('NoOfFloors').setValue(this.noofFloor[1]);
    //  }      
 // Claiming History
 if(!this.InitiatedQuote){
 let claimName =quotedetail.ClaimHistory
 var claimIndex = this.claimHistory.findIndex((obj, k) =>{

   return obj.ClaimHistory == claimName;
 });
 this.smeQuoteDetails.get('claimHistory').setValue(this.claimHistory[claimIndex]);
 this.getQuotationHistory(this.quoteNumber);
 this.pageLoader=this.globalService.spinnerHide();
}
else{
  this.pageLoader=this.globalService.spinnerHide();
}
    }
    else{
      this.pageLoader=this.globalService.spinnerHide();
    }
  })

}

getQuoteDetails(){
  this.smequoteService.getQuotationDetails('DGSME',this.quoteNumber,this.smeQuoteDetails.value.partner,this.source).subscribe(result=>{
    
    let quotedetail = result.quotedetails[0];
    this.quoteDocuments=result.quoteDocuments;	
            //WC document displaying logic- shuhaib	
          //   let wcBenefit=result.benefitData.filter(b=>b.CRS_Prod_Map_Name=="Workmens Compensation" && b.DefaultSumCovered!="0" )	
          //   console.log('wcBenefit:'+wcBenefit)	
          //  if(wcBenefit.length>0){	
          //  this.showWC=true;	
          //  }	
           	
    if(quotedetail.StatusDesc == 'REFAPPROVED' || quotedetail.StatusDesc == 'ADDITIONALINFO'){
      this.reapprove = true;
      }
      else{
        this.reapprove = false;
      } 

      if(quotedetail.StatusDesc == 'QUOTED' || quotedetail.StatusDesc == 'REFAPPROVED' || quotedetail.StatusDesc == 'RENQUOTE'){	
        this.buttonds = false;	
        }	
        else{	
          this.buttonds = true;	
        }
        this.claimCount =quotedetail.ClaimCount;
        this.claimAmount =quotedetail.ClaimAmount;
        this.expiringPremium =quotedetail.ExpiringPremium;
    this.quoteId =quotedetail.QuotationId;
    this.quoteNumber =result.quotation_number;
    this.quoteStatus =result.quotedetails[0].StatusDesc;
    this.additionalDetails.get('entityName').setValue(result.quotedetails[0].BusinessEntityName);
    // this.additionalDetails.get('companyAddress').setValue(result.quotedetails[0].RiskLocationAddress);
    this.expiryDate = result.quotedetails[0].ExpiryDate;
    this.dtminDate = new Date(this.expiryDate);
    if(this.dtminDate > this.minDate){
      this.dtminDate.setDate(this.dtminDate.getDate() + 1);
      this.dtmaxDate = new Date(this.dtminDate);
      this.dtmaxDate.setDate(this.dtmaxDate.getDate() + 45);
          }
          else{
            this.dtminDate = new Date(this.minDate);
            this.dtmaxDate = new Date(this.minDate);
            this.dtmaxDate.setDate(this.dtmaxDate.getDate() + 45);
          }
    this.additionalDetails.get('startDate').setValue(this.dtminDate);
    this.smeQuoteDetails.get('businessArea').setValue(quotedetail.PartnerId);
    this.smeQuoteDetails.get('riskSelect').setValue(quotedetail.IsRiskInMall.trim());
    // console.log(quotedetail);
    // Partner Name
 //underWriter changes for broker and branch- shuhaib
 console.log('partnerID',this.partnerID);
 console.log('BranchId',quotedetail.BranchId);
 if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
   this.smeQuoteDetails.get("partner").setValue(quotedetail.CedantBrokerId);
   this.quotation_branchId=quotedetail.BranchId;
 }
 else{
   this.smeQuoteDetails.get('partner').setValue(quotedetail.PartnerId);
 }
    // Branch 
    this.getPartnerBranchList()

    this.smeQuoteDetails.get('businessName').setValue(quotedetail.BusinessEntityName);
 // Business Area Name
 if(!this.InitiatedQuote){
    let businessArea =quotedetail.BusinessArea
    var businessIndex = this.bArea.findIndex((obj, k) =>{

      return obj.BusinessAreaName == businessArea;
    });
    this.smeQuoteDetails.get('businessArea').setValue(this.bArea[businessIndex]);
  }
      // Industry Name
      if(!this.InitiatedQuote){
    let industryName =quotedetail.IndustryName
    var industryIndex = this.indSegment.findIndex((obj, k) =>{

      return obj.IndustryName == industryName;
    });
    this.smeQuoteDetails.get('industryType').setValue(this.indSegment[industryIndex]);
  
    this.getBusinessSegment(quotedetail.BusinessName);
  }
      // emirate Name
      if(!this.InitiatedQuote){
    let regionName =quotedetail.EmiratesName
    var emirateIndex = this.emirates.findIndex((obj, k) =>{

      return obj.RegionName == regionName;
    });
    this.smeQuoteDetails.get('emirates').setValue(this.emirates[emirateIndex]);
    // this.getemiratesmall(quotedetail.MallId);
    if(regionName == null){
      this.getemiratesmall('0');
   }else{
    this.getemiratesmall(quotedetail.MallId);
   }
  }

    // this.smeQuoteDetails.get('Address').setValue(quotedetail.RiskLocationAddress);

    // Insuring Property
    // if(quotedetail.FloorsCount == '0'){
    //   this.smeQuoteDetails.get('insuringProperty').setValue(this.optionyesNo[1]);
    //   this.checkValidation();
    // }else{
    //   this.smeQuoteDetails.get('insuringProperty').setValue(this.optionyesNo[0]);
    //   this.checkValidation();
    if(!this.InitiatedQuote){
      this.smeQuoteDetails.get('businessYear').setValue(quotedetail.BusinessEstYear);
      let classconstruction =quotedetail.ConstructionClassName
      var constIndex = this.conclass.findIndex((obj, k) =>{
        return obj.ConstructionClassName == classconstruction;
      });
      this.smeQuoteDetails.get('construction').setValue(this.conclass[constIndex]);
      let bussinessPrem =quotedetail.BusinessPremise
      var premIndex = this.bussPremise.findIndex((obj, k) =>{
        return obj.BusinessPremise == bussinessPrem;
      });
      this.smeQuoteDetails.get('businessPremises').setValue(this.bussPremise[premIndex]);
      let annual =quotedetail.AnnualTurnover
      var annualIndex = this.turnOver.findIndex((obj, k) =>{
      return obj.AnnualTurnover == annual;
      });
      console.log(annualIndex)
      this.smeQuoteDetails.get('AnnualBusiness').setValue(this.turnOver[annualIndex]);
            if(quotedetail.PremisesWithCladding == 'N'){
              // alert(1)
              this.smeQuoteDetails.get('buildingCladding').setValue(this.optionyesNo[1]);          
          }else{
            this.smeQuoteDetails.get('buildingCladding').setValue(this.optionyesNo[0]);
          }
          let nooffloor =quotedetail.FloorsCount
      if(quotedetail.FloorsCount == '1'){
      // alert(1)
      this.smeQuoteDetails.get('NoOfFloors').setValue(this.noofFloor[0]);
      // this.checkValidation();
      }
      if(quotedetail.FloorsCount == '2'){
      // alert(1)
      this.smeQuoteDetails.get('NoOfFloors').setValue(this.noofFloor[1]);
      // this.checkValidation();
      }
      if(quotedetail.FloorsCount == '3'){
      // alert(1)
      this.smeQuoteDetails.get('NoOfFloors').setValue(this.noofFloor[2]);
      // this.checkValidation();
      }
    // }
 // Claiming History
 let claimName =quotedetail.ClaimHistory
 var claimIndex = this.claimHistory.findIndex((obj, k) =>{

   return obj.ClaimHistory == claimName;
 });
 this.smeQuoteDetails.get('claimHistory').setValue(this.claimHistory[claimIndex]);
 this.getQuotationHistory(this.quoteNumber);
 this.pageLoader=this.globalService.spinnerHide();
    }
    else{
      this.pageLoader=this.globalService.spinnerHide();
    }
  })
}

// ---------------------------------------Get Quotation History -----------------------------------------------
getQuotationHistory(quoteNumber){
  this.motorQuoteService.getQuotationHistory(quoteNumber,'SM').subscribe(res =>{
            if(res.response_code == 1){
                    this.quotationHistoryArr = res.quotationHistoryList;
            }
                

  });
}
//--------------------------------- SAVE QUOTEATION PLAN DETAIL ------------------------------
selectedPlanIndex = -1 ;
getPlanId(index,iD,planName,basePrimium,vatAmt,policyFee,totalPre){
  // alert(1);
  console.log(index);
  console.log(vatAmt);
        if(index == this.selectedPlanIndex){
              return false;
        }
 
  this.selectedPlanIndex = index ;
  this.planId = iD;
  this.planName = planName;
  this.FixedContribution = this.planData[index].FixedContribution;
  this.Vat_Amount = this.planData[index].Vat_Amount;
  this.Vat_Amount1 = this.planData[index].Vat_Amount;
  this.Vat_Amount2 = this.planData[index].Vat_Amount;
  this.Vat_Amount3 = this.planData[index].Vat_Amount;
  // this.policyFee = this.planData[index].AdminFees;
  this.totalPremium = this.planData[index].totalPremium;
  this.totalPremium1 = this.planData[index].totalPremium;
  this.totalPremium2 = this.planData[index].totalPremium;
  this.totalPremium3 = this.planData[index].totalPremium;
  this.planData[this.selectedPlanIndex].checked = true;
}

selectionChange(event){

  if(event.selectedIndex==0){

    this.policyHolderForm.get('otherForm').setValue('');
  }

  
}

saveQuotePlanDetail(stepper,type){
  if(this.basePremium == 0){
    Swal.fire("", "Basic Premium cannot be 0", 'error');
    return;
  }
  console.log(this.selectedPlanIndex);
  console.log(this.planData[this.selectedPlanIndex]);
  this.quotationloader=this.globalService.spinnerShow();
  let quotedetails=[{
    QuotationNumber:this.quoteNumber,
    partnerId:this.smeQuoteDetails.value.partner,
    industry:this.smeQuoteDetails.value.industryType,
      business:this.smeQuoteDetails.value.businessSegment,
      emiratesid:this.smeQuoteDetails.value.emirates,
      emiratemall:this.smeQuoteDetails.value.emirateMall,
      FireContribution:this.planData[this.selectedPlanIndex].FixedContribution,
      AllRiskContribution:0,
      OptionalContribution:0,
      FixedPlanContribution:this.planData[this.selectedPlanIndex].FixedContribution,
      FixedPlanId:this.planId,
      ServiceTax:this.serviceTax,
      ServiceTaxRate:0,
      policyFee:this.policyFee,
      TotalContribution:this.planData[this.selectedPlanIndex].totalPremium,
      VATAmount: this.planData[this.selectedPlanIndex].Vat_Amount,
      VATTaxRate:5,
      PremLoading:this.loadingAmount,
      PremLoadingRate:this.loadingby,
      productCode: this.productCode,
      lob_code:'SM',
      processType : 'INSERT' ,
      policyType:'NEW',
      source : 'B2B' 

  }]

  this.smequoteService.saveQuotationDetailsfixed(quotedetails,this.benefitData,this.planData).subscribe(res=>{
   
    if(type == 1){
      this.quotationloader=this.globalService.spinnerHide();
    Swal.fire('Quotation Saved Successfully','Your SME quotation has been saved successfully.','success');


    }else if(type == 2){
      console.log(this.isReferral);	
      this.quotationloader=this.globalService.spinnerHide();	
      
      this.claimRatio = (this.claimAmount/this.expiringPremium) * 100;
      console.log(this.claimRatio);
      if(this.claimRatio > 150){
        this.refReason = "The gross loss ratio is more than 150%the gross loss ratio is more than 150%";
        this.quoteLoader=this.globalService.spinnerHide();
        this.refferalButton =true;
        Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
        return false;
      }
      if(this.quoteStatus != 'REFERRED' && this.quoteStatus != 'REFAPPROVED' && this.quoteStatus != 'ADDITIONALINFO') {	
        if(this.claimCount > '3'){
          this.refReason = "The pervious claim count is" + this.claimCount;
          this.quoteLoader=this.globalService.spinnerHide();
          this.refferalButton =true;
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
          return false;
        }
        // if(this.smeQuoteDetails.value.emirateMall.MallName == 'Others'){
          if(this.smeQuoteDetails.value.emirateMall &&this.smeQuoteDetails.value.emirateMall.MallName === 'Others'){
          this.refReason = this.smeQuoteDetails.value.businessPremises.BusinessPremise;
          this.quoteLoader=this.globalService.spinnerHide();
          this.refferalButton =true;
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
          return false;
        }
        if(this.smeQuoteDetails.value.businessPremises.Id == '2'){	
          this.refReason = this.smeQuoteDetails.value.businessPremises.BusinessPremise;	
          this.quoteLoader=this.globalService.spinnerHide();	
          this.refferalButton =true;	
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");	
          return false;	
        }	
        if(this.smeQuoteDetails.value.AnnualBusiness.Id == '2'){	
          this.refReason = this.smeQuoteDetails.value.AnnualBusiness.AnnualTurnover;	
          this.quoteLoader=this.globalService.spinnerHide();	
          this.refferalButton =true;	
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");	
          return false;	
        }	
        if(this.isReferral == 3){	
          this.refReason = this.referalRes;	
          this.quoteLoader=this.globalService.spinnerHide();	
          this.refferalButton =true;	
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");	
          return false;	
        }	
        if(this.smeQuoteDetails.value.claimHistory.Id == '1'){
          if(this.policyType == "RENEWAL" || this.quoteStatus == 'RENQUOTE'){
          this.quoteLoader=this.globalService.spinnerHide();
          this.refferalButton =false;
          }else{
            this.refReason = this.smeQuoteDetails.value.claimHistory.ClaimHistory;
          this.quoteLoader=this.globalService.spinnerHide();
          this.refferalButton =true;
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
          return false;
          }
        }	
        }
      this.smequoteService.getQuotationDetails('DGSME',this.quoteNumber,this.smeQuoteDetails.value.partner,this.source).subscribe(result=>{
    
        let quotedetail = result.quotedetails[0];
        this.quoteId =quotedetail.QuotationId;
        this.quoteNumber =result.quotation_number;
        this.quoteStatus =result.quotedetails[0].StatusDesc;
        this.additionalDetails.get('entityName').setValue(result.quotedetails[0].BusinessEntityName);
        // this.additionalDetails.get('companyAddress').setValue(result.quotedetails[0].RiskLocationAddress);
      })
      this.smequoteService.getDocumentList('B2B','SM',99999,this.quoteNumber).subscribe(result=>{
        this.quotationloader=this.globalService.spinnerHide();
        this.docList =result.getDocumentsList;
        this.docList.forEach((element,index) => {
          this.docList[index].checked = false
          this.docList[index].file_name = '';
          
        });
        stepper.next();
      })
      
      this.checkPartnerCreditLimit()
      
    }else if(type == 3){
      let QuoteArray =[{
        productCode: this.productCode,
        lob_code:'SM',
        processType : 'INSERT' ,
        policyType:'NEW',
        source : 'B2B' 
      }]
      this.smequoteService.printsmepdf(QuoteArray,this.quoteNumber).subscribe(res=>{

        // res.pdfPath
        window.open( res.pdfPath, "_blank");
        this.quotationloader=this.globalService.spinnerHide();

      })
    }else if(type == 4){
      this.quotationloader=this.globalService.spinnerHide();
      let QuoteArray =[{
        productCode: this.productCode,
        lob_code:'SM',
        processType : 'INSERT' ,
        policyType:'NEW',
        source : 'B2B' 
      }]
      if(this.emailAdd == '' || null){
        this.emailaddValidation = true;
        return false;
      }else{
        let QuoteArray =[{
          productCode: this.productCode,
          lob_code:'SM',
          processType : 'INSERT' ,
          policyType:'NEW',
          source : 'B2B' 
        }]
        this.smequoteService.printsmepdf(QuoteArray,this.quoteNumber).subscribe(res=>{
  // alert('1');
  //         // res.pdfPath
  //         window.open( res.pdfPath, "_blank");
  //         this.quotationloader=this.globalService.spinnerHide();
  
        })
        this.emailaddValidation = false;
        this.smequoteService.emailSMEquote(QuoteArray,this.quoteNumber,this.emailAdd).subscribe(res=>{

          
  
          if(res.response_code == 1){
            Swal.fire("", "Thank you for choosing Fidelity United for SME. We have sent an email to your registered email with all the quotation details. Your Quotation Reference# " + this.quoteNumber, 'success');
          }
          // res.pdfPath
          // window.open( res.pdfPath, "_blank");
          // this.quotationloader=this.globalService.spinnerHide();
  
        })

      }
      
    }
   

  })
}
convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat);
  // console.log([ pad(d.getMonth()+1),pad(d.getDate()),d.getFullYear()])
  return ([ pad(d.getMonth()+1),pad(d.getDate()),d.getFullYear()].join('/'));
  
  }
  checkTermsCond(){
    this.accept_terms = !this.accept_terms;
}
checkDisclaimer(){
    this.accept_disclaimer = !this.accept_disclaimer;
}
// checkissuepolicy(type){
//   //DIGI Status check starts Feb2024
//   this.digi_status$.subscribe(trackText => {
//     this.digiSwalInstance= Swal.fire({
//       title: '',
//       html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,   
//       showConfirmButton: false
//     });
//   });
//   //this.digi_status.next("Almost there...");
//   var digiRequest={
//     "QuotationNumber":this.quoteNumber,
//     "FirstName": this.smeQuoteDetails.get('businessName').value,
//     "MiddleName": "",
//     "LastName": this.smeQuoteDetails.get('businessName').value,
//     "MatchCategory": "C",
//     "Nationality": "",
//     "DOB": "",
//     "CustomerType": "C",
//     "UserEmail":this.localStorDta.EmailAddress
//   };
// this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
//   console.log(JSON.stringify(res));
//   if(res==null ||res.data==null ){
//     Swal.fire("", "Failed to process the request. Please try again", 'error'); 
//   return;
//   }
//   else if(res.data.digiStatus=='Approved' ){
//     if (this.digiSwalInstance) {
//       this.digiSwalInstance.close();
//      }
//      this.docUploadFlag = true;
//      this.issuePolicy(type);
//   }
//   else {
//     if(config.enableDIGIApproval=="N"){
//       this.digi_status.next("Almost there...");
//       setTimeout(() => {
//         this.digi_status.next("Please do not close the browser or refresh...");
//     }, 2000);
//       setTimeout(() => {
//         if (this.digiSwalInstance) {
//           this.digiSwalInstance.close();
//          }
//         this.docUploadFlag = true;
//         this.issuePolicy(type);
//     }, 60000); 
//     }
//     else{
//       if (this.digiSwalInstance) {
//         this.digiSwalInstance.close();
//        }
//        this.issuepolicyLoader=this.globalService.spinnerHide();
//        Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
//        return;  
//     }

//   }
// });
//  //DIGI Status check End Feb2024

// }

checkissuepolicy(type){
  this.docUploadFlag = true;
  this.issuePolicy(type);
}


issuePolicy(type){
  console.log(1)
 
  this.docList.forEach((element,index) => {
    if(this.docUploadFlag == true){
            if(element.MandatorySymbol == '*'){
              
                  if(element.UploadedFileName == ''){
                    this.docUploadFlag = false;
                  }else{
                    this.docUploadFlag = true;
                  }
            }
  }
  });
  // if(this.docUploadFlag == false){
  //   Swal.fire("", "Please Upload Required Documents", 'error');
  //   return;
  // }
  if(this.additionalDetails.invalid){

    this.additionalDetails.markAllAsTouched();
    this.additionalDetails.updateValueAndValidity();
  Swal.fire("", "Please fill all the mandatory data", 'error');
  return;

}
else if(this.docUploadFlag == false){
   Swal.fire("", "Please Upload Required Documents", 'error');
    return;
}
else  if(this.accept_terms == false){
  Swal.fire("", "Please Select Terms And Conditions", 'error');
  return;
}
else  if(this.accept_disclaimer == false){
  Swal.fire("", "Please Select Disclaimer", 'error');
  return;
}
this.issuepolicyLoader=this.globalService.spinnerShow();

  let savePolicy=[{
    CompanyName:this.additionalDetails.value.entityName.replace(this.characterToReplace, this.replacementCharacter),
    ContactPerson:this.additionalDetails.value.contactperson,
    MortgageeText:this.additionalDetails.value.mortgagee,
    RegistrationNumber:this.additionalDetails.value.tradeLicNumber,
    TRNNumber:this.additionalDetails.value.trnNo,
    RiskLocation:this.additionalDetails.value.companyAddress,
    POBox:this.additionalDetails.value.poboxNo,
    PhoneNumber:this.additionalDetails.value.phoneNumber,
    // MobileNumber:this.additionalDetails.value.mobNo,
    EmailAddress:this.additionalDetails.value.email,
    freezone:this.additionalDetails.value.freezzone,
    CoverStartDate:this.convertDate(this.additionalDetails.value.startDate),
    PartnerId:this.smeQuoteDetails.value.partner,
    UserId:this.userId,
    FixedPlanContribution:this.FixedContribution,
      FixedPlanId:this.planId,
      ServiceTax:this.serviceTax,
      ServiceTaxRate:0,
      policyFee:this.policyFee,
      TotalContribution:this.totalPremium,
      VATAmount: this.Vat_Amount,
      VATTaxRate:5,
      PremLoading:this.loadingAmount,
      PremLoadingRate:this.loadingby,
    ProductCode: this.productCode,
    QuotationNumber:this.quoteNumber,
    PlanId:this.planId,
    CurrencyCode:'AED',
    source : 'B2B' ,
    AddressLatitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[0].trim().slice(0, 19) : '',
    AddressLongitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[1].trim().slice(0, 19) : ''
  }]
  //Added by Anju for party status active check on Issue Policy/Pay Now/Send Payment Link
  this.selectedPartnerId=this.smeQuoteDetails.value.partner;
  this.selectedBranchId = this.smeQuoteDetails.value.branch.Id; 
  
  this.smequoteService.checkPartner_CreditLimit("Property All Risk",0, this.selectedPartnerId,this.selectedBranchId).subscribe(response=>
  {
    if(response.cl_status=='200012'){
      Swal.fire('Party is not Active.');
      this.issuepolicyLoader=this.globalService.spinnerHide();
    }
    else
    {
  if(type == 1){
    //DIGI Status check starts Feb2024
  this.digi_status$.subscribe(trackText => {
    this.digiSwalInstance= Swal.fire({
      title: '',
      html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,   
      showConfirmButton: false
    });
  });
  //this.digi_status.next("Almost there...");
  var digiRequest={
    "QuotationNumber":this.quoteNumber,
    "FirstName": this.smeQuoteDetails.get('businessName').value,
    "MiddleName": "",
    "LastName": this.smeQuoteDetails.get('businessName').value,
    "MatchCategory": "C",
    "Nationality": "",
    "DOB": "",
    "CustomerType": "C",
    "UserEmail":this.localStorDta.EmailAddress
  };
this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
  console.log(JSON.stringify(res));
  if(res==null ||res.data==null ){
    Swal.fire("", "Failed to process the request. Please try again", 'error'); 
  return;
  }
  else if(res.data.digiStatus=='Approved' ){
    this.smequoteService.smeissuePolicy(savePolicy).subscribe(res=>{
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
      if(res.res_code == 1){
        this.policyNumber = res.policy_details[0].PolicyNumber;  
        this.getCreditDebitNote();
        this.issuepolicyLoader=this.globalService.spinnerHide();
        this._route.navigateByUrl("sme/smefixed/thankyou/" +  this.policyNumber +'/'+ this.quoteNumber);
  
      }
       
  
    })
  }
  else {
    if(config.enableDIGIApproval=="N"){
      this.digi_status.next("Almost there...");
      setTimeout(() => {
        this.digi_status.next("Please do not close the browser or refresh...");
    }, 2000);
      setTimeout(() => {
        this.smequoteService.smeissuePolicy(savePolicy).subscribe(res=>{
          if (this.digiSwalInstance) {
            this.digiSwalInstance.close();
           }
          if(res.res_code == 1){
            this.policyNumber = res.policy_details[0].PolicyNumber;  
            this.getCreditDebitNote();
            this.issuepolicyLoader=this.globalService.spinnerHide();
            this._route.navigateByUrl("sme/smefixed/thankyou/" +  this.policyNumber +'/'+ this.quoteNumber);
      
          }
           
      
        })
    }, 60000); 
    }
    else{
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
       this.issuepolicyLoader=this.globalService.spinnerHide();
       Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
       return;  
    }

  }
});
  }
  if(type ==2 ){
    //DIGI Status check starts Feb2024
  this.digi_status$.subscribe(trackText => {
    this.digiSwalInstance= Swal.fire({
      title: '',
      html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,   
      showConfirmButton: false
    });
  });
  //this.digi_status.next("Almost there...");
  var digiRequest={
    "QuotationNumber":this.quoteNumber,
    "FirstName": this.smeQuoteDetails.get('businessName').value,
    "MiddleName": "",
    "LastName": this.smeQuoteDetails.get('businessName').value,
    "MatchCategory": "C",
    "Nationality": "",
    "DOB": "",
    "CustomerType": "C",
    "UserEmail":this.localStorDta.EmailAddress
  };
this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
  console.log(JSON.stringify(res));
  if(res==null ||res.data==null ){
    Swal.fire("", "Failed to process the request. Please try again", 'error'); 
  return;
  }
  else if(res.data.digiStatus=='Approved' ){
    this.smequoteService.smesavePolicy(savePolicy).subscribe(res=>{
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
      this.policyNumber = res.quote_details[0].PolicyNumber;
          if(res.res_code == 1){
            let return_url = "http://localhost:4200/dashboard";
            let site_url = "http://localhost:4200/" + this._route.url;
              this.travelInsuranceService.paymentAuth(this.quoteNumber, site_url, return_url, 'SM', this.smeQuoteDetails.value.partner, this.policyNumber, 'FIXED', 'B2B').subscribe(res => {//Modified by Anju for SME Payment gateway revamp
                  if (res.status == 'Failed') {
                      Swal.fire(res.res_msg);
                      // this.policyLoader = this.globalService.spinnerHide();

                      return false;
                  }

                  else if (res.status == 'Success') {

                      // this.policyLoader = this.globalService.spinnerHide();
                      localStorage.setItem('Payment_Order_ID', res.data.tran_ref);
                      localStorage.setItem('Payment_Order_Tokan', res.data.token);
                      localStorage.setItem('Payment_Quotation_Number', this.quoteNumber);
                      localStorage.setItem('Payment_Quotation_ID', this.quoteId);
                      window.location.href = res.data.redirect_url;
                // window.open(res.result.redirect_url, '_blank');
             
      
              }

            })
          }
    })
  }
  else {
    if(config.enableDIGIApproval=="N"){
      this.digi_status.next("Almost there...");
      setTimeout(() => {
        this.digi_status.next("Please do not close the browser or refresh...");
    }, 2000);
      setTimeout(() => {
        this.smequoteService.smesavePolicy(savePolicy).subscribe(res=>{
          if (this.digiSwalInstance) {
            this.digiSwalInstance.close();
           }
          this.policyNumber = res.quote_details[0].PolicyNumber;
              if(res.res_code == 1){
                let return_url = "http://localhost:4200/dashboard";
                let site_url = "http://localhost:4200/" + this._route.url;
                  this.travelInsuranceService.paymentAuth(this.quoteNumber, site_url, return_url, 'SM', this.smeQuoteDetails.value.partner, this.policyNumber, 'FIXED', 'B2B').subscribe(res => {//Modified by Anju for SME Payment gateway revamp
                      if (res.status == 'Failed') {
                          Swal.fire(res.res_msg);
                          // this.policyLoader = this.globalService.spinnerHide();
    
                          return false;
                      }
    
                      else if (res.status == 'Success') {
    
                          // this.policyLoader = this.globalService.spinnerHide();
                          localStorage.setItem('Payment_Order_ID', res.data.tran_ref);
                          localStorage.setItem('Payment_Order_Tokan', res.data.token);
                          localStorage.setItem('Payment_Quotation_Number', this.quoteNumber);
                          localStorage.setItem('Payment_Quotation_ID', this.quoteId);
                          window.location.href = res.data.redirect_url;
                    // window.open(res.result.redirect_url, '_blank');
                 
          
                  }
    
                })
              }
        })
    }, 60000); 
    }
    else{
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
       this.issuepolicyLoader=this.globalService.spinnerHide();
       Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
       return;  
    }

  }
});
  }
  if(type == 3){
    //DIGI Status check starts Feb2024
  this.digi_status$.subscribe(trackText => {
    this.digiSwalInstance= Swal.fire({
      title: '',
      html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,   
      showConfirmButton: false
    });
  });
  //this.digi_status.next("Almost there...");
  var digiRequest={
    "QuotationNumber":this.quoteNumber,
    "FirstName": this.smeQuoteDetails.get('businessName').value,
    "MiddleName": "",
    "LastName": this.smeQuoteDetails.get('businessName').value,
    "MatchCategory": "C",
    "Nationality": "",
    "DOB": "",
    "CustomerType": "C",
    "UserEmail":this.localStorDta.EmailAddress
  };
this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
  console.log(JSON.stringify(res));
  if(res==null ||res.data==null ){
    Swal.fire("", "Failed to process the request. Please try again", 'error'); 
  return;
  }
  else if(res.data.digiStatus=='Approved' ){
    this.smequoteService.smesavePolicy(savePolicy).subscribe(res=>{
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
    this.motorQuoteService.sendPaymentLink(this.quoteNumber, 'SM',this.additionalDetails.value.email,'B2B','',this.smeQuoteDetails.value.partner).subscribe(res => {

      let payRes = res;
  
      if (payRes.res_code == 1) {
  
        Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.quoteNumber, 'success');
  
        this._route.navigate(['sme/products']);
  
      }
      // this.showLoader.Quotation = false;
    });
  })
  }
  else {
    if(config.enableDIGIApproval=="N"){
      this.digi_status.next("Almost there...");
      setTimeout(() => {
        this.digi_status.next("Please do not close the browser or refresh...");
    }, 2000);
      setTimeout(() => {
        this.smequoteService.smesavePolicy(savePolicy).subscribe(res=>{
          if (this.digiSwalInstance) {
            this.digiSwalInstance.close();
           }
        this.motorQuoteService.sendPaymentLink(this.quoteNumber, 'SM',this.additionalDetails.value.email,'B2B','',this.smeQuoteDetails.value.partner).subscribe(res => {
    
          let payRes = res;
      
          if (payRes.res_code == 1) {
      
            Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.quoteNumber, 'success');
      
            this._route.navigate(['sme/products']);
      
          }
          // this.showLoader.Quotation = false;
        });
      })
    }, 60000); 
    }
    else{
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
       this.issuepolicyLoader=this.globalService.spinnerHide();
       Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
       return;  
    }

  }
});
  }
}
}); 
}
//------------------------- CHECK USER ACCESS FOR PAGE ------------------------------------//
checkUserAccess(){
  this.motorQuoteService.checkUserAccess('SMEQUOTE',this.localStorDta.EmailAddress, 'SM').subscribe(res => {
    let response = res;
  
        if (response.userAccessData == 0) {                 // userAccessData = 1 ------ give access 
              Swal.fire('Sorry !','You can not authorised.','error');     
              this._route.navigateByUrl('/agentMotor/motorquote');   // userAccessData = 0 ------- access denied
               this.userAccess = false;
              return false;
        }else{
         
            this.userAccess = true;
        }
    });
}

getCreditDebitNote(){
  this.smequoteService.creditDebitNote("B2B",this.policyNumber).subscribe(res=>{
  })

}
annualBusinessChange(item){
  if(item.AnnualTurnover != 'Less than AED 400,000 per year'){
    this.additionalDetails.controls['trnNo'].setValidators([Validators.required]);
    this.additionalDetails.controls['trnNo'].updateValueAndValidity()

  }else{
    this.additionalDetails.controls['trnNo'].setValidators(null);
    this.additionalDetails.controls['trnNo'].updateValueAndValidity()
  }
}

checkNumber(value){
  // console.log(value)
  if(value > this.maxLoadingRange){
    this.loadingby = this.maxLoadingRange
  }
}

uploadCompanyDocument(event,i,AllowedExtensions){
  AllowedExtensions=AllowedExtensions.replaceAll(/\s/g,'');
  this.access =1;
  const formData = new FormData();
//  console.log(event.target.files[0].name);
  let ourEx =event.target.files[0].name.split('.')
  console.log(ourEx);
  // console.log(ourEx[1]);
  if(ourEx[1] == 'xls' || ourEx[1] == 'xlsx'){
    this.docList[i].checked =true;
    this.docList[i].file_name =event.target.files[0].name
    formData.append('file', event.target.files[0]);
    formData.append('LOBCode', 'SM');
    formData.append('QuotationNumber', this.quoteNumber);
    formData.append('FileExtn', ourEx[1]);
    this.smequoteService.uploadDocumentsXls(formData).subscribe(result=>{
      event.target.value = null;
      if(result.response_code == 2 ){
        Swal.fire("Please select valid file format.", "", 'error');
      this.docList[i].file_name ='';
      this.docList[i].checked =false;
      }
      
      this.benefitData.forEach((element,index) => {
        if(this.benefitData[index].BenefitName == 'As per UAE Labour Law with estimated annual wages not exceeding'){
          if(this.benefitData[index].DefaultSumCovered != 0){
            this.totalsalary = this.benefitData[index].DefaultSumCovered.replace(/,/gi, "");
          }else{
            this.totalsalary = 0;
          }
            } 
          });
 
      if(result.total_salary > this.totalsalary ){
        Swal.fire("Mismatch between annual wages on portal and the excel template, please correct the values.", "", 'error');
      this.docList[i].file_name ='';
      event.target.value = null;
      this.docList[i].checked =false;
      this.smequoteService.deleteEmployeeList(this.quoteNumber).subscribe(result=>{
        console.log(result);
      });
      }
      // console.log(result);
    })

  }
  else{
    formData.append('DocumentName', this.docList[i].DocumentName);
    formData.append('file', event.target.files[0]);
    // console.log(event.target.files[0]);
  // let allowedEx =event.target.files[0].name.split(',');
    formData.append('LOBCode', 'SM');
    formData.append('QuotationNumber', this.quoteNumber);
    formData.append('FileExtn', ourEx[1]);
  let allowedEx =AllowedExtensions.split(',');
  // console.log(allowedEx);
  // console.log('.'+ourEx[1].trim  ())

  allowedEx.forEach((element,index) => {
 if(this.access == 1){
  // console.log((allowedEx[index]).toLowerCase());
  // console.log('.'+(ourEx[1].trim()).toLowerCase());
      if((allowedEx[index]).toLowerCase() == '.'+(ourEx[1].trim()).toLowerCase() ){
        this.access =0;
      }else{
        this.access =1;
      }
 }
  
  });

  if(this.access == 1){
    Swal.fire("Please select valid file format.", "", 'error');
    return false;
  }
  this.smequoteService.uploadDocuments(formData).subscribe(result=>{
    console.log(result.response_code);
    if(result.response_code == 1){
      this.docList[i].checked =true;
      this.docList[i].UploadedFileName =result.image_url;
      this.docList[i].file_name =event.target.files[0].name;
      
      // this.companydocument = result.image_url;
      // this.documentCheckbox = true;
    }
    else if(result.response_code == 3){
      Swal.fire("Allowed upload file size is up to 2MB", "", 'error');
      this.docList[i].file_name ='';
      this.docList[i].checked =false;
      // this.companydocument = '';
      // this.documentCheckbox = false;
    }
    else if(result.response_code == 5){
      Swal.fire("Please select valid file format.", "", 'error');
      this.docList[i].file_name ='';
      this.docList[i].checked =false;
      // this.companydocument = '';
      // this.documentCheckbox = false;
    }else{
      this.docList[i].checked =false;
    }

  })

  }
  
}

uploadAddinfoDocument(event,i){	
  this.access =1;	
  const formData = new FormData();	
//  console.log(event.target.files[0].name);	
  let ourEx =event.target.files[0].name.split('.')	
  console.log(ourEx);	
    formData.append('DocumentName', 'Additional_Doc');	
    formData.append('file', event.target.files[0]);	
    console.log(event.target.files[0]);	
  // let allowedEx =event.target.files[0].name.split(',');	
    formData.append('LOBCode', 'SM');	
    formData.append('QuotationNumber', this.quoteNumber);	
    formData.append('FileExtn', ourEx[1]);	
  this.smequoteService.uploadDocuments(formData).subscribe(result=>{	
    console.log(event.target.files[0].name);	
    this.response_code = result.response_code;	
    if(result.response_code == 1){	
      this.UploadedFileName =result.image_url;	
      this.file_name =event.target.files[0].name;	
    }	
    else if(result.response_code == 3){	
      Swal.fire("Allowed upload file size is up to 2MB", "", 'error');	
      event.target.value = null;	
      this.file_name = '';	
    }	
    else if(result.response_code == 5){	
      Swal.fire("Please select valid file format.", "", 'error');	
      event.target.value = null;	
      this.file_name = '';	
    }else{	
      event.target.value = null;	
      this.file_name = '';	
    }	
  })	
}

termsAndConditions(frame){

  frame.show();

}

// ---------------------------------------------Range For Loading & Discount-----------------------------------------------

discountRange(){
  this.homeQuoteService.getPlanLoadingDiscount(this.productCode,'SM', this.smeQuoteDetails.value.partner,'1','ENG').subscribe(
    result=>{
      console.log(result);
      if(result.getPlanLoadingDiscount!=null){
        this.minLoadingRange=result.getPlanLoadingDiscount[0].PLAmount;
        this.maxLoadingRange=result.getPlanLoadingDiscount[0].PLPercent;
        this.loadingby =this.minLoadingRange;
      }      


    })
 
}
getReferalCondtion(ref_val){
// alert(1)
this.ref_value = ref_val;
  this.referalModel = true;
        if(ref_val == 'APPROVED'){
          this.refer_type = 'Approve';
          this.refer_condtion_type = 3;
        }

        if(ref_val == 'REJECTED'){
          this.refer_type = 'Reject';
          this.refer_condtion_type = 4;
        }

        if(ref_val == 'REFERRAL'){
          if (this.quoteNumber == ''){
            this.refQuotation();
          }
          this.refer_type = 'Refer';
          this.refer_condtion_type = 2;
        }

        if(ref_val == 'ADDITIONALINFO'){
          this.refer_type = 'Additional Info';
          this.refer_condtion_type = 5;
        }

}

// -------------------------------------------------Referal ---------------------------------------------------------

sendRefferMailToAdmin(type,refReason,frame) {	
  console.log(refReason)
  if (type == 1 || type == 2 ) {
// alert(1)
    if (this.referalDescription == '') {
      // alert(2)
      this.validtnMsgfordescription = true;
      return false;
    }
    this.validtnMsg = false;
    this.referal = true;
  }

  if (type == 3 || type == 4 || type == 5) {
      if (this.referalDescription == '') {
          this.validtnMsgfordescription = true;
          return false;
      }
      this.validtnMsgfordescription = false;
      this.referal = true;
  }


  let refer_type;
  let event_type;


  if (type == 1 || type == 2) {
    event_type = 'REFERQUOTE';        // REFER A QUOTE
    refer_type = type == 1 ? 'SYSTEM' : 'MANUAL';
    this.policyStatus = 'REFERED';
  }

  if (type == 3) {
      event_type = 'REFERAPPROVED';     // APPROVED
      refer_type = '';
      this.policyStatus = 'APPROVED';
  }

  if (type == 4) {
        event_type = 'REFERREJECTED';      // REJECTED
        refer_type = '';
        this.policyStatus = 'REJECTED';
  }

  if (type == 5) {
      event_type = 'ADDITIONALINFO';      // ADDITIONAL INFO
      refer_type = '';
      this.policyStatus = 'ADDITIONALINFO';
  }
// console.log(type)
// console.log(refer_type)
  this.smequoteService.getReferal(this.quoteNumber,refReason, this.referalDescription ,event_type, refer_type,this.additionalDocFile,'','').subscribe(res => {

    if (res.response_code == 1) {
      if (type == 2 || type == 1) {
        this.referal = false;
        Swal.fire('Your quote has been referred to our expert team of UWs for review.', 'You will be notified soon after the review. Your Quotation Reference# ' + this.quoteNumber, 'success');
        this._route.navigate(['sme/products']);
      }

      if(type == 3){
        Swal.fire('The quote has been successfully approved for the Quotation Number: ' + this.quoteNumber);
        this._route.navigate(['home/referalquote']);
      }
      if(type == 4){
        Swal.fire('The quote has been Rejected for the Quotation Number: ' + this.quoteNumber);
        this._route.navigate(['home/referalquote']);
      }
      if( type == 5 ) {	
        if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
          Swal.fire('The Additional Info has been requested for the Quotation Number: ' + this.quoteNumber);
        }
        else{
          Swal.fire('Quotation Number: ' + this.quoteNumber +' has been referred to our expert team of UWs for review.');
        }
        this._route.navigate(['home/referalquote']);	
            // this.getQuotePlan('');	
            // this.referalModel = false;         
      }

    }

  });
}

//------------------------------------------- UPLOAD ADDITIONAL DOCUMENTS ---------------------------------//
uploadAdditionalDoc(event) {

  this.additionalDoc = true;

  this.additionalDocFile = [];
for (var i = 0; i < event.target.files.length; i++) {

  const formData = new FormData();
  formData.append('file', event.target.files[i]);
  formData.append('stage', 'QUOTE_ADDINFO');
  formData.append('quotation_number', this.quoteNumber);

  this.motorQuoteService.uploadMultipleDocuments(formData).subscribe(res => {

  let updateMemResponse = res;
  this.document_data = updateMemResponse.Document_data;


  this.hideImages = true;

  if (updateMemResponse.res_code == 1) {
    let fileType = updateMemResponse.File.split(".");
    fileType = fileType[fileType.length - 1];
    fileType = fileType == "pdf" ? "PDF" : "IMG";

   
    this.additionalDocFile.push({
      "file": updateMemResponse.FileDir,
      "fileType": fileType,
      'file_name': 'Additional_Doc',
      'file_dir': updateMemResponse.FileDir,
      'docName': updateMemResponse.File,
    });

    this.tempAdditionalDoc = this.additionalDocFile;
  
      this.additionalDoc = false;
   
  }
  if (updateMemResponse.response_code == 5) {

      this.additionalDoc = false;
      Swal.fire("Please select valid file format.", "only .pdf file formats allowed.", 'error');

  }

  if (updateMemResponse.response_code == 6) {

      this.additionalDoc = false;
      Swal.fire(updateMemResponse.response_status);
  }

});
}
}

// ------------------------------------------Check Partner Credit Limit--------------------------------------------
async checkPartnerCreditLimit(){
  //Added by Anju for Partner Status Check & Credit Limit
  this.selectedPartnerId=this.smeQuoteDetails.value.partner;
  this.selectedBranchId = this.smeQuoteDetails.value.branch.Id
  // let result= this.smequoteService.checkPartnerCreditLimit("Property All Risk",this.sectionpremium_PAR,this.selectedPartnerId,this.selectedBranchId);
  // let data = JSON.stringify(result)
  // console.log(result);
 // this.checkcreditLimitArray.push({status:result['cl_status']});
  
  if( this.sectionpremium_PL != 0){
 
  let result=await this.smequoteService.checkPartnerCreditLimit("Property All Risk",this.sectionpremium_PAR,this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
   if( this.sectionpremium_Money != 0){
 
              let result=await this.smequoteService.checkPartnerCreditLimit("Money",this.sectionpremium_Money,this.selectedPartnerId,this.selectedBranchId);
              let data = JSON.stringify(result)
            console.log(result);
            this.checkcreditLimitArray.push({status:result['cl_status']})
  
  }
   if( this.sectionpremium_PL != 0){
 
  let result=await this.smequoteService.checkPartnerCreditLimit("Public Liability",this.sectionpremium_PL,this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_WN != 0){
  
  let result=await this.smequoteService.checkPartnerCreditLimit("Workmens Compensation",this.sectionpremium_WN,this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_FG != 0){
 
  let result=await this.smequoteService.checkPartnerCreditLimit("Fidelity Guarantee",this.sectionpremium_FG,this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_BI != 0){
  
  let result=await this.smequoteService.checkPartnerCreditLimit("Business Interruption",this.sectionpremium_BI,this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_MB != 0){
  
  let result=await this.smequoteService.checkPartnerCreditLimit("Machinery Breakdown",this.sectionpremium_MB,this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_EE != 0){
  
  let result=await this.smequoteService.checkPartnerCreditLimit("Electronic Equipment",this.sectionpremium_EE,this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  console.log('checkcreditLimitArray:', JSON.stringify(this.checkcreditLimitArray))
  const _CLStatus = this.checkcreditLimitArray.every((x) => x.status == "1");
  if(_CLStatus){
    //new API call changes for checking credit Days- shuhaib
   // let res_credit=await this.smequoteService.checkPartnerCreditDays(this.selectedPartnerId);
   // console.log('checkPartnerCreditDays response for selectedPartnerId: '+this.selectedPartnerId,JSON.stringify(res_credit));
    //if(res_credit['creditDays']>0){
      this.lmt_days =_CLStatus;
    //}
    //else{
    //  Swal.fire('Credit limit expired','Partner does not have sufficient Credit Days','error');
    //}
  }
  else{
    console.log('credit limit amount is exceeded')
   Swal.fire('Credit limit is exceeded','Partner does not have sufficient Credit limit','error');
  }

  // this.checkcreditLimitArray.forEach((element,index)=>{
  //   console.log( this.checkcreditLimitArray[index])
  //   if(this.chechfor == true){
  //     if(this.checkcreditLimitArray[index].status == "100010"){
  //       // alert(1)
  //       this.lmt_days =false;
  //       return
  
  //     }
      
  //   }else{
  //     this.chechfor =false;
  //   }
   
  //   console.log(  this.lmt_days)


  // })

} 


viewPlanBenefit(frame,planName){
   
  frame.show();
  this.isPlus=false;
  this.isGold=false;
  this.isPlatinum=false;
  let index = this.selectedPlanIndex ;
  if (planName=='Plus'){
    this.isPlus=true;
  }
  else if (planName=='Gold'){
    this.isGold=true;
  }
  else{
    this.isPlatinum=true;
  }


  this.displayBenefitData=this.benefitData;
  console.log(this.displayBenefitData);
  this.viewBenLoader = this.globalService.spinnerHide();
  // this.planDetailForm.get("quote_plan_id").setValue(index.toString());
  // console.log(this.planDetailForm.value.quote_plan_id);
  // this.plan_Id = this.contributionData[index].PlanId;
  // this.planName = this.contributionData[index].PlanName;
  // this.basePrimium = this.contributionData[index].FixedContributionTemp;
  // this.vatAmt = this.contributionData[index].VATAmount;
  // this.policyFee = this.contributionData[index].policyFee;
  // this.totalPre = this.contributionData[index].TotalContribution;
  
  //this.saveQuotePlanDetail("1");
 
 
  


}
openMap() {
  const factory = this.resolver.resolveComponentFactory(GeoLocationComponent);
  const modalComponentRef = this.modalContainer.createComponent(factory);
  modalComponentRef.instance.closeModalEvent.subscribe(() => {
    modalComponentRef.destroy();
  });
}
riskIntheMall(){
  this.riskInMall=this.smeQuoteDetails.value['riskSelect']=='Y'?true:false;
  
  if(!this.riskInMall){
    this.smeQuoteDetails.controls['emirateMall'].setValidators(null);
    this.smeQuoteDetails.controls['emirateMall'].updateValueAndValidity();
    this.smeQuoteDetails.get('emirateMall').setValue('');
  }
  else if(this.riskInMall){
    this.smeQuoteDetails.controls['emirateMall'].setValidators([Validators.required]);
    this.smeQuoteDetails.controls['emirateMall'].updateValueAndValidity();
    }
  }
  
async GetInitiatedPolicyDetails(quotationNumber){
  this.smequoteService.GetInitiatedPolicyDetails(quotationNumber,this.smeQuoteDetails.value.partner).subscribe(
    result=>{
      this.initiatedPolDocs=result.docs;       
      this.FixedContribution =result.data[0].BasePremWithLoading.replace(/,/g,"");
      this.Vat_Amount =result.data[0].ValueAddedTax.replace(/,/g,"");
      this.AdminFees =result.data[0].PolicyFees;
      this.loadingAmount =result.data[0].PremiumLoading;
      this.totalPremium =result.data[0].TotalContribution.replace(/,/g,"");

      this.additionalDetails.get('contactperson').setValue(result.data[0].ContactPerson);
      this.additionalDetails.get('contactperson').setValue(result.data[0].EmailAddress);
      this.additionalDetails.get('mortgagee').setValue(result.data[0].MortgageeText);
      this.additionalDetails.get('tradeLicNumber').setValue(result.data[0].RegistrationNumber);
      this.additionalDetails.get('trnNo').setValue(result.data[0].TRNNumber);
      this.additionalDetails.get('poboxNo').setValue(result.data[0].POBox);
      this.additionalDetails.get('phoneNumber').setValue(result.data[0].PhoneNumber);
      this.additionalDetails.get('companyAddress').setValue(result.data[0].RiskLocation);
     // this.additionalDetails.get('mobNo').setValue(result.data[0].MobileNumber);
      this.additionalDetails.get('email').setValue(result.data[0].EmailAddress);
      this.additionalDetails.get('trnNo').setValue(result.data[0].TRNNumber);
      if(this.initiatedPolDocs.filter(d=>d.DocType=='No VAT Declaration Letter').length>0){
        this.additionalDetails.get('vatRegcheck').setValue(this.optionyesNo[0])
      }
      else{
        this.additionalDetails.get('vatRegcheck').setValue(this.optionyesNo[1])
      }
      if(result.data[0].MortgageeText.length>0){
        this.additionalDetails.get('mortgageecheck').setValue(this.optionyesNo[0])
      }
      else{
        this.additionalDetails.get('mortgageecheck').setValue(this.optionyesNo[1])
      }
      //need to fix freezzone value
      this.additionalDetails.get('freezzone').setValue(this.freezzone[0])
      
    })
    //Need to modify the below code -shuhaib
    // let creditLimit:any= await this.smequoteService.checkPartnerCreditLimit("7",this.sectionpremium_Money,this.selectedPartnerId,this.selectedBranchId);
    // debugger;
    //   this.lmt_days=creditLimit.cl_status=='1'?true:false;
      this.lmt_days=true;
  }
  issueInitiatedpolicy(type){

    if(!this.additionalDetails.value.email){
  
     // this.additionalDetails.markAllAsTouched();
    //  this.additionalDetails.updateValueAndValidity();
    Swal.fire("", "Please fill all the mandatory data", 'error');
    return;
  
  }

  else  if(this.accept_terms == false){
    Swal.fire("", "Please Select Terms And Conditions", 'error');
    return;
  }
  else  if(this.accept_disclaimer == false){
    Swal.fire("", "Please Select Disclaimer", 'error');
    return;
  }
  this.issuepolicyLoader=this.globalService.spinnerShow();
  
    let savePolicy=[{
      CompanyName:this.additionalDetails.value.entityName.replace(this.characterToReplace, this.replacementCharacter),
      ContactPerson:this.additionalDetails.value.contactperson,
      MortgageeText:this.additionalDetails.value.mortgagee,
      RegistrationNumber:this.additionalDetails.value.tradeLicNumber,
      TRNNumber:this.additionalDetails.value.trnNo,
      RiskLocation:this.additionalDetails.value.companyAddress,
      POBox:this.additionalDetails.value.poboxNo,
      PhoneNumber:this.additionalDetails.value.phoneNumber,
      MobileNumber:this.additionalDetails.value.mobNo,
      EmailAddress:this.additionalDetails.value.email,
      FreeZone:this.additionalDetails.value.freezzone.value,
      CoverStartDate:this.convertDate(this.additionalDetails.value.startDate),
      PartnerId:this.smeQuoteDetails.value.partner,
      UserId:this.userId,
      // FixedPlanContribution:this.basePremium,
      //   FixedPlanId:this.planId,
      //   ServiceTax:this.serviceTax,
      //   ServiceTaxRate:0,
      //   policyFee:this.policyFee,
      //   TotalContribution:this.totalPremium,
      //   VATAmount: this.Vat_Amount,
      //   VATTaxRate:5,
      //   PremLoading:this.loadingAmount,
      //   PremLoadingRate:this.loadingby,
      ProductCode: this.productCode,
      QuotationNumber:this.quoteNumber,
      PlanId:this.planId,
      CurrencyCode:'AED',
      source : 'B2B' ,
      AddressLatitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[0].trim().slice(0, 19) : '',
     AddressLongitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[1].trim().slice(0, 19) : ''
    }]
    //Added by Anju for party status active check on Issue Policy/Pay Now/Send Payment Link
    this.selectedPartnerId=this.smeQuoteDetails.value.partner;
    this.selectedBranchId = this.smeQuoteDetails.value.branch.Id; 
    console.log('calling smequoteService.smesavePolicy savePolicy:'+JSON.stringify(savePolicy) )
    console.log('calling smequoteService.smesavePolicy type:'+type )
    this.smequoteService.checkPartner_CreditLimit("Property All Risk",0, this.selectedPartnerId,this.selectedBranchId).subscribe(response=>
    {
      if(response.cl_status=='200012'){
        Swal.fire('Party is not Active.');
        this.issuepolicyLoader=this.globalService.spinnerHide();
      }
      else
      {
        if(type == 1){
          localStorage.removeItem('Payment_Order_ID');
          localStorage.removeItem('Payment_Order_Tokan');
          localStorage.removeItem('Payment_Quotation_Number');
          localStorage.removeItem('Payment_Quotation_ID');
          this.smequoteService.UpdateInitiatedSMEPolicy(savePolicy).subscribe(res=>{
            if(res.res_code == 1){
              this.policyNumber = res.policy_details[0].PolicyNumber;  
              this.getCreditDebitNote();
              this.issuepolicyLoader=this.globalService.spinnerHide();
              this._route.navigateByUrl("sme/thankyou/" +  this.policyNumber +'/'+ this.quoteNumber);
             }
          });
        }
        if(type ==2 ){
          //this.smequoteService.UpdateInitiatedSMEPolicy(savePolicy).subscribe(res=>{
            this.policyNumber = this.policyNumber;
                // if(res.res_code == 1){
                  let return_url = "http://localhost:4200/dashboard";
                  let site_url = "http://localhost:4200/" + this._route.url;
                    this.travelInsuranceService.paymentAuth(this.quoteNumber, site_url, return_url, 'SM', this.partnerID, this.policyNumber, 'SHIELD', 'B2B').subscribe(res => {//Added by Anju for SME Payment gateway revamp
                        if (res.status == 'Failed') {
                            Swal.fire(res.res_msg);
                            this.issuepolicyLoader = this.globalService.spinnerHide();
  
                            return false;
                        }
  
                        else if (res.status == 'Success') {
  
                            // this.policyLoader = this.globalService.spinnerHide();
                            localStorage.setItem('Payment_Order_ID', res.data.tran_ref);
                            localStorage.setItem('Payment_Order_Tokan', res.data.token);
                            localStorage.setItem('Payment_Quotation_Number', this.quoteNumber);
                            localStorage.setItem('Payment_Quotation_ID', this.quoteId);
                            window.location.href = res.data.redirect_url;
                  // window.open(res.result.redirect_url, '_blank');
               
        
                }
  
              })
            //}
     // })
  
    }
    if(type == 3){
      // this.smequoteService.UpdateInitiatedSMEPolicy(savePolicy).subscribe(res=>{
      this.motorQuoteService.sendPaymentLink(this.quoteNumber, 'SM',this.additionalDetails.value.email,'B2B','',this.smeQuoteDetails.value.partner).subscribe(res => {
  
        let payRes = res;
    
        if (payRes.res_code == 1) {
    
          Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.quoteNumber, 'success');
    
          this._route.navigate(['sme/products']);
    
        }
        // this.showLoader.Quotation = false;
      });
  //  })
    }
     }
      }); 
  }
}

