import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class googleMapService {
  private locationEmitter: EventEmitter<string> = new EventEmitter<string>();
  setLocation(cords: string) {
    this.locationEmitter.emit(cords);
  }
  getLocation(): Observable<string> {
    return this.locationEmitter.asObservable();
  }
}
