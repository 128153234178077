import { UserauthService } from 'app/_service/userauth.service';
import { Component, OnInit, ViewChild,Inject, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { GlobalService } from 'app/_service/global.service';
import Swal from 'sweetalert2';
import { config } from "app/config";
import { ReCaptcha2Component } from 'ngx-captcha';


@Component({
  selector: 'app-adminproxy',
  templateUrl: './admin-proxy.component.html',
  styleUrls: ['./admin-proxy.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class AdminAccessibilityComponent implements OnInit {
  protected accessForm: FormGroup;
  localStorDta:any;
  constructor(public _userAuthService: UserauthService,
    private _router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public globalService: GlobalService) {
 
     }

  ngOnInit() {
    this.localStorDta =  this.globalService.getLocalStorageData();
    this.accessForm = this.formBuilder.group({
      userName:  ['',[Validators.required,Validators.minLength(4), Validators.maxLength(100)]]
     
    });
 


   
  }

  onSubmit(){
   if(this.accessForm.valid){
    this.proxyLogin();
    this._userAuthService.InsertUserActivity({
      
      UserId: this.localStorDta.User_ID,
      PageName: "ProxyLogin",
      AppUrl: "",
      EventName: "ImpersonateBroker",
      SectionName: "",
      EventDescription: `Admin logged-in as ${this.accessForm.value.userName}`,
      MachineName: "",
      OSVersion: "",
      Browser: "",
      MajorVersion:  "",
      MinorVersion:  "",
      AppPlatform:  "",
      Email:  "",
      Token:  "",
      IPAddress:  ""
    

  })
   }
  
  } 

  proxyLogin(){
    this._userAuthService.ProxyLogin({
          username : this.accessForm.value.userName,
          adminId:   this.localStorDta.User_ID}
          ).then((res:any) =>{
            console.log(res)
            if(res.status.statusCode == 1)
            {         
              this._router.navigate(["/dashboard"]);
            }
            else{
              Swal.fire("Sorry.You are not authorised !",'error')
            }

              
              
            })
          
          ///Add this function on Submit 
  }
  cancelAccess(){
    this._router.navigateByUrl('/dashboard');
  }

}
