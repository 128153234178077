import { Component, OnInit, Input,Output, ViewEncapsulation ,ViewChild,EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router,ActivatedRoute } from "@angular/router";
import { environment } from '../../../environments/environment';
import { UserauthService } from '../../_service/userauth.service';
import { GlobalService } from "../../_service/global.service";
@Component({
  selector: "app-basic-login",
  
  templateUrl: "./landing-auth-user.component.html",
  styleUrls: ["./landing-auth-user.component.scss"]
})
export class LandingAuthUserComponent implements OnInit {
 
  public _loginForm: FormGroup;
  public _formErrors: any;
  public _submitted: boolean = false;
  public _errorMessage: string = "";$msgErrr='';
 
  constructor(
    public _userauthService: UserauthService,public _globalService: GlobalService,
    private _router: Router,
    private _formBuilder: FormBuilder,private _activatedRoute: ActivatedRoute
  ) {}
    
  authToken:any;
    ngOnInit()
    {
      console.log(111)
      const routeParams = this._activatedRoute.snapshot.params;
      this.authToken=routeParams.authToken;
      this.projectTokenAuthorisation(); 
    }

    projectTokenAuthorisation(){
      this._submitted = true;
      this._userauthService.projecttokenAuthorisation(this.authToken).subscribe((res)=>{

        let  result=this._globalService.convertResponseInJson(res);
        if (result.status=='true') {
         this._router.navigate(["/dashboard"]);
        }
        else {
       
          this._errorMessage = "Invalid Token..";
          this._submitted = false;
          setTimeout(
            function() {
              this._errorMessage = " ";
             // window.location.href = 'https://www.google.com/';
              this._router.navigate(["/login"]);
            }.bind(this),
            2200
          );
        }
      });
    }

    
   
 
}
