import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { UserauthService } from "../_service/userauth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { GlobalService } from 'app/_service/global.service';
import { config } from "../config";
import Swal from 'sweetalert2';
import { ReCaptcha2Component } from 'ngx-captcha';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('captchaElem',{ static: false }) captchaElem :ReCaptcha2Component
  @ViewChild('langInput',{ static: false }) langInput :ReCaptcha2Component
  protected LoginForm: FormGroup;
  
  public captchaIsLoaded =  false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme :'light' | 'dark' = 'light';
  public size :'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type :'image' | 'audio'

  public siteKey = "6Leq8_woAAAAAHkfJDzIw_CIc_OFYMow5q93jPiX"
  public _submitted: boolean = false;
  public _errorMessage: string = "";
   loaderStatus: boolean = true;
  loginValidations: any;
  ValidLogin: boolean = true;
  IsInternalUser: any;
  validcaptcha: boolean = false;
  partnerId: any;
  partyCode: any;
  branchId: any;
  token: any;
  version: string;
  systemOS: any;
  browserName: any;
  majorVersion: any;
  ipAddress: any;
  uName: any;
  constructor(@Inject(DOCUMENT) public document: Document,public _userAuthService: UserauthService,private translate: TranslateService,
    private _router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public _globalService: GlobalService) { }

  ngOnInit() {

    this.LoginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
    this.getBrowserInfo();
  }

  getBrowserInfo() {
    const userAgent = this.document.defaultView.navigator.userAgent;
    
    this._userAuthService.IpInformation().then((response) => {
      let result = this._globalService.convertResponseInJson(response);
        console.log(result);
        this.ipAddress = result.ipAddress;
        
    })

    this._userAuthService.browserDetail().then((response) => {
      let result = this._globalService.convertResponseInJson(response);
        console.log(result);
        this.systemOS = result.systemOS;
        this.browserName = result.browserCode;
        this.majorVersion = result.majorVersion;
        this.majorVersion = result.minorVersion;
    })
  }
  

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  // Handle Recaptcha Success 
  handleSuccess(data) {
    if(data)
    {
      this.validcaptcha = true;
    }
    else{
      this.validcaptcha = false
    }
    console.log(data);
  }
  
  //Validate Login Messages 
  ValidateLogin(element:any)
  {
    if(element.status == "TRUE" && element.flagStop == 1)
    {
      
      this.ValidLogin = false
      return;  
    }
    else if(element.status == "TRUE" && element.flagStop == 0)
    {
      
      this.ValidLogin = true
      localStorage.setItem("ValidationMessage", element.statusMessage);
     
    }
    else{
      this.ValidLogin = true
     
    }

    return this.ValidLogin
  }

  forgetPass(){
    this._router.navigate(["/reset"], { queryParams: { forgetPassword: 'yes' } });
  }

  // On Login Form Submit 
  onSubmit(){
    // alert(1);
    if (this.LoginForm.status == "INVALID") {
      this.LoginForm.markAllAsTouched();
      Swal.fire("", "Please fill all Mandatory Fields", "error");
      return false;
    }
    
    this._userAuthService.ValidateLogin({
          username : this.LoginForm.value.userName,
          password: this.LoginForm.value.password}
          ).then((res:any) =>{
            console.log(res);
            debugger;
            if(res.status.statusCode == 1 && (res.result && Number(res.result.wrongPasswordAttempt))<3)
            {
              this.uName = res.result.userName;
              if(res.result.resetPassword == "True"){
                localStorage.setItem("oldPass", this.LoginForm.value.password);
                this._router.navigate(["/reset"], { queryParams: { TokenNumber: res.result.tokenNumber,ResetPasswordApprv: res.result.resetPassword,userName: this.LoginForm.value.userName } });
              }
              // else if (res.result.nextPasswordResetDate < res.result.lastLoggedInDate){
              else if (new Date(res.result.nextPasswordResetDate) < new Date()){
                localStorage.setItem("oldPass", this.LoginForm.value.password);
                this._router.navigate(["/reset"], { queryParams: { TokenNumber: res.result.tokenNumber,ResetPasswordApprv: 'True',userName: this.LoginForm.value.userName } });
                Swal.fire("", "Your Password has expired.  Please reset your password", "error");
              }           

                  
               
             
              else{

                if (res.result.nextPasswordResetDate && new Date(res.result.nextPasswordResetDate)> new Date()) {
                  const nextPasswordResetDate = new Date(res.result.nextPasswordResetDate);
                  const currentDate = new Date();
                
                  // Calculate the difference in milliseconds between the two dates
                  const timeDifference = nextPasswordResetDate.getTime() - currentDate.getTime();
                
                  // Calculate the difference in days
                  const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
                
                  if (daysDifference > 0 && daysDifference <= 5) {
                    const options: any = {
                      // title: 'Are you sure?',
                      text: `Your password will expire in ${daysDifference} days, do you want to change now?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes',
                      cancelButtonText: 'No'
                    };
                
                    Swal.fire(options).then((result: any) => {
                      if (!result.dismiss) {
                        localStorage.setItem("oldPass", this.LoginForm.value.password);
                        this._router.navigate(["/reset"], { queryParams: { TokenNumber: res.result.tokenNumber,ResetPasswordApprv: 'True',userName: this.LoginForm.value.userName } });
                         return;
                      } else {
                        this.proceedToLogin(res);
                      }
                    });
                  } 
                  else{
                    this.proceedToLogin(res);
                  }
                } else{
                  Swal.fire("", "Password is expired. Kindly reset your password.", "error");
                  localStorage.setItem("oldPass", this.LoginForm.value.password);
                  this._router.navigate(["/reset"], { queryParams: { TokenNumber: res.result.tokenNumber,ResetPasswordApprv: 'True',userName: this.LoginForm.value.userName } });
                   return;
                }
              
           
            }
             
            }
            else if(res.result && Number(res.result.wrongPasswordAttempt)>2){
              Swal.fire("", "Account is blocked due to wrong password attempts. Kindly contact IT support team.", "error");
              return false;
            }
            else if(res.status.statusCode == -1){
              Swal.fire("", "Please Enter Correct Username and Password", "error");
              return false;
            }
    
            else {
                  localStorage.clear();
                  window.location.href = config.logOutURL;
          
                  this._errorMessage = "Invalid Token..";
                  this._submitted = false;
                  this.loaderStatus = false;
                  // this.loaderStatus =  this._globalService.spinnerShow();
                  setTimeout(
                    function () {
                      this._errorMessage = "";
                    }.bind(this),
                    2200
                  );
                }
              
              
            })
          
          ///Add this function on Submit 
  }

  Internalcheck(){
    console.log(this.IsInternalUser);
    if(this.IsInternalUser == 'Y')
    {
      this._userAuthService.sendMail({
        To : this.LoginForm.value.userName,
        Subject : "Login",
        Body :"<h4>Dear " + this.uName + ",</h4><br><p>You have successfully logged in to the Portal.</p><br><p>If you have not performed this action, please notify us immediately.</p>"
      }).then((response1:any) =>{})
       this._userAuthService.loginAuth(this.token).then((response) => {
       let result = this._globalService.convertResponseInJson(response);
      //  debugger;
         localStorage.setItem("IsInternalAgent", result.data.IsInternalAgent);
         this._router.navigate(["/dashboard"]);
       })
    }
   //  ;
   else{
     this._userAuthService.checkpartystatus({
       PartnerId :this.partnerId,
       BranchId :this.branchId,
       LobCode :'XX'
     }).then((response1:any) =>{
       if(response1.status.statusCode == 1)
       {
         this._userAuthService.checkLoginValidation(response1.result[0]).then((validationresp:any)=>{
           if(validationresp.status.statusCode == 1)
           {
             this.loginValidations = validationresp.result
             for (const element of this.loginValidations) {
               if(!this.ValidateLogin(element))
               {
                 Swal.fire("You are not authorized. ",element.statusMessage,'error')
                 break;
               }
             }
             if(this.ValidLogin)
             {
              this._userAuthService.sendMail({
                To : this.LoginForm.value.userName,
                Subject : "Login",
                Body :"<h4>Dear " + this.uName + ",</h4><br><p>You have successfully logged in to the Portal.</p><br><p>If you have not performed this action, please notify us immediately.</p>"
              }).then((response1:any) =>{})

               this._userAuthService.loginAuth(this.token).then((response) => {
                 let result = this._globalService.convertResponseInJson(response);
                //  debugger;
                   localStorage.setItem("IsInternalAgent", result.data.IsInternalAgent);
                   this._router.navigate(["/dashboard"]);
               })
             }
            
           }

         })
        
       }

     })
   }
  }
  proceedToLogin(res:any){
    this.partnerId = res.result.partnerId,
    this.partyCode = res.result.partyCode,
    this.branchId = res.result.branchId,
    this.token = res.result.token
    console.log(res.result.resetPassword);
    localStorage.setItem("oldPass", this.LoginForm.value.password);
    localStorage.setItem("tokenNumber",  this.token);
    //send email post login
    //insert Useractivity
    this._userAuthService.InsertUserActivity({
      
        UserId: res.result.userId,
        PageName: "LOGIN",
        AppUrl: "",
        EventName: "PortalLogin",
        SectionName: "",
        EventDescription: "User logged-in to the insurance portal",
        MachineName: "",
        OSVersion: "",
        Browser: this.browserName,
        MajorVersion: this.majorVersion,
        MinorVersion: this.majorVersion,
        AppPlatform: this.systemOS,
        Email: res.result.emailAddress,
        Token: res.result.token,
        IPAddress: this.ipAddress
      

    }).then((res:any)=>{
      if(res.statusCode == 200)
      {
        // console.log("Access Group",res.result.AccessGroup)
        this._userAuthService.checkInternalUserLogin(
            this.partnerId,this.partyCode
        ).then((internalresp:any) =>{
          console.log(internalresp.result.isInternalUser);
          if(internalresp.result.isInternalUser == 'Y'){
            this.IsInternalUser = internalresp.result.isInternalUser;
            this.Internalcheck();
            }
            else{
              this.IsInternalUser = internalresp.result.isInternalUser;
              this.Internalcheck();
            }
        })
        //if user group is admin continue else checkparty status & then redirect 
        

      
      }
    })
  }

}


