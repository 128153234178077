import { Component, OnInit } from '@angular/core';
import { IssuepolicyService } from '../../../_service/issuepolicy.service';
import { GlobalService } from "../../../_service/global.service";
import { Router,ActivatedRoute } from '@angular/router';
import { MotorquoteService } from '../../../_service/motorquote.service';
import { TraveInsuranceService } from '../../../_service/traveInsurance.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-motorpaymentinvoice',
  templateUrl: './motorpaymentinvoice.component.html',
  styleUrls: ['./motorpaymentinvoice.component.scss']
})
export class MotorpaymentinvoiceComponent implements OnInit {

  public quoteNum:any; totalPremium :any; quoteDetail:any = []; paymentLoader:boolean = false; quote_Status:any; paymentMsgShow:boolean = false; retrieveData:any;toDayDate: Date;ExpLinkMsgShow: boolean =false;
  linkExpiredDate: any;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public issuePolicyService: IssuepolicyService,public motorQuoteService: MotorquoteService,public globalService:GlobalService,public travelInsuranceService:TraveInsuranceService) { }

  ngOnInit() {
    this.toDayDate = new Date(Date.now());
    console.log("today",this.convertStartDate(this.toDayDate))
    this.paymentLoader = this.globalService.spinnerHide();

    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){

      this.quoteNum = routeParam.QuotationNumber;

      }
      localStorage.setItem('pageStatus','SENDPAYMENTLINK');
      this.getMotorVehcleDetail();

  }

  async getMotorVehcleDetail(){
    this.motorQuoteService.getRetrieveQuote(this.quoteNum,'BTBPORTAL','PAYMENT').subscribe(res => {
              if(res.response_code == 1){
                console.log("today",this.convertStartDate(this.toDayDate))
                  this.retrieveData = res.quotationDetailsDataForCustomer;
                  let resData =  this.retrieveData[0];

                  this.totalPremium = resData.TotalPremium;
                  this.quote_Status = resData.StatusDesc;
                  this.linkExpiredDate = resData.PayLinkExpDt;
                  localStorage.setItem('Payment_Quotation_Number', this.quoteNum);

                    if(this.quote_Status == "ISSUED"){
                          this.paymentMsgShow = true;
                      }
                      if((this.quote_Status == "QUOTED" ||this.quote_Status == "BOUND" || this.quote_Status == 'TAKENUP' || this.quote_Status == 'QUOTECONFIRMED')  && this.convertStartDate(this.toDayDate) == this.convertStartDate(this.linkExpiredDate)){
                        console.log(this.convertStartDate(this.linkExpiredDate))
                        // this.ExpLinkMsgShow = true;
                        this.ExpLinkMsgShow = false;
                      }else{
                        // alert(1)
                        this.ExpLinkMsgShow = false;
                      }

              }
    });
    console.log("PAYMENT MSG",this.paymentMsgShow)
  }

  convertStartDate(inputFormat){
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    return ([pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/'));

  }

  async payTabPayment(){

    this.getMotorVehcleDetail(); // added by ruhina for policy issued or not before actual payment 
    //this.paymentMsgShow = false; // commented by ruhina 
    setTimeout(() => {
      
      if(!this.paymentMsgShow)
      {
       let return_url = "http://localhost:4200/dashboard";
       let site_url = "http://localhost:4200/" + this._route.url;
    
       this.paymentLoader = this.globalService.spinnerShow();
       this.travelInsuranceService.paymentAuth(this.quoteNum,site_url,return_url, 'MT', '1', '','','B2B').subscribe(res => {
       let payRes = res;
       console.log(payRes);
       if(payRes.status == 'Failed'){
         alert(payRes.res_msg);
         this.paymentLoader = this.globalService.spinnerHide();
         return false;
       }
   
       else if (payRes.status == 'Success') {
   
         this.paymentLoader = this.globalService.spinnerHide();
         localStorage.setItem('Payment_Order_ID',payRes.data.tran_ref);
         localStorage.setItem('Payment_Order_Tokan', payRes.data.token);
         localStorage.setItem('Payment_Quotation_Number',this.quoteNum);
         window.location.href = payRes.data.redirect_url;
   
   
       }
        
      
   },
   error => {
    console.log(error);
    const errorMessage = error.error.res_status;
    const errorCode = error.error.res_code;
    Swal.fire(errorMessage);
  });
  }
     
      //Modified by Anju for Payment gateway revamp
}, 1000);
      

 
 

  }

}
