import { Component, OnInit } from '@angular/core';
import { MotorquoteService } from '../../../_service/motorquote.service';
import { Router, ActivatedRoute } from '@angular/router';
import { adexchangebuyer2_v2beta1 } from 'googleapis';
import Swal from 'sweetalert2';
import { ReplaySubject, Subject } from "rxjs";
import { GlobalService } from '../../../_service/global.service';
import { TraveInsuranceService } from '../../../_service/traveInsurance.service';
import { IssuepolicyService } from 'app/_service/issuepolicy.service';
import { config } from 'app/config';

@Component({
  selector: 'app-approve-referral-quote',
  templateUrl: './approve-referral-quote.component.html',
  styleUrls: ['./approve-referral-quote.component.scss']
})
export class ApproveReferralQuoteComponent implements OnInit {

  private digi_status = new Subject<string>();
  digi_status$ = this.digi_status.asObservable();
  digiSwalInstance:any;
  insureType: string;
  IsTPL:any  = false 
  // DECLARE ARRAY 
  savePlanDetailArr: any = []; quoteDetail:any =[]; slectedOptBenefit:any = []; optionalBenefit:any = []; quotationHistoryArr:any = []; public termsAndCondition: any = [];

  // DECLARE VARIABLES 
  public webQuoteNum:any; TotalPremium:any; quoteNumber:any; emailId:any; pageLoader:boolean = true; BaseContribution:any;
  localStorageData: any; businessSource: any; partnerId: any; userAccessStatus:any; prevInsExpDate:any; quoteStatus:any; showLoader:boolean = false;
  public policyStatus: any; public showTerms: boolean = false; issuePolicyBtnAccess :boolean = false; onlinePayBtnAccess :boolean = false;

  public accept_terms: boolean = false; disable_btns:any;
  public referalModel:boolean = false ;
  public referalPopUp:boolean = false;
  public referalDescription:any = '';
  toDay = new Date(Date.now());
  refer_type: string;
  refer_condtion_type: number;
  validtnMsg: boolean;
  selectedPartnerId: any;//added by Anju for Partner Credit Limit check
  selectedBranchId: any;
  accept_disclaimer: boolean = false;
  product_code: any;
  constructor(public _route: Router, public issuepolicyService: IssuepolicyService, public _activatedroute: ActivatedRoute, public motorQuoteService: MotorquoteService, public globalService: GlobalService, public travelInsuranceService:TraveInsuranceService) { }
 

  ngOnInit() {

    this.localStorageData = this.globalService.getLocalStorageData();
    this.businessSource = this.localStorageData.BusinessSource;
    this.partnerId = this.localStorageData.Partner_ID;
      const routeParams = this._activatedroute.snapshot.params;

      if (routeParams.quoteNum) {
        this.webQuoteNum = routeParams.quoteNum;
      }

      this.getUserAccessPermission();
      this.getQuotationDetial();
      this.getQuotationDetailPdf();
     // this.checkAccessForPolicyIssueButtons()
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    return ([pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-'));

  }

  getQuotationDetial(){

    this.motorQuoteService.getRetrieveQuote(this.webQuoteNum, 'BTBPORTAL','').subscribe(res => {
      
          if (res.response_code == 1) {
            this.pageLoader = false;
            let responseData = res.quotationDetailsDataForCustomer;
            this.quoteDetail = responseData;
            this.TotalPremium = this.quoteDetail[0].TotalPremium;
            this.quoteNumber = this.quoteDetail[0].CRSQuoteNumber;
            this.emailId     = this.quoteDetail[0].InsuredEmail;
            this.slectedOptBenefit = this.quoteDetail[0].SelectedBenefits;
            this.prevInsExpDate = this.quoteDetail[0].InsuranceExpiryDate;
            this.quoteStatus = this.quoteDetail[0].StatusDesc;
            this.BaseContribution = this.quoteDetail[0].BaseContribution;
            this.disable_btns = this.quoteDetail[0].PolAll;
            this.selectedPartnerId=this.quoteDetail[0].CedantBrokerId;
            this.selectedBranchId=this.quoteDetail[0].CedantBrokerBranchId;
            this.product_code=this.quoteDetail[0].CRSPrdCd;

            this.getQuotationHistory(this.webQuoteNum);
            this.checkAccessForPolicyIssueButtons();
          }

          if( this.quoteStatus == 'ISSUED' ){
               Swal.fire("Policy Issue Error.","Policy might have already issued or is under process. Please go to View Policy and check or contact your relationship manager.", "error");
               this._route.navigate(['home/viewPolicy']);
          }
    });

  }


  getQuotationDetailPdf(){

    this.motorQuoteService.getQuoteDetailPdf(this.webQuoteNum).subscribe(res => {
      
      let optnBenefit;
      let plaBenefitData = res.planBenefitData;
    
      if(this.slectedOptBenefit.length != 0){
            optnBenefit = this.slectedOptBenefit.split(",")
    
          if (res.response_code == 1) {
                plaBenefitData.forEach((item,index)=>{
                      optnBenefit.forEach( index1 => {
                            if(item.BenefitId == index1){

                                   this.optionalBenefit.push(item);   
                            
                            }
                      });
                       
                });
          }

      }
    });
    
    
  }

  public paymentMode:string = "";
  getPolicy(frame){

    if (frame != '') {
      if (this.accept_terms == false) {
        return;
      }
      if (this.accept_disclaimer == false) {
        return;
      }

      frame.hide();
    }
 

    this.pageLoader = true;
        if( this.policyStatus == 'ISSUEPOLICY'){

              this.paymentMode = "CRD";
              localStorage.setItem('pageStatus', 'ISSUPOLICY');
              //Added Partner Credit Limit check by Anju
              this.motorQuoteService
              .checkPartnerCreditLimit("Motor",this.TotalPremium,this.selectedPartnerId,this.selectedBranchId)
              .subscribe((result) => {
                console.log(result);
              if(result.cl_status == 1){
              this.approvePolicyRequest(); 
              }   // for issue policy
              else{
                if(result.cl_status == '200012'){
                  Swal.fire("Party not active.");
                  }
                  else
                  {
                    Swal.fire("Party credit limit exceeded.");
                  }
                }
            });
          }
        

        if( this.policyStatus == 'PAYMENT'){
          this.paymentMode = "ONL";
             localStorage.setItem('pageStatus', 'PAYMENT');
             this.getPayment();    // for Payment
        }

        if( this.policyStatus == 'SENDPAYMENTLINK'){
              this.sendPaymentLink();    // for Send payment link
        } 

  }

//----------------------------------- APPROVE POLICY REQUEST -------------------------------------//
approvePolicyRequest() {
 
    this.motorQuoteService.approvePolicyRequestSummary(this.quoteNumber, this.webQuoteNum,'B2B',this.paymentMode).subscribe(res => {

      if (res.response_code == 1) {
        let polNum = res.approvePolicyRequest.PolNo;
        let policy_uid = res.approvePolicyRequest.PolicyUid;

        
        localStorage.setItem('Payment_Quotation_Number', this.webQuoteNum);
        localStorage.setItem('CRS_Quotation_Number',this.quoteNumber);

        if(polNum == '' || polNum == null || polNum == undefined){
          localStorage.removeItem('Policy_Number');
          localStorage.removeItem('Policy_UID');
        }else{
          localStorage.setItem('Policy_Number', polNum);
          localStorage.setItem('Policy_UID', policy_uid);
        }
        
      
        this.viewPolicySummary();
      }

    },
    (error) => {
      localStorage.removeItem('Policy_Number');
      localStorage.removeItem('Policy_UID');
      localStorage.setItem('Payment_Quotation_Number', this.webQuoteNum);
      localStorage.setItem('CRS_Quotation_Number',this.quoteNumber);
      this._route.navigateByUrl('motor/thankyou');
        console.error(error.status);
    });
}

//------------------------------------ VIEW POLICY SUMMARY ------------------------------------------//
  viewPolicySummary() {

    this.motorQuoteService.viewPolicySummary(this.quoteNumber, this.webQuoteNum,'B2B').subscribe(res => {
   
      if (res.response_code == 1) {
      
        localStorage.setItem('schedulelink', res.schedulelink);//Changed policy schedule url to IIRIS by Anju
        localStorage.setItem('creditNote', res.credit_note);
        localStorage.setItem('debitNote', res.debit_note);
        localStorage.setItem('hirePurchase', res.hirePurchaselink);
        localStorage.setItem('CRS_Quotation_Number',this.quoteNumber);
        localStorage.setItem('Payment_Quotation_Number', this.webQuoteNum);
        localStorage.setItem('mortgage_bank', res.mortgageBank);
        localStorage.setItem('Policy_Number', res.policy_number);

        this._route.navigateByUrl('motor/thankyou');

      }

    });
}

//------------------------------------ SEND PAYMENT LINK ------------------------------------------//
    sendPaymentLink() {
  
      this.motorQuoteService.sendPaymentLink(this.webQuoteNum, 'MT', this.emailId,'B2B',this.quoteNumber,'').subscribe(res => {
  
        let payRes = res;
  
        if (payRes.res_code == 1) {
          this.pageLoader = false;
          this.motorQuoteService.sendReferralMailToAdmin(this.webQuoteNum, '', 'QUOTECONFIRMED', '','','','').subscribe(res => {
  
          });
          Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.webQuoteNum, 'success');
  
          this._route.navigate(['agentMotor/motorquote']);
  
        }
      
      });
}

//--------------------------------- FOR PAYMENT -----------------------------------------//
getPayment(){

        
            let return_url = "http://k2key.in/motor_ins//dashboard";
            let site_url = "http://k2key.in/motor_ins/" + this._route.url;

            //this.paymentLoader = this.globalService.spinnerShow();
         
            //Modified by Anju for Payment gateway revamp
            this.travelInsuranceService.paymentAuth(this.webQuoteNum, site_url, return_url, 'MT', '1', '','', 'B2B').subscribe(res => {
              let payRes = res;
              console.log(payRes);
              if(payRes.status == 'Failed'){
                Swal.fire(payRes.res_msg);
                // this.paymentLoader = this.globalService.spinnerHide();
             
                return false;
              }
              // if (payRes.res_code == 3) {
              //   Swal.fire(payRes.res_status);
              //   // this.paymentLoader = this.globalService.spinnerHide();
             
              //   return false;
              // }

              else if (payRes.status == 'Success') {

                      //this.paymentLoader = this.globalService.spinnerHide();
                   
                      localStorage.setItem('Payment_Order_ID', payRes.data.tran_ref);
                      localStorage.setItem('Payment_Order_Tokan', payRes.data.token);
                      localStorage.setItem('Payment_Quotation_Number', this.webQuoteNum);
                      window.location.href = payRes.data.redirect_url;

              }


            },
            error => {
              console.log(error);
              const errorMessage = error.error.res_status;
              const errorCode = error.error.res_code;
              Swal.fire(errorMessage);
            });
}

//------------------------ CHECK ACCESS FOR PAGE ------------------------------//
getUserAccessPermission(){
//    REFAPP
this.motorQuoteService.checkUserAccess('REFAPP', this.localStorageData.EmailAddress, 'MT').subscribe(res => {
  let response = res;
      this.userAccessStatus = response.userAccessData;
      
  });

}

validateBtn(){
 
  // if(this.businessSource == 'DIRECT' && this.userAccessStatus == 1 && this.partnerId == 1){
   
  //     return false;
  // }
  // if(((this.prevInsExpDate) <  this.convertDate(this.toDay)) && (this.businessSource != 'DIRECT' || this.userAccessStatus != 1 || this.partnerId != 1)){
  //   return true;
  // }
  // if(this.convertDate(this.prevInsExpDate) >= this.convertDate(this.toDay)){
  
  //     return false;
  // }

  if(this.disable_btns == 0){
          return true;
  }
  
    
}
// --------------- CHECK ACCESS FOR BUTTONS -------------------------//
checkAccessForPolicyIssueButtons(){
  this.motorQuoteService.checkAccessToMotorPolicyButtons('MT',this.product_code).subscribe(res =>{
        if(res.IsNonPayIssueAllowed == 0){
             this.issuePolicyBtnAccess = true;
        }

        if( res.IsOnlinePaymentAllowed == 0){
             this.onlinePayBtnAccess = true;
        }
  });
}




//----------------------------------- VIEW QUOTE PDF -------------------------------------------
viewQuotePDF() {
    this.showLoader = true;
      this.motorQuoteService.getQuoteDetailPDF(this.webQuoteNum).subscribe(res => {
      
        setTimeout(
          function () {

          }.bind(this),
          600
        );
        let viewPDF = res;
        if (viewPDF.response_code == 1) {
          this.showLoader = false;
          window.open(viewPDF.pdfPath, "_blank");
        }
      });

  }
   
//------------------------------- SEND QUOTATION MAIL ----------------------------------------//
sendMail() {
  this.showLoader = true;
  this.motorQuoteService.sendMail(this.webQuoteNum, this.emailId).subscribe(res => {
          if(res.response_code == 1){
                this.showLoader = false;
                Swal.fire("", "Thank you for choosing Fidelity United for insuring your car. We have sent an email to your registered email with all the quotation details. Your Quotation Reference# " + this.webQuoteNum, 'success');
          }
  });
}

checkTermsCond() {
  this.accept_terms = !this.accept_terms;
}
checkDisclaimer() {
  this.accept_disclaimer = !this.accept_disclaimer;
}


termsAndConditions(frame, type) {
  //DIGI Status check starts Feb2024
 this.digi_status$.subscribe(trackText => {
  this.digiSwalInstance= Swal.fire({
    title: '',
    html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,      
    showConfirmButton: false
  });
    });
   // this.digi_status.next("KYC verification is in progress.");
 var digiRequest={
  "QuotationNumber":this.webQuoteNum,
  "FirstName": this.quoteDetail[0].InsuredName,
  "MiddleName": "",
  "LastName":'',
  "MatchCategory": "I",
  "Nationality":"",
  "DOB": "",
  "CustomerType": "I",
  "UserEmail": this.emailId
};
this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
 // this.digi_status.next("Almost there.");
console.log(JSON.stringify(res));
if(res==null ||res.data==null ){
  Swal.fire("", "Failed to process the request. Please try again", 'error'); 
return;
}
else if(res.data.digiStatus=='Approved'){
  if (this.digiSwalInstance) {
    this.digiSwalInstance.close();
   }
if(this.TotalPremium != 0){
    frame.show();
    this.policyStatus = type;
    this.getTermsConditions();
  }
  else{
Swal.fire("Total Premium cannot be Zero","Please create a new quotation and send for referral","error");
  }
  return;
}

else {
 
  if(config.enableDIGIApproval=="N"){
    this.digi_status.next("Almost there...");
    setTimeout(() => {
      this.digi_status.next("Please do not close the browser or refresh...");
  }, 2000);
    setTimeout(() => {
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
    if(this.TotalPremium != 0){
      frame.show();
      this.policyStatus = type;
      this.getTermsConditions();
    }
    else{
  Swal.fire("Total Premium cannot be Zero","Please create a new quotation and send for referral","error");
    }
      return;
  }, 60000); 
  }
  else{  
     if (this.digiSwalInstance) {
    this.digiSwalInstance.close();
   }
    Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
    return;  
  }
}
// else{
//   if(res.data.recordExists==true){
//     Swal.fire("DIGI Approval Requested already", "You will be receiving a confirmation mail from compliance", 'error').then(result => {
//       this._route.navigateByUrl('home/retreivequote');
//     });
//   }
//    else{
//     Swal.fire("DIGI Approval Requested", "You will be receiving a confirmation mail from compliance", 'error').then(result => {
//       this._route.navigateByUrl('home/retreivequote');
//     });
//    }
    
// }
});
//DIGI Status check End Feb2024 
}


getTermsConditions() {

  this.showTerms = true;
  this.motorQuoteService.getTermsConditions('B2B').subscribe(res => {
    if (res.response_code == 1) {
      this.showTerms = false;
      this.termsAndCondition = res.termsAndCondition;
    }

  });
}

getQuotationHistory(quoteNumber){
  this.motorQuoteService.getQuotationHistory(quoteNumber,'MT').subscribe(res =>{
            if(res.response_code == 1){
                    this.quotationHistoryArr = res.quotationHistoryList;
            }
                

  });
}
referAQuote(ref_val){
  this.referalModel = true;
  if(ref_val == 'APPROVED'){
    this.refer_type = 'Approve';
    this.refer_condtion_type = 3;
  }

  if(ref_val == 'REJECTED'){
    this.refer_type = 'Reject';
    this.refer_condtion_type = 4;
  }

  if(ref_val == 'REFERRAL'){
    this.refer_type = 'Refer';
    this.refer_condtion_type = 2;
  }

  if(ref_val == 'ADDITIONALINFO'){
    this.refer_type = 'Additional Info';
    this.refer_condtion_type = 5;
  }

}



sendRefferMailToAdmin(type,frame) {

  if(this.referalDescription==''){

    Swal.fire('Please enter description');
    return false ;


  }

  if (type == 1 || type == 2 ) {

    if (this.referalDescription == '') {
      this.validtnMsg = true;
      return false;
    }
    this.validtnMsg = false;
    this.referalPopUp = true;
  }

  if (type == 3 || type == 4 || type == 5) {
      if (this.referalDescription == '') {
          this.validtnMsg = true;
          return false;
      }
      this.validtnMsg = false;
      this.referalPopUp = true;
  }


  let refer_type;
  let event_type;


  if (type == 1 || type == 2) {
    event_type = 'REFERQUOTE';        // REFER A QUOTE
    refer_type = type == 1 ? 'SYSTEM' : 'MANUAL';
    this.policyStatus = 'REFERED';
  }

  if (type == 3) {
      event_type = 'REFERAPPROVED';     // APPROVED
      refer_type = '';
      this.policyStatus = 'APPROVED';
  }

  if (type == 4) {
        event_type = 'REFERREJECTED';      // REJECTED
        refer_type = '';
        this.policyStatus = 'REJECTED';
  }

  if (type == 5) {
      event_type = 'ADDITIONALINFO';      // ADDITIONAL INFO
      refer_type = '';
      this.policyStatus = 'ADDITIONALINFO';
  }

  this.motorQuoteService.sendReferralMailToAdmin(this.webQuoteNum, this.referalDescription, event_type, refer_type,'','','').subscribe(res => {

    if (res.response_code == 1) {
  
        this.referalPopUp = false;
        Swal.fire('Your quote has been referred to our expert team of UWs for review.', 'You will be notified soon after the review. Your Quotation Reference# ' + this.webQuoteNum, 'success');
        this._route.navigate(['agentMotor/motorquote']);
     

    }

  });
}
}
