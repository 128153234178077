import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { catchError, tap, map } from "rxjs/operators";
import { of, throwError, observable } from "rxjs";
import { GlobalService } from "../_service/global.service";
import { config } from "./../config";

@Injectable({
  providedIn: 'root'
})
export class MarinequoteService {
  apiURL: string = config.apiUrl;
  url: string = config.marineAPIURL


  constructor(private httpClient: HttpClient,public _globalService: GlobalService) { }

  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
}


  //GetCountryList API
  getCountryList(request):Observable<any> {
    return this.httpClient.get(`${this.url}/GetCountries`,{ params: {
          partnerId:request,
          corePolicyNum: "0",
        }
      })
      .pipe(
      catchError(this.handleError),
      tap((res) => {
      let response = this._globalService.convertResponseInJson(res);
    })
  );
  }

  getShipmentTypes(openCoverId):Observable<any>
  {
    return this.httpClient
      .get(`${this.url}/GetShipmentTypes`)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  getGoods(openCoverId):Observable<any>
  {
    return this.httpClient
      .get(`${this.url}/GetGoods`,{params: {
        openCoverPolicyId:openCoverId
      }
    })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  getWareHouseCovers(src,dest):Observable<any>
  {
    return this.httpClient
      .get(`${this.url}/GetCoverageTypeByCountry`,{params: {
        source:src,
        destination:dest
      }
    })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
}
