import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MotorquoteService } from '../../_service/motorquote.service';
import Swal from 'sweetalert2';
import { GlobalService } from '../../_service/global.service';
import { TraveInsuranceService } from '../../_service/traveInsurance.service';//Added by Anju for Medical Payment gateway revamp
import { IssuepolicyService } from '../../_service/issuepolicy.service';

@Component({
  selector: 'app-medicalcancelpayment',
  templateUrl: './medicalcancelpayment.component.html',
  styleUrls: ['./medicalcancelpayment.component.scss']
})
export class MedicalcancelpaymentComponent implements OnInit {

  quotenumber:any; paymentLoader: boolean = false; payment_cancel_reason : any; res_code = 0; errorMsg:any; userId:any = '';
  selectedPartnerId: any;
  selectedBranchId: any;
  quoteDetail: any;
  totalPremium: any;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public motorQuoteService: MotorquoteService,public globalService:GlobalService, public travelInsurance:TraveInsuranceService, public issuePolicyService: IssuepolicyService) { }

  ngOnInit() {

    this.paymentLoader = this.globalService.spinnerHide();
    this.userId = localStorage.getItem('User_ID');
    this.quotenumber = localStorage.getItem('Payment_Quotation_Number');

    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){
     this.res_code = routeParam.Res_Code;
     this.getPaymentErrorMsg(this.res_code);
     this.getMemberDetail();
     
    }
  }

  goBackto(){
          this._route.navigateByUrl('/dashboard');
  }

  retryPayment(){
    
    let return_url = "http://k2key.in/motor_ins//dashboard";
    let site_url = "http://k2key.in/motor_ins/" + this._route.url;

    this.paymentLoader = this.globalService.spinnerShow();
   // this.showHideLoader.additionalDetail = true;
   this.motorQuoteService
 .checkPartnerCreditLimit("Medical",this.totalPremium,this.selectedPartnerId,this.selectedBranchId)
 .subscribe((result) => {
   console.log(result);
//Changed the cl_msg to cl_status to use status code
if(result.cl_status !='200012' )
 {
  //Modified by Anju for Medical Payment gateway revamp
   this.travelInsurance.paymentAuth(this.quotenumber,site_url,return_url,'HT','1','','','B2B').subscribe(res => {
      let payRes = res;

      if (payRes.status == 'Failed') {
        Swal.fire(payRes.res_msg);
         this.paymentLoader = this.globalService.spinnerHide();
       
        return false;
      }

      else if (payRes.status == 'Success') {

         this.paymentLoader = this.globalService.spinnerHide();
        
         localStorage.setItem('Payment_Order_ID',payRes.data.tran_ref);
         localStorage.setItem('Payment_Order_Tokan',payRes.data.token);
         localStorage.setItem('Payment_Quotation_Number',this.quotenumber);

         window.location.href = payRes.data.redirect_url;
        } 
      });
    }
    else if(result.cl_status =='200012'){
        Swal.fire("Party not active.");
        this.paymentLoader = this.globalService.spinnerHide();
        }
     });
  }
  
  

  getPaymentErrorMsg(res_code){
      this.motorQuoteService.getPaymentErrorMsg('PAYMENT',res_code,'ENG').subscribe(res =>{
            this.errorMsg = res.errorMessage[0].ErrorMessage;
      });
  }

  retrieveQuote(){
   
      // this._route.navigateByUrl('/motorquote/' + this.quotenumber);
  
  }

  
  getMemberDetail(){
    this.issuePolicyService.getMembersDetailByQuoteNo(this.quotenumber).subscribe(res =>{
          let response = res;

          this.quoteDetail = response.QuotationDetail[0];

          this.totalPremium = this.quoteDetail.TotalContribution + this.quoteDetail.HAADFine;
          this.selectedPartnerId = this.quoteDetail.CedantBrokerId;
          this.selectedBranchId = this.quoteDetail.CedantBrokerBranchId;


    });


  }

}
