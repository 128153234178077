import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { catchError, tap, map } from "rxjs/operators";
import { of, throwError, observable } from "rxjs";
import { GlobalService } from "../_service/global.service";
import { config } from "./../config";
// import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class HomequoteService {
  apiURL: string = config.apiUrl;
  smedashURL :string = config.SmeMedDashboardURL;
  petRenewalUrl:string =config.petAPIURL;//Added by Anju for Pet Policy renewal

  constructor(
    private httpClient: HttpClient,
    public _globalService: GlobalService
  ) {}

  public handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  //----------------------------- FOR GET PROPERTY TYTPE -----------------------------------------//
  public getPropertyType(partnerId, language, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getPropertyType`,
        { partner_id: partnerId, language: language, source: source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR GET COVER TYPE ----------------------------------------------//
  public getCoverType(partnerId, lob, prodCode, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getCoverType`,
        {
          partner_id: partnerId,
          lob: lob,
          product_code: prodCode,
          source: source,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR GET OWNERSHIP STATUS ---------------------------------------//
  public getOwnershipStatus(partnerId, language, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getOwnershipStatus`,
        { partner_id: partnerId, language: language, source: source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR GET CITY DATA BY COUNTRY  ---------------------------------------//
  public getCityData(country): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getCityByCountry`,
        { country: country },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR GET LOCATION DATA ---------------------------------------//
  public getLocationData(city, language): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getLocation`,
        { city: city, language: language },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR INSERT QUOTATION ---------------------------------------//
  public forGetQuotation(
    quotationValuesArray,
    locationValuesArray,
    source
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/insertQuotation`,
        {
          quotationValuesArray: quotationValuesArray,
          locationValuesArray: locationValuesArray,
          source: source,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR CALCULATE CONTRIBUTION ---------------------------------------//
  public calculateContribution(quotationValuesArray, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/calculateContribution`,
        { quotationValuesArray: quotationValuesArray, source: source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR GET QUOTATION PLAN BENEFITS ---------------------------------------//
  public getPlanBenefits(
    plan_id,
    quotation_number,
    parent_benefit,
    partner_id,
    locationId
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getQuotationPlanBenefits`,
        {
          plan_id: plan_id,
          quotation_number: quotation_number,
          parent_benefit: parent_benefit,
          partner_id: partner_id,
          location_id: locationId,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR SAVE QUOTATION PLAN DETAIL ---------------------------------------//
  public saveQuotePlanDetail(quotationValuesArray, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/insertQuotationPlanDetails`,
        { quotationValuesArray: quotationValuesArray, source: source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // public uploadfiles():Observable<any> {

  //   return this.httpClient.post(`http://sancyberhad.ddns.net/RING_API/api/Register/UploadFiles`,{
  //     PatientMasterId: 27,
  //     CategoryId: 4,
  //     Description: "TEST",
  //     FileAttachments: [
  //         {
  //             DocumentName: "signature.png",
  //             DocumentContent: "JVBERi0xLjQKJeLjz9MKMiAwIG9iago8PC9MZW5ndGggMzIwL0ZpbHRlci9GbGF0ZURlY29kZT4+c3RyZWFtCnichZLLTsMwEEX3/opZlkWMPXb9WAItUBagQn4gIikqyoOGIMTfE1uNFGq7KIrk+B7fcebOgVznRCgwTEFeEgaZNG6xzsmWHPyL8DDu3xFGl/BNjKECtFEguaESkEFfkZc/4tKAVFRaQDMXuZBU27RsOB13UjKiplqnZS0pqqQskFEt07LSFNM3l1xQjWlZMYo8Im9dczmw8eFgmeuNRcgbcnnLgY+rHVmsin39A1fDULVl0b5W8Fx9dP0Au75rxu4iz5jNxnCGbvZ1kb+7hAJ3fep+05VVCHOhfBIB/lg0MdwIn0yAr9u+q2t34BRVzKGLTVO8RQwRrc8yMFwVQwzXymcb4Jt2XnvCztcWiH4SImaQ72O/L5T1wxGcePoa5vUn7nx9yZd+lv5zm7ijW/xqUqGfvMDsvv880r+XL9QQCmVuZHN0cmVhbQplbmRvYmoKNCAwIG9iago8PC9UeXBlL1BhZ2UvTWVkaWFCb3hbMCAwIDU5NSA4NDJdL1Jlc291cmNlczw8L0ZvbnQ8PC9GMSAxIDAgUj4+Pj4vQ29udGVudHMgMiAwIFIvUGFyZW50IDMgMCBSPj4KZW5kb2JqCjEgMCBvYmoKPDwvVHlwZS9Gb250L1N1YnR5cGUvVHlwZTEvQmFzZUZvbnQvSGVsdmV0aWNhL0VuY29kaW5nL1dpbkFuc2lFbmNvZGluZz4+CmVuZG9iagozIDAgb2JqCjw8L1R5cGUvUGFnZXMvQ291bnQgMS9LaWRzWzQgMCBSXT4+CmVuZG9iago1IDAgb2JqCjw8L1R5cGUvQ2F0YWxvZy9QYWdlcyAzIDAgUj4+CmVuZG9iago2IDAgb2JqCjw8L1Byb2R1Y2VyKGlUZXh0riA1LjUuMTAgqTIwMDAtMjAxNSBpVGV4dCBHcm91cCBOViBcKEFHUEwtdmVyc2lvblwpKS9DcmVhdGlvbkRhdGUoRDoyMDIxMDkwNjEzMzg0MSswNSczMCcpL01vZERhdGUoRDoyMDIxMDkwNjEzMzg0MSswNSczMCcpL0hlYWRlcihEYWlseSBBdHRlbmRhbmNlKS9TdWJqZWN0KERhaWx5IEF0dGVuZGFuY2UgYmV0d2VlbiAyMDIxLTA5LTA2IHRvIDIwMjEtMDktMDYpPj4KZW5kb2JqCnhyZWYKMCA3CjAwMDAwMDAwMDAgNjU1MzUgZiAKMDAwMDAwMDUxNCAwMDAwMCBuIAowMDAwMDAwMDE1IDAwMDAwIG4gCjAwMDAwMDA2MDIgMDAwMDAgbiAKMDAwMDAwMDQwMiAwMDAwMCBuIAowMDAwMDAwNjUzIDAwMDAwIG4gCjAwMDAwMDA2OTggMDAwMDAgbiAKdHJhaWxlcgo8PC9TaXplIDcvUm9vdCA1IDAgUi9JbmZvIDYgMCBSL0lEIFs8NGIzMDdiZmU3ZmUwNmRhMzQwMzU0NzNkZjgyMTk1MjY+PDRiMzA3YmZlN2ZlMDZkYTM0MDM1NDczZGY4MjE5NTI2Pl0+PgolaVRleHQtNS41LjEwCnN0YXJ0eHJlZgo5NDAKJSVFT0YK"
  //         }
  //     ],
  //     FileBytes: 200,
  //     TenantId: 9
  // },)
  //         .pipe(catchError(this.handleError), tap(res => {
  //           let  response=this._globalService.convertResponseInJson(res);

  //         }));
  // }

  //------------------------------ FOR GET QUOTATION DETAIL ---------------------------------------//
  public getQuotationDetail(
    view_type,
    reference_number,
    partner_id,
    source
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getQuotationDetails`,
        {
          view_type: view_type,
          quotation_number: reference_number,
          partner_id: partner_id,
          source: source,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ FOR INSERT POLICY ---------------------------------------//
  public insertPolicy(
    policyValuesArray,
    locationArray,
    source
  ): Observable<any> {
    console.log("Before insert");
    console.log(policyValuesArray);
    console.log(locationArray);
    console.log(source);
    //-----------------------Vinith-------------------------
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/insertPolicy`,
        {
          policyValuesArray: policyValuesArray,
          locationValuesArray: locationArray,
          source: source,
        },
        { ...Option, responseType: "text" }
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          console.log(res);
          let res1 = JSON.parse(res);
          // let  response=this._globalService.convertResponseInJson(res1);
          console.log("after insert");
          console.log(res1);
        })
      );
  }

  //------------------------------ UPDATE QUOTATION ---------------------------------------//
  public updateQuotation(
    quotationDataArr,
    locationArray,
    source
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/updateQuotation`,
        {
          quotationDataArr: quotationDataArr,
          locationValuesArray: locationArray,
          source: source,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------ ALL DROPDOWN DATA FOR FLEXI QUOTE ---------------------------------------//
  // public getAllFormData(id,language,lob,prodCode,country,city):Observable<any> {

  //   return this.httpClient.post(`${this.apiURL}HomeInsuranceWebservice/getAllMastersData`,{partner_id:id,language:language,lob:lob,product_code:prodCode,country:country,city:city},this._globalService.getHeader())
  //         .pipe(catchError(this.handleError), tap(res => {
  //           let  response=this._globalService.convertResponseInJson(res);

  //         }));
  // }
  //------------------------------ QUOTATION PDF ---------------------------------------//
  public viewQuotePDF(id, quoteNumber, productType, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getQuotationDetailsPDF`,
        {
          partner_id: id,
          reference_number: quoteNumber,
          productType: productType,
          source: source,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //---------------------------------- VIEW POLICY PDF ----------------------------------------//
  public viewPolicyPDF(id, policyNum): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getPolicyDetailsPDF`,
        { partner_id: id, policy_number: policyNum },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------- View PET Policy --------------------------//
  public getPetPoliciesByUser(patrnercode,datefrom,dateto,pageNumber): Observable<any> {
    return this.httpClient
      .get("http://10.170.11.58/api/b2b/policy/"+patrnercode+"?from_date="+datefrom+"&to_date="+dateto+"&page="+pageNumber)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-------------------------- upload doc --------------------------//

public UploadHCDocumentDetails(formData):Observable<any> {

  return this.httpClient.post(`${this.apiURL}HomeInsuranceWebservice/UploadHCDocumentDetails`,formData)
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

  //------------------- FOR PAYMENT ------------------------//
  public paymentAuth(
    QuotationNumber,
    site_url,
    return_url,
    project_type,
    partner_id,
    policy_number,
    quote_type,
    source
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/open_pay_page_values`,
        {
          QuotationNumber: QuotationNumber,
          site_url: site_url,
          return_url: return_url,
          project_type: project_type,
          partner_id: partner_id,
          policy_number: policy_number,
          quote_type: quote_type,
          source: source,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-----------------SAVE QUOTATION PAYMENT -------------------//
  public saveQuotationPayment(
    quote_id,
    token,
    eventType,
    quoteNumber,
    responseMsg,
    transactionID,
    project_Code,
    quote_type
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/saveQuatationPayment`,
        {
          PaymentLogId: token,
          eventType: eventType,
          QuotationNumber: quoteNumber,
          ResponseMessage: responseMsg,
          TransactionID: transactionID,
          project_Code: project_Code,
          quote_type: quote_type,
          quote_id: quote_id,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-------------------- VERIFY QUOTATION PAYMENT ----------------//
  public verifyQuatationPayment(orderNumber): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/verifyQuatationPayment`,
        { orderNumber: orderNumber },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------- GET POLICY SCHEDULE PDF ----------------//
  public getPolSchedulPDF(
    partner_id,
    policy_number,
    quotationNumber
  ): Observable<any> {
    console.log("getPolSchedulPDF", partner_id, policy_number, quotationNumber);
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getPolicyScheduleDetailsPDF`,
        {
          partner_id: partner_id,
          policy_number: policy_number,
          quotationNumber: quotationNumber,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //---------------------------------------- POLICY DETAil PDF ------------------------------------------------------------//
  public getPolicyDetail(partner_id, policy_number): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getPolicyDetailsPDF`,
        { partner_id: partner_id, policy_number: policy_number },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //---------------------------------------- Get  Ownership Data ------------------------------------------------------------//
  public getownershipData(coverType): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getOwnershipStatusByCoverType`,
        { coverType: coverType },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------- View Policy --------------------------//
  public getPliciesByUser(
    partnerId,
    userName,
    lob,
    fromDate,
    toDate,
    opText,
    approvalType, //Added by Anju for Approval type
    pageSize,
    pageNumber
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PolicyController/getPoliciesByUser`,
        {
          partnerid: partnerId,
          username: userName,
          lob: lob,
          from_date: fromDate,
          to_date: toDate,
          OptionalText: opText,
          approvalType:approvalType, //Added by Anju for Approval type
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------ View Policy Details -----------------------//
  public getCustomerPolicyDetails(
    partnerId,
    userName,
    policyNumber,
    lobCode
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PolicyController/getCustomerPolicyDetails`,
        {
          partnerId: partnerId,
          userName: userName,
          polNumber: policyNumber,
          lobCode: lobCode,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------ View Policy Details -----------------------//
  public getPetCustomerPolicyDetails(
    // partnerId,
    // userName,
    policyNumber,
   // lobCode
  ): Observable<any> {
    return this.httpClient
      .get(
        `http://10.170.11.58/api/b2b/policy-detail/`+policyNumber,
        // {
        //   partnerId: partnerId,
        //   userName: userName,
        //   polNumber: policyNumber,
        //   lobCode: lobCode,
        // },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------ List of dropdown data FOR ISSUPOLICY ---------//
  public getQuotationFormData(): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getQuotationFormData`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
//ahmed
  public getPartnerCode(partnerID): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getPartnerCode`,
        {partnerId:partnerID},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //----------- Quotation List for Retrieve Quote -----------------//
  public retrieveQuoteList(
    partnerId,
    id,
    fromDate,
    toDate,
    quotNum,
    opText,
    status,
    bsrc
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getQuotationListForRetrieve`,
        {
          partnerid: partnerId,
          Id: id,
          fromDate: fromDate,
          toDate: toDate,
          quoteRefNum: quotNum,
          policyHolderName: opText,// Updated field Name to fix Chasis# search Issue - Anju
          status: status,
          business_source: bsrc,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //----------- Pet Quotation List for Retrieve Quote -----------------//
  public retrievePetQuoteList(
    partnerId,
    pageNumber,
    userId,
    fromDate,
    toDate,
    quotNum,
    optionalText,
    status,
   
  ): Observable<any> {
    return this.httpClient
      .post(
        "http://10.170.11.58/api/b2b/quotes/"+userId,
        {
          partner_id:partnerId,
          page:pageNumber,
          from_date: fromDate,
          to_date: toDate,
          quote_ref: quotNum,
          name:optionalText,
          status: status,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //----------- Pet referal Quotation List for Retrieve Quote -----------------//
  public retrieveReferalPetQuoteList(
    PartnerCode,
    pageNumber,
    userId,
    fromDate,
    toDate,
    quotNum,
    optionalText,
    status,
  ): Observable<any> {
    return this.httpClient
    .post(
      "http://10.170.11.58/api/b2b/quotes/"+userId,
      {
        partner_id:PartnerCode,
        page:pageNumber,
        from_date: fromDate,
        to_date: toDate,
        quote_ref: quotNum,
        name:optionalText,
        status: status,
      },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //---------- List of dropdown data FOR RETRIEVE QUOTE --------------//
  public retrieveQuoteFormData(loggedUser=''): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/retrieveQuotationFormApi`,
        {
          loggedUser:loggedUser      
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // async retrieveQuoteFormData(){

  //   return await this.httpClient.post(`${this.apiURL}Webservice/retrieveQuotationFormApi`,{},this._globalService.getHeader()).toPromise();
  // }
  //------------------------------------------ FOR GET PAYMENT ORDER ID ------------------------------------------------//
  public getPaymentOrderID(lob, quoteNo): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/getPaymentOrderId`,
        { LOBCode: lob, QuoteNum: quoteNo },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public updatePaymentOrderID(id): Observable<any> {
    console.log(id);
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/updatePaymentOrderID`,
        { PayId: id },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------ FOR GET ENDORSMENT HISTORY ------------------------------------------------//
  public getEndorsementHistory(
    lob,
    quoteNo,
    polNo,
    Source,
    DataType,
    EndSrNo,
    EndNum
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getEndorsementHistory`,
        {
          LOBCode: lob,
          WebQuotationNumber: quoteNo,
          CRSPolNo: polNo,
          Source: Source,
          DataType: DataType,
          EndSrNo: EndSrNo,
          EndNum: EndNum,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // --list of locations for flexi-home --//
  public getHomeRiskLocations(partner_id, reference_number): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getHomeRiskLocations`,
        { partner_id: partner_id, reference_number: reference_number },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // -- Get Mail-Quote for flexi-home--//
  public sendEmail(id, refNum, email, prodType): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/sendEmail`,
        {
          partner_id: id,
          reference_number: refNum,
          email: email,
          productType: prodType,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  // --------------------------------------Edit Data For Home-----------------------------------------------------
  public getQuotationDetails(
    partnerId,
    retrieveQuoteNumber,
    type
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/getQuotationDetails`,
        {
          partner_id: partnerId,
          quotation_number: retrieveQuoteNumber,
          view_type: type,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  // ----------------------------------------Submit Refferal Data-------------------------------------------------

  public quotationReferralFunctionality(
    comment,
    event_type,
    quotation_number,
    refer_type,
    referral_reason,
    lob
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/quotationReferralFunctionality`,
        {
          comment: comment,
          event_type: event_type,
          quotation_number: quotation_number,
          refer_type: refer_type,
          referral_reason: referral_reason,
          lob: lob,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public uplooadCompressFile(formData): Observable<any> {
    return this.httpClient
      .post(
        `https://k2key.in/Manoj/Google-Login-in-CodeIgniter-master/Auth/fetchResponseTestData`,
        formData
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  // ------------------------------------------------promo code Service--------------------------------------------------------------
  public getPromoCodeDiscountValue(source, lob, promocode): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getPromoCodeDiscountValue`,
        { source: source, lob: lob, promo_code: promocode },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // ------------------------------------------------Range  Service--------------------------------------------------------------
  public getPlanLoadingDiscount(
    product_code,
    lob,
    partner_id,
    loading_discount_amount,
    language
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getPlanLoadingDiscount`,
        {
          product_code: product_code,
          lob: lob,
          partner_id: partner_id,
          loading_discount_amount: loading_discount_amount,
          language: language,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // ------------------------------------------------Save Policy   Dtails--------------------------------------------------------------
  public savePolicyDetails(policyValuesArray, locationArray): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/savePolicyDetails`,
        {
          policyValuesArray: policyValuesArray,
          locationValuesArray: locationArray,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  // -----------------------------------------------------Verify Payment---------------------------------------------------------
  public verifyPayment(orderNumber): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/verifyPayment`,
        { tran_ref: orderNumber },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //----------------------------------------------------Update Benifit Data--------------------------------------------------
  public updateBenefitDetails(
    quotationPlanBenefitsData,
    location_id,
    quotation_number
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}HomeInsuranceWebservice/updateBenefitDetails`,
        {
          quotationPlanBenefitsData: quotationPlanBenefitsData,
          location_id: location_id,
          quotation_number: quotation_number,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  
  public retrieveSMEMedical(
    partnerId,
    pageNumber,
    userId,
    fromDate,
    toDate,
    productId,
    usrRole,
    userLevel,
    branchId,
    quotNum,
    optionalText,
    status,
   
  ): Observable<any> {
    return this.httpClient
      .get(
        `${this.smedashURL}/quotation`, { params: {
          partner_id:partnerId,
          userId:userId,
          // page:pageNumber,
          start_date: fromDate,
          end_date: toDate,
          product : productId,
          userRole : usrRole,
          userLevel : userLevel,
          branchId : branchId,
          quote_number: quotNum,
          // name:optionalText,
          quote_status: status == 'referred' ? 'referral' : status,
        }
      }, 
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //Added by Anju for Party Active Status Check
  public checkPartner_CreditLimit(lob_code,premium, agent_code, selected_branch): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PolicyController/checkPartnerCL`,
        { lob_code:lob_code,premium :premium,agent_code:agent_code,selected_branch:selected_branch },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

   //----------- Added by Anju for Pet Policy List for Renewal -----------------//
   public retrievePetRenewalList(
    pageNumber,
    policyNumber,
    partnerId,
    fromDate,
    toDate,
    renewalStatus,
    claimStatus
   
  ): Observable<any> {
    return this.httpClient
      .get(
        `${this.petRenewalUrl}renewal-policy`, { params: {
          page:pageNumber,
          policy_number:policyNumber,
          partner_id:partnerId,
          from_date: fromDate,
          to_date: toDate,
          renewal_status:renewalStatus,
          claim_status: claimStatus
        }
      },
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

    //----------- Added by Anju for fetching Home Policy Documents -----------------//
    public getHomeDocumentData(quoteNumber): Observable<any> {
      return this.httpClient
        .post(
          `${this.apiURL}HomeInsuranceWebservice/GetHomeDocuments`,
          { quoteNumber: quoteNumber },
          this._globalService.getHeader()
        )
        .pipe(
          catchError(this.handleError),
          tap((res) => {
            let response = this._globalService.convertResponseInJson(res);
          })
        );
    }
}
