import { UserauthService } from 'app/_service/userauth.service';
import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { GlobalService } from 'app/_service/global.service';
import Swal from 'sweetalert2';
import { config } from "app/config";
import { ReCaptcha2Component } from 'ngx-captcha';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  @ViewChild('captchaElem',{ static: false }) captchaElem :ReCaptcha2Component
  @ViewChild('langInput',{ static: false }) langInput :ReCaptcha2Component

  public siteKey = "6Leq8_woAAAAAHkfJDzIw_CIc_OFYMow5q93jPiX"
  protected passwordForm: FormGroup;
  token: any;
  ResetPasswordApprv: any;
  TokenNumber: any;
  forgetPassword: any;
  validcaptcha: boolean = false;
  userName: any;
  oldPass: any;
  randomPass: string;

  constructor(public _userAuthService: UserauthService,
    private _router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public _globalService: GlobalService) {
      this.route.queryParams.subscribe(params => {
        this.ResetPasswordApprv = params['ResetPasswordApprv'];
        this.TokenNumber = params['TokenNumber'];
        this.forgetPassword = params['forgetPassword'];
        this.userName = params['userName'];
      //  console.log('Token:', this.ResetPasswordApprv);
     });
     if(this.userName=='loggedUser'){
      this.userName=JSON.parse(localStorage.getItem('currentUser')).EmailAddress;
      this.TokenNumber=localStorage.getItem('tokenNumber');
     }
     }

  ngOnInit() {
    this.oldPass = localStorage.getItem('oldPass');
    this.passwordForm = this.formBuilder.group({
      userName: [''],
      oldPassword: [''],
      newPassword: [''],
      confirmPassword: [''],
      recaptcha: ['', Validators.required]
    }, {
      validator: this.passwordMatchValidator // custom validator function
    });
    this.checkValidation();
  }

  // Handle Recaptcha Success 
  handleSuccess(data) {
    if(data)
    {
      this.validcaptcha = true;
    }
    else{
      this.validcaptcha = false
    }
    console.log(data);
  }

  checkValidation(){
    // debugger;
     // alert(1)
     if(this.forgetPassword == 'yes'){
      console.log(this.forgetPassword)
      this.passwordForm.controls['userName'].setValidators([Validators.required]);
       this.passwordForm.controls['userName'].updateValueAndValidity()
       this.passwordForm.controls['oldPassword'].setValidators(null);
       this.passwordForm.controls['oldPassword'].updateValueAndValidity()
       this.passwordForm.controls['newPassword'].setValidators(null);
       this.passwordForm.controls['newPassword'].updateValueAndValidity()
       this.passwordForm.controls['confirmPassword'].setValidators(null);
       this.passwordForm.controls['confirmPassword'].updateValueAndValidity()
     }else{
       this.passwordForm.controls['userName'].setValidators(null);
       this.passwordForm.controls['userName'].updateValueAndValidity()
       this.passwordForm.controls['oldPassword'].setValidators([Validators.required]);
       this.passwordForm.controls['oldPassword'].updateValueAndValidity()
       this.passwordForm.controls['newPassword'].setValidators([Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/)]);
       this.passwordForm.controls['newPassword'].updateValueAndValidity()
       this.passwordForm.controls['confirmPassword'].setValidators([Validators.required]);
       this.passwordForm.controls['confirmPassword'].updateValueAndValidity()
     }
 
   }

  passwordMatchValidator(formGroup: FormGroup) {
    const oldPassword = formGroup.get('oldPassword').value;
    const enteredoldPass = localStorage.getItem('oldPass');
    const newPassword = formGroup.get('newPassword').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    if (oldPassword == '' && newPassword == '') {
      formGroup.get('oldPassword').setErrors(null);
    }
    if (oldPassword != '' && newPassword != '') {
      if (enteredoldPass !== oldPassword) {
        formGroup.get('oldPassword').setErrors({ mismatch: true });
      } else {
        formGroup.get('oldPassword').setErrors(null);
      }
    }
    

    if (newPassword !== confirmPassword) {
      formGroup.get('confirmPassword').setErrors({ mismatch: true });
    } else {
      formGroup.get('confirmPassword').setErrors(null);
    }
  }


  onSubmit(){
    // alert(1);
    if (this.passwordForm.status == "INVALID") {
      this.passwordForm.markAllAsTouched();
      Swal.fire("", "Please fill all Mandatory Fields Correctly", "error");
      return false;
    }
    
    this._userAuthService.resetPassword({
          username : this.userName,
          token : this.TokenNumber,
          password: this.passwordForm.value.newPassword}
          ).then((res:any) =>{
            console.log(res)
            if(res.statusCode == 1){
              this._userAuthService.sendMail({
                To : this.userName,
                Subject : "Change Password",
                Body :"<h4>Dear " + res.message + ",</h4><br><p>Your Password has been changed.</p><br><p>If you have not performed the action, please notify us immediately.</p>"
              }).then((response1:any) =>{});
              Swal.fire(
                "",
                "Password changed successfully.",
                "success"
              );
              this._router.navigate(["/login"]);  
            }
            else if(res.statusCode == 3){
              Swal.fire("", "New password should not be the same as the previous 3 passwords", "error");
              return false;  
            }else{
              Swal.fire("", "Please try after sometime", "error");
              return false;
            }         
              
            })
          }

           generateRandomPassword(): string {
            const length = 12;
            const specialCharacters = ['@', '%', '#', '$'];
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const digits = '0123456789';
          
            const requiredChars = [
              uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)],
              specialCharacters[Math.floor(Math.random() * specialCharacters.length)],
              digits[Math.floor(Math.random() * digits.length)],
              digits[Math.floor(Math.random() * digits.length)],
              digits[Math.floor(Math.random() * digits.length)]
            ];
          
            const remainingChars = length - requiredChars.length;
          
            const charset = lowercaseLetters + uppercaseLetters + 'abcdefghijklmnopqrstuvwxyz';
            let password = '';
          
            // Ensure that the first character is an uppercase letter
            const firstUppercaseIndex = Math.floor(Math.random() * uppercaseLetters.length);
            password += uppercaseLetters.charAt(firstUppercaseIndex);
          
            for (let i = 1; i < remainingChars; i++) {
              const randomIndex = Math.floor(Math.random() * charset.length);
              password += charset.charAt(randomIndex);
            }
          
            requiredChars.forEach(char => {
              const randomIndex = Math.floor(Math.random() * password.length);
              password = password.slice(0, randomIndex) + char + password.slice(randomIndex);
            });
          
            console.log('Random Password:', password);
            debugger;
            return password;
          }    
  
  onChange(){
    // alert(1);
    if (this.passwordForm.status == "INVALID") {
      this.passwordForm.markAllAsTouched();
      Swal.fire("", "Please fill all Mandatory Fields Correctly", "error");
      return false;
    }
    this.randomPass=this.generateRandomPassword();
    this._userAuthService.changePassword({
          username : this.passwordForm.value.userName,
          password : this.randomPass}
          ).then((res:any) =>{
            if(res.statusCode==1){

              const options: any = {
                title: 'Password changed successfully',
                text: 'Password has been reset. Please check you mail.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#002f87',
                confirmButtonText: 'OK',

              };
          
              Swal.fire(options).then((result: any) => {
                if (!result.dismiss) {
                  this._router.navigate(["/login"]);        ;
                } 
              });
             // Swal.fire("Password changed successfully. Please check you mail.", 'success');
            }
            this._userAuthService.sendMail({
              To : this.passwordForm.value.userName,
              Subject : "Forgot Password",
              Body :"<h4>Dear " + res.message + ",</h4><br><p>Your Password has been reset.  Please use the reset password below to log in to your Account.</p><br><p>Reset Password: " + this.randomPass + "</p><br><p>Password reset Instructions:<br>1.  Minimum of 8 characters;<br>2.  At least one lower case character;<br>3.  At least one upper case character;<br>4.  One special character and<br>5.  At least one number.<br><br>Please <a href= "+"https://b2bstaging.fidelityunited.ae/login"+" target="+"_blank"+">click here</a> to reset your password.<br><br>If you did not perform this action, please notify us immediately.</p>"
            }).then((response1:any) =>{})
            console.log(res)
            if(res.statusCode == 1){
             // this._router.navigate(["/login"]);  
            }else{
           //   Swal.fire("", "Please try after sometime", "error");
              return false;
            }
              
            })
          }  
          cancelReset(){
            this._router.navigate(["/login"]);   
          }      

}
