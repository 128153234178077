import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialuiModule} from '../materialui/materialui.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MarineRoutingModule } from './marine-routing.module';
import { MarineComponent } from './marine.component';
import { MarinequoteComponent } from './marinequote/marinequote.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { MarinecancelpaymentComponent } from './marinecancelpayment/marinecancelpayment.component';
import { MarinepaymentinvoiceComponent } from './marinepaymentinvoice/marinepaymentinvoice.component';
import { ReferralpopupComponent } from './referralpopup/referralpopup.component';
import { ApprovequoteComponent } from './approvequote/approvequote.component';


@NgModule({
  declarations: [ MarineComponent, MarinequoteComponent, ThankyouComponent, MarinecancelpaymentComponent, MarinepaymentinvoiceComponent, ReferralpopupComponent, ApprovequoteComponent],
  imports: [
    CommonModule,
    MarineRoutingModule,
    MaterialuiModule,
    MatCheckboxModule,
    NgxMaskModule,
    NgxMatSelectSearchModule
  ]
})
export class MarineModule { }
