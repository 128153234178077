import { Component, OnInit , ViewChild} from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

import Swal from 'sweetalert2';
import { catchError, map } from 'rxjs/operators';
import { IssuepolicyService } from 'app/_service/issuepolicy.service';
import { GlobalService } from 'app/_service/global.service';
import { SmeMedicalService } from 'app/_service/sme-medical.service';

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-mailmafquotetool',
  templateUrl: './mailmafquotetool.component.html',
  styleUrls: ['./mailmafquotetool.component.scss']
})
export class MailMafQuoteToolComponent implements OnInit {

  @ViewChild(SignaturePad, {static:false}) public signaturePad: SignaturePad;
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  insuredName: any;
  mafUploadStatus: boolean = false;
  MAFClean: boolean = false;
  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }
 
  drawComplete(id) {
    // will be notified of szimek/signature_pad's onEnd event
    this.medicalHistoryForm[id] = this.signaturePad.toDataURL();
    //console.log(this.medicalHistoryForm[id]);
  }
 
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    
  }

  bloodGroup = [
 
    {value: '0', label: 'A+'},
    {value: '1', label: 'A-'},
    {value: '2', label: 'B+'},
    {value: '3', label: 'B-'},
    {value: '4', label: 'O+'},
    {value: '5', label: 'O-'},
    {value: '6', label: 'AB+'},
    {value: '7', label: 'AB-'},
  ];

  uaeResidentArr=[
    {value: '0', label: 'Yes'},
    {value: '1', label: 'No'}
  ]

  //Declare Array
  public  questionnaireMaster:any=[]; sectionMaster:any=[]; medicalHistoryForm:any=[]; regionArr = []; maritalstatusArr = []; medicalHistory:any=[]; quotationDetail: any=[];
  genderArr = []; nationalityArr = []; mobileArr = []; salaryArr = []; RelationshipArr:any = []; allMedicalHistoryForm:any=[];public selectedMember:any=[];

  public selectAllMedicalYesNo: any=""; genderVal:any; token:any; quoteNum:any; memberId:any; memberDetail:any = [];  maxDate = new Date(); relationVal:any; email:any;
  partnerId:any;
  isDigitalMAF: boolean[] = [];
  isUploadMAF: boolean[] = [];
  hasCondition: string[] = [];
  pdfLoader:any=[];
  mafLoader:any=[];
 
  constructor(public smeMedicalService: SmeMedicalService,public issuePolicyService :IssuepolicyService,public _activatedroute: ActivatedRoute,public globalService:GlobalService,public _route: Router) { }

  ngOnInit() {
    
    this.isDigitalMAF = new Array(length).fill(false);
    this.isUploadMAF = new Array(length).fill(false);
    this.hasCondition = new Array(length).fill('no');
    this.getQuestionnaireMaster();
   // this.getQuotationFormData();

    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){
      this.token = routeParam.token;
      this.memberId = routeParam.memberid;
      this.quoteNum = routeParam.quoteNum;
    }
  
    
      this.getAccessToMAFForm();
  }

  getQuestionnaireMaster(){

    this.issuePolicyService.getQuestionnaire().subscribe(res =>{
      let responseData = res;

      this.questionnaireMaster=responseData.questionnaireMaster;
      this.sectionMaster=responseData.sectionMaster;
      this.questionnaireMaster['section_1'].forEach((qustionObj,index) => {

                this.medicalHistoryForm[qustionObj.Id] = "no" ;
  
           });
       
    });

  }

  //----------- ALL DROPDOWN VALUES --------------//
  getQuotationFormData(){
    
    this.issuePolicyService.getQuotationFormData().subscribe(response =>{
       let formDataRes = response;
     
      this.regionArr = formDataRes.Region;
      this.maritalstatusArr = formDataRes.MaritalStatus;
      //this.coveragetypeArr = formDataRes.Coverage;
      this.nationalityArr = formDataRes.Nationality
      this.mobileArr  = formDataRes.MobileCode;
      this.RelationshipArr = formDataRes.Relationship;
      this.salaryArr = formDataRes.Salary;
      this.genderArr = formDataRes.Gender;
    });
    
  }
  // --------------Select all yes or no -------------------//

selectAllEvent(section){

  if(this.selectAllMedicalYesNo=='yes'){

          this.questionnaireMaster['section_1'].forEach((qustionObj,index) => {
        
            this.medicalHistoryForm[qustionObj.Id] = "yes" ;
          

        });

  }

  if(this.selectAllMedicalYesNo=='no'){

        this.questionnaireMaster['section_1'].forEach((qustionObj,index) => {

          this.medicalHistoryForm[qustionObj.Id] = "no" ;
        

      });

  }

}

callChange(){
  this.selectAllMedicalYesNo = "";
  
}
updateBloodGroup(){ 
    
 // this.formDetail.controls[this.activeIndex].get("bloodGroup").setValue(this.medicalHistory.bloodGroup);

}
//------------------------------------  MAF Page Validation -----------------------------------//
saveMedicalHistory(medicalHistory){

  let mafValidationFlag = false ;

    if(this.medicalHistory.bloodGroup == '' || this.medicalHistory.bloodGroup == null){
        this.globalService.openSnackBar("Please Select Blood group",'',);
        return false;
    }
 
  this.questionnaireMaster['section_1'].forEach((qustionObj) => {

      if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
        mafValidationFlag = true;
        this.globalService.openSnackBar("Please Select all Relevant options for symptoms during diagnosed treatment ",'',);
      
        return false;
      }
  });

  let refundPremSect:boolean = true;
  this.questionnaireMaster['section_2'].forEach((qustionObj,index) => {
      
            
                if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
                      if( refundPremSect == true){

                        mafValidationFlag = true;
                        this.globalService.openSnackBar("Please fill details for Refund Premium",'',);

                      }
                    
                }
                else {
                        if(this.medicalHistoryForm[qustionObj.Id] == 'yes'){
                          refundPremSect = true;
                          
                          
                      }
                      else if(this.medicalHistoryForm[qustionObj.Id] == 'no'){ 
                             refundPremSect = false;
                      
                      }            
                }
          
     });

  if(this.genderVal == 'FEMALE'){

    let maternitySectStatus:boolean = true;
    let isPregnant:boolean = false;

  this.questionnaireMaster['section_3'].forEach((qustionObj,index) => {

        if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
             
          if(qustionObj.RelatedQuestionId == 0){
                  
                      maternitySectStatus = false;
                      mafValidationFlag = true;
                      this.globalService.openSnackBar("Please Select all relevant options for Maternity section",'',);
                   
            }
          if(isPregnant == true){
                      
                      maternitySectStatus = false;
                      mafValidationFlag = true;
                      this.globalService.openSnackBar("Please Select all relevant options for Maternity section",'',);
            } 
        }
          else if(qustionObj.QuestionText == 'Are you currently pregnant?'){
               
                if(this.medicalHistoryForm[qustionObj.Id] == 'yes'){
                    isPregnant = true;
                }
      }

    });
   
}


  if(this.selectAllMedicalYesNo == 'yes' || this.selectAllMedicalYesNo == '' ){

   
        this.questionnaireMaster['section_9'].forEach((qustionObj) => {

                if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null ){
                  mafValidationFlag = true;
                  this.globalService.openSnackBar("Please Select all Relevant options for Medical condition",'',);
                  return false;
                }
        });

        this.questionnaireMaster['section_4'].forEach((qustionObj) => {

            if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
              mafValidationFlag = true;
              this.globalService.openSnackBar("Please Select all Relevant options for Diagnosis Status",'',);
              return false;
            }
        });
   
        this.questionnaireMaster['section_5'].forEach((qustionObj) => {

          if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
            mafValidationFlag = true;
            this.globalService.openSnackBar("Please Select all Relevant options for Diagnosis Status",'',);
            return false;
          }
        });

        this.questionnaireMaster['section_6'].forEach((qustionObj) => {
            if((this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null)){
              mafValidationFlag = true;
              this.globalService.openSnackBar("Please fill all the mandatory details for How often do the symptoms occur ?",'',);
              return false;
            }
        });

        this.questionnaireMaster['section_8'].forEach((qustionObj) => {
          if(this.medicalHistoryForm[qustionObj.Id] == '' || this.medicalHistoryForm[qustionObj.Id] == null){
            mafValidationFlag = true;
            this.globalService.openSnackBar("Please fill all the mandatory details for Systolic/ distolic readings",'',);
            return false;
          }
      });
  }

    if(this.medicalHistoryForm['27'] == '' || this.medicalHistoryForm['27'] == null){
          this.globalService.openSnackBar("Signature is required",'',);
          return false;
      }

      let periodDate =  this.medicalHistoryForm['23'];
               this.medicalHistoryForm['23'] = this.convertEIDExpDate(periodDate);
               console.log(this.medicalHistoryForm['23']);

  if(!mafValidationFlag){
console.log(this.selectedMember)
        this.issuePolicyService.saveSMTMemberMedicalHistory(this.selectedMember,this.medicalHistoryForm,this.quoteNum,this.medicalHistory.bloodGroup,this.medicalHistory.height,this.medicalHistory.weight,this.email,this.partnerId).subscribe( res => {
                   let updateMemResponse = res;

                
            });

            Swal.fire("Thank you for providing your Medical Application Form"," we will contact your representative if in case we need additional information",'success');

             setTimeout(() => {
             
                      this.redirectPage();

              },3000);  
          
    }
  


 }
   //----------- CONVERT DATE INTO DD/MM/YYYY FORMAT ------------//
  convertEIDExpDate(inputData){
    
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(inputData);
      //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
      return ([ pad(d.getDate()),pad(d.getMonth()+1),d.getFullYear()].join('/'));
  }

  getAccessToMAFForm(){
    this.getMemberDetails();
    this.issuePolicyService.validateMAFAccess(this.quoteNum,'0',this.token).subscribe(res =>{
          // this.memberId = res.validateMAFAccess.MemberId;

          // this.smeMedicalService.sendMafPopulate(this.quoteNum,this.memberId).subscribe((res:any)=>{
          //   console.log(res);
          //   this.quotationDetail = res;
          //   this.memberDetail = res.senior_members;
          //   this.getMemberDetails();
          // })

          // this.quotationDetail = res.QuotationDetail[0];
          // this.memberDetail = res.Members;

          this.regionArr = res.Region;
          this.maritalstatusArr = res.MaritalStatus;
          //this.coveragetypeArr = formDataRes.Coverage;
          this.nationalityArr = res.Nationality
          this.mobileArr  = res.MobileCode;
          this.RelationshipArr = res.Relationship;
          this.salaryArr = res.Salary;
          this.genderArr = res.Gender;

          this.email = res.validateMAFAccess.CustEmail;
          this.partnerId = res.validateMAFAccess.PartnerId;
          // this.selectedMember.MemberID = this.memberId;
                // this.getMemberDetails();

        });
  }

   //--------------------FOR ADDITIONAL MEMBER DETAILS -----------------//
   getMemberDetails(){
    
    this.smeMedicalService.sendMafPopulate(this.quoteNum,this.memberId).subscribe((res:any)=>{
              
                  let memberlistRes = res; 
                this.quotationDetail = memberlistRes; 
                this.memberDetail = memberlistRes.senior_members;

                this.memberDetail.forEach((item,i)=>{
                  // this.selectedMember = item;
                        if(item.member_id == this.memberId){
                          this.selectedMember = item;
                           this.medicalHistory.PlanName = this.quotationDetail.plan_name ;
                           this.medicalHistory.insuredName = this.quotationDetail.insured_name;
                           this.medicalHistory.FirstName = item.name;
                           
                           this.medicalHistory.LastName = item.MemberLastName ;
                           this.medicalHistory.DOB = this.convertEIDExpDate(item.dob);
                           this.medicalHistory.height = item.MemberHeight;
                           this.medicalHistory.weight = item.MemberWeight;
                           this.medicalHistory.signatureDate = this.convertEIDExpDate(this.maxDate);
                           this.medicalHistory.signatureDate1 = this.convertEIDExpDate(this.maxDate);
                           this.medicalHistory.inceptionDate = this.convertEIDExpDate(this.quotationDetail.start_date);
                           this.medicalHistory.policyID = this.quotationDetail.quote_id;
                           this.medicalHistory.bloodGroup = item.bloodGroup;
                          //  this.medicalHistory.nationlity = this.formDetail.controls[this.activeIndex].get("nationality").value;
                           
                           // UAE residence
                           this.uaeResidentArr.forEach((obj, k) =>{
                             if(obj.value === '0'){
                               this.medicalHistory.uaeRes = obj;
                             }
                           });
                          //relationship
                            //  this.RelationshipArr.forEach((obj, k) =>{
                            //    if(obj.value === item.relation){
                            //      this.medicalHistory.relation = obj;
                            //    }
                            //  });
                             this.relationVal = item.relation;
                           //Gender
                          //  this.genderArr.forEach((obj, k)=>{
                             if(item.gender == 'F'){
                              this.genderVal = 'FEMALE';
                             }else{
                              this.genderVal = 'MALE';
                             }
                          //  });
                       
                        }

                        this.questionnaireMaster["section_9"].forEach((obj,index) => {
       
                          if(obj.QuestionText == 'Name of applicant')
                          {
                            this.medicalHistoryForm[obj.Id] = item.MemberFirstName;
                          }
                          if(obj.QuestionText == 'Age')
                          {
                          
                            this.medicalHistoryForm[obj.Id] = item.MemberAge;
                          }
                          if(obj.QuestionText == 'Date of application')
                          {
                           
                            this.medicalHistoryForm[obj.Id] = this.convertEIDExpDate(this.maxDate);
                          }
                          if(obj.QuestionText == 'Sex')
                          {
                            this.genderArr.forEach((p,k)=>{
                             
                                if(p.value == item.Gender){
                                  this.medicalHistoryForm[obj.Id] = p;
                                }
                            })
                          }
                        });
                });

                  //-------------- FOR MEDICAL CONDITION----------------------//

          this.questionnaireMaster["section_8"].forEach((item,index) => {
        
              if(item.QuestionText == 'Systolic:')
              {
                this.medicalHistoryForm[item.Id] = '0';
              }
        
              if(item.QuestionText == 'Diastolic:')
              {
                this.medicalHistoryForm[item.Id] = '0';
              }
          });

     




       });

   }   

   redirectPage(){

          this.issuePolicyService.redirectPages().subscribe(res =>{

                    let redirectLink = res.redirection_link;
      
                    window.open(redirectLink,"_self");
                              
          });
   }

   toggleDigitalMAF(index) {
    if (this.isDigitalMAF[index]) {
        this.isUploadMAF[index] = false;
        this.hasCondition[index] = 'no';
        console.log('Digital MAF checked');
    }
  }

  toggleUploadMAF(index) {
    if (this.isUploadMAF[index]) {
        // this.isDigitalMAF.fill(false);
        this.isDigitalMAF[index] = false;
      this.hasCondition[index] = 'yes';
        console.log('Upload MAF checked');
    }
  }

  generatePDF(quoteNum,ID,i)
  {
    this.pdfLoader[i] = true;
    
        this.issuePolicyService.medicalHistoryFormPdfGenerate(quoteNum,ID).subscribe(res =>{
        let pdfRes = res;
        this.pdfLoader[i] = false;
      
        window.open( pdfRes.pdfPath, "_blank");
       
      });
  }

  uploadMAF(event,QuotationNumber,docName,userName,memberIndex,FiledName){
    this.mafUploadStatus = false;
    // this.mafFileData = undefined;    
    // this.mafFileData = event.target.files[0];
      this.mafLoader[memberIndex] = 'true';
console.log(this.hasCondition)
      const formData = new FormData();
        formData.append('file',event.target.files[0]);
        formData.append('QuotationNumber',QuotationNumber);
        formData.append('docName',docName);
        formData.append('userName',userName);

        this.issuePolicyService.saveMemberFiles(formData).subscribe( res => {
          let updateMemResponse = res;
          
        if(updateMemResponse.res_code == 1){
        
              this.mafLoader[memberIndex] = this.globalService.spinnerHide();       
              let fileType = updateMemResponse.File.split(".");
              fileType = fileType[fileType.length-1];
          
              let  formArrayValue:any = this.memberDetail[memberIndex];

              formArrayValue['MAFFileName'] = updateMemResponse.File;
              formArrayValue['MAFFilePath'] = updateMemResponse.FileDir ;
            
                if(fileType=="pdf") {
                        formArrayValue['MAFFileType'] ="PDF" ;
                 } else {
                        // formArrayValue['MAFFileType'] ="IMG" ;
                        Swal.fire("Select only PDF");
                        return false;
                    }

                formArrayValue["docMAF"]="";

                this.issuePolicyService.uploadSMTMAF(this.isDigitalMAF[memberIndex],this.isUploadMAF[memberIndex],this.hasCondition[memberIndex],this.quotationDetail.quote_ref,QuotationNumber,this.memberDetail[memberIndex].member_id,formArrayValue['MAFFilePath'],formArrayValue['MAFFileName'],'UPLOAD').subscribe( maf_res =>{
                      let mafRes = maf_res;
                      if(mafRes.status == 'Success')
                      {
                        this.mafUploadStatus = true;
                        Swal.fire("Thank you for providing your Medical Application Form"," we will contact your representative if in case we need additional information",'success');
                        setTimeout(() => {
                          this.redirectPage();},3000); 
                      }
                      
                });
                //Added condition by Anju if the condition is true
                // if(this.hasCondition=='yes'){
                    if(this.hasCondition.includes('yes')){
                this.MAFClean = false;  // Upload MAF must go for referral if existing condition selected as Yes
                }
        
        }

          if(updateMemResponse.res_code == 5){
            this.mafLoader[memberIndex]=this.globalService.spinnerHide(); 
           
            Swal.fire(updateMemResponse.res_status);
          }

          if(updateMemResponse.res_code == 6){
            this.mafLoader[memberIndex]=this.globalService.spinnerHide(); 
           
            Swal.fire(updateMemResponse.res_status);
          }
          
        });
  }
  public callMedicalHistory(item,frame,index){
    frame.show()
    //alert(1);
  }

}
