import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HomequoteService } from '../../../_service/homequote.service';
import { MotorquoteService } from '../../../_service/motorquote.service';
import { GlobalService } from "../../../_service/global.service";
import { Subscription } from 'rxjs/Subscription';
import { config } from '../../../config';
import { TraveInsuranceService } from '../../../_service/traveInsurance.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class MotorThankyouComponent implements OnInit {

  public subscriptions: Subscription[] = [];
  public policyNum:any; paymentLoader:boolean = false; quoteNum:any;   localStorDta:any;  orderNumber:any; orderToken:any; policySceduleLink:any;
  public payment_transaction_ID_status:any; payment_Transaction_ID:any = ''; token:any; payPolicyNum:any; debit_Note_Url:any; polUID :any; credit_Note_Url:any; policy_Wording_Url:any;
  public creditNoteLink:any = ''; debitNoteLink:any; hirePurchaselink:any; bankLetterLink:any; response_msg:any =''; policyNumber:any = '';  navBarChange=0; pageStatus:any; CRSQuoteNumber:any;
  UIDNo :any; pol_schedule_IIRIS_url:any; docLoader:boolean = false; creditLoader:boolean = false; policyScedLoder:boolean =false;  showHideLoader:boolean = true; access_type:boolean = false;
  showDocumentLoader:boolean = false; hire_purchase_clause:any; mortgageBank:any; counter:number = 1; policyIssueError:boolean = false; bank_letter_clause:any;
  quoteDetail: any;
  paymentMode: any;
  
  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public homeQuoteService:HomequoteService, public globalService:GlobalService,public motorQuoteService:MotorquoteService,public travelInsuranceService:TraveInsuranceService) { }


  ngOnInit() {
    // const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    // const statusModSub =  this.globalService.navChanged.subscribe(data => {
    //   localStorage.setItem('sidebarHideShoe',data.toString());
    
    //   this.navBarChange = data;
    
    // }) ;

    // const window_width = $(window).width();
    //   let $sidebar = $('.sidebar');
    //   let $sidebar_responsive = $('body > .navbar-collapse');
    //   let $sidebar_img_container = $sidebar.find('.sidebar-background');

    
    // this.subscriptions.push(statusModSub);

    this.localStorDta =  this.globalService.getLocalStorageData();
    this.orderNumber =  localStorage.getItem("Payment_Order_ID");
    this.orderToken  =  localStorage.getItem("Payment_Order_Tokan");
    this.quoteNum = localStorage.getItem('Payment_Quotation_Number');
    this.policySceduleLink = localStorage.getItem('schedulelink');
    this.creditNoteLink = localStorage.getItem('creditNote');
    this.debitNoteLink = localStorage.getItem('debitNote');
    this.hire_purchase_clause = localStorage.getItem('hirePurchase');
    this.bank_letter_clause = localStorage.getItem('bankLetter');
    this.policyNumber =  localStorage.getItem('Policy_Number');
    this.pageStatus = localStorage.getItem('pageStatus');
    this.CRSQuoteNumber = localStorage.getItem('CRS_Quotation_Number');
    this.polUID = localStorage.getItem('Policy_UID');
    this.mortgageBank = localStorage.getItem('mortgage_bank');
  
        this.hirePurchaselink = this.hire_purchase_clause + this.polUID;
        this.bankLetterLink = this.bank_letter_clause + this.polUID;
  
        this.policy_Wording_Url = 'https://b2bstaging.fidelityunited.ae/pdfdocuments/hc/6006.2020.01.23_Manzil_Policy_Wording.pdf';
           console.log(this.pageStatus)
              if(this.pageStatus =='PAYMENT' || this.pageStatus =='SENDPAYMENTLINK'){
                console.log("inside payment")
                  this.showHideLoader = true;
                  this.getQuotationDetail();//Added by Anju for saving Aditional Info                 
              }

              if( this.pageStatus =='ISSUPOLICY' && ( this.policyNumber == undefined || this.policyNumber == 'undefined' || this.policyNumber == null  || this.policyNumber == 'null' || this.policyNumber == '')){
                      this.approvePolicyRequest();
              }

   
      this.getCheckCN();
  }

  gotoGetQuote(){
    localStorage.removeItem("schedulelink");
    localStorage.removeItem("hirePurchase");
    localStorage.removeItem('Payment_Quotation_Number');
    localStorage.removeItem('CRS_Quotation_Number');
    localStorage.removeItem('Policy_Number');
    localStorage.removeItem('pageStatus');
    this._route.navigateByUrl('/agentMotor/motorquote');
}

goToHome(){
    localStorage.removeItem("schedulelink");
    localStorage.removeItem("hirePurchase");
    localStorage.removeItem('Payment_Quotation_Number');
    localStorage.removeItem('CRS_Quotation_Number');
    localStorage.removeItem('Policy_Number');
    localStorage.removeItem('pageStatus');
    this._route.navigateByUrl('/dashboard');
}

viewPolicy(){

}

//---------------------------------- SAVE QUOTATION PAYMENT -------------------------------------------//
saveQuotationPayment(){

  this.motorQuoteService.saveQuotationPayment(this.orderToken,this.payment_transaction_ID_status,this.quoteNum,this.response_msg,this.payment_Transaction_ID,'MT','B2B').subscribe(res =>{
    
      setTimeout(() => {
         this.showHideLoader =false;
      },10000); 
console.log("response",res)
          if(res.res_code == 1){
            console.log("saveQuotationPayment",res)
            this.showHideLoader =false;
            //this.approvePolicyRequest(); // added by shitala for recipt number issue
            this.payPolicyNum = res.Data[0].PolicyReferenceNumber;
            localStorage.removeItem("Payment_Order_ID");
            localStorage.removeItem("Payment_Order_Tokan");
            localStorage.removeItem('Payment_Quotation_Number');
            localStorage.removeItem("SchemeCode");
            localStorage.removeItem('Policy_Number');
            localStorage.removeItem('pageStatus');
          }

          if(res.res_code == 5){
              this._route.navigate(['agentMotor/motorquote']);
          }
      },
        (error) => {
            this.showHideLoader =false;
              console.error(error.status);
          });

}
//-------------------------------- VERIFY QUOTATION PAYMENT ----------------------------------------------//
verifyQuatationPayment(){
console.log("verifyQuatationPayment",this.orderNumber)
  //Modified by Anju for Payment gateway revamp
this.travelInsuranceService.verifyPayment(this.orderNumber).subscribe(res =>{
  let response = res;
  this.response_msg = response.result.payment_result.response_code;;
  this.payment_Transaction_ID =response.result.tran_ref;
         console.log("res code",res.res_code)
           if(res.res_code == 2){
             // if(res.result.transaction_id == null){
                  this.payment_transaction_ID_status = "CANCELLED";
                  this.travelInsuranceService.sendMailDueToCancelPayment(response.result.payment_result.response_message, this.payment_Transaction_ID,'MOTOR').subscribe(res=>{
                    console.log(res);
                    });
                    this._route.navigateByUrl("/motor/cancelpayment/" + this.response_msg);	
              }
             else {
                console.log("transaction is not null")
                this.payment_transaction_ID_status = "ISSUED";
                this.saveAdditionalInfo();
              
            }
    });

}

generatePDF(){

}

getDocumentUrls(type){

        if(type == 1){
        
              window.open(this.policySceduleLink, "_blank");
        }
        if(type == 2){
         
              window.open(this.creditNoteLink, "_blank");
        }
        if(type == 3){
        
              window.open(this.debitNoteLink, "_blank");
        }
        if(type == 4){
   
            window.open(this.hirePurchaselink, "_blank");
        }
        if(type == 5){
   
            window.open(this.bankLetterLink, "_blank");
        }

        localStorage.removeItem('Payment_Quotation_Number');
        localStorage.removeItem('Policy_Number');
        localStorage.removeItem('Policy_UID');

}

goToURL(){
      localStorage.removeItem("schedulelink");
      localStorage.removeItem("hirePurchase"); 
      localStorage.removeItem("bankLetter");
      localStorage.removeItem('Payment_Quotation_Number');
      localStorage.removeItem('Policy_Number');
      localStorage.removeItem('pageStatus');

      if(this.pageStatus == 'SENDPAYMENTLINK'){
            this.motorQuoteService.webSiteLink("B2BMotor").subscribe(res =>{
                    location.href = res.fedility_website ;
            });
      }

      if(this.pageStatus == 'PAYMENT'){
            this._route.navigate(['agentMotor/motorquote']);
      }
          

}

//----------------------------------- APPROVE POLICY REQUEST -------------------------------------//
approvePolicyRequest() {

  this.showDocumentLoader = true;
  this.motorQuoteService.approvePolicyRequest(this.CRSQuoteNumber, this.quoteNum,'B2B').subscribe(res => {
  
              if (res.response_code == 1) {
                let polNum = res.approvePolicyRequest.PolNo;
                let PolicyUid = res.approvePolicyRequest.PolicyUid;
                this.policyNumber = polNum;
                this.polUID = PolicyUid;
            
                this.hirePurchaselink =  this.hire_purchase_clause + this.polUID;
                this.bankLetterLink = this.bank_letter_clause + this.polUID;
                this.viewPolicySummary();
              }
      
           

  });
}


 //------------------------------------ VIEW POLICY SUMMARY ------------------------------------------//
 viewPolicySummary() {

  this.counter = this.counter + 1;
      
              this.motorQuoteService.viewPolicySummary(this.CRSQuoteNumber, this.quoteNum,'B2B').subscribe(res => {
                   
                    if (res.response_code == 1) {
                      this.showDocumentLoader = false;
                      this.policySceduleLink  = res.schedulelink;//Modified by Anju
                      this.creditNoteLink = res.credit_note;
                      this.debitNoteLink = res.debit_note;
                      this.hire_purchase_clause = res.hirePurchaselink;
                      this.bank_letter_clause = res.bankletter;
                      this.mortgageBank =  res.mortgageBank;
                      this.policyNumber = res.policy_number;
                      
                    
                          if( (this.policyNumber == undefined || this.policyNumber == 'undefined' || this.policyNumber == null  || this.policyNumber == 'null' || this.policyNumber == '')){
                               // this.approvePolicyRequest();
                             this.policyNumber = res.portalPolicy
                             console.log("www",this.policyNumber)
                          }else{
                                this.showDocumentLoader = false;
                                this.policyIssueError = true;
                                // send mail to admin 
                          }
                    }else{
                        this.showDocumentLoader = false;
                    }
             });

     
}

getCheckCN(){
      this.motorQuoteService.getCheckCN('MT').subscribe(res =>{

            this.access_type = res.access_type;
      });
}
getQuotationDetail(){

  this.motorQuoteService.getRetrieveQuote(this.quoteNum, 'BTBPORTAL','').subscribe(res => {
    
        if (res.response_code == 1) {
          let responseData = res.quotationDetailsDataForCustomer;
          this.quoteDetail = responseData;
          if(this.pageStatus =='PAYMENT' || this.pageStatus =='SENDPAYMENTLINK'){
            this.paymentMode='ONL';
          }
          this.verifyQuatationPayment();
         
        }
  });
}

saveAdditionalInfo() {
  this.motorQuoteService
    .saveAdditionalInfo(
      this.quoteNum,
      this.quoteDetail[0].QuotationNumber,
      this.quoteDetail[0].Mortgage,
      "N",
      this.quoteDetail[0].InsuredName,
      this.quoteDetail[0].PolicyType,
      this.quoteDetail[0].EngineNumber,
      this.quoteDetail[0].InsuredGender,
      this.paymentMode,
      "B2B",
      this.orderNumber
    )
    .subscribe((res) => {
      if (res.response_code == 1) {
        this.saveQuotationPayment();
      }
     })}



}
