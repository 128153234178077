import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup,Validators,FormControl,FormArray} from "@angular/forms";
import { MarinequoteService } from 'app/_service/marinequote.service';

@Component({
  selector: 'app-marinequote',
  templateUrl: './marinequote.component.html',
  styleUrls: ['./marinequote.component.scss']
})
export class MarinequoteComponent implements OnInit {
  quoteForm : FormGroup
  additionalDetailForm : FormGroup
  businessSrcArr = [
    { value: "1", label: "ALL" },
    { value: "2", label: "Broking" },
    { value: "3", label: "Direct" },
    { value: "3", label: "Agency" },
  ];

  partnerBranchArr = [
    { value: "1", BranchName: "ALL" },
    { value: "2", BranchName: "Broking" },
    { value: "3", BranchName: "Direct" },
    { value: "3", BranchName: "Agency" },
  ];
  partnersArr = [
    { value: "1", PartnerName: "ALL" },
    { value: "2", PartnerName: "Broking" },
    { value: "3", PartnerName: "Direct" },
    { value: "3", PartnerName: "Agency" },
  ];
  loadingby = 20
  countryList: any;
  shipmentTypes: any;
  goodsTypes: any;
  WarehouseCovers: any;
  constructor(public formBuilder: FormBuilder,public marineService: MarinequoteService) { }

  ngOnInit() {
    //POLICYFORM 
    this.quoteForm = this.formBuilder.group({
      partner:['',Validators.required],
      branch:['',Validators.required],
      clientType :["", Validators.required],
      clientName: new FormControl(
        "",[
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(150),
          //Validators.pattern("^[a-zA-Z -']+"),
       ]   
      ),
      shipmentType: [""],
      commodity: ["", Validators.required],
      coverType: ["", Validators.required],
      interestUae: ["", Validators.required],
      goodsDescription: ["", Validators.required],
      warcover: ["", Validators.required],
      packagetype: [""],
      tradeType: ["", Validators.required],
      saleTerms: ["", Validators.required],
      loading: ["", Validators.required],
      invoiceCurrency: ["", Validators.required],
      invoiceValue: ["", Validators.required],
      invoiceReference: ["", Validators.required],
      invoiceDate:  [new Date(Date.now()), Validators.required],
      fromVoyage: ["", Validators.required],
      toVoyage: ["", Validators.required],
      sourceWarehousecover: ["", Validators.required],
      destWarehousecover: ["", Validators.required],
      loadPort: ["", Validators.required],
      destPort: ["", Validators.required],
      surveyAgent: ["", Validators.required],
      voyageArea: ["", Validators.required],
      billNumber: ["",],
      billDate:  [new Date(Date.now())],
      shipmentDate:  [new Date(Date.now()), Validators.required],
      expectedArrivalDate:  [new Date(Date.now())],
      transportmode: ["", Validators.required],
      transitDetails: [""],
      conveyanceType: ["", Validators.required],
      vesselName: [""],
      society: [""],
      builtYear: [""],
      lcbankName: [""],
      lcNumber: [""],
      lcDate:  [new Date(Date.now())],
      lcRemarks:  [new Date(Date.now())]
    });
    this.additionalDetailForm = this.formBuilder.group({
      clientName:['',Validators.required],
      certificateName:['',Validators.required],
      contactPerson :["", Validators.required],
      email :["", Validators.required],
      mobile :["", Validators.required],
      officialNumber :["", Validators.required],
      poBox :[""],
      address :["", Validators.required],
      tradelicenseNumber :["", Validators.required],
      trnNo :[""],
    })
    console.log(this.quoteForm)
    console.log(this.additionalDetailForm)
    this.BindShipmentTypes(""),
    this.BindGoods("")
    this.BindWareHouseCover();
    this.marineService.getCountryList(1).subscribe((res:any)=>{
      console.log(res)
      if(res.status)
      {
        if(res.status.statusCode == 1)
        {
          this.countryList = res.result
          console.log(this.countryList)
        }
      }
    })

        

  }

  //GET SHIPMENT TYPES
  BindShipmentTypes(openCoverPolicyId)
  {
    this.marineService.getShipmentTypes(openCoverPolicyId).subscribe((res:any)=>{
      console.log("Shipment Types",res)
      if(res.status)
      {
        if(res.status.statusCode == 1)
        {
          this.shipmentTypes = res.result
        }
      }
    })
  }

  //GET GOODS
  BindGoods(openCoverPolicyId)
  {
    this.marineService.getGoods(openCoverPolicyId).subscribe((res:any)=>{
      console.log(res)
      if(res.status)
      {
        if(res.status.statusCode == 1)
        {
          this.goodsTypes = res.result
        }
      }
    })
  }

  //GET SOURCE AND DESTINATION WAREHOUSE COVER 
  BindWareHouseCover()
  {
    if(this.quoteForm.get('fromVoyage').value && this.quoteForm.get('toVoyage').value)
    {
      this.marineService.getWareHouseCovers(this.quoteForm.get('fromVoyage').value,this.quoteForm.get('toVoyage').value).subscribe((res:any)=>{
        console.log(res)
        if(res.status)
        {
          if(res.status.statusCode == 1)
          {
            this.WarehouseCovers = res.result
          }
        }
      })
    }

  }
  checkNumber(value){
    // console.log(value)
    if(value > 80){
      this.loadingby = 50
    }
  }
  getLoadingBy(){
    // this.loadingAmount =0;
    // console.log(this.loadingby)
    // this.loadingAmount =  this.basePremium *(this.loadingby/100);
    // this.totalPremiumCalculation();
  }
  getQuotation(stepper){

    console.log("Quotation Form Validity",this.quoteForm)
  }

  getPartnerBranchList(){

  }
  getCountryList(){

  }
}
