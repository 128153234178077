///------------------------------------------ FOR K2kSERVER TESTING -------------------------------//

// export const config = {
//   apiUrl: 'http://win.k2key.in/medical_fidelity_united_api/index.php/',

//   apiUrl2: 'http://win.k2key.in/medical_fidelity_united_api/index.php/',

//   server_name: "http://k2key.in/medical_proj/",

//   // apiUrl2: 'http://k2key.in/medical_fidelity_united_api/api/',
//   //paypalApiUrl:'https://api.sandbox.paypal.com/v1/'

//   encKey: "202019$#@$^@1fed",
//   logOutURL: "http://efinadvisor.gearhostpreview.com/insurance/mdds/logout.aspx",
//   motor_hire_purchase_clause : "http://10.168.10.14:78/DocumentPrint/GID035.aspx?pEndtSrlNo=0&pUserLang=ENG&pLogo=Y&pOutType=pdf&pPolUid="

// };

//------------------------------------------ FOR FIDELITY PRODUCTION -------------------------------//
// export const config = {
//   apiUrl: "http://10.0.1.26:8087/index.php/", // FOR SERVER

//   // apiUrl: 'https://api.fidelityunited.ae/index.php/', // FOR SERVER

//   // apiUrl: 'http://win.k2key.in/medical_fidelity_united_api_dev/index.php/', // FOR LOCAL
//   apiUrl2: "http://10.0.1.26:8087/index.php/",

//   // apiUrl2: 'https://api.fidelityunited.ae/index.php/',

//   encKey: "202019$#@$^@1fed",
//   // server_name: "https://api.fidelityunited.ae/index.php/",
//   server_name: "http://10.0.1.26:8087/index.php/",
//   logOutURL: "http://localhost:22123/logout.aspx",

//   // logOutURL : 'https://portal.fidelityunited.ae/logout.aspx',

//   motor_hire_purchase_clause:
//     "https://iirisgidoc.fidelityunited.ae/DocumentPrint/GID035.aspx?pEndtSrlNo=0&pUserLang=ENG&pLogo=Y&pOutType=pdf&pPolUid=",
//   autologouttime: 15,
//   planBenefitID: 477, //For Roadside Assistance - Bronze .
// };
//------------------------------------------ FOR OCI PRODUCTION -------------------------------//
// export const config = {
//   apiUrl: 'https://ociapi.fidelityunited.ae/index.php//', // FOR SERVER

//  // apiUrl: 'http://win.k2key.in/medical_fidelity_united_api_dev/index.php/', // FOR LOCAL

//   apiUrl2: 'https://ociapi.fidelityunited.ae/index.php/',

//   encKey : "202019$#@$^@1fed",
//   server_name: "https://ociapi.fidelityunited.ae/index.php/",

//   logOutURL : 'http://ociportal.fidelityunited.ae/logout.aspx',

//   motor_hire_purchase_clause : "http://10.168.10.14:78/DocumentPrint/GID035.aspx?pEndtSrlNo=0&pUserLang=ENG&pLogo=Y&pOutType=pdf&pPolUid=",

//   planBenefitID :  477  //For Roadside Assistance - Bronze .

// };

//------------------------------------------ FOR FIDELITY TESTING -------------------------------//
//  export const config = {
//       apiUrl: 'http://apitest.fidelityunited.ae/index.php/', // FOR SERVER
//       apiUrl2: 'http://apitest.fidelityunited.ae/index.php/',
//       encKey: "202019$#@$^@1fed",
//       server_name: "http://apitest.fidelityunited.ae/index.php/",
//       logOutURL: 'http://onlinetest.fidelityunited.ae:8090/testportal/logout.aspx',
//       motor_hire_purchase_clause : "http://10.168.10.14:78/DocumentPrint/GID035.aspx?pEndtSrlNo=0&pUserLang=ENG&pLogo=Y&pOutType=pdf&pPolUid="

// //       apiUrl: 'http://10.168.10.21/index.php/', // FOR SERVER
// //       apiUrl2: 'http://10.168.10.21/index.php/',
// //       encKey: "202019$#@$^@1fed",
// //       server_name: "http://10.168.10.21/index.php/",
// //       logOutURL: 'http://onlinetest.fidelityunited.ae:8090/testportal/logout.aspx',
//  };

//------------------------------------------ FOR FIDELITY TESTING -------------------------------//
export const config = {
  //   apiUrl: 'https://testportalapi.fidelityunited.ae/index.php/', // FOR SERVER
  //   apiUrl2: 'https://testportalapi.fidelityunited.ae/index.php/',
  //   encKey: "202019$#@$^@1fed",
  //   server_name: "https://testportalapi.fidelityunited.ae/index.php/",
  //   logOutURL : 'http://localhost:22123/logout.aspx',
    apiUrl: 'https://b2bmedstaging.fidelityunited.ae/index.php/', // FOR SERVER
    apiUrl2: 'https://b2bmedstaging.fidelityunited.ae/index.php/',
    encKey: "202019$#@$^@1fed",
    server_name: "https://b2bmedstaging.fidelityunited.ae/index.php/",
    // logOutURL: 'https://smestaging.fidelityunited.ae/logout.aspx',
    logInURL: 'https://b2bstaging.fidelityunited.ae/login',
    logOutURL: 'https://b2bstaging.fidelityunited.ae/logout',
    motor_hire_purchase_clause : "http://10.168.10.14:78/DocumentPrint/GID035.aspx?pEndtSrlNo=0&pUserLang=ENG&pLogo=Y&pOutType=pdf&pPolUid=",
autologouttime:15,
planBenefitID :  477,  //For Roadside Assistance - Bronze .
paymentGatewayUrl: "https://mybuddy.fidelityunited.ae/b2b-pay-stag/api/v2/pay/", //Added by Anju for Payment gateway integration
SmeMedCoreURL : "https://mybuddy.fidelityunited.ae/sme-stag/api/core", // SME Medical Core API URL
SmeMedDashboardURL  : "https://mybuddy.fidelityunited.ae/sme-stag/api/dashboard", //SME Medical Dashboard API ULR
nextcareAPIURL : "https://tpaintegration_staging.fidelityunited.ae/api/NextcareTPA", //Nextcare API change
marineAPIURL : "https://fidelityb2bapi_staging.fidelityunited.ae/Marine", // Marine API New Dot Net URL
marineCallAPIURL : "https://fidelityb2bapi_staging.fidelityunited.ae",
petAPIURL:"http://10.170.11.58/api/b2b/", //Added by Anju for pet Renewal integration
iIRISReceiptURL:"http://10.0.3.188:84/DocumentPrint/FID008.aspx?Uid=8dpv4Danp0Y=&Dt=KribUPD1xLwigbtZJXPYNrX5HnPUN5Q6:"//Added by Anju for IIRIS Receipt
,sme_EnableCoverDateEdit:"1,41096,3",
shafafiyaServiceUrl: "https://tpaintegration_staging.fidelityunited.ae/api/Shafafiya", //ShafafiyaService url added by Anju
enableDIGIApproval:'N',// N for Auto-approval , Y for waiting for Actual DG response
enableGeoLocValid:'N',
doh_website_Url:'https://www.tamm.abudhabi/',
haad_user_guide_Arabic_Url:'https://b2bmedstaging.fidelityunited.ae/PDFDocuments/HT/HAADDocuments/TAMM_User_Guide_Health_Insurance_Penalty_Payment_AR.pdf',
haad_user_guide_English_Url:'https://b2bmedstaging.fidelityunited.ae/PDFDocuments/HT/HAADDocuments/TAMM_User_Guide_Health_Insurance_Penalty_Payment_EN.pdf',
motor_terms_and_condition_pdf: 'https://b2bmedstaging.fidelityunited.ae/pdfdocuments/MT/Terms_and_conditions.pdf',//Added by Anju for motor terms and conditions,
};


// ------------------------------------------- FOR K2KEY ONLINE TEST -------------------------------------//
//  export const config = {
//       apiUrl: 'http://win.k2key.in/online_fidelity_k2key/index.php/', // FOR SERVER
//       apiUrl2: 'http://win.k2key.in/online_fidelity_k2key/index.php/',
//       encKey: "202019$#@$^@1fed",
//       server_name: "http://k2key.in/main_insurance_project/",
//       logOutURL: 'http://efinadvisor.gearhostpreview.com/insurance/mdds/logout.aspx',
//          planBenefitID :  509
//  };
