import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';

import { GlobalService } from "../../../_service/global.service";
import { config } from '../../../config';
import Swal from 'sweetalert2';
import { SmequoteService } from 'app/_service/smequote.service';
import { HomequoteService } from 'app/_service/homequote.service';
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';

@Component({
  selector: 'app-thankyoufixed',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouSMEFixedComponent implements OnInit {

  public policyNum:any; paymentLoader:boolean = false; quoteNum:any; public partnerId :any;  localStorDta:any;  orderNumber:any; orderToken:any; corePolicyNumber:any='';
  public payment_transaction_ID_status:any; payment_Transaction_ID:any; token:any; payPolicyNum:any; debit_Note_Url:any; polUID :any; credit_Note_Url:any; policy_Wording_Url:any;
  UIDNo :any; pol_schedule_IIRIS_url:any; docLoader:boolean = false; creditLoader:boolean = false; policyScedLoder:boolean =false;
  responseCode:any;access_type:boolean = false;
  quoteID: string;
  response_msg: any;
  issuePoLoader: boolean=false;
  policyDetail: boolean =false;
  webpolicyNum: any ='';
  PageLoader: boolean =false;
  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public homeQuoteService:HomequoteService, public globalService:GlobalService,public smequoteService: SmequoteService,public travelInsuranceService:TraveInsuranceService) { }

  ngOnInit() {
    this.PageLoader = this.globalService.spinnerShow();
    this.paymentLoader = this.globalService.spinnerHide();
    this.docLoader = this.globalService.spinnerHide();
    this.creditLoader = this.globalService.spinnerHide();
    this.policyScedLoder =  this.globalService.spinnerHide();
    this.localStorDta =  this.globalService.getLocalStorageData();
    this.orderNumber =  localStorage.getItem("Payment_Order_ID");
    this.orderToken  =  localStorage.getItem("Payment_Order_Tokan");
    this.quoteNum = localStorage.getItem('Payment_Quotation_Number');
    this.quoteID =  localStorage.getItem('Payment_Quotation_ID');
    this.debit_Note_Url =  localStorage.getItem('debit_note');
    this.credit_Note_Url =  localStorage.getItem('credit_note');
    this.corePolicyNumber =  localStorage.getItem('CorePolicyNumber');
    this.responseCode =  localStorage.getItem('response_code');
  
    if(this.localStorDta != null){
      this.partnerId = this.localStorDta.Partner_ID;
      }
  console.log(this.responseCode)
    const routeParam = this._activatedroute.snapshot.params;
      if(routeParam){
        this.webpolicyNum = routeParam.policyNumber;
        this.quoteNum = routeParam.quoteid;
        // this.responseCode = routeParam.response_code;
        // this.token = routeParam.Token;
        }
        console.log(this.orderToken)
        if(this.orderToken!=null){
          this.verifyQuatationPayment();
      }else{
        this.policyDetail=this.globalService.spinnerHide();
        this.PageLoader = this.globalService.spinnerShow();
        // this.verifyPayment();
      }
     

      
        // console.log( this.policyNum)
        // console.log( this.quoteNum)
        // console.log(  this.responseCode)
        // console.log( this.token)

        this.policy_Wording_Url = 'http://k2key.in/homeinsdocs/6006.2020.01.23_Manzil_Policy_Wording.pdf';
           
        // if(this.orderNumber!=null)
           // this.verifyQuatationPayment();

        //  localStorage.removeItem("Payment_Order_ID");
        //  localStorage.removeItem("Payment_Order_Tokan");
        //  localStorage.removeItem('Payment_Quotation_Number');
       
  }

  gotoGetQuote(){
      localStorage.removeItem("Payment_Order_ID");
      localStorage.removeItem("Payment_Order_Tokan");
      localStorage.removeItem('Payment_Quotation_Number');
      localStorage.removeItem('flexi_home_debit_note');
      localStorage.removeItem('flexi_home_credit_note');
      localStorage.removeItem('flexi_home_corePolicyNumber');
        this._route.navigateByUrl('sme/smefixed');
  }

  goToHome(){
      localStorage.removeItem("Payment_Order_ID");
      localStorage.removeItem("Payment_Order_Tokan");
      localStorage.removeItem('Payment_Quotation_Number');
      localStorage.removeItem('flexi_home_debit_note');
      localStorage.removeItem('flexi_home_credit_note');
      localStorage.removeItem('flexi_home_corePolicyNumber');
        this._route.navigateByUrl('/dashboard');
  }

  viewPolicy(){

  }
  
  saveQuotationPayment(){
    // this.issuePoLoader = this.globalService.spinnerShow();
    this.policyDetail=this.globalService.spinnerShow();
    this.homeQuoteService.saveQuotationPayment(this.quoteID,this.orderToken,this.payment_transaction_ID_status,this.quoteNum,this.response_msg,this.payment_Transaction_ID,'SM','',).subscribe(res =>{
      
          if(res.res_code == 1){
            // this.issuePoLoader = this.globalService.spinnerHide();
            this.policyDetail=this.globalService.spinnerHide();
            this.webpolicyNum = res.web_policy_number;
            this.corePolicyNumber=res.CorePolicyNumber;
            this.debit_Note_Url=res.debit_note;
            this.credit_Note_Url=res.credit_note;
            // localStorage.removeItem("Payment_Order_ID");
            // localStorage.removeItem("Payment_Order_Tokan");
            // localStorage.removeItem('Payment_Quotation_Number');
            // localStorage.removeItem('flexi_home_debit_note');
            // localStorage.removeItem('flexi_home_credit_note');

          }
    });

}
// response_msg:any ='';
verifyQuatationPayment(){

    this.travelInsuranceService.verifyPayment(this.orderNumber).subscribe(res => {//Modified by Anju for SME Payment gateway revamp
    let response = res;
    console.log(res);
    this.response_msg = response.result.payment_result.response_code;
    this.payment_Transaction_ID = this.orderNumber;
           console.log(this.payment_Transaction_ID);

              if(res.res_code == 2){
                // For Test
                //if(res.result.details == "This invoice is either cancelled by the customer or expired"){	
                  // For Prod  if(res.result.details == "This invoice is either cancelled by the customer or expired" || res.result.response_code == "476"){		
                  this.payment_transaction_ID_status = "CANCELLED";	
                  this._route.navigateByUrl('sme/cancelpayment/' + this.response_msg + "/" +this.quoteNum + "/" +"SM");	
                  this.PageLoader = this.globalService.spinnerHide();	
              }	
              else{	
                this.payment_transaction_ID_status = "ISSUED";	
                this.saveQuotationPayment();	
                this.PageLoader = this.globalService.spinnerHide();	
            }
      });

}

  generatePDF(){

  }

  getPolSchedlPDF(){

      // this.policyNum = this.orderNumber == null ? this.policyNum: this.payPolicyNum;
      this.policyScedLoder =  this.globalService.spinnerShow();
        this.smequoteService.getPolSchedulPDF(this.webpolicyNum,"B2B",this.quoteNum,"DGSME").subscribe(res =>{

              setTimeout(
                function() {
        
                }.bind(this),
                1500
              );
              let polSchedulRes = res;
              if(polSchedulRes.response_code == 1){
                this.policyScedLoder =  this.globalService.spinnerHide();
                window.open(polSchedulRes.pdfPath, "_blank");
              }
        });
  }

  getPolicyDetailPDF(type){ 
    this.docLoader = this.globalService.spinnerHide();
    this.creditLoader = this.globalService.spinnerHide();
          if(type == 1){
            this.smequoteService.GetTaxInvoice(this.webpolicyNum,"B2B",this.partnerId,this.quoteNum).subscribe(res =>{
              setTimeout(function() {}.bind(this),1500);               
                let polSchedulRes = res;
                this.policyScedLoder =  this.globalService.spinnerHide();
                if(polSchedulRes.response_code == 1){
                  // alert(1)
                  window.open(polSchedulRes.pdfPath, "_blank");
                }
             });
            // window.open( this.debit_Note_Url, "_blank");
          }
        
          if(type == 2){
            this.smequoteService.GetTaxInvoice(this.webpolicyNum,"B2B",this.partnerId,this.quoteNum).subscribe(res =>{
              setTimeout(function() {}.bind(this),1500);
                let polSchedulRes = res;
                this.policyScedLoder =  this.globalService.spinnerHide();
                if(polSchedulRes.response_code == 1){
                  // alert(1)
                  window.open(polSchedulRes.pdfPath, "_blank");
                }
             });
            // window.open( this.credit_Note_Url, "_blank");
          }
  }


getPolicyDetailPDF1(type){
     
      if(type == 1){ this.docLoader = this.globalService.spinnerShow(); }
      if(type == 2){ this.creditLoader = this.globalService.spinnerShow(); }

                if(type == 1){
                
                     window.open( this.debit_Note_Url, "_blank");
                     this.docLoader = this.globalService.spinnerHide();
                }
              
                if(type == 2){
                 
                     window.open( this.credit_Note_Url, "_blank");
                     this.creditLoader = this.globalService.spinnerHide();
                }
    
  }
//     getCheckCN(){
//       this.motorQuoteService.getCheckCN('HC').subscribe(res =>{

//             this.access_type = res.access_type;
//       });
// }

ViewPolicy(){
this._route.navigateByUrl('/home/viewPolicy');

}
}






