import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import {MatSnackBar} from '@angular/material/snack-bar';
import { mixinColor } from "@angular/material";
import { Observable } from 'rxjs/Rx';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError, observable } from 'rxjs';
import { config } from './../config';
import Swal from "sweetalert2";



@Injectable()
export class GlobalService {
  apiURL: string = config.apiUrl;
  userData:any=[];
 
  public changeUserInfo:BehaviorSubject<any> = new BehaviorSubject<any>(this.userData);
  public navChanged:BehaviorSubject<number> = new BehaviorSubject<number>( Number(localStorage.getItem('sidebarHideShoe')));
  constructor(private httpClient: HttpClient,private _snackBar: MatSnackBar) {


  //  console.log(this.changeUserInfo.prev());

     console.log(this.changeUserInfo);
     console.log(this.navChanged);

     if(typeof(this.changeUserInfo.value.EmailAddress)=="undefined" ||  this.changeUserInfo.value.EmailAddress==""){
    //  localStorage.clear();
     // window.location.href = config.logOutURL;
     }
   
  }
//--------Navin sir code---------//
public handleError(error) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
  } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}
  
  //------------------------------ FOR GET COVER TYPE ----------------------------------------------//
 public getAllMenu():Observable<any> {
  let localStorDta =  this.getLocalStorageData();

let  email = localStorDta.EmailAddress;
 return this.httpClient.post(`${this.apiURL}Webservice/getAllMenuData`,{userName:email},this.getHeader())
       .pipe(catchError(this.handleError), tap(res => {
         let  response=this.convertResponseInJson(res);

       }));
}
  convertResponseInJson(res)
  {
    let  response:any= res;
    console.log('responseprint',res)
    return response;
  }
   
  getLocalStorageData()
  {
    if (localStorage.getItem("backend-token") != null) {
     let retrievedData = localStorage.getItem("currentUser");
      this.userData = JSON.parse(retrievedData);
      this.changeUserInfo.next(this.userData)
      console.log(this.userData);
      return this.userData;     
  } 
   
  }
    /* START:VALIDATE DATA NUM/decimal with number  */
    checkValidInputData(event: any,type) {
      var pattern:any;
      if(type=='1'){
          pattern = /[0-9\+\-\ ]/;//validae only number
      }
      if(type=='2'){
         pattern= /^[0-9]*\.?[0-9]*$/;//validae decimal with number 
      }
      if(type=='3'){
        pattern= /^[A-Za-z0-9]+$/;//validae alphanumeric 
      }
      if(type=='4'){
        pattern= /^[A-Za-z0-9 ]+$/;//validae alphanumeric with space
      
      }
      if(type=='5'){
        pattern = /^[(\d{3}\)\s?\d{3}-\d{4}]+$/;
       // pattern = /[0-9\+\/\ ]/;//validae only number with \
      }
      if(type == '6'){
          pattern= /^['A-Za-z0-9\-\\.\\/\']+$/; 
      }
      if(type=='7'){
        pattern= /^[A-Za-z ]+$/;//validae alphabets with space
       // pattern= /^['A-Za-z0-9 \-\ \.\ \:\ \;\ \_\ \%\ \#\ \@\ \%\']+$/;
      }
      if(type=='8'){
         pattern = /^[a-zA-Z \-\']+/; //Only alphabets
      }
      if(type=='10'){
          // pattern =  /^[A-Za-z0-9 #&()]+$/; //for location table
          pattern = /^[A-Za-z0-9#&()_., -]+$/;
      }
      if(type=='11'){
             pattern = /^[0-9 \,\']+$/;
      }
      if(type=='12'){
             pattern= /^['A-Za-z0-9 \-\ \.\ \(\ \)\ \\\\ \#\ \/\ \,\ \&\']+$/;//validae alphanumeric with space ^[a-zA-Z \-\ \.\']+ ............ADDRESS
      }
     
      if(type=='13'){
        pattern= /^['A-Za-z0-9 \-\ \.\ \&\ \/\']+$/;
      }
      
      if (event.type === 'keypress') {
        let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
    else if (event.type === 'paste') {    
      navigator.clipboard.readText().then((pastedData) => {
        if (event.keyCode != 8 && !pattern.test(pastedData)) {
          Swal.fire('Invalid characters found in pasted data');
          event.preventDefault(); // Prevent default paste behavior
        } 
      });
    }
}




    /* END:VALIDATE DATA NUM/decimal with number  */
   
     /* START:VALIDATE ALL EVENTS  */
    preventAllEvents(e: any)
    {
      if (e.ctrlKey && (e.keyCode == 88 || e.keyCode == 67 || e.keyCode == 86)) {
       e.preventDefault();
      }
    }

    getHeader(){
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
         // Authorization:  'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6bnVsbCwiZGF0YSI6eyJmaXJzdG5hbWUiOiJRdWVlbnplbmQgV2ViIiwibGFzdG5hbWUiOiJTb2x1dGlvbiIsInVzZXJ0eXBlIjoiYWRtaW4iLCJlbWFpbCI6InRlc3QuYnVzaW5lc3N1c2VyMUBnbWFpbC5jb20iLCJpbWFnZSI6InBpY3R1cmVfclhUZkFfRGluZXNoIENsZyAyMDE0MTAzMV8xNDQxMDEuanBnIiwidXNlcmlkIjoiMiIsInVzZXJuYW1lIjoidGVzdC5idXNpbmVzc3VzZXIxQGdtYWlsLmNvbSIsImFjY291bnRpbmdfdXNlciI6MSwiZmlybV9pZCI6IjIiLCJpc19yZXZpZXdlciI6IjEifSwiaWF0IjoxNTc2MTQ5OTA3LCJleHAiOjE1NzYxNjc5MDd9.HnO0fF4FWu-mDQW9LzutzqvVHBZQHvBmiD9fZN1eJB4'
         Authorization:  'Bearer ' +localStorage.getItem("backend-token")
      
        })
      }
      return httpOptions;
    }

    getHeaderForFileUpload(){
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
         // Authorization:  'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6bnVsbCwiZGF0YSI6eyJmaXJzdG5hbWUiOiJRdWVlbnplbmQgV2ViIiwibGFzdG5hbWUiOiJTb2x1dGlvbiIsInVzZXJ0eXBlIjoiYWRtaW4iLCJlbWFpbCI6InRlc3QuYnVzaW5lc3N1c2VyMUBnbWFpbC5jb20iLCJpbWFnZSI6InBpY3R1cmVfclhUZkFfRGluZXNoIENsZyAyMDE0MTAzMV8xNDQxMDEuanBnIiwidXNlcmlkIjoiMiIsInVzZXJuYW1lIjoidGVzdC5idXNpbmVzc3VzZXIxQGdtYWlsLmNvbSIsImFjY291bnRpbmdfdXNlciI6MSwiZmlybV9pZCI6IjIiLCJpc19yZXZpZXdlciI6IjEifSwiaWF0IjoxNTc2MTQ5OTA3LCJleHAiOjE1NzYxNjc5MDd9.HnO0fF4FWu-mDQW9LzutzqvVHBZQHvBmiD9fZN1eJB4'
         Authorization:  'Bearer ' +localStorage.getItem("backend-token")
      
        })
      }
      return httpOptions;
    }

    spinnerHide(){
      return true;
    }
  
    
    spinnerShow(){
      return false;
    }

    openSnackBar(message: string, action: string) {
     
      this._snackBar.open(message, action, {
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center',
        direction:'ltr',
        panelClass : ['snackbar']
      });
    }

//----------- CONVERT DATE INTO DD/MM/YYYY FORMAT ------------//
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    return ([ pad(d.getDate()),pad(d.getMonth()+1),d.getFullYear()].join('-'));
    
  }

  
}
