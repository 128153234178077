import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MotorquoteService } from '../../../_service/motorquote.service';
import Swal from 'sweetalert2';
import { GlobalService } from '../../../_service/global.service';
import { TraveInsuranceService } from '../../../_service/traveInsurance.service';

@Component({
  selector: 'app-motorcancelpayment',
  templateUrl: './motorcancelpayment.component.html',
  styleUrls: ['./motorcancelpayment.component.scss']
})
export class MotorcancelpaymentComponent implements OnInit {

  quotenumber:any; paymentLoader: boolean = false; payment_cancel_reason : any; res_code = 0; errorMsg:any; pageStatus:any;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public motorQuoteService: MotorquoteService,public globalService:GlobalService, public travelInsuranceService:TraveInsuranceService) { }

  ngOnInit() {

    this.paymentLoader = this.globalService.spinnerHide();
    this.quotenumber = localStorage.getItem('Payment_Quotation_Number');
    this.pageStatus = localStorage.getItem('pageStatus');
    
    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){
     this.res_code = routeParam.Res_Code;
     this.getPaymentErrorMsg(this.res_code);
    }

    console.log(this.pageStatus);

  }

  goBackto(){
    // this._route.navigateByUrl('/agentMotor/motorquote');
      window.open('https://fidelityunited.ae','_self');
  }

  retryPayment(){
    
    let return_url = "http://k2key.in/motor_ins//dashboard";
    let site_url = "http://k2key.in/motor_ins/" + this._route.url;

    this.paymentLoader = this.globalService.spinnerShow();
   // this.showHideLoader.additionalDetail = true;
   //Modified by Anju for Payment gateway revamp
    this.travelInsuranceService.paymentAuth(this.quotenumber, site_url, return_url, 'MT', '1', '','','B2B').subscribe(res => {
      let payRes = res;
      console.log(payRes);
      if(payRes.status == 'Failed') {
        Swal.fire(payRes.res_msg);
         this.paymentLoader = this.globalService.spinnerHide();
       
        return false;
      }

      else if (payRes.status == 'Success') {

         this.paymentLoader = this.globalService.spinnerHide();
        
        localStorage.setItem('Payment_Order_ID', payRes.data.tran_ref);
        localStorage.setItem('Payment_Order_Tokan',  payRes.data.token);
        localStorage.setItem('Payment_Quotation_Number', this.quotenumber);
        window.location.href = payRes.data.redirect_url;


      }


    });
  }

  getPaymentErrorMsg(res_code){
      this.motorQuoteService.getPaymentErrorMsg('PAYMENT',res_code,'ENG').subscribe(res =>{
            this.errorMsg = res.errorMessage[0].ErrorMessage;
      });
  }

  retrieveQuote(){
    
      if(this.pageStatus == 'PAYMENT'){
          this._route.navigateByUrl( "agentMotor/MTquotepage/update/" +  this.quotenumber);
      }
        

  }

 

}
