import { Component, OnInit } from '@angular/core';
import { IssuepolicyService } from './../../_service/issuepolicy.service';	
import { GlobalService } from "./../../_service/global.service";	
import { Router,ActivatedRoute } from '@angular/router';	
import { MotorquoteService } from './../../_service/motorquote.service';
import { HomequoteService } from 'app/_service/homequote.service';
import { SmequoteService } from 'app/_service/smequote.service';
import Swal from 'sweetalert2';
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';
@Component({
  selector: 'app-smepaymentinvoice',
  templateUrl: './smepaymentinvoice.component.html',
  styleUrls: ['./smepaymentinvoice.component.scss']
})
export class SmepaymentinvoiceComponent implements OnInit {
  public quoteNum:any; totalPremium :any; quoteDetail:any = []; paymentLoader:boolean = false; quote_Status:any; paymentMsgShow:boolean = false; retrieveData:any;
  localStorDta: any;
  partnerId: any;
  cedant_id: any;
  quoitationID: string;
  issuePoLoader: boolean =false;
  quoteDetailData: any;
  toalContri: any;
  toalContriLoader: boolean =false;
  sourceType='B2B'
  policyNumber: any;	
  quoteStatus: any;
  productCode: any;
  quoteType: any;
  toDayDate: Date;
  ExpLinkMsgShow: boolean =false;
  linkExpiredDate: any;
  
  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public globalService:GlobalService,
    public smequoteService: SmequoteService,public homeQuoteService: HomequoteService,
      issuePolicyService: IssuepolicyService, public motorQuoteService: MotorquoteService,
      public travelInsuranceService: TraveInsuranceService  ) { }

  ngOnInit() {
    this.toDayDate = new Date(Date.now());
    this.paymentLoader = this.globalService.spinnerHide();
    this.issuePoLoader = this.globalService.spinnerHide();
    this.toalContriLoader = this.globalService.spinnerHide();

    const routeParam = this._activatedroute.snapshot.params;
    this.localStorDta =  this.globalService.getLocalStorageData();
    if(this.localStorDta != null){	
      this.partnerId = this.localStorDta.Partner_ID;	
      this.cedant_id = this.localStorDta.Cedant_ID;	
    }
    
    if(routeParam){

      this.quoteNum = routeParam.QuotationNumber;
      this.getQuoteDetail();

      }


     


  }

  getQuoteDetail(){
    this.toalContriLoader = this.globalService.spinnerShow();
    this.smequoteService.getQuotationDetails('',this.quoteNum,'1','B2B').subscribe(res =>{	
      this.toalContriLoader = this.globalService.spinnerHide();	
      this.quoteDetailData = res.quotedetails[0];	
      this.quoitationID = this.quoteDetailData.QuotationId	
      this.toalContri = this.quoteDetailData.TotalContribution;	
      this.quoteStatus = this.quoteDetailData.StatusDesc;	
      this.productCode=this.quoteDetailData.ProductCode;
      this.linkExpiredDate = this.quoteDetailData.PayLinkExpDt;
      //var diffhours = Number((new Date(this.toDayDate).getTime()  - new Date(this.linkExpiredDate).getTime() )/(60*60*1000))
      const today = new Date();
      const today_day= today.setHours(0, 0, 0, 0);
      let linkExpiredDate= new Date(this.linkExpiredDate);
      const linkExpiredDate_day= linkExpiredDate.setHours(0, 0, 0, 0);
      let isLinkExpired:boolean=true;
      console.log('linkExpiredDate:'+linkExpiredDate+',linkExpiredDate_day:'+linkExpiredDate_day+',today_day:'+today_day);
      if( linkExpiredDate_day>=today_day){
        isLinkExpired=false;
      }
      console.log('isLinkExpired:'+isLinkExpired); 
      if(this.quoteStatus == "ISSUED"){	
        this.paymentMsgShow = true;	
       }

      else if(isLinkExpired){
        this.ExpLinkMsgShow = true;
      }

       if( this.productCode=='SMEBI'){
        this.quoteType='SHIELD';
       }
       else if(this.productCode=='DGSME'){
       this.quoteType='FIXED';
       }
       else if(this.productCode=='SMECONDO68'){
       this.quoteType='ALLRISK';
       }
});	
}

  payTabPayment(){
       // this.policyNumber = res.policy_number;
    let return_url = "http://localhost:4200/dashboard";
    let site_url = "http://localhost:4200/" + this._route.url;
    this.paymentLoader = this.globalService.spinnerShow();
      this.travelInsuranceService.paymentAuth(this.quoteNum, site_url, return_url, 'SM', '1', '', this.quoteType, 'B2B').subscribe(res => {//Modified by Anju for SME Payment gateway revamp
          if (res.status == 'Failed'){
        Swal.fire(res.res_msg);
        // this.policyLoader = this.globalService.spinnerHide();
      
        return false;
  }

  else if( res.status == 'Success' ){
  
        // this.policyLoader = this.globalService.spinnerHide();
              localStorage.setItem('Payment_Order_ID', res.data.tran_ref);
        localStorage.setItem('Payment_Order_Tokan', res.data.token);
        localStorage.setItem('Payment_Quotation_Number',this.quoteNum);
        localStorage.setItem('Payment_Quotation_ID',this.quoitationID);
              window.location.href = res.data.redirect_url;
        // window.open(res.result.redirect_url, '_blank');
     

      }

    })
  }
}
