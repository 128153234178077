import { Component, OnInit } from '@angular/core';
import { IssuepolicyService } from '../../../_service/issuepolicy.service';
import { MotorquoteService } from '../../../_service/motorquote.service';
import { GlobalService } from "../../../_service/global.service";
import { Router,ActivatedRoute } from '@angular/router';
import { config } from '../../../config';
import { TraveInsuranceService } from '../../../_service/traveInsurance.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paymentinvoice',
  templateUrl: './paymentinvoice.component.html',
  styleUrls: ['./paymentinvoice.component.scss']
})
export class PaymentinvoiceComponent implements OnInit {

  public quoteNum:any; totalPremium :any; quoteDetail:any = []; paymentLoader:boolean = false; quote_Status:any; paymentMsgShow:boolean = false;toDayDate: Date;ExpLinkMsgShow: boolean =false;
  linkExpiredDate: any;
  selectedPartnerId: any;
  selectedBranchId: any;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public issuePolicyService: IssuepolicyService,public globalService:GlobalService, public motorQuoteService: MotorquoteService, public travelInsurance: TraveInsuranceService) { }

  ngOnInit() {
    this.toDayDate = new Date(Date.now());
    this.paymentLoader = this.globalService.spinnerHide();

    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){

      this.quoteNum = routeParam.QuotationNumber;

      }
      
      
      this.getMemberDetail();
  }

  getMemberDetail(){
    this.issuePolicyService.getMembersDetailByQuoteNo(this.quoteNum).subscribe(res =>{
          let response = res;

          this.quoteDetail = response.QuotationDetail[0];

          this.totalPremium = this.quoteDetail.TotalWithHAAD;
          this.quote_Status = this.quoteDetail.QuoteStatus;
          this.linkExpiredDate = this.quoteDetail.PayLinkExpDt;
          this.selectedPartnerId = this.quoteDetail.CedantBrokerId;
          this.selectedBranchId = this.quoteDetail.CedantBrokerBranchId;
          // console.log("expiry date",new Date(this.linkExpiredDate).getHours())
          var diffhours = Number((new Date(this.toDayDate).getTime()  - new Date(this.linkExpiredDate).getTime() )/(60*60*1000))
          console.log("expiry date",Math.ceil((new Date(this.toDayDate).getTime()  - new Date(this.linkExpiredDate).getTime() )/(60*60*1000)))
          if(this.quote_Status == "ISSUED" || this.quote_Status == "TAKENUP"){
              this.paymentMsgShow = true;
          }
          if((this.quote_Status == "QUOTED" ||this.quote_Status == "BOUND" || this.quote_Status == 'INITIATED' || this.quote_Status == 'QUOTECONFIRMED' )  && diffhours < 24){
            this.ExpLinkMsgShow = false;
          }else{
             //alert(1)
            this.ExpLinkMsgShow = true;
          }

         
    });


  }
  convertStartDate(inputFormat){
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    return ([pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/'));

  }

  payTabPayment(){

    this.paymentMsgShow = false;
    let server_name = config.server_name;

   let return_url = "http://localhost:4200/dashboard";
   let site_url = "http://localhost:4200/" + this._route.url;

  this.paymentLoader = this.globalService.spinnerShow();

 //Added by Anju for party active status check
 this.motorQuoteService
 .checkPartnerCreditLimit("Medical",this.totalPremium,this.selectedPartnerId,this.selectedBranchId)
 .subscribe((result) => {
   console.log(result);
//Changed the cl_msg to cl_status to use status code
if(result.cl_status !='200012' )
 {
  this.travelInsurance.paymentAuth(this.quoteNum,site_url,return_url,'HT','1','','','B2B').subscribe(res => {
      let payRes = res;
    console.log(payRes);
      if(payRes.status == 'Failed') {
        alert(payRes.res_msg);
        this.paymentLoader = this.globalService.spinnerHide();
        return false;
  }

  else if(payRes.status == 'Success' ){

        this.paymentLoader = this.globalService.spinnerHide();
        localStorage.setItem('Payment_Order_ID',payRes.data.tran_ref);
        localStorage.setItem('Payment_Order_Tokan',payRes.data.token);
        localStorage.setItem('Payment_Quotation_Number',this.quoteNum);

        window.location.href = payRes.data.redirect_url;

      } 
    });
  }
  else if(result.cl_status =='200012'){
      Swal.fire("Party not active.");
      this.paymentLoader = this.globalService.spinnerHide();
      }
   });
  }

}
