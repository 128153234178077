import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { catchError, tap, map } from "rxjs/operators";
import { of, throwError, observable } from "rxjs";
import { GlobalService } from "../_service/global.service";
import { config } from "./../config";
import { MatSnackBar } from "@angular/material/snack-bar";
import Swal from "sweetalert2";
import { NgModel } from "@angular/forms";
import { makeArray } from "jquery";

@Injectable({
  providedIn: "root",
})
export class MotorquoteService {
  apiURL: string = config.apiUrl;

  constructor(
    private httpClient: HttpClient,
    public _globalService: GlobalService,
    private _snackBar: MatSnackBar
  ) {}

  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: "top",
      horizontalPosition: "center",
      direction: "ltr",
      panelClass: ["snackbar"],
    });
  }

  public handleError(error) {
    let errorMessage: any = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //console.error(error.status);
    if (error.status == 0) {
      //window.alert("There is server error. Please try again later");
      Swal.fire("There is server error", "Please try again later", "error");
    }
    //   if(error.status == 500){
    //         Swal.fire("Sorry, an error occured", "Please try again later", "error");
    //  }

    return throwError(errorMessage);

    // if (error.status == 0) {
    //  errorMessage = {
    //     success: false,
    //     status: 0,
    //     data: "Sorry, there was a connection error occurred. Please try again."
    //   };
    // } else {
    //   errorMessage = error.json();
    // }
    // return Observable.throw(errorMessage);
  }
  public GetUserProductAccess(EmailAddress,LobId):Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/GetUserProductAccess`,{EmailAddress:EmailAddress,LobId:LobId},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));
    }

  //------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
  public getAllFormData(
    policy_type,
    registration_type,
    vechile_make_id,
    language_code,
    country,
    model_year
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getVechileMasterData`,
        {
          policy_type: policy_type,
          registration_type: registration_type,
          vechile_make_id: vechile_make_id,
          language_code: language_code,
          country: country,
          model_year: model_year,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //---------------------------------------------------------- FOR GET VEHICLE NCD --------------------------------------------------//
  public getVehicleNcd(language_code, policy_type): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getVechileNCD`,
        { language_code: language_code, policy_type: policy_type },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-------------------------------------- FOR SEND SMS & SEND MAIL  -----------------------------------------------------//
  public generatesendNotifications(
    quoteNumber,
    email,
    phoneNumber,
    type,
    lob,
    process_type,
    policy_Number,
    pol_exp_dt,
    pol_ins_name,
    link
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/sendNotification`,
        {
          pol_exp_dt: pol_exp_dt,
          pol_ins_name: pol_ins_name,
          quotation_number: quoteNumber,
          email: email,
          phone_number: phoneNumber,
          notification_type: type,
          lob: lob,
          process_type: process_type,
          core_policy_number: policy_Number,
          link: link,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //--------------------------------------------------------- FOR GET VEHICLE MODEL --------------------------------------------------//
  public getVehicleModel(
    vechile_reg_type,
    vechile_make_id,
    language_code,
    vechile_body_type,
    year
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getVechileModel`,
        {
          vechile_reg_type: vechile_reg_type,
          make_name: vechile_make_id,
          language_code: language_code,
          vechile_body_type: vechile_body_type,
          model_year: year,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // //-------------------------------------- GET TOKEN DATA ---------------------------------------------------//
  //   public getTokenData():Observable<any> {

  //     return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getTokenData`,{},this._globalService.getHeader())
  //           .pipe(catchError(this.handleError), tap(res => {
  //             let  response=this._globalService.convertResponseInJson(res);

  //           }));
  //   }
  //------------------------------------------------ FOR GET MOTOR BODY TYPE ------------------------------------------------------------//
  public getMotorBodyType(
    policy_type,
    registration_type,
    vechile_model_id,
    language_code,
    model_year,
    model_name
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getMotorBodyType`,
        {
          policy_type: policy_type,
          registration_type: registration_type,
          vechile_model_id: vechile_model_id,
          language_code: language_code,
          model_year: model_year,
          model_name: model_name,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------------FOR GET VEHICLE SPECIFICATION ------------------------------------------------//
  public getVhclSpecification(
    language_code,
    vechile_model_id
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getVechilesSpecification`,
        { language_code: language_code, vechile_model_id: vechile_model_id },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- For INSERT QUOTATION ------------------------------------------------------//
  public getInsertQuote(motorValuesArray, policyDetailsArray): Observable<any> {
    console.log("Quote Array to Insert",motorValuesArray)
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/insertQuotation`,
        {
          motorValuesArray: motorValuesArray,
          policyDetailsArray: policyDetailsArray,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

    //-------------------------------------------- For INSERT QUOTATION Non GCC ------------------------------------------------------//
  public getInsertQuoteNonGCC(motorValuesArray, policyDetailsArray): Observable<any> {
      console.log("Quote Array to Insert",motorValuesArray)
      return this.httpClient
        .post(
          `${this.apiURL}MotorInsuranceWebservice/insertQuotationNGCC`,
          {
            motorValuesArray: motorValuesArray,
            policyDetailsArray: policyDetailsArray,
          },
          this._globalService.getHeader()
        )
        .pipe(
          catchError(this.handleError),
          tap((res) => {
            let response = this._globalService.convertResponseInJson(res);
          })
        );
    }

  public getCylinderData(): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getMotorcylinder`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------  SAVE QUOTATION WITH PLAN --------------------------------------------------//
  public insertPlanData(
    quotationNumber,
    product_code,
    totalFixedPrimum,
    totalVariablePremium,
    policyFee,
    totalPreimum,
    planData,
    benefit_data,
    repairType,
    savePlanDetail,
    insType,
    source,
    scheme_code,
    loadingBy,
    calculBy,
    loadByAmount,
    repairLoadingAmt
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/saveQuoteWithPlan`,
        {
          quotationNumber: quotationNumber,
          product_code: product_code,
          totalFixedPrimum: totalFixedPrimum,
          totalVariablePremium: totalVariablePremium,
          policyFee: policyFee,
          totalPreimum: totalPreimum,
          planData: planData,
          benefit_data: benefit_data,
          repairType: repairType,
          savePlanDetail: savePlanDetail,
          insuranceType: insType,
          source: source,
          scheme_code: scheme_code,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          // let  response=this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------  SAVE QUOTATION WITH PLAN Email--------------------------------------------------//
  public insertPlanDataEmail(
    quotationNumber,
    product_code,
    totalFixedPrimum,
    totalVariablePremium,
    policyFee,
    totalPreimum,
    planData,
    benefit_data,
    repairType,
    savePlanDetail,
    insType,
    scheme_code
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/saveQuoteWithPlanEmail`,
        {
          quotationNumber: quotationNumber,
          product_code: product_code,
          totalFixedPrimum: totalFixedPrimum,
          totalVariablePremium: totalVariablePremium,
          policyFee: policyFee,
          totalPreimum: totalPreimum,
          planData: planData,
          benefit_data: benefit_data,
          repairType: repairType,
          savePlanDetail: savePlanDetail,
          insuranceType: insType,
          scheme_code: scheme_code,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          // let  response=this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- For INSERT Policy ------------------------------------------------------//
  public saveAdditionalInfo(
    QuotNo,
    WebQuotNo,
    insr_mortgage_bank,
    is_survey,
    insured_name,
    insured_type,
    vechile_engine_no,
    gender,
    PaymentMode,
    source,
    paymentReferenceNo
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/saveAdditionalInfo`,
        {
          QuotNo: QuotNo,
          WebQuotNo: WebQuotNo,
          insr_mortgage_bank: insr_mortgage_bank,
          is_survey: is_survey,
          insured_name: insured_name,
          insured_type: insured_type,
          vechile_engine_no: vechile_engine_no,
          gender: gender,
          PaymentMode: PaymentMode,
          source: source,
          paymentReferenceNo:paymentReferenceNo
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------------ UPLOAD DRIVING LICENCE DOC -------------------------------------------//
  public uploadDrivLicDoc(QuotNo, driving_license): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/uploadDrivingLicense`,
        { QuotNo: QuotNo, driving_license: driving_license },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------------ UPLOAD EMIRATES ID DOC -------------------------------------------//
  public uploadEmiratesIdDoc(QuotNo, emirates_documents): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/uploadEmiratesIdDocuments`,
        { QuotNo: QuotNo, emirates_documents: emirates_documents },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------------ UPLOAD REGISTRATION CARD DOC -------------------------------------------//
  public uploadRegistrationCardDoc(
    QuotNo,
    registration_documents
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/uploadMulkiyaOrRegistrationCard`,
        { QuotNo: QuotNo, registration_documents: registration_documents },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------------- UPLOAD DOCUMENTS ----------------------------------------------------//
  public uploadDocuments(formData): Observable<any> {
    return this.httpClient
      .post(`${this.apiURL}MotorInsuranceWebservice/uploadDocuments`, formData)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------------ FOR MULTIPLE DOCUMENT UPLOAD ------------------------------------------//
  public uploadMultipleDocuments(formData): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/uploadMultipleDocuments`,
        formData
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //---------------------------------------------- Validate Chassis number -----------------------------------------------//
  public validateChassisNumber(chassis_number): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/validateChassisNumber`,
        { chassis_number: chassis_number },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------------------------------- QUOTATION DETAIL PDF -----------------------------------------------//
  public getQuoteDetailPDF(quotation_number): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getQuotationDetailsPDF`,
        { quotation_number: quotation_number },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //--------------------------------------------- SAVE POLICY DETAIL -----------------------------------------------//
  public savePolicyDetail(
    policyDetailsArray,
    quotationDetailsArray
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/savePolicyDetails`,
        {
          policyDetailsArray: policyDetailsArray,
          quotationDetailsArray: quotationDetailsArray,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------------------------------- GET BANKDETAIL -----------------------------------------------//
  public getBankDetail(): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getBankdetails`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------- FOR PAYMENT ------------------------//
  public paymentAuth(
    QuotationNumber,
    site_url,
    return_url,
    project_type,
    partner_id,
    policy_number,
    source
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/open_pay_page_values`,
        {
          QuotationNumber: QuotationNumber,
          site_url: site_url,
          return_url: return_url,
          project_type: project_type,
          partner_id: partner_id,
          policy_number: policy_number,
          source: source,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-----------------SAVE QUOTATION PAYMENT -------------------//
  public saveQuotationPayment(
    token,
    eventType,
    quoteNumber,
    responseMsg,
    transactionID,
    project_Code,
    source
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/saveQuatationPayment`,
        {
          PaymentLogId: token,
          eventType: eventType,
          QuotationNumber: quoteNumber,
          ResponseMessage: responseMsg,
          TransactionID: transactionID,
          project_Code: project_Code,
          source: source,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-------------------- VERIFY QUOTATION PAYMENT ----------------//
  public verifyQuatationPayment(orderNumber): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/verifyQuatationPayment`,
        { orderNumber: orderNumber },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-------------------- Get Motor Valuation ----------------//
  public getMotorValuation(vechileForm): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getValuation`,
        { vechileForm: vechileForm },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-------------------- Get Motor Valuation ----------------//
  public getDetailsByVIN(chasisNo): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getDetailsByVIN`,
        { chasisNo: chasisNo },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- For UPDATE QUOTATION ------------------------------------------------------//
  public updateQuotation(
    motorValuesArray,
    policyDetailsArray
  ): Observable<any> {

    console.log("Update Quote Values",motorValuesArray)
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/UpdateQuotation`,
        {
          motorValuesArray: motorValuesArray,
          policyDetailsArray: policyDetailsArray,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- For Mail  ------------------------------------------------------//
  public sendMail(web_quotation_number, emailId): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/sendEmail`,
        { web_quotation_number: web_quotation_number, emailId: emailId },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------------------- FOR PLATE CODE DATA --------------------------------------------------------------//
  public getPlateCode(language_code, plate_source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getPlateCode`,
        { language_code: language_code, plate_source: plate_source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //---------------------------------------------FOR ADMIN PAGE DATA -----------------------------------------------------//
  public retrieveQuoteDataForAdmin(
    webQuoteNo,
    crsQuoteNo,
    from_date,
    to_date,
    insured_name,
    insured_email,
    insured_mobile
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/retriveQuoteDataForAdmin`,
        {
          web_quotation_number: webQuoteNo,
          CRS_quotation_number: crsQuoteNo,
          from_date: from_date,
          to_date: to_date,
          insured_name: insured_name,
          insured_email: insured_email,
          insured_mobile: insured_mobile,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //---------------------------------------------FOR CHECK USER ACCESS -----------------------------------------------------//
  public checkUserAccess(page_name, user_name, lob): Observable<any> {
    console.log("User Access Page",page_name,user_name,lob)
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/checkUserAccess`,
        { page_name: page_name, user_name: user_name, lob: lob },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------- GET VEHICLE MAKE DATA ----------------//
  public getVhclMake(
    policy_type,
    registration_type,
    vechile_make_id,
    language_code,
    country,
    model_year,
    scheme_code
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getVechileMakeValues`,
        {
          policy_type: policy_type,
          registration_type: registration_type,
          vechile_make_id: vechile_make_id,
          language_code: language_code,
          country: country,
          model_year: model_year,
          scheme_code: scheme_code,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------- VERIFY QUOTATION PAYMENT ----------------//
  public webSiteLink(type): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getFedilityWebsite`,
        { type: type },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- For RETREIVE QUOTE ------------------------------------------------------//
  public getRetrieveQuote(quotation_number, app_source, type): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getQuotationDetailsForCustomer`,
        {
          quotation_number: quotation_number,
          app_source: app_source,
          type: type,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------------------------ FOR GET MOTOR Insured TYPE ------------------------------------------------------------//
  public getInsuredType(SchemeCode): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getinsuredType`,
        { schemeCode: SchemeCode },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- GET DATA FOR EDITQUOTE DOCUMENTS -------------------------------------------------------//
  public getDataForEditQuoteDoc(quoteNum): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getDataForEditQuoteDoc`,
        { quotation_number: quoteNum },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- GET TERMS AND CONDITIONS -------------------------------------------------------//
  public getTermsConditions(product): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getTermsAndCondition`,
        { product: product },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- FOR VIEW POLICY SUMMARY -------------------------------------------------------//
  public viewPolicySummary(QuotNo, webQuoteNum, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/viewPolicySummary`,
        { QuotNo: QuotNo, webQuoteNum: webQuoteNum, source: source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- APPROVE POLICY REQUEST -------------------------------------------------------//
  public approvePolicyRequest(QuotNo, webQuoteNum, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/approvePolicyRequest`,
        { QuotNo: QuotNo, webQuoteNum: webQuoteNum, source: source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-------------------------------------------- APPROVE POLICY REQUEST -------------------------------------------------------//
  public approvePolicyRequestSummary(
    QuotNo,
    webQuoteNum,
    source,
    paymentMode
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/approvePolicyRequestsummary`,
        {
          QuotNo: QuotNo,
          webQuoteNum: webQuoteNum,
          source: source,
          PaymentMode: paymentMode,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- FOR PAYMENT ERROR MESSAGES -------------------------------------------------------//
  public getPaymentErrorMsg(section, response_code, language): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/getErrorMessageDuringPayment`,
        { section: section, response_code: response_code, language: language },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- FOR SERVICE LOG DATA -------------------------------------------------------//
  public getServiceLogData(
    crsQuoteNo,
    webQuoteNum,
    service_name
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getServiceLogData`,
        {
          CRS_quotation_number: crsQuoteNo,
          web_quotation_number: webQuoteNum,
          service_name: service_name,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- FOR RENEWAL POLICY LIST ---------------------------------------------------//
  public getPolicyRenewList(
    from_date,
    to_date,
    lob,
    cedant_partner_id,
    search_text,
    claimStatus
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getRenewalPolicyList`,
        {
          from_date: from_date,
          to_date: to_date,
          lob: lob,
          cedant_partner_id,
          OptionalText: search_text,
          claimStatus: claimStatus,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- FOR RENEWAL POLICY LIST ---------------------------------------------------//
  public getPolicyRenewListForChild(
    from_date,
    to_date,
    lob,
    cedant_partner_id,
    search_text,
    msg
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getRenewalPolicyList`,
        {
          from_date: from_date,
          to_date: to_date,
          lob: lob,
          cedant_partner_id,
          OptionalText: search_text,
          msg: msg,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- FOR GET POLICY DETAILS FOR RENEWAL ----------------------------------------//
  public getPolicyDetailForRenewal(
    cedant_partner_id,
    quotation_number,
    core_policy_number,
    view_type
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getPolicyDetailsForRenewal`,
        {
          cedant_partner_id: cedant_partner_id,
          quotation_number: quotation_number,
          core_policy_number: core_policy_number,
          view_type: view_type,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------- FOR SCHEME IN MOTORQUOTE PAGE ---------------------------------------------//
  public getProductListFOrScheme(lobcode): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/productList`,
        { lobcode: lobcode },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------- SEND PAYMENT LINK TO CUSTOMER -------------//
  public sendPaymentLink(
    quotationNumber,
    project_type,
    emailId,
    source,
    coreQuotationNumber,
    partner_id
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/sendQuatationPaymentLink`,
        {
          QuotationNumber: quotationNumber,
          project_type: project_type,
          emailId: emailId,
          source: source,
          coreQuotationNumber: coreQuotationNumber,
          partner_id: partner_id,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------------------------ FOR GOOGLE TRANSLATION ------------------------------------------//
  public translateInEnglish(arabData): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/googleTranslateAPI/` + arabData,
        { arabData: arabData }
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------ FOR REFERRAL FUCTIONALITY -------------------------------------------------//
  public sendReferralMailToAdmin(
    quotationNumber,
    comment,
    event_type,
    refer_type,
    referral_reason,
    additional_doc_data,
    additionalCondition
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/quotationReferralFunctionality`,
        {
          quotation_number: quotationNumber,
          comment: comment,
          event_type: event_type,
          refer_type: refer_type,
          referral_reason: referral_reason,
          additional_doc_data: additional_doc_data,
          ref_aditional_condition: additionalCondition,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------ FOR GET QUOTATION DETAIL PDF -------------------------------------------------//
  public getQuoteDetailPdf(quotationNumber): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getQuotationDetailsPDF`,
        { quotation_number: quotationNumber },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------ FOR GET QUOTATION DETAIL PDF -------------------------------------------------//
  public getMotorReceipt(coreQuoteNum, webQuoteNum, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getReceipt`,
        { coreQuotNo: coreQuoteNum, webQuoteNum: webQuoteNum, source: source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------------------------- FOR GET TONNAGE ----------------------------------------------------------//
  public getTonnage(make, model, year): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getTonnage`,
        { vechile_make: make, vechile_model: model, model_year: year },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------------------------- FOR GET MOTOR INS REQUIRED DATA  ----------------------------------------------------------//
  public getMotorRequiredData(
    key,
    vhclAge,
    vhclYear,
    polStartYear,
    vhclMake,
    schmeCode,
    quote_status,
    web_quote_number
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/motorInsuranceRequiredData`,
        {
          key: key,
          vechile_age: vhclAge,
          vechile_year: vhclYear,
          policy_start_date_year: polStartYear,
          vechile_make_array: vhclMake,
          scheme_code: schmeCode,
          quote_status: quote_status,
          web_quote_number: web_quote_number,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------- FOR GET QUOTATION HISTORY -----------------------------------------------------//
  public getQuotationHistory(quoteNumber, lob): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getQuotationHistory`,
        { quotation_number: quoteNumber, lob: lob },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------- FOR SEND SMS & SEND MAIL  -----------------------------------------------------//
  public sendNotifications(
    quoteNumber,
    email,
    phoneNumber,
    type,
    lob,
    process_type,
    policy_Number,
    pol_exp_dt,
    pol_ins_name
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/sendNotification`,
        {
          pol_exp_dt: pol_exp_dt,
          pol_ins_name: pol_ins_name,
          quotation_number: quoteNumber,
          email: email,
          phone_number: phoneNumber,
          notification_type: type,
          lob: lob,
          process_type: process_type,
          core_policy_number: policy_Number,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------------------------- FOR GET DEDUCTIBLE ------------------------------------------------------------//
  public getDeductible(deductibleArray): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getDeductible`,
        { deductibleArray: deductibleArray },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------------------------- FOR GET RTA RESPONSE ---------------------------------------------------------//
  public geRTARes(QNo, polUID, source): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getRTAResponse`,
        { quotation_number: QNo, core_policy_no: polUID, source: source },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------- FOR CHECK CREDIT NOTE  ---------------------------------------------//
  public getCheckCN(lobcode): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/checkCN`,
        { LOBCode: lobcode },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------------ FOR GET INDUSTRY TYPE ------------------------------------------------//
  public getIndustryTypes(lob): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getIndustryTypes`,
        { LOBCode: lob },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------------------ FOR GET ACEES TO ISSUE POLICY BUTTONS -------------------------------------//
  public checkAccessToPolicyButtons(lob): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/checkPolicyIssueButton`,
        { lob: lob },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------------------------- For Plans Details ------------------------------------------------------//
  public getplanDetails(motorValuesArray, policyDetailsArray): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getRenewalPremium`,
        {
          motorValuesArray: motorValuesArray,
          policyDetailsArray: policyDetailsArray,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public getSchemesByProduct(lob): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getSchemesByProduct`,
        { lob: lob },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //Added new service method by Anju for credit limit check
  // ---------------------------------------Check partner Credit------------------------------------------------------
  // public checkPartnerCreditLimit(lob_code,selected_partnerId, selected_branch): Observable<any> {
  //   return this.httpClient
  //     .post(
  //       `${this.apiURL}MotorInsuranceWebservice/checkPartnerCreditLimit`,
  //       { lob_code: lob_code,selected_partnerId: selected_partnerId,selected_branch:selected_branch  },
  //       this._globalService.getHeader()
  //     )
  //     .pipe(
  //       catchError(this.handleError),
  //       tap((res) => {
  //         let response = this._globalService.convertResponseInJson(res);
  //       })
  //     );
  // }

   //Added new service method by Anju for credit limit check
  // ---------------------------------------Check partner Credit------------------------------------------------------
  public checkPartnerCreditLimit(lob_code,premium, agent_code, selected_branch): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/checkPartnerCL`,
        { lob_code:lob_code,premium :premium,agent_code:agent_code,selected_branch:selected_branch },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

     //Added new service method by shitala for NCD Declaration from gneration

   public generateNCDDeclarationForm (data): Observable<any> {
      return this.httpClient
        .post(
          `${this.apiURL}GeneratePdf/NCDFormPDFGenerate`,
          { data },
          this._globalService.getHeader()
        )
        .pipe(
          catchError(this.handleError),
          tap((res) => {
            let response = this._globalService.convertResponseInJson(res);
          })
        );
     }

       //------------------------------------------------- UPLOAD DOCUMENTS ----------------------------------------------------//
  public uploadDocumentswithoutOCR(formData): Observable<any> {
    return this.httpClient
      .post(`${this.apiURL}MotorInsuranceWebservice/uploadPolDocumentB2B`, formData)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //Added by Anju for NCD
  public getVehicleNcdStandard(language_code, policy_type, registration_Date, repair_type, model_year, license_issue_date): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/getVehicleNCDStandard`,
        { language_code: language_code, policy_type: policy_type, registration_Date: registration_Date, repair_type:repair_type, model_year:model_year, license_issue_date:license_issue_date },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //Added by Anju for Premium Edit Permission
  public checkPremiumEditPermission( user_name, lob): Observable<any> {
    console.log("User Access Page",user_name,lob)
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/CheckPremiumEditPermission`,
        { user_name: user_name, lob: lob },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

   //------------------------------------------ FOR GET ACEES TO ISSUE POLICY BUTTONS -------------------------------------//
   public checkAccessToMotorPolicyButtons(lob,product_code): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/checkMotorPolicyIssueButton`,
        { lob: lob, product_code:product_code },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
     
}
