import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MarineComponent } from './marine.component';
import { MarinequoteComponent } from './marinequote/marinequote.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { MarinepaymentinvoiceComponent } from './marinepaymentinvoice/marinepaymentinvoice.component';
import { MarinecancelpaymentComponent } from './marinecancelpayment/marinecancelpayment.component';
import { ReferralpopupComponent } from './referralpopup/referralpopup.component';

const routes: Routes = [
  {
    path: '',
    component: MarineComponent,
  },
  {
    path: 'marinequote',
    component: MarinequoteComponent,
  },
  {
    path: 'thankYou',
    component: ThankyouComponent,
  },
  {
    path: 'referral',
    component: ReferralpopupComponent,
  },
  {
    path: 'payment',
    component: MarinepaymentinvoiceComponent,
  },
  {
    path: 'cancelpayment',
    component: MarinecancelpaymentComponent,
  },


  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarineRoutingModule { 

}
