import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { catchError, tap, map } from "rxjs/operators";
import { of, throwError, observable } from "rxjs";
import { GlobalService } from "../_service/global.service";
import { config } from "./../config";

@Injectable({
  providedIn: "root",
})
export class IssuepolicyService {
  

  apiURL: string = config.apiUrl;
  url: string = config.SmeMedCoreURL
  nextcareURL: any = config.nextcareAPIURL;
  shafafiyaURL:any =config.shafafiyaServiceUrl;

  constructor(
    private httpClient: HttpClient,
    public _globalService: GlobalService
  ) {}

  //   private handleError<T> (operation = 'operation', result?: T) {
  //     return (error: any): Observable<T> => {

  //       // TODO: send the error to remote logging infrastructure
  //       console.error(error); // log to console instead

  //       // Let the app keep running by returning an empty result.
  //       return of(result as T);
  //     };
  // }

  public handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  //------------ List of dropdown data FOR ISSUPOLICY ---------//
  public getQuotationFormData(): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getQuotationFormData`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------- FOR GET QUOTE --------------//
  public getQuote(policyHolderFormData): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getQuots`,
        { policyHolderFormData: policyHolderFormData },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //--------------- FOR GET PLANS OF PERTICULAR QUOTE NUMBER ---------------//
  public getPlans(quotationNumber): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getPlansByQuotataionNumber`,
        { QuotationNumber: quotationNumber },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //---------- List of dropdown data FOR RETRIEVE QUOTE --------------//
  public retrieveQuoteFormData(loggedUser=''): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/retrieveQuotationFormApi`,
        {
          loggedUser:loggedUser
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  // ----------------------------------------------------------insert Upload Data Medical Process----------------------------------------
  public insertUploadData(
    lob,
    fromDate,
    toDate,
    fileName,
    uploadDesc
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/insertUploadData`,
        {
          lob: lob,
          fromDate: fromDate,
          toDate: toDate,
          fileName: fileName,
          uploadDesc: uploadDesc,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  // ----------------------------------------------------------Medical Renewal----------------------------------------
  public medicalrenewal(formData): Observable<any> {
    return this.httpClient
      .post(`${this.apiURL}Webservice/renewalFunctionality`, formData)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //----------- Quotation List for Retrieve Quote -----------------//
  public retrieveQuoteList(
    id,
    fromDate,
    toDate,
    quotNum,
    phName,
    status
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getQuotationListForRetrieve`,
        {
          Id: id,
          fromDate: fromDate,
          toDate: toDate,
          quoteRefNum: quotNum,
          policyHolderName: phName,
          status: status,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //----------- GET POLICY LIST FOR REVIEW  -----------------//
  public getPolicyListReview(
    id,
    fromDate,
    toDate,
    search,
    userId
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getpolicyListReview`,
        {
          product_code: id,
          from_date: fromDate,
          to_date: toDate,
          search_text: search,
          user_id: userId,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //----------- GET SOO LIST  -----------------//
  public getSOOListReport(
    id,
    date,
    userId
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getSOOListReport`,
        {
          partner_code: id,
          date: date,
          user_id: userId,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //--------------- get Quotation Details For RetrieveByQuoteNo -----------------//
  // public getMembersDetailByQuoteNo(quoteNum):Observable<any> {

  //   return this.httpClient.post(`${this.apiURL}Webservice/getMembersDetailByQuoteNo`,{QuotationNumber:quoteNum},this._globalService.getHeader())
  //         .pipe(catchError(this.handleError), tap(res => {
  //           let  response=this._globalService.convertResponseInJson(res);

  //          }));
  // }
  //-------------------- update premimum and Plan -------
  public updatePlanAndPrimum(planId, primum, DiscountPercent): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}quotationController/updatePlanAndPrimum`,
        { plan: planId, primum: primum, DiscountPercent: DiscountPercent },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------- get member's detail -------------//
  public getMembersDetailByQuoteNo(quoteNum): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getMembersDetailByQuoteNo`,
        { QuotationNumber: quoteNum },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------- To Get MAF Details------------//
  public getMedicalMAFDetails(quotationNumber, memberId): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/getMedicalMAFDetails`,
        { quoteNumber: quotationNumber, memberId: memberId },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //----------------- Update Quote -------------//
  public updateQuote(quoteNum, policyHolderFormData): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/updateQuotation`,
        {
          QuotationNumber: quoteNum,
          policyHolderFormData: policyHolderFormData,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public getQuestionnaire(): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getQuestionnaire`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //--------------- Update members additional detail ------------- //

  public updateMemAdditionalDetail(
    memList,
    memberDetailFormData,
    remark,
    hasMAFCondition,//Added by Anju for MAF Condition tracking
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/updateMembersAdditionalDetails`,
        {
          memList: memList,
          memberDetailFormData: memberDetailFormData,
          remark: remark,
          hasMAFCondition:hasMAFCondition,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public saveMemberFiles(formData): Observable<any> {
    return this.httpClient
      .post(`${this.apiURL}MemberController/saveMembersFiles`, formData)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public saveMemberMedicalHistory(
    selectedMember,
    medicalHistory,
    quotationNumber,
    bloodGp,
    email,
    partnerID,
    conditions
  ): Observable<any> {
    let jsonDataSend = [];
  //   medicalHistory.forEach((item, index) => {
  //     jsonDataSend.push({ id: index, data: item });
  //   });

  // Object.keys(medicalHistory).forEach((key) => {
  //     if (key.includes('section_') && medicalHistory[key] !== 'false') {
  //         jsonDataSend.push({ id: key, data: medicalHistory[key] });
  //     }
  // });

  if (Array.isArray(medicalHistory)) {
    medicalHistory.forEach((item, index) => {
      jsonDataSend.push({ id: index, data: item });
    });
     Object.keys(medicalHistory).forEach((key) => {
          if (key.includes('section_') && medicalHistory[key] !== 'false') {
              jsonDataSend.push({ id: key, data: medicalHistory[key] });
          }
      });
  } else if (typeof medicalHistory === 'object' && medicalHistory !== null) {
    Object.keys(medicalHistory).forEach((key) => {
        jsonDataSend.push({ id: key, data: medicalHistory[key] });
    });
  }

    return this.httpClient
      .post(
        `${this.apiURL}MemberController/saveMemberMedicalHistory`,
        {
          MemberID: selectedMember.MemberID,
          formData: jsonDataSend,
          quotationNumber: quotationNumber,
          bloodGp: bloodGp,
          email: email,
          partnerID: partnerID,
          conditions:conditions,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public saveSMTMemberMedicalHistory(
    selectedMember,
    medicalHistory,
    quotationNumber,
    bloodGp,
    height,
    weight,
    email,
    partnerID
  ): Observable<any> {
    let jsonDataSend = [];
    medicalHistory.forEach((item, index) => {
      jsonDataSend.push({ id: index, data: item });
    });
    return this.httpClient
      .post(
        `${this.url}/submit_maf`,
        {
          MemberID: selectedMember.member_id,
          formData: jsonDataSend,
          quotationNumber: quotationNumber,
          bloodGp: bloodGp,
          height: height,
          weight: weight,
          email: email,
          partnerID: partnerID,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //--------------------- PDF generate for Medical History Form------------//
  public medicalHistoryFormPdfGenerate(
    quotationNumber,
    memberId
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}GeneratePdf/medicalHistoryFormPDFGenerate`,
        { quotationNumber: quotationNumber, memberId: memberId },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public smeMedicalHistoryFormPdfGenerate(
    name,relation,dob,martialStatus,gender,
    nationality,
    quotationNumber,
    memberId
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}GeneratePdf/smeMedicalHistoryFormPDFGenerate`,
        { name: name,relation: relation,dob: dob,martialStatus: martialStatus,gender: gender,nationality: nationality,quotationNumber: quotationNumber, memberId: memberId },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  // ------------------------------view TOB File --------------------------------------------//
 public viewTOBPDF(planid,isrenewal, productType, source) : Observable<any> {
  console.log(planid)
  return this.httpClient
  .post(
    `${this.apiURL}Webservice/getMedicalTOB`,
    {
      planid: planid,
      isrenewal : isrenewal,
      productType: productType,
      source: source,
    },
    this._globalService.getHeader()
  )
  .pipe(
    catchError(this.handleError),
    tap((res) => {
      let response = this._globalService.convertResponseInJson(res);
    })
  );
}

  //--------------------- PDF generate for Quotation Plans------------//
  public quotationPlanPDFGenerate(quotationNumber): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}QuotationPdf/quotationPlanPDFGenerate`,
        { quotationNumber: quotationNumber },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public getpartnerBranch(partnerID) {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/partnerBranch`,
        { partnerID: partnerID },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public getuserBranch(userID) {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/userBranch`,
        { userID: userID },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //--------------------- CHECK MEMBER DUPLICATE ------------//
  public duplicateMember(passportNum, emiratesID, uidNum): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/checkDuplicateMember`,
        {
          PassportNumber: passportNum,
          EmiratesId: emiratesID,
          UIDNumber: uidNum,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //---------------- webservice for Work Location -------------//
  public getMemberWorkLocation(visaregion): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/getMemberWorkLocation`,
        { VisaRegion: visaregion },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //---------------- webservice for Residential Location -------------//
  public getMemberResLocation(visaregion): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/getMemberResidentialLocation`,
        { VisaRegion: visaregion },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------- GET DASHBOARD DATA ------------------------//
  public getDashboaedData(user_id, partner_id, lob_code): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}DashboardController/getDashboardData`,
        { user_id: user_id, partner_id: partner_id, lob_code: lob_code },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------- GET PET DASHBOARD DATA ------------------------//
  public getPetDashboaedData(user_id, partner_id, lob_code): Observable<any> {
    return this.httpClient
      .get(`http://10.170.11.58/api/b2b/stats/` + user_id)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------- GET PET DASHBOARD Transactions DATA ------------------------//
  public getPetTransactionsData(
    user_id,
    partner_id,
    lob_code
  ): Observable<any> {
    return this.httpClient
      .get(`http://10.170.11.58/api/b2b/transactions/` + user_id)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------- FOR PAYMENT ------------------------//
  public paymentAuth(QuotationNumber, site_url, return_url): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/open_pay_page_values`,
        {
          QuotationNumber: QuotationNumber,
          site_url: site_url,
          return_url: return_url,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //-----------------SAVE QUOTATION PAYMENT -------------------//
  public saveQuotationPayment(
    token,
    eventType,
    quoteNumber,
    responseMsg,
    transactionID
  ) { //Nextcare API change - removed observable and made to Promise function
    return this.httpClient 
      .post(
        `${this.apiURL}PaymentWebservice/saveQuatationPayment`,
        {
          PaymentLogId: token,
          eventType: eventType,
          QuotationNumber: quoteNumber,
          ResponseMessage: responseMsg,
          TransactionID: transactionID,
          project_Code:'HT'
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      ).toPromise();
  }

  //-------------------- VERIFY QUOTATION PAYMENT ----------------//
  public verifyQuatationPayment(orderNumber): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/verifyQuatationPayment`,
        { orderNumber: orderNumber },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------ UPLOAD MAF --------------------//
  public uploadMAF(
    quotationNumber,
    MemberID,
    filePath,
    fileName,
    processType
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/uploadMAFMedicalHistory`,
        {
          quotationNumber: quotationNumber,
          MemberID: MemberID,
          filePath: filePath,
          fileName: fileName,
          processType: processType,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public uploadSMTMAF(
    isDigitalMAF,
    isUploadMAF,
    Condition,
    quoterefNum,
    quotationNumber,
    MemberID,
    filePath,
    fileName,
    processType
  ): Observable<any> {
    return this.httpClient
      .post(`${this.url}/upload_maf`,
        {
          isDigitalMAF: isDigitalMAF,
          isUploadMAF: isUploadMAF,
          Condition: Condition,
          quoterefNum: quoterefNum,
          quotationNumber: quotationNumber,
          MemberID: MemberID,
          filePath: filePath,
          fileName: fileName,
          processType: processType,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //--------------------- Old Code  SEND PAYMENT LINK TO CUSTOMER -------------//
  //  public sendPaymentLink(quotationNumber):Observable<any> {

  //   return this.httpClient.post(`${this.apiURL}PaymentWebservice/sendQuatationPaymentLink`,{QuotationNumber:quotationNumber},this._globalService.getHeader())
  //         .pipe(catchError(this.handleError), tap(res => {
  //           let  response=this._globalService.convertResponseInJson(res);

  //           }));
  //   }
  //--------------------- SEND PAYMENT LINK TO CUSTOMER -------------//
  public sendPaymentLink(
    quotationNumber,
    project_type,
    emailId,
    source,
    coreQuotationNumber
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/sendQuatationPaymentLink`,
        {
          QuotationNumber: quotationNumber,
          project_type: project_type,
          emailId: emailId,
          source: source,
          coreQuotationNumber: coreQuotationNumber,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //-------------------------- View Policy --------------------------//
  public getPliciesByUser(
    partnerId,
    userName,
    lob,
    fromDate,
    toDate
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PolicyController/getPoliciesByUser`,
        {
          partnerid: partnerId,
          username: userName,
          lob: lob,
          from_date: fromDate,
          to_date: toDate,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // ----------------------------------------------------------Medical Process----------------------------------------
  public processRenewal(CorePolNo, CedBrkId, LOB): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/processRenewal`,
        { CorePolNo: CorePolNo, CedBrkId: CedBrkId, LOB: LOB },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------ View Policy Details -----------------------//
  public getCustomerPolicyDetails(
    partnerId,
    userName,
    policyNumber,
    lobCode
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PolicyController/getCustomerPolicyDetails`,
        {
          partnerId: partnerId,
          userName: userName,
          polNumber: policyNumber,
          lobCode: lobCode,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------ INDIVIDUAL CERTIFICATE PDF -----------------------//
  public individualCertPDF(quotationNumber, memberId): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Memberedcertificate_new/memberCertificatePDFGenerate`,
        { quotationNumber: quotationNumber, memberId: memberId },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------ VIRTUAL CARD PDF -----------------------//
  public virtualCardPDF(quotationNumber, memberId): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}GeneratePdf/memberVirtualCardPdf`,
        { quotationNumber: quotationNumber, memberId: memberId },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------ SEND MAF MAIL TO CUSTOMER -----------------------------------------------//
  public sendMafMailToCustomer(
    quotationNumber,
    partner_id,
    memberDetails,
    email
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/saveMAFLogsData`,
        {
          quotationNumber: quotationNumber,
          partner_id: partner_id,
          memberDetails: memberDetails,
          email: email,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public sendSmeMafMailToCustomer(
    quotationNumber,
    partner_id,
    memberDetails,
    email
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/saveSmeMAFLogsData`,
        {
          quotationNumber: quotationNumber,
          partner_id: partner_id,
          memberDetails: memberDetails,
          email: email,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------- VALIDATE MAF ACCESS -----------------------------------------//
  public validateMAFAccess(
    quotationNumber,
    partner_id,
    user_token
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/validateMAFAccess`,
        {
          quotationNumber: quotationNumber,
          partner_id: partner_id,
          user_token: user_token,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------- FOR REDIRECT PAGES -----------------------------------------//
  public redirectPages(): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getRedirectionLink`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------- FOR VALIDATE USER ACCESS -----------------------------------------//
  public validateUserAccess(productPageName, lobCode): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MotorInsuranceWebservice/checkAccess`,
        { productPageName: productPageName, lobCode: lobCode },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------ FOR GET MEDICAL RECIEPT  -------------------------------------------------//
  public getMedicalReceipt(
    webQuoteNum,
    lobCode,
    user_name,
    policy_number,
    partnerId
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getPaymentReceipt`,
        {
          quotation_number: webQuoteNum,
          lob: lobCode,
          user_name: user_name,
          policy_number: policy_number,
          partnerId: partnerId,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------ FOR GET MEDICAL RECIEPT  -------------------------------------------------//
  public getPolicyPeriod(visa_region): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getPolicyPeriod`,
        { visa_region: visa_region },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------------ FOR GET MEDICAL MemberID  -------------------------------------------------//
  public getMemberID(visa_region): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/getCustMemberID`,
        { visa_region: 1 },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------ FOR POLICY APPROVE  -------------------------------------------------//
  public policyApproval(quoteNum): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/policyApproval`,
        { quatationNumber: quoteNum },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //------------------------------------ FOR RECEIPT GENERATION  -------------------------------------------------//
  public generateReceipt(quoteNum, transID): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}PaymentWebservice/receiptGeneration`,
        { quatationNumber: quoteNum, transaction_id: transID },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //------------------------------- FOR GET QUOTATION DETAIL FOR RETRIEVE QUOTE ------------------------------------//
  public getDataForRetrieveQuote(quoteNum): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getQuotationDetailsForRetrieveByQuoteNo`,
        { QuotationNumber: quoteNum },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  // ----------------------------------------------------------get renewal history data----------------------------------------
  public getDocumentHistoryList(lobCode, partner_id): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getDocumentHistoryList`,
        { lob: lobCode, partner_id: partner_id },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  // ----------------------------------------------------------GET PDF LINK----------------------------------------
  public viewPdf(quoteNum, CorePolNo, lobcode, planName): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/generateMedicalRenewalNotice`,
        {
          quotationNumber: quoteNum,
          policy_number: CorePolNo,
          lobcode: lobcode,
          planName: planName,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public repayment(data): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/VerifyandProcessPolicy`,
        {
          lob: data.lob,
          paymentType: data.paymentType,
          quotationNumber: data.quotationNumber,
          refernceNumber: data.refernceNumber,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public callNextCareTPAIntegration(quoteNum) :Observable<any> {

    return this.httpClient.post(`${this.nextcareURL}/PostXMLData?QuotationNumber=${quoteNum}`, {
        QuotationNumber : quoteNum
      } )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          console.log("response recieved",response)
        })
      );
  }

  getTPADetails(quoteNum,CRSPolNumber):Observable<any> {

    return this.httpClient.post(`${this.nextcareURL}/GetTPAStatus?QuotationNumber=${quoteNum}`, {
        QuotationNumber : quoteNum
      } )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          console.log("response recieved",response)
        })
      );
  }

  //Added by Anju to fetch receipt details rom IIRIS
  getReceiptDetails(crsPolUID,lob): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getReceiptDetails`,
        { corepolUID: crsPolUID,
          lob:lob
         },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
// to Check validity of IA License , Trade License etc
  public getBrokerLicenseDetails(partnerId,partnerbranchId):Observable<any>{
    return  this.httpClient.post(`${this.apiURL}Webservice/getBrokerLicenseDetails`,{partnerId:partnerId, partnerbranchId:partnerbranchId },this._globalService.getHeader()).pipe(catchError(this.handleError), tap(res => {
      let  response=this._globalService.convertResponseInJson(res);  
    }));  
  }

  //Added by Anju to log referral condition
  public insertQuoteActivity(quoteNum): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/insertQuoteActivity`,
        { QuotationNumber: quoteNum },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //Added by Anju for invoking Shafafiya Service
  public callShafafiyaService(quoteNum) :Observable<any> {

    return this.httpClient.post(`${this.shafafiyaURL}/GetHAADFine?quotationNumber=${quoteNum}`, {
      quotationNumber : quoteNum
      } )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          console.log("response recieved",response)
        })
      );
  }

 
  
  //Added by Anju for calculating HAAD fine
  public calculateHAADFine(quotationId) :Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/calculateHAADFine`, {
      quotationId : quotationId
      } )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public saveHAADReceipt(formData): Observable<any> {
    return this.httpClient
      .post(`${this.apiURL}MemberController/saveHAADReceipt`, formData)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public getHAADReceipts(quotationNumber): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}MemberController/getHAADReceipts`,
        { quotationNumber: quotationNumber },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  //HAAD Penalty changes- start
  public uploadHaadFile(formData): Observable<any> {
    return this.httpClient
      .post(`${this.apiURL}Webservice/uploadHaadFile`, formData)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  public getHaadDataList(partner_id,fromDate,toDate): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getHAADPenaltyData`,
        { partner_id: partner_id ,fromDate:fromDate,toDate:toDate},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  } 
  public getDataForOutputDoc(masterId): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/generateOutputDocument`,
        {masterId:masterId},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //HAAD Penalty changes- End
          // DIGI Status check Feb2024 Changes Start
     public GetDIGIStatus(digiRequest):Observable<any> {
        return this.httpClient.post(`${config.marineCallAPIURL}/DIGI/Screen`,digiRequest,this._globalService.getHeader())
                  .pipe(catchError(this.handleError), tap(res => {
                    let  response=this._globalService.convertResponseInJson(res);          
                  }));   
     }
            //DIGI Status check Feb2024 Changes END

    //Added by Anju for updating Cover Start Date for Offline payments
    public UpdateCoverStartDate(lob,paymentType,quotationNumber,coverStartDate): Observable<any> {
      return this.httpClient
        .post(
          `${this.apiURL}Webservice/updateCoverStartDate`,
          {
            lob: lob,
            paymentType: paymentType,
            quotationNumber: quotationNumber,
            coverStartDate: coverStartDate,
          },
          this._globalService.getHeader()
        )
        .pipe(
          catchError(this.handleError),
          tap((res) => {
            let response = this._globalService.convertResponseInJson(res);
          })
        );
    }

    //Added by Anju for getting history from Shafafiya Service
  public getPersonHistory(emiratesId,uidNumber) :Observable<any> {

    return this.httpClient.post(`${this.shafafiyaURL}/GetHAADPersonalHistory?emiratesid=${emiratesId}&unifiedNumber=${uidNumber}`, {
      emiratesId : emiratesId,
      } )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          console.log("response recieved",response)
        })
      );
  }

  public uploadBulkHistoryFile(formData): Observable<any> {
    return this.httpClient
      .post(`${this.apiURL}Webservice/uploadBulkHistoryFile`, formData)
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public getHaadBulkDataHistoryList(partner_id,fromDate,toDate): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getHaadBulkDataHistoryList`,
        { partner_id: partner_id ,fromDate:fromDate,toDate:toDate},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  } 

  public getDataForBulkHistoryOutputDoc(masterId): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/GenerateBulkHistoryOutputDoc`,
        {masterId:masterId},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public getIndividualHAADFine(emiratesId,uidNumber,status) :Observable<any> {

    return this.httpClient.post(`${this.shafafiyaURL}/GetIndividualHAADFine?emiratesid=${emiratesId}&unifiedNumber=${uidNumber}&status=${status}`, {
      emiratesId : emiratesId,
      } )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          console.log("response recieved",response)
        })
      );
  }

  public CancelCOC(referenceNumber) :Observable<any> {

    return this.httpClient.post(`${this.shafafiyaURL}/CancelCOC?referenceNumber=${referenceNumber}`, {
      referenceNumber : referenceNumber,
      } )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          console.log("response recieved",response)
        })
      );
  }

  
  public getProduct(lobId): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getProductCode`,
        {lobId:lobId},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public getPlansRate(
    lob,
    productCode,
    pageSize,
    pageNumber
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getProductPlanList`,
        {
          lob: lob,
          productCode: productCode,
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }


  GetCOCData(corePolicyNumber) :Observable<any> {

    return this.httpClient.post(`${this.shafafiyaURL}/GetCOCPolicyData?corePolicyNumber=${corePolicyNumber}`, {
      corePolicyNumber : corePolicyNumber,
      } )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          console.log("response recieved",response)
        })
      );
  }

  public COCCertificatePDFGenerate(policyData): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Memberedcertificate_new/COCCertificatePDFGenerate`,
        { policyData: policyData },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
    // ------------------------------view TOB File --------------------------------------------//
 public viewPolicyDocumentMedical(partnerId,username,policyNo,lob) : Observable<any> {
  return this.httpClient
  .post(
    `${this.apiURL}GeneratePdf/PolicyDocumentPDFGenerate`,
    {
      partnerId: partnerId,
      username : username,
      policyNo: policyNo,
      lob: lob,
    },
    this._globalService.getHeader()
  )
  .pipe(
    catchError(this.handleError),
    tap((res) => {
      let response = this._globalService.convertResponseInJson(res);
    })
  );
}

getICPIntegrationStatus(corePolicyNumber) :Observable<any> {
  return this.httpClient.post(`${this.apiURL}Webservice/getICPIntegrationStatus`,
    {
      corePolicyNumber: corePolicyNumber,
    },
    this._globalService.getHeader()
  )
  .pipe(
    catchError(this.handleError),
    tap((res) => {
      let response = this._globalService.convertResponseInJson(res);
    })
  );
}

public GenerateMAFDocument(partnerId,username,quotationNumber,lob) : Observable<any> {
  return this.httpClient
  .post(
    `${this.apiURL}GeneratePdf/GenerateCombinedMAFDocument`,
    {
      partnerId: partnerId,
      username : username,
      quotationNumber: quotationNumber,
      lob: lob,
    },
    this._globalService.getHeader()
  )
  .pipe(
    catchError(this.handleError),
    tap((res) => {
      let response = this._globalService.convertResponseInJson(res);
    })
  );
}

}



