import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { catchError, tap, map } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { GlobalService } from "../_service/global.service";
import { config } from "./../config";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class UserauthService {
  apiURL: string = config.apiUrl2;

  apiURL1: string = config.apiUrl;
  private loggedIn = false;

  constructor(
    private httpClient: HttpClient,
    public _globalService: GlobalService,
    public _route: Router
  ) {}

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public projecttokenAuthorisation(token) {
    var parameter = { token: token };
    return this.httpClient
      .post(`${this.apiURL}Webserviceauth/tokenAuthorisation`, parameter)

      .pipe(
        catchError(this.handleError),
        tap((res) => {
          //let  response:any= res;
          let response = this._globalService.convertResponseInJson(res);

          if (response.success) {
            if (response.data.access_token != null) {
              let response_data = JSON.stringify(response.data);
              localStorage.setItem("currentUser", response_data);
              localStorage.setItem("backend-token", response.data.access_token);
            }

            this.loggedIn = true;
          } else {
            localStorage.clear();
            this.loggedIn = false;
          }
          return response;
        })
      );
  }

  public loginUserAuth(username, password) {
    return this.httpClient
      .post(`${this.apiURL}Webserviceauth/login_authentication`, {
        EmailAddress: username,
        Password: password,
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);

          if (response.success) {
            if (response.data.access_token != null) {
              let response_data = JSON.stringify(response.data);

              let userInfo = {
                UserRole: response.data.UserRole,
                AccessGroup: response.data.AccessGroup,
                EmailAddress: response.data.EmailAddress,
                CedantLogo: response.data.CedantLogo,
                PartnerName: response.data.PartnerName,
                UserName: response.data.UserName,
                BranchId: response.data.BranchId,
                User_ID: response.data.UserId,
                Partner_ID: response.data.PartnerId,
                Cedant_ID: response.data.CedantId,
                BusinessSource: response.data.BusinessSource,
              };
              //  localStorage.setItem("UserRole", response.data.UserRole);
              //  localStorage.setItem("EmailAddress", response.data.EmailAddress);
              //  localStorage.setItem("CedantLogo", response.data.CedantLogo);
              //  localStorage.setItem("PartnerName", response.data.PartnerName);
              //  localStorage.setItem("UserName", response.data.UserName);
              localStorage.setItem("currentUser", JSON.stringify(userInfo));
              localStorage.setItem("backend-token", response.data.access_token);
            }

            this.loggedIn = true;
          } else {
            localStorage.clear();
            this.loggedIn = false;
          }
          return response;
        })
      );
  }

  public loginAuth(key) {
    return this.httpClient
      .post(`${this.apiURL}Webserviceauth/login_user_authentication`, {
        key: key,
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);

          if (response.success) {
            if (response.data.access_token != null) {
              let response_data = JSON.stringify(response.data);
              let userInfo = {
                UserRole: response.data.UserRole,
                AccessGroup: response.data.AccessGroup,
                EmailAddress: response.data.EmailAddress,
                CedantLogo: response.data.CedantLogo,
                PartnerName: response.data.PartnerName,
                UserName: response.data.UserName,
                BranchId: response.data.BranchId,
                User_ID: response.data.UserId,
                Partner_ID: response.data.PartnerId,
                Cedant_ID: response.data.CedantId,
                BusinessSource: response.data.BusinessSource,
                UserType: response.data.UserType,
                PartyCode: response.data.PartyCode,
                IsInternalAgent: response.data.IsInternalAgent,
                Usr_Role: response.data.Usr_Role,
                UserLevel: response.data.UserLevel,
              };
              //  localStorage.setItem("UserRole", response.data.UserRole);
              //  localStorage.setItem("EmailAddress", response.data.EmailAddress);
              //  localStorage.setItem("CedantLogo", response.data.CedantLogo);
              //  localStorage.setItem("PartnerName", response.data.PartnerName);
              //  localStorage.setItem("UserName", response.data.UserName);
              localStorage.setItem("currentUser", JSON.stringify(userInfo));
              localStorage.setItem("backend-token", response.data.access_token);
            }

            this.loggedIn = true;
          } else {
            localStorage.clear();
            this.loggedIn = false;
          }
          return response;
        })
      ).toPromise();
  }

  public userAuthLoginExist() {
    var backend_token = localStorage.getItem("backend-token");
    let loginStatus =
      backend_token == "" || backend_token == null ? false : true;

    if (loginStatus == false) {
      this._route.navigate(["/login"]);
    } else {
      this._route.navigate(["/dashboard"]);
    }
  }

  public getAllMenuList(): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getMenuList`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }
  //dynmic pet nav menu
  public getAllMenuListDynmic(): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}Webservice/getMenuListDynmic`,
        {},
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public ValidateLogin(req) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/User/ValidateUserLogin`, {
        UserName: req.username,
        Password: req.password,
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);

          if (response.status.statusCode == 1) {
            if (response.result.token != null) {
              let response_data = JSON.stringify(response.result);

              let userInfo = {
                UserRole: response.result.userRole,
                AccessGroup: response.resultaccessGroup,
                EmailAddress: response.result.emailAddress,
                CedantLogo: response.result.cedantLogo,
                PartnerName: response.result.partnerName,
                UserName: response.result.userName,
                BranchId: response.result.branchId,
                User_ID: response.result.userId,
                Partner_ID: response.result.partnerId,
                Cedant_ID: response.result.cedantId,
                BusinessSource: response.result.businessSource,
              };
              //  localStorage.setItem("UserRole", response.data.UserRole);
              //  localStorage.setItem("EmailAddress", response.data.EmailAddress);
              //  localStorage.setItem("CedantLogo", response.data.CedantLogo);
              //  localStorage.setItem("PartnerName", response.data.PartnerName);
              //  localStorage.setItem("UserName", response.data.UserName);
              localStorage.setItem("currentUser", JSON.stringify(userInfo));
              localStorage.setItem("Dotnet-token", response.result.token);

            }

            this.loggedIn = true;
          } else {
            localStorage.clear();
            this.loggedIn = false;
          }
          return response;
        })
      ).toPromise();
  }

  public InsertUserActivity(req) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/User/InsertUserActivity`, {
        
          UserId: req.UserId,
          PageName: req.PageName,
          AppUrl: req.AppUrl,
          EventName: req.EventName,
          SectionName: req.SectionName,
          EventDescription: req.EventDescription,
          MachineName:req.MachineName,
          OSVersion: req.OSVersion,
          Browser: req.Browser,
          MajorVersion: req.MajorVersion,
          MinorVersion: req.MinorVersion,
          AppPlatform: req.AppPlatform,
          Email: req.Email,
          Token: req.Token,
          IPAddress: req.IPAddress
        
        
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);

          // if (response.status.statusCode == 1) {
          
          //   }

            
          
          return response;
        })
      ).toPromise();
  }

  public checkpartystatus(req) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/Shared/CheckPartnerCreditStatus`, {
        
          PartnerId: req.PartnerId,
          BranchId: req.BranchId,
          LobCode: req.LobCode, 
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);

          // if (response.status.statusCode == 1) {
          
          //   }
          
          return response;
        })
      ).toPromise();
  }

  public sendMail(req) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/Shared/SendEmail`, {    
        To: req.To,
        Subject: req.Subject,
        Body: req.Body, 
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          return response;
        })
      ).toPromise();
  }

  public checkLoginValidation(req) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/User/CheckLoginValidations`, {
        
        ptY_CODE: req.ptY_CODE,
        ptY_NAME: req.ptY_NAME,
        ptY_CREDIT_LMT_YN: req.ptY_CREDIT_LMT_YN,
        ptY_CREDIT_LMT_LC_AMT: req.ptY_CREDIT_LMT_LC_AMT,
        ptY_CREDIT_LMT_DAYS: req.ptY_CREDIT_LMT_DAYS,
        ptY_ACTIVE_YN: req.ptY_ACTIVE_YN,
        ptY_CREDIT_LIMIT_CHK_LVL: req.ptY_CREDIT_LIMIT_CHK_LVL,
        ptY_FLAG: req.ptY_FLAG,
        ptY_CR_CNTRL_FLAG: req.ptY_CR_CNTRL_FLAG ,
        ptY_VAT_EXP_DT: req.ptY_VAT_EXP_DT,
        ptY_DHA_LIC_NO: req.ptY_DHA_LIC_NO,
        ptY_DHA_LIC_VALID_TO_DT:  req.ptY_DHA_LIC_VALID_TO_DT,
        ptY_HAAD_LIC_NO: req.ptY_HAAD_LIC_NO,
        ptY_HAAD_LIC_VALID_TO_DT: req.ptY_HAAD_LIC_VALID_TO_DT,
        ptY_IA_LIC_NO: req.ptY_IA_LIC_NO,
        ptY_IA_LIC_VALID_TO_DT: req.ptY_IA_LIC_VALID_TO_DT,
        ptY_BRK_LIC_VALID_TO_DT: req.ptY_BRK_LIC_VALID_TO_DT
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);

          // if (response.status.statusCode == 1) {
          
          //   }
          
          return response;
        })
      ).toPromise();
  }

  public checkInternalUserLogin(PartnerId,PartyCode) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/User/CheckInternalUser`, {
        PartnerId:PartnerId,PartyCode:PartyCode
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);

          // if (response.status.statusCode == 1) {
          
          //   }
          
          return response;
        })
      ).toPromise();
  }

  public browserDetail() {
    return this.httpClient
      .get(`${config.marineCallAPIURL}/User/GetBrowserInfo`, {})
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);     
          return response;
        })
      ).toPromise();
  }

  public IpInformation() {
    return this.httpClient
      .get(`${config.marineCallAPIURL}/User/GetIpInformation`, {})
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);     
          return response;
        })
      ).toPromise();
  }

  public resetPassword(req) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/User/ValidateAndResetPassword`, {
        username: req.username,
          token: req.token,
          password: req.password, 
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          return response;
        })
      ).toPromise();
  }

  public changePassword(req) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/User/ChangePassword`, {
        username: req.username,
        password: req.password
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
          return response;
        })
      ).toPromise();
  }
  public ProxyLogin(req) {
    return this.httpClient
      .post(`${config.marineCallAPIURL}/User/ProxyLogin`, {
        UserName: req.username,
       AdminId: req.adminId,
      })
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);

          if (response.status.statusCode == 1) {
            if (response.result.token != null) {
              let response_data = JSON.stringify(response.result);

              let userInfo = {
                UserRole: response.result.userRole,
                AccessGroup: response.resultaccessGroup,
                EmailAddress: response.result.emailAddress,
                CedantLogo: response.result.cedantLogo,
                PartnerName: response.result.partnerName,
                UserName: response.result.userName,
                BranchId: response.result.branchId,
                User_ID: response.result.userId,
                Partner_ID: response.result.partnerId,
                Cedant_ID: response.result.cedantId,
                BusinessSource: response.result.businessSource,
              };
              //  localStorage.setItem("UserRole", response.data.UserRole);
              //  localStorage.setItem("EmailAddress", response.data.EmailAddress);
              //  localStorage.setItem("CedantLogo", response.data.CedantLogo);
              //  localStorage.setItem("PartnerName", response.data.PartnerName);
              //  localStorage.setItem("UserName", response.data.UserName);
              localStorage.setItem("currentUser", JSON.stringify(userInfo));
              localStorage.setItem("Dotnet-token", response.result.token);

            }

            this.loggedIn = true;
          } else {
            localStorage.clear();
            this.loggedIn = false;
          }
          return response;
        })
      ).toPromise();
  }
  


}
