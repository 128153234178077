import { Component} from '@angular/core';
import { AutoLogoutService } from './_service/auto-logout.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public _AutoLogoutService:AutoLogoutService){

  }
}
