import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmeRoutingModule } from './sme-routing.module';
import { IssupolicyComponent } from './issupolicy/issupolicy.component';
import { SmequoteComponent } from './smequote/smequote.component';
import {MaterialuiModule} from '../materialui/materialui.module';
import {MatExpansionModule} from '@angular/material/expansion';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { DecimalPipe } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AgmCoreModule } from '@agm/core';
import { SmeComponent } from './sme.component';
// import { ThankyouSMEComponent } from './thankyou/thankyou.component';

// import { SmepaymentinvoiceComponent } from './smepaymentinvoice/smepaymentinvoice.component';
import { SmeFixedComponent } from './sme-fixed/sme-fixed.component';
import { SmeAllriskComponent } from './sme-allrisk/sme-allrisk.component';
import { GeoLocationComponent } from './geo-location-modal/geo-location.component';
import { StandaloneComponent } from './standalone/standalone.component';
// import { GoogleMapService } from 'app/_service/google-map-service';
// import { ThankyouSMEFixedComponent } from './sme-fixed/thankyou/thankyou.component';
// import { ThankyouSMERiskComponent } from './sme-allrisk/thankyou/thankyou.component';
// import { SmecancelpaymentComponent } from './smecancelpayment/smecancelpayment.component';

@NgModule({
  declarations: [IssupolicyComponent,SmequoteComponent, SmeComponent, SmeFixedComponent, SmeAllriskComponent, GeoLocationComponent, StandaloneComponent,  
    // , SmecancelpaymentComponent
  ],
  imports: [
    CommonModule,
    SmeRoutingModule,
    CommonModule,MaterialuiModule,NgxMatSelectSearchModule, MDBBootstrapModule.forRoot(),NgxMaskModule.forRoot(),MatExpansionModule
  ]
})
export class SmeModule { }
