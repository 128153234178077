import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HomequoteService } from '../../../_service/homequote.service';
import { GlobalService } from "../../../_service/global.service";
import { config } from '../../../config';
import Swal from 'sweetalert2';
import { MotorquoteService } from 'app/_service/motorquote.service';
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponentholiday implements OnInit {

  public policyNum:any; paymentLoader:boolean = false; quoteNum:any; public partnerId :any;  localStorDta:any;  orderNumber:any; orderToken:any; 
  public payment_transaction_ID_status:any; payment_Transaction_ID:any; token:any; payPolicyNum:any; debit_Note_Url:any; corePolicyNumber:any;  polUID :any; credit_Note_Url:any; policy_Wording_Url:any;
  UIDNo :any; pol_schedule_IIRIS_url:any; docLoader:boolean = false; creditLoader:boolean = false; policyScedLoder:boolean =false;
  quoteId: string;
  access_type: boolean;
  payWebPolicyNumber: any;
  policyDetail: boolean =false;
    PageLoader: boolean =false;
    policyno: boolean =false;
    response_msg: any;
  constructor(public _route: Router,public motorQuoteService:MotorquoteService,public _activatedroute: ActivatedRoute,public homeQuoteService:HomequoteService, public globalService:GlobalService,public traveInsuranceService:TraveInsuranceService) { }

  ngOnInit() {
    this.paymentLoader = this.globalService.spinnerHide();
    this.docLoader = this.globalService.spinnerHide();
    this.policyno = this.globalService.spinnerShow();
    this.creditLoader = this.globalService.spinnerHide();
    this.policyScedLoder =  this.globalService.spinnerHide();
    this.localStorDta =  this.globalService.getLocalStorageData();
    this.orderNumber =  localStorage.getItem("Payment_Order_ID");
    this.orderToken  =  localStorage.getItem("Payment_Order_Tokan");
    this.quoteNum = localStorage.getItem('Payment_Quotation_Number');
    this.quoteId = localStorage.getItem('Payment_Quotation_ID');
    this.debit_Note_Url =  localStorage.getItem('flexi_home_debit_note');
    this.credit_Note_Url =  localStorage.getItem('flexi_home_credit_note');
    this.corePolicyNumber = localStorage.getItem('flexi_home_corePolicyNumber');
    if(this.localStorDta != null){
      this.partnerId = this.localStorDta.Partner_ID;
      }
  
    const routeParam = this._activatedroute.snapshot.params;
      if(routeParam){

        this.policyNum = routeParam.policyNumber;
        this.quoteNum = routeParam.quoteNum;
        this.token = routeParam.Token;
  
        }

        this.policy_Wording_Url = 'https://b2bmedstaging.fidelityunited.ae/pdfdocuments/hc/ManzilPolicyWording.pdf';
           this.getCheckCN();
        if(this.orderToken!=null){
            this.verifyQuatationPayment();
        }
        else{
          this.PageLoader = this.globalService.spinnerHide();
          this.policyno = this.globalService.spinnerHide();
        }
        //  localStorage.removeItem("Payment_Order_ID");
        //  localStorage.removeItem("Payment_Order_Tokan");
        //  localStorage.removeItem('Payment_Quotation_Number');
  }

  gotoGetQuote(){
      this._route.navigateByUrl('home/getquote');
  }

  goToHome(){
      this._route.navigateByUrl('/dashboard');
  }

  viewPolicy(){

  }
  // payment(){
  //   let server_name = config.server_name;
  //   let return_url = "http://localhost:4200/dashboard";
  //   let site_url = "http://localhost:4200/" + this._route.url;

  //  this.paymentLoader = this.globalService.spinnerShow();

  //  this.homeQuoteService.paymentAuth(this.quoteNum ,site_url,return_url,'HC',this.partnerId,this.policyNum).subscribe(res => {
  //      let payRes = res;

  //      if(payRes.res_code == 101){
  //        Swal.fire(payRes.res_msg);
  //        this.paymentLoader = this.globalService.spinnerHide();
  //        return false;
  //  }
 
  //  else if(payRes.result.response_code == 4012 && payRes.res_code == 1 ){

  //        this.paymentLoader = this.globalService.spinnerHide();
  //        localStorage.setItem('Payment_Order_ID',payRes.result.p_id);
  //        localStorage.setItem('Payment_Order_Tokan',payRes.result.token);
  //        localStorage.setItem('Payment_Quotation_Number',this.quoteNum);

  //          window.location.href = payRes.result.payment_url;


  //      }
        
      
  //  });
  

    
  // }

saveQuotationPayment(){
  this.homeQuoteService.saveQuotationPayment(this.quoteId,this.orderToken,this.payment_transaction_ID_status,this.quoteNum,this.response_msg,this.payment_Transaction_ID,'HC','Flexi').subscribe(res =>{
    console.log(res)
        if(res.res_code == 1){
          // this.policyDetail=this.globalService.spinnerHide();
          this.payPolicyNum = res.Data[0].PolicyReferenceNumber;
          // this.corePolicyNumber=res.CorePolicyNumber;
          this.policyNum = res.web_policy_number;
          // this.payPolicyNum = res.CorePolicyNumber;
        // this.policyuid =res.PolicyUid;
        this.debit_Note_Url = res.debit_note;
        this.credit_Note_Url = res.credit_note;
        this.payWebPolicyNumber = res.web_policy_number;
        this.policyno = this.globalService.spinnerHide();
          // localStorage.removeItem("Payment_Order_ID");
          // localStorage.removeItem("Payment_Order_Tokan");
          // localStorage.removeItem('Payment_Quotation_Number');
          // localStorage.removeItem('flexi_home_debit_note');
          // localStorage.removeItem('flexi_home_credit_note');

        }
  });

}
// response_msg:any ='';
verifyQuatationPayment(){
//Modified by Anju for integrating new payment gateway
this.traveInsuranceService.verifyPayment(this.orderNumber).subscribe(res =>{
  console.log(res);
  let response = res;
  this.response_msg =  response.result.payment_result.response_code;
  this.payment_Transaction_ID = response.result.tran_ref;
         
          if(res.res_code == 2){
            // For Test
            //if(res.result.details == "This invoice is either cancelled by the customer or expired"){	
              // For Prod  if(res.result.details == "This invoice is either cancelled by the customer or expired" || res.result.response_code == "476"){		
              this.payment_transaction_ID_status = "CANCELLED";	
              this._route.navigateByUrl('travel/cancelpayment/' + this.response_msg+ "/" +this.quoteNum + "/" +"HC");		
              this.PageLoader = this.globalService.spinnerHide();	
          }	
          else{	
            this.payment_transaction_ID_status = "ISSUED";	
            this.saveQuotationPayment();	
            this.PageLoader = this.globalService.spinnerHide();	
        }

            //   if(res.result.transaction_id == null){
            //       this.payment_transaction_ID_status = "CANCELLED";
            //   }
            //   if(res.result.transaction_id != null){
            //     this.payment_transaction_ID_status = "ISSUED";
            // }


            //  this.saveQuotationPayment();
    });

}
getCheckCN(){
this.motorQuoteService.getCheckCN('HC').subscribe(res =>{

      this.access_type = res.access_type;
});
}

generatePDF(type){
if(type == 1){
  console.log('policynum')
  this.policyScedLoder =  this.globalService.spinnerShow();
  this.policyNum = this.orderNumber == null ? this.policyNum: this.policyNum;
  console.log('policynum',this.policyNum)
  console.log('payWebPolicyNumber',this.payWebPolicyNumber)
      this.homeQuoteService.getPolSchedulPDF(this.partnerId,this.policyNum,this.quoteNum).subscribe(res =>{
            let polSchedulRes = res;
            if(polSchedulRes.response_code == 1){
              window.open(polSchedulRes.pdfPath, "_blank");
              this.policyScedLoder =  this.globalService.spinnerHide();
            }
      });
}

if(type == 2){
  this.docLoader = this.globalService.spinnerShow();
                   window.open( this.debit_Note_Url, "_blank");
                   this.docLoader = this.globalService.spinnerHide();
}

if(type == 3){
  this.creditLoader = this.globalService.spinnerShow();
                   window.open( this.credit_Note_Url, "_blank");
                   this.creditLoader = this.globalService.spinnerHide();
}
    
}

  
}
