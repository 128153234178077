import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgentmotorquoteComponent } from './agentmotorquote.component';
import { ApproveReferralQuoteComponent } from './approve-referral-quote/approve-referral-quote.component';
import { MotorThankyouComponent } from './thankyou/thankyou.component'; // ruhina - added Thank You routing
const routes: Routes = [
    {
      path: '',
      component: AgentmotorquoteComponent,
      data: {
        icon: 'icofont icofont-file-document bg-c-pink',
        breadcrumb_caption: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit - Sample Page',
        status: true
      }
    },

    { 
        path: 'update/:quoteNum',
        component: AgentmotorquoteComponent,
    },

    {
      path : 'approveQuote/:quoteNum',
      component : ApproveReferralQuoteComponent
    },

    {
       path: 'thankyou/:quoteNum',
       // path: 'thankyou',
        component: MotorThankyouComponent,
    },

   
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgentmotorquoteRoutingModule { }
