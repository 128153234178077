import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { GlobalService } from "../../../_service/global.service";
import { HomequoteService } from '../../../_service/homequote.service';
import { config } from '../../../config';
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.scss']
})
export class PaymentConfirmComponent implements OnInit {

  quoteNum = 0; loader:boolean = false; 
  token = 0; orderNumber:any; orderToken:any; quoteNumber:any; payment_transaction_ID_status:any; payment_Transaction_ID:any;
  totalPremium :any; quoteDetail:any = [];
  quoteId: string;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public homeQuoteService:HomequoteService,public globalService:GlobalService,public traveInsuranceService:TraveInsuranceService) { }

  ngOnInit() {

        this.orderNumber =  localStorage.getItem("Payment_Order_ID");
        this.orderToken  =  localStorage.getItem("Payment_Order_Tokan");
        this.quoteNumber = localStorage.getItem('Payment_Quotation_Number');
        this.quoteId = localStorage.getItem('Payment_Quotation_ID');
        const routeParam = this._activatedroute.snapshot.params;
        if(routeParam){
  
         this.token = routeParam.Token;
    
        }
         this.verifyQuatationPayment();

  }
  saveQuotationPayment(){
    this.homeQuoteService.saveQuotationPayment(this.quoteId,this.orderToken,this.payment_transaction_ID_status,this.quoteNum,this.response_msg,this.payment_Transaction_ID,'HC','Fixed').subscribe(res =>{
      
          if(res.res_code == 1){
            localStorage.removeItem("Payment_Order_ID");
            localStorage.removeItem("Payment_Order_Tokan");
            localStorage.removeItem('Payment_Quotation_Number');

          }
    });

}
response_msg:any ='';
verifyQuatationPayment(){

  this.traveInsuranceService.verifyPayment(this.orderNumber).subscribe(res =>{
    let response = res;
    this.response_msg = response.result.result;
    this.payment_Transaction_ID = response.result.transaction_id;
           
             if(res.res_code == 1){

                if(res.result.transaction_id == null){
                    this.payment_transaction_ID_status = "CANCELLED";
                }
                if(res.result.transaction_id != null){
                  this.payment_transaction_ID_status = "ISSUED";
              }


               this.saveQuotationPayment();
             }
      });

}

donePayment(){
        this._route.navigateByUrl('/dashboard');
  }

}
