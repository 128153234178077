import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IssupolicyComponent } from './issupolicy/issupolicy.component';
import { SmeAllriskComponent } from './sme-allrisk/sme-allrisk.component';
import { SmeFixedComponent } from './sme-fixed/sme-fixed.component';
import { SmeComponent } from './sme.component';
import { SmequoteComponent } from './smequote/smequote.component';
import { StandaloneComponent } from './standalone/standalone.component';
// import { ThankyouSMEComponent } from './thankyou/thankyou.component';
// import { ThankyouSMEFixedComponent } from './sme-fixed/thankyou/thankyou.component';
// import { ThankyouSMERiskComponent } from './sme-allrisk/thankyou/thankyou.component';
// import { SmecancelpaymentComponent } from './smecancelpayment/smecancelpayment.component';

const routes: Routes = [
  {
    path: '',
    component: SmeComponent,
  },
  {
    path: 'issuepolicy',
    component: IssupolicyComponent,
  },
  {
    path: 'issuepolicy/update/:policyNumber',
    component: IssupolicyComponent,
  },
  {
    path: 'issuepolicy/update/:policyNumber/:ID',
    component: IssupolicyComponent,
  },
  {
    path: 'issuepolicy/update/:quoteNumber/:ID',
    component: IssupolicyComponent,
  },
  {
    path: 'smefixed',
    component: SmeFixedComponent,
  },
  {
    path: 'smefixed/update/:policyNumber',
    component: SmeFixedComponent,
  },
  {
    path: 'smefixed/update/:quoteNumber/:ID',
    component: SmeFixedComponent,
  },
  {
    path: 'smeallrisk',
    component: SmeAllriskComponent,
  },
  {
    path: 'smeallrisk/update/:policyNumber',
    component: SmeAllriskComponent,
  },
  {
    path: 'smeallrisk/update/:quoteNumber/:ID',
    component: SmeAllriskComponent,
  },
  {
    path: 'products',
    component: SmequoteComponent,
  },
  {
    path: 'standalone/:productid',
    component: StandaloneComponent,
  },
  // {
  //   path: 'thankyou/:policyNumber/:quoteid',
  //   component: ThankyouSMEComponent,
  // },
  // {
  //   path: 'smefixed/thankyou/:policyNumber/:quoteid',
  //   component: ThankyouSMEFixedComponent,
  // },
  // {
  //   path: 'smeallrisk/thankyou/:policyNumber/:quoteid',
  //   component: ThankyouSMERiskComponent,
  // },
  // {
  //   path : 'sme/cancelpayment/:Res_Code/:quoteNum/:businessSource',
  //   component : SmecancelpaymentComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmeRoutingModule { }
