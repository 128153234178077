import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomequoteComponent } from './homequote.component';
import { ThankYouComponenthome } from './thank-you/thank-you.component';
import {PaymentConfirmComponent } from './payment-confirm/payment-confirm.component';
const routes: Routes = [
  {
    path: '',
    component: HomequoteComponent,
    data: {
      icon: 'icofont icofont-file-document bg-c-pink',
      breadcrumb_caption: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit - Sample Page',
      status: true
    }
  },

  {
    path: 'thankyou/:policyNumber/:quoteNum',
    component: ThankYouComponenthome,
  },

  {
    path: 'pay/r/:Token',
    component: PaymentConfirmComponent,
  },

  { path: 'update/:quoteNum',
    component: HomequoteComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomequoteRoutingModule { }
