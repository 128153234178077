import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { config } from "../config";
// import { WalletManagementModule } from "app/modules/admin/wallet-management/wallet-management.module";
let MINUTES_UNITL_AUTO_LOGOUT:any = config.autologouttime; // in mins
const CHECK_INTERVAL = 5000 // in ms
const STORE_KEY =  'lastAction';
const token = localStorage.getItem('accessToken')
@Injectable(
  {providedIn: 'root'}
)
export class AutoLogoutService {
 public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router) {
  
// console.log(window.location.pathname)

  this.check();
  this.initListener();
  this.initInterval();
  localStorage.setItem(STORE_KEY,Date.now().toString());
    
  }

  initListener() {
    MINUTES_UNITL_AUTO_LOGOUT = config.autologouttime
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    MINUTES_UNITL_AUTO_LOGOUT = config.autologouttime;
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    // console.log(diff/1000);


    if (isTimeout)  {
      //alert(1)
      localStorage.clear();
        
      window.location.href = config.logOutURL;
    }
  }
}