import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentmotorquoteComponent } from './agentmotorquote.component';
import { AgentmotorquoteRoutingModule } from './agentmotorquote-routing.module';
import {MaterialuiModule} from '../../materialui/materialui.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ApproveReferralQuoteComponent } from './approve-referral-quote/approve-referral-quote.component';
import { UppercaseInputDirective } from './uppercase.directive'
import { MotorThankyouComponent } from './thankyou/thankyou.component'
import { TraveInsuranceService } from '../../_service/traveInsurance.service';
import { AutoAddSlashDirective } from './autoaddslash.directive';

@NgModule({
  declarations: [AgentmotorquoteComponent, ApproveReferralQuoteComponent, MotorThankyouComponent, UppercaseInputDirective,AutoAddSlashDirective],
  imports: [
    CommonModule,MaterialuiModule,MDBBootstrapModule,AgentmotorquoteRoutingModule,NgxMatSelectSearchModule,
    NgxMaskModule.forRoot(),
  ]
})
export class AgentmotorquoteModule { }
