import { Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomequoteService } from '../../_service/homequote.service';
import { GlobalService } from "../../_service/global.service";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router,ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { config } from '../../config';
import { MatStepper } from '@angular/material/stepper';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material';
import { take, takeUntil } from 'rxjs/operators';
import { MotorquoteService } from 'app/_service/motorquote.service';
import { SmequoteService } from 'app/_service/smequote.service';
import { IssuepolicyService } from 'app/_service/issuepolicy.service';	
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';



interface Gender {
  value: string;
  label: string;
}
interface Country {
  value: string;
  label: string;
}


interface Property {
  value: string;
  label: string;
}

interface Code {
  value: string;
  label: string;
}
interface Codehomeoffc {
  value: string;
  label: string;
}
interface City {
  CRS_CITY_CODE: string;
  CRS_VEH_REG_PLACE: string;
  CRS_VISA_LOC_CODE:string;
  CityName: string;
  Id: number;
}

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-holidayquote',
  templateUrl: './holidayquote.component.html',
  styleUrls: ['./holidayquote.component.scss'],
  providers:[
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class HolidayquoteComponent implements OnInit {

 /** control for the MatSelect filter keyword  */
 public cityFilterCtrl: FormControl = new FormControl();

 /** list of banks filtered by search keyword */
 public filteredCities: ReplaySubject<City[]> = new ReplaySubject<City[]>(1);

 @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
 /** Subject that emits when the component has been destroyed. */
 private _onDestroy = new Subject<void>();
  accidenatalDetails: any=[];
  contentCoverDetails: any;
  personalBelongingDetails: any;
  buttonAcc: any;
  issuePolicyBtnAccess: boolean =true;
  onlinePayBtnAccess: boolean =true;
  quoitationID:any;
  branchId: any;	
  branchVal: any;	
  formDataRes: any;	
  partnerVal: any;
  savedBranchId:any;
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }
  genders: Gender[] = [
    {value: 'M', label: 'Male'},
    {value: 'F', label: 'Female'}
  ];
  countrys: Country[] = [
    {value: '0', label: 'United Arab Emirates'}
  ];
 
  properties: Property[] = [
    {value: '0', label: 'Apartment/Flat'},
    {value: '1', label: 'Villa'},
  ];
  
  
  codes: Code[] = [
    {value: '050', label: '050'},
    {value: '052', label: '052'},
    {value: '054', label: '054'},
    {value: '055', label: '055'},
    {value: '056', label: '056'},
    {value: '057', label: '057'},
    {value: '058', label: '058'},
  ];
  codehomeoffcs: Codehomeoffc[] = [
    {value: '02', label: '02'},
    {value: '02', label: '03'},
    {value: '04', label: '04'},
    {value: '06', label: '06'},
    {value: '07', label: '07'},
    {value: '09', label: '09'},
  ];

  plans:any=  [
    {name:"Standard",amount:"75"},
    {name:"Elite",amount:"175"},
    {name:"Plus",amount:"375"}
  ];

  

  colorPlan:any=[{color:"backgroundmaintheme-blue"},
                 {color:"backgroundmaintheme-lavender"},
                 {color:"backgroundmaintheme-lightblue"},
                 {color:"backgroundmaintheme-blue"}]



  policyHolderForm :FormGroup; planDetailForm:FormGroup; additionalFormDetail:FormGroup;

  //Declare All Arrays
  CoverTypeData:any = []; owershipStatData:any = []; quotationDataArr:any = []; quotationValuesArray:any = []; contributionData:any =[];
  planBenefitsData:any = []; saveQuotePlanData:any = []; locationData:any = []; propertyTypeData:any = []; quoteDetailData:any = [];
  policyValueData:any = []; cityData:City[]; updateQuoteDataArr:any = [];partnersArr:any  = [];	
  partnerBranchArr:any=[];quatationNo='';	
  selectedPartnerId: any; //Added by Anju for Partner Status Check	
  selectedBranch: any;//Added by Anju for Partner Status Check

  private digi_status = new Subject<string>();
  digi_status$ = this.digi_status.asObservable();
  digiSwalInstance:any;
  //Declare all variables
  public partnerId :any; localStorDta:any; lob:any = 'HC'; prodCode:any ='HCINS'; language:any ='ENG'; policyFormStatus:boolean = true; checked:any=[];
  public user_email:any; cedant_id:any; value_of_content:any; user_id:any; value:any = 0; totalContribution:any = 0; plan_Id:any = ''; quoteNumber:any='';
  public planStatus:boolean=true;  view_type:any = 'EDIT'; policyFee:any; planName:any; basePrimium:any; vatAmt:any; totalPre:any; loader:boolean = false;
  public isEditable:boolean = false; quoteRes:boolean=false; viewBenLoader:boolean = false; policyNumber:any; baseContribution:any; toalContri:any;   minDate = new Date(Date.now());
  serviceFee:any; adminFee:any; VATPercent:any; VATAmount:any; PaymentType:any;  invalidEID:boolean = false; isValidFormSubmitted = true; prevButton:boolean = true; issuePolButton:boolean = true;discountPromoCodeValue:any;
  paymentButton:boolean = true; issuePolLoader:boolean = false; invalid_promoCode:any;
  date = new Date(new Date().setDate(new Date().getDate() + 60)); Plan_Name:any; paymentLoader:boolean = false; accept_terms :boolean = false; accept_disclaimer :boolean = false;sourceType ='B2B'
  document_data: any;
  document_data1: any;
  document_data2: any;
  filename: any;
  filename1: any;
  filename2: any;
  fileType: string;
  fileType1: string;
  fileType2: string;
  multilpleFile: any = [];
  multilpleFile1: any = [];
  multilpleFile2: any = [];
  multipleImg: any = "";
  showAllDocImg: any = 0;
  ImagedocumentType: any;
  docUploadFlag: boolean ;
  docUploadFlag1: boolean ;
  emiratesIdPath:any;
  titleDeedPath:any;
  otherDocPath:any;
  eidFileType:any;
  tDFileType:any;
  oDFileType:any;


  constructor(public _route: Router,public smequoteService : SmequoteService ,public _activatedroute: ActivatedRoute,public formBuilder: FormBuilder, public homeQuoteService:HomequoteService, public globalService:GlobalService , public motorQuoteService: MotorquoteService,public issuepolicyService:IssuepolicyService,public travelInsuranceService:TraveInsuranceService) { 
    
  }

  ngOnInit() {
  //  this.getupload();
    // var encrypted = this.EncrDecr.set(config.encKey, 'password@123456');
    // var decrypted = this.EncrDecr.get(config.encKey, encrypted);
   
    this.loader=this.globalService.spinnerHide();
    this.paymentLoader = this.globalService.spinnerHide();
    this.issuePolLoader = this.globalService.spinnerHide();
    
    this.localStorDta =  this.globalService.getLocalStorageData();
    this.partnerId = this.localStorDta.Partner_ID;
    this.cedant_id = this.localStorDta.Cedant_ID;
    this.user_email = this.localStorDta.EmailAddress;
    this.user_id =this.localStorDta.User_ID;

      this.policyHolderForm = this.formBuilder.group({
        partner:[''],	
        branch:[''],
        name: ['',[Validators.required,Validators.minLength(3), Validators.maxLength(150),Validators.pattern('^[a-zA-Z&/ \-\']+')]],
        gender: ['',Validators.required],
        email: ['',Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+)[.]{1}[a-zA-Z]{2,}$')])], 
        // code: ['',Validators.required],
        mobileNum: ['',[Validators.required,Validators.minLength(7), Validators.maxLength(10)]],
        residenceContry: ['',Validators.required],
        city: ['',Validators.required],
       // location: ['',Validators.required],
        propertyType: ['',Validators.required],
        ownershipStatus: ['',Validators.required],
        coverType: ['',Validators.required],
        noDaysPropUnOccupied: ['',Validators.required],
        suffered_losses: ['',Validators.required],
        otherForm: ['',Validators.required],
        
      });

      this.planDetailForm = this.formBuilder.group({
        quote_plan_id: ['',Validators.required],
        loading_per:[0],
        discount_per:[0],
        promo_code : ''
      
      });

      this.additionalFormDetail = this.formBuilder.group({
        policy_comm_date: [this.minDate,Validators.required],
        emiratesID: ['',Validators.required],
        ph_name: ['',[Validators.required,Validators.minLength(3), Validators.maxLength(150),Validators.pattern('^[a-zA-Z&/ \-\']+')]],
        email: ['',[Validators.required,Validators.email,Validators.pattern('^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+)[.]{1}[a-zA-Z]{2,}$')]],
        // mCode:['',Validators.required],
        mobile_num: ['',[Validators.required,Validators.minLength(7), Validators.maxLength(10)]],
        hCode:[''],
        home_num: [''],
        po_box_num: [''],
        street_name: [''],
        address : ['',Validators.required],
        landmark: [''],
        location: ['',Validators.required],
        //accept_terms: ['',Validators.required],
        
      });

     
      this. gelAllFormData();
     
      // listen for search field value changes
      this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterCity();
      });

      this.countrys.forEach((item,index)=>{
            if(item.label == 'United Arab Emirates'){
              this.policyHolderForm.get('residenceContry').setValue(item);
            }
      })

      // const routeParams = this._activatedroute.snapshot.params;

      // if(routeParams.quoteNum){
      //   this.quoteNumber = routeParams.quoteNum;
      //   this.getQuoteDetail();
      // }
     
  }

  // getupload(){
  //   this.homeQuoteService.uploadfiles().subscribe(res=>{

  //   })
  // }

  get f() { return this.policyHolderForm.controls; }
  get g() { return this.planDetailForm.controls; }
  get h() { return this.additionalFormDetail.controls; }
  

  getQuotation(stepper){

      // alert(1)

        let tempmemberDetailFormData = 
        {
          name: this.policyHolderForm.value.name,
          gender: this.policyHolderForm.value.gender,
          insured_email: this.policyHolderForm.value.email, 
          // MobileCode: this.policyHolderForm.value.code,
          mobileNum: this.policyHolderForm.value.mobileNum,
          residenceContry: this.policyHolderForm.value.residenceContry,
          city: this.policyHolderForm.value.city,
         // location: this.policyHolderForm.value.location,
          propertyType: this.policyHolderForm.value.propertyType,
          ownershipStatus: this.policyHolderForm.value.ownershipStatus,
          coverType: this.policyHolderForm.value.coverType,
          noDaysPropUnOccupied: this.policyHolderForm.value.noDaysPropUnOccupied,
          suffered_losses:this.policyHolderForm.value.suffered_losses,
          value_of_content:'0',
          partner_id:this.policyHolderForm.value.partner,	
          branch_id:this.policyHolderForm.value.branch.Id,
          product_id:'1080',
          product_code:this.prodCode,
          cedant_id:this.cedant_id,
          user_email:this.user_email,
          user_id:this.user_id,
          productType:'FIXED',
          //Added by Anju for document insert
          finalFileWithURL: this.document_data,
          finalFileWithURL1: this.document_data1,
          finalFileWithURL2: this.document_data2,
          fileName: this.filename,
          fileName1: this.filename1,
          fileName2: this.filename2,
          fileType: this.fileType,
          fileType1: this.fileType1,
          fileType2: this.fileType2
        }

        this.quotationDataArr = tempmemberDetailFormData;

        if(this.quotationDataArr.noDaysPropUnOccupied == 'More than 60 days' || this.quotationDataArr.suffered_losses == "Yes"){
             
              Swal.fire("Sorry! we could not find any plan based on your quotation criteria. Please try again or contact your relationship manager.", "Sorry for the inconvenience caused", "error");
              return false;
          }
          else if(this.docUploadFlag != true && this.docUploadFlag1 != true){
            Swal.fire('','Please upload mandatory files','error');
          }
          else if(this.docUploadFlag != true && this.docUploadFlag1 == true){
            Swal.fire('','Please upload Emirates ID','error');
          }
          else if(this.docUploadFlag == true && this.docUploadFlag1 != true){
            Swal.fire('','Please upload Title Deed','error');
          }
          else {
            this.policyHolderForm.get('otherForm').setValue('1');
           
                  this.quoteRes=this.globalService.spinnerShow();
                  this.homeQuoteService.forGetQuotation(this.quotationDataArr,'',this.sourceType).subscribe(res =>{
                        this.quoteNumber = res.quotation_number;
                        this.quoteRes = this.globalService.spinnerHide();
                      this.calculateContribution();
          
                  });
                  this.planDetailForm.value.promo_code = 'SPLD-10';
                  stepper.next();
          }
       
        
          if(this.policyHolderForm.invalid){
          
            return false;
          }

  }

//--------------------------------- Get All Form Data ------------------------------
    gelAllFormData(){
      this.homeQuoteService.getPropertyType(this.partnerId,this.language,this.sourceType).subscribe(res =>{
                    this.propertyTypeData = res.propertyTypeData;
      });

      this.homeQuoteService.getCoverType(this.partnerId,this.lob,this.prodCode,this.sourceType).subscribe(res =>{
                   this.CoverTypeData = res.coverTypeData;
                   this.policyHolderForm.get('coverType').setValue(this.CoverTypeData[0]); 
      });

      this.homeQuoteService.getOwnershipStatus(this.partnerId,this.language,this.sourceType).subscribe(res =>{
                  this.owershipStatData = res.ownershipStatusData[1];
                  console.log(this.owershipStatData)
      });

      this.homeQuoteService.getCityData('United Arab Emirates').subscribe(res =>{
                  this.cityData = res.cityData;
                  this.filteredCities.next(this.cityData.slice());


      });

      this.issuepolicyService.getQuotationFormData().subscribe(response =>{	
        this.formDataRes = response;	
        this.quoteRes = this.globalService.spinnerHide();	
        this.partnersArr = this.formDataRes.Partners;	
    	
        // if(this.quatationNo =='' ){	
      //  console.log(this.partnerId)	
          this.partnersArr.forEach((item,index)=>{	
     	
               if(item.PartnerId== this.partnerId){	
                 this.partnerVal  = item.PartnerId;	
                 	
               }	
              	
          });	
          const routeParams = this._activatedroute.snapshot.params;
     
          if(routeParams.quoteNum){
            //console.log('routeParams getQuoteDetail ');
            this.quoteNumber = routeParams.quoteNum;
            this.getQuoteDetail();
          }
          else{
          this.policyHolderForm.get('partner').setValue(this.partnerVal);
           this.getPartnerBranchList();
          }
    	
      });

      if(this.quoteNumber !=''){
        this.editQuotationFormData();
  
      }

      

}

  getLocationData(city){
    this.loader=this.globalService.spinnerShow();
          this.homeQuoteService.getLocationData(city,this.language).subscribe(res =>{
            
                 this.locationData = res.locationData;
                 this.loader=this.globalService.spinnerHide();
          });
  }
//--------------------------------- CALCULATE CONTRIBUTION ------------------------------
    calculateContribution(){
     this.checked =[];
     this.planDetailForm.get('quote_plan_id').setValue(null);
        this.quotationValuesArray = {
            cover_type :this.policyHolderForm.value.coverType,
	          sub_cover_type :null,
	          product_code :'HHINS',
	          partner_id :this.policyHolderForm.value.partner,
        }
      
       
        this.quoteRes=this.globalService.spinnerShow(); 
        this.homeQuoteService.calculateContribution(this.quotationValuesArray,this.sourceType).subscribe(res =>{
         
            this.contributionData = res.contributionData;
            this.quoteRes = this.globalService.spinnerHide();
            this.contributionData.forEach((item,index) => {
              
              this.totalContribution = item.FixedContribution + item.PolicyFees + item.VATAmount
              this.contributionData[index].TotalContribution = this.totalContribution;
              this.contributionData[index].FixedContributionTemp =  this.contributionData[index].FixedContribution;
              this.contributionData[index].checked = false;
                  
            });
                
        });
        
    }
//--------------------------------- VIEW PLAN BENEFITS ------------------------------
  viewPlanBenefit(frame,plan_Id){
   
   
    let index = this.selectedPlanIndex ;

    // this.planDetailForm.get("quote_plan_id").setValue(index.toString());
    // console.log(this.planDetailForm.value.quote_plan_id);
    // this.plan_Id = this.contributionData[index].PlanId;
    // this.planName = this.contributionData[index].PlanName;
    // this.basePrimium = this.contributionData[index].FixedContributionTemp;
    // this.vatAmt = this.contributionData[index].VATAmount;
    // this.policyFee = this.contributionData[index].policyFee;
    // this.totalPre = this.contributionData[index].TotalContribution;
    
    //this.saveQuotePlanDetail("1");
    frame.show();
    this.viewBenLoader=this.globalService.spinnerShow();
    this.homeQuoteService.getPlanBenefits(plan_Id,this.quoteNumber,null,this.policyHolderForm.value.partner,'').subscribe(res =>{
      console.log(plan_Id);
              this.planBenefitsData = res.quotationPlanBenefitsData;
              if(plan_Id == '1139'){
                this.contentCoverDetails =this.planBenefitsData[0].data;
              this.accidenatalDetails = this.planBenefitsData[1].data;
              this.viewBenLoader = this.globalService.spinnerHide();
              this.Plan_Name = this.planBenefitsData[0].data[0].PlanName;
          }else{
            this.contentCoverDetails =this.planBenefitsData[0].data;
            this.viewBenLoader = this.globalService.spinnerHide();
            this.Plan_Name = this.planBenefitsData[0].data[0].PlanName;
          }
              // this.contentCoverDetails =this.planBenefitsData[0].data;
              // this.accidenatalDetails = this.planBenefitsData[1].data;
              // this.personalBelongingDetails =this.planBenefitsData[2].data;
              // this.viewBenLoader = this.globalService.spinnerHide();
              // console.log(this.accidenatalDetails)
              // this.Plan_Name = this.planBenefitsData[0].data[0].PlanName;
              // console.log( this.Plan_Name)
              
    }); 

    
  }
  selected:number;
//--------------------------------- SAVE QUOTEATION PLAN DETAIL ------------------------------
selectedPlanIndex = -1 ;
getPlanId(index,iD,planName,basePrimium,vatAmt,policyFee,totalPre){
  
        if(index == this.selectedPlanIndex){
              return false;
        }
 
  this.selectedPlanIndex = index ;
  this.plan_Id = iD;
  this.planName = planName;
  this.basePrimium = this.contributionData[index].FixedContributionTemp;
  this.vatAmt = this.contributionData[index].VATAmount;
  this.policyFee = this.contributionData[index].policyFee;
  this.totalPre = this.contributionData[index].TotalContribution;
}

  saveQuotePlanDetail(validateCheck="",type){


    if(validateCheck=="" && this.planDetailForm.invalid){
     
      return false;
    } 

      this.saveQuotePlanData = {
        cover_type :this.policyHolderForm.value.coverType,
        value_of_content :'0',
        product_code :this.prodCode,
        partner_id :this.policyHolderForm.value.partner,
        quotes_number :this.quoteNumber,
        plan_id : this.plan_Id,
        PlanName: this.planName,
        BasePremium : this.contributionData[this.selectedPlanIndex].FixedContributionTemp,
        VATAmount : this.contributionData[this.selectedPlanIndex].VATAmount,
        PolicyFees : this.policyFee,
        TotalPremium : this.contributionData[this.selectedPlanIndex].TotalContribution,
        LoadingPercent :this.planDetailForm.value.loading_per,
        DiscountPercent :this.planDetailForm.value.discount_per,
        OptionalCover : '0',
      }

      this.homeQuoteService.saveQuotePlanDetail(this.saveQuotePlanData,this.sourceType).subscribe(res =>{

                 

                  if(type == 1){
                    this.viewQuotePDF();
                  }else if(type ==2){
                    this.checkAccessForPolicyIssueButtons();
                    this.getQuoteDetail();
                  }else if(type ==3){
                    this.sendEmail();
                  }
      });
  }

  sendEmail(){
   
   // this.saveQuotePlanDetail();
  
        this.homeQuoteService.sendEmail(this.partnerId,this.quoteNumber,this.policyHolderForm.value.email,'STANDARD').subscribe(res =>{
                this.quoteRes = this.globalService.spinnerHide();
                Swal.fire("", "Thank you for choosing Fidelity United. We have sent an email to your registered email with all the quotation details. Your Quotation Reference# " + this.quoteNumber, 'success');
       });
  
  }

//--------------------------------- GET QUOTATION DETAIL ------------------------------
  getQuoteDetail(){

        this.homeQuoteService.getQuotationDetail(this.view_type,this.quoteNumber,this.partnerId,this.sourceType).subscribe(res =>{
                        this.quoteDetailData = res.quotationDetailsData[0];
                        this.baseContribution = this.quoteDetailData.BaseContribution;
                        this.toalContri = this.quoteDetailData.TotalContribution;
                        this.serviceFee = this.quoteDetailData.ServiceFees;
                        this.adminFee = this.quoteDetailData.AdminFees;
                        this.VATPercent = this.quoteDetailData.VATPercent;
                        this.VATAmount = this.quoteDetailData.VATAmount;
                        this.PaymentType = this.quoteDetailData.PaymentType;
                            //Added by Anju for Silkaus Plan display
                        if(this.planName=='Silkhaus Plan'){
                          const policyholderName = `SILKHAUS VACATION HOMES LLC &/Or ${this.quoteDetailData.InsuredName}`;
                          this.additionalFormDetail.get('ph_name').setValue(policyholderName);
                        }
                        else if(this.planName=='GuestReady Plan')
                          {
                          const policyholderName = `GUESTREADY VACATION HOMES RENTAL L.L.C &/Or ${this.quoteDetailData.InsuredName}`;
                          this.additionalFormDetail.get('ph_name').setValue(policyholderName);
                        }
                        else{
                        this.additionalFormDetail.get('ph_name').setValue(this.quoteDetailData.InsuredName);
                        }
                        this.additionalFormDetail.get('email').setValue(this.quoteDetailData.InsuredEmail);
                        this.additionalFormDetail.get('mobile_num').setValue(this.quoteDetailData.InsuredMobile);
                        //Added by Anju for document upload
                        this.emiratesIdPath = this.quoteDetailData.EmiratesIdPath;
                        this.titleDeedPath = this.quoteDetailData.TitleDeedPath;
                        this.otherDocPath = this.quoteDetailData.OtherDocPath;
                        this.eidFileType = this.quoteDetailData.EidFileType;
                        this.tDFileType = this.quoteDetailData.TDFileType;
                        this.oDFileType = this.quoteDetailData.ODFileType;
                        
                        //Code 
                        let moCode =  this.quoteDetailData.MobileCode ;
                        var indexcode =  this.codes.findIndex(function(obj, k) {
                          return obj.label == moCode;
                        });
                        
                        let codeVal = this.codes[indexcode];

                        // this.additionalFormDetail.get('mCode').setValue(codeVal);
                        // this.policyHolderForm.get('code').setValue(codeVal);

                        //Policy holder detail
                    

                          this.policyHolderForm.get('name').setValue(this.quoteDetailData.InsuredName);

                        this.policyHolderForm.get('mobileNum').setValue(this.quoteDetailData.InsuredMobile);
                        this.policyHolderForm.get('email').setValue(this.quoteDetailData.InsuredEmail);  
                        this.policyHolderForm.get('noDaysPropUnOccupied').setValue(this.quoteDetailData.UnOccupiedDays);
                        this.policyHolderForm.get('suffered_losses').setValue(this.quoteDetailData.SufferedLosses);

                        //gender
                        let genderVal = this.quoteDetailData.InsuredGender;
                        var indexGender =  this.genders.findIndex(function(obj, k) {
                          return obj.value == genderVal;
                        });
                        
                        let gVal = this.genders[indexGender];
                        this.policyHolderForm.get('gender').setValue(gVal);

                         //City
                         let cityVal = this.quoteDetailData.City;
                         var indexCity =  this.cityData.findIndex(function(obj, k) {
                           return obj.CityName == cityVal;
                         });
                         
                         let cVal = this.cityData[indexCity];
                         this.policyHolderForm.get('city').setValue(cVal);

                        //Property - PropertyTypeId
                        let propVal = this.quoteDetailData.PropertyTypeId;
                        var indexProp =  this.propertyTypeData.findIndex(function(obj, k) {
                          return obj.PropertyTypeName == propVal;
                        });
                        
                        let pVal = this.propertyTypeData[indexProp];
                        this.policyHolderForm.get('propertyType').setValue(pVal);

                        //Ownership - OwnershipStatusId
                        // let ownshpVal = this.quoteDetailData.OwnershipStatus;
                        // var indexOwnershp =  this.owershipStatData.findIndex(function(obj, k) {
                        //   return obj.OwnershipStatusName == ownshpVal;
                        // });
                        // console.log(indexOwnershp);
                    
                        let ownVal = this.owershipStatData;
                        this.policyHolderForm.get('ownershipStatus').setValue(ownVal);

                        //-----------------------------------------------Partner -------------------------------------------------	
                        // console.log(this.quoteDetailData)	
                        let partnerId = this.quoteDetailData.PartnerId;	
 var indexpartner =  this.partnersArr.findIndex(function(obj, k) {	
   return obj.PartnerId == partnerId;	
 });	
//  console.log(indexpartner)	
 let partnerVal = this.partnersArr[indexpartner].PartnerId;	
//  console.log(partnerVal)	
 this.policyHolderForm.controls['partner'].setValue(partnerVal);	
 this.savedBranchId=this.quoteDetailData.CedantBrokerBranchId;
 this.getPartnerBranchList();	
//  console.log(this.policyHolderFormArray[index])	
//  let locationValue = this.quoteDetailData[index].LocationName;	
//  this.getLocationData(index,1,locationValue);	
 //-----------------------------------------------Branch  -------------------------------------------------	
 let branchId = this.quoteDetailData.CedantBrokerBranchId;	
// console.log(partnerId)	
 var indexbranch =  this.partnerBranchArr.findIndex(function(obj, k) {	
   return obj.Id == branchId;	
 });	
//  console.log(indexpartner)	
 let branchVal = this.partnerBranchArr[indexbranch];	
//  console.log(branchVal)	
 this.policyHolderForm.controls['branch'].setValue(branchVal);

                        
        });
  }

checkTermsCond(){
      this.accept_terms = !this.accept_terms;
}

checkDisclaimer(){
  this.accept_disclaimer = !this.accept_disclaimer;
}
//--------------------------------- ISSUE POLICY ------------------------------
  issuePolicy(){

      if(this.additionalFormDetail.invalid){
        return false;
      } 
      if(this.invalidEID == true){

        return;
     }
     if(this.accept_terms == false){
          return;
     }
     if(this.accept_disclaimer == false){
      return;
 }
     this.issuePolLoader = this.globalService.spinnerShow();

     this.isValidFormSubmitted = false;
      this.isEditable = true;
      this.prevButton = false;
      this.paymentButton = false;

      this.policyValueData = {
        commencement_date:this.additionalFormDetail.value.policy_comm_date,
        emiratesID:this.additionalFormDetail.value.emiratesID,
        insured_name:this.additionalFormDetail.value.ph_name,
        email :this.additionalFormDetail.value.email,
        mobile_number:this.additionalFormDetail.value.mobile_num,
        home_office_number:this.additionalFormDetail.value.home_num,
        PO_box:this.additionalFormDetail.value.po_box_num,
        street_name:this.additionalFormDetail.value.street_name,
        address:this.additionalFormDetail.value.address,
        land_mark:this.additionalFormDetail.value.landmark,
        user_id:this.user_id,
        quotation_number:this.quoteNumber,
        cedant_id:this.cedant_id,
        partner_id:this.policyHolderForm.value.partner,
        base_contribution :this.baseContribution,
        service_fees : this.serviceFee,
        admin_fees:this.adminFee,
        total_contribution: this.toalContri,
        payment_type:this.PaymentType,
        VAT_percent :this.VATPercent,
        VAT_amount: this.VATAmount,
        location: this.additionalFormDetail.value.location,
        payment_process : 'CREDITPOLICY'
        
      }

      this.homeQuoteService.insertPolicy(this.policyValueData,'',this.sourceType).subscribe(res =>{
        let res1 = JSON.parse(res);
// console.log(res1)
// console.log(res1.response_code)
        this.policyNumber = res1.policy_number;
        if(res1.response_code == 1 ){

          localStorage.setItem('flexi_home_debit_note',res1.debit_note);
          localStorage.setItem('flexi_home_credit_note',res1.credit_note);
          localStorage.setItem('flexi_home_corePolicyNumber',res1.CorePolicyNumber);
         
          setTimeout(() => {
            this.issuePolLoader = this.globalService.spinnerHide();
            localStorage.setItem('home_core_policy_number',res1.CorePolicyNumber)
            this._route.navigateByUrl('home/holidayquote/thankyou/' + this.policyNumber +"/"+ this.quoteNumber);
         });  
          
        }else if(res1.response_code == 4){
          this.issuePolLoader = this.globalService.spinnerHide();
            this._route.navigateByUrl('home/holidayquote/thankyou/' + this.policyNumber );
        }
       
      });
    
  }

  paymentGateway(){

    if(this.additionalFormDetail.invalid){
      return false;
    } 
    if(this.invalidEID == true){

      return;
   }
   if(this.accept_terms == false){
      return;
  }
  if(this.accept_disclaimer == false){
    return;
}
  this.issuePolLoader = this.globalService.spinnerShow();

   this.isValidFormSubmitted = false;
    this.isEditable = true;
    this.prevButton = false;
    this.issuePolButton = false;
    this.policyValueData = {
      commencement_date:this.additionalFormDetail.value.policy_comm_date,
      emiratesID:this.additionalFormDetail.value.emiratesID,
      insured_name:this.additionalFormDetail.value.ph_name,
      email :this.additionalFormDetail.value.email,
      mobile_number:this.additionalFormDetail.value.mobile_num,
      home_office_number:this.additionalFormDetail.value.home_num,
      PO_box:this.additionalFormDetail.value.po_box_num,
      street_name:this.additionalFormDetail.value.street_name,
      address:this.additionalFormDetail.value.address,
      land_mark:this.additionalFormDetail.value.landmark,
      user_id:this.user_id,
      quotation_number:this.quoteNumber,
      cedant_id:this.cedant_id,
      partner_id:this.partnerId,
      // base_contribution :this.baseContribution,
      // service_fees : this.serviceFee,
      // admin_fees:this.adminFee,
      // total_contribution: this.toalContri,
      // payment_type:this.PaymentType,
      // VAT_percent :this.VATPercent,
      // VAT_amount: this.VATAmount,
      payment_process : 'Online',
      productType :'FIXED',
      
    }

    this.homeQuoteService.savePolicyDetails(this.policyValueData,'').subscribe(res =>{

      // this.policyNumber = res.policy_number;

      let server_name = config.server_name;
      let return_url = "http://localhost:4200/dashboard";
      let site_url = "http://localhost:4200/" + this._route.url;
  
    //  this.paymentLoader = this.globalService.spinnerShow();
    this.homeQuoteService.checkPartner_CreditLimit("HC",0, this.selectedPartnerId,this.selectedBranch).subscribe(response=>
      {
      if(response.cl_status!='200012'){ //Added By Anju for Party ACtive status check before Payment
     //Modified by Anju for Home Payment gateway revamp
     this.travelInsuranceService.paymentAuth(this.quoteNumber ,site_url,return_url,'HC',this.partnerId,this.policyNumber,'FIXED',this.sourceType).subscribe(res => {
         let payRes = res;
  
      if (payRes.status == 'Failed') {
           Swal.fire(payRes.res_msg);
           this.issuePolLoader = this.globalService.spinnerShow();
           return false;
     }
   
     else if (payRes.status == 'Success') {
  
          //  this.paymentLoader = this.globalService.spinnerHide();
          //  this.issuePolLoader = this.globalService.spinnerHide();
           localStorage.setItem('Payment_Order_ID',payRes.data.tran_ref);
           localStorage.setItem('Payment_Order_Tokan',payRes.data.token);
           localStorage.setItem('Payment_Quotation_Number',this.quoteNumber);
           localStorage.setItem('Payment_Quotation_ID',this.quoitationID);
  
          window.location.href = res.data.redirect_url;
            //  window.open(res.result.redirect_url, '_blank');
  
  
         }
          
        
     });
    }
    else{
      if(response.cl_status=='200012'){  //Added By Anju for Party ACtive status check before Payment
      Swal.fire("Party not active.");
      this.issuePolLoader = this.globalService.spinnerHide();
      return false;
      }
    }
  });
      // this._route.navigateByUrl('/homequote/thankyou/' + this.policyNumber +"/"+ this.quoteNumber);
    });

  }

  // ------------------------------------send pay link----------------------------------------
  sendPayLink(){

    if(this.additionalFormDetail.invalid){
      return false;
    } 
    if(this.invalidEID == true){

      return;
   }
   if(this.accept_terms == false){
        return;
   }
   this.issuePolLoader = this.globalService.spinnerShow();

   this.isValidFormSubmitted = false;
    this.isEditable = true;
    this.prevButton = false;
    this.paymentButton = false;

    this.policyValueData = {
      commencement_date:this.additionalFormDetail.value.policy_comm_date,
      emiratesID:this.additionalFormDetail.value.emiratesID,
      insured_name:this.additionalFormDetail.value.ph_name,
      email :this.additionalFormDetail.value.email,
      mobile_number:this.additionalFormDetail.value.mobile_num,
      home_office_number:this.additionalFormDetail.value.home_num,
      PO_box:this.additionalFormDetail.value.po_box_num,
      street_name:this.additionalFormDetail.value.street_name,
      address:this.additionalFormDetail.value.address,
      land_mark:this.additionalFormDetail.value.landmark,
      user_id:this.user_id,
      quotation_number:this.quoteNumber,
      cedant_id:this.cedant_id,
      partner_id:this.partnerId,
      // base_contribution :this.baseContribution,
      // service_fees : this.serviceFee,
      // admin_fees:this.adminFee,
      // total_contribution: this.toalContri,
      // payment_type:this.PaymentType,
      // VAT_percent :this.VATPercent,
      // VAT_amount: this.VATAmount,
      payment_process : 'Online',
      productType :'FIXED',
      
    }
    
   //DIGI Status check starts Feb2024
   this.digi_status$.subscribe(trackText => {
    this.digiSwalInstance= Swal.fire({
      title: '',
      html: `<div style="display: inline-block;margin-top:45px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,    
      showConfirmButton: false
    });
      });
  debugger
   var digiRequest={
    "QuotationNumber":this.quoteNumber,
    "FirstName": this.policyHolderForm.value.name,
    "MiddleName": "",
    "LastName": "",
    "MatchCategory": "I",
    "Nationality": "",
    "DOB": "",
    "CustomerType": "I",
    "CompanyName":"",
    "UserEmail":this.user_email
  };
  console.log(digiRequest);
  this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{ 
    console.log(JSON.stringify(res));
    if(res==null ||res.data==null ){
      Swal.fire("", "Failed to process the request. Please try again", 'error'); 
  this.issuePolLoader = this.globalService.spinnerHide();
    return;
    }
  
    
    if(res.data.digiStatus=='Approved' ) {
    this.homeQuoteService.savePolicyDetails(this.policyValueData,'').subscribe(res =>{
    if (this.digiSwalInstance) {
      this.digiSwalInstance.close();
     }
      this.policyNumber = res.policy_number;
      if(res.response_code == 1 ){
        this.homeQuoteService.checkPartner_CreditLimit("HC",0, this.selectedPartnerId,this.selectedBranch).subscribe(response=>
          {
          if(response.cl_status!='200012'){ //Added By Anju for Party Active status check before Payment
        this.motorQuoteService.sendPaymentLink(this.quoteNumber, 'HC', this.additionalFormDetail.value.email,'B2C','','').subscribe(res => {

          let payRes = res;
      
          if (payRes.res_code == 1) {
      
            Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.quoteNumber, 'success');
      
            this._route.navigate(['home/getquote']);
      
          }
          // this.showLoader.Quotation = false;
        });
      }
      else{
        if(response.cl_status =='200012'){  //Added By Anju for Party ACtive status check before Payment
        Swal.fire("Party not active.");
        this.issuePolLoader = this.globalService.spinnerHide();
        return false;
        }
      }
    });
      }
     
    });
    }
    else{
      if(config.enableDIGIApproval=="N"){
        this.digi_status.next("Almost there...");
        setTimeout(() => {
          this.digi_status.next("Please do not close the browser or refresh...");
      }, 2000);
        setTimeout(() => {
          if (this.digiSwalInstance) {
            this.digiSwalInstance.close();
           }
           this.homeQuoteService.savePolicyDetails(this.policyValueData,'').subscribe(res =>{
            if (this.digiSwalInstance) {
              this.digiSwalInstance.close();
             }
              this.policyNumber = res.policy_number;
              if(res.response_code == 1 ){
                this.homeQuoteService.checkPartner_CreditLimit("HC",0, this.selectedPartnerId,this.selectedBranch).subscribe(response=>
                  {
                  if(response.cl_status!='200012'){ //Added By Anju for Party Active status check before Payment
                this.motorQuoteService.sendPaymentLink(this.quoteNumber, 'HC', this.additionalFormDetail.value.email,'B2C','','').subscribe(res => {
        
                  let payRes = res;
              
                  if (payRes.res_code == 1) {
              
                    Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.quoteNumber, 'success');
              
                    this._route.navigate(['home/getquote']);
              
                  }
                  // this.showLoader.Quotation = false;
                });
              }
              else{
                if(response.cl_status =='200012'){  //Added By Anju for Party ACtive status check before Payment
                Swal.fire("Party not active.");
                this.issuePolLoader = this.globalService.spinnerHide();
                return false;
                }
              }
            });
              }
             
            });
      }, 60000); 
     }  
     else {
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
       this.issuePolLoader = this.globalService.spinnerHide();
      Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
      return;  
   }

    }

    });
    //DIGI Status check End Feb2024
  
}

//---------VALIDATION FOR NUMBERS & DECIMAL NUM------------//
   checkValidInputData(event: any,type)
   {
       this.globalService.checkValidInputData(event,type);
   }
   preventAllEvents(event: any)
   {
       this.globalService.preventAllEvents(event);
   }

   uppercaseinput(){
     alert(1)
   }

//--------------------------------- UPDATE QUOTATION ------------------------------
   updateQuotation(stepper){
// console.log((this.policyHolderForm.value.name).toUpperCase())
   
    this.checked = [];
    
    this.updateQuoteDataArr = 
    {
      quotes_ref_number:this.quoteNumber,
      insured_name: this.policyHolderForm.value.name.toUpperCase(),
      insured_gender: this.policyHolderForm.value.gender,
      insured_email: this.policyHolderForm.value.email, 
      MobileCode: this.policyHolderForm.value.code,
      insured_mobile: this.policyHolderForm.value.mobileNum,
      residenceContry: this.policyHolderForm.value.residenceContry,
      city: this.policyHolderForm.value.city,
      //location: this.policyHolderForm.value.location,
      propertyType: this.policyHolderForm.value.propertyType,
      ownershipStatus: this.policyHolderForm.value.ownershipStatus,
      coverType: this.policyHolderForm.value.coverType,
      noDaysPropUnOccupied: this.policyHolderForm.value.noDaysPropUnOccupied,
      suffered_losses:this.policyHolderForm.value.suffered_losses,
      value_of_content:'0',
      partner_id:this.policyHolderForm.value.partner,	
      branch_id:this.policyHolderForm.value.branch.Id,
      product_id:'1080',
      product_code:this.prodCode,
      cedant_id:this.cedant_id,
      user_email:this.user_email,
      user_id:this.user_id,
      productType:'FIXED',
      //Added by Anju for document insert
      finalFileWithURL: this.document_data,
      finalFileWithURL1: this.document_data1,
      finalFileWithURL2: this.document_data2,
      fileName: this.filename,
      fileName1: this.filename1,
      fileName2: this.filename2,
      fileType: this.fileType,
      fileType1: this.fileType1,
      fileType2: this.fileType2,
    }
        if(this.updateQuoteDataArr.noDaysPropUnOccupied == 'More than 60 days' || this.updateQuoteDataArr.suffered_losses == "Yes"){
            
              Swal.fire("Sorry! we could not find any plan based on your quotation criteria. Please try again or contact your relationship manager.", "Sorry for the inconvenience caused", "error");
              this.policyHolderForm.get('suffered_losses').setValue('');
              return false;
        }
        else{
          this.policyHolderForm.get('otherForm').setValue('1');
      
                  this.homeQuoteService.updateQuotation(this.updateQuoteDataArr,'',this.sourceType).subscribe(res =>{  
                             this.calculateContribution();
                });
                this.planDetailForm.value.promo_code = 'SPLD-10';
                stepper.next();
        }

      if(this.policyHolderForm.invalid){
            return false;
      }
          
   }
//------------------------------------------- CALCULATE LOADING AND DISCOUNT VALUE -----------------------------
loadingVal = 0 ;
discountVal = 0 ;
loadingBy(){
  this.contributionData.forEach((item,index) => {
    let totalLoading:number = 0 ;
    let totalDiscount:number = 0 ;

    if(this.planDetailForm.value.loading_per!=0 && this.planDetailForm.value.discountVal!=0){
      
      totalLoading = this.planDetailForm.value.loading_per*item.FixedContribution/100;
      totalDiscount=this.planDetailForm.value.discount_per*item.FixedContribution/100;


    }
    else if(this.planDetailForm.value.loading_per!=0 && this.planDetailForm.value.discountVal==0){

      totalLoading = this.planDetailForm.value.loading_per*item.FixedContribution/100;
    }else if(this.planDetailForm.value.loading_per==0 && this.planDetailForm.value.discountVal!=0){

      totalDiscount=this.planDetailForm.value.discount_per*item.FixedContribution/100;

    }

    this.contributionData[index].FixedContributionTemp =item.FixedContribution+totalLoading-totalDiscount;
    this.contributionData[index].VATAmount=(this.contributionData[index].FixedContributionTemp+item.PolicyFees)*item.VATPercent/100;
  //  this.contributionData[index].VATAmount=this.contributionData[index].FixedContributionTemp*item.VATPercent/100;

            
     this.contributionData[index].TotalContribution = item.FixedContributionTemp + item.PolicyFees + item.VATAmount

    // this.contributionData[index].TotalContribution = this.totalContribution;
    // this.contributionData[index].FixedContributionTemp = item.FixedContribution;
    
    });
    console.log( this.contributionData)
//   this.loadingVal =  this.planDetailForm.value.loading_per ;

 }


 getDiscOnPromoCode(){

  this.homeQuoteService.getPromoCodeDiscountValue('B2B','HC',this.planDetailForm.value.promo_code).subscribe(result=>{
    // console.log(result)
        if(result.response_code == 1){
          this.invalid_promoCode = 2;
          this.planDetailForm.value.discount_per = result.discount_value;
          this.discountPromoCodeValue=result.discount_value;
          this.loadingBy();
          // this.totalPremiumData = this.PolicyFees + this.VATAmount + this.basicPremium + this.loadingAmount-this.DiscountAmount;

    }else{
      this.invalid_promoCode = 1;
      this.planDetailForm.value.discount_per =0;
          this.loadingBy();
          // this.totalPremiumData = this.PolicyFees + this.VATAmount + this.basicPremium + this.loadingAmount-this.DiscountAmount;
    }
  })
      // if(this.planDetailForm.value.promo_code == 'SPLD-10'){
      //   this.invalid_promoCode = 2;
      //   this.planDetailForm.value.discount_per = 10;

      //     this.loadingBy();
      // }
      // else{
      //   this.invalid_promoCode = 1;
      // }
 }
 //----------------------------------- VIEW QUOTE PDF -------------------------------------------
 viewQuotePDF(){

  // this.saveQuotePlanDetail('','');

          this.homeQuoteService.viewQuotePDF(this.partnerId,this.quoteNumber,'FIXED',this.sourceType).subscribe(res =>{
              setTimeout(
                function() {
        
                }.bind(this),
                200
              );
                  let viewPDF = res;
                if(viewPDF.response_code == 1){
                  window.open(viewPDF.pdfPath, "_blank");
                }
          });
 }
 //----------------------------------- VIEW POLICY PDF ------------------------------------------------
 viewPolicyPDF(){

          this.homeQuoteService.viewPolicyPDF(this.partnerId,this.policyNumber).subscribe(res =>{
              setTimeout(
                function() {
        
                }.bind(this),
                200
              );
                  let viewPolicy = res;
                  if(viewPolicy.response_code == 1){
                    window.open(viewPolicy.pdfPath, "_blank");
                  }

          });
 }
 
//------------------------------- VALIDATION FOR EMIRATES ID ----------------------------------------------//
checkEID(i) {
  this.value = i;
 
this.value = this.value.replace(/-/g, "");
       if (/[^0-9-]+/.test(this.value))
         this.invalidEID = true;  
        
         if (this.value.length != 15 )
         {
           this.invalidEID = true; 
           return  this.invalidEID ;
         }
       if (this.value == "111111111111111" || this.value == "000000000000000" || this.value == "222222222222222" || this.value == "333333333333333") {
         this.invalidEID = false;
          
           return  this.invalidEID ;
       }
       
       else {
           //The Luhn Algorithm.
           var nCheck = 0, nDigit = 0, bEven = false;
           this.value = this.value.replace(/\D/g,"");

         
           //784-1982-6961498-2
         
               for (let n = this.value.length - 1; n >= 0; n--) {

                   var cDigit = this.value.charAt; nDigit = parseInt(this.value[n]);
                
                   if (bEven) {
                       if ((nDigit *= 2) > 9) 
                       nDigit -= 9;
                   }

                   nCheck += nDigit;
                   bEven = !bEven;
                  

                   
               }

               if ((nCheck % 10) == 0) {
                   //if valid, ok, check next
                   this.invalidEID  = false;
                  
                   return this.invalidEID;
                  
               }
             else {
               this.invalidEID  = true;
              
                 //alert('Invalid Emirates ID. Please enter valid emirates Id including (-) dash/hypen.');
             }


             
       }
      


      
}
//----------------------------- CHECK  USER ACCESS FOR POLICY ISSUE BUTTONS ------------------------------//
checkAccessForPolicyIssueButtons(){
  this.motorQuoteService.checkAccessToPolicyButtons('HC').subscribe(res =>{
        if(res.IsNonPayIssueAllowed == 0){
             this.issuePolicyBtnAccess = false;
        }

        if( res.IsOnlinePaymentAllowed == 0){
             this.onlinePayBtnAccess = false;
        }
  });
  console.log(this.issuePolicyBtnAccess)
  console.log(this.onlinePayBtnAccess)
  this.checkPartnerCreditLimit();
}
clickChkBox(){

  if(this.updateQuoteDataArr.noDaysPropUnOccupied == 'More than 60 days' || this.updateQuoteDataArr.suffered_losses == "Yes"){
            
  //  Swal.fire("Sorry! we could not find any plan based on your quotation criteria. Please try again or contact your relationship manager.", "Sorry for the inconvenience caused", "error");
    this.policyHolderForm.get('suffered_losses').setValue('');
  //  return false;
}
}

 validateEmail(c: FormControl) {
  
  if(this.updateQuoteDataArr.noDaysPropUnOccupied == 'More than 60 days' || this.updateQuoteDataArr.suffered_losses == "Yes"){
         return false ;
  }else
  return true ;
 
}
selectionChange(event){

  if(event.selectedIndex==0){

    this.policyHolderForm.get('otherForm').setValue('');
  }

  
}

termsAndConditions(frame){

  frame.show();

}

// ------------------------------------------Check Partner Credit Limit--------------------------------------------
async checkPartnerCreditLimit(){
 //Added by Anju for Partner Status Check	
 this.selectedPartnerId=this.policyHolderForm.value.partner;	
 this.selectedBranch=this.policyHolderForm.value.branch.Id;
 
  
  //Added paramters as blank for PartnerCreditlimit --Anju 	
  let result=await this.smequoteService.checkPartnerCreditLimit("HC",this.toalContri,this.selectedPartnerId, this.selectedBranch);
  let data = JSON.stringify(result)
  console.log(result);
  this.buttonAcc =result['cl_status'];
  console.log(this.buttonAcc)
  

  

} 

  /************** Country Filter */

  private filterCity() {
    if (!this.cityData) {
      return;
    }
    // get the search keyword
    let search = this.cityFilterCtrl.value;
    if (!search) {
      this.filteredCities.next(this.cityData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCities.next(
      this.cityData.filter(bank => bank.CityName.toLowerCase().indexOf(search) > -1)
    );
  }

  editQuotationFormData(){

        this.homeQuoteService.getQuotationDetail(this.view_type,this.quoteNumber,this.partnerId,this.sourceType).subscribe(res =>{

          

        });
  }

//   getPartnerBranchList(){
//     if(!this.policyHolderForm.value.partner){
//  return;
//     }
//     this.partnerBranchArr = [];
//     this.loader=this.globalService.spinnerShow();
//     this.issuepolicyService.getpartnerBranch(this.policyHolderForm.value.partner).subscribe(res =>{
//       let updateRes:any = res;
//       this.loader=this.globalService.spinnerHide();
//       this.partnerBranchArr = updateRes.branchList.filter(b=>b.BranchCode!='ONL')
//       this.branchId=this.partnerBranchArr[0];
//       //  branch
      
//       if(!this.savedBranchId){
//         this.partnerBranchArr.forEach((item,index)=>{
//           if(item.Id ==  this.branchId.Id){
//             this.branchVal  = item;
//           }
//       });
//        this.policyHolderForm.get('branch').setValue(this.branchVal);
//   }
//   else{
//     //console.log('savedBranchId not null')
//     this.partnerBranchArr.forEach((item,index)=>{
//       if(item.Id ==  this.savedBranchId){
//         this.branchVal  = item;
//       }
//   });
//    this.policyHolderForm.get('branch').setValue(this.branchVal);
//   }       
      
//     });
  
//   }

getPartnerBranchList(){
  if(!this.policyHolderForm.value.partner){
return;
  }
  this.partnerBranchArr = [];
  this.loader=this.globalService.spinnerShow();
  this.issuepolicyService.getpartnerBranch(this.policyHolderForm.value.partner).subscribe(res =>{
    let updateRes:any = res;
    this.loader=this.globalService.spinnerHide();
    this.partnerBranchArr = updateRes.branchList.filter(b=>b.BranchCode!='ONL')
    this.branchId=this.partnerBranchArr[0];
    //  branch
    
    if(!this.savedBranchId){
      this.partnerBranchArr.forEach((item,index)=>{
        if(item.Id ==  this.branchId.Id){
          this.branchVal  = item;
        }
    });
     this.policyHolderForm.get('branch').setValue(this.branchVal);
}
else{
  //console.log('savedBranchId not null')
  this.partnerBranchArr.forEach((item,index)=>{
    if(item.Id ==  this.savedBranchId){
      this.branchVal  = item;
    }
});
 this.policyHolderForm.get('branch').setValue(this.branchVal);
}       
    
  });

}

// -----------------------------File Upload --------------------------------------------------------

fileUpload(e) {
  this.multilpleFile = [];
  for (var i = 0; i < e.target.files.length; i++) {
    
    if ( e.target.files[i]) {
      // Get the file extension
      const fileExtension =  e.target.files[i].name.split('.').pop().toLowerCase();

      // Check if the file format is valid
      if (['pdf', 'jpg', 'jpeg', 'png'].includes(fileExtension)) {
        // File format is valid, do something with the file
        console.log('File format is valid');
      } else {
        // File format is not valid, show SweetAlert
        Swal.fire(
          "Please select valid file format.",
          "Only .pdf, .jpg, .jpeg, and .png file formats are allowed.",
          "error"
        );
          return false;
      }
    }

    // console.log( e.target.files[i])
    const formData = new FormData();
    formData.append("file", e.target.files[i]);

    this.homeQuoteService
      .UploadHCDocumentDetails(formData)
      .subscribe((result) => {
        this.docUploadFlag = true;
        let updateMemResponse = result;
        this.document_data = updateMemResponse.image_url;
        this.filename = updateMemResponse.file_name;
        let fileType = updateMemResponse.file_name.split(".");
        fileType = fileType[fileType.length - 1];
        this.fileType = fileType == "pdf" ? "PDF" : "IMG";
        this.multilpleFile.push({
          finalFileWithURL: updateMemResponse.image_url,
          finalFileName: "Other",
          docName: updateMemResponse.file_name,
          fileType: this.fileType,
        });
        // console.log(this.document_data)
      });
    // console.log(this.myFiles)
  }
  // console.log(this.traveldetails)
}
showMultipleDoc(fileName, documentType) {
  this.multipleImg = fileName;
  this.showAllDocImg = 1;
  this.ImagedocumentType = documentType;
}
modalboxclose() {
  this.showAllDocImg = 0;
}

fileUpload1(e) {
  this.multilpleFile1 = [];
  for (var i = 0; i < e.target.files.length; i++) {
    if ( e.target.files[i]) {
      // Get the file extension
      const fileExtension =  e.target.files[i].name.split('.').pop().toLowerCase();

      // Check if the file format is valid
      if (['pdf', 'jpg', 'jpeg', 'png'].includes(fileExtension)) {
        // File format is valid, do something with the file
        console.log('File format is valid');
      } else {
        // File format is not valid, show SweetAlert
        Swal.fire(
          "Please select valid file format.",
          "Only .pdf, .jpg, .jpeg, and .png file formats are allowed.",
          "error"
        );
          return false;
      }
    }
    // console.log( e.target.files[i])
    const formData = new FormData();
    formData.append("file", e.target.files[i]);
    this.homeQuoteService
      .UploadHCDocumentDetails(formData)
      .subscribe((result) => {
        this.docUploadFlag1 = true;
        let updateMemResponse1 = result;
        this.document_data1 = updateMemResponse1.image_url;
        this.filename1 = updateMemResponse1.file_name;
        let fileType1 = updateMemResponse1.file_name.split(".");
        fileType1 = fileType1[fileType1.length - 1];
        this.fileType1 = fileType1 == "pdf" ? "PDF" : "IMG";
        this.multilpleFile1.push({
          finalFileWithURL1: updateMemResponse1.image_url,
          finalFileName: "Other",
          docName: updateMemResponse1.file_name,
          fileType1: this.fileType1,
        });
        // console.log( this.fileType)
      });
    // console.log(this.myFiles)
  }
  // console.log(this.traveldetails)
}

fileUpload2(e) {
  this.multilpleFile2 = [];
  for (var i = 0; i < e.target.files.length; i++) {
    if ( e.target.files[i]) {
      // Get the file extension
      const fileExtension =  e.target.files[i].name.split('.').pop().toLowerCase();

      // Check if the file format is valid
      if (['pdf', 'jpg', 'jpeg', 'png'].includes(fileExtension)) {
        // File format is valid, do something with the file
        console.log('File format is valid');
      } else {
        // File format is not valid, show SweetAlert
        Swal.fire(
          "Please select valid file format.",
          "Only .pdf, .jpg, .jpeg, and .png file formats are allowed.",
          "error"
        );
          return false;
      }
    }
    // console.log( e.target.files[i])
    const formData = new FormData();
    formData.append("file", e.target.files[i]);
    this.homeQuoteService
      .UploadHCDocumentDetails(formData)
      .subscribe((result) => {
        let updateMemResponse2 = result;
        this.document_data2 = updateMemResponse2.image_url;
        this.filename2 = updateMemResponse2.file_name;
        let fileType2 = updateMemResponse2.file_name.split(".");
        fileType2 = fileType2[fileType2.length - 1];
        this.fileType2 = fileType2 == "pdf" ? "PDF" : "IMG";
        this.multilpleFile2.push({
          finalFileWithURL2: updateMemResponse2.image_url,
          finalFileName: "Other",
          docName: updateMemResponse2.file_name,
          fileType2: this.fileType2,
        });
        // console.log( this.fileType)
      });
    // console.log(this.myFiles)
  }
  // console.log(this.traveldetails)
}

}

