import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewContainerRef,ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl,RequiredValidator } from '@angular/forms';
import { GlobalService } from 'app/_service/global.service';
import { IssuepolicyService } from 'app/_service/issuepolicy.service';
import { SmequoteService } from 'app/_service/smequote.service';
import Swal from 'sweetalert2';
import { AppDateAdapter, APP_DATE_FORMATS} from '../../home/viewpolicy/date.adapter';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import {  DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MotorquoteService } from 'app/_service/motorquote.service';
import { HomequoteService } from 'app/_service/homequote.service';
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';
import { TreeRepository } from 'typeorm';
import { THIS_EXPR, ThrowStmt } from '@angular/compiler/src/output/output_ast';
import { GeoLocationComponent } from '../geo-location-modal/geo-location.component';
import { googleMapService } from 'app/_service/google-map-service';
import { config } from 'app/config';
import { Subject } from 'rxjs';
import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { ChangeDetectorRef } from '@angular/core';
import { element } from 'protractor';
import * as bootstrap from 'bootstrap';


// import { GoogleMapService } from 'app/_service/google-map-service';
@Component({
  selector: 'app-issupolicy',
  templateUrl: './issupolicy.component.html',
  styleUrls: ['./issupolicy.component.scss'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class IssupolicyComponent implements OnInit {
  characterToReplace = '&'; 
  replacementCharacter = '&#38;';
  optionyesNo=[{value:'Y',label:'Yes'},{value:'N',label:'No'},]
  uwNames =[{
    id:1,
    value:'Sanjay',
    email:'sanjay@fidelityunited.ae'
  },
  {
    id:2,
    value:'Rohit',
    email:'sanjay@fidelityunited.ae'
  },
  {
    id:3,
    value:'Melissa',
    email:'melissa.obmina@fidelityunited.ae'
  },
  {
    id:4,
    value:'Ruhina',
    email:'ruhina.aasifa@fidelityunited.ae'
  },
]
  smeQuoteDetails: FormGroup;
  localStorDta: any;
  partnerID: any;
  noofFloor: any;
  turnOver: any;
  bArea: any;
  claimHistory: any;
  emirates: any;
  emiratesArea: any;
  conclass: any;
  indSegment: any;
  busseg: any =[];
  emrmall: any = [];
  bussPremise: any;
  userId: any;
  validtnMsg: boolean;
  formDataRes: any;
  partnersArr: any;
  partnerId: any;
  partnerVal: number =0;
  partnerBranchArr: any[]; accept_terms :boolean = false;
  accept_disclaimer :boolean = false;
  branchId: any;
  branchVal: any;
  quoteNumber = '';
  benefitData: any[][] = [];
  WorkmensName="Workmens Compensation including Employers Liability "
  Property: any;
  rowAmount: number =0;
  sectionpremium_PAR : number[]= [];sectionpremium_Money: number[]= [];  sectionpremium_PL: number[]= [];totalsumCoveredAll: number = 0;
  quoteProceed: boolean = true;
  IntitatedPolicyResult: any[] = [];
  IsRenewal: number;
;  sectionpremium_WN: number[]= [];; sectionpremium_FG:number[]= [];; sectionpremium_BI: number[]= [];
  sectionpremium_MB: number[]= [];
  sectionpremium_EE: number[]= [];
  minsectionpremium_PAR : number[]= [];
  minsectionpremium_Money : number[] = [];
  minsectionpremium_PL: number[]= [];
  minsectionpremium_WN : number[]= [];
  minsectionpremium_FG: number[]= [];
  minsectionpremium_BI: number[]= [];
  minsectionpremium_MB: number[]= [];
  minsectionpremium_EE: number[]= [];
  // eLAddedTax : number[]= [];
  insuredAmount= [];
  totalPremium : number =0;
  policyFee = 50;
  basePremium: number[] = [];
  Vat_Amount: number = 0;
  totalbasepremium :number = 0
  totalsumCovered:number[] =[];
  loadingby: any;
  loadingAmount:number=0;
  planId: any;
  serviceTax: any;
  freezzone: any;
  ButtonName="Get Quote"
  policyType = "NEW";
  quoteId: any;
  businessSegmentLoader: boolean;
  emiratesmallLoader: boolean;
  quoteLoader: boolean;
  pageLoader: boolean;
  processType="'INSERT'"
  policyNumber: any;
  productCode: string;
  companydocument: any;
  documentCheckbox: boolean =false;
  docList: any[][]=[];
  docUploadFlag: boolean ;  minDate = new Date(Date.now());
  maxDate = new Date(new Date().setDate(new Date().getDate() + 30));
  renewalmaxdate = new Date(new Date().setDate(new Date().getDate() + 60 )); // we must not take todays date , it must be policy expiry date + 60 days ; - check with business
  madFileds: string;
  quotationloader: boolean;
  issuepolicyLoader: boolean;
  worDoc: any;
  userAccess: boolean =true;
  referalModel: boolean =false;
  businessPackageModel: boolean =false;
  refer_type: string;
  refer_condtion_type: number;
  referalDescription: string ='';
  referal: boolean;
  policyStatus: string;
  validtnMsgfordescription: boolean;
  additionalDoc: boolean;
  additionalDocFile: any[];
  document_data: any;
  hideImages: boolean;
  tempAdditionalDoc: any[];
  refferalButton: boolean =false;
  businessSource: any;
  accessGroup: any;
  userRole: any;
  userType: any;
  checkId: any;
  quoteStatus: any;
  quotationHistoryArr: any;
  emailAdd: any ='';
  minLoadingRange: any;
  maxLoadingRange: any;
  creditLimit: any;
  PTY_CREDIT_LMT_DAYS: any;
  lmt_days: boolean =false;
  source='B2B';
  emiId: any;
  partnerActive:boolean =false; //Added by Anju for partnercreditlimitcheck
  selectedPartnerId: any;//added by Anju for Partner Credit Limit check
  selectedBranchId: any;//added by Anju for Partner Credit Limit check

  section={PAR_hideshow :false,M_hideshow:false,PL_hideshow:false,WM_hideshow:false,FG_hideshow:false,BI_hideshow:false,MB_hideshow:false,EE_hideshow:false}
  checkcreditLimitArray:any=[];
  chechfor: boolean =true;
  emailaddValidation: boolean =false;
  access: number;
  hideshow={par:true,m:true,pl:true,w:true,fg:true,bi:true,mb:true,ee:true};
  hideshowpar: any =true;  hideshowm : any =true;  hideshowpl: any =true;  hideshoww: any =true;hideshowfg: any =true; hideshowbi: any =true; hideshowmb: any =true; hideshowee: any =true;
  disabled: boolean = false;
  disabledbr: boolean = false;
  reapprove: boolean = false;
  disableDate: boolean = false;
  planData: any;
  allowed: number = 1;
  public showLoader = {
    load_data : false, // added loader for initial page loading - ruhina
  };
  selectedYear: number;
  years: number[] = [];
  TotalCont: any;
  ValueAddedT: any;
  PremiumLoad: any;
  LoadingRate: any;
  onf: number[]=[];
  alor: number[]=[];
  obp: number[]=[];
  ome: number[]=[];
  osrm: number[]=[];
  totalpar: number[]=[];
  Icw: number[]=[];
  grssp: number[]=[];
  totalBI: number[]=[];
  totalparpr: number[]=[];
  expiryDate: any;
  dtminDate: Date;
  dtmaxDate: Date;
  PubLty: number[]=[];
  ratioallowed: number = 0;
  covermust: number = 0;
  userID: any;
  userBranchArr: any[];
  //totalparbi: number;
  Eml: number[]=[];
  Llaw: number[]=[];
  totalWcEl: number[]=[];
  coversumallowed: number;
  totalsalary: any;
  madFiledsBuilding: string="*";
  quotation_branchId:string="";
  is_buildingSelected:boolean[] = [];
  refReason: any[] =[];	
  referalRes: any;	
  isReferral: any;	
  file_name: any;	
  UploadedFileName: any;	
  response_code: any;	
  quoteDocuments: any;	
  showWC: boolean;	
  buttonds: boolean;
  ref_value: any;
  claimCount: any;
  claimAmount: any;
  claimRatio: number;
  expiringPremium: any;
  valueRatio: number;
  addressLocationCords:string="";
  geoLocationMandatory:boolean=true;
  riskInMall:boolean[]=[false];
  WCMandatory:any[]=[];
  InitiatedQuote:boolean=false;
  initiatedPolDocs:any=[];
  enableCoverStartDateModify:boolean=false;
  private digi_status = new Subject<string>();
  digi_status$ = this.digi_status.asObservable();
  digiSwalInstance:any;
  @ViewChild('modalContainer', { static: true, read: ViewContainerRef }) modalContainer: ViewContainerRef;
  showIcon: boolean;
  isDirectSalesAgent: boolean;
  otherFileName: any;
  claimFileName: any;
  claimResponseCode: string;
  uploadedClaimFileName: any;
  otherResponseCode: string;
  uploadedOtherFileName: any;
  additionalDocFiles: Array<{ fileName: string, fileType: string, fileURL: string }> = [];
  bankDetail: any;
  RefAssignedTo: string;
  totalSumInsured: any=[];
  bankDetailres: any;
  allowedLimit: number;
  isToBeReferred: boolean = false;
  filteredquotationHistoryArr: any;
  adminCount: number = 0; // Track number of Admin employees
  nonAdminCount: number = 0; // Track number of Non-admin employees
  adminSumInsured: number = 0; // Calculate the sum insured for Admin
  nonAdminSumInsured: number = 0; // Calculate the sum insured for Non-admin
  totalSumInsured1: number = 0; // Calculate the total sum insured
  EmployeeHeaderData: any;
  locationFormArray: any = [];
  itemDeclartion:any=[];
  locationForm: FormGroup;
  additionalDetailForm: FormGroup
  currentSubArray: any = [];
  currentIndex: number = 0;

 

  constructor(public _route: Router,public formBuilder: FormBuilder,public homeQuoteService:HomequoteService,public _activatedroute: ActivatedRoute,public smequoteService: SmequoteService, public issuepolicyService:IssuepolicyService,public motorQuoteService: MotorquoteService, public globalService: GlobalService,public travelInsuranceService:TraveInsuranceService,private resolver: ComponentFactoryResolver, private _googleMapService:googleMapService,private cdr: ChangeDetectorRef ) {
    
    this.smeQuoteDetails = this.formBuilder.group({
      partner:['',Validators.required],
      branch:['',Validators.required],
      businessName:['',Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9\s!@#$%^&.,-/()*]+$/)])],
      claimHistory: ['',Validators.required], //Pol
      startDate:  [this.minDate,Validators.required],
    });

    this.locationForm = this.formBuilder.group({
      locations: this.formBuilder.array([])
    })
    this.additionalDetailForm = this.formBuilder.group({
      BusinessEntityName : [this.smeQuoteDetails.value.businessName,Validators.required],
      CoverStartDate : [this.minDate,Validators.required],
      additionalDetails: this.formBuilder.array([])
    })
    this.addLocation();
   }

  ngOnInit() {

    console.log(this.additionalDetails.controls)
    console.log(this.locationForm)
    const routParam = this._activatedroute.snapshot.params;
    if(routParam.quoteNumber &&routParam.ID=="3"){
      this.InitiatedQuote=true;
      this.localStorDta = this.globalService.getLocalStorageData();
      this.userID =Number(this.localStorDta.User_ID);
      let privilagedUsers: number[] = config.sme_EnableCoverDateEdit.split(',').map(Number);
      if (privilagedUsers.includes(this.userID)){
        this.enableCoverStartDateModify=true;
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        this.minDate = thirtyDaysAgo;

      }
    }
    this._googleMapService.getLocation().subscribe(res=>{
    this.addressLocationCords=res;
    this.additionalDetailForm.get('companyAddressGeoLocation').setValue(res);
    })
    this.setupYears();
    this.showLoader.load_data = true;
    this.localStorDta = this.globalService.getLocalStorageData();
    this.partnerID = this.localStorDta.Partner_ID;
    this.userID = this.localStorDta.User_ID;
    this.businessSource = this.localStorDta.BusinessSource;
    this.claimCount = localStorage.getItem('ClaimCount');
    this.claimAmount = localStorage.getItem('ClaimAmount');
    this.expiringPremium = localStorage.getItem('ExpiringPremium');
    console.log(this.claimCount);
    console.log(this.claimAmount);
    this.accessGroup = this.localStorDta.AccessGroup;
    this.userRole = this.localStorDta.UserRole;
    this.userType = this.localStorDta.UserType;
    // this.productCode = localStorage.getItem('ProductCode');
    this.productCode = 'SMEBI';
    console.log(this.productCode);
    this.businessSegmentLoader=this.globalService.spinnerHide();
    this.emiratesmallLoader=this.globalService.spinnerHide();
    this.issuepolicyLoader=this.globalService.spinnerHide();
    this.pageLoader=this.globalService.spinnerHide();
    this.quotationloader=this.globalService.spinnerHide();
    this.quoteLoader=this.globalService.spinnerHide();
    this.userId = this.localStorDta.User_ID;
   

    this.checkUserAccess();

    this.checkDirectSalesAgent();
    console.log("PartnerId outside Quotation Form",this.partnerID);
    this.getQuotationFormData();
  

    //if(!this.InitiatedQuote){
      this.getfloorCount();
      this.getannualover();
      this.getBusinessArea();
      this.getConstClass();
      this.getIndSegment();
      this.getBusinessPremise();
         


    this.discountRange();
    this.getemirates();
    this.getcliamhistory();
    //}

    this.getZone();
    this.getMortgagelist();




      // this.getfloorCount();
      // this.getannualover();
      // this.getBusinessArea();
      // this.getcliamhistory();
      // this.getConstClass();
      // this.getIndSegment();
      // this.getZone();
      // this.getBusinessPremise();
      // this.discountRange();
      // this.getemirates();

 

    if(this.userRole == 'BROKER' && this.userType == 'POS'){
      if(this.partnerID == 1){
      this.disabled = false;
      }
      else{
        this.disabled = true;
      }
    }
    if(this.userRole == 'BROKER' || this.userType == 'POS'){
      if(this.partnerID == 1){
        this.disabled = false;
        }
        else{
          this.disabled = true;
        }
    }
    if(this.partnerID == 1){
      this.disabledbr = false;
      }
      else{
        this.disabledbr = true;
      }

    const routeParams = this._activatedroute.snapshot.params;
    console.log(routeParams.quoteNumber)
    if(routeParams.quoteNumber){
      // alert(1)
     this.pageLoader=this.globalService.spinnerShow();
     setTimeout(() => {
      // this.policyNumber = routeParams.policyNumber;
      this.checkId = routeParams.ID;
      // console.log(this.checkId);
      if(this.checkId != ''){
       this.quoteNumber = routeParams.quoteNumber;
       this.ButtonName ="Update Quote";
       this.policyType = "Update";
       this.getQuoteDetails();
      }
     }, 1000);
          
          
     }
     if(routeParams.policyNumber){
      this.pageLoader=this.globalService.spinnerShow();
      setTimeout(() => {
        this.policyNumber = routeParams.policyNumber;
      this.ButtonName ="Update Quote";
      this.policyType = "RENEWAL";
     this.getEditData(); 
    }, 1000);
     }
     if(routeParams.policyNumber && routeParams.ID){
      this.pageLoader=this.globalService.spinnerShow();
      setTimeout(() => {
        this.policyNumber = routeParams.policyNumber;
      this.ButtonName ="Update Quote";
      this.policyType = "RENEWAL";
     this.getEditData(); 
    

    }, 1000);
     }
  
    // this.smeQuoteDetails.controls['businessYear'].setValidators([Validators.required]);
//shuhaib initiated policy
    if(this.InitiatedQuote){
    this.GetInitiatedPolicyDetails(routeParams.quoteNumber);
    }
  }

   // Getter to easily access the form array
  get locations(): FormArray {
    return this.locationForm.get('locations') as FormArray;
  }
  get additionalDetails(): FormArray {
    return this.additionalDetailForm.get('additionalDetails') as FormArray;
  }

   // Function to create a new location FormGroup
   createLocation(): FormGroup {
    return this.formBuilder.group({

      businessArea:['',Validators.required],
      industryType : ['',Validators.required],
      businessSegment: ['',Validators.required],
      Address: ['',Validators.required],
      insuringProperty:  ['',Validators.required],
      businessYear: [''],
      construction: [''],
      AnnualBusiness: [''],
      buildingCladding: [''],
      NoOfFloors: [''],
      emirates: [''],
      emirateMall: [''],
      riskSelect: [''], 
    });
    
  }

     // Function to create a new location FormGroup
     createAdditionalDetail(): FormGroup {
      return this.formBuilder.group({
      
        contactperson:['',Validators.required],
        mortgageecheck:['',Validators.required],
        vatRegcheck:['',Validators.required],
        mortgagee:['',Validators.required],
        assignmentValue:['',Validators.required,Validators.pattern(/^[0-9]+$/)],
        tradeLicNumber:['',Validators.required],
        companyAddress : ['',Validators.required],
        // companyAddressGeoLocation : ['',Validators.compose([Validators.required,Validators.minLength(8)])],
        companyAddressGeoLocation : [''],
        poboxNo: ['',Validators.required],
        phoneNumber: ['',Validators.compose([Validators.required,Validators.minLength(9), Validators.maxLength(9), Validators.pattern(/^((02|03|04|06|07|08|09){1}([0-9]{7}))$/)])],
        startDate:  [this.minDate,Validators.required],
      
        email: ['',Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+)[.]{1}[a-zA-Z]{2,}$')])],
        trnNo: [''],
        mobNo: ['',Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/)])],
        freezzone: ['',Validators.required],
      });
  
      
    }

    // Function to add a new location to the form array
    addLocation() {
      if(this.locations.length>=9)
      {
        Swal.fire("Sorry,Maximum No of allowed Premises is 10", "error");
        return false;
      }
      this.locations.push(this.createLocation());
      this.additionalDetails.push(this.createAdditionalDetail());
    }

   

     // Function to remove a location from the form array
  removeLocation(index: number) {
    this.locations.removeAt(index);
    this.additionalDetails.removeAt(index);
  }

  get f() { return this.smeQuoteDetails.controls; }
  getfloorCount(){
    this.smequoteService.getfloorcount(this.partnerID).subscribe(res=>{

      this.noofFloor =res.Quotations;
      
    })
  }

  setupYears() {
    const currentYear = new Date().getFullYear();
    const startYear = 1951; 
    const endYear = currentYear;
    // const endYear = currentYear + 50; 
    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year);
    }
  }

  onYearSelected(year: number) {
    this.selectedYear = year;
    console.log('Selected Year:', this.selectedYear);
  }
  OnAssigntoUW(name:string)
  {
    console.log(name);
    this.RefAssignedTo = name['email'];
    console.log(this.RefAssignedTo);
  }

  getannualover(){
    this.smequoteService.getannualturnover(this.partnerID).subscribe(res=>{

      this.turnOver =res.Quotations;
      
    })
  }
  getBusinessArea(){
    this.smequoteService.getbusinessarea(this.partnerID).subscribe(res=>{

      this.bArea =res.Quotations;
      
    })
  }
  getcliamhistory(){
    this.smequoteService.getClaimHistory(this.partnerID).subscribe(res=>{

      this.claimHistory =res.Quotations;
      
    })
  }
  getemirates(){
    this.smequoteService.getEmirates(this.partnerID).subscribe(res=>{

      this.emirates =res.Quotations;
      
    })
  }
  getConstClass(){
    this.smequoteService.getConstructionClass(this.partnerID).subscribe(res=>{

      this.conclass =res.Quotations;
      
    })
  }
  getIndSegment(){
    this.smequoteService.getIndustrySegment(this.partnerID, this.productCode).subscribe(res=>{

      this.indSegment =res.Quotations;
      
    })
  }
  getBusinessPremise(){
    this.smequoteService.getbusinesspremise(this.partnerID).subscribe(res=>{

      this.bussPremise =res.Quotations;
      
    })
  }
  getBusinessSegment(data,i){
    console.log(data)
    this.businessSegmentLoader=this.globalService.spinnerShow();
    

    
    this.smequoteService.getBusinesSegment(this.partnerID,this.locations.at(i).get('industryType').value.IndustryId, this.productCode).subscribe(res=>{
      console.log("Business Segmetn Response",res);
      if(res.res_code == 1){
        this.busseg[i] =res.Quotations;
        this.businessSegmentLoader=this.globalService.spinnerHide();
            if(data != ''){
              var businesssegIndex =  this.busseg[i].findIndex((obj,key)=>{
                return obj.BusinessName == data
              })
              console.log("Index",this.busseg)
              console.log("Index",businesssegIndex)
              this.locations.at(i).get('businessSegment').setValue(this.busseg[i][businesssegIndex])
            }
      }
      
      console.log(this.busseg)
    })
   
    
  }

  getemiratesmall(data,i){
    // console.log(this.smeQuoteDetails.value.emirates.Id);
    // console.log(data);
    if(data == '0'){
       this.emiId = null;
    }else{
      this.emiId = this.locations.at(i).value.emirates.Id;
    }
    this.emiratesmallLoader=this.globalService.spinnerShow();
    this.smequoteService.getemiratemall(this.partnerID,this.emiId).subscribe(res=>{
      console.log(res);
      if(res.res_code == 1){
        this.emrmall[i] =res.Quotations;
        this.emiratesmallLoader=this.globalService.spinnerHide();
            if(data != ''){
              var emiratesmallIndex =  this.emrmall[i].findIndex((obj,key)=>{
                return obj.Id == data
              })
              console.log(emiratesmallIndex)
              this.locations.at(i).get('emirateMall').setValue(this.emrmall[i][emiratesmallIndex])
            }
      }
      
      console.log(this.emrmall)
    })
   
  }

  getQuotation(stepper){
    // console.log(this.smeQuoteDetails)
    // console.log(this.smeQuoteDetails.value.businessYear);
if(this.smeQuoteDetails.invalid || this.locationForm.invalid){
  this.validtnMsg =true;
  this.smeQuoteDetails.markAllAsTouched();
 return false;
}
if(this.quoteStatus != 'REFERRED' && this.quoteStatus != 'REFAPPROVED' && this.quoteStatus != 'ADDITIONALINFO') {
  this.quoteProceed = true;
  this.locations.controls.forEach((x,i)=>{
    if(x.value.NoOfFloors.Id == '3')
    {
      this.quoteLoader=this.globalService.spinnerHide();
      
      
      this.quoteProceed = false;
      
    }
      
  })
  }
  if(this.quoteProceed == false)
  {
    Swal.fire("Check the coverage, This policy will not cover you if you are looking for a cover for the entire building which is having more than 10 floors with aluminium cladding/ any other combustible cladding.","However if you are looking for covering only a few units within such building, then you can change no.of floors and proceed issuing policy from this portal", "error");
    return false;
  }
this.quoteLoader=this.globalService.spinnerShow();
///shuhaib IA License/ Trade license trigger getBrokerLicenseDetails starts
// this.getBrokerLicenseDetails().then(validLicense=>{
//  if(validLicense){
  console.log(this.quoteNumber)
  let processtype = (this.quoteNumber == '' ) ? 'Insert':'Update';
  let QuoteParamArray = []
  this.locationForm.value.locations.map((x,i)=>{
   
    QuoteParamArray.push({
      EntityName:this.smeQuoteDetails.value.businessName.replace(this.characterToReplace, this.replacementCharacter),
      industry:x.industryType,
      business:x.businessSegment,
      emiratesid:x.emirates,
      emiratemall:x.emirateMall,
      ConstructionClass:x.construction,
      Premises:this.locations.length > 1 ? 2 : 1,
      Turnover:x.AnnualBusiness,
      estYear:x.businessYear,
      claimHistory:this.smeQuoteDetails.value.claimHistory,
      cladding:x.buildingCladding,
      businessArea:x.businessArea,
      floors:x.NoOfFloors,
      riskLocation:x.Address,
      partnerId:this.smeQuoteDetails.value.partner,
      userId:this.userId,
      quotationNumber:this.quoteNumber,
      quoteId:this.quoteId,
      brokerId:this.smeQuoteDetails.value.partner,
      branchId:this.smeQuoteDetails.value.branch.Id,
      productCode: this.productCode,
      lob_code:'SM',
      processType : processtype ,
      policyType:this.policyType,
      source : 'B2B' ,
      isRiskInMall:x.riskSelect

    })
  });
  this.additionalDetailForm.get('BusinessEntityName').setValue(this.smeQuoteDetails.value.businessName)
  this.additionalDetailForm.get('BusinessEntityName').updateValueAndValidity();
  //  let QuoteParamArray=[{
  //    EntityName:this.smeQuoteDetails.value.businessName.replace(this.characterToReplace, this.replacementCharacter),
  //    industry:this.smeQuoteDetails.value.industryType,
  //    business:this.smeQuoteDetails.value.businessSegment,
  //    emiratesid:this.smeQuoteDetails.value.emirates,
  //    emiratemall:this.smeQuoteDetails.value.emirateMall,
  //    ConstructionClass:this.smeQuoteDetails.value.construction,
  //    Premises:this.smeQuoteDetails.value.businessPremises,
  //    Turnover:this.smeQuoteDetails.value.AnnualBusiness,
  //    estYear:this.smeQuoteDetails.value.businessYear,
  //    claimHistory:this.smeQuoteDetails.value.claimHistory,
  //    cladding:this.smeQuoteDetails.value.buildingCladding,
  //    businessArea:this.smeQuoteDetails.value.businessArea,
  //    floors:this.smeQuoteDetails.value.NoOfFloors,
  //    riskLocation:this.smeQuoteDetails.value.Address,
  //    partnerId:this.smeQuoteDetails.value.partner,
  //    userId:this.userId,
  //    quotationNumber:this.quoteNumber,
  //    quoteId:this.quoteId,
  //    brokerId:this.smeQuoteDetails.value.partner,
  //    branchId:this.smeQuoteDetails.value.branch.Id,
  //    productCode: this.productCode,
  //    lob_code:'SM',
  //    processType : processtype ,
  //    policyType:this.policyType,
  //    source : 'B2B' ,
  //    isRiskInMall:this.smeQuoteDetails.value.riskSelect
  //  }]
   console.log(QuoteParamArray)
     this.smequoteService.getQuote(QuoteParamArray).subscribe(res=>{
       this.ButtonName ='Update Quote';
       this.isReferral = res.response_code;	
       this.referalRes = res.referralReason;	
       if(res.response_code == 3 || res.response_code == 1 || this.quoteStatus == 'REFERRED' || this.quoteStatus == 'ADDITIONALINFO'){
         this.quoteLoader=this.globalService.spinnerHide();
         this.quoteNumber = res.quotation_number;
         this.benefitData =res.benefitData;
         this.IsRenewal = res.quotedetails[0].IsRenewal
         console.log(this.benefitData);
         this.planId =res.quotedetails[0].PlanId;
         this.serviceTax =res.quotedetails[0].ServiceTax;
         this.benefitData.forEach((locationElement,locindex) => {
          locationElement.forEach((element,index)=>{
            element['SumCover']=element.DefaultSumCovered;       
            element['defaultCover']=element.DefaultSumCovered;       
            element['checked']=true;
            if( element.ParentBenefitName == 'Property All Risks'){
              this.hideshowpar= false;
 
            }else if(element.ParentBenefitName =='Money'){
              this.hideshowm = false;
            } else if(element.ParentBenefitName == 'Public Liability'){
              this.hideshowpl = false;
            } else if(element.ParentBenefitName == this.WorkmensName){
              this.hideshoww= false;
            }  else if(element.ParentBenefitName == 'Fidelity Guarantee'){
              this.hideshowfg = false;
            }else if(element.ParentBenefitName == 'Business Interruption'){
              this.hideshowbi = false;
            }else if(element.ParentBenefitName == 'Machinery Breakdown'){
              this.hideshowmb = false;
            }else if(element.ParentBenefitName == 'Electronic Equipment'){
              this.hideshowee = false;
            }
          })
          
         });
   
         if(stepper !='')
         {
          stepper.next();
         }
         
         console.log(this.hideshowpar + ','+this.hideshowm +','+this.hideshowpl+','+this.hideshoww +','+ this.hideshowfg+','+ this.hideshowbi+','+ this.hideshowmb+','+this.hideshowee)
         if(processtype == 'Insert')
         {
          this.adminCount = 1
          this.adminSumInsured = 500000
         }
         this.premiumCalucation();
         this.getWordDoc();
         console.log("After Update Benefit data",this.benefitData)

       }
       // else if(this.smeQuoteDetails.value.NoOfFloors.Id == '3'){
       //   this.quoteLoader=this.globalService.spinnerHide();
       //   this.quoteNumber = res.quotation_number;
       //   Swal.fire("Check the coverage, This policy will not cover you if you are looking for a cover for the entire building which is having more than 10 floors with aluminium cladding/ any other combustible cladding. However if you are looking for covering only a few units within such building, then you can proceed issuing policy from this portal", "error");
       // }
       else {
         this.quoteLoader=this.globalService.spinnerHide();
         this.quoteNumber = res.quotation_number;
         this.refferalButton =true;
         //   Refferal notificattion email to be sent
         Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
       } 
       this.saveQuotePlanDetail('',3)
     }) 
//  }
//  else{
//   this.quoteLoader=this.globalService.spinnerHide();
//  }
// });  
   
  }

  refQuotation(){
    if(this.smeQuoteDetails.invalid){
  this.validtnMsg =true;
  this.smeQuoteDetails.markAllAsTouched();
  return false;
}
    let QuoteParamArray=[{
      EntityName:this.smeQuoteDetails.value.businessName.replace(this.characterToReplace, this.replacementCharacter),
      industry:this.smeQuoteDetails.value.industryType,
      business:this.smeQuoteDetails.value.businessSegment,
      emiratesid:this.smeQuoteDetails.value.emirates,
      emiratemall:this.smeQuoteDetails.value.emirateMall,
      ConstructionClass:this.smeQuoteDetails.value.construction,
      Premises:this.locations.length > 1 ? 2 : 1,
      Turnover:this.smeQuoteDetails.value.AnnualBusiness,
      estYear:this.smeQuoteDetails.value.businessYear,
      claimHistory:this.smeQuoteDetails.value.claimHistory,
      cladding:this.smeQuoteDetails.value.buildingCladding,
      businessArea:this.smeQuoteDetails.value.businessArea,
      floors:this.smeQuoteDetails.value.NoOfFloors,
      riskLocation:this.smeQuoteDetails.value.Address,
      partnerId:this.smeQuoteDetails.value.partner,
      userId:this.userId,
      quotationNumber:this.quoteNumber,
      quoteId:this.quoteId,
      brokerId:this.smeQuoteDetails.value.partner,
      branchId:this.smeQuoteDetails.value.branch.Id,
      productCode: this.productCode,
      lob_code:'SM',
      processType : 'Insert' ,
      policyType:this.policyType,
      source : 'B2B' 
    }]
      this.smequoteService.getQuote(QuoteParamArray).subscribe(res=>{
        // this.ButtonName ='Update Quote';
          // this.quoteLoader=this.globalService.spinnerHide();
          this.quoteNumber = res.quotation_number;             
      })
 
  }

  //---------VALIDATION FOR NUMBERS & DECIMAL NUM------------//
  // checkValidInputData(event: any,type)
  // {
  //     this.globalService.checkValidInputData(event,type);
  // }
  back(stepper){
    if(stepper !='')
    {
        stepper.previous();
    }
   
  }
   checkValidation(i){
   // debugger;
    // alert(1)
    if(this.locations.at(i).value.insuringProperty.label == 'No'){
      this.madFiledsBuilding = "";
      // this.smeQuoteDetails.controls['businessYear'].setValidators(null);
      // this.smeQuoteDetails.controls['businessYear'].updateValueAndValidity()
      this.locations.at(i).get('construction').setValidators(null);
      this.locations.at(i).get('construction').updateValueAndValidity()
      // this.locations.at(i).get('businessPremises').setValidators(null);
      // this.locations.at(i).get('businessPremises').updateValueAndValidity()
      this.locations.at(i).get('AnnualBusiness').setValidators(null);
      this.locations.at(i).get('AnnualBusiness').updateValueAndValidity()
      this.locations.at(i).get('buildingCladding').setValidators(null);
      this.locations.at(i).get('buildingCladding').updateValueAndValidity()
      this.locations.at(i).get('NoOfFloors').setValidators(null);
      this.locations.at(i).get('NoOfFloors').updateValueAndValidity()
      this.locations.at(i).get('emirates').setValidators(null);
      this.locations.at(i).get('emirates').updateValueAndValidity();
      if(!this.riskInMall[i]){
        this.locations.at(i).get('emirateMall').setValidators(null);
        this.locations.at(i).get('emirateMall').updateValueAndValidity();
      }

      this.locations.at(i).get('riskSelect').setValidators(null);
      this.locations.at(i).get('riskSelect').updateValueAndValidity();
      // this.smeQuoteDetails.get('NoOfFloors').setValidators(null);
      // this.smeQuoteDetails.get('claimHistory').setValidators(null);
    }else{

      this.madFiledsBuilding = "*";
      // alert('yes')
      // this.smeQuoteDetails.controls['businessYear'].setValidators([Validators.required]);
      // this.smeQuoteDetails.get('businessYear').setValidators([Validators.required]);
      // this.smeQuoteDetails.controls['businessYear'].setValidators([Validators.required]);
      // this.smeQuoteDetails.controls['businessYear'].updateValueAndValidity()
      this.locations.at(i).get('construction').setValidators([Validators.required]);
      this.locations.at(i).get('construction').updateValueAndValidity()
      // this.locations.at(i).get('businessPremises').setValidators([Validators.required]);
      // this.locations.at(i).get('businessPremises').updateValueAndValidity()
      this.locations.at(i).get('AnnualBusiness').setValidators([Validators.required]);
      this.locations.at(i).get('AnnualBusiness').updateValueAndValidity()
      this.locations.at(i).get('buildingCladding').setValidators([Validators.required]);
      this.locations.at(i).get('buildingCladding').updateValueAndValidity()
      this.locations.at(i).get('NoOfFloors').setValidators([Validators.required]);
      this.locations.at(i).get('NoOfFloors').updateValueAndValidity()
      this.locations.at(i).get('emirates').setValidators([Validators.required]);
      this.locations.at(i).get('emirates').updateValueAndValidity();
      if(this.riskInMall[i]){
      this.locations.at(i).get('emirateMall').setValidators([Validators.required]);
      this.locations.at(i).get('emirateMall').updateValueAndValidity();
      }
      this.locations.at(i).get('riskSelect').setValidators([Validators.required]);
      this.locations.at(i).get('riskSelect').updateValueAndValidity();
      console.log(this.smeQuoteDetails)
    }
   
    // this.smeQuoteDetails.get('businessYear').setValidators(null);
    // this.smeQuoteDetails.get('construction').setValidators(null);
    // this.smeQuoteDetails.get('businessPremises').setValidators(null);
    // this.smeQuoteDetails.get('AnnualBusiness').setValidators(null);
    // this.smeQuoteDetails.get('buildingCladding').setValidators(null);
    // this.smeQuoteDetails.get('NoOfFloors').setValidators(null);
    // this.smeQuoteDetails.get('claimHistory').setValidators(null);

    if(this.additionalDetails.at(i).value.mortgageecheck.label == 'No'){
      this.madFileds = "";
      this.additionalDetails.at(i).get('mortgagee').setValidators(null);
      this.additionalDetails.at(i).get('assignmentValue').setValidators(null);
      this.additionalDetails.at(i).get('mortgagee').setValue('');
      this.additionalDetails.at(i).get('mortgagee').updateValueAndValidity();
      if(!this.InitiatedQuote)
      {
          this.additionalDetails.at(i).get('assignmentValue').setValue(0); 
          this.additionalDetails.at(i).get('assignmentValue').updateValueAndValidity()
      }
    }else{
      this.madFileds = "*";
      this.additionalDetails.at(i).get('mortgagee').setValidators([Validators.required]);
      this.additionalDetails.at(i).get('assignmentValue').setValidators([Validators.required]);
      this.additionalDetails.at(i).get('mortgagee').updateValueAndValidity();
      this.additionalDetails.at(i).get('assignmentValue').updateValueAndValidity()
    }

  }

  GeoCheckValidation(){
            console.log(this.totalpar);
            this.additionalDetails.controls.forEach((locationEl,locationindex) => {
              if(config.enableGeoLocValid == 'Y'){
                this.additionalDetails.at(locationindex).get('companyAddressGeoLocation').setValidators([Validators.required]);
                this.additionalDetails.at(locationindex).get('companyAddressGeoLocation').updateValueAndValidity()
              }else{
                this.additionalDetails.at(locationindex).get('companyAddressGeoLocation').setValidators(null);
                this.additionalDetails.at(locationindex).get('companyAddressGeoLocation').updateValueAndValidity()
              }
            });
           

          if(this.totalpar[0] > 0){
            this.showIcon = true;
          }else{
            this.showIcon = false;
          }
  }

  vatCheckValidation(i){
    // this.docUploadFlag = true;
    if(this.additionalDetails.at(i).value.vatRegcheck.label == 'No'){
      this.additionalDetails.at(i).get('trnNo').setValidators(null); // Not Mandatory
      this.additionalDetails.at(i).get('trnNo').updateValueAndValidity();
      //console.log(this.additionalDetails[i].get('trnNo').validator)
   
      this.docList[i].forEach((element,index) => {
        // if(this.docUploadFlag == true){
                if(element.DocumentName == 'VAT Certificate'){
                  element.MandatorySymbol = '';
                }
                if(element.DocumentName == 'No VAT Declaration Letter'){
                  element.MandatorySymbol = '*';
                }
      // }
      });
    }else{
      this.additionalDetails.at(i).get('trnNo').setValidators(Validators.required); // Required Field validator 
      this.additionalDetails.at(i).get('trnNo').updateValueAndValidity();
     // console.log(this.additionalDetailForm.get('trnNo'))
       
        this.docList[i].forEach((element,index) => {
          // if(this.docUploadFlag == true){
                  if(element.DocumentName == 'VAT Certificate'){
                    element.MandatorySymbol = '*';
                  }
                  if(element.DocumentName == 'No VAT Declaration Letter'){
                    element.MandatorySymbol = '';
                  }
        // }
        });
      
      console.log("Document List", this.docList)
    }
  }
  // ---------------------------------------dropdown For Partner And Branch-------------------------------------------------
getQuotationFormData(){
  // this.quoteRes=this.globalService.spinnerShow();
console.log("PartnerId inside Quotation Form",this.partnerID);
  this.issuepolicyService.getQuotationFormData().subscribe(response =>{
    this.formDataRes = response;
    console.log("FormData",this.formDataRes)
    console.log("Partner Id form",this.partnerID)
    // this.quoteRes = this.globalService.spinnerHide();
    this.partnersArr = this.formDataRes.Partners;

    // if(this.quatationNo =='' ){
  //  console.log(this.partnerId)
      this.partnersArr.forEach((item,index)=>{
 
           if(item.PartnerId== this.partnerID){
            console.log(item.PartnerId)
             this.partnerVal  = item.PartnerId;
             
           }
          
      });
      console.log("Partner Id form",this.partnerVal)
    //  console.log(this.partnerVal);
      
      //  if(this.partnerID == 1){
      //   this.getPartnerBranchList();
      // }
      //   else{
      //     this.getUserBranchList();
      //   }
  //  }
  if(!this.InitiatedQuote)
  {
    this.smeQuoteDetails.get('partner').setValue(this.partnerVal);
    this.getPartnerBranchList();
  }
  
   

  });
  
}

getPartnerBranchList(){
  this.partnerBranchArr = [];
  // this.loader=this.globalService.spinnerShow();
  this.issuepolicyService.getpartnerBranch(this.smeQuoteDetails.value.partner).subscribe(res =>{
    let updateRes:any = res;
    // this.loader=this.globalService.spinnerHide();
    updateRes.branchList=updateRes.branchList.filter(a=>a.BranchCode!='ONL')
    this.partnerBranchArr = updateRes.branchList;
    if(this.disabledbr||this.quoteStatus == 'REFERRED'){
      this.userBranchArr = updateRes.branchList ;
    }
    //UW changes -shuhaib
    if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
      const routeParams = this._activatedroute.snapshot.params;
      if(routeParams.quoteNumber){
        this.branchId=updateRes.branchList.filter(b=>b.Id==this.quotation_branchId)[0];
      }
      else{
        this.branchId=updateRes.branchList[0];
      }
     
      console.log('branches list',JSON.stringify(this.branchId));
      this.smeQuoteDetails.get('branch').setValue(this.branchId);
    }
    else{
      this.branchId=updateRes.branchList[0];
      this.partnerBranchArr.forEach((item,index)=>{
        if(item.Id ==  this.branchId.Id){
          this.branchVal  = item;          
        }
    });
    this.smeQuoteDetails.get('branch').setValue(this.branchVal);
    }


    //  branch
    // if(this.quatationNo == ''){

 
    
    

  

// }
    
 
    
  });
  this.showLoader.load_data = false;
}

getUserBranchList(){
  this.userBranchArr = [];
  // this.loader=this.globalService.spinnerShow();
  this.issuepolicyService.getuserBranch(this.userID).subscribe(res =>{
    let updateRes:any = res;
    // this.loader=this.globalService.spinnerHide();
    this.userBranchArr = updateRes.branchList ;
    this.branchId=updateRes.branchList[0];

      this.userBranchArr.forEach((item,index)=>{
        if(item.Id ==  this.branchId.Id){
          this.branchVal  = item;
          
        }
    });
     this.smeQuoteDetails.get('branch').setValue(this.branchVal); 
  });
  this.showLoader.load_data = false;
}
preventAllEvents(event: any)
{
    this.globalService.preventAllEvents(event);
}
getZone(){
  this.smequoteService.getfreezone(this.smeQuoteDetails.value.partner).subscribe(res=>{
    this.freezzone=res.freezones;
    this.benefitData.map((x,i)=>{
      this.additionalDetails.at(i).get('freezzone').setValue( this.freezzone[0])
    })
    

  })
}
checksumCal(data,val,index){
  
  console.log(val)
  console.log(data.BenefitSI)
  this.benefitData[this.currentIndex][index].checked=true;
  val = val.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  this.benefitData[this.currentIndex][index].DefaultSumCovered = val;
  this.benefitData[this.currentIndex][index].defaultCover = val.replace(/,/gi, "");
   
  this.benefitData[this.currentIndex][index].BenefitSI=data.BenefitSI.replace(/,/gi, "");val =val.replace(/,/gi, "");
    console.log( data.DefaultSumCovered.replace(/,/gi, ""))
  if(parseInt( this.benefitData[this.currentIndex][index].BenefitSI) < parseInt(val)){
    
    this.benefitData[this.currentIndex][index].DefaultSumCovered= this.benefitData[this.currentIndex][index].SumCover.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // if(this.benefitData[index].BenefitName == "As per UAE Labour Law with estimated annual wages not exceeding")
    // {
    //   this.adminCount = 1;
    //   this.nonAdminCount = 0;
    //   this.adminSumInsured = data.SumCover.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   this.nonAdminSumInsured = 0;
    // }
    Swal.fire('Invalid Sum Insured',data.BenefitName+ ',' +'cannot be more than'+ ' ' +(data.BenefitSI).replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ","),'error');
    
  }

  // if(this.policyType == "RENEWAL" || this.quoteStatus == "RENQUOTE"){
  //   if(this.partnerID == 1){
  //     this.valueRatio = parseInt(this.benefitData[index].SumCover) + (parseInt(this.benefitData[index].SumCover) * 0.25);
  //     // console.log(this.valueRatio)
  //     // console.log(this.benefitData[index].SumCover)
  //     if(this.valueRatio < parseInt(val)){
  //       this.benefitData[index].DefaultSumCovered=data.SumCover.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //       Swal.fire('Invalid Sum Insured',data.BenefitName+ ',' +'cannot be increased more than 25% of already Sum Insured','error');
  //     }
  //   }else{
  //     this.valueRatio = parseInt(this.benefitData[index].SumCover) + (parseInt(this.benefitData[index].SumCover) * 0.15);
  //     if(this.valueRatio < parseInt(val)){
  //       this.benefitData[index].DefaultSumCovered=data.SumCover.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //       Swal.fire('Invalid Sum Insured',data.BenefitName+ ',' +'cannot be increased more than 15% of already Sum Insured','error');
  //     } 
  //   }
  // }

  this.premiumCalucation();
}
checkCheckboxSelection(value,index,item){

  if(!value.checked){
    // alert(1)
    this.benefitData[this.currentIndex][index].checked = false;
    this.benefitData[this.currentIndex][index].DefaultSumCovered=0;
    
    // this.currentSubArray[index].checked=false;
    // this.currentSubArray[index].DefaultSumCovered=0;
    this.premiumCalucation();

  }else{
    //this.currentSubArray[index].DefaultSumCovered=item.SumCover.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.benefitData[this.currentIndex][index].DefaultSumCovered=item.SumCover.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.premiumCalucation();
  }
}

premiumCalucation(){
  this.sectionpremium_PAR= [];
  this.sectionpremium_Money= [];
  this.sectionpremium_PL= [];
  this.sectionpremium_WN= [];
  this.sectionpremium_FG= [];
  this.sectionpremium_BI= [];
  this.sectionpremium_MB= [];
  this.sectionpremium_EE= [];
  this.totalsumCovered= [];
  // this.eLAddedTax= [];
  this.insuredAmount= [];

  this.benefitData.forEach((locationElement,locindex) => {
    locationElement.forEach((element,index)=>{
      if (this.totalsumCovered[locindex] === undefined) {
        this.totalsumCovered[locindex] = 0;
      }
      if( element.DefaultSumCovered == 0){
        this.rowAmount = element.DefaultSumCovered * element.Rate /100;
        this.totalsumCovered[locindex] += parseInt(element.DefaultSumCovered);
      }else{
        this.rowAmount = element.DefaultSumCovered.replace(/,/gi, "") * element.Rate /100;
        this.totalsumCovered[locindex] += parseInt(element.DefaultSumCovered.replace(/,/gi, ""));
      }
  // this.rowAmount = this.benefitData[index].DefaultSumCovered.replace(/,/gi, "") * this.benefitData[index].Rate /100;
  
  // this.totalsumCovered += parseInt(element.DefaultSumCovered.replace(/,/gi, ""));
   console.log(this.rowAmount)
      if(element.ParentBenefitName == 'Property All Risks'){
        if (this.sectionpremium_PAR[locindex] === undefined) {
          this.sectionpremium_PAR[locindex] = 0;
        }
        if (this.minsectionpremium_PAR[locindex] === undefined) {
          this.minsectionpremium_PAR[locindex] = 0;
        }
        this.sectionpremium_PAR[locindex] += Number(this.rowAmount);
        console.log( this.sectionpremium_PAR)
        this.minsectionpremium_PAR[locindex] = (this.minsectionpremium_PAR[locindex] >  element.MinimumContribution) ? this.minsectionpremium_PAR[locindex] : element.MinimumContribution ;
        //Added by Anju for setting minimum premium for Restaurant
       
       

        if(this.locations.at(locindex).value.businessSegment.BusinessName  == 'Restaurant, Cafe')
        {
          this.minsectionpremium_PAR[locindex] =1500;
        }
              if(element.DefaultSumCovered == 0){
                element.checked = false;
              }else{
                element.DefaultSumCovered= element.DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                
              }
           
  
      }else if(element.ParentBenefitName == 'Money'){
        if (this.sectionpremium_Money[locindex] === undefined) {
          this.sectionpremium_Money[locindex] = 0;
        }
        if (this.minsectionpremium_Money[locindex] === undefined) {
          this.minsectionpremium_Money[locindex] = 0;
        }
        this.sectionpremium_Money[locindex] += this.rowAmount;
        this.minsectionpremium_Money[locindex] = this.minsectionpremium_Money[locindex] > element.MinimumContribution ?  this.minsectionpremium_Money[locindex] : element.MinimumContribution;
                if(element.DefaultSumCovered == 0){
                  element.checked = false;
                }else{
                  element.DefaultSumCovered=element.DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                
                }
      }else if(element.ParentBenefitName == 'Public Liability'){
        if (this.sectionpremium_PL[locindex] === undefined) {
          this.sectionpremium_PL[locindex] = 0;
        }
        if (this.minsectionpremium_PL[locindex] === undefined) {
          this.minsectionpremium_PL[locindex] = 0;
        }
        this.sectionpremium_PL[locindex] += this.rowAmount;
        // console.log(this.sectionpremium_PL)
        this.minsectionpremium_PL[locindex] = this.minsectionpremium_PL[locindex] > element.MinimumContribution ? this.minsectionpremium_PL[locindex] : element.MinimumContribution  ;
                if(element.DefaultSumCovered == 0){
                  element.checked = false;
                }else{
                  element.DefaultSumCovered=element.DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  
                }
  
      }else if(element.ParentBenefitName == this.WorkmensName){
        if (this.sectionpremium_WN[locindex] === undefined) {
          this.sectionpremium_WN[locindex] = 0;
        }
        if (this.minsectionpremium_WN[locindex] === undefined) {
          this.minsectionpremium_WN[locindex] = 0;
        }
        // if (this.eLAddedTax[locindex] === undefined) {
        //   this.eLAddedTax[locindex] = 0;
        // }
        this.sectionpremium_WN[locindex] += this.rowAmount;
        this.minsectionpremium_WN[locindex] = this.minsectionpremium_WN[locindex] > element.MinimumContribution ?this.minsectionpremium_WN[locindex]: element.MinimumContribution;
                    if(element.DefaultSumCovered == 0){
                      element.checked = false;
                    }else{
                      
                      element.DefaultSumCovered=element.DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                     
                         //Added by Anju for ELAddedTax computation
                         this.insuredAmount[locindex] = element.DefaultSumCovered.replace(/,/g, '');
                      // if(element.BenefitName=='Employers Liability' && this.insuredAmount[locindex]>1000000)
                      //  {
                      //    this.eLAddedTax[locindex] = this.insuredAmount[locindex]* 0.05 / 100;
                      //  }
                    }
  
      }else if(element.ParentBenefitName == 'Fidelity Guarantee'){
        if (this.sectionpremium_FG[locindex] === undefined) {
          this.sectionpremium_FG[locindex] = 0;
        }
        if (this.minsectionpremium_FG[locindex] === undefined) {
          this.minsectionpremium_FG[locindex] = 0;
        }
        this.sectionpremium_FG[locindex] += this.rowAmount;
        this.minsectionpremium_FG[locindex] = this.minsectionpremium_FG[locindex] > element.MinimumContribution ? this.minsectionpremium_FG[locindex] : element.MinimumContribution;
                if(element.DefaultSumCovered == 0){
                  element.checked = false;
                }else{
                  element.DefaultSumCovered= element.DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
  
      }else if(element.ParentBenefitName == 'Business Interruption'){
        if (this.sectionpremium_BI[locindex] === undefined) {
          this.sectionpremium_BI[locindex] = 0;
        }
        if (this.minsectionpremium_BI[locindex] === undefined) {
          this.minsectionpremium_BI[locindex] = 0;
        }
        this.sectionpremium_BI[locindex] += this.rowAmount;
        this.minsectionpremium_BI[locindex] = this.minsectionpremium_BI[locindex] >  element.MinimumContribution ? this.minsectionpremium_BI[locindex]:  element.MinimumContribution ;
                if(element.DefaultSumCovered == 0){
                  element.checked = false;
                }else{
                  element.DefaultSumCovered=element.DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
      }else if(element.ParentBenefitName == 'Machinery Breakdown'){
        if (this.sectionpremium_MB[locindex] === undefined) {
          this.sectionpremium_MB[locindex] = 0;
        }
        if (this.minsectionpremium_MB[locindex] === undefined) {
          this.minsectionpremium_MB[locindex] = 0;
        }
        this.sectionpremium_MB[locindex] += this.rowAmount;
        this.minsectionpremium_MB[locindex] = this.minsectionpremium_MB[locindex] > element.MinimumContribution ? this.minsectionpremium_MB[locindex] : element.MinimumContribution;
              if(element.DefaultSumCovered == 0){
                element.checked = false;
              }else{
                element.DefaultSumCovered=element.DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
      }else if(element.ParentBenefitName == 'Electronic Equipment'){
        if (this.sectionpremium_EE[locindex] === undefined) {
          this.sectionpremium_EE[locindex] = 0;
        }
        if (this.minsectionpremium_EE[locindex] === undefined) {
          this.minsectionpremium_EE[locindex] = 0;
        }
        this.sectionpremium_EE[locindex] += this.rowAmount;
        this.minsectionpremium_EE[locindex] = this.minsectionpremium_EE[locindex] > element.MinimumContribution ? this.minsectionpremium_EE[locindex] : element.MinimumContribution;
              if(element.DefaultSumCovered == 0){
                element.checked = false;
              }else{
                element.DefaultSumCovered=element.DefaultSumCovered.replace(/\D/g, "") .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
      }
     
    })
  })

console.log(this.benefitData)
  this.Vat_Amount =0;
  this.totalPremium =0;
// console.log(this.sectionpremium_PAR)
this.benefitData.forEach((locationElement,locindex) => {
  if( this.sectionpremium_Money[locindex] <= 0 && this.sectionpremium_PL[locindex] > 0 && this.sectionpremium_PAR[locindex] <= 0  && this.sectionpremium_WN[locindex] <= 0 && this.sectionpremium_FG[locindex] <= 0 && this.sectionpremium_BI[locindex] <= 0 && this.sectionpremium_MB[locindex] <= 0 && this.sectionpremium_EE[locindex] <= 0){
    this.minsectionpremium_PL[locindex] =750;
    // alert('PL selected')
  }
  else if( this.sectionpremium_Money[locindex] <= 0 && this.sectionpremium_WN[locindex] > 0 && this.sectionpremium_PAR[locindex] <= 0 &&  this.sectionpremium_PL[locindex] <= 0  && this.sectionpremium_FG[locindex] <= 0 && this.sectionpremium_BI[locindex] <= 0 && this.sectionpremium_MB[locindex] <= 0 && this.sectionpremium_EE[locindex] <= 0){
    this.minsectionpremium_WN[locindex] =750;
    // alert('WM selected')
  }
  else if( this.sectionpremium_Money[locindex] <= 0 && this.sectionpremium_PAR[locindex] > 0 &&  this.sectionpremium_PL[locindex] <= 0 && this.sectionpremium_WN[locindex] > 0 && this.sectionpremium_FG[locindex] <= 0 && this.sectionpremium_BI[locindex] <= 0 && this.sectionpremium_MB[locindex] <= 0 && this.sectionpremium_EE[locindex] <= 0){
    this.minsectionpremium_PL[locindex] =1000;
    // alert('Both selected')
  }
console.log(this.sectionpremium_PAR)
console.log(this.sectionpremium_PL)
console.log(this.minsectionpremium_PL)
  if( this.sectionpremium_PAR[locindex] > 0 &&  this.sectionpremium_PAR[locindex] < this.minsectionpremium_PAR[locindex]){
    //  alert(1)
      this.sectionpremium_PAR[locindex] = this.minsectionpremium_PAR[locindex];
    }
     if(this.sectionpremium_Money[locindex] > 0 &&  this.sectionpremium_Money[locindex] < this.minsectionpremium_Money[locindex]){
      this.sectionpremium_Money[locindex] =this.minsectionpremium_Money[locindex];
  
    }
     if(this.sectionpremium_PL[locindex] > 0 &&  this.sectionpremium_PL[locindex] < this.minsectionpremium_PL[locindex]){
      this.sectionpremium_PL[locindex] =this.minsectionpremium_PL[locindex];
  
    }
    //  if(this.sectionpremium_WN[locindex] > 0 &&  this.sectionpremium_WN[locindex] < this.minsectionpremium_WN[locindex] && (this.eLAddedTax[locindex]<this.minsectionpremium_WN[locindex])){
    if(this.sectionpremium_WN[locindex] > 0 &&  this.sectionpremium_WN[locindex] < this.minsectionpremium_WN[locindex]){
      this.sectionpremium_WN[locindex] =this.minsectionpremium_WN[locindex];
  
    }
     if(this.sectionpremium_FG[locindex] > 0 &&  this.sectionpremium_FG[locindex] < this.minsectionpremium_FG[locindex]){
      this.sectionpremium_FG[locindex] =this.minsectionpremium_FG[locindex];
  
    }
     if(this.sectionpremium_BI[locindex] > 0 &&  this.sectionpremium_BI[locindex] < this.minsectionpremium_BI[locindex]){
      this.sectionpremium_BI[locindex] =this.minsectionpremium_BI[locindex];
    }
     if(this.sectionpremium_MB[locindex] > 0 &&  this.sectionpremium_MB[locindex] < this.minsectionpremium_MB[locindex]){
      this.sectionpremium_MB[locindex] =this.minsectionpremium_MB[locindex];
    }
     if(this.sectionpremium_EE[locindex] > 0 &&  this.sectionpremium_EE[locindex] < this.minsectionpremium_EE[locindex]){
      this.sectionpremium_EE[locindex] =this.minsectionpremium_EE[locindex];
    }

    if (this.basePremium[locindex] === undefined) {
      this.basePremium[locindex] = 0;
    }
    console.log(this.basePremium);
  // console.log( this.sectionpremium_PAR)
  console.log(this.sectionpremium_PAR, this.sectionpremium_Money , this.sectionpremium_PL,this.sectionpremium_WN,this.sectionpremium_FG,this.sectionpremium_BI,this.sectionpremium_MB,this.sectionpremium_EE)
  this.basePremium[locindex] = Number(this.sectionpremium_PAR[locindex]) + Number(this.sectionpremium_Money[locindex]) + Number(this.sectionpremium_PL[locindex]) + Number(this.sectionpremium_WN[locindex]) + Number(this.sectionpremium_FG[locindex]) +
                        Number(this.sectionpremium_BI[locindex]) + Number(this.sectionpremium_MB[locindex]) + Number(this.sectionpremium_EE[locindex])  //+this.eLAddedTax[locindex];
                        console.log( this.basePremium)
  // this.claimRatio = (this.claimAmount/this.expiringPremium) * 100;
  // console.log(this.claimRatio);
  // if(this.claimRatio > 40 && this.claimRatio < 60){
  //   this.basePremium[locindex] = this.basePremium[locindex] + (this.basePremium[locindex] * 0.1);
  // }  
  
  // if(this.claimRatio > 60 && this.claimRatio < 150){
  //   this.basePremium[locindex] = this.basePremium[locindex] + (this.basePremium[locindex] * 0.5);
  // }
                 
                   
    
})
this.totalPremiumCalculation();
if(this.LoadingRate > 0){
  this.getLoadingByEdit();
  }
console.log("Base Premium Array",this.basePremium);

}


totalPremiumCalculation(){
  this.Vat_Amount =0;
  this.totalPremium =0;
  this.totalbasepremium = 0;
  this.totalsumCoveredAll = 0;
  this.basePremium.forEach((x,i)=>
  {
    this.totalbasepremium += Number(x);
  })

  this.totalsumCovered.forEach((x,i)=>
  {
    this.totalsumCoveredAll += Number(x);
  })
  this.Vat_Amount += (this.totalbasepremium+ this.loadingAmount + this.policyFee )* 0.05;

  this.totalPremium += this.totalbasepremium + this.Vat_Amount + this.policyFee +this.loadingAmount ;

}

getLoadingBy(){
  this.loadingAmount =0;
  console.log(this.loadingby)
 

  this.loadingAmount =  this.totalbasepremium *(this.loadingby/100);
  this.totalPremiumCalculation();
}

getLoadingByEdit(){
  this.loadingAmount = 0;
  this.loadingby = this.LoadingRate;
  this.loadingAmount =  this.totalbasepremium *(this.loadingby/100);
this.totalPremiumCalculation();
}

getWordDoc(){
  this.smequoteService.getWordingDocuments('SM','B2B').subscribe(res=>{
    this.worDoc = res.wordingdocs;

  })
}
printDoc(value){
  window.open(value, "_blank");
}
getEditData(){
  this.smequoteService.getProcessSMERenewal('B2B', this.productCode,'eng',this.policyNumber,'RENQUOTE','RENEWAL',this.claimCount,this.claimAmount,this.expiringPremium).subscribe(res=>{
    let quotedetail = res.quotedetails;
    this.quoteId =quotedetail.QuotationId;
    this.quoteNumber =res.quotation_number;
    this.expiryDate = res.quotedetails[0].ExpiryDate;
    this.dtminDate = new Date(this.expiryDate);
    if(this.dtminDate > this.minDate){
      this.disableDate = true;
      this.dtminDate.setDate(this.dtminDate.getDate() + 1);
      this.dtmaxDate = new Date(this.dtminDate);
      this.dtmaxDate.setDate(this.dtmaxDate.getDate() + 45);
          }
          else{
            this.disableDate = false;
            this.dtminDate = new Date(this.minDate);
            this.dtmaxDate = new Date(this.dtminDate);
            this.dtmaxDate.setDate(this.dtmaxDate.getDate() + 45);
          }
          quotedetail.map((x,i)=>{
            this.smeQuoteDetails.get('startDate').setValue(this.dtminDate);
          })  
    // Partner Name
    
    //this.smeQuoteDetails.get('businessArea').setValue(quotedetail.PartnerId); -- commented now
    //underWriter changes for broker and branch- shuhaib
    console.log('partnerID',this.partnerID);
    console.log('BranchId',quotedetail.BranchId);
    if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
      this.smeQuoteDetails.get("partner").setValue(quotedetail.CedantBrokerId);
      this.quotation_branchId=quotedetail.BranchId;
    }

    // Branch 
    // if(this.partnerID == 1){
    //   this.getPartnerBranchList();
    // }
    //   else{
    //     this.getUserBranchList();
    //   }
      this.getPartnerBranchList();
      this.smeQuoteDetails.get('businessName').setValue(quotedetail[0].BusinessEntityName); 
      this.additionalDetailForm.get('BusinessEntityName').setValue(quotedetail[0].BusinessEntityName)
      this.additionalDetailForm.get('BusinessEntityName').updateValueAndValidity();
      // Business Area Name
      quotedetail.map((quoteData,index)=>{
        if(index>0)
        {
          this.addLocation();
        }
        this.locations.at(index).get('riskSelect').setValue(quoteData.IsRiskInMall.trim()); 
        console.log("quotedetails",index)
        if(!this.InitiatedQuote){
          let businessArea =quoteData.BusinessArea
          var businessIndex = this.bArea.findIndex((obj, k) =>{
      
            return obj.BusinessAreaName == businessArea;
          });
         this.locations.at(index).get('businessArea').setValue(this.bArea[businessIndex]); 
        }
            // Industry Name
            if(!this.InitiatedQuote){
          let industryName =quoteData.IndustryName
          var industryIndex = this.indSegment.findIndex((obj, k) =>{
      
            return obj.IndustryName == industryName;
          });
          this.locations.at(index).get('industryType').setValue(this.indSegment[industryIndex]); 
        }
          this.getBusinessSegment(quoteData.BusinessName,index);
            // emirate Name
            if(!this.InitiatedQuote){
          let regionName =quoteData.EmiratesName
          var emirateIndex = this.emirates.findIndex((obj, k) =>{
      
            return obj.RegionName == regionName;
          });
          this.locations.at(index).get('emirates').setValue(this.emirates[emirateIndex]); 
          // this.getemiratesmall(quotedetail.MallId);
          if(regionName == null){
            this.getemiratesmall('0',index);
         }else{
          this.getemiratesmall(quoteData.MallId,index);
         }
        }
        this.locations.at(index).get('Address').setValue(quoteData.RiskLocationAddress); 
      
          // Insuring Property
          if(quoteData.FloorsCount == '0'){
            // alert(1)
            this.locations.at(index).get('insuringProperty').setValue(this.optionyesNo[1]); 
            this.checkValidation(index);
          }else{
            this.locations.at(index).get('insuringProperty').setValue(this.optionyesNo[0]); 
            this.checkValidation(index);
      
            this.locations.at(index).get('businessYear').setValue(quoteData.BusinessEstYear); 
      
            let classconstruction =quoteData.ConstructionClassName
            var constIndex = this.conclass.findIndex((obj, k) =>{
        
              return obj.ConstructionClassName == classconstruction;
            });
            this.locations.at(index).get('construction').setValue(this.conclass[constIndex]); 
      
      
      
            // let bussinessPrem =quoteData.BusinessPremise
            // var premIndex = this.bussPremise.findIndex((obj, k) =>{
        
            //   return obj.BusinessPremise == bussinessPrem;
            // });
            // this.locations.at(index).get('businessPremises').setValue(this.bussPremise[premIndex]); 
            
        
      
      
          let annual =quoteData.AnnualTurnover
          var annualIndex = this.turnOver.findIndex((obj, k) =>{
      
            return obj.AnnualTurnover == annual;
          });
          console.log(annualIndex)
          this.locations.at(index).get('AnnualBusiness').setValue(this.turnOver[annualIndex]); 
      
      
                if(quoteData.PremisesWithCladding == 'N'){
                    // alert(1)
                    this.locations.at(index).get('buildingCladding').setValue(this.optionyesNo[1]); 
                  
                }else{
                    this.locations.at(index).get('buildingCladding').setValue(this.optionyesNo[0]); 
                }
      
                let nooffloor =quoteData.FloorsCount
          // var floorindex = this.noofFloor.findIndex((obj, k) =>{
      
          //   return obj.AnnualTurnover == annual;
          // });
          // console.log(annualIndex)
          // this.smeQuoteDetails.get('NoOfFloors').setValue(this.noofFloor[1]);
          if(quoteData.FloorsCount == '1'){
            // alert(1)
            this.locations.at(index).get('NoOfFloors').setValue(this.noofFloor[0]); 
            // this.checkValidation();
          }
          if(quoteData.FloorsCount == '2'){
            // alert(1)
            this.locations.at(index).get('NoOfFloors').setValue(this.noofFloor[1]); 
            // this.checkValidation();
          }
          if(quoteData.FloorsCount == '3'){
            // alert(1)
            this.locations.at(index).get('NoOfFloors').setValue(this.noofFloor[2]); 
            // this.checkValidation();
          }
      
      
              }
       // Claiming History
       if(!this.InitiatedQuote){
       let claimName =quoteData.ClaimHistory
       var claimIndex = this.claimHistory.findIndex((obj, k) =>{
      
         return obj.ClaimHistory == claimName;
       });
       this.smeQuoteDetails.get('claimHistory').setValue(this.claimHistory[claimIndex]); 
       this.getQuotationHistory(this.quoteNumber);
       this.pageLoader=this.globalService.spinnerHide();
       }
       else{
        this.pageLoader=this.globalService.spinnerHide();
       }
      })
  })

}

getQuoteDetails(){
  this.smequoteService.getQuotationDetails('SMEBI',this.quoteNumber,this.smeQuoteDetails.value.partner,this.source).subscribe(result=>{
    let quotedetail = result.quotedetails;
    console.log("Get Quote Details",quotedetail)
    this.quoteDocuments=result.quoteDocuments;	
    this.EmployeeHeaderData = result.EmployeeHeaderData
    this.benefitData = result.benefitData
            //WC document displaying logic- shuhaib	
          //   let wcBenefit=result.benefitData.filter(b=>b.CRS_Prod_Map_Name=="Workmens Compensation" && b.DefaultSumCovered!="0" )	
          //   console.log('wcBenefit:'+wcBenefit)	
          //  if(wcBenefit.length>0){	
          //  this.showWC=true;	
          //  }	
           	
    // if(quotedetail[0].StatusDesc == 'REFAPPROVED' || quotedetail[0].StatusDesc == 'ADDITIONALINFO'){
    if(quotedetail[0].StatusDesc == 'REFAPPROVED'){
      this.reapprove = true;
      }
      else{
        this.reapprove = false;
      } 

      if(quotedetail[0].StatusDesc == 'QUOTED' || quotedetail[0].StatusDesc == 'REFAPPROVED' || quotedetail[0].StatusDesc == 'RENQUOTE'){	
        this.buttonds = false;	
        }	
        else{	
          this.buttonds = true;	
        } 

        if(this.EmployeeHeaderData.length > 0)
          {
            this.adminCount = this.EmployeeHeaderData[0].EmployeeCount == null ? 0 : this.EmployeeHeaderData[0].EmployeeCount
            this.adminSumInsured = this.EmployeeHeaderData[0].SumInsured
            this.nonAdminCount = this.EmployeeHeaderData[1].EmployeeCount  == null ? 0 :this.EmployeeHeaderData[1].EmployeeCount
            this.nonAdminSumInsured = this.EmployeeHeaderData[1].SumInsured
          }
     
        this.claimCount =quotedetail[0].ClaimCount;
        this.claimAmount =quotedetail[0].ClaimAmount;
        this.expiringPremium =quotedetail[0].ExpiringPremium;
    this.quoteId =quotedetail[0].QuotationId;
    this.quoteNumber =result.quotation_number;
    this.quoteStatus =result.quotedetails[0].StatusDesc;
    this.TotalCont= quotedetail[0].TotalContribution;
    this.ValueAddedT = quotedetail[0].ValueAddedTax;
    this.PremiumLoad = quotedetail[0].PremiumLoading;
    this.LoadingRate = quotedetail[0].LoadingRate;
    //this.IsRenewal = quotedetail[0].IsRenewal

    this.additionalDetailForm.get('BusinessEntityName').setValue(result.quotedetails[0].BusinessEntityName)
    this.additionalDetailForm.get('BusinessEntityName').updateValueAndValidity();
    
   
    this.expiryDate = result.quotedetails[0].ExpiryDate;
    this.dtminDate = new Date(this.expiryDate);
    if(this.dtminDate > this.minDate){
      this.disableDate = true;
      this.dtminDate.setDate(this.dtminDate.getDate() + 1);
      this.dtmaxDate = new Date(this.dtminDate);
      this.dtmaxDate.setDate(this.dtmaxDate.getDate() + 45);
          }
          else{
            this.disableDate = false;
            this.dtminDate = new Date(this.minDate);
            this.dtmaxDate = new Date(this.minDate);
            this.dtmaxDate.setDate(this.dtmaxDate.getDate() + 45);
          }
    this.benefitData.map((x,i)=>{
      this.smeQuoteDetails.get('startDate').setValue(this.dtminDate);
    })     

    //this.smeQuoteDetails.get('businessArea').setValue(quotedetail[0].PartnerId); --commented now
    //
    // console.log(quotedetail);
    // Partner Name
   
        //underWriter changes for broker and branch- shuhaib
        console.log('partnerID',this.partnerID);
        console.log('BranchId',quotedetail[0].BranchId);
        if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
          this.smeQuoteDetails.get("partner").setValue(quotedetail[0].CedantBrokerId);
          this.quotation_branchId=quotedetail[0].BranchId;
        }
        else{
          this.smeQuoteDetails.get('partner').setValue(quotedetail[0].PartnerId);
        }
    // Branch 
    // if(this.partnerID == 1){
    //   this.getPartnerBranchList();
    // }
    //   else{
    //     this.getUserBranchList();
    //   }
    this.getPartnerBranchList();
    this.smeQuoteDetails.get('businessName').setValue(quotedetail[0].BusinessEntityName); 
    // Business Area Name
    quotedetail.map((quoteData,index)=>{
      if(index>0)
      {
        this.addLocation();
      }
      this.locations.at(index).get('riskSelect').setValue(quoteData.IsRiskInMall.trim()); 
      //this.additionalDetails.at(index).get('entityName').setValue(quoteData.BusinessEntityName);
      this.additionalDetails.at(index).get('companyAddress').setValue(quoteData.RiskLocationAddress);
     // this.locations.at(index).get('riskSelect').setValue(quotedetail[0].IsRiskInMall.trim()); 
      console.log("quotedetails",index)
      
        let businessArea =quoteData.BusinessArea
        var businessIndex = this.bArea.findIndex((obj, k) =>{
    
          return obj.BusinessAreaName == businessArea;
        });
       this.locations.at(index).get('businessArea').setValue(this.bArea[businessIndex]); 
     
          // Industry Name
         
        let industryName =quoteData.IndustryName
        var industryIndex = this.indSegment.findIndex((obj, k) =>{
    
          return obj.IndustryName == industryName;
        });
        this.locations.at(index).get('industryType').setValue(this.indSegment[industryIndex]); 
     
        this.getBusinessSegment(quoteData.BusinessName,index);
          // emirate Name
          if(!this.InitiatedQuote){
        let regionName =quoteData.EmiratesName
        var emirateIndex = this.emirates.findIndex((obj, k) =>{
    
          return obj.RegionName == regionName;
        });
        this.locations.at(index).get('emirates').setValue(this.emirates[emirateIndex]); 
        // this.getemiratesmall(quotedetail.MallId);
        if(regionName == null){
          this.getemiratesmall('0',index);
       }else{
        this.getemiratesmall(quoteData.MallId,index);
       }
      }
      this.locations.at(index).get('Address').setValue(quoteData.RiskLocationAddress); 
    
        // Insuring Property
        if(quoteData.FloorsCount == '0'){
          // alert(1)
          this.locations.at(index).get('insuringProperty').setValue(this.optionyesNo[1]); 
          this.checkValidation(index);
        }else{
          this.locations.at(index).get('insuringProperty').setValue(this.optionyesNo[0]); 
          this.checkValidation(index);
    
          this.locations.at(index).get('businessYear').setValue(quoteData.BusinessEstYear); 
    
          let classconstruction =quoteData.ConstructionClassName
          var constIndex = this.conclass.findIndex((obj, k) =>{
      
            return obj.ConstructionClassName == classconstruction;
          });
          this.locations.at(index).get('construction').setValue(this.conclass[constIndex]); 
    
    
    
          // let bussinessPrem =quoteData.BusinessPremise
          // var premIndex = this.bussPremise.findIndex((obj, k) =>{
      
          //   return obj.BusinessPremise == bussinessPrem;
          // });
          // this.locations.at(index).get('businessPremises').setValue(this.bussPremise[premIndex]); 
          
      
    
    
        let annual =quoteData.AnnualTurnover
        var annualIndex = this.turnOver.findIndex((obj, k) =>{
    
          return obj.AnnualTurnover == annual;
        });
        console.log(annualIndex)
        this.locations.at(index).get('AnnualBusiness').setValue(this.turnOver[annualIndex]); 
    
    
              if(quoteData.PremisesWithCladding == 'N'){
                  // alert(1)
                  this.locations.at(index).get('buildingCladding').setValue(this.optionyesNo[1]); 
                
              }else{
                  this.locations.at(index).get('buildingCladding').setValue(this.optionyesNo[0]); 
              }
    
              let nooffloor =quoteData.FloorsCount
        // var floorindex = this.noofFloor.findIndex((obj, k) =>{
    
        //   return obj.AnnualTurnover == annual;
        // });
        // console.log(annualIndex)
        // this.smeQuoteDetails.get('NoOfFloors').setValue(this.noofFloor[1]);
        if(quoteData.FloorsCount == '1'){
          // alert(1)
          this.locations.at(index).get('NoOfFloors').setValue(this.noofFloor[0]); 
          // this.checkValidation();
        }
        if(quoteData.FloorsCount == '2'){
          // alert(1)
          this.locations.at(index).get('NoOfFloors').setValue(this.noofFloor[1]); 
          // this.checkValidation();
        }
        if(quoteData.FloorsCount == '3'){
          // alert(1)
          this.locations.at(index).get('NoOfFloors').setValue(this.noofFloor[2]); 
          // this.checkValidation();
        }
    
    
            }
     // Claiming History
    
     let claimName =quoteData.ClaimHistory
     var claimIndex = this.claimHistory.findIndex((obj, k) =>{
    
       return obj.ClaimHistory == claimName;
     });
     this.smeQuoteDetails.get('claimHistory').setValue(this.claimHistory[claimIndex]); 
     this.getQuotationHistory(this.quoteNumber);
     this.pageLoader=this.globalService.spinnerHide();
   
    //  else{
    //   this.pageLoader=this.globalService.spinnerHide();
    //  }
    
    })

  })
  console.log("Location Form in update",this.locationForm)
  console.log("Location Form in update",this.locations)
  

}

// ---------------------------------------Get Quotation History -----------------------------------------------
getQuotationHistory(quoteNumber){
  this.motorQuoteService.getQuotationHistory(quoteNumber,'SM').subscribe(res =>{
            if(res.response_code == 1){
                    this.quotationHistoryArr = res.quotationHistoryList;
                    if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
                      this.filteredquotationHistoryArr = this.quotationHistoryArr
                    }
                    else
                    {
                      this.filteredquotationHistoryArr = this.quotationHistoryArr.filter(item => 
                        !item.StatusCode.includes('QUOTATION REASSIGNED')
                      );
                    }
                    
                    
            }
                

  });
}
saveQuotePlanDetail(stepper,type){
  this.isToBeReferred = false;
  this.saveChanges();
  if(this.allowed == 1 && this.ratioallowed != 2 && this.covermust != 3 && this.coversumallowed != 4){
  this.quotationloader=this.globalService.spinnerShow();
  let quotedetails= []
  this.benefitData.forEach((locationElement,locIndex)=>{
    quotedetails.push(
      {
        QuotationNumber:this.quoteNumber,
        partnerId:this.smeQuoteDetails.value.partner,
        industry:this.locations.at(locIndex).value.industryType,
        business:this.locations.at(locIndex).value.businessSegment,
        emiratesid:this.locations.at(locIndex).value.emirates,
        emiratemall:this.locations.at(locIndex).value.emirateMall,
        FireContribution:this.basePremium.reduce((acc, curr) => acc + curr, 0),
        AllRiskContribution:0,
        OptionalContribution:0,
        FixedPlanContribution:this.basePremium.reduce((acc, curr) => acc + curr, 0),
        FixedPlanId:this.planId,
        ServiceTax:this.serviceTax,
        ServiceTaxRate:0,
        policyFee:this.policyFee,
        TotalContribution:this.totalPremium,
        VATAmount: this.Vat_Amount,
        VATTaxRate:5,
        PremLoading:this.loadingAmount,
        PremLoadingRate:this.loadingby,
        productCode: this.productCode,
        lob_code:'SM',
        processType : 'INSERT' ,
        policyType:'NEW',
        source : 'B2B' 
    
      }
    )
  })

  let EmployeeHeaderdetails= [{
    EmployeeType : 'Admin',
    EmployeeCount : this.adminCount,
    SumInsured : this.adminSumInsured
  },
  {
    EmployeeType : 'Non Admin',
    EmployeeCount : this.nonAdminCount,
    SumInsured : this.nonAdminSumInsured
  }]

  this.benefitData.forEach((locElement,locIndex)=>{
    locElement.forEach((element,index)=>{
      console.log(this.locations.at(locIndex).value.industryType)
      element.IndustrySegment = this.locations.at(locIndex).value.industryType
      this.additionalDetails.at(locIndex).get('companyAddress').setValue(this.locations.at(locIndex).value.Address);
    })
  })
  this.smequoteService.saveQuotationDetails(quotedetails,this.benefitData,EmployeeHeaderdetails).subscribe(res=>{
   
    if(type == 1){
      this.quotationloader=this.globalService.spinnerHide();
    Swal.fire('Quotation Saved Successfully','Your SME quotation has been saved successfully.','success');


    }else if(type == 2){

      this.isToBeReferred = this.CheckReferralConditions();
      if(this.isToBeReferred)
      {
          this.refferalButton =true;
         
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
          return false;
      }
      this.smequoteService.getQuotationDetails('SMEBI',this.quoteNumber,this.smeQuoteDetails.value.partner,this.source).subscribe(result=>{
    
        let quotedetail = result.quotedetails[0];
        this.quoteId =quotedetail.QuotationId;
        this.quoteNumber =result.quotation_number;
        this.quoteStatus =result.quotedetails[0].StatusDesc;
        //this.IsRenewal = quotedetail[0].IsRenewal
        this.benefitData.forEach((x,i)=>{
        //  this.additionalDetails.at(i).get('entityName').setValue(result.quotedetails[0].BusinessEntityName);
          //this.additionalDetails.at(i).get('companyAddress').setValue(result.quotedetails[0].RiskLocationAddress);
          this.smequoteService.getDocumentList('B2B','SM',99999,this.quoteNumber).subscribe(result=>{
            // this.additionalDetails.at(i).get('documents').setValue
            this.quotationloader=this.globalService.spinnerHide();
            debugger;
            console.log("WC Changes",this.WCMandatory.length)
            if(this.WCMandatory.length>0){
              this.docList[i]= result.getDocumentsList.map(document => {
                if (document.DocumentId === 155) {
                  document.MandatorySymbol = '*';
                }
                return document;
              });
            }
            else{
              this.docList[i] =result.getDocumentsList;
            }
    
            this.docList[i].forEach((element,index) => {
              this.docList[i][index].checked = false
              this.docList[i][index].file_name = '';
              
            });
 
          })
        })
      })
      console.log("Document List", this.docList)
      
      this.checkPartnerCreditLimit()
      if(stepper !='')
        {
         stepper.next();
        }
    }else if(type == 3){
      this.isToBeReferred = this.CheckReferralConditions();
      if(this.isToBeReferred)
      {
          this.refferalButton =true;
         
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
          return false;
      }
      let QuoteArray =[{
        productCode: this.productCode,
        lob_code:'SM',
        processType : 'INSERT' ,
        policyType:'NEW',
        source : 'B2B' 
      }]
      this.smequoteService.printsmepdf(QuoteArray,this.quoteNumber).subscribe(res=>{

        // res.pdfPath
        window.open( res.pdfPath, "_blank");
        this.quotationloader=this.globalService.spinnerHide();

      })
    }else if(type == 4){
      this.isToBeReferred = this.CheckReferralConditions();
      if(this.isToBeReferred)
      {
          this.refferalButton =true;
         
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "If you need further assistance call us at 800-842", "error");
          return false;
      }
      this.quotationloader=this.globalService.spinnerHide();
      let QuoteArray =[{
        productCode: this.productCode,
        lob_code:'SM',
        processType : 'INSERT' ,
        policyType:'NEW',
        source : 'B2B' 
      }]
      if(this.emailAdd == '' || null){
        this.emailaddValidation = true;
        return false;
      }else{
        let QuoteArray =[{
          productCode: this.productCode,
          lob_code:'SM',
          processType : 'INSERT' ,
          policyType:'NEW',
          source : 'B2B' 
        }]
        this.smequoteService.printsmepdf(QuoteArray,this.quoteNumber).subscribe(res=>{
  // alert('1');
  //         // res.pdfPath
  //         window.open( res.pdfPath, "_blank");
  //         this.quotationloader=this.globalService.spinnerHide();
  
        })
        this.emailaddValidation = false;
        this.smequoteService.emailSMEquote(QuoteArray,this.quoteNumber,this.emailAdd).subscribe(res=>{

          
  
          if(res.response_code == 1){
            Swal.fire("", "Thank you for choosing Fidelity United for SME. We have sent an email to your registered email with all the quotation details. Your Quotation Reference# " + this.quoteNumber, 'success');
          }
          // res.pdfPath
          // window.open( res.pdfPath, "_blank");
          // this.quotationloader=this.globalService.spinnerHide();
  
        })

      }
      
    }
   

  })
  	  
}
else if(this.allowed == 1 && this.ratioallowed == 2 && this.covermust != 3 && this.coversumallowed != 4){
  Swal.fire("", "Business Interruption sum insured should not be more than 200% of Property Damage (or PAR section) sum insured", 'error');
              return;
}  
else if(this.allowed == 1 && this.ratioallowed != 2 && this.covermust == 3 && this.coversumallowed != 4){
  Swal.fire("", "Invalid Covers Selected Please select at least one cover either from Property All Risk and Public Liability and Workmens Compensation", 'error');
              return;
} 
else if(this.allowed == 1 && this.ratioallowed != 2 && this.covermust != 3 && this.coversumallowed == 4){
  Swal.fire("", "Total Sum Insured under PAR and BI cannot be more than 40,000,000", 'error');
              return;
}  
else {
  Swal.fire("", "Please uncheck the Employers Liability first. Employers Liability cannot be allowed without Workmens Compensation", 'error');
  return;
}  
}

saveChanges(){
 
  this.totalparpr[0] = 0;
  this.totalpar[0] = 0;
  this.totalWcEl[0] =0;
  this.PubLty[0] =0;
  this.ratioallowed = 0;
  this.covermust = 0;
  this.allowed = 1;
  if(this.basePremium[this.currentIndex] == 0){
    Swal.fire("", "Basic Premium cannot be 0", 'error');
    return;
  }

  this.benefitData.forEach((locationElement,locindex)=>{
    locationElement.forEach((element,index)=>{
      let suminsured = element.DefaultSumCovered != 0 ? element.DefaultSumCovered.replace(/,/gi, "") : 0
      this.totalSumInsured[locindex] = +parseInt(suminsured)
    })
  })

  this.benefitData.forEach((locationElement,locindex)=>{
    locationElement.forEach((element,index)=>{
      if(element.BenefitName == 'As per UAE Labour Law with estimated annual wages not exceeding' && element.DefaultSumCovered == 0){
        this.benefitData[locindex].forEach((benefitel,benefiti)=>{
          if(benefitel.BenefitName == 'Employers Liability'){
            if(benefitel.DefaultSumCovered != 0){
              this.allowed = 0;
            }
            
          }
        })
   
      } 
      if(element.BenefitName == 'On Contents, Furniture'){
        if(element.DefaultSumCovered != 0){
          this.onf[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.onf[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'Annual Loss of Rent/Additional rent for alternative accommodation'){
        if(element.DefaultSumCovered != 0){
          this.alor[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.alor[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'On Building/Premises including Fixtures and Fittings'){
        if(element.DefaultSumCovered != 0){
          this.obp[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.obp[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'On Machinery, Equipments and Tools'){
        if(element.DefaultSumCovered != 0){
          this.ome[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.ome[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'On Stock (Raw Material, Work In Progress and Finished Goods, Incidental to the business only)'){
        if(element.DefaultSumCovered != 0){
          this.osrm[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.osrm[locindex] = 0;
        }
      }  

      this.totalpar[locindex] = this.onf[locindex] + this.alor[locindex] + this.obp[locindex] + this.ome[locindex] + this.osrm[locindex];
  
      if(element.BenefitName == 'Increased Cost of Working'){
        if(element.DefaultSumCovered != 0){
          this.Icw[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.Icw[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'Gross Profit'){
        if(element.DefaultSumCovered != 0){
          this.grssp[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.grssp[locindex] = 0;
        }
      }    
      this.totalBI[locindex] = this.Icw[locindex] + this.grssp[locindex];
      this.totalparpr[locindex] = this.totalpar[locindex] + (this.totalpar[locindex] * 2);
      if(element.BenefitName == 'On Contents, Furniture'){
        if(element.DefaultSumCovered != 0){
          this.onf[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.onf[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'Annual Loss of Rent/Additional rent for alternative accommodation'){
        if(element.DefaultSumCovered != 0){
          this.alor[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.alor[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'On Building/Premises including Fixtures and Fittings'){
        if(element.DefaultSumCovered != 0){
          this.obp[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.obp[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'On Machinery, Equipments and Tools'){
        if(element.DefaultSumCovered != 0){
          this.ome[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.ome[locindex]= 0;
        }
      }  
      if(element.BenefitName == 'On Stock (Raw Material, Work In Progress and Finished Goods, Incidental to the business only)'){
        if(element.DefaultSumCovered != 0){
          this.osrm[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.osrm[locindex] = 0;
        }
      }  
  
      this.totalpar[locindex] = this.onf[locindex] + this.alor[locindex] + this.obp[locindex] + this.ome[locindex] + this.osrm[locindex];
      if(element.BenefitName == 'Public Liability'){
            if(element.DefaultSumCovered != 0){
              this.PubLty[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
            }else{
              this.PubLty[locindex] = 0;
            }
      }  
              
      if(element.BenefitName == 'Employers Liability'){
        console.log("WC Changes",this.WCMandatory.length)
        console.log("WC Employers Liability", this.WCMandatory)
            if(element.DefaultSumCovered != 0){
              this.Eml[locindex] = +Number(element.DefaultSumCovered.replace(/,/gi, ""));
              console.log("EML",this.Eml)
              if(!this.WCMandatory.includes(index)){
                this.WCMandatory.push(index)
              }
              console.log("WC Employers Liability", this.WCMandatory)
              
            }else{
              this.Eml[locindex] = 0;
              this.WCMandatory= this.WCMandatory.filter(d => d !== index)
              console.log("WC Employers Liability", this.WCMandatory)
            }
      }  
      if(element.BenefitName == 'As per UAE Labour Law with estimated annual wages not exceeding'){
        if(element.DefaultSumCovered != 0){
          console.log("WC Employers Liability", this.WCMandatory)
          this.Llaw[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
          if(!this.WCMandatory.includes(index)){
            this.WCMandatory.push(index)
          }
          console.log("WC Employers Liability", this.WCMandatory)

        }else{
          this.Llaw[locindex] = 0;
          this.WCMandatory= this.WCMandatory.filter(d => d !== index)
          console.log("WC Employers Liability", this.WCMandatory)
        }
      }    
      this.totalWcEl[locindex] = this.Eml[locindex] + this.Llaw[locindex];  
                  console.log("WorkerMen",this.Eml,this.Llaw)
      this.totalparpr[locindex] = this.totalpar[locindex] + this.totalWcEl[locindex] + this.PubLty[locindex];

      if(element.BenefitName == 'Increased Cost of Working'){
        if(element.DefaultSumCovered != 0){
          this.Icw[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.Icw[locindex] = 0;
        }
      }  
      if(element.BenefitName == 'Gross Profit'){
        if(element.DefaultSumCovered != 0){
          this.grssp[locindex] = +element.DefaultSumCovered.replace(/,/gi, "");
        }else{
          this.grssp[locindex] = 0;
        }
      }    
      this.totalBI[locindex] = this.Icw[locindex] + this.grssp[locindex];  
    })
  })
   // if(this.totalparbi > 40000000){
              //   this.coversumallowed = 4;
              // }
              // else{
              //   this.coversumallowed = 0;
              // }  
                // }  



          
          
  console.log(this.totalpar[this.currentIndex],this.totalWcEl[this.currentIndex],this.PubLty[this.currentIndex],Number(this.totalsumCovered[this.currentIndex]) - Number(this.totalparpr[this.currentIndex]));
  if(this.IsRenewal ==0 && (this.totalpar[this.currentIndex] == 0 || this.totalWcEl[this.currentIndex] == 0 || this.PubLty[this.currentIndex] ==0 )){
    this.covermust = 3;
  }
  if(this.totalBI[this.currentIndex] > this.totalparpr[this.currentIndex]){
    this.ratioallowed= 2;
  }
             
          

 console.log(this.allowed);
 console.log(this.ratioallowed);
 console.log(this.covermust);
 console.log(this.coversumallowed);
 if(this.ratioallowed == 2){
  Swal.fire("", "Business Interruption sum insured should not be more than 200% of Property Damage (or PAR section) sum insured", 'error');
              return;
}  
else if(this.covermust == 3){
  Swal.fire("", "Invalid Covers Selected Please select at least one cover  from Property All Risk and Public Liability and Workmens Compensation", 'error');
              return;
} 
else if(this.allowed == 0 && this.ratioallowed != 2 && this.covermust != 3){
  Swal.fire("", "Please uncheck the Employers Liability first. Employers Liability cannot be allowed without Workmens Compensation", 'error');
  return;
} 
else
{
  this.businessPackageModel = false;
} 
}
convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat);
  // console.log([ pad(d.getMonth()+1),pad(d.getDate()),d.getFullYear()])
  return ([ pad(d.getMonth()+1),pad(d.getDate()),d.getFullYear()].join('/'));
  
  }
  checkTermsCond(){
    this.accept_terms = !this.accept_terms;
}
checkDisclaimer(){
  this.accept_disclaimer = !this.accept_disclaimer;
}
// checkissuepolicy(type){
//     //IA license and Trade license validate starts..
//   // this.getBrokerLicenseDetails().then(validLicense=>{
//   //   if(validLicense){
//     //DIGI Status check starts Feb2024
   
//     this.digi_status$.subscribe(trackText => {
//       //this.digiSwalInstance= Swal.fire(`<b>SME Insurance Policy</b>`,`${trackText}`,"success")
//       this.digiSwalInstance= Swal.fire({
//         title: '',
//         html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,  
//         showConfirmButton: false
//       });
//     });
    
//     var digiRequest={
//       "QuotationNumber":this.quoteNumber,
//       "FirstName": this.smeQuoteDetails.get('businessName').value,
//       "MiddleName": "",
//       "LastName": this.smeQuoteDetails.get('businessName').value,
//       "MatchCategory": "C",
//       "Nationality": "",
//       "DOB": "",
//       "CustomerType": "C",
//       "UserEmail": this.localStorDta.EmailAddress
//     };
//     this.issuepolicyLoader=this.globalService.spinnerShow();
//   this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
//     this.issuepolicyLoader=this.globalService.spinnerHide();
//     if(res==null ||res.data==null ){
//    Swal.fire("", "Failed to process the request. Please try again", 'error'); 
//     return;
//     }
//       else if(res.data.digiStatus=='Approved' ){
//       if (this.digiSwalInstance) {
//         this.digiSwalInstance.close();
//        }
//        this.docUploadFlag = true;     
//        this.issuePolicy(type);
//       return;
//     }
    
//     else {   
   
//        if(config.enableDIGIApproval=="N"){
//         this.digi_status.next("Almost there...");
//         setTimeout(() => {
//           this.digi_status.next("Please do not close the browser or refresh...");
//       }, 2000);
//         setTimeout(() => {
//           if (this.digiSwalInstance) {
//             this.digiSwalInstance.close();
//            }
//           this.docUploadFlag = true;     
//           this.issuePolicy(type);
//       }, 60000); 
//      }  
//      else {
//       if (this.digiSwalInstance) {
//         this.digiSwalInstance.close();
//        }
//       Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
//       return;  
//    }

//     }
//     // else{
//     //   if(res.data.recordExists==true){
//     //     Swal.fire("DIGI Approval Requested already", "You will be receiving a confirmation mail from compliance", 'error').then(result => {
//     //       this._route.navigateByUrl('home/retreivequote');
//     //     });
//     //   }
//     //    else{
//     //     Swal.fire("DIGI Approval Requested", "You will be receiving a confirmation mail from compliance", 'error').then(result => {
//     //       this._route.navigateByUrl('home/retreivequote');
//     //     });
//     //    }

//     // }
//   });
//    //DIGI Status check End Feb2024
//     // }
//     // });
// }

checkissuepolicy(type){
  //IA license and Trade license validate starts..
// this.getBrokerLicenseDetails().then(validLicense=>{
//   if(validLicense){
this.docUploadFlag = true;
this.issuePolicy(type);
  // }
  // });
}


issuePolicy(type){
  console.log(type)
  // console.log(this.docUploadFlag);
this.additionalDetails.controls.forEach((locElement,locIndex) => {
  this.docList[locIndex].forEach((element,index) => {
    if(this.docUploadFlag == true){
            if(element.MandatorySymbol == '*'){
                  if(element.UploadedFileName == ''){
                    this.docUploadFlag = false;
                  }else{
                    this.docUploadFlag = true;
                  }
            }
  }
  });
});

  // if(this.docUploadFlag == false){
  //   Swal.fire("", "Please Upload Required Documents", 'error');
  //   return;
  // }
  if(this.additionalDetailForm.invalid){

    this.additionalDetailForm.markAllAsTouched();
    this.additionalDetailForm.updateValueAndValidity();
  Swal.fire("", "Please fill all the mandatory data", 'error');
  return;

}
else if(this.docUploadFlag == false){
   Swal.fire("", "Please Upload Required Documents", 'error');
    return;
}
else  if(this.accept_terms == false){
  Swal.fire("", "Please Select Terms And Conditions", 'error');
  return;
}
else  if(this.accept_disclaimer == false){
  Swal.fire("", "Please accept Disclaimer", 'error');
  return;
}
this.issuepolicyLoader=this.globalService.spinnerShow();
let savePolicy = []

this.additionalDetailForm.value.additionalDetails.map((x,i)=>{
    savePolicy.push({
        CompanyName:this.smeQuoteDetails.value.businessName.replace(this.characterToReplace, this.replacementCharacter),
        ContactPerson:x.contactperson,
        MortgageeText:x.mortgagee,
        AssignmentValue:x.assignmentValue,
        RegistrationNumber:x.tradeLicNumber,
        TRNNumber:x.trnNo,
        RiskLocation:x.companyAddress,
        POBox:x.poboxNo,
        PhoneNumber:x.phoneNumber,
        MobileNumber:x.mobNo,
        EmailAddress:x.email,
        FreeZone:x.freezzone.value,
        CoverStartDate:this.convertDate(this.smeQuoteDetails.value.startDate),
        PartnerId:this.smeQuoteDetails.value.partner,
        UserId:this.userId,
        FixedPlanContribution:this.basePremium[i],
          FixedPlanId:this.planId,
          ServiceTax:this.serviceTax,
          ServiceTaxRate:0,
          policyFee:this.policyFee,
          TotalContribution:this.totalPremium,
          VATAmount: this.Vat_Amount,
          VATTaxRate:5,
          PremLoading:this.loadingAmount,
          PremLoadingRate:this.loadingby,
        ProductCode: this.productCode,
        QuotationNumber:this.quoteNumber,
        PlanId:this.planId,
        CurrencyCode:'AED',
        source : 'B2B' ,
        AddressLatitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[0].trim().slice(0, 19) : '',
       AddressLongitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[1].trim().slice(0, 19) : ''
  
    })

})

  //Added by Anju for party status active check on Issue Policy/Pay Now/Send Payment Link
  this.selectedPartnerId=this.smeQuoteDetails.value.partner;
  this.selectedBranchId = this.smeQuoteDetails.value.branch.Id; 
  console.log('calling smequoteService.smesavePolicy savePolicy:'+JSON.stringify(savePolicy) )
  console.log('calling smequoteService.smesavePolicy type:'+type )
  this.smequoteService.checkPartner_CreditLimit("Property All Risk",0, this.selectedPartnerId,this.selectedBranchId).subscribe(response=>
  {
    if(response.cl_status=='200012'){
      Swal.fire('Party is not Active.');
      this.issuepolicyLoader=this.globalService.spinnerHide();
    }
    else
    {
      if(type == 1){
        //DIGI Status check starts Feb2024
        this.digi_status$.subscribe(trackText => {
          //this.digiSwalInstance= Swal.fire(`<b>SME Insurance Policy</b>`,`${trackText}`,"success")
          this.digiSwalInstance= Swal.fire({
            title: '',
            html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,  
            showConfirmButton: false
          });
        });
        
        var digiRequest={
          "QuotationNumber":this.quoteNumber,
          "FirstName": this.smeQuoteDetails.get('businessName').value,
          "MiddleName": "",
          "LastName": this.smeQuoteDetails.get('businessName').value,
          "MatchCategory": "C",
          "Nationality": "",
          "DOB": "",
          "CustomerType": "C",
          "UserEmail": this.localStorDta.EmailAddress
        };
        this.issuepolicyLoader=this.globalService.spinnerShow();
      this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
        this.issuepolicyLoader=this.globalService.spinnerHide();
        if(res==null ||res.data==null ){
       Swal.fire("", "Failed to process the request. Please try again", 'error'); 
        return;
        }
          else if(res.data.digiStatus=='Approved' ){
        this.smequoteService.smeissuePolicy(savePolicy).subscribe(res=>{
          if (this.digiSwalInstance) {
            this.digiSwalInstance.close();
           }
          if(res.res_code == 1){
            this.policyNumber = res.policy_details[0].PolicyNumber;  
            this.getCreditDebitNote();
            this.issuepolicyLoader=this.globalService.spinnerHide();
            this._route.navigateByUrl("sme/thankyou/" +  this.policyNumber +'/'+ this.quoteNumber);
           }
        });
      }
      else {   
         if(config.enableDIGIApproval=="N"){
          this.digi_status.next("Almost there...");
          setTimeout(() => {
            this.digi_status.next("Please do not close the browser or refresh...");
        }, 2000);
          setTimeout(() => {
             this.smequoteService.smeissuePolicy(savePolicy).subscribe(res=>{
              debugger;
              if (this.digiSwalInstance) {
                this.digiSwalInstance.close();
               }
              if(res.res_code == 1){
                this.policyNumber = res.policy_details[0].PolicyNumber;  
                this.getCreditDebitNote();
                this.issuepolicyLoader=this.globalService.spinnerHide();
                this._route.navigateByUrl("sme/thankyou/" +  this.policyNumber +'/'+ this.quoteNumber);
               }
            });
        }, 60000); 
       }  
       else {
        if (this.digiSwalInstance) {
          this.digiSwalInstance.close();
         }
        Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
        return;  
     }
  
      }
      });
      }
      if(type ==2 ){
        //DIGI Status check starts Feb2024
        this.digi_status$.subscribe(trackText => {
          //this.digiSwalInstance= Swal.fire(`<b>SME Insurance Policy</b>`,`${trackText}`,"success")
          this.digiSwalInstance= Swal.fire({
            title: '',
            html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,  
            showConfirmButton: false
          });
        });
        
        var digiRequest={
          "QuotationNumber":this.quoteNumber,
          "FirstName": this.smeQuoteDetails.get('businessName').value,
          "MiddleName": "",
          "LastName": this.smeQuoteDetails.get('businessName').value,
          "MatchCategory": "C",
          "Nationality": "",
          "DOB": "",
          "CustomerType": "C",
          "UserEmail": this.localStorDta.EmailAddress
        };
        this.issuepolicyLoader=this.globalService.spinnerShow();
      this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
        this.issuepolicyLoader=this.globalService.spinnerHide();
        if(res==null ||res.data==null ){
       Swal.fire("", "Failed to process the request. Please try again", 'error'); 
        return;
        }
          else if(res.data.digiStatus=='Approved' ){
        this.smequoteService.smesavePolicy(savePolicy).subscribe(res=>{
          debugger;
          if (this.digiSwalInstance) {
            this.digiSwalInstance.close();
           }
          this.policyNumber = res.quote_details[0].PolicyNumber;
              if(res.res_code == 1){
                let return_url = "http://localhost:4200/dashboard";
                let site_url = "http://localhost:4200/" + this._route.url;
                  this.travelInsuranceService.paymentAuth(this.quoteNumber, site_url, return_url, 'SM', this.partnerID, this.policyNumber, 'SHIELD', 'B2B').subscribe(res => {//Added by Anju for SME Payment gateway revamp
                      if (res.status == 'Failed') {
                          Swal.fire(res.res_msg);
                          this.issuepolicyLoader = this.globalService.spinnerHide();

                          return false;
                      }

                      else if (res.status == 'Success') {

                          // this.policyLoader = this.globalService.spinnerHide();
                          localStorage.setItem('Payment_Order_ID', res.data.tran_ref);
                          localStorage.setItem('Payment_Order_Tokan', res.data.token);
                          localStorage.setItem('Payment_Quotation_Number', this.quoteNumber);
                          localStorage.setItem('Payment_Quotation_ID', this.quoteId);
                          window.location.href = res.data.redirect_url;
                // window.open(res.result.redirect_url, '_blank');
             
      
              }

            })
          }
    })
  }
  else {   
     if(config.enableDIGIApproval=="N"){
      this.digi_status.next("Almost there...");
      setTimeout(() => {
        this.digi_status.next("Please do not close the browser or refresh...");
    }, 2000);
      setTimeout(() => {
        this.smequoteService.smesavePolicy(savePolicy).subscribe(res=>{
          if (this.digiSwalInstance) {
            this.digiSwalInstance.close();
           }
          this.policyNumber = res.quote_details[0].PolicyNumber;
              if(res.res_code == 1){
                let return_url = "http://localhost:4200/dashboard";
                let site_url = "http://localhost:4200/" + this._route.url;
                  this.travelInsuranceService.paymentAuth(this.quoteNumber, site_url, return_url, 'SM', this.partnerID, this.policyNumber, 'SHIELD', 'B2B').subscribe(res => {//Added by Anju for SME Payment gateway revamp
                      if (res.status == 'Failed') {
                          Swal.fire(res.res_msg);
                          this.issuepolicyLoader = this.globalService.spinnerHide();

                          return false;
                      }

                      else if (res.status == 'Success') {

                          // this.policyLoader = this.globalService.spinnerHide();
                          localStorage.setItem('Payment_Order_ID', res.data.tran_ref);
                          localStorage.setItem('Payment_Order_Tokan', res.data.token);
                          localStorage.setItem('Payment_Quotation_Number', this.quoteNumber);
                          localStorage.setItem('Payment_Quotation_ID', this.quoteId);
                          window.location.href = res.data.redirect_url;
                // window.open(res.result.redirect_url, '_blank');
             
      
              }

            })
          }
    })
    }, 60000); 
   }  
   else {
    if (this.digiSwalInstance) {
      this.digiSwalInstance.close();
     }
    Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
    return;  
 }

  }
  });
  }
  if(type == 3){
    //DIGI Status check starts Feb2024
    this.digi_status$.subscribe(trackText => {
      //this.digiSwalInstance= Swal.fire(`<b>SME Insurance Policy</b>`,`${trackText}`,"success")
      this.digiSwalInstance= Swal.fire({
        title: '',
        html: `<div style="display: inline-block;margin-top:40px; vertical-align: middle; height: 50px; width: 430px;">${trackText}</div>`,  
        showConfirmButton: false
      });
    });
    
    var digiRequest={
      "QuotationNumber":this.quoteNumber,
      "FirstName": this.smeQuoteDetails.get('businessName').value,
      "MiddleName": "",
      "LastName": this.smeQuoteDetails.get('businessName').value,
      "MatchCategory": "C",
      "Nationality": "",
      "DOB": "",
      "CustomerType": "C",
      "UserEmail": this.localStorDta.EmailAddress
    };
    this.issuepolicyLoader=this.globalService.spinnerShow();
  this.issuepolicyService.GetDIGIStatus(digiRequest).subscribe(res=>{
    this.issuepolicyLoader=this.globalService.spinnerHide();
    if(res==null ||res.data==null ){
   Swal.fire("", "Failed to process the request. Please try again", 'error'); 
    return;
    }
      else if(res.data.digiStatus=='Approved' ){
    this.smequoteService.smesavePolicy(savePolicy).subscribe(res=>{
      debugger;
      if (this.digiSwalInstance) {
        this.digiSwalInstance.close();
       }
    this.motorQuoteService.sendPaymentLink(this.quoteNumber, 'SM',this.additionalDetailForm.value.email,'B2B','',this.smeQuoteDetails.value.partner).subscribe(res => {

      let payRes = res;
  
      if (payRes.res_code == 1) {
  
        Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.quoteNumber, 'success');
  
        this._route.navigate(['sme/products']);
  
      }
      // this.showLoader.Quotation = false;
    });
  })
}
else {   
   if(config.enableDIGIApproval=="N"){
    this.digi_status.next("Almost there...");
    setTimeout(() => {
      this.digi_status.next("Please do not close the browser or refresh...");
  }, 2000);
    setTimeout(() => {
      this.smequoteService.smesavePolicy(savePolicy).subscribe(res=>{
        if (this.digiSwalInstance) {
          this.digiSwalInstance.close();
         }
      this.motorQuoteService.sendPaymentLink(this.quoteNumber, 'SM',this.additionalDetailForm.value.email,'B2B','',this.smeQuoteDetails.value.partner).subscribe(res => {
  
        let payRes = res;
    
        if (payRes.res_code == 1) {
    
          Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.quoteNumber, 'success');
    
          this._route.navigate(['sme/products']);
    
        }
        // this.showLoader.Quotation = false;
      });
    })
  }, 60000); 
 }  
 else {
  if (this.digiSwalInstance) {
    this.digiSwalInstance.close();
   }
  Swal.fire("", "KYC verification is in progress. You will be receiving a confirmation email to proceed", 'warning'); 
  return;  
}

}
});
  }
   }
    }); 
}
//------------------------- CHECK USER ACCESS FOR PAGE ------------------------------------//
checkUserAccess(){
  this.motorQuoteService.checkUserAccess('SMEQUOTE',this.localStorDta.EmailAddress, 'SM').subscribe(res => {
    let response = res;
  
        if (response.userAccessData == 0) {                 // userAccessData = 1 ------ give access 
              Swal.fire('Sorry !','You can not authorised.','error');     
              this._route.navigateByUrl('/agentMotor/motorquote');   // userAccessData = 0 ------- access denied
               this.userAccess = false;
              return false;
        }else{
         
            this.userAccess = true;
        }
    });
}

getCreditDebitNote(){
  this.smequoteService.creditDebitNote("B2B",this.policyNumber).subscribe(res=>{
  })

}
annualBusinessChange(item){
  if(item.AnnualTurnover != 'Less than AED 400,000 per year'){
    this.additionalDetailForm.controls['trnNo'].setValidators([Validators.required]);
    this.additionalDetailForm.controls['trnNo'].updateValueAndValidity()

  }else{
    this.additionalDetailForm.controls['trnNo'].setValidators(null);
    this.additionalDetailForm.controls['trnNo'].updateValueAndValidity()
  }
}

checkNumber(value){
  // console.log(value)
  if(value > this.maxLoadingRange){
    this.loadingby = this.maxLoadingRange
  }
  this.getLoadingBy();
}

uploadCompanyDocument(event,i,docindex,AllowedExtensions){
  let  locationId = parseInt(i) + 1;
  AllowedExtensions=AllowedExtensions.replaceAll(/\s/g,'');
  this.access =1;
  const formData = new FormData();
//  console.log(event.target.files[0].name);
  let ourEx =event.target.files[0].name.split('.')
  console.log(ourEx);
  // console.log(ourEx[1]);
  if(ourEx[1] == 'xls' || ourEx[1] == 'xlsx'){
    this.docList[i][docindex].checked =true;
    this.docList[i][docindex].file_name =event.target.files[0].name
    this.docList[i][docindex].UploadedFileName =event.target.files[0].name
    formData.append('file', event.target.files[0]);
    formData.append('LOBCode', 'SM');
    formData.append('QuotationNumber', this.quoteNumber);
    formData.append('FileExtn', ourEx[1]);
    formData.append('LocationId',locationId.toString())
    this.smequoteService.uploadDocumentsXls(formData).subscribe(result=>{
      event.target.value = null;
      if(result.response_code == 2 ){
        Swal.fire("Please select valid file format.", "", 'error');
      this.docList[i][docindex].file_name ='';
      this.docList[i][docindex].UploadedFileName ='';
      this.docList[i][docindex].checked =false;
      }
      this.benefitData.forEach((locationElement,locindex) => {
        locationElement.forEach((element,index)=>{
          if(element.BenefitName == 'As per UAE Labour Law with estimated annual wages not exceeding'){
            if(element.DefaultSumCovered != 0){
              this.totalsalary = element.DefaultSumCovered.replace(/,/gi, "");
            }else{
              this.totalsalary = 0;
            }
              } 
        })
      })

 
      if(result.total_salary > this.totalsalary ){
        Swal.fire("Mismatch between annual wages on portal and the excel template, please correct the values.", "", 'error');
      this.docList[i][docindex].file_name ='';
      this.docList[i][docindex].UploadedFileName ='';
      event.target.value = null;
      this.docList[i][docindex].checked =false;
      this.smequoteService.deleteEmployeeList(this.quoteNumber).subscribe(result=>{
        console.log(result);
      });
      }
      // console.log(result);
    })

  }
  else{
    formData.append('DocumentName', this.docList[i][docindex].DocumentName);
    formData.append('file', event.target.files[0]);
    // console.log(event.target.files[0]);
  // let allowedEx =event.target.files[0].name.split(',');
    formData.append('LOBCode', 'SM');
    formData.append('QuotationNumber', this.quoteNumber);
    formData.append('FileExtn', ourEx[1]);
    formData.append('LocationId',locationId.toString())
  let allowedEx =AllowedExtensions.split(',');
  // console.log(allowedEx);
  // console.log('.'+ourEx[1].trim  ())

  allowedEx.forEach((element,index) => {
 if(this.access == 1){
  // console.log((allowedEx[index]).toLowerCase());
  // console.log('.'+(ourEx[1].trim()).toLowerCase());
      if((allowedEx[index]).toLowerCase() == '.'+(ourEx[1].trim()).toLowerCase() ){
        this.access =0;
      }else{
        this.access =1;
      }
 }
  
  });

  if(this.access == 1){
    Swal.fire("Please select valid file format.", "", 'error');
    return false;
  }
  this.smequoteService.uploadDocuments(formData).subscribe(result=>{
    console.log(result.response_code);
    if(result.response_code == 1){
      this.docList[i][docindex].checked =true;
      this.docList[i][docindex].UploadedFileName =result.image_url;
      this.docList[i][docindex].file_name =event.target.files[0].name;
      
      // this.companydocument = result.image_url;
      // this.documentCheckbox = true;
    }
    else if(result.response_code == 3){
      Swal.fire("Allowed upload file size is up to 2MB", "", 'error');
      this.docList[i][docindex].file_name ='';
      this.docList[i][docindex].checked =false;
      // this.companydocument = '';
      // this.documentCheckbox = false;
    }
    else if(result.response_code == 5){
      Swal.fire("Please select valid file format.", "", 'error');
      this.docList[i][docindex].file_name ='';
      this.docList[i][docindex].checked =false;
      // this.companydocument = '';
      // this.documentCheckbox = false;
    }else{
      this.docList[i][docindex].checked =false;
    }

  })

  }
  
}
uploadAddinfoDocument(event,i){
  this.access =1;
  const formData = new FormData();
//  console.log(event.target.files[0].name);
  let ourEx =event.target.files[0].name.split('.')
  console.log(ourEx);
    formData.append('DocumentName', 'Additional_Doc');
    formData.append('file', event.target.files[0]);
    console.log(event.target.files[0]);
  // let allowedEx =event.target.files[0].name.split(',');
    formData.append('LOBCode', 'SM');
    formData.append('QuotationNumber', this.quoteNumber);
    formData.append('FileExtn', ourEx[1]);

  this.smequoteService.uploadDocuments(formData).subscribe(result=>{
    console.log(event.target.files[0].name);
    this.response_code = result.response_code;
    if(result.response_code == 1){
      this.UploadedFileName =result.image_url;
      this.file_name =event.target.files[0].name;
    }
    else if(result.response_code == 3){
      Swal.fire("Allowed upload file size is up to 2MB", "", 'error');
      event.target.value = null;
      this.file_name = '';
    }
    else if(result.response_code == 5){
      Swal.fire("Please select valid file format.", "", 'error');
      event.target.value = null;
      this.file_name = '';
    }else{
      event.target.value = null;
      this.file_name = '';
    }

  })
}

uploadAddinfoDocument2(event, type: string){
  this.access =1;
  const formData = new FormData();
//  console.log(event.target.files[0].name);
  let ourEx =event.target.files[0].name.split('.')
  console.log(ourEx);
    formData.append('DocumentName', 'Additional_Doc');
    formData.append('file', event.target.files[0]);
    console.log(event.target.files[0]);
  // let allowedEx =event.target.files[0].name.split(',');
    formData.append('LOBCode', 'SM');
    formData.append('QuotationNumber', this.quoteNumber);
    formData.append('FileExtn', ourEx[1]);

  this.smequoteService.uploadDocuments(formData).subscribe(result=>{
    console.log(event.target.files[0].name);
    this.response_code = result.response_code;
    if(result.response_code == 1)
    {
      if (type === 'claimDocument') {
        this.claimFileName = event.target.files[0].name;
        // Update response code and uploaded file name accordingly
        this.claimResponseCode = '1'; // Simulating a successful upload
        this.uploadedClaimFileName = result.image_url; // Set your file path here
      } else if (type === 'additionalDocument') {
        this.otherFileName =event.target.files[0].name; 
        // Update response code and uploaded file name accordingly
        this.otherResponseCode = '1'; // Simulating a successful upload
        this.uploadedOtherFileName =  result.image_url;// Set your file path here
        const additionalDocFile = {
          fileName: event.target.files[0].name,
          fileType: ourEx[1],
          fileURL: result.image_url
        };
        this.additionalDocFiles.push(additionalDocFile); 
      }

    }

    // if(result.response_code == 1){
    //  // this.UploadedFileName =result.image_url;
    //  // this.file_name =event.target.files[0].name;
    // }
    else if(result.response_code == 3){
      Swal.fire("Allowed upload file size is up to 2MB", "", 'error');
      event.target.value = null;
      this.file_name = '';
    }
    else if(result.response_code == 5){
      Swal.fire("Please select valid file format.", "", 'error');
      event.target.value = null;
      this.file_name = '';
    }else{
      event.target.value = null;
      this.file_name = '';
    }

  })
}

triggerFileInput(type?: string) {
  // Logic to open file input for uploading additional documents
  const fileInput = document.getElementById(type);
  if (fileInput) {
      fileInput.click();
  }
}
// triggerFileInput() {
//   const fileInput = document.getElementById('additionalDocumentInput') as HTMLInputElement;
//   if (fileInput) {
//     fileInput.click(); // Programmatically click the file input when "+" is pressed
//   }
// }

handleAdditionalDocument(event: any) {
  const file = event.target.files[0];
  if (file) {
    // Call the modified upload function with type 'additionalDocument'
    this.uploadAddinfoDocument2(event, 'additionalDocument');
  }
}
termsAndConditions(frame){

  frame.show();

}

// ---------------------------------------------Range For Loading & Discount-----------------------------------------------

discountRange(){
  this.homeQuoteService.getPlanLoadingDiscount(this.productCode,'SM', this.smeQuoteDetails.value.partner,'1','ENG').subscribe(
    result=>{
      console.log(result);
      this.minLoadingRange=result.getPlanLoadingDiscount[0].PLAmount;
      this.maxLoadingRange=result.getPlanLoadingDiscount[0].PLPercent;
      this.loadingby =this.minLoadingRange;

    })
 
}
getReferalCondtion(ref_val){
// alert(1)
this.ref_value = ref_val;
  this.referalModel = true;
        if(ref_val == 'APPROVED'){
          this.refer_type = 'Approve';
          this.refer_condtion_type = 3;
        }

        if(ref_val == 'REJECTED'){
          this.refer_type = 'Reject';
          this.refer_condtion_type = 4;
        }

        if(ref_val == 'REFERRAL'){
          if (this.quoteNumber == ''){
            this.refQuotation();
          }
          this.refer_type = 'Refer';
          this.refer_condtion_type = 2;
        }
        if(ref_val == 'REFASSIGN'){
          if (this.quoteNumber == ''){
            this.refQuotation();
          }
          this.refer_type = 'Refer';
          this.refer_condtion_type = 1;
        }

        if(ref_val == 'ADDITIONALINFO'){
          this.refer_type = 'Additional Info';
          this.refer_condtion_type = 5;
        }

}

// -------------------------------------------------Referal ---------------------------------------------------------

sendRefferMailToAdmin(type,refReason,frame) {	
  console.log(refReason)
  if(type == 1)
  {
    this.validtnMsg = false;
    this.referal = true;
    //update the Quotation details table 
  }
  if (type == 2 ) {
// alert(1)
    if (this.referalDescription == '') {
      // alert(2)
      this.validtnMsgfordescription = true;
      return false;
    }
    this.validtnMsg = false;
    this.referal = true;
  }

  if (type == 3 || type == 4 || type == 5) {
      if (this.referalDescription == '') {
          this.validtnMsgfordescription = true;
          return false;
      }
      this.validtnMsgfordescription = false;
      this.referal = true;
  }


  let refer_type;
  let event_type;

  if (type == 1) {
    event_type = 'REFASSIGN';        // REFER A QUOTE
    refer_type = type == 1 ? 'SYSTEM' : 'MANUAL';
    this.policyStatus = 'REFERED';

  }
  if (type == 2 ) {
    event_type = 'REFERQUOTE';        // REFER A QUOTE
    refer_type = type == 1 ? 'SYSTEM' : 'MANUAL';
    this.policyStatus = 'REFERED';
  }

  if (type == 3) {
      event_type = 'REFERAPPROVED';     // APPROVED
      refer_type = '';
      this.policyStatus = 'APPROVED';
  }

  if (type == 4) {
        event_type = 'REFERREJECTED';      // REJECTED
        refer_type = '';
        this.policyStatus = 'REJECTED';
  }

  if (type == 5) {
      event_type = 'ADDITIONALINFO';      // ADDITIONAL INFO
      refer_type = '';
      this.policyStatus = 'ADDITIONALINFO';
  }
// console.log(type)
// console.log(refer_type)
  this.smequoteService.getReferal(this.quoteNumber,refReason, this.referalDescription ,event_type, refer_type,this.additionalDocFile,'',this.RefAssignedTo).subscribe(res => {

    if (res.response_code == 1) {
      if(type ==1)
      {
        this.referal = false;
      
      }
      if (type == 1) {
        this.referal = false;
        Swal.fire('Your quote has been re-assigned to our expert team of UWs for review.', 'You will be notified soon after the review. Your Quotation Reference# ' + this.quoteNumber, 'success');
        this._route.navigate(['sme/products']);
      }

      if (type == 2) {
        this.referal = false;
        Swal.fire('Your quote has been referred to our expert team of UWs for review.', 'You will be notified soon after the review. Your Quotation Reference# ' + this.quoteNumber, 'success');
        this._route.navigate(['sme/products']);
      }

      if(type == 3){
        Swal.fire('The quote has been successfully approved for the Quotation Number: ' + this.quoteNumber);
        this._route.navigate(['home/referalquote']);
      }
      if(type == 4){
        Swal.fire('The quote has been Rejected for the Quotation Number: ' + this.quoteNumber);
        this._route.navigate(['home/referalquote']);
      }
      if( type == 5 ) {	
        if ((this.userRole == "TELEMARKETING" || this.userRole == 'UW USER' || this.userRole == 'SENIOR UW') && this.businessSource == "DIRECT") {
          Swal.fire('The Additional Info has been requested for the Quotation Number: ' + this.quoteNumber);
        }
        else{
          Swal.fire('Quotation Number: ' + this.quoteNumber +' has been referred to our expert team of UWs for review.');
        }
       	
        this._route.navigate(['home/referalquote']);	
            // this.getQuotePlan('');	
            // this.referalModel = false;           
      }

    }

  });
}

//------------------------------------------- UPLOAD ADDITIONAL DOCUMENTS ---------------------------------//
uploadAdditionalDoc(event) {

  this.additionalDoc = true;

  this.additionalDocFile = [];
for (var i = 0; i < event.target.files.length; i++) {

  const formData = new FormData();
  formData.append('file', event.target.files[i]);
  formData.append('stage', 'QUOTE_ADDINFO');
  formData.append('quotation_number', this.quoteNumber);

  this.motorQuoteService.uploadMultipleDocuments(formData).subscribe(res => {

  let updateMemResponse = res;
  this.document_data = updateMemResponse.Document_data;


  this.hideImages = true;

  if (updateMemResponse.res_code == 1) {
    let fileType = updateMemResponse.File.split(".");
    fileType = fileType[fileType.length - 1];
    fileType = fileType == "pdf" ? "PDF" : "IMG";

   
    this.additionalDocFile.push({
      "file": updateMemResponse.FileDir,
      "fileType": fileType,
      'file_name': 'Additional_Doc',
      'file_dir': updateMemResponse.FileDir,
      'docName': updateMemResponse.File,
    });

    this.tempAdditionalDoc = this.additionalDocFile;
  
      this.additionalDoc = false;
   
  }
  if (updateMemResponse.response_code == 5) {

      this.additionalDoc = false;
      Swal.fire("Please select valid file format.", "only .pdf file formats allowed.", 'error');

  }

  if (updateMemResponse.response_code == 6) {

      this.additionalDoc = false;
      Swal.fire(updateMemResponse.response_status);
  }

});
}
}

// ------------------------------------------Check Partner Credit Limit--------------------------------------------
async checkPartnerCreditLimit(){
  //Added by Anju for Partner Status Check & Credit Limit
  this.selectedPartnerId=this.smeQuoteDetails.value.partner;
  this.selectedBranchId = this.smeQuoteDetails.value.branch.Id
  // let result= this.smequoteService.checkPartnerCreditLimit("Property All Risk",this.sectionpremium_PAR,this.selectedPartnerId,this.selectedBranchId);
  // let data = JSON.stringify(result)
  // console.log(result);
 // this.checkcreditLimitArray.push({status:result['cl_status']});
  
  if( this.sectionpremium_PL[0] != 0){
 
  let result=await this.smequoteService.checkPartnerCreditLimit("Property All Risk",this.sectionpremium_PAR.reduce((accumulator, currentValue) => accumulator + currentValue, 0),this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
   if( this.sectionpremium_Money[0] != 0){
 
              let result=await this.smequoteService.checkPartnerCreditLimit("Money",this.sectionpremium_Money.reduce((accumulator, currentValue) => accumulator + currentValue, 0),this.selectedPartnerId,this.selectedBranchId);
              let data = JSON.stringify(result)
            console.log(result);
            this.checkcreditLimitArray.push({status:result['cl_status']})
  
  }
   if( this.sectionpremium_PL[0] != 0){
 
  let result=await this.smequoteService.checkPartnerCreditLimit("Public Liability",this.sectionpremium_PL.reduce((accumulator, currentValue) => accumulator + currentValue, 0),this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_WN[0] != 0){
  
  let result=await this.smequoteService.checkPartnerCreditLimit("Workmens Compensation",this.sectionpremium_WN.reduce((accumulator, currentValue) => accumulator + currentValue, 0),this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_FG[0] != 0){
 
  let result=await this.smequoteService.checkPartnerCreditLimit("Fidelity Guarantee",this.sectionpremium_FG.reduce((accumulator, currentValue) => accumulator + currentValue, 0),this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_BI[0] != 0){
  
  let result=await this.smequoteService.checkPartnerCreditLimit("Business Interruption",this.sectionpremium_BI.reduce((accumulator, currentValue) => accumulator + currentValue, 0),this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_MB[0] != 0){
  
  let result=await this.smequoteService.checkPartnerCreditLimit("Machinery Breakdown",this.sectionpremium_MB.reduce((accumulator, currentValue) => accumulator + currentValue, 0),this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  if( this.sectionpremium_EE[0] != 0){
  
  let result=await this.smequoteService.checkPartnerCreditLimit("Electronic Equipment",this.sectionpremium_EE.reduce((accumulator, currentValue) => accumulator + currentValue, 0),this.selectedPartnerId,this.selectedBranchId);
  let data = JSON.stringify(result)
  console.log(result);
  this.checkcreditLimitArray.push({status:result['cl_status']})
  }
  console.log('checkcreditLimitArray:', JSON.stringify(this.checkcreditLimitArray))
  const _CLStatus = this.checkcreditLimitArray.every((x) => x.status == "1");  
if(_CLStatus){
  //new API call changes for checking credit Days- shuhaib
  //let res_credit=await this.smequoteService.checkPartnerCreditDays(this.selectedPartnerId);
  //console.log('checkPartnerCreditDays response for selectedPartnerId: '+this.selectedPartnerId,JSON.stringify(res_credit));
 // if(res_credit['creditDays']>0){
    this.lmt_days =_CLStatus;
 // }
 // else{
//Swal.fire('Credit limit expired','Partner does not have sufficient Credit Days','error');
 // }
}
else{
  console.log('credit limit amount is exceeded')
 Swal.fire('Credit limit exceeded','Partner does not have sufficient Credit limit','error');
}
  
  // this.checkcreditLimitArray.forEach((element,index)=>{
  //   console.log( this.checkcreditLimitArray[index])
  //   if(this.chechfor == true){
  //     if(this.checkcreditLimitArray[index].status == "100010"){
  //       // alert(1)
  //       this.lmt_days =false;
  //       return
  
  //     }
      
  //   }else{
  //     this.chechfor =false;
  //   }
   
  //   console.log(  this.lmt_days)


  // })

}  
selectionChange(stepper:any){
  this.locations.controls.forEach((x,i)=>{
    if(this.locations.at(i).value.insuringProperty.label == 'Yes'){
      this.is_buildingSelected[i]=true;
      }
      else{
        this.is_buildingSelected[i]=false;
        this.benefitData[i].forEach((element,index)=>{
            if(element.BenefitName == 'On Building/Premises including Fixtures and Fittings'){
              element.DefaultSumCovered = 0;
              }
          })
       
      }
  })
  console.log( this.is_buildingSelected)
  
  // const check_building:any = document.getElementsByClassName('bn-97');
  // debugger;
  //       if (check_building.length>0) {
  //               debugger;
  //               check_building[0].classList.add('disable_build');
              
  //             }
}
openMap() {
  const factory = this.resolver.resolveComponentFactory(GeoLocationComponent);
  const modalComponentRef = this.modalContainer.createComponent(factory);
  modalComponentRef.instance.closeModalEvent.subscribe(() => {
    modalComponentRef.destroy();
  });
}
riskIntheMall(i){
this.riskInMall[i]=this.locations.at(i).value.riskSelect=='Y'?true:false;

if(!this.riskInMall[i]){
  this.locations.at(i).get('emirateMall').setValidators(null);
  this.locations.at(i).get('emirateMall').setValue('');
  this.locations.at(i).get('emirateMall').updateValueAndValidity();
}
else if(this.riskInMall[i]){
  this.locations.at(i).get('emirateMall').setValidators([Validators.required]);
  this.locations.at(i).get('emirateMall').updateValueAndValidity();
  }
}

  //shuhaib changes for IA License and Trade License expiry check 13102023

public getBrokerLicenseDetails():Promise<boolean>{
  return new Promise(resolve => {
let licenseValid:boolean=false;
this.issuepolicyService.getBrokerLicenseDetails(this.smeQuoteDetails.value.partner,this.smeQuoteDetails.value.branch.Id).subscribe(res=>{
let _reponse=res;
console.log('getBrokerLicenseDetails',JSON.stringify(res));
if(_reponse.response_code=="1"){
  if(!_reponse.getBrokerLicenseDetails[0].PTY_IA_LIC_VALID_TO_DT){
    Swal.fire("Your IA License is not updated in the system. Please contact your relationship manager as soon as possible to avoid inconvenience.","error");
  }
  else if(!_reponse.getBrokerLicenseDetails[0].PTY_BRK_LIC_VALID_TO_DT){
    Swal.fire("Your Trade License is not updated in the system. Please contact your relationship manager as soon as possible to avoid inconvenience.","error");  
  }
  else {
    var iaExpDate = new Date(_reponse.getBrokerLicenseDetails[0].PTY_IA_LIC_VALID_TO_DT);
    var tdExpDate = new Date(_reponse.getBrokerLicenseDetails[0].PTY_BRK_LIC_VALID_TO_DT);
var currentDate = new Date();
if (iaExpDate < currentDate) {
  Swal.fire("You are not authorized.  Please contact relationship manager.  Possible Reason. IA LICENSE EXPIRED.",  "Sorry for the inconvenience caused", "error");
}
else if (tdExpDate < currentDate) {
  Swal.fire("You are not authorized.  Please contact relationship manager.  Possible Reason. TRADE LICENSE EXPIRED.",  "Sorry for the inconvenience caused", "error");
} 
else {
  licenseValid=true;
} 
}
}  
else{
  Swal.fire("Something went wrong!. Please contact your relationship manager as soon as possible to avoid inconvenience.", "error");
}
resolve(licenseValid);
})

  })
}
async GetInitiatedPolicyDetails(quotationNumber){
  this.smequoteService.GetInitiatedPolicyDetails(quotationNumber,this.smeQuoteDetails.value.partner).subscribe(
    result=>{
      debugger;
      this.IntitatedPolicyResult = result.data;
      this.policyNumber= result.data[0].PolicyNumber;
      this.initiatedPolDocs=result.docs;       
      this.totalbasepremium =result.data[0].BasePremWithLoading.replace(/,/g,"");
      this.Vat_Amount =result.data[0].ValueAddedTax.replace(/,/g,"");
      this.policyFee =result.data[0].PolicyFees;
      this.loadingAmount =result.data[0].PremiumLoading;
      this.totalPremium =result.data[0].TotalContribution.replace(/,/g,"");
      this.IntitatedPolicyResult.forEach((element,i) => {
        //this.addLocation();
        this.additionalDetails.at(i).get('contactperson').setValue(element.ContactPerson);
        this.additionalDetails.at(i).get('contactperson').setValue(element.EmailAddress);
        this.additionalDetails.at(i).get('mortgagee').setValue(element.MortgageeText);
        this.additionalDetails.at(i).get('tradeLicNumber').setValue(element.RegistrationNumber);
        this.additionalDetails.at(i).get('trnNo').setValue(element.TRNNumber);
        this.additionalDetails.at(i).get('poboxNo').setValue(element.POBox);
        this.additionalDetails.at(i).get('phoneNumber').setValue(element.PhoneNumber);
        this.additionalDetails.at(i).get('companyAddress').setValue(element.RiskLocation);
        this.additionalDetails.at(i).get('mobNo').setValue(element.MobileNumber);
        this.additionalDetails.at(i).get('email').setValue(element.EmailAddress);
        this.additionalDetails.at(0).get('trnNo').setValue(element.TRNNumber);
        this.additionalDetails.at(i).get('assignmentValue').setValue(null);
        if(this.initiatedPolDocs.filter(d=>d.DocType=='No VAT Declaration Letter').length>0){
          this.additionalDetails.at(i).get('vatRegcheck').setValue(this.optionyesNo[0])
        }
        else{
          this.additionalDetails.at(i).get('vatRegcheck').setValue(this.optionyesNo[1])
        }
        if(result.data[0].MortgageeText.length>0){
          this.additionalDetails.at(i).get('mortgageecheck').setValue(this.optionyesNo[0])
        }
        else{
          this.additionalDetails.at(i).get('mortgageecheck').setValue(this.optionyesNo[1])
        }
        //need to fix freezzone value
        this.additionalDetails.at(i).get('freezzone').setValue(this.freezzone[0])
      });
   
      
    })
    //Need to modify the below code -shuhaib
    // let creditLimit:any= await this.smequoteService.checkPartnerCreditLimit("7",this.sectionpremium_Money,this.selectedPartnerId,this.selectedBranchId);
    // debugger;
    //   this.lmt_days=creditLimit.cl_status=='1'?true:false;

   // this.getQuotation('')
   // this.checkPartnerCreditLimit();
      //this.lmt_days=true;
      console.log(this.additionalDetails)
  }
  issueInitiatedpolicy(type){

    if (!this.additionalDetails.controls.every(x => x.value.email)) {
  
     // this.additionalDetails.markAllAsTouched();
    //  this.additionalDetails.updateValueAndValidity();
    Swal.fire("", "Please fill all the mandatory data", 'error');
    return;
  
  }

  else  if(this.accept_terms == false){
    Swal.fire("", "Please Select Terms And Conditions", 'error');
    return;
  }
  else  if(this.accept_disclaimer == false){
    Swal.fire("", "Please accept Disclaimer", 'error');
    return;
  }
  this.issuepolicyLoader=this.globalService.spinnerShow();
  let savePolicy = []

this.additionalDetailForm.value.additionalDetails.map((x,i)=>{
    savePolicy.push({
        CompanyName:this.smeQuoteDetails.value.businessName.replace(this.characterToReplace, this.replacementCharacter),
        ContactPerson:x.contactperson,
        MortgageeText:x.mortgagee,
        AssignmentValue:x.assignmentValue,
        RegistrationNumber:x.tradeLicNumber,
        TRNNumber:x.trnNo,
        RiskLocation:x.companyAddress,
        POBox:x.poboxNo,
        PhoneNumber:x.phoneNumber,
        MobileNumber:x.mobNo,
        EmailAddress:x.email,
        FreeZone:x.freezzone.value,
        CoverStartDate:this.convertDate(this.smeQuoteDetails.value.startDate),
        PartnerId:this.smeQuoteDetails.value.partner,
        UserId:this.userId,
        FixedPlanContribution:this.basePremium[i],
          FixedPlanId:this.planId,
          ServiceTax:this.serviceTax,
          ServiceTaxRate:0,
          policyFee:this.policyFee,
          TotalContribution:this.totalPremium,
          VATAmount: this.Vat_Amount,
          VATTaxRate:5,
          PremLoading:this.loadingAmount,
          PremLoadingRate:this.loadingby,
        ProductCode: this.productCode,
        QuotationNumber:this.quoteNumber,
        PlanId:this.planId,
        CurrencyCode:'AED',
        source : 'B2B' ,
        AddressLatitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[0].trim().slice(0, 19) : '',
       AddressLongitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[1].trim().slice(0, 19) : ''
  
    })

})
    // let savePolicy=[{
    //   CompanyName:this.additionalDetailForm.value.entityName.replace(this.characterToReplace, this.replacementCharacter),
    //   ContactPerson:this.additionalDetailForm.value.contactperson,
    //   MortgageeText:this.additionalDetailForm.value.mortgagee
    //   ? this.additionalDetailForm.value.mortgagee
    //   : {
    //       "Id": 0,
    //       "InstituteName": "Not Mortgaged",
    //       "InstituteCode": "NTMORT",
    //       "CRS_Bank_Map_Code": "000"
    //   },
    //   RegistrationNumber:this.additionalDetailForm.value.tradeLicNumber,
    //   TRNNumber:this.additionalDetailForm.value.trnNo,
    //   RiskLocation:this.additionalDetailForm.value.companyAddress,
    //   POBox:this.additionalDetailForm.value.poboxNo,
    //   PhoneNumber:this.additionalDetailForm.value.phoneNumber,
    //   MobileNumber:this.additionalDetailForm.value.mobNo,
    //   EmailAddress:this.additionalDetailForm.value.email,
    //   FreeZone:this.additionalDetailForm.value.freezzone.value,
    //   CoverStartDate:this.convertDate(this.smeQuoteDetails.value.startDate),
    //   PartnerId:this.smeQuoteDetails.value.partner,
    //   UserId:this.userId,
    //   ProductCode: this.productCode,
    //   QuotationNumber:this.quoteNumber,
    //   PlanId:this.planId,
    //   CurrencyCode:'AED',
    //   source : 'B2B' ,
    //   AddressLatitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[0].trim().slice(0, 19) : '',
    //  AddressLongitude :this.addressLocationCords.includes(',') ? this.addressLocationCords.split(',')[1].trim().slice(0, 19) : '',
    //  Type:type
    // }]
    //Added by Anju for party status active check on Issue Policy/Pay Now/Send Payment Link
    this.selectedPartnerId=this.smeQuoteDetails.value.partner;
    this.selectedBranchId = this.smeQuoteDetails.value.branch.Id; 
    console.log('calling smequoteService.smesavePolicy savePolicy:'+JSON.stringify(savePolicy) )
    console.log('calling smequoteService.smesavePolicy type:'+type )
    this.smequoteService.checkPartner_CreditLimit("Property All Risk",0, this.selectedPartnerId,this.selectedBranchId).subscribe(response=>
    {
      if(response.cl_status=='200012'){
        Swal.fire('Party is not Active.');
        this.issuepolicyLoader=this.globalService.spinnerHide();
      }
      else
      {
        if(type == 1){
          localStorage.removeItem('Payment_Order_ID');
          localStorage.removeItem('Payment_Order_Tokan');
          localStorage.removeItem('Payment_Quotation_Number');
          localStorage.removeItem('Payment_Quotation_ID');
          this.smequoteService.UpdateInitiatedSMEPolicy(savePolicy).subscribe(res=>{
            if(res.res_code == 1){
              this.policyNumber = res.policy_details[0].PolicyNumber;  
              this.getCreditDebitNote();
              this.issuepolicyLoader=this.globalService.spinnerHide();
              this._route.navigateByUrl("sme/thankyou/" +  this.policyNumber +'/'+ this.quoteNumber);
             }
          });
        }
        if(type ==2 ){
        this.smequoteService.UpdateInitiatedSMEPolicy(savePolicy).subscribe(res=>{
            this.policyNumber = this.policyNumber;
                // if(res.res_code == 1){
                  let return_url = "http://localhost:4200/dashboard";
                  let site_url = "http://localhost:4200/" + this._route.url;
                    this.travelInsuranceService.paymentAuth(this.quoteNumber, site_url, return_url, 'SM', this.partnerID, this.policyNumber, 'SHIELD', 'B2B').subscribe(res => {//Added by Anju for SME Payment gateway revamp
                       debugger;
                      if (res.status == 'Failed') {
                            Swal.fire(res.res_msg);
                            this.issuepolicyLoader = this.globalService.spinnerHide();
  
                            return false;
                        }
  
                        else if (res.status == 'Success') {
  
                            // this.policyLoader = this.globalService.spinnerHide();
                            localStorage.setItem('Payment_Order_ID', res.data.tran_ref);
                            localStorage.setItem('Payment_Order_Tokan', res.data.token);
                            localStorage.setItem('Payment_Quotation_Number', this.quoteNumber);
                            localStorage.setItem('Payment_Quotation_ID', this.quoteId);
                            window.location.href = res.data.redirect_url;
                  // window.open(res.result.redirect_url, '_blank');
               
        
                }
  
              })
            //}
     })
  
    }
    if(type == 3){
       this.smequoteService.UpdateInitiatedSMEPolicy(savePolicy).subscribe(res=>{
      this.motorQuoteService.sendPaymentLink(this.quoteNumber, 'SM',this.additionalDetailForm.value.email,'B2B','',this.smeQuoteDetails.value.partner).subscribe(res => {
  
        let payRes = res;
    
        if (payRes.res_code == 1) {
    
          Swal.fire('', 'Payment link has been sent to customer for the reference no. ' + this.quoteNumber, 'success');
    
          this._route.navigate(['sme/products']);
    
        }
        // this.showLoader.Quotation = false;
      });
  })
    }
     }
      }); 
  }

  checkDirectSalesAgent(){
    this.smequoteService.checkDirectSalesAgent(this.partnerID).subscribe(res => {
      let response = res;
    
          if (response.directSalesAgent.directSalesAgent == 'Y') {           
              this.isDirectSalesAgent=true;
              this.disabled = false;
          }
      });
  }
  //getMortgagelist
  getMortgagelist(){

    this.motorQuoteService.getBankDetail().subscribe((res) => {
      this.bankDetailres = res.bankdetailsData;
      //remove the not mortgage value from the list
      this.bankDetail = this.bankDetailres.slice(1,  this.bankDetailres.length);
      console.log("BankList",this.bankDetail)
      //this.additionalDetails.get('mortgagee').setValue(this.bankDetail[0]);
      ///this.filteredBank.next(this.bankDetail.slice());
    });

  }
  
  isRequiredMarker(i,controlName : string):boolean
  {
    // this.additionalDetails.at(i).get(controlName).setValidators(null);
    // this.additionalDetails.at(i).get(controlName).updateValueAndValidity();
    const control = this.additionalDetails.at(i).get(controlName).validator; // commented now
    return control != null ? true : false // commented now
    return false;
  }

  CheckValueLimit(i)
  {
    console.log(this.totalpar[i],this.alor[i])
    this.allowedLimit =  this.totalpar[i] - this.alor[i];
    if(Number(this.additionalDetails.at(i).value.assignmentValue) > Number(this.allowedLimit))
    {
      Swal.fire('', 'Assignment Value cannot be greater than Property Sum Insured', 'error');
      this.additionalDetails.at(i).get('assignmentValue').setValue(null);
      this.additionalDetails.at(i).get('assignmentValue').updateValueAndValidity();
    }


  }

  CheckReferralConditions()
  {
    if(this.quoteStatus != 'REFERRED' && this.quoteStatus != 'REFAPPROVED' && this.quoteStatus != 'ADDITIONALINFO') {
      console.log(this.refReason)
      this.refReason[0] = ''
      if(this.isReferral == 3){
        this.refReason[0] = this.refReason[0] + this.referalRes + ";\n";
        this.quoteLoader=this.globalService.spinnerHide();
        this.isToBeReferred = true;
      }
      if(this.smeQuoteDetails.value.claimHistory.Id == '1'){
        if(this.policyType == "RENEWAL" || this.quoteStatus == 'RENQUOTE'){
        this.quoteLoader=this.globalService.spinnerHide();
        this.refferalButton =false;
        }else{
          this.refReason[0] = this.refReason[0] + "Claim History Selected as Yes" + ";\n";
          this.quoteLoader=this.globalService.spinnerHide();
          this.isToBeReferred = true;
        }
      }
      this.locations.controls.forEach((x,i)=>{
        console.log(this.refReason)
        if(i>=1)
        {
          this.refReason[i]=''
        }
        console.log(this.isReferral);
        this.quotationloader=this.globalService.spinnerHide();
        console.log(this.claimCount);
        console.log(this.claimAmount);
        console.log(this.expiringPremium);
        if(this.claimCount > '3'){
          this.refReason[i] = this.refReason[i] + "The pervious claim count is" + this.claimCount;";\n";
          this.quoteLoader=this.globalService.spinnerHide();
          this.isToBeReferred = true;
        }
        this.claimRatio = (this.claimAmount/this.expiringPremium) * 100;
        console.log(this.claimRatio);
        if(this.claimRatio > 150){
          this.refReason[i] = this.refReason + "The gross loss ratio is more than 150%the gross loss ratio is more than 150%" + ";\n";
          this.quoteLoader=this.globalService.spinnerHide();
          this.isToBeReferred = true;
        }

          if(this.claimCount > '3'){
            this.refReason[i] = this.refReason[i] + "The pervious claim count is" + this.claimCount + ";\n";
            this.quoteLoader=this.globalService.spinnerHide();
            this.isToBeReferred = true;
          }
        
          console.log(this.locations.at(i).value)
          // if(this.smeQuoteDetails.value.emirateMall &&this.smeQuoteDetails.value.emirateMall.MallName === 'Others')
          if(this.locations.at(i).value.emirateMall && this.locations.at(i).value.MallName === 'Others') {
          this.refReason[i] = this.refReason +this.locations.at(i).value.businessPremises.BusinessPremise + ";\n";
          this.quoteLoader=this.globalService.spinnerHide();
          this.isToBeReferred = true;
          }
            const locations = this.locationForm.get('locations') as FormArray;
    
      
            console.log(this.locations.length)
            const AnnualTurnoverId = this.locations.at(i).value.AnnualBusiness.Id;
            const industryTypeId = this.locations.at(i).value.industryType.IndustryId;

            if(industryTypeId == '1'){
              this.refReason[i] = this.refReason[i] + "Industry Selected is "+this.locations.at(i).value.industryType.IndustryName + " in Location " + (Number(i)+1)+";\n";
              this.quoteLoader=this.globalService.spinnerHide();
              this.isToBeReferred = true;
            }
            if(AnnualTurnoverId == '2'){
              this.refReason[i] = this.refReason[i] + "Annual Business Selected is "+this.locations.at(i).value.AnnualBusiness.AnnualTurnover + " in Location " + (Number(i)+1)+";\n";
              this.quoteLoader=this.globalService.spinnerHide();
              this.isToBeReferred = true;
            }
          
      })
    }
    console.log(this.refReason)
     return this.isToBeReferred


  }
    // Method to update the sum insured values and total
    updateSumInsured(item,i,locationIndex) {
      if(this.adminCount == 0 && this.adminSumInsured > 0)
      {
        Swal.fire("Admin Count cannot be 0", "error");
        return false;
      }
      if(this.nonAdminCount == 0 && this.nonAdminSumInsured > 0)
      {
        Swal.fire("Non Admin Count cannot be 0", "error");
        return false;
      }
      // this.adminSumInsured = this.adminCount * this.someAdminRate; // Replace with your logic
      // this.nonAdminSumInsured = this.nonAdminCount * this.someNonAdminRate; // Replace with your logic
      //this.totalEmployees = this.adminCount + this.this.adminCount
      
       this.totalSumInsured1 = Number(this.adminSumInsured) + Number(this.nonAdminSumInsured);
       this.benefitData[locationIndex][i].DefaultSumCovered =this.totalSumInsured1.toString();
       //this.benefitData[i].DefaultSumCovered =this.totalSumInsured1.toLocaleString('en-US');
       this.adminSumInsured.toLocaleString('en-US');
       this.nonAdminSumInsured.toLocaleString('en-US');
       this.cdr.detectChanges();
       this.premiumCalucation();
       console.log("calling Check Sum Cal")
       this.checksumCal(item, this.benefitData[locationIndex][i].DefaultSumCovered, i);
       console.log("Premium Calculation")
       this.premiumCalucation();
       
    }
  
    get totalEmployees() {
      return Number(this.adminCount) + Number(this.nonAdminCount);
    }

    viewPlanBenefit(frame)
    {
      frame.show()
    }
    //  public itemLoc:any = -1;
  addQuoateForm(i){
    this.locationFormArray.push(this.formBuilder.group({
     
      contactperson:['',Validators.required],
      mortgageecheck:['',Validators.required],
      vatRegcheck:['',Validators.required],
      mortgagee:['',Validators.required],
      assignmentValue:['',Validators.required,Validators.pattern(/^[0-9]+$/)],
      tradeLicNumber:['',Validators.required],
      companyAddress : ['',Validators.required],
      // companyAddressGeoLocation : ['',Validators.compose([Validators.required,Validators.minLength(8)])],
      companyAddressGeoLocation : [''],
      poboxNo: ['',Validators.required],
      phoneNumber: ['',Validators.compose([Validators.required,Validators.minLength(9), Validators.maxLength(9), Validators.pattern(/^((02|03|04|06|07|08|09){1}([0-9]{7}))$/)])],
      startDate:  [this.minDate,Validators.required],
  
      email: ['',Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+)[.]{1}[a-zA-Z]{2,}$')])],
      trnNo: [''],
      mobNo: ['',Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/)])],
      freezzone: ['',Validators.required],
    }));

    let formCounter = this.locationFormArray.length-1 ;
    this.itemDeclartion[formCounter]= [];

     let formLength  = this.locationFormArray.length ;
     formLength = formLength -2 ;
    
    let el = document.getElementById("target"+formLength);
     

      el.scrollIntoView({behavior: 'smooth'});
 

  }

  deleteQuoateForm(index){
    this.locationFormArray.splice(index,1);
    let formLength  = this.locationFormArray.length ;
    formLength = formLength -2 ;
    
   let el = document.getElementById("target0");
   el.scrollIntoView({behavior: 'smooth' , block: "end", inline: "nearest"});


  }

  // Common function to get a FormGroup at a specific index in the locations FormArray
getLocationFormAtIndex(index: number): FormGroup {
  const locations = this.locationForm.get('locations') as FormArray;
  return locations.at(index) as FormGroup;
}
openModal(subArray, index): void {
  // Set the current subarray and index
  this.businessPackageModel = true;
  this.currentSubArray = subArray;
  this.currentIndex = index;
  debugger;
}
closeModel(){
  this.businessPackageModel = false;
}
}
