import { Component, OnInit } from '@angular/core';
import { IssuepolicyService } from './../../_service/issuepolicy.service';
import { GlobalService } from "./../../_service/global.service";
import { Router,ActivatedRoute } from '@angular/router';
import { MotorquoteService } from './../../_service/motorquote.service';
import { HomequoteService } from 'app/_service/homequote.service';
import Swal from 'sweetalert2';
import { TraveInsuranceService } from 'app/_service/traveInsurance.service';

@Component({
  selector: 'app-paymentinvoice',
  templateUrl: './paymentinvoice.component.html',
  styleUrls: ['./paymentinvoice.component.scss']
})

export class TravelPaymentinvoiceComponent implements OnInit {
  public quoteNum:any; totalPremium :any; quoteDetail:any = []; paymentLoader:boolean = false; quote_Status:any; paymentMsgShow:boolean = false; retrieveData:any;
  localStorDta: any;
  partnerId: any;
  cedant_id: any;
  quoitationID: string;
  issuePoLoader: boolean =false;
  quoteDetailData: any;
  toalContri: any;
  toalContriLoader: boolean =false;
  policyNumber: any;
  quoteStatus: any;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public issuePolicyService: IssuepolicyService,public motorQuoteService: MotorquoteService,public globalService:GlobalService, public homeQuoteService:HomequoteService,public travelInsuranceService:TraveInsuranceService) { }

  ngOnInit() {
    
    this.paymentLoader = this.globalService.spinnerHide();
    this.issuePoLoader = this.globalService.spinnerHide();
    this.toalContriLoader = this.globalService.spinnerHide();

    const routeParam = this._activatedroute.snapshot.params;
    this.localStorDta =  this.globalService.getLocalStorageData();
    if(this.localStorDta != null){
      this.partnerId = this.localStorDta.Partner_ID;
      this.cedant_id = this.localStorDta.Cedant_ID;
    }
  
    
    if(routeParam){

      this.quoteNum = routeParam.QuotationNumber;
      this.getQuoteDetail();

      }


     


  }

  getQuoteDetail(){
    this.toalContriLoader = this.globalService.spinnerShow();
    this.travelInsuranceService.getQuotationDetail('',this.quoteNum,'1','B2B').subscribe(res =>{
                    this.toalContriLoader = this.globalService.spinnerHide();
                    this.quoteDetailData = res.quotationDetailsData[0];
                    this.quoitationID = this.quoteDetailData.QuotationId
                    this.toalContri = this.quoteDetailData.TotalContribution;
                    this.quoteStatus = this.quoteDetailData.StatusDesc;
                    if(this.quoteStatus == "ISSUED"){
                      this.paymentMsgShow = true;
                     }
                  
    });
  }

  payTabPayment(){
    // alert(1)
       // this.policyNumber = res.policy_number;
       let return_url = "http://localhost:4200/dashboard";
       let site_url = "http://localhost:4200//standardtravel/InboundScheme";
    // this.paymentLoader = this.globalService.spinnerShow();
    //Modified by Anju for payment gateway integration
    this.travelInsuranceService.paymentAuth(this.quoteNum ,site_url,return_url,'TR',this.partnerId,this.policyNumber,'INB','B2B').subscribe(res => {
      // console.log(res.result.redirect_url);
    
      if (res.status == 'Failed'){
        Swal.fire(res.res_msg);
        // this.policyLoader = this.globalService.spinnerHide();
        // this.paymentLoader = this.globalService.spinnerHide();
        return false;
  }
 
  else if(res.status == 'Success'){
    // this.paymentLoader = this.globalService.spinnerHide();
        // this.policyLoader = this.globalService.spinnerHide();
        localStorage.setItem('Payment_Order_ID',res.data.tran_ref);
        localStorage.setItem('Payment_Order_Tokan',res.data.token);
        localStorage.setItem('Payment_Quotation_Number',this.quoteNum);
        localStorage.setItem('Payment_Quotation_ID',this.quoitationID);
        window.location.href = res.data.redirect_url;
        // window.open(res.result.redirect_url, '_blank');
     
 
      }
      
   
     
  
  
  
  });
  }

  
}
